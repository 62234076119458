  <div id="register" class="container">

    <div class="row">

      <div class="col-12 text-right">
        <button [routerLink]="['/login']" class="btn color-dark-grey">Looking to login?</button>
      </div>

      <div class="col-12 text-center mb-3">
        <img class="logo" src="assets/images/logo_large.png" height="100" />
      </div>

      <div class="col-12">

        <h2 class="color-dark-grey font-weight-300 text-center mb-4">
          Create your SureSpace Account
          <small><br />Register now and experience our unique dashboard today.</small>
        </h2>

        <hr class="mt-5 mb-4" />

        <form [formGroup]="registerForm" (ngSubmit)="registerNewUser()">

          <div class="row">

            <div class="col-12 col-md-6">
              <!-- First Name -->
              <div class="form-group">

                <label for="firstName">First name:</label>
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.firstName.errors }"
                  placeholder="First name"
                  required
                >

                <div *ngIf="formSubmitted && form['firstName'].errors" class="invalid-feedback">
                  <div *ngIf="form['firstName'].errors.required">Please enter your first name.</div>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>

            <div class="col-12 col-md-6">
              <!-- Last Name -->
              <div class="form-group">

                <label for="lastName">Last name:</label>
                <input
                  formControlName="lastName"
                  type="text"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.lastName.errors }"
                  placeholder="Last name"
                  required
                >

                <div *ngIf="formSubmitted && form['lastName'].errors" class="invalid-feedback">
                  <div *ngIf="form['lastName'].errors.required">Please enter your last name.</div>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="row">

            <div class="col-12 col-md-6">
              <!-- Last Name -->
              <div class="form-group">

                <label for="username">ID number:</label>
                <input
                  formControlName="username"
                  type="text"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && (!validId || form['username'].errors) }"
                  placeholder="ID number"
                  maxlength="13"
                  required
                >

                <div *ngIf="formSubmitted && (!validId || form['username'].errors)" class="invalid-feedback d-block">
                  <div *ngIf="form['username'].errors && form['username'].errors.required">Please enter your SA ID number.</div>
                  <div *ngIf="form['username'].errors && form['username'].errors.minlength">Your ID number must be 13 characters.</div>
                  <div *ngIf="!validId">Please enter a valid SA ID number.</div>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>

            <div class="col-12 col-md-6">
              <!-- Last Name -->
              <div class="form-group">

                <label for="email">Email address:</label>
                <input
                  formControlName="email"
                  type="email"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.email.errors }"
                  placeholder="Email address"
                  required
                >

                <div *ngIf="formSubmitted && form['email'].errors" class="invalid-feedback help-block errorMessage">
                  <div *ngIf="form['email'].errors.required">Please enter your email.</div>
                  <div *ngIf="form['email'].errors.email || form['email'].errors.pattern">Please enter a valid email address</div>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="row" *ngIf="registrar === 'broker'" formGroupName="company">

              <div class="col-12 col-md-6">
                <!-- Company -->
                <div class="form-group">
  
                  <label for="company">Company:</label>
                  <input
                    formControlName="companyName"
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': formSubmitted && form['company']['controls'].companyName.errors }"
                    placeholder="Company name"
                    required
                  >
  
                  <div *ngIf="formSubmitted && form['company']['controls'].companyName.errors" class="invalid-feedback">
                    <div *ngIf="form['company']['controls'].companyName.errors.required">Please enter your company name.</div>
                  </div>
  
                </div> <!-- Close div for .form-group -->
              </div>
  
              <div class="col-12 col-md-6">
                <!-- Broker code -->
                <div class="form-group">
  
                  <label for="brokerCode">Company broker code:</label>
                  <input
                    formControlName="companyBrokerCode"
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': formSubmitted && form['company']['controls'].companyBrokerCode.errors }"
                    placeholder="Broker code"
                    required
                  >
  
                  <div *ngIf="formSubmitted && form['company']['controls'].companyBrokerCode.errors" class="invalid-feedback help-block errorMessage">
                    <div *ngIf="form['company']['controls'].companyBrokerCode.errors.required">Please enter your company broker code.</div>
                  </div>
  
                </div> <!-- Close div for .form-group -->
              </div>
  
              <div class="col-12 col-md-6">
                <!-- Broker code -->
                <div class="form-group">
  
                  <label for="brokerCode">Employee code:</label>
                  <input
                    formControlName="employeeCode"
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': formSubmitted && form['company']['controls'].employeeCode.errors }"
                    placeholder="Employee code"
                    required
                  >
  
                  <div *ngIf="formSubmitted && form['company']['controls'].employeeCode.errors" class="invalid-feedback help-block errorMessage">
                    <div *ngIf="form['company']['controls'].employeeCode.errors.required">Please enter your employee code.</div>
                  </div>
  
                </div> <!-- Close div for .form-group -->
              </div>
  

            </div> <!-- Close div for .row -->
          
          <div class="row">

            <div class="col-12 col-md-6">
              <!-- PASSWORD -->
              <div class="form-group">

                <label for="password">Password:</label>
                <input
                  #password
                  formControlName="password"
                  type="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.password.errors }"
                  placeholder="Password"
                  required
                >

                <mat-password-strength 
                  #passwordStengthMeter
                  (onStrengthChanged)="onStrengthChanged($event)"
                  [password]="password.value">
                </mat-password-strength>

                <div *ngIf="formSubmitted && form['password'].errors" class="invalid-feedback help-block errorMessage">
                    <div *ngIf="form['password'].errors.required">Password is required.</div>
                    <div *ngIf="form['password'].errors.minlength">Password must be at least 7 characters.</div>
                </div>

                <p class="color-dark-grey smallest mt-1">
                  <small>To conform with our Strong Password policy, you are required to use a
                  sufficiently strong password. Password must be more than 7 characters.</small>
                </p>

                <mat-password-strength-info
                  *ngIf="form['password'].dirty"
                  [passwordComponent]="passwordStengthMeter">
                </mat-password-strength-info>

              </div> <!-- Close div for .form-group -->
            </div>


            <div *ngIf="false" class="col-12 col-md-6">
              <!-- CONFIRM PASSWORD -->
              <div class="form-group">

                <label for="confirmPassword">Confirm password:</label>
                <input
                  formControlName="confirmPassword"
                  type="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.confirmPassword.errors }"
                  placeholder="Confirm password"
                  required
                >
                
                <div *ngIf="formSubmitted && form['confirmPassword'].errors" class="invalid-feedback help-block errorMessage">
                    <div *ngIf="form['confirmPassword'].errors.required">Password is required.</div>
                    <div *ngIf="form['confirmPassword'].errors.minlength">Password must be at least 7 characters.</div>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>



            <div class="col-12 col-md-6" *ngIf="registrar === 'user'" formGroupName="insuranceType">
              <!-- INSURANCE TYPES -->
              <div class="form-group">
                <label for="insuranceTypes">Insurance types:<br />
                  <small>
                    Please select which types of items you would like to insure.
                  </small>
                </label>

                <div class="checkbox">
                  <mat-checkbox color="brand" formControlName="householdInsurance">Household insurance</mat-checkbox>
                </div>

                <div class="checkbox">
                  <mat-checkbox color="brand" formControlName="vehicleInsurance">Vehicle insurance</mat-checkbox>
                </div>

                <div class="checkbox">
                  <mat-checkbox color="brand" formControlName="buildingInsurance">Building insurance</mat-checkbox>
                </div>

                <div class="checkbox">
                  <mat-checkbox color="brand" formControlName="personalItemInsurance">Personal item insurance</mat-checkbox>
                </div>

              </div> <!-- Close div for .form-group -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="row mt-5">
            <div class="captcha text-center mb-4" [ngClass]="{ 'failed-captcha': formSubmitted && form.captcha.errors }">
              <re-captcha
                #reCaptchaRef
                name="captcha"
                (resolved)="resolved($event)"
              ></re-captcha>
              
            </div>
          </div> <!-- Close div for .row -->

          <div class="row">

            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
              <button (click)="reCaptchaRef.execute()" type="submit" class="btn btn-brand btn-block mt-4 mb-3">Create my account</button>
            </div>

            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
              <button routerLink="/login" class="btn color-dark-grey mb-5">Back to login?</button>
            </div>

          </div> <!-- Close div for .row -->

        </form>

      </div> <!-- Close div for .col-12 -->

    </div> <!-- Close div for .row -->

  </div> <!-- Close div for .container -->