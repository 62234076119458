<div id="content" class="row">

  <sub-navigation *ngIf='authenticated' heading="Broker plans" subheading="{{ pricingTable === 0 ? 'User pricing' : 'Module purchase' }}"></sub-navigation>

  <div class="col-12">

    <h2 class="text-center color-dark-grey mt-5">
      {{ pricingTable === 0 ? 'User pricing' : 'Module purchase' }}
    </h2>

    <div class="btn-toolbar center mt-4 mb-5" role="toolbar">
      <div class="btn-group" role="group" aria-label="Pricing group" style="margin: 0 auto;">
        <button type="button" (click)="pricingTable = 0" [ngClass]="pricingTable === 0 ? 'btn-brand' : 'btn-secondary'" class="btn btn-pill">User pricing</button>
        <button type="button" (click)="pricingTable = 1" [ngClass]="pricingTable === 0 ? 'btn-secondary' : 'btn-brand'" class="btn btn-pill">Module purchase</button>
      </div>
    </div>

    <div class="row" [hidden]="pricingTable === 1">

        <div id="price-item" class="col-md-4 col-12">
  
          <div id="item">
  
            <div id="price-image" class="col-12 text-center">
              <i class="fi flaticon-account fi-price"></i>
            </div>
  
            <div id="price" class="col-12 text-center">
              <h3 class="font-weight-800"><sup>R</sup>15 <small class="font-weight-800">p/user</small></h3>
              <h5 class="color-dark-grey">0 - 100 users</h5>
            </div>
  
            <div id="price-description" class="col-12 text-center">
              <p>
                Manage up to 100 users<br />
                Add unlimited employees
              </p>
            </div>
  
            <div id="price-purchase" class="col-12 text-center">
              <h5 class="font-weight-500 pt-2 pb-2 color-danger">*BILLED AUTOMATICALLY</h5>
            </div>
  
          </div> <!-- Close div for #item -->
  
        </div> <!-- Close div for #price-item -->
  
        <!--  -->
  
        <div id="price-item" class="col-md-4 col-12">
  
          <div id="item">
  
            <div id="price-image" class="col-12 text-center">
              <i class="fi flaticon-users fi-price"></i>
            </div>
  
            <div id="price" class="col-12 text-center">
              <h3 class="font-weight-800"><sup>R</sup>10 <small class="font-weight-800">p/user</small></h3>
              <h5 class="color-dark-grey">100 - 500 users</h5>
            </div>
  
            <div id="price-description" class="col-12 text-center">
                <p>
                  Manage between 100 and 500 users<br />
                  Add unlimited employees
                </p>
              </div>
    
              <div id="price-purchase" class="col-12 text-center">
                <h5 class="font-weight-500 pt-2 pb-2 color-danger">*BILLED AUTOMATICALLY</h5>
              </div>
  
          </div> <!-- Close div for #item -->
  
        </div> <!-- Close div for #price-item -->

        <!--  -->
  
        <div id="price-item" class="col-md-4 col-12">
  
          <div id="item">
  
            <div id="price-image" class="col-12 text-center">
              <i class="fi flaticon-user fi-price"></i>
            </div>
  
            <div id="price" class="col-12 text-center">
              <h3 class="font-weight-800"><sup>R</sup>5 <small class="font-weight-800">p/user</small></h3>
              <h5 class="color-dark-grey">500+ users</h5>
            </div>
  
            <div id="price-description" class="col-12 text-center">
                <p>
                  Manage unlimited users<br />
                  Add unlimited employees
                </p>
              </div>
    
              <div id="price-purchase" class="col-12 text-center">
                <h5 class="font-weight-500 pt-2 pb-2 color-danger">*BILLED AUTOMATICALLY</h5>
              </div>
  
          </div> <!-- Close div for #item -->
  
        </div> <!-- Close div for #price-item -->
  
      </div> <!-- Close div for .row -->

    <div class="row" [hidden]="pricingTable === 0">

      <div id="price-item" class="col-md-4 offset-md-2 col-12">

        <div id="item">

          <div id="price-image" class="col-12 text-center">
            <i class="fi flaticon-investments fi-price"></i>
          </div>

          <div id="price-name" class="col-12 text-center">
            <h3 class="font-weight-500">Investments</h3>
          </div>

          <div id="price-description" class="col-12 text-center">
            <p>
              Add or edit user investments<br />
              Keep track of changes<br />
              Receive and colaborate with client input
            </p>
          </div>

          <div id="price" class="col-12 text-center">
            <h3 class="font-weight-800"><sup>R</sup>500 <small class="font-weight-800">p/year</small></h3>
            <h5 class="color-dark-grey">calculated annually</h5>
          </div>

          <div id="price-purchase" class="col-12 text-center">
            <button class="btn btn-pill btn-block" mat-flat-button color="brand">Purchase</button>
          </div>

        </div> <!-- Close div for #item -->

      </div> <!-- Close div for #price-item -->

      <!--  -->

      <div id="price-item" class="col-md-4 col-12 price-disabled">

        <div id="item">

          <div id="price-image" class="col-12 text-center">
            <i class="fi flaticon-umbrella fi-price"></i>
          </div>

          <div id="price-name" class="col-12 text-center">
            <h3 class="font-weight-500">Short term insurance</h3>
          </div>

          <div id="price-description" class="col-12 text-center">
            <p>
              Vehicle, household, personal and structure insurance<br />
              Add, edit or remove client items<br />
              Keep track of all client belongings<br />
              Collaborate with client input<br />
              Get claims from clients<br />
              Get quote requests from clients
            </p>
          </div>

          <div id="price" class="col-12 text-center">
            <h3 class="font-weight-800">COMING SOON</h3>
            <h5 class="color-dark-grey">Price TBD</h5>
          </div>

          <div id="price-purchase" class="col-12 text-center">
            <button class="btn btn-pill btn-block" mat-flat-button color="brand" disabled="true">Purchase</button>
          </div>

        </div> <!-- Close div for #item -->

      </div> <!-- Close div for #price-item -->

    </div> <!-- Close div for .row -->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for #content -->
