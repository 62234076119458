import { User } from 'src/app/_shared/models/User.model';
import { Employee } from 'src/app/_shared/models/employeeModel';
import { BrokerService } from 'src/app/services/broker.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BrokerComponent } from './../../broker.component';
import { HttpResponse } from '@angular/common/http';
import { ActivityLogResponse, ActivityLog } from 'src/app/_shared/models/activityLogs.model';
import { Component, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/services/reporting.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-broker-activity-log',
  templateUrl: './broker-activity-log.component.html',
  styleUrls: ['./broker-activity-log.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerActivityLogComponent implements OnInit {

  activityLogs: ActivityLog[] = [];
  
  page = 0;
  noteSearch:string = '';
  brokerSearch = new FormControl(null);

  searchDateStart: Date;
  searchDateEnd: Date;

  employees = [];

  fetchActivities:boolean = true;

  isLoading:boolean = false;

  constructor(
    private reportingService: ReportingService,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService
  ) {
    this.getAllEmployees();
  }

  getAllEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.employees = response.body.user;

      this.brokerSearch.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        // switchMap(value => this.brokerService.getAllClients(this.currentCompany, {search: value}, true)
        switchMap(value => this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName, {search: value}, true)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response) => {
          this.employees = response.body.user;
        }
      )
    })
  }

  displayFn(user?: User) {
    if(!user) return
    return user._id || user['brokerId'] ? `${user.firstName} ${user.lastName}` : typeof user === 'string' ? user : undefined;
  }

  runSearch(search){
    // this.loadingResults = true;
    this.page = 0;
    this.fetchActivities = true;
    this.activityLogs = [];

    this.getActivityLog(this.noteSearch)
  }

  runBrokerSearch(employeeId:string){

    // this.loadingResults = true;
    this.page = 0;
    this.fetchActivities = true;
    this.activityLogs = [];

    setTimeout(() => {
      this.getActivityLog()
    }, 500)
  }

  getActivityLog(search?:string) {

    let req = {
      page: this.page,
      limit: 10,
      search: this.noteSearch,
      broker: this.brokerSearch.value?._id || null,
      startDate: this.searchDateStart,
      endDate: this.searchDateEnd
    }

    if(this.fetchActivities){
      this.reportingService.getActivityLogs(this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response: HttpResponse<ActivityLogResponse>) => {

          console.log('response AL => ', response)

          this.page = this.page + 1;

          response.body.activity.map(activity => {
            // if(activity.action != 'POST') 
            activity.diff = this.createData(activity.diff);
            this.activityLogs.push(activity)
          })

        }
      )
    }
  }

  createData(data){

    if(!data) return;

    let changes = [];

    function mapData(item, key:string, prop?){

      let property = prop;

      for(let prop in item){

        if(prop === 'changeFrom' || prop === 'changeTo'){

          // THIS FILTERS OUT THE ObjectID's that stay the same
          if(item['changeFrom'] == item['changeTo']) return;

          if(item['changeFrom'] && item['changeFrom'].toString() == 'true') item['changeFrom'] = 'added';
          if(item['changeTo'].toString() == 'true') item['changeTo'] = 'added';
          if(typeof item['changeFrom'] !== "undefined" && typeof item['changeFrom'] !== "object" && item['changeFrom'].toString() === 'false') item['changeFrom'] = 'removed';
          if(typeof item['changeTo'] !== "undefined" && item['changeTo'].toString() === 'false') item['changeTo'] = 'removed';

          return changes.push({
            'changeFrom': { key: key, value: item['changeFrom'], prop: property},
            'changeTo': { key: key, value: item['changeTo'], prop: property}
          })
        }

        if(Array.isArray(item[prop])){
          item[prop].map(diff => mapData(item[prop], key, prop))
        }
  
        if(!Array.isArray(item[prop])) {
          mapData(item[prop], key, prop)
        }

      }

    }
    for (let [key, value] of Object.entries(data)){
      mapData(value, key)
    }

    return changes;

  }

  formatValue(value:any){

    // if(Object.prototype.toString.call(new Date(value)) === "[object Date]" && !isNaN(new Date(Date.parse(value)) as any)){
    //   return formatDate(value, 'dd MMM yyyy', 'en')
    // }

    return value
  }

  ngOnInit() {
    this.getActivityLog();
  }

}
