import { AuthenticationService } from './../../../services/authentication.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-reporting-employees',
  templateUrl: './broker-reporting-employees.component.html',
  styleUrls: ['./broker-reporting-employees.component.scss']
})
export class BrokerReportingEmployeesComponent implements OnInit {

  displayedColumns: string[] = ['employee', 'totalInstructions', 'totalClaims', 'totalPolicies', 'totalQuotes', 'totalReturnedDebits', 'totalCalendarEvents'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  employeeReport: any;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getperEmployeeReport();
  }

  getperEmployeeReport(){
    this.reportingService.getPerEmployeeReports(this.authService.currentCompany.companyName).subscribe(response => {
      console.log('response => ', response.body)
      this.employeeReport = response.body.employeeData;
    })
  }

}
