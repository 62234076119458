<div class="row" [formGroup]="claimsFormGroup">

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Client"
                  placeholder="Client"
                  formControlName='client'
                  [matAutocomplete]="user"
                  [readonly]="inEditMode"
                />
                <mat-hint [hidden]="claimsFormGroup.get('client')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding clients...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="clients?.length === 0">
                            <span>No client found</span>
                        </mat-option>
                        <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="claimsFormGroup.get('productType').enable()">
                            <span>{{client?.companyName}}{{ client?.firstName }} {{ client?.lastName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>client</strong> for this claim.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- PRODUCT TYPE -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <mat-label>Please select a product type</mat-label>
                <mat-select
                    name="productType"
                    formControlName="productType"
                    [ngClass]="{ 'is-invalid': formSubmitted && claimsFormGroup['productType'].errors }"
                    (selectionChange)="selectedProductType($event.value)"
                >
                    <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                    <ng-container *ngIf="!claimsFormGroup.get('client')?.value?.companyName">
                        <mat-option value="vehicle">Vehicle</mat-option>
                        <mat-option value="building">Building</mat-option>
                        <mat-option value="homeContent">Home contents</mat-option>
                        <mat-option value="trailerAndCaravan">Trailers and Caravans</mat-option>
                        <mat-option value="allRiskItem">All risk item</mat-option>
                        <mat-option value="vap">Value added products</mat-option>
                    </ng-container>
                        <mat-option *ngIf="claimsFormGroup.get('client')?.value?.companyName" value="commercialProduct">Commercial product</mat-option>
                </mat-select>

                <mat-error>
                    Please select a <strong>product type</strong>.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- PRODUCT -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                    matInput
                    type="text"
                    aria-label="Product"
                    placeholder="Product"
                    formControlName='productDisplay'
                    [matAutocomplete]="product"
                />
                <mat-hint [hidden]="claimsFormGroup?.get('product')?.dirty">Type a {{ claimsFormGroup.get('productType').value | sentenceCase }} to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #product="matAutocomplete" [displayWith]="displayProduct.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding {{ claimsFormGroup.get('productType').value | sentenceCase }}s...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="products?.length === 0">
                            <span>No {{ claimsFormGroup.get('productType').value | sentenceCase }}s found</span>
                        </mat-option>
                        <mat-option *ngFor="let product of products" [value]="product" (onSelectionChange)="mapProduct($event)">
                            <span *ngIf="claimsFormGroup.get('productType').value === 'vehicle'">{{ product.make }} {{ product.model }} - {{ product.registrationNumber }}</span>
                            <span *ngIf="claimsFormGroup.get('productType').value === 'trailerAndCaravan'">{{ product.type }} - {{ product.make }} {{ product.model }}</span>
                            <span *ngIf="claimsFormGroup.get('productType').value === 'building' || claimsFormGroup.get('productType').value === 'homeContent'">{{ product?.address?.formattedAddress }}</span>
                            <span *ngIf="claimsFormGroup.get('productType').value === 'allRiskItem'">{{ product?.itemName }}</span>
                            <span *ngIf="claimsFormGroup.get('productType').value === 'vap'">{{ product?.description }}</span>
                            <span *ngIf="claimsFormGroup.get('productType').value === 'commercialProduct'">{{ product?.productType | sentenceCase }} - {{ product?.description }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>{{ claimsFormGroup.get('productType').value | sentenceCase }}</strong> for this claim.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

        <!-- CLAIM TYPE -->
        <div class="col-sm-6 col-12 mb-3">
            <div class="input-group">
                <mat-form-field class="mb-0">
                    <mat-label>Claim type</mat-label>
                    <mat-select
                        name="claimType"
                        formControlName="claimType"
                        [ngClass]="{ 'is-invalid': formSubmitted && claimsFormGroup['claimType'].errors }"
                    >
                        <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                        <mat-option value="partialLoss">Partial loss</mat-option>
                        <mat-option value="totalLoss">Total loss</mat-option>
                    </mat-select>
    
                    <mat-error>
                        Please select the <strong>claim type</strong>.
                    </mat-error>
    
                </mat-form-field>
            </div> <!-- Close div for .input-group -->
        </div>

    <div class="col-sm-6 col-12 mb-3" *ngIf="(claimsFormGroup.get('productType').value === 'vehicle' && claimsFormGroup.get('claimType').value === 'partialLoss') || claimsFormGroup.get('productType').value === 'commercialProduct'">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <mat-label>Damaged areas</mat-label>
                <mat-select
                    name="affectedArea"
                    formControlName="affectedArea"
                    multiple
                >
                    <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                    <mat-option value="front">Front</mat-option>
                    <mat-option value="leftFront">Left front</mat-option>
                    <mat-option value="rightFront">Right front</mat-option>
                    <mat-option value="right">Right</mat-option>
                    <mat-option value="rightRear">Right rear</mat-option>
                    <mat-option value="rear">Rear</mat-option>
                    <mat-option value="leftRear">Left rear</mat-option>
                    <mat-option value="left">Left</mat-option>
                    <mat-option value="roof">Roof</mat-option>
                    <mat-option value="windscreen">Windscreen</mat-option>
                </mat-select>

            </mat-form-field>
        </div>
    </div>

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Employee"
                  placeholder="Employee"
                  formControlName='employee'
                  [matAutocomplete]="employeeAuto"
                />
                <mat-hint [hidden]="claimsFormGroup.get('employee')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #employeeAuto="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding employees...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="employees?.length === 0">
                            <span>No employee found</span>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="claimsFormGroup.get('productType').enable()">
                            <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select an <strong>employee</strong> for this claim.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <div class="col-12 clearfix"></div>

    <!-- DATE OF LOSS -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input placeholder="Date of loss" matInput [matDatepicker]="dateOfLoss" name="dateOfLoss"
                    formControlName="dateOfLoss" [max]="getMaxDate()">
                <mat-datepicker-toggle matSuffix [for]="dateOfLoss"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dateOfLoss></mat-datepicker>

                <mat-error>
                    Please select the <strong>date of loss</strong>.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- DATE REPORTED -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input placeholder="Date reported" matInput [matDatepicker]="dateReported" name="dateReported"
                    formControlName="dateReported" [min]="claimsFormGroup.get('dateOfLoss').value">
                <mat-datepicker-toggle matSuffix [for]="dateReported"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dateReported></mat-datepicker>

                <mat-error>
                    Please select the <strong>report date</strong> for this claim.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- CLAIM NUMBER -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input 
                    matInput
                    type="text"
                    placeholder="Claim number"
                    name="claimNumber"
                    formControlName="claimNumber"
                />

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- UNDERWRITERS CLAIM NUMBER -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input 
                    matInput
                    type="text"
                    placeholder="Underwriters claim number"
                    name="underwritersClaimNumber"
                    formControlName="underwritersClaimNumber"
                />

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- EXCESS -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input 
                    matInput
                    type="number"
                    placeholder="Claim excess"
                    name="excess"
                    formControlName="excess"
                />

                <span matPrefix>R</span>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- TOTAL COST -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input 
                    matInput
                    type="number"
                    placeholder="Total cost of claim"
                    name="totalCost"
                    formControlName="totalCost"
                />

                <span matPrefix>R</span>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- CLAIM DESCRIPTION -->
    <div class="col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <textarea 
                    matInput
                    type="text"
                    placeholder="Description of loss"
                    name="claimDescription"
                    formControlName="claimDescription"
                ></textarea>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <ng-container formGroupName="claimSteps">

        <div class="col-12 mb-15" formGroupName="recovery" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'nonMotor'">
            <ng-container formGroupName="recoveryRequired">
                <mat-checkbox [disabled]="displayCompletedBy('recoveryRequired', 'recovery')" disableRipple formControlName="value">The items need to be recovered.</mat-checkbox>
                <small *ngIf="displayCompletedBy('recoveryRequired', 'recovery')" class="text-muted">Completed by: {{ getStepCompletedBy('recoveryRequired', 'recovery') }} </small>
            </ng-container>
        </div>

        <ng-container formGroupName="roadsideAssist" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor'">
            <div class="col-12 mb-15" formGroupName="roadsideAssistRequired">
                <mat-checkbox [disabled]="displayCompletedBy('roadsideAssistRequired')" disableRipple formControlName="value">Is insurance roadside assist required?</mat-checkbox>
                <!-- <small *ngIf="displayCompletedBy('roadsideAssistRequired')" class="text-muted">Completed by: {{ getStepCompletedBy('roadsideAssistRequired') }} </small> -->
            </div>
        </ng-container>

        <ng-container formGroupName="carHire" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor'">
            <div class="col-12 mb-15" formGroupName="requireCarHire">
                <mat-checkbox [disabled]="displayCompletedBy('requireCarHire')" disableRipple formControlName="value">Does the client require car hire?</mat-checkbox>
                <!-- <small *ngIf="displayCompletedBy('requireCarHire')" class="text-muted">Completed by: {{ getStepCompletedBy('requireCarHire') }} </small> -->
            </div>
        </ng-container>

        <ng-container formGroupName="finance" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor' && claimsFormGroup.get('claimType').value === 'totalLoss'">
            <div class="col-12 mb-15" formGroupName="vehicleFinanced">
                <mat-checkbox [disabled]="displayCompletedBy('vehicleFinanced')" disableRipple formControlName="value">The vehicle is currently still being financed.</mat-checkbox>
                <!-- <small *ngIf="displayCompletedBy('vehicleFinanced')" class="text-muted">Completed by: {{ getStepCompletedBy('vehicleFinanced') }} </small> -->
            </div>
        </ng-container>

        <ng-container formGroupName="claimStatus">
            <div class="col-12 col-sm-6 mb-0">
                <mat-label>What is the current status of the claim?</mat-label>
                <mat-select
                    name="claimType"
                    class="form-control"
                    formControlName="value"
                    [ngClass]="{ 'is-invalid': formSubmitted && claimsFormGroup.get('claimSteps').get('claimStatus').errors }"
                >
                    <mat-option value="pending">Pending</mat-option>
                    <mat-option value="approve">Approve</mat-option>
                    <mat-option value="repudiate">Repudiate</mat-option>
                    <mat-option value="withdrawn">Withdrawn</mat-option>
                </mat-select>

            </div>
        </ng-container>

    </ng-container>

</div> <!-- Close div for .row -->

<div class="row claimSteps" [formGroup]="claimsFormGroup">

    <div class="col-12" formGroupName="claimSteps">

        <div class="row">

            <div class="col-12">
                <hr />
                <p><strong>Where are we with this claim?</strong></p>
            </div>

            <div class="col-12 col-ms-6 col-md-4 col-lg-3" formGroupName="claimReceivedVia">
                <div class="input-group">
                    <mat-form-field class="mb-0" appearance="none">
                        <label>How was the claim received from the client?</label>
                        <mat-select
                            name="claimReceivedVia"
                            class="form-control"
                            formControlName="value"
                            [disabled]="displayCompletedBy('claimReceivedVia')"
                            [ngClass]="{ 'is-invalid': formSubmitted && claimsFormGroup['productType'].errors }"
                        >
                            <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                            <mat-option value="email">Email</mat-option>
                            <mat-option value="telephone">Phonecall</mat-option>
                            <mat-option value="whatsapp">Whatsapp</mat-option>
                            <mat-option value="faceToFace">Face to face</mat-option>
                            <mat-option value="fax">Fax</mat-option>
                            <!-- <mat-option value="surespace">SureSpace</mat-option> -->
                        </mat-select>

                        <mat-error>
                            Please select a claim <strong>receive method</strong>.
                        </mat-error>

                        <small *ngIf="displayCompletedBy('claimReceivedVia')" class="text-muted mt-2">Completed by: {{ getStepCompletedBy('claimReceivedVia') }} </small>
        
                    </mat-form-field>

                </div> <!-- Close div for .input-group -->
            </div>

            <div class="col-12" formGroupName="claimFormsSentToClient">
                <mat-checkbox [disabled]="displayCompletedBy('claimFormsSentToClient')"  disableRipple formControlName="value">The claim forms have been sent to the client.</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimFormsSentToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('claimFormsSentToClient') }} </small>
            </div>

            <div class="col-12" formGroupName="clientReceivedClaimForms">
                <mat-checkbox [disabled]="displayCompletedBy('clientReceivedClaimForms')" disableRipple formControlName="value">The client has confirmed receipt of the claim forms.</mat-checkbox>
                <small *ngIf="displayCompletedBy('clientReceivedClaimForms')" class="text-muted">Completed by: {{ getStepCompletedBy('clientReceivedClaimForms') }} </small>
            </div>

            <div class="col-12" formGroupName="advisedInsurersOfPossibleClaim">
                <mat-checkbox [disabled]="displayCompletedBy('advisedInsurersOfPossibleClaim')" disableRipple formControlName="value">All necessary insurance companies have been notified about the possibility of an incoming claim.</mat-checkbox>
                <small *ngIf="displayCompletedBy('advisedInsurersOfPossibleClaim')" class="text-muted">Completed by: {{ getStepCompletedBy('advisedInsurersOfPossibleClaim') }} </small>
            </div>

            <div class="col-12" formGroupName="claimFormsReceivedFromClient">
                <mat-checkbox [disabled]="displayCompletedBy('claimFormsReceivedFromClient')" disableRipple formControlName="value">The client has completed the claim forms and has sent them back?</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimFormsReceivedFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('claimFormsReceivedFromClient') }} </small>
            </div>

            <div class="col-12" formGroupName="claimFormsCompleted">
                <mat-checkbox [disabled]="displayCompletedBy('claimFormsCompleted')" disableRipple formControlName="value">The claim forms have been fully completed and is correct according to required specification.</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimFormsCompleted')" class="text-muted">Completed by: {{ getStepCompletedBy('claimFormsCompleted') }} </small>
            </div>

            <div class="col-12" formGroupName="additionalDocumentationReceived">
                <mat-checkbox [disabled]="displayCompletedBy('additionalDocumentationReceived')" disableRipple formControlName="value">All the additional documentation, required by the claim, have been received from the client.</mat-checkbox>
                <small *ngIf="displayCompletedBy('additionalDocumentationReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('additionalDocumentationReceived') }} </small>
            </div>

            <div class="col-12" formGroupName="documentsUploadedOnSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('documentsUploadedOnSureSpace')" disableRipple formControlName="value">All the documentation, and it's counterparts, have been uploaded and saved to SureSpace?</mat-checkbox>
                <small *ngIf="displayCompletedBy('documentsUploadedOnSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('documentsUploadedOnSureSpace') }} </small>
            </div>

        </div>

        <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('claimStatus').value.value !== 'withdrawn'">

        <div class="row" formGroupName="roadsideAssist" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor'">

            <div class="col-12">
                <hr />
                <p><strong>Roadside assistance?</strong></p>
            </div>

            <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('roadsideAssist').get('roadsideAssistRequired').value.value">

                <div class="col-12" formGroupName="roadsideAssistCompany">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('roadsideAssistCompany')" matInput placeholder="Roadside assist company name" name="roadsideAssistCompany" type="text" formControlName="value" />
                        <small *ngIf="displayCompletedBy('roadsideAssistCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('roadsideAssistCompany') }} </small>
                    </mat-form-field>
                </div>

                <div class="col-12" formGroupName="roadsideAssistTowingRefNo">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('roadsideAssistTowingRefNo')" matInput placeholder="What is the towing reference number for the assist?" name="roadsideAssistTowingRefNo" type="text" formControlName="value" />
                        <small *ngIf="displayCompletedBy('roadsideAssistTowingRefNo')" class="text-muted">Completed by: {{ getStepCompletedBy('roadsideAssistTowingRefNo') }} </small>
                    </mat-form-field>
                </div>

                <div class="col-12" formGroupName="vehicleMovedTo">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('vehicleMovedTo')" matInput placeholder="Where is the vehicle being taken to?" name="vehicleMovedTo" type="text" formControlName="value" />
                        <small *ngIf="displayCompletedBy('vehicleMovedTo')" class="text-muted">Completed by: {{ getStepCompletedBy('vehicleMovedTo') }} </small>
                    </mat-form-field>
                </div>

            </ng-container>

            <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('roadsideAssist').get('roadsideAssistRequired').value.value">

                <div class="col-12 col-sm-6 col-md-4" formGroupName="roadsideAssistDeclineReason">
                    <label>Why is roadside assist not being used?</label>
                    <input [disabled]="displayCompletedBy('roadsideAssistDeclineReason')" class="form-control" name="roadsideAssistDeclineReason" type="text" formControlName="value" />
                    <small *ngIf="displayCompletedBy('roadsideAssistDeclineReason')" class="text-muted">Completed by: {{ getStepCompletedBy('roadsideAssistDeclineReason') }} </small>
                </div>

                <div class="col-12 clearfix mb-0"></div>

                <div class="col-12 col-sm-6 col-md-4" formGroupName="vehicleCurrentLocation">
                    <label>Where is the vehicle currently being stored?</label>
                    <input [disabled]="displayCompletedBy('vehicleCurrentLocation')" class="form-control" name="vehicleCurrentLocation" type="text" formControlName="value" />
                    <small *ngIf="displayCompletedBy('vehicleCurrentLocation')" class="text-muted">Completed by: {{ getStepCompletedBy('vehicleCurrentLocation') }} </small>
                </div>

                <div class="col-12" formGroupName="clientUnderstandsStorageCost">
                    <mat-checkbox [disabled]="displayCompletedBy('clientUnderstandsStorageCost')" disableRipple formControlName="value">Has the client been informed that there might be a high storage cost not covered by the claim?</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientUnderstandsStorageCost')" class="text-muted">Completed by: {{ getStepCompletedBy('clientUnderstandsStorageCost') }} </small>
                </div>

            </ng-container>

        </div>

        <div class="row" formGroupName="carHire" *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor' && claimsFormGroup.get('claimSteps').get('carHire').get('requireCarHire').value.value">

            <div class="col-12">
                <hr />
                <p><strong>Car hire?</strong></p>
            </div>

                <div class="col-12 input-group" formGroupName="carHireDate">
                    <mat-form-field class="mb-0">
                        <input [disabled]="displayCompletedBy('carHireDate', 'carHire')"  placeholder="When would the client like the car hire vehicle to be delivered?" matInput [matDatepicker]="carHireDate" name="carHireDate"
                            formControlName="value" [min]="getMaxDate('carHireDate', 'carHire')">
                        <mat-datepicker-toggle matSuffix [for]="carHireDate"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #carHireDate></mat-datepicker>        
                    </mat-form-field>
                    <small *ngIf="displayCompletedBy('carHireDate', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('carHireDate', 'carHire') }} </small>
                </div> <!-- Close div for .input-group -->

                <div class="col-12" formGroupName="carDeliveryLocation">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('carDeliveryLocation', 'carHire')" matInput placeholder="Where should the vehicle be delivered?" name="carDeliveryLocation" type="text" formControlName="value" />
                    </mat-form-field>
                    <small *ngIf="displayCompletedBy('carDeliveryLocation', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('carDeliveryLocation', 'carHire') }} </small>
                </div>

                <div class="col-12" formGroupName="deliveryArrangementsMadeWithInsurance">
                    <mat-checkbox [disabled]="displayCompletedBy('deliveryArrangementsMadeWithInsurance', 'carHire')" disableRipple formControlName="value">Delivery arrangements have been made with the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('deliveryArrangementsMadeWithInsurance', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('deliveryArrangementsMadeWithInsurance', 'carHire') }} </small>
                </div>

                <div class="col-12" formGroupName="deliveryArrangementsMadeWithClient">
                    <mat-checkbox [disabled]="displayCompletedBy('deliveryArrangementsMadeWithClient', 'carHire')" disableRipple formControlName="value">The client been made aware about the delivery of the vehicle.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('deliveryArrangementsMadeWithClient', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('deliveryArrangementsMadeWithClient', 'carHire') }} </small>
                </div>

                <div class="col-12" formGroupName="clientConfirmDelivery">
                    <mat-checkbox [disabled]="displayCompletedBy('clientConfirmDelivery', carHire)" disableRipple formControlName="value">The client has taken delivery of the vehicle?</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientConfirmDelivery', carHire)" class="text-muted">Completed by: {{ getStepCompletedBy('clientConfirmDelivery', carHire) }} </small>
                </div>

                <div class="col-12" formGroupName="deliveryDate">
                    <div class="input-group">
                        <mat-form-field class="mb-0">
                            <input [disabled]="displayCompletedBy('deliveryDate', 'carHire')" placeholder="What is the date on which the vehicle was delivered?" matInput [matDatepicker]="deliveryDate" name="deliveryDate"
                                formControlName="value" [min]="getMaxDate('deliveryDate', 'carHire')">
                            <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #deliveryDate></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('deliveryDate', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('deliveryDate', 'carHire') }} </small>
                    </div>
                </div>

                <!-- <div class="col-12">
                    <mat-checkbox [disabled]="displayCompletedBy('REPLACE')" disableRipple formControlName="vehicleReturned">The vehicle has been returned in same condition as when it arrived.</mat-checkbox>
                </div> -->

                <div class="col-12" formGroupName="carHireReturnDate">
                    <div class="input-group">
                        <mat-form-field class="mb-0">
                            <input [disabled]="displayCompletedBy('carHireReturnDate', 'carHire')" placeholder="What is the date on which the vehicle was returned?" matInput [matDatepicker]="carHireReturnDate" name="carHireReturnDate"
                                formControlName="value" [min]="getMaxDate('carHireReturnDate', 'carHire')">
                            <mat-datepicker-toggle matSuffix [for]="carHireReturnDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #carHireReturnDate></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('carHireReturnDate', 'carHire')" class="text-muted">Completed by: {{ getStepCompletedBy('carHireReturnDate', 'carHire') }} </small>
                    </div>
                </div>

        </div>

        <div class="row">

            <div class="col-12">
                <hr />
                <p><strong>Claim details?</strong></p>
            </div>

            <div class="col-12" formGroupName="documentsSentToInsurer">
                <mat-checkbox [disabled]="displayCompletedBy('documentsSentToInsurer')" disableRipple formControlName="value">All the required documentation has been sent to the insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('documentsSentToInsurer')" class="text-muted">Completed by: {{ getStepCompletedBy('documentsSentToInsurer') }} </small>
            </div>

            <div class="col-12" formGroupName="insurerReceivedDocuments">
                <mat-checkbox [disabled]="displayCompletedBy('insurerReceivedDocuments')" disableRipple formControlName="value">The insurance companies have received all documentation in order.</mat-checkbox>
                <small *ngIf="displayCompletedBy('insurerReceivedDocuments')" class="text-muted">Completed by: {{ getStepCompletedBy('insurerReceivedDocuments') }} </small>
            </div>

            <div class="col-12" formGroupName="claimNoReceived">
                <mat-checkbox [disabled]="displayCompletedBy('claimNoReceived')" disableRipple formControlName="value" (change)="claimNumberReceived($event)">A reference number for the claim has been sent by the insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimNoReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('claimNoReceived') }} </small>
            </div>

            <div class="col-12" formGroupName="claimNoSentToClient">
                <mat-checkbox [disabled]="displayCompletedBy('claimNoSentToClient')" disableRipple formControlName="value">The client has been informed of the claim number that has been sent by insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimNoSentToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('claimNoSentToClient') }} </small>
            </div>

            <div class="col-12">
                <hr />
                <p><strong>Assessor?</strong></p>
            </div>

            <div class="col-12" formGroupName="assessorAppointed">
                <mat-checkbox [disabled]="displayCompletedBy('assessorAppointed')" disableRipple formControlName="value">An assessor has been appointed to the claim.</mat-checkbox>
                <small *ngIf="displayCompletedBy('assessorAppointed')" class="text-muted">Completed by: {{ getStepCompletedBy('assessorAppointed') }} </small>
            </div>

            <div class="col-12" formGroupName="confirmAssesorWithClient">
                <mat-checkbox [disabled]="displayCompletedBy('confirmAssesorWithClient')" disableRipple formControlName="value">The client has been notified about the assessor who has been appointed to the claim.</mat-checkbox>
                <small *ngIf="displayCompletedBy('confirmAssesorWithClient')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmAssesorWithClient') }} </small>
            </div>

            <div class="col-12" formGroupName="assesorMadeContactWithClient">
                <mat-checkbox [disabled]="displayCompletedBy('assesorMadeContactWithClient')" disableRipple formControlName="value">The assessor has made contact with the client.</mat-checkbox>
                <small *ngIf="displayCompletedBy('assesorMadeContactWithClient')" class="text-muted">Completed by: {{ getStepCompletedBy('assesorMadeContactWithClient') }} </small>
            </div>

            <div class="col-12" formGroupName="assesorBusyWithReport">
                <mat-checkbox [disabled]="displayCompletedBy('assesorBusyWithReport')" disableRipple formControlName="value">The assessor is busy with a report for the claim.</mat-checkbox>
                <small *ngIf="displayCompletedBy('assesorBusyWithReport')" class="text-muted">Completed by: {{ getStepCompletedBy('assesorBusyWithReport') }} </small>
            </div>

            <div class="col-12" formGroupName="assesorReportReceived">
                <mat-checkbox [disabled]="displayCompletedBy('assesorReportReceived')" disableRipple formControlName="value">The report from the assessor has been received.</mat-checkbox>
                <small *ngIf="displayCompletedBy('assesorReportReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('assesorReportReceived') }} </small>
            </div>

            <div class="col-12" formGroupName="assesorReportSavedToSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('assesorReportSavedToSureSpace')" disableRipple formControlName="value">The assessor report has been saved on SureSpace under the claim.</mat-checkbox>
                <small *ngIf="displayCompletedBy('assesorReportSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('assesorReportSavedToSureSpace') }} </small>
            </div>

        </div> <!-- Close div for row -->

        <ng-container *ngIf="claimsFormGroup.get('productTypeDescription').value === 'motor'">

            <div class="row" *ngIf="claimsFormGroup.get('claimSteps').get('claimStatus').value.value === 'approve'">

                <div class="col-12">
                    <hr />
                    <p><strong>Authorisation?</strong></p>
                </div>

                <div class="col-12" formGroupName="repairAuthReceived">
                    <mat-checkbox [disabled]="displayCompletedBy('repairAuthReceived')" disableRipple formControlName="value">The authorization for the repair has been received from the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('repairAuthReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('repairAuthReceived') }} </small>
                </div>

                <div class="col-12" formGroupName="authAndExcessInOrder">
                    <mat-checkbox [disabled]="displayCompletedBy('authAndExcessInOrder')" disableRipple formControlName="value">The authorization and excess received is in order and correct.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('authAndExcessInOrder')" class="text-muted">Completed by: {{ getStepCompletedBy('authAndExcessInOrder') }} </small>
                </div>

                <div class="col-12" formGroupName="repairAuthSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('repairAuthSavedToSureSpace')" disableRipple formControlName="value">The authorization has been uploaded and saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('repairAuthSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('repairAuthSavedToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="confirmRepairsWithClient">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmRepairsWithClient')" disableRipple formControlName="value">Confirmed with client that the authorization has been approved and confirmed the panelbeater details.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmRepairsWithClient')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmRepairsWithClient') }} </small>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3" formGroupName="vehicleRepairDate">
                    <div class="input-group">
                        <label>What is the vehicle repair date?</label><br />
                        <mat-form-field class="mb-0" appearance="outline">
                            <input [disabled]="displayCompletedBy('vehicleRepairDate')" placholder="" matInput [matDatepicker]="vehicleRepairDate" name="vehicleRepairDate"
                                formControlName="value" [min]="getMaxDate('vehicleRepairDate')">
                            <mat-datepicker-toggle matSuffix [for]="vehicleRepairDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #vehicleRepairDate></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('vehicleRepairDate')" class="text-muted">Completed by: {{ getStepCompletedBy('vehicleRepairDate') }} </small>
                    </div>
                </div>

                <div class="col-12" formGroupName="clientReceivedVehicle">
                    <mat-checkbox [disabled]="displayCompletedBy('clientReceivedVehicle')" disableRipple formControlName="value">Confirmed with client that their vehicle has been returned.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientReceivedVehicle')" class="text-muted">Completed by: {{ getStepCompletedBy('clientReceivedVehicle') }} </small>
                </div>

            </div>

            <div class="row" formGroupName="repairs">

                <div class="col-12">
                    <hr />
                    <p><strong>Repairs?</strong></p>
                </div>

                <div class="col-12" formGroupName="clientHappyWithRepairs">
                    <mat-checkbox [disabled]="displayCompletedBy('clientHappyWithRepairs')" disableRipple formControlName="value">The client is happy with the repair done to the vehicle.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientHappyWithRepairs')" class="text-muted">Completed by: {{ getStepCompletedBy('clientHappyWithRepairs') }} </small>
                </div>

                <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('repairs').get('clientHappyWithRepairs').value.value">

                    <div class="col-12" formGroupName="confirmRepairWithInsurer">
                        <mat-checkbox [disabled]="displayCompletedBy('confirmRepairWithInsurer')" disableRipple formControlName="value">Confirmed with the insurance company that the repairs have been finalised and the client is happy.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('confirmRepairWithInsurer')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmRepairWithInsurer') }} </small>
                    </div> 

                </ng-container>

                <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('repairs').get('clientHappyWithRepairs').value.value">

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3" formGroupName="repairNotes">
                        <label>What was the client not happy with?</label>
                        <input class="form-control" [disabled]="displayCompletedBy('repairNotes')" name="repairNotes" type="text" formControlName="value" />
                        <small *ngIf="displayCompletedBy('repairNotes')" class="text-muted">Completed by: {{ getStepCompletedBy('repairNotes') }} </small>
                    </div> 

                    <div class="col-12" formGroupName="requestProofOfPayment">
                        <mat-checkbox [disabled]="displayCompletedBy('requestProofOfPayment')" disableRipple formControlName="value">Requested the proof of payment from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('requestProofOfPayment')" class="text-muted">Completed by: {{ getStepCompletedBy('requestProofOfPayment') }} </small>
                    </div>

                    <div class="col-12" formGroupName="savedProofOfPayment">
                        <mat-checkbox [disabled]="displayCompletedBy('savedProofOfPayment')" disableRipple formControlName="value">The proof of payment has been saved and uploaded to SureSpace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('savedProofOfPayment')" class="text-muted">Completed by: {{ getStepCompletedBy('savedProofOfPayment') }} </small>
                    </div>

                </ng-container>

            </div>

        </ng-container>

        <ng-container *ngIf="claimsFormGroup.get('claimType').value === 'totalLoss' && claimsFormGroup.get('productTypeDescription').value === 'motor'">

            <div class="row" formGroupName="investigator">

                <div class="col-12">
                    <hr />
                    <p><strong>Investigator?</strong></p>
                </div>

                <div class="col-12" formGroupName="investigatorAppointed">
                    <mat-checkbox [disabled]="displayCompletedBy('investigatorAppointed')" disableRipple formControlName="value">An investigator has been appointed to the claim.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('investigatorAppointed')" class="text-muted">Completed by: {{ getStepCompletedBy('investigatorAppointed') }} </small>
                </div>

                <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('investigator').get('investigatorAppointed').value.value">

                    <div class="col-12" formGroupName="confirmInvestigatorToClient">
                        <mat-checkbox [disabled]="displayCompletedBy('confirmInvestigatorToClient')" disableRipple formControlName="value">Confirmed with the client that an investigator has been appointed.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('confirmInvestigatorToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmInvestigatorToClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="investigatorMadeContactWithClient">
                        <mat-checkbox [disabled]="displayCompletedBy('investigatorMadeContactWithClient')" disableRipple formControlName="value">The investigator has made contact with the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('investigatorMadeContactWithClient')" class="text-muted">Completed by: {{ getStepCompletedBy('investigatorMadeContactWithClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="investigatorBusyWithReport">
                        <mat-checkbox [disabled]="displayCompletedBy('investigatorBusyWithReport')" disableRipple formControlName="value">The investigator is busy with the report for the claim.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('investigatorBusyWithReport')" class="text-muted">Completed by: {{ getStepCompletedBy('investigatorBusyWithReport') }} </small>
                    </div>

                    <div class="col-12" formGroupName="investigatorReportReceived">
                        <mat-checkbox [disabled]="displayCompletedBy('investigatorReportReceived')" disableRipple formControlName="value">The investigator report has been received.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('investigatorReportReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('investigatorReportReceived') }} </small>
                    </div>

                </ng-container>

            </div> <!-- Close div for investigator -->

            <div class="row" formGroupName="finance">

                <div class="col-12">
                    <hr />
                    <p><strong>Finance?</strong></p>
                </div>

                <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('finance').get('vehicleFinanced').value.value">

                    <div class="col-12" formGroupName="requestSettlementDocumentsFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('requestSettlementDocumentsFromClient')" disableRipple formControlName="value">The settlement documents have been requested from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('requestSettlementDocumentsFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('requestSettlementDocumentsFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="settlementDocumentsReceivedFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('settlementDocumentsReceivedFromClient')" disableRipple formControlName="value">All settlement documents have been received from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('settlementDocumentsReceivedFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('settlementDocumentsReceivedFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="settlementDocumentsSavedToSureSpace">
                        <mat-checkbox [disabled]="displayCompletedBy('settlementDocumentsSavedToSureSpace')" disableRipple formControlName="value">All settlement documents have been saved to SureSpace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('settlementDocumentsSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('settlementDocumentsSavedToSureSpace') }} </small>
                    </div>

                    <div class="col-12" formGroupName="settlementDocumentsSentToInsuranceCompany">
                        <mat-checkbox [disabled]="displayCompletedBy('settlementDocumentsSentToInsuranceCompany')" disableRipple formControlName="value">All settlement documents have been sent to the insurance company.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('settlementDocumentsSentToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('settlementDocumentsSentToInsuranceCompany') }} </small>
                    </div>

                    <div class="col-12" formGroupName="insuranceCompanyReceivedSettlementDocuments">
                        <mat-checkbox [disabled]="displayCompletedBy('insuranceCompanyReceivedSettlementDocuments')" disableRipple formControlName="value">The insurance company has received all settlement documents.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('insuranceCompanyReceivedSettlementDocuments')" class="text-muted">Completed by: {{ getStepCompletedBy('insuranceCompanyReceivedSettlementDocuments') }} </small>
                    </div>

                </ng-container>

                <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('finance').get('vehicleFinanced').value.value">

                    <div class="col-12" formGroupName="requestChangeOfOwnershipFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('requestChangeOfOwnershipFromClient')" disableRipple formControlName="value">The change of ownership documents have been requested from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('requestChangeOfOwnershipFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('requestChangeOfOwnershipFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="changeOfOwnershipReceivedFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('changeOfOwnershipReceivedFromClient')" disableRipple formControlName="value">All change of ownership documents have been received from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('changeOfOwnershipReceivedFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('changeOfOwnershipReceivedFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="changeOfOwnershipSavedToSureSpace">
                        <mat-checkbox [disabled]="displayCompletedBy('changeOfOwnershipSavedToSureSpace')" disableRipple formControlName="value">All change of ownership documents have been saved to SureSpace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('changeOfOwnershipSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('changeOfOwnershipSavedToSureSpace') }} </small>
                    </div>

                    <div class="col-12" formGroupName="changeOfOwnershipSentToInsuranceCompany">
                        <mat-checkbox [disabled]="displayCompletedBy('changeOfOwnershipSentToInsuranceCompany')" disableRipple formControlName="value">All change of ownership documents have been sent to the insurance company.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('changeOfOwnershipSentToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('changeOfOwnershipSentToInsuranceCompany') }} </small>
                    </div>

                    <div class="col-12" formGroupName="insuranceCompanyReceivedChangeOfOwnership">
                        <mat-checkbox [disabled]="displayCompletedBy('insuranceCompanyReceivedChangeOfOwnership')" disableRipple formControlName="value">The insurance company has received all change of ownership documents.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('insuranceCompanyReceivedChangeOfOwnership')" class="text-muted">Completed by: {{ getStepCompletedBy('insuranceCompanyReceivedChangeOfOwnership') }} </small>
                    </div>

                </ng-container>

            </div>

            <div class="row" formGroupName="agreementOfLoss">

                <div class="col-12">
                    <hr />
                    <p><strong>Agreement of loss?</strong></p>
                </div>

                <div class="col-12" formGroupName="agreementOfLossReceived">
                    <mat-checkbox [disabled]="displayCompletedBy('agreementOfLossReceived')" disableRipple formControlName="value">Agreement of loss has been received from the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('agreementOfLossReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementOfLossReceived') }} </small>
                </div>

                <div class="col-12" formGroupName="agreementOfLossChecked">
                    <mat-checkbox [disabled]="displayCompletedBy('agreementOfLossChecked')" disableRipple formControlName="value">Agreement of loss has been checked and looks to be in correct order.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('agreementOfLossChecked')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementOfLossChecked') }} </small>
                </div>

                <div class="col-12" formGroupName="agreementOfLossSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('agreementOfLossSavedToSureSpace')" disableRipple formControlName="value">Agreement of loss has been uploaded and saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('agreementOfLossSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementOfLossSavedToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="agreementOfLossSendToClient">
                    <mat-checkbox [disabled]="displayCompletedBy('agreementOfLossSendToClient')" disableRipple formControlName="value">Agreement of loss has been sent to the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('agreementOfLossSendToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementOfLossSendToClient') }} </small>
                </div>
                    
                    <div class="col-12" formGroupName="clientAcceptedAgreement">
                        <mat-checkbox [disabled]="displayCompletedBy('clientAcceptedAgreement')" disableRipple formControlName="value">The client has accepted the agreement of loss.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('clientAcceptedAgreement')" class="text-muted">Completed by: {{ getStepCompletedBy('clientAcceptedAgreement') }} </small>
                    </div>

                    <div class="col-12" formGroupName="clientSignedAgreement">
                        <mat-checkbox [disabled]="displayCompletedBy('clientSignedAgreement')" disableRipple formControlName="value">The client has signed and sent back the agreement of loss.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('clientSignedAgreement')" class="text-muted">Completed by: {{ getStepCompletedBy('clientSignedAgreement') }} </small>
                    </div>

                    <div class="col-12" formGroupName="signedAgreementSavedToSureSpace">
                        <mat-checkbox [disabled]="displayCompletedBy('signedAgreementSavedToSureSpace')" disableRipple formControlName="value">The signed agreement of loss has been saved to SureSpace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('signedAgreementSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('signedAgreementSavedToSureSpace') }} </small>
                    </div>

                    <div class="col-12" formGroupName="signedAgreementSentToInsuranceCompany">
                        <mat-checkbox [disabled]="displayCompletedBy('signedAgreementSentToInsuranceCompany')" disableRipple formControlName="value">The signed agreement of loss has been sent to the insurance company for approval.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('signedAgreementSentToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('signedAgreementSentToInsuranceCompany') }} </small>
                    </div>

                    <div class="col-12" formGroupName="confirmReceiptOfSignedAgreementFromInsuranceCompany">
                        <mat-checkbox [disabled]="displayCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany')" disableRipple formControlName="value">The insurance company has received the signed agreement of loss.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany') }} </small>
                    </div>

                    <div class="col-12" formGroupName="requestPaymentDateFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('requestPaymentDateFromClient')" disableRipple formControlName="value">A requested payment date has been received from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('requestPaymentDateFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('requestPaymentDateFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="requestPoPFromClient">
                        <mat-checkbox [disabled]="displayCompletedBy('requestPoPFromClient')" disableRipple formControlName="value">A proof of payment has been requested from the client.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('requestPoPFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('requestPoPFromClient') }} </small>
                    </div>

                    <div class="col-12" formGroupName="confirmWithClientReceiptPoP">
                        <mat-checkbox [disabled]="displayCompletedBy('confirmWithClientReceiptPoP')" disableRipple formControlName="value">Confirm with client that the proof of payment has been received.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('confirmWithClientReceiptPoP')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmWithClientReceiptPoP') }} </small>
                    </div>

                    <div class="col-12" formGroupName="savedPoPToSureSpace">
                        <mat-checkbox [disabled]="displayCompletedBy('savedPoPToSureSpace')" disableRipple formControlName="value">The proof of payment has been saved to SureSpace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('savedPoPToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('savedPoPToSureSpace') }} </small>
                    </div>

            </div>

        </ng-container>

        <div class="row" formGroupName="replacement" *ngIf="claimsFormGroup.get('claimSteps').get('recovery')?.get('settlementMethod')?.value === 'replacement'">

            <div class="col-12">
                <hr />
                <p><strong>Replacement?</strong></p>
            </div>

            <div class="col-12" formGroupName="requestReplacementList">
                <mat-checkbox [disabled]="displayCompletedBy('requestReplacementList')" disableRipple formControlName="value">a Replacement list has been requested from the insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('requestReplacementList')" class="text-muted">Completed by: {{ getStepCompletedBy('requestReplacementList') }} </small>
            </div>

            <div class="col-12" formGroupName="confirmAllItemsOnList">
                <mat-checkbox [disabled]="displayCompletedBy('confirmAllItemsOnList')" disableRipple formControlName="value">The replacement list is correct and contains all claim items.</mat-checkbox>
                <small *ngIf="displayCompletedBy('confirmAllItemsOnList')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmAllItemsOnList') }} </small>
            </div>

            <div class="col-12" formGroupName="sendListToClient">
                <mat-checkbox [disabled]="displayCompletedBy('sendListToClient')" disableRipple formControlName="value">The replacement list has been sent to the client for approval.</mat-checkbox>
                <small *ngIf="displayCompletedBy('sendListToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('sendListToClient') }} </small>
            </div>

            <div class="col-12" formGroupName="saveListToSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('saveListToSureSpace')" disableRipple formControlName="value">The replacement list has been uploaded and saved on SureSpace.</mat-checkbox>
                <small *ngIf="displayCompletedBy('saveListToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('saveListToSureSpace') }} </small>
            </div>

            <div class="col-12" formGroupName="clientAcceptsList">
                <mat-checkbox [disabled]="displayCompletedBy('clientAcceptsList')" disableRipple formControlName="value">Client has accepted the replacement list.</mat-checkbox>
                <small *ngIf="displayCompletedBy('clientAcceptsList')" class="text-muted">Completed by: {{ getStepCompletedBy('clientAcceptsList') }} </small>
            </div>

            <ng-container *ngIf="claimsFormGroup.get('replacement').get('clientAcceptsList').value.value">

                <div class="col-12" formGroupName="insuranceToProceedWithReplacement">
                    <mat-checkbox [disabled]="displayCompletedBy('insuranceToProceedWithReplacement')" disableRipple formControlName="value">The insurance company has been informed that they can continue with the replacement of the items.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('insuranceToProceedWithReplacement')" class="text-muted">Completed by: {{ getStepCompletedBy('insuranceToProceedWithReplacement') }} </small>
                </div>

                <div class="col-12" formGroupName="replacementDate">
                    <div class="input-group">
                        <mat-form-field class="mb-0">
                            <input [disabled]="displayCompletedBy('replacementDate', 'replacement')" placeholder="What is the replacement date for these items?" matInput [matDatepicker]="replacementDate" name="replacementDate"
                                formControlName="value" [min]="getMaxDate('replacementDate', 'replacement')">
                            <mat-datepicker-toggle matSuffix [for]="replacementDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #replacementDate></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('replacementDate', 'replacement')" class="text-muted">Completed by: {{ getStepCompletedBy('replacementDate', 'replacement') }} </small>
                    </div>
                </div>

                <div class="col-12" formGroupName="confirmReplacementDateWithClient">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmReplacementDateWithClient')" disableRipple formControlName="value">The replecement date given by the insurance company has been communicated with the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmReplacementDateWithClient')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmReplacementDateWithClient') }} </small>
                </div>

                <div class="col-12" formGroupName="itemsReceived">
                    <mat-checkbox [disabled]="displayCompletedBy('itemsReceived')" disableRipple formControlName="value">The replecement items have been received by the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('itemsReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('itemsReceived') }} </small>
                </div>

                <div class="col-12" formGroupName="requestItemSerials">
                    <mat-checkbox [disabled]="displayCompletedBy('requestItemSerials')" disableRipple formControlName="value">All electronic items have serials.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('requestItemSerials')" class="text-muted">Completed by: {{ getStepCompletedBy('requestItemSerials') }} </small>
                </div>

            </ng-container>

            <ng-container *ngIf="!claimsFormGroup.get('replacement').get('clientAcceptsList').value.value">
                
                <div class="col-12" formGroupName="rejectListReason">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('rejectListReason')" matInput placeholder="Why was the replacement list rejected by the client?" name="rejectListReason" type="text" formControlName="value" />
                    </mat-form-field>
                    <small *ngIf="displayCompletedBy('rejectListReason')" class="text-muted">Completed by: {{ getStepCompletedBy('rejectListReason') }} </small>
                </div>

                <div class="col-12" formGroupName="rejectionSentToInsurance">
                    <mat-checkbox [disabled]="displayCompletedBy('rejectionSentToInsurance')" disableRipple formControlName="value">The reason for the replacement list being rejected has been sent to the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('rejectionSentToInsurance')" class="text-muted">Completed by: {{ getStepCompletedBy('rejectionSentToInsurance') }} </small>
                </div>

                <div class="col-12" formGroupName="receivedAmendedList">
                    <mat-checkbox [disabled]="displayCompletedBy('receivedAmendedList')" disableRipple formControlName="value">An amended replacement list was received from the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('receivedAmendedList')" class="text-muted">Completed by: {{ getStepCompletedBy('receivedAmendedList') }} </small>
                </div>

                <div class="col-12" formGroupName="savedAmendedListToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('savedAmendedListToSureSpace')" disableRipple formControlName="value">The amended replacement list has been uploaded and saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('savedAmendedListToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('savedAmendedListToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="amendedListSentToClient">
                    <mat-checkbox [disabled]="displayCompletedBy('amendedListSentToClient')" disableRipple formControlName="value">The amended replacement list has been emailed to the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('amendedListSentToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('amendedListSentToClient') }} </small>
                </div>

            </ng-container>

        </div>

        <div class="row" formGroupName="agreementOfLoss" [hidden]="claimsFormGroup.get('claimSteps').get('recovery')?.get('settlementMethod')?.value != 'agreementOfLoss'" *ngIf="claimsFormGroup.get('claimType').value === 'totalLoss'" >

            <div class="col-12">
                <hr />
                <p><strong>Agreement of loss?</strong></p>
            </div>

            <div class="col-12" formGroupName="agreementOfLossReceived">
                <mat-checkbox [disabled]="displayCompletedBy('agreementOfLossReceived')" disableRipple formControlName="value">The client has accepted the agreement of loss.</mat-checkbox>
                <small *ngIf="displayCompletedBy('agreementOfLossReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementOfLossReceived') }} </small>
            </div>

            <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('agreementOfLoss').get('agreementOfLossReceived').value.value">
                
                <div class="col-12" formGroupName="clientSignedAgreement">
                    <mat-checkbox [disabled]="displayCompletedBy('clientSignedAgreement')" disableRipple formControlName="value">Client has sent back a signed agreement of loss.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientSignedAgreement')" class="text-muted">Completed by: {{ getStepCompletedBy('clientSignedAgreement') }} </small>
                </div>

                <div class="col-12" formGroupName="signedAgreementSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('signedAgreementSavedToSureSpace')" disableRipple formControlName="value">The signed agreement has been saved and uploaded to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('signedAgreementSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('signedAgreementSavedToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="signedAgreementSentToInsuranceCompany">
                    <mat-checkbox [disabled]="displayCompletedBy('signedAgreementSentToInsuranceCompany')" disableRipple formControlName="value">The signed agreement has been sent to the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('signedAgreementSentToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('signedAgreementSentToInsuranceCompany') }} </small>
                </div>

                <div class="col-12" formGroupName="confirmReceiptOfSignedAgreementFromInsuranceCompany">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany')" disableRipple formControlName="value">The insurance company has received the signed agreement.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmReceiptOfSignedAgreementFromInsuranceCompany') }} </small>
                </div>

                <div class="col-12" formGroupName="requestPaymentDateFromClient">
                    <div class="input-group">
                        <mat-form-field class="mb-0">
                            <input [disabled]="displayCompletedBy('requestPaymentDateFromClient', 'agreementOfLoss')" placeholder="A payment date has been requested from the client." matInput [matDatepicker]="requestPaymentDateFromClient" name="requestPaymentDateFromClient"
                                formControlName="value" [min]="getMaxDate('requestPaymentDateFromClient', 'agreementOfLoss')">
                            <mat-datepicker-toggle matSuffix [for]="requestPaymentDateFromClient"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #requestPaymentDateFromClient></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('requestPaymentDateFromClient', 'agreementOfLoss')" class="text-muted">Completed by: {{ getStepCompletedBy('requestPaymentDateFromClient', 'agreementOfLoss') }} </small>
                    </div>
                </div>

                <div class="col-12" formGroupName="requestPoPFromClient">
                    <mat-checkbox [disabled]="displayCompletedBy('requestPoPFromClient')" disableRipple formControlName="value">The proof of payment has been requested from the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('requestPoPFromClient')" class="text-muted">Completed by: {{ getStepCompletedBy('requestPoPFromClient') }} </small>
                </div>

                <div class="col-12" formGroupName="savedPoPToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('savedPoPToSureSpace')" disableRipple formControlName="value">The proof of payment has been saved and uploaded to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('savedPoPToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('savedPoPToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="confirmWithClientReceiptPoP">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmWithClientReceiptPoP')" disableRipple formControlName="value">Confirmed with client that the proof of payment has been received.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmWithClientReceiptPoP')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmWithClientReceiptPoP') }} </small>
                </div>

            </ng-container>

            <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('agreementOfLoss').get('agreementOfLossReceived').value.value">

                <div class="col-12" formGroupName="agreementChangesToBeMade">
                    <mat-form-field>
                        <input [disabled]="displayCompletedBy('agreementChangesToBeMade')" matInput placeholder="What changes need to made on the agreement of loss?" name="agreementChangesToBeMade" type="text" formControlName="value" />
                    </mat-form-field>
                    <small *ngIf="displayCompletedBy('agreementChangesToBeMade')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementChangesToBeMade') }} </small>
                </div>

                <div class="col-12" formGroupName="sentChangesToInsuranceCompany">
                    <mat-checkbox [disabled]="displayCompletedBy('sentChangesToInsuranceCompany')" disableRipple formControlName="value">Client requested changes have been sent to the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('sentChangesToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('sentChangesToInsuranceCompany') }} </small>
                </div>

                <div class="col-12" formGroupName="confirmReceiptOfChangesByInsuranceCompany">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmReceiptOfChangesByInsuranceCompany')" disableRipple formControlName="value">Insurance company has received the changes the client has requested.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmReceiptOfChangesByInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmReceiptOfChangesByInsuranceCompany') }} </small>
                </div>

                <div class="col-12" formGroupName="receivedNewAgreement">
                    <mat-checkbox [disabled]="displayCompletedBy('receivedNewAgreement')" disableRipple formControlName="value">A new agreement of loss has been received from the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('receivedNewAgreement')" class="text-muted">Completed by: {{ getStepCompletedBy('receivedNewAgreement') }} </small>
                </div>

                <div class="col-12" formGroupName="agreementInOrder">
                    <mat-checkbox [disabled]="displayCompletedBy('agreementInOrder')" disableRipple formControlName="value">The new agreement seems to be in order with the client request.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('agreementInOrder')" class="text-muted">Completed by: {{ getStepCompletedBy('agreementInOrder') }} </small>
                </div>

                <div class="col-12" formGroupName="newAgreementSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('newAgreementSavedToSureSpace')" disableRipple formControlName="value">The new agreement has been saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('newAgreementSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('newAgreementSavedToSureSpace') }} </small>
                </div>

                <div class="col-12" formGroupName="sentAgreementToClient">
                    <mat-checkbox [disabled]="displayCompletedBy('sentAgreementToClient')" disableRipple formControlName="value">The new agreement has been sent to the client for approval.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('sentAgreementToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('sentAgreementToClient') }} </small>
                </div>

            </ng-container>

        </div> <!-- Close div for agreement of loss -->

        <div class="row" formGroupName="repudiation" *ngIf="claimsFormGroup.get('claimSteps').get('claimStatus').value.value == 'repudiate'">

            <div class="col-12">
                <hr />
                <p><strong>Repudiation?</strong></p>
            </div>

            <div class="col-12" formGroupName="clientAcceptsRepudiation">
                <mat-checkbox [disabled]="displayCompletedBy('clientAcceptsRepudiation')" disableRipple formControlName="value">The client has accepted the repudiation.</mat-checkbox>
                <small *ngIf="displayCompletedBy('clientAcceptsRepudiation')" class="text-muted">Completed by: {{ getStepCompletedBy('clientAcceptsRepudiation') }} </small>
            </div>

            <ng-container *ngIf="claimsFormGroup.get('claimSteps').get('repudiation').get('clientAcceptsRepudiation').value.value">

                <div class="col-12" formGroupName="confirmationSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmationSavedToSureSpace')" disableRipple formControlName="value">The client confirmation has been saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmationSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmationSavedToSureSpace') }} </small>
                </div>

            </ng-container>

            <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('repudiation').get('clientAcceptsRepudiation').value.value">

                <div class="col-12" formGroupName="rejectionSavedToSureSpace">
                    <mat-checkbox [disabled]="displayCompletedBy('rejectionSavedToSureSpace')" disableRipple formControlName="value">The client rejection has been saved to SureSpace.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('rejectionSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('rejectionSavedToSureSpace') }} </small>
                </div>
                
                <div class="col-12" formGroupName="clientRequestNegotiation">
                    <mat-checkbox [disabled]="displayCompletedBy('clientRequestNegotiation')" disableRipple formControlName="value">The client has requested a negotiation on the terms.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientRequestNegotiation')" class="text-muted">Completed by: {{ getStepCompletedBy('clientRequestNegotiation') }} </small>
                </div>

                <div class="col-12" formGroupName="sendNegotiationToInsurance">
                    <mat-checkbox [disabled]="displayCompletedBy('sendNegotiationToInsurance')" disableRipple formControlName="value">The client negotiation terms have been sent to the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('sendNegotiationToInsurance')" class="text-muted">Completed by: {{ getStepCompletedBy('sendNegotiationToInsurance') }} </small>
                </div>

                <div class="col-12" formGroupName="receiveNegotiationOutcome">
                    <mat-checkbox [disabled]="displayCompletedBy('receiveNegotiationOutcome')" disableRipple formControlName="value">The outcome of the negotiations have been received from the insurance company.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('receiveNegotiationOutcome')" class="text-muted">Completed by: {{ getStepCompletedBy('receiveNegotiationOutcome') }} </small>
                </div>

                <div class="col-12" formGroupName="sentOutcomeToClient">
                    <mat-checkbox [disabled]="displayCompletedBy('sentOutcomeToClient')" disableRipple formControlName="value">The outcome from the insurance company has been sent to the client.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('sentOutcomeToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('sentOutcomeToClient') }} </small>
                </div>

                <ng-container formGroupName="outcome">

                    <div class="col-12">
                        <hr />
                        <p><strong>Outcome?</strong></p>
                    </div>

                    <div class="col-12" formGroupName="outcomeSavedToSureSpace">
                        <mat-checkbox [disabled]="displayCompletedBy('outcomeSavedToSureSpace')" disableRipple formControlName="value">The outcome has been saved to Surespace.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('outcomeSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('outcomeSavedToSureSpace') }} </small>
                    </div>

                    <div class="col-12" formGroupName="clientAcceptsOutcome">
                        <mat-checkbox [disabled]="displayCompletedBy('clientAcceptsOutcome')" disableRipple formControlName="value">The client accepts the outcome sent forth by the insurance company.</mat-checkbox>
                        <small *ngIf="displayCompletedBy('clientAcceptsOutcome')" class="text-muted">Completed by: {{ getStepCompletedBy('clientAcceptsOutcome') }} </small>
                    </div>
            
                    <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('repudiation').get('outcome').get('clientAcceptsOutcome').value">
                    
                        <div class="col-12" formGroupName="adviseToSubmitToOmbud">
                            <mat-checkbox [disabled]="displayCompletedBy('adviseToSubmitToOmbud')" disableRipple formControlName="value">Has the client been advised that they can send a claim to the ombud?</mat-checkbox>
                            <small *ngIf="displayCompletedBy('adviseToSubmitToOmbud')" class="text-muted">Completed by: {{ getStepCompletedBy('adviseToSubmitToOmbud') }} </small>
                        </div>

                        <div class="col-12" formGroupName="provideOmbudDetails">
                            <mat-checkbox [disabled]="displayCompletedBy('provideOmbudDetails')" disableRipple formControlName="value">Has the ombud details been provided to the client?</mat-checkbox>
                            <small *ngIf="displayCompletedBy('provideOmbudDetails')" class="text-muted">Completed by: {{ getStepCompletedBy('provideOmbudDetails') }} </small>
                        </div>

                    </ng-container>

                </ng-container> <!-- Close div for outcome -->

                <ng-container formGroupName="ombud">

                    <div class="col-12">
                        <hr />
                        <p><strong>Ombud?</strong></p>
                    </div>

                    <div class="col-12" formGroupName="clientSubmitToOmbud">
                        <mat-checkbox [disabled]="displayCompletedBy('clientSubmitToOmbud')" disableRipple formControlName="value">Has the client submitted a claim to the ombud?</mat-checkbox>
                        <small *ngIf="displayCompletedBy('clientSubmitToOmbud')" class="text-muted">Completed by: {{ getStepCompletedBy('clientSubmitToOmbud') }} </small>
                    </div>

                    <ng-container *ngIf="!claimsFormGroup.get('claimSteps').get('repudiation').get('ombud').get('clientSubmitToOmbud').value.value">

                        <div class="col-12 col-sm-6 col-md-4" formGroupName="sumbittedDate">
                            <div class="input-group">
                                <label>When was the claim submitted to the ombud?</label>
                                <mat-form-field class="mb-0" appearance="outline">
                                    <input [disabled]="displayCompletedBy('sumbittedDate')" matInput [matDatepicker]="sumbittedDate" name="requestPaymentDateFromClient"
                                        formControlName="value" [max]="getMaxDate()">
                                    <mat-datepicker-toggle matSuffix [for]="sumbittedDate"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #sumbittedDate></mat-datepicker>        
                                </mat-form-field>
                                <small *ngIf="displayCompletedBy('sumbittedDate')" class="text-muted">Completed by: {{ getStepCompletedBy('sumbittedDate') }} </small>
                            </div>
                        </div>

                        <div class="col-12" formGroupName="ombudAcceptedClaim">
                            <mat-checkbox [disabled]="displayCompletedBy('ombudAcceptedClaim')" disableRipple formControlName="value">Has the ombud accepted the claim?</mat-checkbox>
                            <small *ngIf="displayCompletedBy('ombudAcceptedClaim')" class="text-muted">Completed by: {{ getStepCompletedBy('ombudAcceptedClaim') }} </small>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" formGroupName="ombudRefNr">
                            <label>What is the ombud reference number?</label>
                            <input class="form-control" [disabled]="displayCompletedBy('ombudRefNr')" name="ombudRefNr" type="text" formControlName="value" />
                            <small *ngIf="displayCompletedBy('ombudRefNr')" class="text-muted">Completed by: {{ getStepCompletedBy('ombudRefNr') }} </small>
                        </div> 

                    </ng-container>

                </ng-container> <!-- Close row for ombud-->
                
            </ng-container>

        </div> <!-- Close div for repudiation -->

    </ng-container>

        <div class="row">

            <div class="col-12">
                <hr />
                <p><strong>Finalise claim?</strong></p>
            </div>

            <div class="col-12" formGroupName="claimFinalised">
                <mat-checkbox [disabled]="displayCompletedBy('claimFinalised')" disableRipple formControlName="value">This claim has been finalised?</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimFinalised')" class="text-muted">Completed by: {{ getStepCompletedBy('claimFinalised') }} </small>
            </div>
        </div>

        <div class="row" *ngIf="canCompleteClaim()">
            <div class="col-12" formGroupName="claimSignedOff">
                <mat-checkbox [disabled]="displayCompletedBy('claimSignedOff')" disableRipple formControlName="value">This claim has been completed and signed off?</mat-checkbox>
                <small *ngIf="displayCompletedBy('claimSignedOff')" class="text-muted">Completed by: {{ getStepCompletedBy('claimSignedOff') }} </small>
            </div>
        </div>

    </div> <!-- Close div for claimSteps -->

</div>