<div id="content" class="row">

    <sub-navigation heading="{{ editHome ? 'Edit' : 'Add new' }} home"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="homeFormGroup" [formGroup]="homeFormGroup">

              <mat-horizontal-stepper linear #stepper>

                <mat-step>
                  <ng-template matStepLabel>Address</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="mat-stepper-body row" formGroupName="address">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Add home address</strong></h5>
                        </div>

                        <div class="col-12">
                          <label for="addressLine1">Address</label>
                        </div>

                        <div class="col-12">
                          <input
                            ngx-google-places-autocomplete
                            #placesRef="ngx-places"
                            (onAddressChange)="handleAddressChange($event)"
                            formControlName="addressLine1"
                            [options]="addressOptions"
                            class="form-control"
                          />
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <!-- <div class="col-12">
                          <label for="addressLine1">Address Line 1</label>
                        </div>

                        <div class="col-12">
                          <mat-form-field>
                            <input 
                                matInput
                                name="addressLine1"
                                formControlName="addressLine1"
                                placeholder="Address line 1"
                            >

                            <mat-error>
                                Please provide the <strong>home address</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12">
                          <label for="addressLine2">Address Line 2</label>
                        </div>

                        <div class="col-12">
                            <input 
                              class="form-control"
                              name="addressLine2"
                              formControlName="addressLine2"
                              placeholder="Address line 2"
                            >
                        </div>

                        <div class="col-12 clearfix"></div> -->

                        <div class="col-12 col-md-6">

                          <div class="row">

                            <div class="col-12">
                              <label for="suburb">Suburb</label>
                            </div>
        
                            <div class="col-12">
                              <input 
                                class="form-control"
                                name="suburb"
                                formControlName="suburb"
                                placeholder="Suburb"
                              >
                            </div>

                          </div>

                        </div>

                        <div class="col-12 clearfix mb-3 d-block d-md-none"></div>

                        <div class="col-12 col-md-6">

                          <div class="row">

                            <div class="col-12">
                              <label for="city">City</label>
                            </div>
        
                            <div class="col-12">
                              <input 
                                class="form-control"
                                name="city"
                                formControlName="city"
                                placeholder="City"
                              >
                            </div>

                          </div>

                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-md-6">

                          <div class="row">

                            <div class="col-12">
                              <label for="postalCode">Postal Code</label>
                            </div>
        
                            <div class="col-12">
                              <input 
                                class="form-control"
                                name="postalCode"
                                formControlName="postalCode"
                                placeholder="Postal Code"
                              >
                            </div>

                          </div>

                        </div>

                      </div> <!-- Close div for .row-->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-bg btn-outline-secondary" (click)="location.back()">Cancel</button>
                        <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                      </div>

                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>

                  <ng-template matStepLabel>Details</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Describe the property </strong></h5>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Type of building</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="residenceType">
                                <mat-option value="house">House</mat-option>
                                <mat-option value="townhouse">Townhouse</mat-option>
                                <mat-option value="cottageAttached">Cottage-attached</mat-option>
                                <mat-option value="cottageNotAttached">Cottage-not attached</mat-option>
                                <mat-option value="cluster">Cluster</mat-option>
                                <mat-option value="flatAboveGround">Flat above ground</mat-option>
                                <mat-option value="flatOnGround">Flat on ground</mat-option>
                                <mat-option value="outbuilding">Outbuilding</mat-option>
                                <mat-option value="storage">Storage facility</mat-option>
                                <mat-option value="garage">Park home / Caravan</mat-option>
                                <mat-option value="other">Other</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is this a primary home?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="isPrimaryResidence">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is this a holiday home?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="isHolidayHome">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">How old is the building?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="buildingAge">
                                <mat-option value="0-5">0-5 years</mat-option>
                                <mat-option value="6-10">6-10 years</mat-option>
                                <mat-option value="11-25">11-25 years</mat-option>
                                <mat-option value="25+">Older than 25 years</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is there a thatched lapa on the property?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group" formGroupName="thatch">
                            <mat-form-field>
                              <mat-select formControlName="hasThatch">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <ng-container formGroupName="thatch" *ngIf="homeFormGroup.get('thatch').get('hasThatch').value">

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the thatch closer than 5 meters to the main house?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="thatchWithin5Meters">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the thatch treated?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="treated">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div> <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is there a SABS approved lightning conductor installed?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="lightningConductorInstalled">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                        </ng-container>
                        
                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Does the property have a swimming pool?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="swimmingPool">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Does this property have an electrically operated gate motor?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="gateMotor">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is the building unoccupied for more than 60 consecutive days per annum?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="occupiedDuringDay">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is the property a commune?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="isCommune">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                      </div> <!-- Close div for row -->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-outline-secondary btn-bg" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                      </div>
                    
                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>Building</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Describe the home construction</strong></h5>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is the building currently under construction?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="underConstruction">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What are the walls of the building made of?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="externalWalls">
                                <mat-option *ngFor="let wall of wallMaterial" [value]="wall">
                                  {{ wall | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What type of roof is on the building?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="roof">
                                <mat-option *ngFor="let roof of roofMaterial" [value]="roof">
                                  {{ roof | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What type of flooring is in the building?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="floor">
                                <mat-option *ngFor="let floor of floorMaterial" [value]="floor">
                                  {{ floor | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Surrounding area type?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="areaType">
                                <mat-option value="farm">Farm</mat-option>
                                <mat-option value="plot">Plot</mat-option>
                                <mat-option value="golfEstate">Golf estate</mat-option>
                                <mat-option value="countryEstate">Country estate / Residential estate</mat-option>
                                <mat-option value="securityComplex">Security complex</mat-option>
                                <mat-option value="complex">Complex with no access control</mat-option>
                                <mat-option value="boomedOffSuburb">Boomed-off suburb</mat-option>
                                <mat-option value="suburb">Suburb</mat-option>
                                <mat-option value="industrialArea">Industrial area</mat-option>
                                <mat-option value="commercialArea">Commercial area</mat-option>
                                <mat-option value="cbd">Inner city / CBD</mat-option>
                                <mat-option value="informalSettlement">Informal settlement</mat-option>
                                <mat-option value="retirementVillage">Retirement village</mat-option>
                                <mat-option value="natureReserve">Nature reserve</mat-option>
                                <mat-option value="holidayResort">Holiday resort</mat-option>
                                <mat-option value="school">School / University</mat-option>
                                <mat-option value="other">Other</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <ng-container formGroupName="environment">

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the building built on a steep slope?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="Built on steep slope" formControlName="builtOnSteepSlope">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Any excessive use of retaining walls?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="Excessive use of retaining walls" formControlName="excessiveRetainingWalls">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Less that 100m from the sea, lagoon, river, natural stream, dam or swamp?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="Close to water source" formControlName="closeToWater">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Any visible signs of rising damp in the walls?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="Visible signs of rising damp in the walls" formControlName="wallDamp">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Any visible signs of cracks in the walls?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="Visible signs of cracks in the walls" formControlName="crackedWalls">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is there any history of subsidence or landslip in the area?</label>
                          </div>
      
                          <div class="col-12 col-sm-5 col-md-4" >
                            <mat-radio-group aria-label="History of subsidence or landslip in the area" formControlName="subsidence">
                              <mat-radio-button class="mr-5" [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                          </div>

                        </ng-container>

                      </div> <!-- Close div for .row -->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-bg mr-0 float-right" matStepperNext>Next Step</button>
                        <!-- <button *ngIf="isQuoteProduct" class="btn btn-success btn-bg float-right mr-0" (click)="editHome ? updateStructure() : addNewStructure()">Finish</button> -->
                      </div>

                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>Insurance</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body" formGroupName="insurance">

                        <div class="col-12">
                          <h5 class="mb-5"><strong>Add insurance to home</strong></h5>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="insuredValue">Insured value</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="insuredValue"
                                formControlName="insuredValue"
                                placeholder="Insured value"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>insured value</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <ng-container *ngIf="!isQuoteProduct">

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="premium">Premium</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="premium"
                                formControlName="premium"
                                placeholder="Premium"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>premium</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="policy">Policy number</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <div class="input-group">
                            <mat-form-field>
                              <mat-select formControlName="policyNumber">
                                <mat-option disabled *ngIf="policies?.length === 0 && homeFormGroup.get('insurance').get('policyNumber').value">
                                  <span>No policy found</span>
                              </mat-option>
                              <mat-option class="px-0 py-0" style="height: unset" *ngIf="policies?.length === 0 && homeFormGroup.get('insurance').get('policyNumber').value">
                                  <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                              </mat-option>
                                <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                  <span>{{ policy?.policyNumber }}</span>
                              </mat-option>
                              </mat-select>

                              <mat-error>
                                Please select a <strong>policy number</strong> for this structure.
                              </mat-error>

                            </mat-form-field>
                          </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="inceptionDate">Structure inception date</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="inceptionDate"
                                [matDatepicker]="inceptionDate"
                                name="inceptionDate"
                                formControlName="inceptionDate"
                            >
                            <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #inceptionDate></mat-datepicker>
      
                            <mat-error>
                                Please provide the <strong>home inception date</strong>.
                            </mat-error>
      
                        </mat-form-field>
                        </div>

                        </ng-container>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="coverType">Cover type</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <mat-select multiple formControlName="coverType">
                              <mat-option value="comprehensive">Comprehensive</mat-option>
                              <mat-option value="theftCoverExcluding">Theft Cover Excluding</mat-option>
                              <mat-option value="floodCoverExcluding">Flood Cover Excluding</mat-option>
                              <mat-option value="Advensure">Advensure</mat-option>
                            </mat-select>

                            <mat-error>
                                Please provide the <strong>cover type</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="excess">Excess</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="excess"
                                formControlName="excess"
                                placeholder="Excess"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>excess amount</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="accidentalDamage">Accidental damage</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="accidentalDamage"
                                formControlName="accidentalDamage"
                                placeholder="Accidental damage"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>accidental damage</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="powerSurge">Power surge</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="powerSurge"
                                formControlName="powerSurge"
                                placeholder="Power Surge"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>power surge</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                      </div> <!-- Close div for .row-->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                        <button *ngIf="!view && canViewItem" class="btn btn-success btn-bg float-right mr-0" (click)="editHome ? updateStructure() : addNewStructure()">Finish</button>
                      </div>

                    </div>
                  
                  </div> <!-- Close div for .row -->

                </mat-step>
                
              </mat-horizontal-stepper>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->