import { HttpResponse } from '@angular/common/http';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SEOService } from 'src/app/services/seo.service';
import { Notifications, NotificationResponse } from '../_shared/models/notifications.model';
// import { UserRequest, UserRequestResponse } from 'src/app/_shared/models/request.model';
import { UserRequest, UserRequestResponse } from 'src/app/_shared/models/userRequest.interface';
import { BrokerEmailService } from '../services/broker-email.service';
import { Router } from '@angular/router';
import { RequestService } from '../services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { BrokerService } from 'src/app/services/broker.service';
import { RejectInstructionModalComponent } from '../components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-broker-component',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss'],
  providers: [RejectInstructionModalComponent]
})
export class BrokerComponent implements OnInit, AfterViewInit, OnChanges {

  messages: any = ['hello'];

  notifications: Notifications[];
  newNotifications: number;

  requests: UserRequest;

  userRequests: UserRequest[];

  currentUser = this.authenticationService.currentUserValue;

  // TODO: ADD THE ABILITY FOR USERS TO HAVE MULTIPLE COMPANIES
  brokerCompany = this.authenticationService.currentUserValue.company[0] ;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public globals: Globals,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService,
    public brokerEmailService: BrokerEmailService,
    private router: Router,
    public dialog: MatDialog,
    private requestService: RequestService,
    private rejectInstructionModal: RejectInstructionModalComponent,
    private notificationService: NotificationService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.getLoggedinUser();

    this.authenticationService.currentUser.subscribe(token => {
      this.authenticated = !!token;  
    })
  }

  title = this.globals.TITLE;
  authenticated = this.globals.AUTENTICATED;
  me = this.globals.ME;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  onActivate(event) {
    window.scroll(0,0);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getLoggedinUser(){
    const token = this.authenticationService.getToken();
    if(token){
      const tokenExpirationDate = this.authenticationService.getTokenExpirationDate(token)
    }
    

    // if(this.authenticated){
    //   this.authService.getLoggedinUser().subscribe(
    //     (response:HttpResponse<any>) => {
    //       this.globals.ME = response.body.user;
    //       localStorage.setItem('userToken', response.body.user.token);
    //       // localStorage.setItem('user', JSON.stringify(response.body.user));
    //       // this.me = response.body.user;
    //     },
    //     (error: HttpErrorResponse) => {
    //         console.log('error -> ', error)
    //     }
    //   );
    // };
  }

  getNotifications(){
    this.brokerService.getNotifications().subscribe(
      (response: HttpResponse<NotificationResponse>) => {
        this.notifications = response.body.notifications;
        this.newNotifications = response.body.newNotifications
      }
    )
  }

  getUserRequests(){
    this.requestService.getUserRequests(this.currentUser._id, this.brokerCompany.companyName).subscribe(
      (response: HttpResponse<UserRequestResponse>) => {
        console.log('requests => ', response.body.requests)
        this.userRequests = response.body.requests;
      }
    )
  }

  handleRequest(request: any){
    switch(request.productType){
      case 'Instruction':
        this.router.navigate([`/b/instructions/edit/${request.productRef}`], { queryParams: { request: request._id } })
        break;
      
      case 'Claim':
        this.router.navigate([`/b/claims/edit/${request.productRef}`], { queryParams: { request: request._id } })
        break;
    }
  }

  ngAfterViewInit(){
    this.getNotifications();
    this.getUserRequests();
  }

  ngOnInit(){
  }
  
  ngOnChanges(changes: SimpleChanges): void{
    console.log('the change is now => ', changes)
  }

  notificationDropdownStatus: string = "ddClosed";
  onNotificationMenuOpenChange(isOpen: boolean):void {
    isOpen ? this.notificationDropdownStatus = 'ddNotifyOpened' : this.notificationDropdownStatus = "ddClosed";
  }

  ddStatus: string = "ddClosed";
  dropDownClicked() {
    (this.ddStatus == 'ddClosed') ? this.ddStatus = 'ddOpened' : this.ddStatus = "ddClosed";
  }

  sendToEmail: string = '';
  toggleSendingEmail(clientId: string, ...opts){

    opts = opts[0] || {};

    this.sendToEmail = opts['to'];
    this.brokerEmailService.clientId = clientId;
    this.brokerEmailService.emailAdress = opts['to'];
    this.brokerEmailService.emailOptions = opts
    this.brokerEmailService.composingNewEmail = !this.brokerEmailService.composingNewEmail
  }

  acceptRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.userRequests = this.userRequests.filter(r => r._id !== requestId)
      }
    )
  }

  rejectRequest(request){
    this.rejectInstructionModal.openDialog({ request }, request.productType.toLowerCase());
    this.rejectInstructionModal.dialogRef.afterClosed().subscribe(response => {
      if(response === 'success'){
        this.userRequests = this.userRequests.filter(r => r._id !== request._id)
      }
    })
  }

  notificationClick(notification){
    let notificationIndex = this.notifications.findIndex(n => n._id === notification._id)
    if(!notification.isSeen){
      this.notificationService.markNotifcationAsRead(notification._id).subscribe(() => {
        // this.notifications = this.notifications.filter(notify => notify._id !== notification._id)
        let newNotification = notification;
        newNotification.isSeen = true;
        this.notifications[notificationIndex] = newNotification;
        this.newNotifications = this.newNotifications - 1;
      });
    }

    let navigationRoute = '';

    switch(notification.category.toLowerCase()){

      case 'instruction':
        navigationRoute = '/b/instructions/edit'

    }

    this.router.navigate([navigationRoute, notification.itemRef])
  }

  hasPermissions(permission:string){
    let currentCompany = this.currentUser.company.find(c => c.companyName === this.authenticationService.currentCompany.companyName);
    return currentCompany.permissions.includes(permission);
  }

}
