import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { InvestmentService } from 'src/app/services/investment.service';
import { ActivatedRoute } from '@angular/router';
import { Investment, InvestmentResponse } from 'src/app/_shared/models/investment.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeleteInvestmentComponent } from 'src/app/components/modal/delete/delete-investment/delete-investment.component';

@Component({
  selector: 'client-investments',
  templateUrl: './client-investments.component.html',
  styleUrls: ['./client-investments.component.scss'],
  providers: [DeleteInvestmentComponent]
})
export class ClientInvestmentsComponent implements OnInit {

  user = this.activeRoute.snapshot.paramMap.get('id');
  investments: Investment[];

  displayedColumns: string[] = ['policyNumber', 'type', 'investmentCompany', 'investmentDate', 'investedAmount', 'currentValue', 'calculationDate', 'actions'];
  resultsLength = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private investmentService: InvestmentService,
    private activeRoute: ActivatedRoute,
    private deleteInvestment: DeleteInvestmentComponent
  ) { }

  getInvestments() {
    this.investmentService.getAllInvestments(this.user).subscribe(
      (response:HttpResponse<InvestmentResponse>) => {
        this.investments = response.body.investment;
      }
    );
  }

  removeInvestment(investment:Investment) {
    this.deleteInvestment.openDialog(investment);
    this.deleteInvestment.dialogRef.afterClosed().subscribe(response => {
      if(response && response === 'success'){
        this.getInvestments()
      }
    })
  }

  calculateCalculationDate(date, calculationPeriod){
    calculationPeriod = calculationPeriod || 0
    date = new Date(date);
    date.setMonth(date.getMonth() + calculationPeriod)
    return date
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getInvestments();
  }

}
