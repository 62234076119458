import { IdNumberVerifyService } from './../../services/id-number-verify.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-id-number-tester',
  templateUrl: './id-number-tester.component.html',
  styleUrls: ['./id-number-tester.component.scss']
})
export class IdNumberTesterComponent implements OnInit {

  formGroup: FormGroup;
  testingId: boolean = false;
  idNumberValidation: any;

  constructor(
    private formBuilder: FormBuilder,
    private verifyIdService: IdNumberVerifyService
  ) {

    this.formGroup = this.formBuilder.group({
      idNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]]
    })

  }

  ngOnInit(): void {
  }

  idValidator = (field:FormGroup) => {
    const condition = this.verifyIdService.validateIdNumber(field.value)
    const isValid = condition && condition.isValid;

    return !isValid ? { invalidIdNumber: true } : null;
  }

  validateIdNumber(){
    this.testingId = true;
    this.idNumberValidation = this.verifyIdService.validateIdNumber(this.formGroup.get('idNumber').value)
  }

}
