import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentModel } from 'src/app/_shared/models/document.model';

@Component({
  selector: 'app-broker-display-client-document-modal',
  templateUrl: './broker-display-client-document-modal.component.html',
  styleUrls: ['./broker-display-client-document-modal.component.scss']
})
export class BrokerDisplayClientDocumentModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  downloadDocument(){
    console.log('data => ', this.data.document)
    const blob = new Blob([this.data.document.documentFile.replace(/'%20'/g, '+')], { type: 'application/pdf' });
    // const blob = new Blob([this.data.document.documentFile], { type: 'application/octet-stream' });
    const fileUrl = window.URL.createObjectURL(blob)

    const downloadLink = document.createElement("a");
    const fileName = this.data.document.documentName;

    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

}
