import { AuthenticationService } from './../../../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllRiskItem, AllRiskItemResponse } from 'src/app/_shared/models/allRiskItem.model';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { HttpResponse } from '@angular/common/http';
import { DeletePersonalItemComponent } from 'src/app/components/modal/delete-personal-item/delete-personal-item.component';

@Component({
  selector: 'app-broker-view-user-all-risk-insurance',
  templateUrl: './broker-view-user-all-risk-insurance.component.html',
  styleUrls: ['./broker-view-user-all-risk-insurance.component.scss'],
  providers: [BrokerComponent, DeletePersonalItemComponent]
})
export class BrokerViewUserAllRiskInsuranceComponent implements OnInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userAllRiskItems: AllRiskItem[] = [];
  previouslyInsuredUserAllRiskItems: AllRiskItem[] = [];

  displayedColumns: string[] = ['itemName', 'specified', 'insuredValue', 'inceptionDate', 'actions'];
  displayedColumnsPrevious: string[] = ['itemName', 'specified', 'insuredValue', 'terminationDate', 'actions'];
  resultsLength = 0;
  pageSize = 25;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  resultsLengthPreviousInsurance = 0;
  pageSizePreviousInsurance = 25;
  isRateLimitReachedPreviousInsurance: boolean = false;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private clientAllRiskService: BrokerClientAllRiskServiceService,
    private brokerComponent: BrokerComponent,
    private deleteAllRiskItemModal: DeletePersonalItemComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getUserAllRiskItems(event?:any){

    this.isLoadingResults = true;

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReached){
      this.clientAllRiskService.getAllAllRiskInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<AllRiskItemResponse>) => {
          this.isLoadingResults = false;
          this.userAllRiskItems = response.body.item;

          this.resultsLength = response.body.count;
          if(this.userAllRiskItems.length < this.pageSize){
            this.isRateLimitReached = true;
          }
        }
      )
    }
  };

  getUserPreviousInsuredAllRisk(event?:any){

    this.isLoadingResults = true;

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReachedPreviousInsurance){
      this.clientAllRiskService.getPreviousAllRiskInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<AllRiskItemResponse>) => {

          this.isLoadingResults = false;
          this.previouslyInsuredUserAllRiskItems = response.body.item;

          this.resultsLengthPreviousInsurance = response.body.count;
          if(this.previouslyInsuredUserAllRiskItems.length < this.pageSize){
            this.isRateLimitReachedPreviousInsurance = true;
          }
        }
      )
    }
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserAllRiskItems();
    this.getUserPreviousInsuredAllRisk();
  }

  deleteAllRiskItemInsurance(allRiskItem:AllRiskItem){
    allRiskItem['clientId'] = this.selectedUser;
    this.deleteAllRiskItemModal.openDialog(allRiskItem);
    this.deleteAllRiskItemModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userAllRiskItems = this.userAllRiskItems.filter(item => item != allRiskItem);
      }
    })
  };

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
