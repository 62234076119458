import { EmailResponse } from './../_shared/models/email.model';
import { Injectable } from '@angular/core';
import { Globals } from '../app.globals';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrokerEmailService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  composingNewEmail: boolean = false;
  emailAdress:string = '';
  emailOptions = null;
  clientId = '';

  sendEmailToClient(companyName:string, email:FormData): Observable<HttpResponse<any>>{
    return this.http.post<any>(`${this.globals.SERVER_URL}/api/broker/messages/${companyName}`, email, {
      observe: 'response',
    })
  }

  getEmailSignature(): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/broker/messages/signature`, {
      observe: 'response',
    })
  }

  getAllSentEmails(clientId:string, companyName: string, req = {}){
    return this.http.get<EmailResponse>(`${this.globals.SERVER_URL}/api/broker/messages/email/client/${clientId}/company/${companyName}`, {
      params: req,
      observe: 'response',
    })
  }

}
