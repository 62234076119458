import { HttpResponse } from '@angular/common/http';
import { finalize, debounceTime, tap, switchMap } from 'rxjs/operators';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Instruction } from 'src/app/_shared/models/brokerClaims.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { Vehicle } from 'src/app/_shared/models/vehicle.model';
import { ValueAddedProduct } from 'src/app/_shared/models/vaps.model';
import { Home } from 'src/app/_shared/models/homeInsurance.model';
import { AllRiskItem } from 'src/app/_shared/models/allRiskItem.model';
import { HomeContent } from 'src/app/_shared/models/homeContentInsurance.model';
import { TrailersAndCaravans } from 'src/app/_shared/models/trailersAndCaravans.model';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';
import { formatDate } from '@angular/common';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CommercialProductsService } from 'src/app/services/commercialProduct.service';

@Component({
  selector: 'broker-admin-instruction-form-group',
  templateUrl: './broker-admin-instruction-form-group.component.html',
  styleUrls: ['./broker-admin-instruction-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrokerAdminInstructionFormGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BrokerAdminInstructionFormGroupComponent),
      multi: true
    }
  ]
})
export class BrokerAdminInstructionFormGroupComponent implements OnInit {

  @Output() currentInstructionStep = new EventEmitter()

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];
  currentCompany: string = this.authenticationService.currentCompany.companyName;
  inEditMode: boolean = false;

  instructionFormGroup: FormGroup;
  subscriptions: Subscription[] = [];

  loadingResults:boolean = true;

  clients: User[];
  employees: Employee[];
  products: Vehicle[] | ValueAddedProduct[] | AllRiskItem[] | HomeContent[] | Home[] | TrailersAndCaravans[];

  maxDate: Date;

  get instructionFormValue(): Instruction {
    return this.instructionFormGroup.value;
  }

  set instructionFormValue(value: Instruction) {
    this.instructionFormGroup.patchValue(value);
    console.log('value => ', value)
    this.instructionFormGroup.get('productDisplay').setValue(value['product']);

    if(value['client']._id){
      this.inEditMode = true;
      this.instructionFormGroup.get('productType').enable();
      this.instructionFormGroup.get('productDisplay').enable();
    }

    this.currentInstructionStep.emit(value['currentStep']);

    this.onChange(value);
    this.onTouched();
  }

  get claimControl() {
    return this.instructionFormGroup.controls.client;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService,
    private homeService: BrokerClientHomeService,
    private homeContentService: BrokerClientHomeContentService,
    private allRiskService: BrokerClientAllRiskServiceService,
    private vapsService: ValueAddedProductsService,
    private trailersAndCaravansService: TrailersAndCaravansService,
    private commercialProductService: CommercialProductsService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.currentCompany).permissions
  }

  ngOnInit(): void {

    this.instructionFormGroup = this.formBuilder.group({
      _id: '',
      client: [null, Validators.required],
      productType: [{
        value: '',
        disabled: true
      }, Validators.required],
      productDisplay: [{
        value: null,
        disabled: true
      }],
      product: [null],
      instructionType: ['', Validators.required],
      instructionDescription: [''],
      instructionSteps: this.formBuilder.group({
        instructionReceivedVia: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
        instructionSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        instructionSentToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        insuranceCompanyReceivedInstruction: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        receiveNewScheduleFromInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        scheduleSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        requestProRataPremiums: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        sentScheduleAndProRataToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        confirmClientReceivedLastestSchedule: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        clientRequestedRefund: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        sentRefundRequestToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        insuranceConfirmReceiptOfRefund: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        refundPaymentDate: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
        receivedProofOfPayment: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        confirmProRataCredit: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        instructionFinalised: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        instructionSignedOff: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
      }),
      employee: [null, Validators.required],
      currentStep: [],
      instructionRejected: []
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.instructionFormGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // GET CLIENTS
    this.instructionFormGroup.get('client').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

    // GET EMPLOYEES
    this.instructionFormGroup.get('employee').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

    // GET PRODUCTS
    this.instructionFormGroup.get('productDisplay').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.getProducts(this.instructionFormGroup.get('productType')?.value, this.instructionFormGroup.get('client')?.value?._id, value))
    )
    .subscribe(
      (response: HttpResponse<any>) => {
        this.products = response.body.vehicle || response.body.home || response.body.homeContent || response.body.trailerAndCaravan || response.body.item || response.body.products;
      }
    )

    this.maxDate = new Date();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.instructionFormValue = value;
    }

    if (value === null) {
      this.instructionFormGroup.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.instructionFormGroup.valid ? null : { profile: { valid: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }) } };
  }

  displayClient(client?: User): string | undefined {
    if(client){
      if(client.hasOwnProperty('companyName')){
        return client['companyName']
      } else return `${client.firstName} ${client.lastName}`
    } else {
      return undefined
    }
  }

  mapEmployee(event: MatOptionSelectionChange){
    this.instructionFormGroup.get('employee').setValue(event.source.value._id)
  }

  displayProduct(product?: any): string | undefined {

    if(product && product.description){
      return product.description;
    }

    if(product && this.instructionFormGroup.get('productType').value === 'vehicle') {
      return `${product.make} ${product.model} - ${product.registrationNumber}`
    }

    if(product && this.instructionFormGroup.get('productType').value === 'trailerAndCaravan') {
      return `${product.type} - ${product.make} ${product.model}`
    }

    if(product && (this.instructionFormGroup.get('productType').value === 'building' || this.instructionFormGroup.get('productType').value === 'homeContent')) {
      return `${product?.address?.formattedAddress}`
    }

    if(product && this.instructionFormGroup.get('productType').value === 'allRisk') {
      return `${product?.itemName}`
    }

    if(product && this.instructionFormGroup.get('productType').value === 'vap') {
      return `${product?.description}`
    }

    return undefined;

  }

  mapProduct(event){
    let product = {
      _id: event.source.value._id,
      description: this.displayProduct(event.source.value),
      path: this.instructionFormGroup.get('productType').value === 'vehicle' ? event.source.value.registrationNumber : event.source.value._id
    }
    this.instructionFormGroup.get('product').patchValue(product);

  }

  selectedProductType(event){

    if(event.value === 'general') return;

    let product = this.instructionFormGroup.get('productDisplay');

    product.enable();
    if(product && product.value) product.setValue(null);
  }

  getProducts(product:string, clientId:string, searchValue:string = '') {
    if(product === 'vehicle') return this.brokerService.getUserVehicle(clientId, { search: searchValue, isQuoteProduct: false }, true).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'building') return this.homeService.getAllHomeInsuranceForClient(clientId, this.currentCompany, { search: searchValue }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'homeContent') return this.homeContentService.getAllHomeContentInsuranceForClient(clientId, this.currentCompany, { search: searchValue }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'trailerAndCaravan') return this.trailersAndCaravansService.getAllTrailersAndCaravansInsuranceForClient(clientId, this.currentCompany, { search: searchValue }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'allRisk') return this.allRiskService.getAllAllRiskInsuranceForClient(clientId, this.currentCompany, { search: searchValue }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'vap') return this.vapsService.getAllVapsForClient(clientId, this.currentCompany, { search: searchValue }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'commercialProduct') return this.commercialProductService.getAllCommercialProductForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))

  }

  canCompleteInstruction(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  displayCompletedBy(step){
    return this.instructionFormGroup.get('instructionSteps').get(step).get('completedBy').value?.brokerId;
  }

  getStepCompletedBy(step: string){
    let stepFormControl = this.instructionFormGroup.get('instructionSteps').get(step);
    return `${stepFormControl?.get('completedBy')?.value?.firstName} ${stepFormControl?.get('completedBy')?.value?.lastName} on ${formatDate(stepFormControl.get('dateCompleted').value, "dd MMM yyyy 'at' HH:mm", 'en')}`;
  }

}
