import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { PersonalItem, PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DeleteModalComponent } from 'src/app/components/modal/delete/delete.component';

@Component({
  selector: 'app-broker-view-user-personal-insurance',
  templateUrl: './broker-view-user-personal-insurance.component.html',
  styleUrls: ['./broker-view-user-personal-insurance.component.scss'],
  providers: [DeleteModalComponent]
})
export class BrokerViewUserPersonalInsuranceComponent implements OnInit, AfterViewInit {

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userPersonalItems: PersonalItem[]

  displayedColumns: string[] = ['item', 'itemCategory', 'specified', 'insuredValue', 'menu'];
  resultsLength = 0;
  pageSize = 30;
  isLoadingResults = true;
  isRateLimitReached = false;

  constructor(
    private router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private brokerService: BrokerService,
    private deleteModal: DeleteModalComponent,
  ) { }

  getUserPersonalItem(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReached){
      this.brokerService.getUserPersonalItem(this.selectedUser, req).subscribe(
        (response:HttpResponse<PersonalItemResponse>) => {
          this.isLoadingResults = false;
          this.userPersonalItems = response.body.personalItem;

          this.resultsLength = response.body.count;
          if(this.userPersonalItems.length < this.pageSize){
            this.isRateLimitReached = true;
          }
        }
      )
    }
  };

  deletePersonalItem(personalItem){
    this.deleteModal.openDialog('personal item', personalItem);
    this.deleteModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userPersonalItems = this.userPersonalItems.filter(item => item != personalItem);
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserPersonalItem();
  }

}
