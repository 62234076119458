import { Globals } from 'src/app/app.globals';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private toastr: ToastrService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(catchError(err => {

            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logoutUser();
                this.router.navigate(['/login']);
            }

            if (err.status === 404) {
                // redirect to error page if response returned is not found
                this.router.navigate(['/404']);
                return
            }

            // if (err.status === 500) {
            //     this.toastr.error(`${err.error.message} ${err.error.sub_message ? '<br />' + err.error.sub_message : '' }`, err.error.heading || '500 Internal Server Error!')
            //     return
            // }

            const hideAlert = ['cancelled', 'inactive'];

            console.log('interceptor error -> ', err);

            // ONLY RUN THIS ERROR IF ERROR NOT HAS TYPE AND ERROR TYPE NOT IN HIDEALERT ARRAY
            if(!err.error.errors.type && !hideAlert.includes(err.error.errors.type)){

                let message = {
                    heading: '',
                    message: '',
                    sub_message: ''
                };

                if(err.error && err.error.errors){
                    message.heading = err.error.errors.heading || 'ERROR';
                    message.message = err.error.errors.message.errmsg ? `${err.error.errors.message.name} - ${err.error.errors.message.errmsg}` : err.error.errors.message;
                    message.sub_message = err.error.errors.sub_message;
                } else {
                    message.heading = err.heading ? err.heading : err.name;
                    message.message = err.message
                    message.sub_message = err.sub_message;
                }

                this.toastr.error(`${message.message} ${message.sub_message ? '<br />' + message.sub_message : '' }`, message.heading)
            }

            return throwError(err);
        }))
        .pipe(tap(res => {
            const response = res['body'];

            if(response && response.message){
                const message = response.message;
                this.toastr.success(`${message.message} ${message.sub_message ? '<br />' + message.sub_message : '' }`, message.heading);
            }

        }))
    }
}
