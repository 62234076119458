import { AuthenticationService } from './../../../services/authentication.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { BrokerService } from 'src/app/services/broker.service';
import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service'
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { ToastrService } from 'ngx-toastr';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

class InvalidId implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
@Component({
  selector: 'app-new-broker-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss'],
  providers: [BrokerComponent]
})
export class NewBrokerClientComponent implements OnInit, AfterViewInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  basicInformationForm: FormGroup;
  get basicForm(){ return this.basicInformationForm.controls; }

  additionalInformationForm: FormGroup;
  get additionalForm(){ return this.additionalInformationForm.controls; }

  contactInformationForm: FormGroup;
  get contactForm(){ return this.contactInformationForm.controls; }

  formSubmitted = false;
  validId: boolean = true;

  matcher = new InvalidId();

  employees: Employee[];

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  constructor(
    private formBuilder: FormBuilder,
    public location: Location,
    private brokerService: BrokerService,
    private verifyIdService: IdNumberVerifyService,
    private brokerComponent: BrokerComponent,
    private toastr: ToastrService,
  ) { }

  idValidator = (field:FormGroup) => {
    const condition = this.verifyIdService.validateIdNumber(field.value)
    const isValid = condition && condition.isValid;

    return !isValid ? { invalidIdNumber: true } : null;
  }

  addClient(saveType?:string) {

    let verifyId: any = this.verifyIdService.validateIdNumber(this.basicInformationForm.value['username']);
    this.validId = verifyId.isValid;

    this.formSubmitted = true;
    
    // MARK ALL INPUTS FOR MAT-ERROR TO TAKE EFFECT 
    this.basicInformationForm.markAllAsTouched();
    this.additionalInformationForm.markAllAsTouched();
    this.contactInformationForm.markAllAsTouched();

    if (this.basicInformationForm.invalid || this.additionalInformationForm.invalid || this.contactInformationForm.invalid ) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add client.')

      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    let formData = {
      ...this.basicInformationForm.value,
      ...this.additionalInformationForm.value,
      ...this.contactInformationForm.value
    }

    this.brokerService.addNewClient(formData, this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<UserResponse>) => {

        switch(saveType){
          case 'back':
            this.location.back();
            break;

          case 'new':
            this.basicInformationForm.reset();
            this.additionalInformationForm.reset();
            this.contactInformationForm.reset();
            break;

          default:
            this.location.back();
            break;
        }

      }
    )
  }

  determineGender(){
    let verifyId: any = this.verifyIdService.validateIdNumber(this.basicInformationForm.value['username']);
    this.basicForm['gender'].setValue(verifyId.gender);

    this.checkTitle();
  }

  checkTitle(){

    let title = this.basicForm['title'].value;
    let gender = this.basicForm['gender'].value;

    if(!title)
      this.basicForm['title'].setValue(gender === 'Male' ? 'Mr' : 'Mrs');

    if(title === 'Mr' && gender === 'Female')
      this.basicForm['title'].setValue('Mrs');

    if(title === 'Mrs' || title === 'Miss' && gender === 'Male')
      this.basicForm['title'].setValue('Mr');
  }

  getEmployees(){
    this.brokerService.getAllCompanyEmployees(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )
  }

  ngAfterViewInit() {
    this.getEmployees();
  }

  ngOnInit() {
    this.basicInformationForm = this.formBuilder.group({
      username:         [''],
      passportNumber:   [''],
      taxNumber:        [''],
      clientNumber:     [''],
      title:            [''],
      initials:         [''],
      firstName:        ['', Validators.required],
      lastName:         ['', Validators.required],
      nickName:         [''],
      maritalStatus:    [''],
      language:         ['', Validators.required],
      broker:           this.formBuilder.group({
        _id: '',
        clientStatus:     ['Lead'],
      }),
      gender:           ['', Validators.required],
      physicalAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        suburb: [''],
        city: [''],
        province: [''],
        postalCode: [''],
        formattedAddress: ['']
      }),
      insuranceType: this.formBuilder.group({
        householdInsurance:     false,
        vehicleInsurance:       false,
        buildingInsurance:      false,
        personalItemInsurance:  false,
        trailersAndCaravansInsurance:  false,
        vapsInsurance:          false
      }),
    });

    this.additionalInformationForm = this.formBuilder.group({
      middleName:       [''],
      employer:         [''],
      jobDescription:   [''],
      salary:           [''],
      driversLicence: this.formBuilder.group({
        licenceCode:        [''],
        licenceFirstIssue:  [null],
        licenceExpiryDate:  [null]
      }),
    });

    this.contactInformationForm = this.formBuilder.group({
      email:            ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      cellphone:        ['', Validators.required],
      homePhone:        [''],
      workPhone:        [''],
      alternateNumber:  [''],
    });
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let basicInformationFormGroupAddress = this.basicInformationForm.get('physicalAddress');

    basicInformationFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    basicInformationFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    basicInformationFormGroupAddress.get('city').setValue(findInAddress('locality'));
    basicInformationFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    // basicInformationFormGroupAddress.get('country').setValue(findInAddress('country'));
    basicInformationFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    basicInformationFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

}
