<div class="page-description">
    <div class="row h-100">
        <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">Dashboard</h5>
        <!-- <h6 class="d-inline-block mb-0">{{ tabHeading(selectedTab) }}</h6> -->
        </div>
    
        <div class="col-6 text-right align-self-center">
            <span class="color-label-2"><strong>Today</strong></span> 
            <span class="color-info ml-1"><strong>{{ today | date: 'dd MMM'}}</strong></span>
        </div>

    </div>  <!-- Close div for .row -->
</div> <!-- Close div for .page-description -->

<div class="row">

    <div class="col-4" *ngIf="currentUser?.insuranceType?.vehicleInsurance">
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>My vehicles</h5>
            </div>

            <div class="panel-body">

                <div class="row">

                    <div class="col-12 mb-3">
                        <h5>{{ insuredVehicleInformation?.totalMonthlyPremium || 0 | currency: 'R ' }}<small><br />Monthly premium</small></h5>
                        <hr class="mt-4 mb-2" />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ vehicleInformation?.totalVehicles || 0 }}<small><br />Total vehicles added</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ insuredVehicleInformation?.totalInsuredVehicles || 0 }}<small><br />Total insured vehicles</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>0<small><br />Claims</small></h5>
                    </div>

                </div> <!-- Close div for .row -->                

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel panel-primary -->
    </div>

    <div class="col-4" *ngIf="currentUser?.insuranceType?.personalItemInsurance">
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>My personal items</h5>
            </div>

            <div class="panel-body">

                <div class="row">

                    <div class="col-12 mb-3">
                        <h5>{{ insuredPersonalItemInformation?.premium || 0 | currency: 'R ' }}<small><br />Monthly premium</small></h5>
                        <hr class="mt-4 mb-2" />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ personalItemInformation?.totalItems || 0 }}<small><br />Total items added</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ insuredPersonalItemInformation?.totalInsuredItems || 0 }}<small><br />Total insured items</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>0<small><br />Claims</small></h5>
                    </div>

                </div> <!-- Close div for .row -->   

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel panel-primary -->
    </div>

    <div class="col-4" *ngIf="currentUser?.insuranceType?.householdInsurance">
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>My household</h5>
            </div>

            <div class="panel-body">

                <div class="row">

                    <div class="col-12 col-md-6">
                        <h5 class="mb-0">{{ householdInsuranceInformation?.premium || 0 | currency: 'R ' }}<small><br />Monthly premium</small></h5>
                    </div>

                    <div class="col-12 col-md-6">
                        <h5 class="mb-0">{{ householdInsuranceInformation?.insuredHouseholdValue || 0 | currency: 'R ' }}<small><br />Insured household value</small></h5>
                    </div>

                    <div class="col-12 mb-3">
                        <hr class="mt-4 mb-2" />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ householdItemInformation?.totalItems || 0 }}<small><br />Total items</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ householdItemInformation?.totalItemValue || 0 | currency: "R " }}<small><br />Total item value</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>0<small><br />Claims</small></h5>
                    </div>

                </div> <!-- Close div for .row -->   

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel panel-primary -->
    </div>

    <div class="col-4" *ngIf="currentUser?.insuranceType?.buildingInsurance">
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>My structures</h5>
            </div>

            <div class="panel-body">

                <div class="row">

                    <div class="col-12 mb-3">
                        <h5>{{ insuredStructureInformation?.premium || 0 | currency: 'R ' }}<small><br />Monthly premium</small></h5>
                        <hr class="mt-4 mb-2" />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>{{ insuredStructureInformation?.totalStructures || 0 }}<small><br />Total structures</small></h5>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                        <h5>0<small><br />Claims</small></h5>
                    </div>

                </div> <!-- Close div for .row -->   

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel panel-primary -->
    </div>

    <div class="col-4" *ngIf="false">
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>Recent activities</h5>
            </div>

            <div class="panel-body">

                <p *ngIf="bulletins.length === 0" >Nothing interesting has happened here yet...</p>

                <div *ngIf="bulletins.length > 0" class="timeline-v3">

                    <div class="timeline-items">
        
                        <div class="w-100" *ngFor="let bulletin of bulletins; let firstBulletin = first; let lastBulletin = last">

                            <div class="timeline-item" *ngFor="let bullet of bulletin; let firstBullet = first"  [ngClass]="{'not-last': !lastBulletin}">

                                <!-- testing => {{ bullet | json}} -->

                                <div *ngIf="firstBullet" class="timeline-full" [ngClass]="{'mt-3': !firstBulletin}"><small class="color-label-1">{{ bullet?.dateAdded | date: 'dd MMMM'}}</small></div>
                                <span class="timeline-time">{{ bullet?.dateAdded | date: 'HH:mm'}}</span>
                
                                <div class="timeline-item-circle">
                                    <div class="circle circle-danger"></div>
                                    <!-- <div *ngIf="bullet?.item === 'investment'" class="circle circle-danger"></div> -->
                                </div>
                
                                <div class="timeline-item-content">

                                    <p *ngIf="currentUser?._id === bullet?.addedBy?._id">
                                        <strong>You</strong> added <a>{{ bullet?.item | sentenceCase | indefinite }}</a> to your account.
                                    </p>
                
                                    <p *ngIf="currentUser?._id != bullet?.addedBy?._id">
                                        <a>{{ bullet?.item | sentenceCase | indefinite }}</a> was added to your account by <strong>{{ bullet?.addedBy?.firstName }} {{ bullet?.addedBy?.lastName }}</strong>.
                                    </p>
                
                                </div> <!-- Close div for .timeline-content -->

                            </div>
        
                        </div> <!-- Close div for .timeline-item -->
        
                    </div> <!-- Close div for timeline-items -->
        
                </div> <!-- Close div for .timeline-v3 -->

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-4 -->

</div> <!-- Close div for .row-->