import { UserResponse, User } from './../../../../_shared/models/User.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerService } from 'src/app/services/broker.service';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service'
import { HttpResponse, HttpClient } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ActivatedRoute, Router } from "@angular/router"
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { Lightbox } from 'ngx-lightbox';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MyAccountService } from 'src/app/services/my-account.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';

@Component({
  selector: 'app-broker-edit-vehicle',
  templateUrl: './broker-edit-vehicle.component.html',
  styleUrls: ['./broker-edit-vehicle.component.scss'],
  providers: [DeleteVehicleComponent, BrokerComponent]
})
export class BrokerEditVehicleComponent implements OnInit {

  maxDate = new Date();

  clientId = this.activeRoute.snapshot.paramMap.get('id');
  clientInformation: any;

  vehicleRegistrationNumber = this.activeRoute.snapshot.paramMap.get('registration');
  vehicle: Vehicle[];

  vehicleYears: any;
  vehicleMakes: any;
  vehicleModels: any;
  vehicleTrims: any;

  brokers: Employee[];
  insuranceCompanies: InsuranceCompany[];

  submitted = false;
  vehicleFormGroup: FormGroup;
  get form() { return this.vehicleFormGroup.controls; }

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  maxUploads: number = 6

  familyMembers: FamilyMember[];

  constructor(
    private personalInsuranceService: PersonalInsuranceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private deleteVehicleModal: DeleteVehicleComponent,
    private insuranceService: InsuranceService,
    private lightbox: Lightbox,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private myAccountService: MyAccountService,
    private vehicleService: VehicleService,
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent
  ) {
    this.options = { concurrency: 2, maxUploads: this.maxUploads, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

  }

  getVehicleYears(){
    this.vehicleService.getVehicleYears().subscribe(
      (response: HttpResponse<any>) => {
        this.vehicleYears = response.body.years
      }
    )
  }

  getVehicleMakes(year:string = this.vehicleFormGroup.value.year){
    this.vehicleService.getVehicleMakes(year).subscribe(
      (response: HttpResponse<any>) => {
        this.vehicleMakes = response.body.makes
      }
    )
  }

  getVehicleModels(year:string = this.vehicleFormGroup.value.year, make:string = this.vehicleFormGroup.value.make){
    this.vehicleService.getVehicleModels({ year: year, make: make }).subscribe(
      (response: HttpResponse<any>) => {
        this.vehicleModels= response.body.models
      }
    )
  }

  getVehicleTrims(
    year:string = this.vehicleFormGroup.value.year,
    make:string = this.vehicleFormGroup.value.make,
    model:string = this.vehicleFormGroup.value.model,
  ){
    this.vehicleService.getVehicleTrims({ year: year, make: make, model: model }).subscribe(
      (response: HttpResponse<any>) => {
        this.vehicleTrims = response.body.trims
      }
    )
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });

            this.files.push(file);
            this.vehicleFormGroup.value.newPhotos.push(file.nativeFile)
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
      this.removeAllFiles()
        break;
    }
  };

  getCurrentVehicle(registrationNumber){

    this.personalInsuranceService.getSelectedVehicle(registrationNumber).subscribe(
      (response:HttpResponse<VehicleResponse>) => {
        this.vehicle = response.body.vehicle;

        // create array where extras needs to be patched
        this.vehicle['optionalExtras'].map(item => {
          if(item.extra){
            this.addNewOptionalExtra()
          }
        })

        this.vehicleFormGroup.patchValue(this.vehicle);

        this.vehicleFormGroup.controls.insurance.patchValue(this.vehicle['insurance'].find(insurance => insurance.brokerCompany === this.brokerComponent.brokerCompany.companyName));
        this.vehicleFormGroup.controls.insurance['controls'].insuredWith.patchValue(this.brokers.find(broker => broker._id === this.vehicleFormGroup.controls.insurance['controls'].brokerID.value));

        this.maxUploads = this.maxUploads - this.vehicle['s3Files'].length;

        this.vehicleFormGroup.value.newPhotos = [];
        this.vehicleFormGroup.value.removedPhotos = [];

        // GET VEHICLE YEAR/MAKE/MODEL/TRIM FROM SERVER
        this.getVehicleYears();
        if(response.body.vehicle['year']) this.getVehicleMakes()
        if(response.body.vehicle['make']) this.getVehicleModels()
        if(response.body.vehicle['model']) this.getVehicleTrims()
      }
    )
  };

  getClientInformation(client_id:string){
    this.brokerService.getSingleClient(client_id).subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clientInformation = response.body.user;
      }
    )
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  removeExistingVehiclePhoto(photo){
    //remove the image from the array so that it is not in the view
    this.vehicleFormGroup.value.s3Files = this.vehicleFormGroup.value.s3Files.filter((name, photoIndex) => { return name.key !== photo.key });
    this.vehicleFormGroup.value.photos = this.vehicleFormGroup.value.photos.filter((name, photoIndex) => { return name.key !== photo.key });

    this.vehicleFormGroup.value.removedPhotos.push(photo)
  }

  updateVehicle(saveType?:string){

    //Set insured with details
    this.form.insurance['controls'].brokerID.setValue(this.vehicleFormGroup.value.insurance.insuredWith._id);
    this.form.insurance['controls'].brokerFirstName.setValue(this.vehicleFormGroup.value.insurance.insuredWith.firstName);
    this.form.insurance['controls'].brokerLastName.setValue(this.vehicleFormGroup.value.insurance.insuredWith.lastName);
    this.form.insurance['controls'].brokerCompany.setValue(this.brokerComponent.brokerCompany.companyName);

    this.form.insurance['controls'].currentlyInsured.setValue(true);
    this.form.insurance['controls'].terminationDate.setValue(null);

    // THIS ONLY HAPPENS ON CREATION OF NEW VEHICLE
    // this.form.registeredTo['controls']._id.setValue(this.clientInformation._id);
    // this.form.registeredTo['controls'].idNumber.setValue(this.clientInformation.username);
    // this.form.registeredTo['controls'].firstName.setValue(this.clientInformation.firstName);
    // this.form.registeredTo['controls'].lastName.setValue(this.clientInformation.lastName);

    this.submitted = true;
    // stop here if form is invalid
    if (this.vehicleFormGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save vehicle.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    // Set photos == files
    this.files.map(file => {
      this.vehicleFormGroup.value.photos.push(file.nativeFile)
    });

    this.personalInsuranceService.updateVehicle(this.vehicleRegistrationNumber, this.vehicleFormGroup.value).subscribe(
      (response:HttpResponse<VehicleResponse>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            // this.resetForm()
            break;

          default:
            break;
        }

      }
    )
  };

  deleteVehicle(){
    this.deleteVehicleModal.openDialog(this.vehicleFormGroup.value);
    this.goBack();
  };

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  getClientFamilyMembers(client_Id:string){
    this.brokerService.getClientFamilyMembers(client_Id)
    .subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        this.familyMembers = response.body.family;
      }
    )
  };

  open(index: number): void {
    this.lightbox.open(this.vehicleFormGroup.value.s3Files, index);
  }

  close(): void {
    this.lightbox.close();
  }

  initOptionalExtraField(): FormGroup{
    return this.formBuilder.group({
      extra 		: ['', Validators.required]
    });
   };

  addNewOptionalExtra(): void{
    const control = <FormArray>this.form.optionalExtras;
    control.push(this.initOptionalExtraField());
  };

  removeOptionalExtra(i : number): void {
      const control = <FormArray>this.form.optionalExtras;
      control.removeAt(i);
  };

  goBack(){
    this.router.navigate(['/b/clients/personal/edit'], { queryParams: { id: this.clientId }});
  }

  addNewRegularDriver(){
    localStorage.setItem('SureSpace-Vehicle', JSON.stringify(this.vehicleFormGroup.value))
    this.router.navigate([`/u/me/my-family/new-member`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-Broker-Vehicle')){
      this.vehicleFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-Broker-Vehicle')));
      localStorage.removeItem('SureSpace-Broker-Vehicle');
    }
  };

  ngOnInit() {
    this.vehicleFormGroup = this.formBuilder.group({
      _id: [''],
      registrationNumber: ['', Validators.required],
      photos: [[]],
      s3Files: [[]],
      newPhotos: [[]],
      removedPhotos: [[]],
      type: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      year: ['', Validators.required],
      color: ['', Validators.required],
      // status: ['', Validators.required],
      variant: ['', Validators.required],
      milage: [''],
      use: ['', Validators.required],
      engineSize: ['', Validators.required],
      vin: ['', Validators.required],
      // engineNumber: ['', Validators.required],
      homeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      officeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      optionalSafetyExtras: this.formBuilder.group({
        alarm: false,
        centralLocking: false,
        dataDot: false,
        gearLock: false,
        immobiliser: false,
        smashAndGrab: false,
        steeringLock: false,
        tracker: false
      }),
      optionalExtras: this.formBuilder.array([]),
      isInsured: [true],
      insurance: this.formBuilder.group({
        insuredWith: ['', Validators.required],
        // brokerID: [''],
        // brokerFirstName: [''],
        // brokerLastName: [''],
        brokerCompany: [''],
        insuredValue: ['', Validators.required],
        premium: ['', Validators.required],
        policyNumber: ['', Validators.required],
        inceptionDate: ['', Validators.required],
        terminationDate: [''],
        currentlyInsured: [''],
        regularDriver: ['', Validators.required],
      }),
      registeredTo: this.formBuilder.group({
        _id: [''],
        idNumber: [''],
        firstName: [''],
        lastName: [''],
      }),
    });
  }

  ngAfterViewInit(){
    this.getAllBrokers();
    this.getClientFamilyMembers(this.clientId);

    this.getClientInformation(this.clientId);
    this.getCurrentVehicle(this.vehicleRegistrationNumber);
    this.getLocalStorageItem();
  }

}

