<div class="page-description">
        <div class="row h-100">
            <div class="col-6 my-auto">
            <h5 class="d-inline-block mb-0 font-weight-400">Household insurance</h5>
            <h6 class="d-inline-block mb-0">Add household information and save</h6>
            </div>
        
            <div class="col-6 text-right my-auto">
    
                <button type="button" class="btn btn-secondary" (click)="goBack()">
                  Back
                </button>
            
                <div class="btn-group" ngbDropdown>
                  <button type="button" class="btn btn-primary" mat-tooltip="Save household insurance and go back;" (click)="saveHousholdInsurance('back')">
                    Save
                  </button>
                  <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                  </button>
                  <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" (click)="saveHousholdInsurance('continue')">Save &amp; continue</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="saveHousholdInsurance('back')">Save &amp; go back</a></li>
                  </ul>
                </div> <!-- Close div for .btn-group -->
        
            </div>
        </div> <!-- Close div for .row -->
    </div> <!-- Close div for .page-description -->
    
    <div class="row">
    
        <div class="col-12">
        
            <div class="panel panel-wizard no-last-step">
        
                <div class="panel-body">
                  <form id="householdInsuranceForm" [formGroup]="householdFormGroup">
    
                    <mat-horizontal-stepper [linear]="true" #stepper>
    
                      <ng-template matStepperIcon="edit" let-index="index">
                        <span>{{ index + 1 }}</span>
                      </ng-template>
    
                      <mat-step>
    
                        <ng-template matStepLabel>
                          <span class="mat-step-label-title">Information</span><br />
                          <span class="mat-step-label-desc">Basic household information</span>
                        </ng-template>
    
                        <div class="row pt-5">
    
                          <div id="form-container" class="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
    
                            <div class="row">
    
                              <div class="col-12">
                                  <h4>Household address</h4>
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12 mt-4">
                                  <label for="addressLine1"><strong>Physical address line 1:</strong></label>
                                  <input type="text" name="addressLine1" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.addressLine1.errors }" formControlName="addressLine1" />
    
                                  <div *ngIf="formSubmitted && form['addressLine1'].errors" class="invalid-feedback">
                                      <div *ngIf="form['addressLine1'].errors.required">Please supply us with an address.</div>
                                  </div>
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12 mt-4">
                                  <label for="addressLine2"><strong>Physical address line 2:</strong></label>
                                  <input type="text" name="addressLine2" class="form-control" formControlName="addressLine2" />
                              </div> <!-- Close div for .col-12 -->
              
                              <div class="col-sm-6 col-12 mt-4">
                                  <label for="suburb"><strong>Suburb:</strong></label>
                                  <input type="text" name="suburb" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.suburb.errors }" formControlName="suburb" />
              
                                  <div *ngIf="formSubmitted && form['suburb'].errors" class="invalid-feedback">
                                      <div *ngIf="form['suburb'].errors.required">Please supply us with your suburb.</div>
                                  </div>
                              </div>
    
                              <div class="col-sm-6 col-12 mt-4">
                                  <label for="city"><strong>City/Town:</strong></label>
                                  <input type="text" name="city" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.city.errors }" formControlName="city" />
              
                                  <div *ngIf="formSubmitted && form['city'].errors" class="invalid-feedback help-block errorMessage">
                                      <div *ngIf="form['city'].errors.required">Please supply us with your city.</div>
                                  </div>
                              </div>
    
                              <div class="col-sm-6 col-12 mt-4">
                                  <label for="postalCode"><strong>Postal code:</strong></label>
                                  <input type="text" name="postalCode" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.postalCode.errors }" formControlName="postalCode" />
              
                                  <div *ngIf="formSubmitted && form['postalCode'].errors" class="invalid-feedback help-block errorMessage">
                                      <div *ngIf="form['postalCode'].errors.required">Please supply us with your postal code.</div>
                                  </div>
                              </div>
    
                              <div class="col-12 text-right">
                                  <hr class="hr-light mt-5 mb-4" />
    
                                  <button class="btn btn-primary mr-0" matStepperNext>Next Step</button>
                              </div> <!-- Close div for .col-12 -->
                                
                            </div> <!-- Close div for .row -->
    
                          </div> <!-- Close div for #form-container -->
        
                        </div> <!-- Close div for .row -->
    
                      </mat-step>
    
                      <mat-step>
    
                              <ng-template matStepLabel>
                                  <span class="mat-step-label-title">Security</span><br />
                                  <span class="mat-step-label-desc">Household security information</span>
                              </ng-template>
    
                              <div class="row pt-5">
    
                                  <div id="form-container" class="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2" formGroupName="security">
    
                                      <div class="row">
    
                                          <div class="col-12">
                                              <h4>Household security</h4>
                                          </div> <!-- Close div for .col-12 -->
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.breakin">Have you had a break-in at this address?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <mat-form-field class="form-control">
                                                  <mat-select name="security.breakin" formControlName="breakin">
                                                      <mat-option [value]="true">Yes</mat-option>
                                                      <mat-option [value]="false">No</mat-option>
                                                  </mat-select>
                                              </mat-form-field>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.alarm">Do you have an alarm in working order?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.alarm" formControlName="alarm">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.electricFence">Does the property have an electric fence?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.electricFence" formControlName="electricFence">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.occupied">Is your home occupied during working hours?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.occupied" formControlName="occupied">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.neighbours">Do you have neighbours on all borders?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.neighbours" formControlName="neighbours">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.burglarBars">Do you have burglar bars on all windows?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.burglarBars" formControlName="burglarBars">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-sm-6 col-12 my-auto">
                                              <label for="security.accessControl">Do you live in an accessed controlled estate?</label>
                                          </div>
    
                                          <div class="col-sm-6 col-12 input-group">
                                              <div class="input-group">
                                                  <mat-form-field class="form-control">
                                                      <mat-select name="security.accessControl" formControlName="accessControl">
                                                          <mat-option [value]="true">Yes</mat-option>
                                                          <mat-option [value]="false">No</mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                              </div>
                                          </div>
    
                                          <div class="clearfix col-12 mt-4"></div>
    
                                          <div class="col-12">
                                              <hr class="hr-light mt-5 mb-4" />
                                          </div> <!-- Close div for .col-12 -->
    
                                          <div class="col-6">
                                              <button class="btn btn-secondary mr-0" matStepperPrevious>Previous</button>
                                          </div> <!-- Close div for .col-6 -->
    
                                          <div class="col-6 text-right">
                                              <button class="btn btn-primary mr-0" matStepperNext>Next Step</button>
                                          </div> <!-- Close div for .col-6 -->
                                          
                                      </div> <!-- Close div for .row -->
    
                                  </div> <!-- Close div for #form-container -->
              
                              </div> <!-- Close div for .row -->
    
                      </mat-step>
    
                      <mat-step>
    
                          <ng-template matStepLabel>
                              <span class="mat-step-label-title">Insurance</span><br />
                              <span class="mat-step-label-desc">Household insurance information</span>
                          </ng-template>
    
                          <div class="row pt-5">
    
                              <div id="form-container" class="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
    
                                  <div class="row">
    
                                      <div class="col-12">
                                          <h4>Household insurance information</h4>
                                      </div> <!-- Close div for .col-12 -->

                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <mat-checkbox color="brand" formControlName="quote">I do not have insurance on this item, and would like to get a quote</mat-checkbox>
                                      </div>

                                      <div class="disabled d-contents" [ngClass]="{'hidden' : form['quote'].value}">
    
                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <label for="insuredWith">Insured with:</label>
                                      </div>
    
                                      <div class="col-12 col-sm-6 col-md-9 input-group">
                                          <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.insuredWith.errors }">
                                              <mat-select name="insuredWith" formControlName="insuredWith">
                          
                                                  <mat-optgroup label="Brokers" [hidden]="brokers?.length > 0 && customBrokers?.length > 0">
                                                        <mat-option *ngFor="let broker of brokers" [value]="broker">
                                                            {{ broker?.company }}
                                                        </mat-option>
                                                        <mat-option *ngFor="let broker of customBrokers" [value]="broker">
                                                            {{ broker?.company }}
                                                        </mat-option>
                                                  </mat-optgroup>
                          
                                                  <!-- <mat-optgroup label="Insurance companies" *ngIf="insuranceCompanies && insuranceCompanies.length > 0">
                                                      <mat-option *ngFor="let company of insuranceCompanies" [value]="company">
                                                          {{ company?.companyName }}
                                                      </mat-option>
                                                  </mat-optgroup> -->

                                                    <mat-option *ngIf="brokers?.length < 1 && customBrokers?.length < 1" disabled>
                                                        No insurers added
                                                    </mat-option>
                          
                                                  <div class="row mt-2">
                                                      <button (click)="addNewBroker()" class="btn btn-square btn-label-primary btn-block">
                                                          New broker
                                                      </button>
                                                      <!-- <div class="dropdown-buttons col-12 col-md-6">
                                                          <button (click)="addNewInsurer('insuranceCompany')" class="btn btn-square btn-secondary btn-block">
                                                              New insurance company
                                                          </button>
                                                      </div> -->
                                                  </div>
                          
                                              </mat-select>
                                          </mat-form-field>
                      
                                          <div *ngIf="formSubmitted && form['insuredWith'].errors" class="invalid-feedback help-block errorMessage">
                                              <div *ngIf="form['insuredWith'].errors.required">Who is your household insurance insured with?
                                              </div>
                                          </div>
                          
                                      </div> <!-- Close div for .input-group-->

                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <label for="premium">Monthy premium:</label>
                                      </div>
    
                                      <div class="col-12 col-sm-6 col-md-9 input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">R</div>
                                            </div>
                                          <input [disabled]="form['quote'].value" [readonly]="form['quote'].value" type="text" name="premium" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.premium.errors }" formControlName="premium" />
                        
                                          <div *ngIf="formSubmitted && form['premium'].errors" class="invalid-feedback ">
                                              <div *ngIf="form['premium'].errors.required">Please enter your monthly premium.</div>
                                          </div>
                                      </div>
    
                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <label for="policyNumber">Policy number:</label>
                                      </div>
    
                                      <div class="col-12 col-sm-6 col-md-9 input-group">
                                          <input [disabled]="form['quote'].value" [readonly]="form['quote'].value" type="text" name="policyNumber" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.policyNumber.errors }" formControlName="policyNumber" />
                        
                                          <div *ngIf="formSubmitted && form['policyNumber'].errors" class="invalid-feedback ">
                                              <div *ngIf="form['policyNumber'].errors.required">Please enter your policy number.</div>
                                          </div>
                                      </div>
    
                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <label for="structureValue">Date insured / <br />Policy start date:</label>
                                      </div>
    
                                      <div class="col-12 col-sm-6 col-md-9 input-group">
                                          <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.dateInsured.errors }">
                                              <input [disabled]="form['quote'].value" [readonly]="form['quote'].value" matInput [matDatepicker]="dateInsured" name="dateInsured" formControlName="dateInsured" [max]="maxDate">
                                              <mat-datepicker-toggle matSuffix [for]="dateInsured"></mat-datepicker-toggle>
                                              <mat-datepicker touchUi #dateInsured></mat-datepicker>
                                          </mat-form-field>
                                      </div>
    
                                      <div class="clearfix col-12 mt-4"></div>
    
                                      <div class="col-12 col-sm-6 col-md-3 my-auto">
                                          <label for="insuredHouseholdValue">Insured household value:</label>
                                      </div>
    
                                      <div class="col-12 col-sm-6 col-md-9 input-group">
                                          <div class="input-group-prepend">
                                              <div class="input-group-text">R</div>
                                          </div>
                                          <input [disabled]="form['quote'].value" [readonly]="form['quote'].value" type="text" name="insuredHouseholdValue" class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && form.insuredHouseholdValue.errors }" formControlName="insuredHouseholdValue" />
    
                                          <div *ngIf="formSubmitted && form['insuredHouseholdValue'].errors" class="invalid-feedback">
                                              <div *ngIf="form['insuredHouseholdValue'].errors.required">What is your household content insured for?</div>
                                          </div>
                                      </div> <!-- Close div for .input-group-->
    
                                      <div class="clearfix col-12 mt-4"></div>

                                    </div> <!-- Close div for .disabled -->
    
                                      <div class="col-12">
                                          <hr class="hr-light mt-5 mb-4" />
                                      </div> <!-- Close div for .col-12 -->
    
                                      <div class="col-6">
                                          <button class="btn btn-secondary mr-0" matStepperPrevious>Previous</button>
                                      </div> <!-- Close div for .col-6 -->
    
                                      <div class="col-6 text-right">
                                          <button class="btn btn-primary mr-0" matStepperNext>Next Step</button>
                                      </div> <!-- Close div for .col-6 -->
                                      
                                  </div> <!-- Close div for .row -->
    
                              </div> <!-- Close div for #form-container -->
          
                          </div> <!-- Close div for .row -->
    
                      </mat-step>
    
                      <mat-step>
    
                        <div class="row pt-5">
    
                          <div id="form-container" class="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
    
                            <div class="row">
    
                              <div class="col-12">
                                <h4>Review household details and save</h4>
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="clearfix col-12 mt-4"></div>
    
                              <div class="col-12">
                                <h5>Household address</h5>
                                <p>
                                  {{ form['addressLine1'].value }}<br />
                                  <span *ngIf="form['addressLine2'].value">{{ form['addressLine2'].value }}<br /></span>
                                  {{ form['suburb'].value }}<br />
                                  {{ form['city'].value }}<br />
                                  {{ form['postalCode'].value }}
                                </p>
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12">
                                <hr class="hr-light mt-3 mb-4" />
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12">
                                <h5>Household security</h5>
                                <p>
                                  <strong>Break-in:</strong> {{ form['security'].value.breakin ? 'Yes' : 'No' }}<br />
                                  <strong>Working alarm:</strong> {{ form['security'].value.alarm ? 'Yes' : 'No' }}<br />
                                  <strong>Electric fence:</strong> {{ form['security'].value.electricFence ? 'Yes' : 'No' }}<br />
                                  <strong>Occupied during day:</strong> {{ form['security'].value.occupied ? 'Yes' : 'No' }}<br />
                                  <strong>All border neighbours:</strong> {{ form['security'].value.neighbours ? 'Yes' : 'No' }}<br />
                                  <strong>Burglar bars:</strong> {{ form['security'].value.burglarBars ? 'Yes' : 'No' }}<br />
                                  <strong>Access controlled estate:</strong> {{ form['security'].value.accessControl ? 'Yes' : 'No' }}
                                </p>
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12">
                                <hr class="hr-light mt-3 mb-4" />
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12" *ngIf="!form['quote'].value">
                                <h5>Household insurance information</h5>
                                <p>
                                  <strong>Insured with:</strong> {{ form['insuredWith'].value.company }}<br />
                                  <strong>Monthy premium:</strong> {{ form['premium'].value | currency: 'R ' }}<br />
                                  <strong>Policy number:</strong> {{ form['policyNumber'].value }}<br />
                                  <strong>Policy start date:</strong> {{ form['dateInsured'].value | date: 'dd/MM/yyyy' }}<br />
                                  <strong>Insured household value:</strong> {{ form['insuredHouseholdValue'].value | currency: 'R ' }}
                                </p>
                              </div> <!-- Close div for .col-12 -->

                              <div class="col-12" *ngIf="form['quote'].value">
                                    <h5>Household insurance quote to be requested</h5>
                                </div> <!-- Close div for .col-12 -->
    
                              <div class="col-12">
                                  <hr class="hr-light mt-5 mb-4" />
                              </div> <!-- Close div for .col-12 -->
    
                              <div class="col-6">
                                  <button class="btn btn-secondary mr-0" matStepperPrevious>Previous</button>
                              </div> <!-- Close div for .col-6 -->
    
                              <div class="col-6 text-right">
                                  <button class="btn btn-success mr-0" matStepperNext (click)="saveHousholdInsurance('continue')">Save</button>
                              </div> <!-- Close div for .col-6 -->
    
                            </div> <!-- Close div for .row -->
    
                          </div> <!-- Close div for .form-container -->
    
                        </div> <!-- Close div for .row -->
    
                      </mat-step>
    
                    </mat-horizontal-stepper>
    
                  </form>
                </div> <!-- Close div for .panel-body -->
    
            </div> <!-- Close div for .panel -->
    
        </div> <!-- Close div for .col-12 -->
    
    </div> <!-- Close div for .row -->
          