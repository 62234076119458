import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { ManagementService } from 'src/app/services/management.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-console',
  templateUrl: './management-console.component.html',
  styleUrls: ['./management-console.component.scss']
})
export class ManagementConsoleComponent implements OnInit {

  registeredUserCount: number;
  registeredBrokerCount: number;
  registeredCompanyCount: number;
  registeredInsuranceCompanyCount: number;
  registeredUsers: any[]

  constructor(
    private managementService: ManagementService
  ) { }

  getTotalRegisteredUsers(){

    const req = {
      "offset": 0,
      "limit": 10
    }
      
    this.managementService.getRegisteredUsers(req).subscribe(
      (response: HttpResponse<any>) => {
        this.registeredUserCount = response.body.userCount;
        this.registeredCompanyCount = response.body.companyCount;
        this.registeredBrokerCount = response.body.brokerCount;
        this.registeredInsuranceCompanyCount = response.body.insuranceCompanyCount;
        this.registeredUsers = response.body.users;
      }
    )
  }

  ngOnInit() {
    this.getTotalRegisteredUsers();
  }

}
