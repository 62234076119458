import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Globals } from '../app.globals';
import { LoadingScreenService } from '../components/loading-screen/loading-screen.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, AfterViewInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public globals: Globals,
    private loading: LoadingScreenService
  ) {}

  ngOnInit() {
    this.loading.startLoading();
    // this.globals.AUTENTICATED;
    // location.reload(true);
  }

  ngAfterViewInit() {
    this.authService.logoutUser();
    this.router.navigate(['/login']);
    this.loading.stopLoading();
  }

}
