<div id="content" class="row">

    <sub-navigation heading="Company Documents"></sub-navigation>
  
    <div class="col-12">
  
        <div class="panel panel-primary">
    
            <div class="panel-heading">
    
                <h5>Company Documents</h5>

                <button
                    type="button"
                    class="btn btn-brand my-auto"
                    (click)="uploadCompanyDocument()"
                    matTooltip="Add a new company location"
                    >
                    <i class="fi flaticon-add"></i>Upload document
                </button>
    
            </div> <!-- Close div for .panel-heading -->
    
            <div class="panel-body pb-5">

                <p class="pt-3 text-center" *ngIf="companyDocuments?.length === 0">No company documents found</p>

                <div *ngIf="companyDocuments.length > 0">
                    <table class="table table-sm table-borderless mat-table document-list-view">
                        <thead role="rowgroup">
                            <tr class="mat-header-row" role="row">
                                <th class="mat-header-cell" width="50px"></th>
                                <th class="mat-header-cell" width="45%">Description</th>
                                <th class="mat-header-cell" width="45%">Name</th>
                                <th class="mat-header-cell" width="45%">Type</th>
                                <th class="mat-header-cell" width="50px"></th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr class="mat-row" role="row" *ngFor="let file of companyDocuments">
                                <td class="mat-cell"><i class="fi flaticon-file"></i></td>
                                <td class="mat-cell">{{ file?.documentDescription }}</td>
                                <td class="mat-cell">{{ file?.documentName }}</td>
                                <td class="mat-cell">File</td>
                                <td class="mat-cell" *ngIf="!addAsAttachment">
                                    <button class="btn btn-clean btn-icon btn-sm" [matMenuTriggerFor]="fileMenu">
                                        <i class="fi flaticon-menu"></i>
                                    </button>
                                    <mat-menu #fileMenu="matMenu">
                                        <button mat-menu-item (click)="viewDocument(file)">
                                            <i class="fi flaticon-edit mr-2"></i>View file
                                        </button>
                                        <a [href]="file.documentFile" target="_blank" mat-menu-item>
                                            <i class="fi flaticon-edit mr-2"></i>Download file
                                        </a>
                                        <!-- <button mat-menu-item (click)="deleteDocument(file)">
                                            <i class="fi flaticon-trash mr-2"></i>Delete file
                                        </button> -->
                                    </mat-menu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div> <!-- Close div for panel-body -->

        </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->
    
</div> <!-- Close div for #content -->