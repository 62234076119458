import { AuthenticationService } from './authentication.service';
import { FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { PolicyResponse, Policy } from 'src/app/_shared/models/policy.model';
import { Employee } from 'src/app/_shared/models/employeeModel';
import { User, UserResponse, Company } from 'src/app/_shared/models/User.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { StructureResponse } from 'src/app/_shared/models/structure.model';
import { BrokerResponse } from 'src/app/_shared/models/broker.model';
import { EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { NotificationResponse } from 'src/app/_shared/models/notifications.model';
import { UserRequestResponse } from 'src/app/_shared/models/userRequest.interface';
import { ActivityLog, ActivityLogResponse } from '../_shared/models/activityLogs.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(
    private http: HttpClient,
    public globals: Globals,
    private authenticationService: AuthenticationService
  ) {}

  getNotifications(): Observable<HttpResponse<NotificationResponse>>{
    return this.http.get<NotificationResponse>(`${this.globals.SERVER_URL}/api/client/notifications`, {
      observe: 'response'
    });
  }

  getAllClients(companyName:string, req?:any, noLoad?:boolean){

    console.log('req => ', req)

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/broker/clients/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getAllCompanyClientsAndCommercial(companyName:string, req?:any, noLoad?:boolean){

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/broker/clients/${companyName}/allClients`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getSingleClient(id: string): Observable<HttpResponse<UserResponse>>{
    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/broker/client/${id}`, {
      observe: 'response'
    });
  }

  addNewClient(formData:User, companyName:string): Observable<HttpResponse<UserResponse>>{

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.post<UserResponse>(`${this.globals.SERVER_URL}/api/broker/client/${companyName}`, formData, {
      observe: 'response',
      headers: headers
    })
  }

  removeClient(user:string, companyName: string): Observable<HttpResponse<UserResponse>>{
    return this.http.delete<UserResponse>(`${this.globals.SERVER_URL}/api/broker/client/${user}/${companyName}`, {
      observe: 'response'
    })
  }

  saveUserBasicInformation(clientId: string, brokerCompanyName:string, formData: User): Observable<HttpResponse<UserResponse>>{
    return this.http.put<UserResponse>(`${this.globals.SERVER_URL}/api/broker/${brokerCompanyName}/clientInformation/${clientId}`, formData, {
      observe: 'response'
    });
  }

  getUserAdditionalInfo(id: string): Observable<HttpResponse<UserResponse>>{
    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/broker/client/${id}/additionalInformation`, {
      observe: 'response'
    });
  }

  saveUserAdditionalInformation(id: string, formData: User): Observable<HttpResponse<UserResponse>>{
    return this.http.put<UserResponse>(`${this.globals.SERVER_URL}/api/broker/clientAdditionalInformation/${id}`, formData, {
      observe: 'response'
    });
  }

  getUserHouseholdItem(id, req?: any): Observable<HttpResponse<HouseholdItemResponse>>{
    return this.http.get<HouseholdItemResponse>(`${this.globals.SERVER_URL}/api/broker/client/${id}/householdItem`, {
      params: req,
      observe: 'response'
    });
  }

  getUserPersonalItem(id, req?: any): Observable<HttpResponse<PersonalItemResponse>>{
    return this.http.get<PersonalItemResponse>(`${this.globals.SERVER_URL}/api/broker/client/${id}/personalItem`, {
      params: req,
      observe: 'response'
    });
  }

  getUserVehicle(client_id:string, req?:any, noLoad?:boolean): Observable<HttpResponse<VehicleResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/client/${client_id}/company/${this.authenticationService.currentCompany.companyName}/vehicle`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getUserStructure(id, req?: any): Observable<HttpResponse<StructureResponse>>{
    return this.http.get<StructureResponse>(`${this.globals.SERVER_URL}/api/broker/client/${id}/structure`, {
      params: req,
      observe: 'response'
    });
  }

  addEmployee(companyName:string, employee:Employee): Observable<HttpResponse<UserResponse>>{
    return this.http.post<UserResponse>(`${this.globals.SERVER_URL}/api/broker/employee/${companyName}`, employee, {
      observe: 'response'
    });
  }

  saveEmployee(companyName:String, employeeId:string, employee:Employee): Observable<HttpResponse<EmployeeResponse>>{
    return this.http.put<EmployeeResponse>(`${this.globals.SERVER_URL}/api/broker/employee/${companyName}/${employeeId}`, employee, {
      observe: 'response'
    });
  }

  getAllCompanyEmployees(companyName:string, req?:any, noLoad?:boolean){

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<EmployeeResponse>(`${this.globals.SERVER_URL}/api/broker/companyEmployees/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getAllCompanyPreviousEmployees(companyName:string, req?:any, noLoad?:boolean){

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<EmployeeResponse>(`${this.globals.SERVER_URL}/api/broker/previousCompanyEmployees/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getEmployee(companyName:string, employeeId:string): Observable<HttpResponse<EmployeeResponse>>{
    return this.http.get<EmployeeResponse>(`${this.globals.SERVER_URL}/api/broker/employee/${companyName}/${employeeId}`, {
      observe: 'response'
    });
  }

  removeEmployee(employee: Employee, companyName: String): Observable<HttpResponse<EmployeeResponse>>{
    return this.http.delete<EmployeeResponse>(`${this.globals.SERVER_URL}/api/broker/employee/${companyName}/${employee._id}`, {
      observe: 'response'
    });
  }

  getClientFamilyMembers(clientId:string): Observable<HttpResponse<FamilyMemberResponse>>{
    return this.http.get<FamilyMemberResponse>(`${this.globals.SERVER_URL}/api/broker/client/${clientId}/family`, {
      observe: 'response'
    });
  }

  createNewPolicy(brokerCompany:string, policyDetails: Policy): Observable<HttpResponse<PolicyResponse>>{
    return this.http.post<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/${brokerCompany}`, policyDetails, {
      observe: 'response'
    });
  }

  addPolicyItems(policyNumber: string, companyName: string, policyItems){
    console.log('this service')
    return this.http.put<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policyItems/${policyNumber}/company/${companyName}/activate`, policyItems, {
      observe: 'response'
    });
  }

  removePolicyItems(companyName: string, policyItems){
    return this.http.put<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policyItems/company/${companyName}/deactivate`, policyItems, {
      observe: 'response'
    });
  }

  getPolicies(brokerCompany:string, req?: any, noLoad?:boolean): Observable<HttpResponse<PolicyResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }
    return this.http.get<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getPoliciesPerUser(brokerCompany:string, user:string, req?: any, noLoad?:boolean): Observable<HttpResponse<PolicyResponse>>{

    if(!brokerCompany || !user) return null;
        
    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/user/${user}/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers
    });
  }

  getSelectedPolicyDetails(policyId:string): Observable<HttpResponse<PolicyResponse>>{
    return this.http.get<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/${policyId}`, {
      observe: 'response'
    });
  }

  getPolicyItems(policyId:string, companyName:string, req = {}): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/broker/policyItems/${policyId}/company/${companyName}`, {
      observe: 'response',
      params: req
    });
  }

  deactivatePolicy(policyId:string): Observable<HttpResponse<PolicyResponse>>{
    return this.http.delete<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/${policyId}`, {
      observe: 'response'
    });
  }

  updatePolicy(policyId:string, formData: Policy): Observable<HttpResponse<PolicyResponse>>{
    return this.http.put<PolicyResponse>(`${this.globals.SERVER_URL}/api/broker/policy/${policyId}`, formData, {
      observe: 'response'
    });
  };

  createManualNote(clientId:string, companyName: string, noteFormData): Observable<HttpResponse<ActivityLogResponse>>{
    return this.http.post<ActivityLogResponse>(`${this.globals.SERVER_URL}/api/broker/note/${clientId}/company/${companyName}`, noteFormData, {
      observe: 'response'
    });
  };

  getCompanyLogo(companyName){
    return this.http.get(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/settings/logo`, {
      observe: 'response'
    })
  }

  saveCompanyLogo(companyName, formGroup){
    return this.http.put(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/settings/logo`, formGroup, {
      observe: 'response'
    })
  }

  addCompanyAddress(companyName, addressFormGroup){
    return this.http.put(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/address`, addressFormGroup, {
      observe: 'response'
    })
  }

  editCompanyAddress(companyName, addressFormGroup){
    return this.http.put(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/address/${addressFormGroup._id}`, addressFormGroup, {
      observe: 'response'
    })
  }

  getCompanyAddress(companyName){
    return this.http.get(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/address`, {
      observe: 'response'
    })
  }

  deleteCompanyAddress(companyName, addressId){
    return this.http.delete(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/address/${addressId}`, {
      observe: 'response'
    })
  }

  saveCompanyDetails(companyName, detailsFormGroup){
    return this.http.put(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/details`, detailsFormGroup, {
      observe: 'response'
    })
  }

  getCompanyDetails(companyName){
    return this.http.get(`${this.globals.SERVER_URL}/api/broker/company/${companyName}/details`, {
      observe: 'response'
    })
  }

}
