import { Injectable } from '@angular/core';

@Injectable()
export class Shared {

    createFormData = function(object: Object, form?, namespace?: string): FormData {
        const formData = form || new FormData();
        for (let property in object) {
            // if (!object.hasOwnProperty(property) || !object[property]) {
            // if (object.hasOwnProperty(property) && object[property] != null && object[property] !== undefined) {
            if (!object.hasOwnProperty(property)) {
                continue;
            }
            const formKey = namespace ? `${namespace}[${property}]` : property;
            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
                this.createFormData(object[property], formData, formKey);
            } else {
              let alreadySaved = false;
              for (var pair of formData.entries()) {
                  if((pair[0] == formKey &&  object[property] == pair[1])){
                      alreadySaved = true;
                  }; 
              }
    
              if(!alreadySaved  && !formKey.includes('buffer')){
                formData.append(formKey, object[property]);
              }
                
            }
        }
        return formData;
    }

}