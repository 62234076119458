import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password-create-new',
  templateUrl: './forgot-password-create-new.component.html',
  styleUrls: ['./forgot-password-create-new.component.scss']
})
export class ForgotPasswordCreateNewComponent implements OnInit{

  token: string = this.activeRoute.snapshot.paramMap.get('token');

  passwordStrength: number;

  submitted = false;
  resetPasswordFormGroup: FormGroup;
  get form() { return this.resetPasswordFormGroup.controls; }

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {

    if(!this.token){
      this.router.navigate(['/login']);
    }

    this.authenticationService.findResetPasswordUser(this.token).subscribe(
      (response: HttpResponse<any>) => {
      },
      (error: HttpErrorResponse) => {
        this.toastr.error('This user has not requested a password reset', 'User not found.')
        this.router.navigate(['/login']);
      }
    )
  }

  resetPassword(){

    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordFormGroup.invalid) {
      this.toastr.error('There were some errors on your form.  Please confirm everything is correct and try again.', 'Could not change password.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(this.form.password.value != this.form.newPassword.value){
      this.toastr.error('Your passwords do not match', 'Passwords don\'t match.')
      return;
    }

    this.authenticationService.createNewPassword(this.token, this.resetPasswordFormGroup.value).subscribe(
      (response: HttpResponse<any>) => {
        this.router.navigate(['/login']);
      }
    )
  }

  onStrengthChanged(strength: number) {
    this.passwordStrength = strength;
  }

  ngOnInit() {
    this.resetPasswordFormGroup = this.formBuilder.group({
      password: ['', [ Validators.required, Validators.minLength(7) ]],
      newPassword: ['', [ Validators.required, Validators.minLength(7) ]]
    });
  }

}
