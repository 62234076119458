import { AuthenticationService } from './../../../../../services/authentication.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DeleteModalComponent } from 'src/app/components/modal/delete/delete.component';
import { BrokerClientService } from 'src/app/services/broker-client.service';
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { TrailersAndCaravansResponse, TrailersAndCaravans } from 'src/app/_shared/models/trailersAndCaravans.model';
import { DeleteTrailersAndCaravansModalComponent } from 'src/app/components/modal/deleteTrailersAndCaravans/delete-trailers-and-caravans-modal.component';

@Component({
  selector: 'broker-view-user-trailers-and-caravans-insurance',
  templateUrl: './broker-view-user-trailers-and-caravans-insurance.component.html',
  styleUrls: ['./broker-view-user-trailers-and-caravans-insurance.component.scss'],
  providers: [DeleteTrailersAndCaravansModalComponent, BrokerComponent]
})
export class BrokerViewUserTrailersAndCaravansInsuranceComponent implements OnInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userTrailersAndCaravans: TrailersAndCaravans[]
  previouslyInsuredProducts: TrailersAndCaravans[]

  displayedColumns: string[] = ['type', 'registrationNumber', 'make', 'model', 'year', 'insuredValue', 'inceptionDate', 'menu'];
  resultsLength = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100]
  pageSize = 30;
  isLoadingResults = true;
  isInsuredProductsRateLimitReached = false;
  isPreviousInsuredProductsRateLimitReached = false;

  resultsLengthPreviousInsurance = 0;
  pageSizePreviousInsurance = 30;
  isLoadingResultsPreviousInsurance = true;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private deleteTrailersAndCaravansModal: DeleteTrailersAndCaravansModalComponent,
    private brokerClientService: BrokerClientService,
    private trailersAndCaravansService: TrailersAndCaravansService,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getUserTrailersAndCaravans(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isInsuredProductsRateLimitReached){

      this.isLoadingResults = true;

      this.trailersAndCaravansService.getAllTrailersAndCaravansInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<TrailersAndCaravansResponse>) => {
          this.isLoadingResults = false;
          this.userTrailersAndCaravans = response.body.trailerAndCaravan;

          this.resultsLength = response.body.count;
          if(this.userTrailersAndCaravans.length < this.pageSize){
            this.isInsuredProductsRateLimitReached = true;
          }
        }
      )
    }
  };

  getPreviousInsuredTrailersAndCaravans(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isPreviousInsuredProductsRateLimitReached){

      this.isLoadingResults = true;

      this.trailersAndCaravansService.getPreviousTrailerAndCaravansInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<TrailersAndCaravansResponse>) => {
          this.isLoadingResults = false;
          this.previouslyInsuredProducts = response.body.trailerAndCaravan;

          this.resultsLengthPreviousInsurance = response.body.count;
          if(this.previouslyInsuredProducts.length < this.pageSizePreviousInsurance){
            this.isPreviousInsuredProductsRateLimitReached = true;
          }
        }
      )
    }

  }

  deleteProduct(trailerCaravan){
    trailerCaravan['clientId'] = this.selectedUser;
    this.deleteTrailersAndCaravansModal.openDialog(trailerCaravan);
    this.deleteTrailersAndCaravansModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userTrailersAndCaravans = this.userTrailersAndCaravans.filter(item => item != trailerCaravan);
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserTrailersAndCaravans();
    this.getPreviousInsuredTrailersAndCaravans();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
