import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-broker-email-add-attachment',
  templateUrl: './broker-email-add-attachment.component.html',
  styleUrls: ['./broker-email-add-attachment.component.scss']
})
export class BrokerEmailAddAttachmentComponent implements OnInit {

  emailAttachedFiles: string[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BrokerEmailAddAttachmentComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close({ attachments: this.emailAttachedFiles })
  }

}
