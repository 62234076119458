import { PersonalItem, PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { AllRiskItem, AllRiskItemResponse } from 'src/app/_shared/models/allRiskItem.model';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';

@Component({
  template: ''
})
export class DeletePersonalItemComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(personalItem: AllRiskItem): void {

    this.dialogRef = this.dialog.open(DeletePersonalItemComponentPreview, {
      // width: '600px',
      data: personalItem
    });
  }

}

@Component({
  templateUrl: './delete-personal-item.component.html',
  styleUrls: ['./delete-personal-item.component.scss']
})
export class DeletePersonalItemComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeletePersonalItemComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public personalItem: AllRiskItem,
    public personalInsuranceService: PersonalInsuranceService,
    private brokerAllRiskService: BrokerClientAllRiskServiceService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removePersonalItem(personalItem: PersonalItem){
    this.personalInsuranceService.removePersonalItem(personalItem._id).subscribe(
      (response:HttpResponse<PersonalItemResponse>) => {
        this.closeDialog('success');
      }
    )
  }

  deleteAllRiskItem(item: AllRiskItem){
    this.brokerAllRiskService.deleteInsuranceFromAllRiskItem(item['clientId'], item._id).subscribe(
      (response:HttpResponse<AllRiskItemResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
