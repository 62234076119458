import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {}

    intercept(request, next){
        // const userToken = localStorage.getItem('SureSpace-userToken')

        // if(userToken){
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            const authRequest = request.clone({
                headers: request.headers.set('Authorization', `Token ${currentUser.token}`).set('Company', this.authenticationService.currentCompany.companyName)
            })
            return next.handle(authRequest);
        } else {
            return next.handle(request);
        }

    }
}
