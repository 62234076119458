import { BrokerComponent } from 'src/app/broker/broker.component';
import { Emails } from './../../../../../_shared/models/email.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { BrokerEmailService } from 'src/app/services/broker-email.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'broker-view-client-emails',
  templateUrl: './broker-view-user-emails.component.html',
  styleUrls: ['./broker-view-user-emails.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerViewUserEmailsComponent implements OnInit {

  selectedUser = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  companyName: string = this.authenticationService.currentCompany.companyName;

  emails: Emails[] = [];

  page = 0;

  fetchEmails: boolean = true;

  mailToReadIndex: number = 0;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private brokerEmailService: BrokerEmailService,
    private brokerComponent: BrokerComponent
  ) { }

  ngOnInit(): void {
    this.getAllSentEmails();
  }

  getAllSentEmails(){

    let req = {
      page: this.page,
      limit: 25,
    }

    this.brokerEmailService.getAllSentEmails(this.selectedUser, this.companyName, req).subscribe(response => {

      console.log('email response => ', response.body)

      if(response.body.emails.length < req.limit){
        this.fetchEmails = false;
      }

      this.emails = [...this.emails, ...response.body.emails];

      this.page = this.page + 1;
    })
  }

  createEmailBody(emailBody:string){

    if(emailBody.includes('Claim: ')){}

    if(emailBody.includes('Instruction: ')){}

    if(emailBody.includes('Policy: ')){
      emailBody = emailBody.split('Policy: ')[1].split('<br>')[1];
    }

    return emailBody.substring(0, 255) + '...';
  }

  replyToEmail(){
    // this.brokerComponent.toggleSendingEmail(this.selectedUser, user?.email));
  }

  forwardEmail(email: Emails){

    let emailOptions = {
      forward: true,
      to: email.to,
      from: email.from,
      subject: email.emailSubject,
      body: email.emailBody,
      dateSent: email.createdAt,
      policy: email.policyRef,
      instruction: email.instructionRef,
      claim: email.claimRef,
      quote: email.quoteRef,
    }

    this.brokerComponent.toggleSendingEmail(this.selectedUser, emailOptions)
  }

}
