<div class="surespace-body" [ngClass]="authenticated?'background':'login-background'">

    <header id="surespaceHeader" class="fixed-top col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12" *ngIf="authenticated">

        <div class="d-inline-flex w-50 h-100" style="justify-content: flex-start;">
          {{ brokerCompany?.companyName }}
        </div>

        <div class="d-inline-flex w-50 h-100" style="justify-content: flex-end;">

          <div id="header-icons" class="my-auto">
            <!-- <button class="btn color-label-3 btn-label-hover-dark"><i class="fi flaticon-question mr-0"></i></button>
            <button class="btn color-label-3 btn-label-hover-dark"><i class="fi flaticon-chat mr-0"></i></button> -->

            <!-- BROKER NOTIFICATIONS -->
            <span class="dropdown-default" ngbDropdown (openChange)="onNotificationMenuOpenChange($event)" *ngIf="userRequests?.length > 0">
              <button class="btn color-label-3 btn-label-hover-dark" ngbDropdownToggle>
                <span [matBadge]="userRequests?.length" matBadgeSize="small" class="fi mr-0 flaticon-request fi-bold"></span>
              </button>
              <div [ngClass]='notificationDropdownStatus' class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownManual" x-placement="bottom-right">

                <div class="dropdown-header text-center">
                  <p class="mb-0 my-auto">
                    <span class="font-size-18 color-dark font-weight-bold">Requests</span>
                    <span class="btn btn-label-info btn-sm btn-bold ml-2 mr-0">{{ userRequests?.length }} Total</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <!-- REQUEST -->
                  <div class="notification request unread" *ngFor="let request of userRequests" ngbDropdownItem >
                    <!-- <div class="d-inline-block h-100 my-auto mr-3">
                      <span class='icon'><i class="fi mr-0 flaticon-comment fi-bold"></i></span>
                    </div> -->
                    <div id="notify-holder" class="d-block">
                      <span class='heading'>{{ request?.message }}</span>
                    </div>
                    <div class="col-12 text-center mt-2">
                      <button class="btn btn-success text-white btn-sm" (click)="acceptRequest(request?._id)">Accept</button>
                      <button class="btn btn-danger text-white btn-sm" (click)="rejectRequest(request)">Reject</button>
                      <button class="btn btn-primary btn-sm" (click)="handleRequest(request)">View</button>

                    </div>
                  </div>

                </div>

                <div class="dropdown-footer text-center pt-1 pb-1">
                  <button (click)="this.notificationDropdownStatus = 'ddClosed'" [routerLink]="['/b/notifications']" class="btn btn-light btn-sm">Show all</button>
                </div>

              </div>
            </span>

            <!-- BROKER NOTIFICATIONS -->
            <span class="dropdown-default" ngbDropdown (openChange)="onNotificationMenuOpenChange($event)">
              <button class="btn color-label-3 btn-label-hover-dark" ngbDropdownToggle>
                <span [matBadge]="newNotifications" matBadgeSize="small" [matBadgeHidden]="newNotifications === 0" class="fi mr-0 flaticon-notifications fi-bold"></span>
              </button>
              <div [ngClass]='notificationDropdownStatus' class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownManual" x-placement="bottom-right">

                <div class="dropdown-header text-center">
                  <p class="mb-0 my-auto">
                    <span class="font-size-18 color-dark font-weight-bold">Notifications</span>
                    <span *ngIf="newNotifications > 0" class="btn btn-label-info btn-sm btn-bold ml-2 mr-0">{{ newNotifications }} New</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <!-- REQUEST -->
                  <div class="notification request unread" *ngFor="let request of userRequests" ngbDropdownItem >
                    <!-- <div class="d-inline-block h-100 my-auto mr-3">
                      <span class='icon'><i class="fi mr-0 flaticon-comment fi-bold"></i></span>
                    </div> -->
                    <div id="notify-holder" class="d-block">
                      <span class='heading'>{{ request?.request }}</span>
                    </div>
                    <div class="col-12 text-center mt-2">
                      <button class="btn btn-primary btn-sm" (click)="handleRequest(request?._id, 'accept')">Accept</button>
                      <button class="btn btn-secondary btn-sm" (click)="handleRequest(request?._id, 'decline')">Decline</button>
                    </div>
                  </div>

                  <!-- NOTIFICATIONS -->
                  <button class="d-flex notification" *ngFor="let notification of notifications" ngbDropdownItem [ngClass]="{'unread': !notification.isSeen}" (click)="notificationClick(notification)">
                    <div class="d-inline-block h-100 my-auto mr-3">
                      <span class='icon'><i class="fi mr-0 flaticon-comment fi-bold"></i></span>
                    </div>
                    <div id="notify-holder" class="d-inline-block">
                      <span class='heading'>{{ notification?.message }}</span>
                      <span class='description'>{{ notification?.createdAt | date: 'dd MMM yyyy - HH:mm' }}</span>
                    </div>
                  </button>
                </div>

                <div class="dropdown-footer text-center pt-1 pb-1">
                  <button (click)="this.notificationDropdownStatus = 'ddClosed'" [routerLink]="['/b/notifications']" class="btn btn-light btn-sm">Show all</button>
                </div>

              </div>
            </span>

          </div>

            <div class="split mr-0"></div>

            <div id="me" class="my-auto text-right" ngbDropdown>

              <button class="btn btn-label-hover-dark ml-2 mr-0" id='profile-icon' ngbDropdownToggle (click)="dropDownClicked()">
                <span class="color-label-2 ng-binding">Hi, <strong>{{ this.currentUser?.firstName }}</strong></span>
                <div class="ml-2 userpic userpic-brand userpic-md">
                  <span>{{ this.currentUser?.firstName.substring(0, 1) }}{{ this.currentUser?.lastName.substring(0, 1) }}</span>
                </div>
              </button>

              <div [ngClass]='ddStatus' ngbDropdownMenu aria-labelledby="dropdownManual">
                <div class="dropdown-header d-flex">
                  <div class="userpic userpic-lg userpic-dark">
                    <span>{{ this.currentUser?.firstName.substring(0, 1) }}{{ this.currentUser?.lastName.substring(0, 1) }}</span>
                  </div>
                  <p class="mb-0 ml-3 my-auto">
                    <span class="name">{{ this.currentUser?.firstName }} {{ this.currentUser?.lastName }}</span><br>
                    <span class="id">{{ this.currentUser?.idNumber }}</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <!-- <button ngbDropdownItem >
                    <span class='heading'>My Profile</span>
                    <span class='description'>Personal information and more</span>
                  </button> -->

                  <button ngbDropdownItem [routerLink]="['/b/settings']">
                    <span class='heading'>Settings</span>
                    <span class='description'>Password, notifications and more</span>
                  </button>

                  <button *ngIf="hasPermissions('brokerDirector')" ngbDropdownItem [routerLink]="['/b/companySettings']">
                    <span class='heading'>Company details</span>
                    <span class='description'>Company related details and info</span>
                  </button>

                  <button ngbDropdownItem [routerLink]="['/b/companyDocuments']">
                    <span class='heading'>Company documents</span>
                    <span class='description'>Documents related to your company</span>
                  </button>

                  <button ngbDropdownItem [routerLink]="['faq']">
                    <span class='heading'>FAQ</span>
                    <span class='description'>Frequently asked questions</span>
                  </button>

                  <!-- <button ngbDropdownItem>
                    <span class='heading'>My Messages</span>
                    <span class='description'>Inbox and tasks</span>
                  </button> -->
                </div>

                <div class="dropdown-footer">
                  <div class="row">
                    <div class="col-6 text-left">
                      <button [routerLink]="['/logout']" class="btn btn-outline-info">Sign out</button>
                    </div>
                    <div class="col-6 text-right">
                      <!-- <button class="btn btn-secondary" [routerLink]="['/pricing']">Plan options</button> -->
                    </div>
                  </div>
                </div>

              </div>

            </div>

        </div>

    </header>

    <mat-sidenav-container [hasBackdrop]="mobileQuery.matches" [ngClass]="authenticated ? 'min-height-full' : ''" autosize>

      <mat-sidenav
        #mainNavigation
        role="navigation"
        [ngClass]="authenticated ? 'col-xl-2 col-lg-2 col-md-3 col-sm-7 col-8' : 'd-none'"
        mode="push"
        [opened]="!mobileQuery.matches"
        [disableClose]="!mobileQuery.matches">

        <app-dashboard-menu
          (navToggle)="mobileQuery.matches ? mainNavigation.toggle() : null">
        </app-dashboard-menu>

      </mat-sidenav>

      <mat-sidenav-content [ngClass]="authenticated ? 'col-xl-10 offset-xl-2 col-lg-10 offset-lg-2 col-md-9 offset-md-3 col-sm-12 col-12 h-100-vh' : 'col-12'" role="region" [ngStyle]="{ 'margin-left': '100px' }">

        <div class="row">

          <!-- <div [ngClass]="authenticated ? 'col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12' : 'col-12'"> -->
          <div class="col-12">
            <router-outlet (activate)="onActivate($event)">
            <!-- (click)="dismissSidebar()" -->
            </router-outlet>
          </div>

          <div *ngIf="authenticated" id="advertising" class="col-xl-3 col-lg-3 col-md-4 col-sm-4 d-none d-md-block">
            <!-- <app-advertising></app-advertising> -->
          </div>

        </div> <!-- Close div for #content .row -->

      </mat-sidenav-content>

    </mat-sidenav-container>

    <footer *ngIf="!authenticated">

      <div class="container">

        <div class="row mt-3">

          <div class="col-12 text-center d-flex">

            <ul class="list-inline mx-auto justify-content-center">

              <li class="list-inline-item">
                <a href="/login">Login</a>
              </li>

              <li class="list-inline-item">
                <a href="/about">About</a>
              </li>

              <li class="list-inline-item">
                <a href="/faq">FAQ</a>
              </li>

              <li class="list-inline-item">
                <a href="/reportIssue">Report an issue</a>
              </li>

              <li class="list-inline-item">
                <a href="/suggestions">Suggestions</a>
              </li>

              <li class="list-inline-item">
                <a href="/termsAndConditions">Terms and conditions</a>
              </li>

            </ul>

          </div> <!-- Close div for .col-12 -->

          <div class="col-12 text-center d-flex">

            <ul class="list-inline mx-auto justify-content-center">

              <li class="list-inline-item">
                <a class="facebook" href="https://www.facebook.com/surespace/" target="_blank">
                  <i class="fi fi-button fi-big flaticon-fb"></i>
                </a>
              </li>

              <li class="list-inline-item">
                <a class="twitter" href="https://twitter.com/_surespace" target="_blank">
                  <i class="fi fi-button fi-big flaticon-twitter"></i>
                </a>
              </li>

            </ul>

          </div> <!-- Close div for .col-12 -->

          <div class="col-12 text-center">
            <p class="color-light-grey">&copy; 2021 SureSpace</p>
          </div> <!-- Close div for .col-12 -->

        </div>

      </div>
      <!-- Close div for .container -->

    </footer>

    <broker-compose-new-email *ngIf="brokerEmailService.composingNewEmail" [sendToEmail]="sendToEmail"></broker-compose-new-email>

  </div>
  <!-- Close div for .surespace-body -->