import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { HttpResponse } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalItem, PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-client-edit-personal-item',
  templateUrl: './client-edit-personal-item.component.html',
  styleUrls: ['./client-edit-personal-item.component.scss']
})
export class ClientEditPersonalItemComponent implements OnInit, AfterViewInit {

  maxDate = new Date();

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  maxUploads: number = 6;

  public Editor = ClassicEditor;

  submitted = false;
  personalItemFormGroup: FormGroup;
  get form() { return this.personalItemFormGroup.controls; }

  personalItem: PersonalItem = {};
  personalItemId =  this.activeRoute.snapshot.paramMap.get('id');

  constructor(
    private formBuilder: FormBuilder,
    private insuranceService: InsuranceService,
    private personalInsuranceService: PersonalInsuranceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private lightbox: Lightbox,
  ) {
    this.options = { concurrency: 2, maxUploads: 6, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  };

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  };

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  getAllInsuranceCompanies(){
    this.insuranceService.getAllInsuranceCompanies().subscribe(
      (response: HttpResponse<InsuranceCompanyResponse>) => {
        this.insuranceCompanies = response.body.insuranceCompany;
      }
    )
  };

  getCurrentPersonalItem(id){

    this.personalInsuranceService.getSelectedPersonalItem(id).subscribe(
      (response:HttpResponse<PersonalItemResponse>) => {
        this.personalItemFormGroup.patchValue(response.body.personalItem);

        this.maxUploads = this.maxUploads - response.body.personalItem['s3Files'].length;

        this.personalItemFormGroup.value.newPhotos = [];
        this.personalItemFormGroup.value.removedPhotos = [];
      }
    )
  };

  removeExistingPersonalItemPhoto(photo){
    //remove the image from the array so that it is not in the view
    this.personalItemFormGroup.value.s3Files = this.personalItemFormGroup.value.s3Files.filter((name, photoIndex) => { return name.key !== photo.key });
    this.personalItemFormGroup.value.photos = this.personalItemFormGroup.value.photos.filter((name, photoIndex) => { return name.key !== photo.key });

    this.personalItemFormGroup.value.removedPhotos.push(photo)
  }

  updatePersonalItem(saveType:string){

    this.submitted = true;
    // stop here if form is invalid
    if (this.personalItemFormGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save personal item.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    // Set photos == files
    this.files.map(file => {
      this.personalItemFormGroup.value.photos.push(file.nativeFile)
    });

    this.personalInsuranceService.updatePersonalItem(this.personalItemFormGroup.value).subscribe(
      (response:HttpResponse<PersonalItemResponse>) => {
        
        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            break;

          default:
            this.goBack();
            break;
        }

      }
    )
  };

  goBack(){
    this.router.navigate(['/u/short-term-insurance/personal-item']);
  }

  addNewBroker(){
    localStorage.setItem('SureSpace-PersonalItem', JSON.stringify(this.personalItemFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-PersonalItem')){
      this.personalItemFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-PersonalItem')));
      localStorage.removeItem('SureSpace-PersonalItem');
    }
  }; 
  
  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.personalItemFormGroup.value.s3Files, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  ngOnInit() {
    this.personalItemFormGroup = this.formBuilder.group({
      item: ['', Validators.required],
      photos: [[]],
      newPhotos: [[]],
      removedPhotos: [[]],
      s3Files: [[]],
      itemDescription: ['', Validators.required],
      itemCategory : ['', Validators.required],
      itemSerial : [''],
      itemQuantity : [''],
      itemRoom : [''],
      purchaseDate : [''],
      purchasePrice : [''],
      warranty : [''],
      specified : [''],
      insured: ['No'],
      insuredWith: [''],
      dateInsured: [''],
      insuredValue: [''],
      policyNumber: ['']
    });
  };

  ngAfterViewInit() {   
    this.getCurrentPersonalItem(this.personalItemId)
    this.getAllBrokers();
    this.getAllInsuranceCompanies();
    this.getLocalStorageItem(); 
  }

}

