<div id="mangement_registered-users">

  <div class="panel panel-new">

    <div class="panel-header">

      <div class="row">

        <div class="col-12">
          <h4>Registered users</h4>
        </div>

      </div>
      <!-- Close div for .row -->

    </div>
    <!-- Close div for .panel-header -->

    <div class="panel-body">

      <div class="col-lg-4">
        <!-- TODO: If vehicles insured-->

        <div class="row">

          <div class="col-12 text-center">
            <h4>Total registered users</h4>
            <div class="totalItems blue">
              <h2>{{ registeredUserCount }}</h2>
            </div>
          </div>

          <div class="col-12 clearfix">
            <br />
          </div>

          <div class="col-12" class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td class="text-left">
                    <strong>Total users:</strong>
                  </td>
                  <td class="text-left">
                    {{ registeredUserCount - registeredCompanyCount - registeredBrokerCount - registeredInsuranceCompanyCount }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <strong>Total companies:</strong>
                  </td>
                  <td class="text-left">
                    {{ registeredCompanyCount }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <strong>Total brokers:</strong>
                  </td>
                  <td class="text-left">
                    {{ registeredBrokerCount }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <strong>Total insurance companies:</strong>
                  </td>
                  <td class="text-left">
                    {{ registeredInsuranceCompanyCount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div> <!-- Close div for .row -->

      </div>

    </div> <!-- Close div for .panel-body -->

  </div> <!-- Close div for .panel -->

</div> <!-- Close div for #mangement_registered-users -->
