<div class="page-description">
    <div class="row h-100">
        <div class="col-6 my-auto">
            <h5 class="d-inline-block mb-0 font-weight-400">Household insurance</h5>
            <h6 class="d-inline-block mb-0">Add a new houshold item and save</h6>
        </div>
    
        <div class="col-6 text-right my-auto">

            <button type="button" class="btn btn-secondary" (click)="goBack()">
                Back
            </button>
        
            <div class="btn-group" ngbDropdown>
                <button type="button" class="btn btn-primary" (click)="addNewHouseholdItem('continue')">
                    Save item
                </button>
                <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" (click)="addNewHouseholdItem('continue')">Save &amp; continue</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="addNewHouseholdItem('new')">Save &amp; add new</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="addNewHouseholdItem('back')">Save &amp; go back</a></li>
                </ul>
            </div> <!-- Close div for .btn-group -->
    
        </div>
    </div> <!-- Close div for .row -->
</div> <!-- Close div for .page-description -->

<div class="row">

    <div class="col-12">
    
        <div class="panel panel-primary">
            <form id="newHouseholdItemForm" [formGroup]="newHouseholdItemFormGroup">
      
              <div class="panel-heading">
                <h5>Information</h5>
              </div>
      
              <div class="panel-body">
      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="item"><strong>Item name:</strong></label>
                        <input
                          type="text"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && form['item'].errors }"
                          name="item"
                          formControlName="item"
                        />
                        <div *ngIf="submitted && form['item'].errors" class="invalid-feedback">
                          <div *ngIf="form['item'].errors.required">Please specify an item.</div>
                        </div>
                      </div>                  
      
                    </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>
      
                    <div class="row">
      
                      <div class="col-12">
                        <label for="itemDescription"><strong>Item description:</strong></label>
                        <ckeditor
                          id="editor"
                          name="itemDescription"
                          [editor]="Editor"
                          [ngClass]="{ 'is-invalid': submitted && form.itemDescription.errors }"
                          formControlName="itemDescription">
                        </ckeditor>
                        <div *ngIf="submitted && form['itemDescription'].errors" class="invalid-feedback">
                          <div *ngIf="form['itemDescription'].errors.required">Please add a description to your item.</div>
                        </div>
                      </div>
      
                    </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>

                  <div class="row">
      
                    <div class="col-12">
                      
                      <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                        [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
        
                        <i class="fi flaticon-upload"></i>
                        <strong>Drop photos here<br />or <span class="text-bold color-primary">click to select</span></strong>
        
                        <label class="upload-button">
                          <input #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                            (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                        </label> <!-- Close .upload-button -->
        
                      </div><!-- close .drop-container -->
        
                      <div>
                        <div id="uploads" class="row" *ngIf="files?.length">
                          <div id="upload-item" class="col-sm-2 col-4" *ngFor="let f of files; let i = index;">
        
                            <div class="thumbnail">
                              <img src="{{f['imagePreview']}}" class="img-fluid" />
                              <!-- <button (click)="removeFile(f.id)"></button> -->
                              <div class="removeImage">
                                <div class="remove" (click)="removeFile(f.id)">
                                  x
                                </div> <!-- Close div for .remove -->
                              </div> <!-- Close div for .removeImage -->
                            </div>
        
                          </div>
                        </div>
                      </div>
                    </div> <!-- Close div for .col-12 -->
                  </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>
      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="itemCategory"><strong>Item category:</strong></label>
                        <div class="input-group">
                          <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form.itemCategory.errors }">
                            <mat-select name="itemCategory" formControlName="itemCategory">
                              <mat-option option value="" disabled>None</mat-option>
                              <mat-option value="Appliances">Appliances</mat-option>
                              <mat-option value="Clothing">Clothing</mat-option>
                              <mat-option value="Collectables">Collectables</mat-option>
                              <mat-option value="Electronics">Electronics</mat-option>
                              <mat-option value="Food">Food</mat-option>
                              <mat-option value="Furniture">Furniture</mat-option>
                              <mat-option value="Jewelery">Jewelery</mat-option>
                              <mat-option value="Linen">Linen</mat-option>
                              <mat-option value="Sport equipment">Sport equipment</mat-option>
                              <mat-option value="Tools">Tools</mat-option>
                              <mat-option value="Miscellaneous">Miscellaneous</mat-option>
                            </mat-select>
                          </mat-form-field>
      
                          <div *ngIf="submitted && form['itemCategory'].errors" class="invalid-feedback">
                            <div *ngIf="form['itemCategory'].errors.required">Please select a category for your item.</div>
                          </div>
                          
                        </div> <!-- Close div for .input-group-->
                      </div>
      
                      <div class="col-12 clearfix d-sm-none d-block">
                      </div>
      
                      <div class="col-sm-6 col-12">
                        <label for="itemSerial"><strong>Item serial number:</strong></label>
                        <input
                          type="text"
                          name="itemSerial"
                          class="form-control"
                          formControlName="itemSerial"
                        />
                      </div>
      
                    </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>
      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="itemQuantity"><strong>Item quantity:</strong></label>
                        <input type="number" name="itemQuantity"  class="form-control"formControlName="itemQuantity" />
                      </div>
      
                      <div class="col-12 clearfix d-sm-none d-block">
                      </div>
      
                      <div class="col-sm-6 col-12">
                        <label for="itemRoom"><strong>Area/Room item:</strong></label>
                        <input type="text" class="form-control" name="itemRoom" formControlName="itemRoom" />
                      </div>
      
                    </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>
      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="purchaseDate"><strong>Purchase date:</strong></label>
                        <div class="input-group">
                          <mat-form-field class="form-control">
                            <input matInput [matDatepicker]="purchaseDate" name="purchaseDate" formControlName="purchaseDate" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="purchaseDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #purchaseDate></mat-datepicker>
                          </mat-form-field>
                        </div> <!-- Close div for .input-group-->
                      </div>
      
                      <div class="col-12 clearfix d-sm-none d-block">
                      </div>
      
                      <div class="col-sm-6 col-12">
                        <label for="purchasePrice"><strong>Purchase price:</strong></label>                
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">R</div>
                          </div>
                          <input type="text" class="form-control" name="purchasePrice" formControlName="purchasePrice" />
                        </div>
                      </div>
      
                    </div> <!-- Close div for .row -->
      
                  <div class="col-12 clearfix mt-3">
                  </div>
      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="warranty"><strong>Warranty (months):</strong></label>
                        <input type="text" class="form-control" name="warranty" formControlName="warranty" />
                      </div>
      
                    </div> <!-- Close div for .row -->

                </div> <!-- Close div for .panel-body -->

                <div class="panel-heading mt-4">
                  <h5>Insurance</h5>
                </div>

                  <div class="panel-body">

      
                    <div class="row">
      
                      <div class="col-sm-6 col-12">
                        <label for="purchaseDate"><strong>Specified:
                            <span class="help" matTooltip="Specified items are items that is not static in one place. i.e. a laptop that can be transported in your car or left at home.  Can be valuable items that are kept in safe."
                              matTooltipPosition="above">
                              ?
                            </span>
                          </strong>
                        </label>
                        <div class="input-group">
                          <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form.specified.errors }">
                            <mat-select name="specified" formControlName="specified">
                              <mat-option value="" disabled>Please select an option</mat-option>
                              <mat-option value="Yes">Yes</mat-option>
                              <mat-option value="No">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
      
                      <div class="col-12 clearfix d-sm-none d-block">
                      </div>
      
                      <div class="col-sm-6 col-12">
                        <label for="purchaseDate"><strong>Insured value:</strong> </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">R</div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.insuredValue.errors }"
                            name="insuredValue"
                            formControlName="insuredValue"
                          />
                          <div *ngIf="submitted && form['insuredValue'].errors" class="invalid-feedback">
                            <div *ngIf="form['insuredValue'].errors.required">Please specify what your item is insured for.</div>
                          </div>
                        </div>
                      </div>
      
                    </div> <!-- Close div for .row -->
      
                  </div> <!-- Close div for .panel-body -->

            </form>
          </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for .row -->
