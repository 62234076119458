<table mat-table [dataSource]="policyItems">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Structure type</th>
        <td mat-cell *matCellDef="let row">{{ row?.residenceType | sentenceCase }}</td>
      </ng-container>

      <!-- Structure suburb Column -->
      <ng-container matColumnDef="suburb">
        <th mat-header-cell *matHeaderCellDef>Structure suburb</th>
        <td mat-cell *matCellDef="let row">{{ row?.address?.suburb }}</td>
      </ng-container>

      <!-- Structure city Column -->
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>Structure city</th>
        <td mat-cell *matCellDef="let row">{{ row?.address?.city }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="insuredValue">
        <th mat-header-cell *matHeaderCellDef>Insured Value</th>
        <td mat-cell *matCellDef="let row">{{ row?.insurance?.insuredValue | currency: 'R ' }}</td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>