<div id="content" class="row">

  <form [formGroup]="commercialClientFormGroup" class="w-100">

    <sub-navigation heading="New client"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
  
          <h5>Add a new commercial client</h5>
  
          <div class="my-auto">
  
            <button matTooltip="Go back to view clients" class="btn btn-secondary my-auto" (click)="location.back()"><i class="fi flaticon-back"></i>Back</button>
            <button
              matTooltip="Add new client and go back"
              class="btn btn-brand my-auto"
              (click)="addClient('back')"
              >
                Add client
            </button>
          
            <button class="btn btn-light btn-pill my-auto color-brand" [matMenuTriggerFor]="menuEdit">
              <i class="fi flaticon-menu mr-0"></i>
            </button>
  
            <mat-menu xPosition="before" yPosition="below" #menuEdit="matMenu">
              <button (click)="addClient('back')" mat-menu-item>
                <span>Save &amp; go back</span>
              </button>
              <button (click)="addClient('new')" mat-menu-item>
                <span>Save &amp; add new</span>
              </button>
            </mat-menu>
  
          </div> <!-- Close div for .my-auto -->
  
        </div> <!-- Close div for .panel-heading -->
  
        <div class="panel-body pb-5">
            <broker-commercial-clients-form-group formControlName="client"></broker-commercial-clients-form-group>
        </div>
      </div>
    </div>

  </form>
</div>
