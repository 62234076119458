import { SharedModule } from './../_shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrokerComponent } from './broker.component';
import { DashboardMenuComponent } from 'src/app/dashboard-menu/dashboard-menu.component';
import { RouterModule } from '@angular/router';
import { BrokerEmployeesComponent } from './employees/broker-employees.component';
import { BrokerClientsComponent } from './clients/broker-clients.component';
import { NewBrokerClientComponent } from './clients/new-client/new-client.component';
import { EditBrokerClientComponent } from './clients/edit-client/edit-client.component';
import { BrokerViewUserPersonalInsuranceComponent } from './clients/edit-client/templates/broker-view-user-personal-insurance/broker-view-user-personal-insurance.component';
import { BrokerViewUserHouseholdInsuranceComponent } from './clients/edit-client/templates/broker-view-user-household-insurance/broker-view-user-household-insurance.component';
import { BrokerViewUserVehicleInsuranceComponent } from './clients/edit-client/templates/broker-view-user-vehicle-insurance/broker-view-user-vehicle-insurance.component';
import { BrokerViewUserBuildingInsuranceComponent } from './clients/edit-client/templates/broker-view-user-building-insurance/broker-view-user-building-insurance.component';
import { ClientInvestmentsComponent } from './clients/modules/investments/client-investments.component';
import { NewClientInvestmentComponent } from './clients/modules/investments/new-investment/new-client-investment.component';
import { EditClientInvestmentComponent } from './clients/modules/investments/edit-investment/edit-client-investment.component';
import { BrokerEditVehicleComponent } from './short-term-insurance/vehicle/edit-vehicle/broker-edit-vehicle.component';
import { BrokerEmployeeInformationComponent } from './employees/view-broker/broker-employee-information.component';
import { BrokerActivityLogComponent } from './reporting/activityLog/broker-activity-log.component';
import { BrokerClientPoliciesComponent } from './clients/policies/broker-client-policies.component';
import { BrokerNewEditClientVehicleComponent } from './short-term-insurance/vehicle/broker-new-edit-client-vehicle.component';
import { BrokerNewEditClientFamilyMemberComponent } from './clients/familyMembers/NewEditFamilyMember/broker-new-edit-client-family-member.component';
import { BrokerNewEditClientPoliciesComponent } from './clients/policies/NewEditPolicies/broker-new-edit-client-policies.component';
import { BrokerAdministratorsComponent } from './administrators/broker-administrators.component';
import { BrokerUnderwritersComponent } from './underwriters/broker-underwriters.component';
import { BrokerNewEditAdministratorComponent } from './administrators/newEditAdministrator/broker-new-edit-administrator.component';
import { BrokerNewEditUnderwriterComponent } from './underwriters/newEditUnderwriter/broker-new-edit-underwriter.component';
import { BrokerAdminInstructionComponent } from './admin-instructions/broker-admin-instruction.component';
import { BrokerNewEditAdminInstructionComponent } from './admin-instructions/newEditInstruction/broker-new-edit-admin-instruction.component';
import { BrokerClaimsComponent } from './claims/broker-claims.component';
import { BrokerNewEditClaimsComponent } from './claims/newEditClaim/broker-new-edit-claims.component';
import { BrokerViewUserNotesComponent } from './clients/edit-client/templates/broker-view-user-notes/broker-view-user-notes.component';
import { BrokerViewUserPoliciesComponent } from './clients/edit-client/templates/broker-view-user-policies/broker-view-user-policies.component';
import { BrokerSettingsComponent } from './settings/broker-settings.component';
import { BrokerComposeNewEmailComponent } from './clients/email/new-email/broker-compose-new-email.component';
import { BrokerSettingsEmailComponent } from './settings/emailSettings/broker-settings-email.component';
import { BrokerUploadAnyDocumentModalComponent } from './clients/documents/uploadAnyDocumentModal/broker-upload-any-document-modal.component';
import { BrokerViewClientDocumentsComponent } from './clients/documents/viewDocuments/broker-view-client-documents.component';
import { BrokerDisplayClientDocumentModalComponent } from './clients/documents/displayDocumentsModal/broker-display-client-document-modal.component';
import { BrokerNewEditClientHomeownerInsuranceComponent } from './short-term-insurance/homeOwners/broker-new-edit-client-homeowner-insurance.component';
import { BrokerNewEditClientHomeContentInsuranceComponent } from './short-term-insurance/home-content/broker-new-edit-client-home-content-insurance.component';
import { BrokerViewUserAllRiskInsuranceComponent } from './clients/edit-client/templates/broker-view-user-all-risk-insurance/broker-view-user-all-risk-insurance.component';
import { BrokerNewEditClientAllRiskInsuranceComponent } from './short-term-insurance/all-risk/broker-new-edit-client-all-risk-insurance.component';
import { BrokerEditClientDocumentDetailsComponent } from './clients/documents/editDocumentDetails/broker-edit-client-document-details.component';
import { BrokerCreateUserCustomNoteComponent } from './clients/edit-client/templates/broker-view-user-notes/custom-note/broker-create-user-custom-note.component';
import { BrokerViewClientFamilyMembersComponent } from './clients/familyMembers/broker-view-client-family-members.component';
import { SelectNewPolicyVehicleItemsComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/vehicles/select-new-policy-vehicle-items.component';
import { SelectNewPolicyAllRiskItemsComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/allRisk/select-new-policy-all-risk-items.component';
import { SelectNewPolicyHouseholdContentItemsComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/householdItems/select-new-policy-household-content-items.component';
import { SelectNewPolicyItemBuildingComponentComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/building/select-new-policy-item-building-component.component';
import { BrokerNewEditClientCaranvansAndTrailersComponent } from './short-term-insurance/trailersAndCaravans/broker-new-edit-client-caranvans-and-trailers.component';
import { BrokerViewUserTrailersAndCaravansInsuranceComponent } from './clients/edit-client/templates/broker-view-user-trailers-caravans-insurance/broker-view-user-trailers-and-caravans-insurance.component';
import { SelectNewPolicyItemsTrailersAndCaravansComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/trailersAndCaravans/select-new-policy-items-trailers-and-caravans.component';
import { BrokerQuotesComponent } from './quotes/broker-quotes.component';
import { BrokerNewEditQuotesComponent } from './quotes/newEditQuotes/broker-new-edit-quotes.component';
import { BrokerCalendarComponent } from './calendar/broker-calendar.component';
import { BrokerCreateNewCalendarEventComponent } from './calendar/new-event/broker-create-new-calendar-event.component';
import { BrokerViewCalendarEventComponent } from './calendar/view-event/broker-view-calendar-event.component';
import { BrokerNewEditClientValueAddedProductsComponent } from './short-term-insurance/value-added-products/broker-new-edit-client-value-added-products/broker-new-edit-client-value-added-products.component';
import { BrokerViewUserValueAddedProductsComponent } from './clients/edit-client/templates/broker-view-user-vaps/broker-view-user-value-added-products.component';
import { DeleteValueAddedProductComponent } from '../components/modal/delete-value-added-product/delete-value-added-product.component';
import { SelectNewPolicyItemsValueAddedProdductsComponent } from './clients/policies/NewEditPolicies/select-new-policy-items/valueAddedProducts/select-new-policy-items-value-added-prodducts.component';
import { RejectQuoteModalComponent } from './quotes/rejectQuote/reject-quote-modal.component';
import { ApproveQuoteModalComponent } from './quotes/approveQuote/approve-quote-modal.component';
import { BrokerViewUserClaimsComponent } from './clients/edit-client/templates/broker-view-user-claims/broker-view-user-claims.component';
import { BrokerViewUserInstructionsComponent } from './clients/edit-client/templates/broker-view-user-instructions/broker-view-user-instructions.component';
import { BrokerEmailAddAttachmentComponent } from './clients/email/new-email/attachments/broker-email-add-attachment.component';
import { BrokerViewUserEmailsComponent } from './clients/edit-client/templates/broker-view-user-emails/broker-view-user-emails.component';
import { BrokerClaimFormGroupComponent } from './claims/brokerClaimFormGroup/broker-claim-form-group.component';
import { BrokerClaimsNewClaimComponent } from './claims/newClaim/broker-claims-new-claim.component';
import { BrokerClaimsEditClaimComponent } from './claims/editClaim/broker-claims-edit-claim.component';
import { BrokerAdminInstructionNewComponent } from './admin-instructions/new-instruction/broker-admin-instruction-new.component';
import { BrokerAdminInstructionEditComponent } from './admin-instructions/edit-instruction/broker-admin-instruction-edit.component';
import { BrokerAdminInstructionFormGroupComponent } from './admin-instructions/brokerAdminInstructionFormGroup/broker-admin-instruction-form-group.component';
import { BrokerReturnedDebitsComponent } from './returned-debits/broker-returned-debits.component';
import { BrokerReturnedDebitNewDebitComponent } from './returned-debits/new-returned-debit/broker-returned-debit-new-debit.component';
import { BrokerReturnedDebitEditDebitComponent } from './returned-debits/edit-returned-debit/broker-returned-debit-edit-debit.component';
import { ReturnedDebitOrderFormGroupComponent } from './returned-debits/returnedDebitOrderFormGroup/returned-debit-order-form-group.component';
import { BrokerViewUserReturnedDebitComponent } from './clients/edit-client/templates/broker-view-user-returned-debit/broker-view-user-returned-debit.component';
import { AdminInstructionHandoverModalComponent } from './admin-instructions/edit-instruction/handover-instruction/admin-instruction-handover-modal.component';
import { BrokerClaimsHandoverModalComponent } from './claims/editClaim/claim-handover/broker-claims-handover-modal.component';
import { BrokerViewUserQuotesComponent } from './clients/edit-client/templates/broker-view-client-quotes/broker-view-user-quotes.component';
import { BrokerCommercialClientsComponent } from './commercial/broker-commercial-clients.component';
import { BrokerCommercialClientsFormGroupComponent } from './commercial/commercial-clients-form-group/broker-commercial-clients-form-group.component';
import { BrokerCommercialClientsNewComponent } from './commercial/new-client/broker-commercial-clients-new.component';
import { BrokerCommercialClientsEditComponent } from './commercial/edit-client/broker-commercial-clients-edit.component';
import { BrokerCommercialClientDeleteComponent } from './commercial/delete-client/broker-commercial-client-delete.component';
import { BrokerCommercialClientsProductsComponent } from './commercial/products/broker-commercial-clients-products.component';
import { BrokerCommercialClientsProductsNewComponent } from './commercial/products/new-products/broker-commercial-clients-products-new.component';
import { BrokerCommercialClientsProductsEditComponent } from './commercial/products/edit-products/broker-commercial-clients-products-edit.component';
import { BrokerNotificationsComponent } from './clients/notifications/broker-notifications.component';
import { BrokerReportingComponent } from './reporting/broker-reporting.component';
import { BrokerReportingInstructionsComponent } from './reporting/instructions/broker-reporting-instructions.component';
import { BrokerReportingClaimsComponent } from './reporting/claims/broker-reporting-claims.component';
import { BrokerReportingPoliciesComponent } from './reporting/policies/broker-reporting-policies.component';
import { BrokerReportingQuotesComponent } from './reporting/quotes/broker-reporting-quotes.component';
import { BrokerReportingReturnedDebitComponent } from './reporting/returnedDebits/broker-reporting-returned-debit.component';
import { BrokerReportingEmployeesComponent } from './reporting/employees/broker-reporting-employees.component';
import { BrokerReportingCalendarEventsComponent } from './reporting/calendarEvents/broker-reporting-calendar-events.component';
import { BrokerReportingClientsComponent } from './reporting/clients/broker-reporting-clients.component';
import { BrokerCompanySettingsComponent } from './settings/companySettings/broker-company-settings.component';
import { BrokerNewCompanyLocationComponent } from './settings/companySettings/address/newAddress/broker-new-company-location.component';
import { BrokerEditCompanyLocationComponent } from './settings/companySettings/address/editAddress/broker-edit-company-location.component';
import { BrokerDeleteCompanyLocationComponent } from './settings/companySettings/address/deleteAddress/broker-delete-company-location.component';
import { BrokerCommercialProductDeleteComponent } from './commercial/products/delete-products/broker-commercial-product-delete.component';
import { BrokerCompanyDocumentsComponent } from './settings/companyDocuments/broker-company-documents.component';
import { UploadCompanyDocumentsComponent } from './settings/companyDocuments/uploadDocuments/upload-company-documents.component';
import { DeleteTrailersAndCaravansModalComponent } from '../components/modal/deleteTrailersAndCaravans/delete-trailers-and-caravans-modal.component';


@NgModule({
  declarations: [
    BrokerComponent,
    DashboardMenuComponent,
    BrokerEmployeesComponent,
    BrokerClientsComponent,
    NewBrokerClientComponent,
    EditBrokerClientComponent,
    BrokerViewUserPersonalInsuranceComponent,
    BrokerViewUserHouseholdInsuranceComponent,
    BrokerViewUserVehicleInsuranceComponent,
    BrokerViewUserBuildingInsuranceComponent,
    ClientInvestmentsComponent,
    NewClientInvestmentComponent,
    EditClientInvestmentComponent,
    BrokerEditVehicleComponent,
    BrokerEmployeeInformationComponent,
    BrokerActivityLogComponent,
    BrokerClientPoliciesComponent,
    BrokerNewEditClientVehicleComponent,
    BrokerNewEditClientFamilyMemberComponent,
    BrokerNewEditClientPoliciesComponent,
    BrokerAdministratorsComponent,
    BrokerUnderwritersComponent,
    BrokerNewEditAdministratorComponent,
    BrokerNewEditUnderwriterComponent,
    BrokerAdminInstructionComponent,
    BrokerNewEditAdminInstructionComponent,
    BrokerClaimsComponent,
    BrokerNewEditClaimsComponent,
    BrokerViewUserNotesComponent,
    BrokerViewUserPoliciesComponent,
    BrokerSettingsComponent,
    BrokerComposeNewEmailComponent,
    BrokerSettingsEmailComponent,
    BrokerUploadAnyDocumentModalComponent,
    BrokerViewClientDocumentsComponent,
    BrokerDisplayClientDocumentModalComponent,
    BrokerNewEditClientHomeownerInsuranceComponent,
    BrokerNewEditClientHomeContentInsuranceComponent,
    BrokerViewUserAllRiskInsuranceComponent,
    BrokerNewEditClientAllRiskInsuranceComponent,
    BrokerEditClientDocumentDetailsComponent,
    BrokerCreateUserCustomNoteComponent,
    BrokerViewClientFamilyMembersComponent,
    SelectNewPolicyVehicleItemsComponent,
    SelectNewPolicyAllRiskItemsComponent,
    SelectNewPolicyHouseholdContentItemsComponent,
    SelectNewPolicyItemBuildingComponentComponent,
    BrokerNewEditClientCaranvansAndTrailersComponent,
    BrokerViewUserTrailersAndCaravansInsuranceComponent,
    SelectNewPolicyItemsTrailersAndCaravansComponent,
    BrokerQuotesComponent,
    BrokerNewEditQuotesComponent,
    BrokerCalendarComponent,
    BrokerCreateNewCalendarEventComponent,
    BrokerViewCalendarEventComponent,
    BrokerNewEditClientValueAddedProductsComponent,
    BrokerViewUserValueAddedProductsComponent,
    SelectNewPolicyItemsValueAddedProdductsComponent,
    RejectQuoteModalComponent,
    ApproveQuoteModalComponent,
    BrokerViewUserClaimsComponent,
    BrokerViewUserInstructionsComponent,
    BrokerEmailAddAttachmentComponent,
    BrokerViewUserEmailsComponent,
    BrokerClaimFormGroupComponent,
    BrokerClaimsNewClaimComponent,
    BrokerClaimsEditClaimComponent,
    BrokerAdminInstructionNewComponent,
    BrokerAdminInstructionEditComponent,
    BrokerAdminInstructionFormGroupComponent,
    BrokerReturnedDebitsComponent,
    BrokerReturnedDebitNewDebitComponent,
    BrokerReturnedDebitEditDebitComponent,
    ReturnedDebitOrderFormGroupComponent,
    BrokerViewUserReturnedDebitComponent,
    AdminInstructionHandoverModalComponent,
    BrokerClaimsHandoverModalComponent,
    BrokerViewUserQuotesComponent,
    BrokerCommercialClientsComponent,
    BrokerCommercialClientsFormGroupComponent,
    BrokerCommercialClientsNewComponent,
    BrokerCommercialClientsEditComponent,
    BrokerCommercialClientDeleteComponent,
    BrokerCommercialClientsProductsComponent,
    BrokerCommercialClientsProductsNewComponent,
    BrokerCommercialClientsProductsEditComponent,
    BrokerNotificationsComponent,
    BrokerReportingComponent,
    BrokerReportingInstructionsComponent,
    BrokerReportingClaimsComponent,
    BrokerReportingPoliciesComponent,
    BrokerReportingQuotesComponent,
    BrokerReportingReturnedDebitComponent,
    BrokerReportingEmployeesComponent,
    BrokerReportingCalendarEventsComponent,
    BrokerReportingClientsComponent,
    BrokerCompanySettingsComponent,
    BrokerNewCompanyLocationComponent,
    BrokerEditCompanyLocationComponent,
    BrokerDeleteCompanyLocationComponent,
    BrokerCommercialProductDeleteComponent,
    BrokerCompanyDocumentsComponent,
    UploadCompanyDocumentsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
  ],
  entryComponents: [
    BrokerUploadAnyDocumentModalComponent,
    BrokerCreateNewCalendarEventComponent,
    BrokerViewCalendarEventComponent,
    DeleteValueAddedProductComponent,
    RejectQuoteModalComponent,
    ApproveQuoteModalComponent,
    BrokerCommercialProductDeleteComponent,
    DeleteTrailersAndCaravansModalComponent
  ],
  exports: [BrokerComponent]
})
export class BrokerModule { }
