import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { ReturnedDebitOrders } from 'src/app/_shared/models/returnedDebitOrders.model';
import { BrokerReturnedDebitService } from './../../../../../../services/broker-returned-debit.service';
import { BrokerClaimsService } from 'src/app/services/broker-claims.service';
import { BrokerAdminInstructionService } from 'src/app/services/broker-admin-instruction.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Policy } from 'src/app/_shared/models/policy.model';
import { BrokerClaim } from 'src/app/_shared/models/brokerClaims.model';
import { BrokerAdminInstruction } from 'src/app/_shared/models/brokerAdminInstruction.model';
import { Component, OnInit, Inject } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/components/modal/delete/delete.component';
import { MatRadioChange } from '@angular/material/radio';
import { Quote } from '@angular/compiler';
import { BrokerQuote } from 'src/app/_shared/models/brokerQuotes.model';

@Component({
  selector: 'app-broker-create-user-custom-note',
  templateUrl: './broker-create-user-custom-note.component.html',
  styleUrls: ['./broker-create-user-custom-note.component.scss']
})
export class BrokerCreateUserCustomNoteComponent implements OnInit {

  formSubmitted: boolean = false;
  clientNoteFormGroup: FormGroup;

  selectedUser = this.data['userId']
  brokerCompany: string = this.authenticationService.currentCompany.companyName;

  instructions: BrokerAdminInstruction[] = [];
  totalInstructions: number = 0;
  instructionOffset: number = 0;

  claims: BrokerClaim[] = [];
  totalClaims:number = 0;
  claimOffset: number = 0;

  policies: Policy[] = [];
  totalPolicies:number = 0;
  policyOffset: number = 0;

  quotes: BrokerQuote[] = [];
  totalQuotes: number = 0;
  quoteOffset: number = 0;

  returnedDebits: ReturnedDebitOrders[] = [];
  totalReturnedDebits: number = 0;
  returnedDebitOffset: number = 0;

  noteReference: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<BrokerCreateUserCustomNoteComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private brokerService: BrokerService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private instructionService: BrokerAdminInstructionService,
    private claimsService: BrokerClaimsService,
    private brokerQuoteService: BrokerQuoteService,
    private brokerReturnedDebitService: BrokerReturnedDebitService
  ) { }

  ngOnInit(): void {
    this.clientNoteFormGroup = this.formBuilder.group({
      message: ['', Validators.required],
      client: [''],
      claim: [''],
      claimDescription: [''],
      instruction: [''],
      instructionDescription: [''],
      policy: [''],
      policyDescription: [''],
      returnedDebit: [''],
      returnedDebitDescription: [''],
      quote: [''],
      quoteDescription: ['']
    });

    this.clientNoteFormGroup.get('client').setValue(this.data['userId'])
  }

  createManualNote(){

    if(this.clientNoteFormGroup.invalid){
      this.toastr.error('Please provide a note message.', 'Could not save manual note.')
      return;
    }

    this.brokerService.createManualNote(this.data['userId'], this.data['companyName'], this.clientNoteFormGroup.value).subscribe((response) => {
      this.closeModal({ status: 'success', data: response.body.activity });
    })
  }

  closeModal(status?){
    this.dialogRef.close(status);
  }

  getAllPoliciesPerUser(){

    if(this.policies.length === this.totalPolicies && this.totalPolicies != 0) return;

    let req = {
      offset: this.policyOffset,
      limit: 100,
      status: 'active'
    }

    this.brokerService.getPoliciesPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {
      
      this.policyOffset = this.policyOffset + response.body.policies.length;
      this.totalPolicies = response.body.count;
      
      this.policies = [
        ...this.policies,
        ...response.body.policies
      ]
    })
  }

  getAllInstructionsPerUser(){

    if(this.instructions.length === this.totalInstructions && this.totalInstructions != 0) return;

    let req = {
      completed: 'incomplete',
      offset: this.instructionOffset,
      limit: 100
    }

    this.instructionService.getAllInstructionsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {

      this.instructionOffset = this.instructionOffset + response.body.instruction.length;
      
      this.instructions = [
        ...this.instructions,
        ...response.body.instruction
      ]
    })
  }

  getAllClaimsPerUser(){

    if(this.claims.length === this.totalClaims && this.totalClaims != 0) return;

    let req = {
      completed: 'incomplete',
      offset: this.claimOffset,
      limit: 100
    }

    this.claimsService.getAllClaimsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {
      
      this.claimOffset = this.claimOffset + response.body.claim.length;
      
      this.claims = [
        ...this.claims,
        ...response.body.claim
      ]
    })
  }

  getAllReturnedDebitsPerUser(){

    if(this.returnedDebits.length === this.totalReturnedDebits && this.totalReturnedDebits != 0) return;

    let req = {
      offset: this.returnedDebitOffset,
      limit: 100,
      completed: 'incomplete'
    }

    this.brokerReturnedDebitService.getAllReturnedDebitsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {      
      this.returnedDebitOffset = this.returnedDebitOffset + response.body.returnedDebit.length;
      
      this.returnedDebits = [
        ...this.returnedDebits,
        ...response.body.returnedDebit
      ]
    })
  }

  getAllQuotesPerUser(){

    if(this.quotes.length === this.totalQuotes && this.totalQuotes != 0) return;

    let req = {
      offset: this.quoteOffset,
      limit: 100,
      status: ['sent', 'inProgress', 'accepted']
    }

    this.brokerQuoteService.getAllClientQuotes(this.brokerCompany, this.selectedUser, req).subscribe(response => {      
      this.quoteOffset = this.quoteOffset + response.body.quotes.length;
      
      this.quotes = [
        ...this.quotes,
        ...response.body.quotes
      ]
    })
  }

  setPolicyDetails(event):void {
    this.clientNoteFormGroup.get('policyDescription').setValue(event.value)
  }

  setInstructionDetails(event):void {
    this.clientNoteFormGroup.get('instructionDescription').setValue(event.value)
  }

  setClaimDetails(event):void {
    this.clientNoteFormGroup.get('claimDescription').setValue(event.value)
  }

  setQuoteDetails(event):void {
    this.clientNoteFormGroup.get('quoteDescription').setValue(event.value)
  }

  setReturnedDebitDetails(event):void {
    this.clientNoteFormGroup.get('returnedDebitDescription').setValue(event.value)
  }

  selectNoteReference(reference: MatRadioChange){
    if(reference.value === 'policy') this.getAllPoliciesPerUser();
    if(reference.value === 'instruction') this.getAllInstructionsPerUser();
    if(reference.value === 'claim') this.getAllClaimsPerUser();
    if(reference.value === 'quote') this.getAllQuotesPerUser();
    if(reference.value === 'returnedDebit') this.getAllReturnedDebitsPerUser();
  }

}
