import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-settings',
  templateUrl: './broker-settings.component.html',
  styleUrls: ['./broker-settings.component.scss']
})
export class BrokerSettingsComponent implements OnInit {

  passwordStrength: number;

  formSubmitted = false;
  resetPasswordFormGroup: FormGroup;
  get resetPasswordForm() { return this.resetPasswordFormGroup.controls; }

  notificationFormGroup: FormGroup;
  get notificationForm() { return this.notificationFormGroup.controls; }

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.resetPasswordFormGroup = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', [ Validators.required, Validators.minLength(7) ]],
      confirmNewPassword: ['', [ Validators.required, Validators.minLength(7) ]]
    });

    this.notificationFormGroup = this.formBuilder.group({
      productChanges: this.formBuilder.group({
        push: [''],
        email: [''],
        sms: ['']
      }),
      reminders: this.formBuilder.group({
        push: [''],
        email: [''],
        sms: ['']
      })
    });
  }

  resetPassword(){

    this.resetPasswordFormGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.resetPasswordFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.authService.resetPassword(this.resetPasswordFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {
      }
    )
  }

  onStrengthChanged(strength: number) {
    this.passwordStrength = strength;
  }


}
