<div class="backdrop">

  <div id="email" class="modal">

    <form [formGroup]="newEmailFormGroup">

      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">New email</h5>
        <button type="button" class="close" aria-label="Close" (click)="toggleSendingEmail()">
        <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-header">

        <div class="row w-100">

          <div class="col-12 col-sm-3">
            <mat-form-field>
              <mat-label>Client</mat-label>
              <input readonly matInput [value]="getCurrentClient()" placeholder="Client">
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3">
            <mat-form-field>
              <mat-label>Policy</mat-label>
              <mat-select formControlName="policy" (selectionChange)="getPolicyEmails($event); setEmailBodyDetails($event, 'policy')" [disabled]="emailOptions?.forward">
                <mat-option disabled *ngIf="policies?.length === 0">No policies found</mat-option>
                <mat-option [value]="null">No policy selected</mat-option>
                <mat-option *ngFor="let policy of policies" [value]="policy?.policyNumber">
                  Policy {{ policy.policyNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 mt-3"></div>

          <div class="col-12">
            <label for="message">What is this note in reference to:</label>
          </div>
          <div class="col-12">          
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="noteReference"
              (change)="selectNoteReference($event)"
            >
              <mat-radio-button class="mr-3" value="instruction">Instruction</mat-radio-button>
              <mat-radio-button class="mr-3" value="claim">Claim</mat-radio-button>
              <mat-radio-button class="mr-3" value="quote">Quote</mat-radio-button>
              <mat-radio-button value="returnedDebit">Returned debit</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="formSubmitted && newEmailFormGroup.get('noteReference')?.errors?.required" class="d-block invalid-feedback">
              <p>Please supply a reference for this mail.</p>
            </div>
          
          </div>

          <div class="col-12 col-sm-3 long-select" *ngIf="newEmailFormGroup.get('noteReference').value === 'instruction'">
            <mat-form-field>
              <mat-label>Instruction</mat-label>
              <mat-select formControlName="instruction" (selectionChange)="setEmailBodyDetails($event, 'instruction')" [disabled]="emailOptions?.forward">
                <mat-option disabled *ngIf="instructions?.length === 0">No instructions found</mat-option>
                <mat-option [value]="null">No Instruction selected</mat-option>
                <mat-option *ngFor="let instruction of instructions" [value]="instruction?.referenceNumber">
                  {{ instruction?.referenceNumber }} - {{ instruction?.product?.description || instruction?.instructionDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" *ngIf="newEmailFormGroup.get('noteReference').value === 'claim'">
            <mat-form-field>
              <mat-label>Claim</mat-label>
              <mat-select formControlName="claim" (selectionChange)="setEmailBodyDetails($event, 'claim')" [disabled]="emailOptions?.forward">
                <mat-option disabled *ngIf="claims?.length === 0">No claims found</mat-option>
                <mat-option [value]="null">No claim selected</mat-option>
                <mat-option *ngFor="let claim of claims" [value]="claim?.referenceNumber">
                  {{ claim?.referenceNumber }} - {{ claim?.product?.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" *ngIf="newEmailFormGroup.get('noteReference').value === 'quote'">
            <mat-form-field>
              <mat-label>Quote</mat-label>
              <mat-select formControlName="quote" (selectionChange)="setEmailBodyDetails($event, 'quote')" [disabled]="emailOptions?.forward">
                <mat-option disabled *ngIf="quotes?.length === 0">No quotes found</mat-option>
                <mat-option [value]="null">No quote selected</mat-option>
                <mat-option *ngFor="let quote of quotes" [value]="quote?.quoteNumber">
                  {{ quote?.quoteNumber }} - {{ quote?.client?.lastName }}{{ quote?.client?.companyName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-3" *ngIf="newEmailFormGroup.get('noteReference').value === 'returnedDebit'">
            <mat-form-field>
              <mat-label>Returned debit</mat-label>
              <mat-select formControlName="returnedDebit" (selectionChange)="setEmailBodyDetails($event, 'returnedDebit')" [disabled]="emailOptions?.forward">
                <mat-option disabled *ngIf="returnedDebits?.length === 0">No returned debits found</mat-option>
                <mat-option [value]="null">No returned debit selected</mat-option>
                <mat-option *ngFor="let returnedDebit of returnedDebits" [value]="returnedDebit?.referenceNumber">
                  {{ returnedDebit?.referenceNumber }} - {{ returnedDebit?.policyNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        
      </div>

      <div class="modal-header py-0">

        <div class="row w-100">

          <!-- TO -->
          <div class="col-12">
            <mat-form-field class="example-chip-list">
              <mat-label>To...</mat-label>
              <mat-chip-list #chipList aria-label="Email recipients">
                <mat-chip
                  *ngFor="let email of addedRecipients"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeRecipientEmail(email)"
                  >
                  {{ email }}
                  <mat-icon matChipRemove *ngIf="removable">
                    <i class="fi flaticon-error color-danger"></i>
                  </mat-icon>
                </mat-chip>
                <input
                  placeholder="Please enter recipient..."
                  #recipientsInput
                  formControlName="autoComplete"
                  [matAutocomplete]="recipientsAuto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addRecipient($event)"
                  (blur)="addOnBlur($event)"
                >
              </mat-chip-list>
              <mat-autocomplete #recipientsAuto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let familyMember of filteredFamilyMembers | async" [value]="familyMember?.email">
                  {{ familyMember?.firstName || familyMember?.name }} {{ familyMember?.lastName || familyMember?.surname }} ({{ familyMember?.email || 'no email' }})
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- CC -->
          <div class="col-12">
            <mat-form-field class="example-chip-list">
              <mat-label>cc...</mat-label>
              <mat-chip-list #ccChipList aria-label="Email cc">
                <mat-chip
                  *ngFor="let email of ccRecipients"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeCcEmail(email)">
                  {{ email }}
                  <mat-icon matChipRemove *ngIf="removable">
                    <i class="fi flaticon-error color-danger"></i>
                  </mat-icon>
                </mat-chip>
                <input
                  placeholder="Please enter recipient..."
                  #recipientsCcInput
                  [matAutocomplete]="recipientsCcAuto"
                  [matChipInputFor]="ccChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addCcRecipient($event)"
                  (blur)="addOnBlurCC($event)"
                >
              </mat-chip-list>
              <mat-autocomplete #recipientsCcAuto="matAutocomplete" (optionSelected)="selectedCc($event)">
                <mat-option *ngFor="let familyMember of filteredFamilyMembers | async" [value]="familyMember?.email">
                  {{ familyMember?.firstName || familyMember?.name }} {{ familyMember?.lastName || familyMember?.surname }} ({{ familyMember?.email}})
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- BCC -->
          <div class="col-12">
            <mat-form-field class="example-chip-list">
              <mat-label>bcc...</mat-label>
              <mat-chip-list #bccChipList aria-label="Email bcc">
                <mat-chip
                  *ngFor="let email of bccRecipients"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeBccEmail(email)">
                  {{ email }}
                  <mat-icon matChipRemove *ngIf="removable">
                    <i class="fi flaticon-error color-danger"></i>
                  </mat-icon>
                </mat-chip>
                <input
                  placeholder="Please enter recipient..."
                  #recipientsBccInput
                  [matAutocomplete]="recipientsBccAuto"
                  [matChipInputFor]="bccChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addBccRecipient($event)"
                  (blur)="addOnBlurBcc($event)"
                >
              </mat-chip-list>
              <mat-autocomplete #recipientsBccAuto="matAutocomplete" (optionSelected)="selectedBcc($event)">
                <mat-option *ngFor="let familyMember of filteredFamilyMembers | async" [value]="familyMember?.email">
                  {{ familyMember?.firstName || familyMember?.name }} {{ familyMember?.lastName || familyMember?.surname }} ({{ familyMember?.email}})
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- SUBJECT -->
          <div class="col-12">
            <mat-form-field>
              <mat-label>Subject</mat-label>
              <input matInput formControlName="emailSubject" placeholder="Subject">
            </mat-form-field>
          </div>
          
        </div>

      </div>

      <div class="modal-header">

        <div class="row w-100">

          <div class="col-12">
            <span class="color-label-2">Attachments</span>
          </div>

          <div *ngFor="let attachment of emailAttachments" class="col-6 my-auto py-2">
            <img class="attachment" src="https://www.flaticon.com/svg/vstatic/svg/136/136522.svg?token=exp=1610792269~hmac=581e727d84b8cecce98bd3a3e3bea850" />
            {{ attachment?.documentName }}
            <btn (click)="removeAttachment(attachment)" class="btn btn-icon btn-danger btn-pill ml-3"><i class="fi flaticon-trash"></i></btn>
          </div>

          <div class="col-12 mt-3">
            <button type="button" class="btn btn-success btn-sm" (click)="addAttachment()">Add attachment</button>
          </div>

        </div>

      </div>

      <div class="modal-body">
        <ckeditor
          id="editor"
          name="itemDescription"
          [editor]="Editor"
          [config]="ckEditorConfig"
          formControlName="emailBody"
        >
        </ckeditor>
        <!-- <textarea class="textarea form-control" formControlName="emailBody"></textarea> -->
      </div>
      <div class="modal-footer">
        <button type="button" (click)="toggleSendingEmail()" class="btn btn-outline-secondary">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="sendEmail()">Send</button>
      </div>

    </form>

  </div>

</div>