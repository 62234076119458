import { AuthenticationService } from 'src/app/services/authentication.service';
import { MyAccountService } from 'src/app/services/my-account.service';

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
  providers: [AppComponent],
})
export class ClientInformationComponent implements OnInit {
  selectedTab = 0;
  userInformation: any;
  editingBasicInformation = true;

  basicInformationForm: FormGroup;
  get basicForm() {
    return this.basicInformationForm.controls;
  }

  additionalInformationForm: FormGroup;
  get additionalForm() {
    return this.additionalInformationForm.controls;
  }

  contactInformationForm: FormGroup;
  get contactForm() {
    return this.contactInformationForm.controls;
  }

  submittedForm = false;

  constructor(
    private authenticationService: AuthenticationService,
    private myAccountService: MyAccountService,
    private formBuilder: FormBuilder,
    public location: Location,
    private app: AppComponent
  ) {}

  getUserBasicInformation() {
    this.basicInformationForm.patchValue(this.app.currentUser);
  }

  tabHeading(tab) {
    switch (tab) {
      case 0:
        return 'Basic information';
        break;

      case 1:
        return 'Additional information';
        break;

      case 2:
        return 'Contact information';
        break;

      case 3:
        return 'Insurance information';
        break;
    }
  }

  updateUserInformation() {
    this.submittedForm = true;
    if (this.basicInformationForm.invalid) {
      this.selectedTab = 0;
      return;
    }

    let data = {
      basicInformation: this.basicInformationForm.value
    }

    this.myAccountService.updateUserInformation(data).subscribe();
  }

  ngOnInit() {
    this.basicInformationForm = this.formBuilder.group({
      idNumber: [{ value: '', disabled: true }],
      passportNumber: [''],
      taxNumber: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      nickName: [''],
      gender: [{ value: '', disabled: true }],
      insuranceType: this.formBuilder.group({
        householdInsurance: [''],
        vehicleInsurance: [''],
        buildingInsurance: [''],
        personalItemInsurance: [''],
      }),
      middleName: [''],
      employer: ['', Validators.required],
      jobDescription: [''],
      salary: [''],
      driversLicence: this.formBuilder.group({
        licenceCode: ['', Validators.required],
        licenceFirstIssue: [''],
        licenceExpiryDate: [''],
      }),
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      cellphone: ['', Validators.required],
      homePhone: [''],
      workPhone: [''],
      alternateNumber: [''],
    });

    this.getUserBasicInformation();
  }
}
