<div id="register" class="row">

  <div class="ol-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mt-5">

    <form id="newVehicleForm" [formGroup]="resetPasswordFormGroup" (ngSubmit)="resetPassword()">

      <!-- Password -->
      <div class="form-group" ng-class="{ 'is-invalid': submitted && form['password'].errors }">

        <label for="password">New password:</label>
        <input #Password type="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && form['password'].errors }" name="password"
          formControlName="password" />

        <mat-password-strength #passwordStengthMeter (onStrengthChanged)="onStrengthChanged($event)"
          [password]="Password.value">
        </mat-password-strength>

        <div *ngIf="submitted && form['password'].errors" class="invalid-feedback">
          <div *ngIf="form['password'].errors.required">Please provide a new password.</div>
          <div *ngIf="form['password'].errors.minlength">Password must be at least 8 characters.</div>
        </div>

        <p class="color-dark-grey smallest mt-1">
          <small>To conform with our Strong Password policy, you are required to use a
            sufficiently strong password. Password must be more than 8 characters.</small>
        </p>

        <mat-password-strength-info *ngIf="form['password'].dirty" [passwordComponent]="passwordStengthMeter">
        </mat-password-strength-info>

      </div>
      <!-- Close div for .form-group -->

      <!-- Confirm Password -->
      <div class="form-group">

        <label for="newPassword">Confirm new password:</label>

        <input type="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && form['newPassword'].errors }" name="newPassword"
          formControlName="newPassword" />
        <div *ngIf="submitted && form['newPassword'].errors" class="invalid-feedback">
          <div *ngIf="form['newPassword'].errors.required">Please confirm your new password.</div>
          <div *ngIf="form['newPassword'].errors.minlength">Password must be at least 8 characters.</div>
        </div>

      </div> <!-- Close div for .form-group -->

      <div class="row">
          <div class="col-12 text-center mt-4">
               
                <button class="btn btn-brand btn-widest mr-0" (click)="resetPassword()">Save</button>
                <br />
                <button class="btn btn-secondary mr-0 mt-3" [routerLink]="['/login']">Back to login</button>
          </div>
      </div>

    </form>

  </div>

</div> <!-- Close div for #content -->
