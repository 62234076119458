import { Component, OnInit } from '@angular/core';
import { CancelAccountModalComponent } from 'src/app/components/modal/cancel-account/cancel-account-modal.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
@Component({
  selector: 'app-cancel-account',
  templateUrl: './cancel-account.component.html',
  styleUrls: ['./cancel-account.component.scss']
})
export class CancelAccountComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  openModal(){
    this.dialog.open(CancelAccountModalComponent);
  }

  ngOnInit() {
  }

}
