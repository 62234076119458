import { BrokerAdminInstructionResponse } from './../../../_shared/models/brokerAdminInstruction.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../../services/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BrokerAdminInstructionService } from './../../../services/broker-admin-instruction.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-broker-admin-instruction-new',
  templateUrl: './broker-admin-instruction-new.component.html',
  styleUrls: ['./broker-admin-instruction-new.component.scss']
})
export class BrokerAdminInstructionNewComponent implements OnInit {

  currentCompany: string;
  instructionFormGroup: FormGroup;

  constructor(
    private brokerAdminInstructionService: BrokerAdminInstructionService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private location: Location
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;

    this.instructionFormGroup = this.formBuilder.group({
      instruction: []
    })
  }

  ngOnInit(): void {
  }

  createNewInstruction(saveType: string = 'back'){

    // this.formSubmitted = true;
    // // stop here if form is invalid
    if (this.instructionFormGroup.get('instruction').invalid) {
      console.warn('form => ', this.instructionFormGroup.get('instruction'))
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new instruction.')
      return;
    }

    this.brokerAdminInstructionService.addNewInstruction(this.currentCompany, this.instructionFormGroup.getRawValue().instruction).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  goBack(){
    this.location.back()
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.instructionFormGroup.get('instruction').reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

}
