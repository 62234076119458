<div class="row">

    <div class="col-12 col-lg-8 offset-lg-2">

      <p class="mb-4"><strong>Email setup</strong></p>

      <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">

        <mat-tab label="SMTP Setup">

            <form [formGroup]="emailSetupFormGroup" (ngSubmit)="saveEmailSettings()">

                <div class="row">
                  <div class="col-12">
        
                    <h5 class="mb-4">
                      <strong>SMTP</strong>
                    </h5>
        
                    <div class="row my-2">
        
                        <div class="col-12 col-md-6">
                            <mat-form-field>
                                <input matInput name="Username" placeholder="Username" type="text" formControlName="username"  />
                                <mat-error *ngIf="emailSetupFormGroup.get('username').errors && emailSetupFormGroup.get('username').errors.required">
                                    We need your <strong>SMTP username</strong> for you to be able to send emails.
                                </mat-error>
                            </mat-form-field>
                        </div>
        
                        <div class="col-12 col-md-6">
                            <mat-form-field>
                                <input matInput name="Password" placeholder="Password" type="password" formControlName="password"  />
                                <mat-error *ngIf="emailSetupFormGroup.get('password').errors && emailSetupFormGroup.get('password').errors.required">
                                    We need your <strong>SMTP password</strong> for you to be able to send emails.
                                </mat-error>
                            </mat-form-field>
                        </div>
          
                    </div> <!-- Close div for .row -->
        
                    <div class="row my-2">
        
                        <div class="col-12 col-md-6">
                            <mat-form-field>
                                <input matInput name="Host" placeholder="Host" type="text" formControlName="host"  />
                                <mat-error *ngIf="emailSetupFormGroup.get('host').errors && emailSetupFormGroup.get('host').errors.required">
                                    We need your <strong>SMTP host</strong> for you to be able to send emails.
                                </mat-error>
                            </mat-form-field>
                        </div>
        
                        <div class="col-12 col-md-6">
                            <mat-form-field>
                                <input matInput name="Port" placeholder="Port" type="text" formControlName="port"  />
                                <mat-error *ngIf="emailSetupFormGroup.get('port').errors && emailSetupFormGroup.get('port').errors.required">
                                    We need your <strong>SMTP port</strong> for you to be able to send emails.
                                </mat-error>
                            </mat-form-field>
                        </div>
          
                    </div> <!-- Close div for .row -->
        
                  </div>
                </div>
        
                <hr class="hr-dashed hr-small" />
                <hr class="hr-dashed hr-small" />
        
                <div class="panel-footer-inside">
                    <button matTooltip="Save changes" class="btn btn-primary my-auto" type="submit">Save</button>
                </div>
        
              </form>

        </mat-tab>

        <mat-tab label="Email Signature">

            <form [formGroup]="emailSignatureFormGroup" (ngSubmit)="saveEmailSignature()">

                <div class="row">
                    <div class="col-12 height-250">
          
                        <h5 class="mb-4">
                            <strong>Email Signature</strong>
                        </h5>

                        <div class="row my-2">
        
                            <div class="col-12">
                                <quill-editor
                                    formControlName="emailSignature"
                                    placeholder="Enter Text"
                                    [modules]="quillConfig"
                                >
                                </quill-editor>
                            </div>

                        </div>

                    </div>

                    <div class="col-12">
                        <hr class="hr-dashed hr-small" />
                        <hr class="hr-dashed hr-small" />
                
                        <div class="panel-footer-inside">
                            <button matTooltip="Save changes" class="btn btn-primary my-auto" type="submit">Save</button>
                        </div>
                    </div>

                </div>

            </form>

        </mat-tab>

      </mat-tab-group>

    </div>

  </div> <!-- Close div for .row -->