import { UserResponse } from './../_shared/models/User.model';
import { FAQResponse } from './../_shared/models/faq.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllUsers(req:any, noload?:boolean): Observable<HttpResponse<UserResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/management/users`, {
      observe: 'response',
      params: req
    });
  }

  getRegisteredUsers(req): Observable<HttpResponse<UserResponse>>{
    return this.http.get<UserResponse>(`${this.globals.SERVER_URL}/api/management/registeredUsers`, {
      params: req,
      observe: 'response'
    });
  };

  activateUser(username:string): Observable<HttpResponse<UserResponse>>{
    return this.http.put<UserResponse>(`${this.globals.SERVER_URL}/api/management/activate/${username}`, {}, {
      observe: 'response'
    });
  };

  disableUser(username:string): Observable<HttpResponse<UserResponse>>{
    return this.http.put<UserResponse>(`${this.globals.SERVER_URL}/api/management/disable/${username}`, {}, {
      observe: 'response'
    });
  };

  enableUser(username:string): Observable<HttpResponse<UserResponse>>{
    return this.http.put<UserResponse>(`${this.globals.SERVER_URL}/api/management/enable/${username}`, {}, {
      observe: 'response'
    });
  };

  createNewFAQ(faq:any): Observable<HttpResponse<FAQResponse>> {
    return this.http.post<FAQResponse>(`${this.globals.SERVER_URL}/api/management/faq`, faq, {
      observe: 'response'
    });
  };

  removeFAQ(id:string): Observable<HttpResponse<FAQResponse>> {
    return this.http.delete<FAQResponse>(`${this.globals.SERVER_URL}/api/management/faq/${ id }`, {
      observe: 'response'
    });
  };

  addSuggestion(suggestion:any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.globals.SERVER_URL}/api/management/suggestion`, suggestion, {
      observe: 'response'
    });
  };

  getSuggestions(req?:any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/management/suggestions`, {
      observe: 'response',
      params: req
    });
  };

  editSuggestion(id:string, suggestion:any): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/management/suggestions/${id}`, suggestion, {
      observe: 'response'
    });
  };

  sortSuggestions(suggestions:any[]): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/management/order/suggestions`, suggestions, {
      observe: 'response'
    });
  };

}
