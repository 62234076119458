<div id="content" class="row">

        <sub-navigation heading="Manage employees" subheading="Showing {{ employees?.length || '0' }} employee{{ employees?.length > 1 ? 's' : '' }}"></sub-navigation>
        
        <div class="col-12">
          
          <div class="panel panel-primary">
      
            <div class="panel-heading">
                <h5>Employees</h5>
      
                <button
                  *ngIf="currentUserPermissions.includes('systemAdmin') || currentUserPermissions.includes('brokerHR') || currentUserPermissions.includes('brokerDirector')"
                  type="button"
                  class="btn btn-brand my-auto"
                  [routerLink]="['new']"
                  matTooltip="Add a new employee"
                >
                  <i class="fi flaticon-add"></i>Add employee
                </button>
      
            </div> <!-- Close div for .panel-heading -->
      
            <div class="panel-body">
      
              <div id="filter" class="col-12 mb-3">
      
                <div class="row">

                  <div class="col-12 d-flex mb-3">
                    <p class="mr-3 my-auto"><strong>Search employee by name or email</strong></p>
                    <input type="text" placeholder="Search employee by name, email" class="form-control w-50" name="searchEmployee" [(ngModel)]="searchEmployee" (ngModelChange)="getEmployees($event, null, true)"/>
                  </div>
      
                  <div class="col-12 d-inline">
                    <p class="d-inline-block mr-3"><strong>Filter by role</strong></p>
      
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, '')" [ngClass]="!filterByRole ? 'btn-info' : 'btn-label-info'">All</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerLeadProvider')" [ngClass]="filterByRole === 'brokerLeadProvider' ? 'btn-info' : 'btn-label-info'">Lead provider</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerClerk')" [ngClass]="filterByRole === 'brokerClerk' ? 'btn-info' : 'btn-label-info'">Clerk</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerAdmin')" [ngClass]="filterByRole === 'brokerAdmin' ? 'btn-info' : 'btn-label-info'">Administrator</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerClaimsTechnician')" [ngClass]="filterByRole === 'brokerClaimsTechnician' ? 'btn-info' : 'btn-label-info'">Claims technician</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerTeamLead')" [ngClass]="filterByRole === 'brokerTeamLead' ? 'btn-info' : 'btn-label-info'">Team leader</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerManager')" [ngClass]="filterByRole === 'brokerManager' ? 'btn-info' : 'btn-label-info'">Manager</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerHr')" [ngClass]="filterByRole === 'brokerHr' ? 'btn-info' : 'btn-label-info'">HR</button>
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, 'brokerDirector')" [ngClass]="filterByRole === 'brokerDirector' ? 'btn-info' : 'btn-label-info'">Director</button>
                  </div>

                  <div class="col-12 d-inline">
                    <p class="d-inline-block mr-3"><strong>Employment status</strong></p>
      
                    <button class="btn btn-sm btn-pill" (click)="getEmployees(null, '')" [ngClass]="employmentStatus === 'employed' ? 'btn-info' : 'btn-label-info'">Currently employed</button>
                    <button class="btn btn-sm btn-pill" (click)="getPreviousEmployees('notEmployed')" [ngClass]="employmentStatus === 'notEmployed' ? 'btn-info' : 'btn-label-info'">Not Employed</button>
                  </div>
                  
                </div>
                
              </div>
      
              <div id="employees" class="col-12">
      
                <div class="row">

                  <div class="col-12 text-center" *ngIf="employees?.length === 0">
                    <p class="mt-3">No employees found</p>
                  </div>
      
                  <div class="col-md-4 col-sm-6 col-6 mb-3" *ngFor="let employee of employees">
                    <div class="card h-100">
                      <div class="card-body">   
                        
                        <div
                          class="text-right"
                          *ngIf="currentUserPermissions.includes('systemAdmin') || currentUserPermissions.includes('brokerHR') || currentUserPermissions.includes('brokerDirector')"
                        >
                          <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="menu">
                            <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
                          </button>
                    
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['view/', ({id: employee._id})]">
                              <span>View employee</span>
                            </button>
                            <button *ngIf="employmentStatus === 'employed'" mat-menu-item [routerLink]="['edit/', ({id: employee._id})]">
                              <span>Edit employee</span>
                            </button>
                            <button *ngIf="employmentStatus === 'employed'" mat-menu-item (click)="removeEmployee(employee)">
                              <span>Remove employee</span>
                            </button>
                          </mat-menu>
                        </div>

                        <div class="text-center mb-3">
                          <span class="userpic userpic-lg userpic-circle userpic-dark">
                            <span>{{ employee?.firstName?.substring(0, 1) }}{{ employee?.lastName?.substring(0, 1) }}</span>
                          </span>
                        </div>

                        <h5 class="card-title text-center color-label-4 mb-3">{{ employee?.firstName }} {{ employee?.lastName }}</h5>
                        <p class="card-text mb-0">
                          <small class="text-left"><strong>Email:</strong></small>
                          <small class="float-right color-dark-grey">{{ employee?.email }}</small>
                        </p>
                        <p class="card-text mb-0">
                          <small class="text-left"><strong>Cellphone:</strong></small>
                          <small class="float-right color-dark-grey" *ngIf="employee.cellphone"><span>{{ employee?.cellphone }}</span></small>
                        </p>
                        <p class="card-text">
                          <small class="text-left"><strong>Employee code:</strong></small>
                          <small class="float-right color-dark-grey"><span>{{ employee?.company?.employeeCode }}</span></small>
                        </p>
                      </div>
                    </div> <!-- Close div for .card -->
                  </div>
      
                </div> <!-- Close div for .row -->
      
              </div> <!-- Close div for #employees -->
      
            </div> <!-- Close div for .panel-body -->
      
          </div> <!-- Close div for .panel -->
        </div> <!-- Close div for .col-12 -->
      
      </div> <!-- Close div for #content -->