import { Component, OnInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service';
import { HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-client-new-broker',
  templateUrl: './client-new-broker.component.html',
  styleUrls: ['./client-new-broker.component.scss']
})
export class ClientNewBrokerComponent implements OnInit {

  newBrokerForm: FormGroup;
  submittedForm = false;
  get form(){ return this.newBrokerForm.controls; }

  constructor(
    private insuranceService: InsuranceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public location: Location,
  ) { }

  newBroker = {};

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  addNewBroker(saveType?:string){

    this.submittedForm = true;
    // stop here if form is invalid
    if (this.newBrokerForm.invalid) {
      return;
    }

    this.insuranceService.saveNewBroker(this.newBrokerForm.value).subscribe(
      (response:HttpResponse<BrokerResponse>) => {
        switch(saveType){
          case 'back':
            this.goBack();
            // this.location.back();
            break;

          case 'continue':
            break;

          default:
          this.goBack();
          // this.location.back();
            break;
        }
      }
    )
  };

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.location.back();
      this.router.navigate(['/u/my-brokers'])
    };
  }

  ngOnInit() {
    this.newBrokerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      workPhone: [''],
      cellphone: ['', Validators.required],
      company: ['', Validators.required],
      brokerCode: ['']
    })
  }

}

