<div class="col-md-8 col-sm-6 col-6 d-inline-block mb-3">
    <button class="btn btn-sm btn-pill" (click)="view = 'current'" [ngClass]="view == 'current' ? 'btn-info' : 'btn-label-info'">Currently insured</button>
    <button class="btn btn-sm btn-pill" (click)="view = 'previous'" [ngClass]="view == 'previous' ? 'btn-info' : 'btn-label-info'">Previously insured</button>
  </div>
  
  <ng-container *ngIf="view === 'current'">
    <div class="example-container">
  
      <div class="example-table-container">
    
        <table mat-table [dataSource]="userAllRiskItems" matSort matSortActive="created" matSortDisableClear
          matSortDirection="desc">
    
          <!-- Item name Column -->
          <ng-container matColumnDef="itemName">
            <th mat-header-cell *matHeaderCellDef>Item Name</th>
            <td mat-cell *matCellDef="let row">{{ row?.itemName }}</td>
          </ng-container>
    
          <!-- Specified item Column -->
          <ng-container matColumnDef="specified">
            <th mat-header-cell *matHeaderCellDef>Specified item</th>
            <td mat-cell *matCellDef="let row">{{ row?.specified ? 'Yes' : 'No' }}</td>
          </ng-container>
    
          <!-- Insured Value Column -->
          <ng-container matColumnDef="insuredValue">
            <th mat-header-cell *matHeaderCellDef>Insured Value</th>
            <td mat-cell *matCellDef="let row">{{ row?.insurance?.insuredValue | currency: 'R ' }}</td>
          </ng-container>

          <ng-container matColumnDef="inceptionDate">
            <th mat-header-cell *matHeaderCellDef>Inception date</th>
            <td mat-cell *matCellDef="let row" data-label="Inception date">{{ row?.insurance?.inceptionDate | date: 'dd MMM yyyy' }}</td>
          </ng-container>
    
          <!-- Menu Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" data-label="Actions">
              <ng-container *ngIf="canViewItem" >
                <button class="btn btn-light btn-pill color-brand" matTooltip="Edit item" [routerLink]="['short-term-insurance/allRisk/edit/', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
                <button class="btn btn-light btn-pill color-danger" matTooltip="Cancel item insurance" (click)="deleteAllRiskItemInsurance(row)"><i class="fi flaticon-trash mr-0"></i></button>
              </ng-container>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>


      <div class="col-12 text-center mt-3" *ngIf="userAllRiskItems?.length === 0">
        <p>No all risk items found</p>
      </div>
    
      <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getUserAllRiskItems($event)">
      </mat-paginator>
    
    </div>
  </ng-container>
  
  <ng-container *ngIf="view === 'previous'">
    <div class="example-container">
  
      <div class="example-table-container">
    
        <table mat-table [dataSource]="previouslyInsuredUserAllRiskItems" matSort matSortActive="created" matSortDisableClear
          matSortDirection="desc">
    
          <!-- Item name Column -->
          <ng-container matColumnDef="itemName">
            <th mat-header-cell *matHeaderCellDef>Item Name</th>
            <td mat-cell *matCellDef="let row">{{ row?.item?._id?.itemName }}</td>
          </ng-container>
    
          <!-- Specified item Column -->
          <ng-container matColumnDef="specified">
            <th mat-header-cell *matHeaderCellDef>Specified item</th>
            <td mat-cell *matCellDef="let row">{{ row?.item?._id?.specified ? 'Yes' : 'No' }}</td>
          </ng-container>

          <!-- Insured Value Column -->
          <ng-container matColumnDef="insuredValue">
            <th mat-header-cell *matHeaderCellDef>Insured Value</th>
            <td mat-cell *matCellDef="let row">{{ row?.item?.insuredValue | currency: 'R ' }}</td>
          </ng-container>

          <!-- Insured Value Column -->
          <ng-container matColumnDef="terminationDate">
            <th mat-header-cell *matHeaderCellDef>Termination date</th>
            <td mat-cell *matCellDef="let row">{{ row?.item?.removedDate | date: 'dd MMM yyyy' }}</td>
          </ng-container>
    
          <!-- Menu Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" data-label="Actions">
                <button class="btn btn-light btn-pill color-brand" matTooltip="View item" [routerLink]="['short-term-insurance/allRisk/edit/', row._id]" [view]="true"><i class="fi flaticon-edit mr-0"></i></button>
                <!-- <button class="btn btn-light btn-pill color-danger" matTooltip="Cancel home" (click)="deleteAllRiskItemInsurance(row)"><i class="fi flaticon-trash mr-0"></i></button> -->
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumnsPrevious"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPrevious;"></tr>
        </table>
      </div>

      <div class="col-12 text-center mt-3" *ngIf="previouslyInsuredUserAllRiskItems?.length === 0">
        <p>No previously insured items found</p>
      </div>
    
      <mat-paginator [length]="resultsLengthPreviousInsurance" [pageSize]="pageSizePreviousInsurance" [pageSizeOptions]="pageSizeOptions" (page)="getUserPreviousInsuredAllRisk($event)">
      </mat-paginator>
    
    </div>
  </ng-container>
  