import { ReturnedDebitOrders, ReturnedDebitOrdersResponse } from 'src/app/_shared/models/returnedDebitOrders.model';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core'
import { Globals } from 'src/app/app.globals';

@Injectable({
  providedIn: 'root'
})
export class BrokerReturnedDebitService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getAllReturnedDebits(brokerCompany:string, req?:any, noLoad?:boolean): Observable<HttpResponse<ReturnedDebitOrdersResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<ReturnedDebitOrdersResponse>(`${this.globals.SERVER_URL}/api/brokerReturnedDebitOrders/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getAllReturnedDebitsPerUser(brokerCompany:string, clientId:string, req?:any, noLoad = false): Observable<HttpResponse<ReturnedDebitOrdersResponse>>{
    
    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }
    
    return this.http.get<ReturnedDebitOrdersResponse>(`${this.globals.SERVER_URL}/api/brokerReturnedDebitOrders/${brokerCompany}/user/${clientId}`, {
      params: req,
      observe: 'response',
      headers
    });
  };

  getSelectedReturnedDebit(brokerCompany:string, returnedDebitId:string){
    return this.http.get<ReturnedDebitOrdersResponse>(`${this.globals.SERVER_URL}/api/brokerReturnedDebitOrders/${brokerCompany}/${returnedDebitId}`, {
      observe: 'response'
    });
  };

  addNewReturnedDebit(brokerCompany:string, formData:ReturnedDebitOrders): Observable<HttpResponse<ReturnedDebitOrdersResponse>> {
    return this.http.post<ReturnedDebitOrdersResponse>(`${this.globals.SERVER_URL}/api/brokerReturnedDebitOrders/${brokerCompany}`, formData, {
      observe: 'response'
    });
  }

  editReturnedDebit(brokerCompany:string, returnedDebitId:string, formData:ReturnedDebitOrders): Observable<HttpResponse<ReturnedDebitOrdersResponse>> {
    return this.http.put<ReturnedDebitOrdersResponse>(`${this.globals.SERVER_URL}/api/brokerReturnedDebitOrders/${brokerCompany}/${returnedDebitId}`, formData, {
      observe: 'response'
    });
  }
  
}
