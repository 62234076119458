<!-- <div *ngIf="hasRequestAttached" id="instruction-handover" class="panel panel-primary">
    <div class="row py-3 px-3">
        <div class="col-12 col-md-8 my-auto">
            <h5 class="mb-0">
                <strong *ngIf="request?.whatNeedsToHappen === 'handover'">
                    This claim is being handed over from {{ claimFormGroup.get('employee').get('firstName').value }} to you.
                    Do you accept this handover?
                </strong>
                <strong *ngIf="request?.whatNeedsToHappen === 'signOff'">
                    {{ request?.message }}  Do you approve this signoff?
                </strong>
            </h5>
        </div>
        <div class="col-12 col-md-4 text-center text-md-right">
            <button class="btn btn-success text-white" (click)="acceptRequest(request._id)">Accept</button>
            <button class="btn btn-danger text-white" (click)="rejectRequest(request._id)">Reject</button>
        </div>
    </div> <!-- Close div for .row ->
</div> -->

<div id="content" class="row">
    <sub-navigation heading="Add new claim"></sub-navigation>

    <div class="col-12">

        <form [formGroup]="claimsFormGroup">
  
            <div class="panel panel-primary">

                <div class="panel-heading">
                    <h5>Claim details</h5>
        
                    <div class="my-auto">
        
                    <button type="button" matTooltip="Go back to view all claims" class="btn btn-secondary my-auto" (click)="goBack()">
                        <i class="fi flaticon-back"></i>Back
                    </button>
        
                    <div class="btn-group" ngbDropdown>

                        <button type="submit" class="btn btn-primary" mat-tooltip="Save claim and go back." (click)="createNewClaim('back')">
                            Save claim
                        </button>

                        <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                        </button>
                        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewClaim('continue')">Save &amp; continue</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewClaim('new')">Save &amp; add new</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewClaim('back')">Save &amp; go back</a></li>
                        </ul>

                    </div> <!-- Close div for .btn-group -->
        
                    </div> <!-- Close div for .my-auto -->
        
                </div>
        
                <div class="panel-body">
                    
                    <div class="row">
                        <broker-claim-form-group class="col-12" formControlName="claim"></broker-claim-form-group>
                    </div>

                </div>

            </div>

        </form>

    </div>

</div>