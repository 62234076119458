import { BrokerCompanyResponse } from 'src/app/_shared/models/brokerCompany.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from "@angular/router"
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-register-broker-company',
  templateUrl: './broker-company.component.html',
  styleUrls: ['./broker-company.component.scss']
})
export class RegisterBrokerCompanyComponent implements OnInit {

  formSubmitted = false;
  registerForm: FormGroup;
  password: string;
  passwordStrength: number;

  recaptcha_site_key = "6Le-D5YUAAAAAO-7-I1Ao-6enzpC3z7Z14-YpJaS";

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private router: Router
  ) {}

  get form(){ return this.registerForm.controls; }

  registerNewBrokerCompany(){

    this.formSubmitted = true;
    if (this.registerForm.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.authentication.registerNewBrokerCompany(this.registerForm.value).subscribe(
      (response:HttpResponse<BrokerCompanyResponse>) => {
        this.router.navigate(['/login']);
      }
    );

  };

  onStrengthChanged(strength: number) {
    this.passwordStrength = strength;
  }

  resolved(captchaResponse: string) {
    // this.registerForm.controls.captcha = captchaResponse;
  };

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
        companyName: ['' , Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        contactNumber: ['', Validators.required],
        brokerCode: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(7)]],
        type: ['broker'],
        companyType: ['brokerCompany'],
        permissions: ['brokerAdmin'],
        captcha: ['', Validators.required],
      });
  }

}
