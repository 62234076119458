import { BrokerClaim, BrokerClaimResponse } from './../_shared/models/brokerClaims.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrokerClaimsService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getAllClaims(brokerCompany:string, req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerClaimResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/brokerClaims/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getAllClaimsPerUser(brokerCompany:string, clientId:string, req?:any){
    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/brokerClaims/${brokerCompany}/user/${clientId}`, {
      params: req,
      observe: 'response',
    });
  }

  getSelectedClaim(brokerCompany:string, instructionId:string){
    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/brokerClaims/${brokerCompany}/${instructionId}`, {
      observe: 'response'
    });
  };

  addNewClaim(brokerCompany:string, formData:BrokerClaim): Observable<HttpResponse<BrokerClaimResponse>> {
    return this.http.post<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/brokerClaims/${brokerCompany}`, formData, {
      observe: 'response'
    });
  }

  editClaim(brokerCompany:string, claimId:string, formData:BrokerClaim): Observable<HttpResponse<BrokerClaimResponse>> {
    return this.http.put<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/brokerClaims/${brokerCompany}/${claimId}`, formData, {
      observe: 'response'
    });
  }

  getClaimSteps(){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/brokerClaims/steps`, {
      observe: 'response',
    });
  }

}
