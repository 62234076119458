<div class="page-description">
    <div class="row h-100">
      <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
        <h6 class="d-inline-block mb-0">Dropdown</h6>
      </div>
    </div>
  </div>
  
  <div class="row">
  
    <div class="col-6 float-left">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Basic example</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Single button dropdowns:</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-success dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>
  
          </div> <!-- Close div for .row -->
  
          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Dropdown menu with icons:</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-success dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-add"></i> Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-edit"></i> Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-trash"></i> Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-settings"></i> Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-bell"></i> Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#"><i class="fi flaticon-faq"></i> Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div> <!-- Close div for .col -->
  
          </div> <!-- Close div for .row -->
          
          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Button state dropdowns:</p>

          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
              Primary
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              Secondary
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-success dropdown-toggle" aria-controls="dropdown-basic">
              Success
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-info dropdown-toggle" aria-controls="dropdown-basic">
              Info
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-danger dropdown-toggle" aria-controls="dropdown-basic">
              Danger
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Single button dropdowns:</p>

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-primary">
              Primary
            </button>
            <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-secondary">
              Secondary
            </button>
            <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-success">
              Success
            </button>
            <button ngbDropdownToggle type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-warning">
              Warning
            </button>
            <button ngbDropdownToggle type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Single icon button dropdowns:</p>

          <div class="dropdown dropdown-inline mr-2" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-hover-brand btn-icon btn-pill" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="dropdown dropdown-inline mr-2" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-hover-danger btn-icon" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="dropdown dropdown-inline mr-2" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-hover-primary btn-icon btn-sm" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="dropdown dropdown-inline mr-2" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-default btn-icon btn-sm" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="dropdown dropdown-inline mr-2" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-clean btn-icon btn-sm" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="dropdown dropdown-inline" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-primary btn-icon btn-pill btn-sm" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">New report</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Add customer</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">New download</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Settings</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->
  

        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Sizing</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Button dropdowns work with buttons of all sizes, including default and split dropdown buttons.</p>
  
          <div class="btn-group" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-secondary btn-lg dropdown-toggle" aria-controls="dropdown-basic">
              Large button
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-secondary btn-lg">
                Large split button
            </button>
            <button ngbDropdownToggle type="button" class="btn btn-secondary btn-lg dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
            </button>
            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
            </ul>
          </div> <!-- Close div for .btn-group -->

          <hr class="hr-light hr-dashed mb-4 mt-4" />

          <div class="btn-group" ngbDropdown>
              <button ngbDropdownToggle type="button" class="btn btn-secondary btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                Small button
              </button>
              <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
            </div> <!-- Close div for .btn-group -->
  
            <div class="btn-group" ngbDropdown>
              <button type="button" class="btn btn-primary btn-sm">
                  Small split button
              </button>
              <button ngbDropdownToggle type="button" class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
              </button>
              <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
            </div> <!-- Close div for .btn-group -->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->
  
    <div class="col-6">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Dropup variation</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Trigger dropdown menus above elements by adding <code>placement</code> property to the parent directive element.</p>
  
          <div class="row">

            <div class="col">
              <div class="btn-group dropdown-up" ngbDropdown placement="top-left">

                <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown up
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>
  
            <div class="col">
              <div class="btn-group dropdown-left" ngbDropdown placement="left-start">

                <button ngbDropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown left
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>

            <div class="col">
              <div class="btn-group dropdown-right" ngbDropdown placement="right-start">

                <button type="button" class="btn btn-outline-success">
                  Dropdown right 
                </button>
                <button ngbDropdownToggle type="button" class="btn btn-outline-success dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .btn-group -->
            </div>

          </div> <!-- Close div for .row -->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
  
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Dropdown menu</h5>
        </div>
  
        <div class="panel-body">
  
          <p>You can optionally use <code>button</code> elements for your dropdowns items.</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown menu 
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <button type="button" class="dropdown-item" href="#">Action</button>
                  <button type="button" class="dropdown-item" href="#">Another action</button>
                  <button type="button" class="dropdown-item" href="#">Something else here</button>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown menu 
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <button type="button" class="dropdown-item" href="#">Action</button>
                  <button type="button" class="dropdown-item" href="#">Another action</button>
                  <button type="button" class="dropdown-item" href="#">Something else here</button>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown menu 
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <button type="button" class="dropdown-item" href="#">Action</button>
                  <button type="button" class="dropdown-item" href="#">Another action</button>
                  <button type="button" class="dropdown-item" href="#">Something else here</button>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

          </div> <!-- Close div for .row -->

          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Add <code>placement="bottom-right"</code> to a <code>ngbDropdown</code> directive to right align the dropdown menu.</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown placement="bottom-right">

                <button ngbDropdownToggle type="button" class="btn btn-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Right aligned 
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Left aligned
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown placement="bottom-right">

                <button ngbDropdownToggle type="button" class="btn btn-outline-danger dropdown-toggle" aria-controls="dropdown-basic">
                  Right aligned
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

          </div> <!-- Close div for .row -->


          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Add a header to label sections of actions in any dropdown menu.</p>

          <div class="row">
  
            <div class="col">
              <div class="btn-group" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <h6 class="dropdown-header">Dropdown header</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="btn-group" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-outline-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <h6 class="dropdown-header">Dropdown header</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
    
              </div> <!-- Close div for .btn-group -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="btn-group">

                <button type="button" class="btn btn-outline-success">
                  Dropdown button
                </button>
                <div class="btn-group" ngbDropdown>
                    <button ngbDropdownToggle type="button" class="btn btn-outline-success dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <h6 class="dropdown-header">Dropdown header</h6>
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> <!-- Close div for .btn-group -->
              </div> <!-- Close div for .btn-group -->
            </div> <!-- Close div for .col -->

          </div> <!-- Close div for .row -->


          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Separate groups of related menu items with a divider.</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-outline-danger dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

          </div> <!-- Close div for .row -->


          <hr class="hr-light hr-dashed mb-4 mt-5" />
          <p>Add <code>.disabled</code> to items in the dropdown to style them as disabled.</p>
  
          <div class="row">
  
            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-warning dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button 
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item disabled" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-brand dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item disabled" href="#">Something else here</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

            <div class="col">
              <div class="dropdown" ngbDropdown>

                <button ngbDropdownToggle type="button" class="btn btn-danger dropdown-toggle" aria-controls="dropdown-basic">
                  Dropdown button
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item disabled" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
    
              </div> <!-- Close div for .dropdown -->
            </div> <!-- Close div for .col -->

          </div> <!-- Close div for .row -->

        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->
  
  </div> <!-- Close div for .row -->
  