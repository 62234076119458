<div class="row" [formGroup]="returnedDebitFormGroup">

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Client"
                  placeholder="Client"
                  formControlName='client'
                  [matAutocomplete]="user"
                  [readonly]="inEditMode"
                />
                <mat-hint [hidden]="returnedDebitFormGroup.get('client')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding clients...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="clients?.length === 0">
                            <span>No client found</span>
                        </mat-option>
                        <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="clientSelected($event.source.value)">
                            <span>{{ client?.companyName }}{{ client?.firstName }} {{ client?.lastName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>client</strong> for this return debit order.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <input placeholder="Returned debit order date" matInput [matDatepicker]="returnedDebitDate" name="returnedDebitDate"
                    formControlName="returnedDebitDate" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="returnedDebitDate"></mat-datepicker-toggle>
                <mat-datepicker touchUi #returnedDebitDate></mat-datepicker>

                <mat-error>
                    Please select the <strong>date of the debit order</strong> which has failed.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>


    <!-- POLICIES -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Policy number"
                  placeholder="Policy number"
                  formControlName='policyNumber'
                  [matAutocomplete]="policyAuto"
                  [readonly]="inEditMode"
                />
                <mat-hint [hidden]="returnedDebitFormGroup.get('policy')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #policyAuto="matAutocomplete">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding policies...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="policies?.length === 0">
                            <span>No policies found</span>
                        </mat-option>
                        <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                            <span>{{ policy?.policyNumber }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>policy</strong> for this return debit order.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Employee"
                  placeholder="Employee"
                  formControlName='employee'
                  [matAutocomplete]="employeeAuto"
                />
                <mat-hint [hidden]="returnedDebitFormGroup.get('employee')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #employeeAuto="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding employees...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="employees?.length === 0">
                            <span>No employee found</span>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees" [value]="employee">
                            <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select an <strong>employee</strong> for this return debit order.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

</div>

<div class="row returnedDebitSteps mt-3" [formGroup]="returnedDebitFormGroup">

    <div class="col-12" formGroupName="returnedDebitSteps">

        <div class="row">

            <div class="col-12" formGroupName="receivedReturnedDebitFromInsuranceCompany">
                <mat-checkbox [disabled]="displayCompletedBy('receivedReturnedDebitFromInsuranceCompany')" class="step" disableRipple formControlName="value">The insurance company has sent a notification that a debit order has failed.</mat-checkbox>
                <small *ngIf="displayCompletedBy('receivedReturnedDebitFromInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('receivedReturnedDebitFromInsuranceCompany') }} </small>
            </div>

            <div class="col-12" formGroupName="communicateReturnedDebitToClient">
                <mat-checkbox [disabled]="displayCompletedBy('communicateReturnedDebitToClient')" class="step" disableRipple formControlName="value">Inform the client about the failed debit order.</mat-checkbox>
                <small *ngIf="displayCompletedBy('communicateReturnedDebitToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('communicateReturnedDebitToClient') }} </small>
            </div>

            <div class="col-12" *ngIf="!returnedDebitFormGroup.get('returnedDebitSteps').get('clientRequestDoublePayment').value.value" formGroupName="clientRequestForcedDebit">
                <mat-checkbox [disabled]="displayCompletedBy('clientRequestForcedDebit')" class="step" disableRipple formControlName="value">The client has requested a forced debit.</mat-checkbox>
                <small *ngIf="displayCompletedBy('clientRequestForcedDebit')" class="text-muted">Completed by: {{ getStepCompletedBy('clientRequestForcedDebit') }} </small>
            </div>

            <div class="col-12" *ngIf="returnedDebitFormGroup.get('returnedDebitSteps').get('clientRequestForcedDebit').value.value">
                <div class="input-group">
                    <mat-form-field class="mb-0" formGroupName="forcedDebitDate">
                        <input placeholder="Forced debit order date" matInput [matDatepicker]="forcedDebitDate" name="forcedDebitDate"
                            formControlName="value" [min]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="forcedDebitDate"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #forcedDebitDate></mat-datepicker>        
                    </mat-form-field>
                    <small *ngIf="displayCompletedBy('forcedDebitDate')" class="text-muted">Completed by: {{ getStepCompletedBy('forcedDebitDate') }} </small>
                </div> <!-- Close div for .input-group -->
            </div>
            
            <div class="col-12" *ngIf="!returnedDebitFormGroup.get('returnedDebitSteps').get('clientRequestForcedDebit').value.value" formGroupName="clientRequestDoublePayment">
                <mat-checkbox [disabled]="displayCompletedBy('clientRequestDoublePayment')" class="step" disableRipple formControlName="value">The client has requested a double payment.</mat-checkbox>
                <small *ngIf="displayCompletedBy('clientRequestDoublePayment')" class="text-muted">Completed by: {{ getStepCompletedBy('clientRequestDoublePayment') }} </small>
            </div>

            <div class="col-12" *ngIf="returnedDebitFormGroup.get('returnedDebitSteps').get('clientRequestDoublePayment').value.value" formGroupName="savedClientRequestToSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('savedClientRequestToSureSpace')" class="step" disableRipple formControlName="value">The client request for a double payment has been saved to SureSpace.</mat-checkbox>
                <small *ngIf="displayCompletedBy('savedClientRequestToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('savedClientRequestToSureSpace') }} </small>
            </div>

            <div class="col-12" formGroupName="confirmedPaymentReceived">
                <mat-checkbox [disabled]="displayCompletedBy('confirmedPaymentReceived')" class="step" disableRipple formControlName="value">Confirmed with the insurance company that the payment has been received.</mat-checkbox>
                <small *ngIf="displayCompletedBy('confirmedPaymentReceived')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmedPaymentReceived') }} </small>
            </div>

            <div class="col-12" formGroupName="returnedDebitFinalised">
                <mat-checkbox [disabled]="displayCompletedBy('returnedDebitFinalised')" class="step" disableRipple formControlName="value">The returned debit order needs to be signed off.</mat-checkbox>
                <small *ngIf="displayCompletedBy('returnedDebitFinalised')" class="text-muted">Completed by: {{ getStepCompletedBy('returnedDebitFinalised') }} </small>
            </div>

            <div *ngIf="canCompleteReturnedDebit()" class="col-12" formGroupName="returnedDebitSignedOff">
                <mat-checkbox [disabled]="displayCompletedBy('returnedDebitSignedOff')" class="step" disableRipple formControlName="value">The returned debit order is finished and complete.  This returned debit order is signed off.</mat-checkbox>
                <small *ngIf="displayCompletedBy('returnedDebitSignedOff')" class="text-muted">Completed by: {{ getStepCompletedBy('returnedDebitSignedOff') }} </small>
            </div>

        </div>

    </div>

</div>