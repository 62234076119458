<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
      <h6 class="d-inline-block mb-0">Tables</h6>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-6">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Basic tables</h5>
      </div>

      <div class="panel-body">

        <p>Using the most basic table markup, here’s how tables look in SureSpace:</p>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

        <hr class="hr-light hr-dashed mb-4 mt-5" />
        <p>You can also invert the colors with light text on dark backgrounds by adding a <code>.table-dark</code>
          class.</p>


        <div class="table-responsive">
          <table class="table table-dark">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Table head options</h5>
      </div>

      <div class="panel-body">

        <p>Similar to default and inverse tables, use one of two modifier classes to make <code>&lt;thead&gt;</code>s
          appear light or dark gray:</p>

        <div class="table-responsive">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->


        <div class="table-responsive mt-3">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Small table</h5>
      </div>

      <div class="panel-body">

        <p>Add <code>.table-sm</code> to make tables more compact by cutting cell padding in half:</p>

        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Striped row</h5>
      </div>

      <div class="panel-body">

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

  <div class="col-6">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Bordered table</h5>
      </div>

      <div class="panel-body">

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->



    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Hoverable table</h5>
      </div>

      <div class="panel-body">

        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>larry@twitter.com</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Table row states</h5>
      </div>

      <div class="panel-body">

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>

              <tr class="table-active">
                <td><strong>1</strong></td>
                <td>Jan</td>
                <td>Erasmus</td>
                <td>janneman@surespace.co.za</td>
              </tr>

              <tr class="table-primary">
                <td><strong>2</strong></td>
                <td>Carmi</td>
                <td>Erasmus</td>
                <td>zeelie.carmi@gmail.com</td>
              </tr>

              <tr class="table-success">
                <td><strong>3</strong></td>
                <td>Andreas</td>
                <td>Smith</td>
                <td>andreas@scarletred.co.za</td>
              </tr>

              <tr class="table-brand">
                <td><strong>4</strong></td>
                <td>MJ</td>
                <td>Meyer</td>
                <td>mj@scarletred.co.za</td>
              </tr>

              <tr class="table-warning">
                <td><strong>5</strong></td>
                <td>Heinrich</td>
                <td>Coetzee</td>
                <td>heinrich@scarletred.co.za</td>
              </tr>

              <tr class="table-danger">
                <td><strong>5</strong></td>
                <td>De Wet</td>
                <td>Smit</td>
                <td>dewet@scarletred.co.za</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Responsive tables</h5>
      </div>

      <div class="panel-body">

        <p>Create responsive tables by wrapping any table with <code>.table-responsive</code>  <code>DIV</code> to make
          them scroll horizontally on small devices (under 768px).</p>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
                <th>Table heading</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>1</strong></td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>

              <tr>
                <td><strong>2</strong></td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>

              <tr>
                <td><strong>3</strong></td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
                <td>Table cell</td>
              </tr>

            </tbody>
          </table>
        </div> <!-- Close div for .table-responsive-->

      </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

</div> <!-- Close div for .row -->
