import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { SurespaceService } from 'src/app/services/surespace.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BugResponse } from 'src/app/_shared/models/bug.model';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {

  authenticated = this.globals.AUTENTICATED;

  public Editor = ClassicEditor;

  submitted = false;
  newIssueForm: FormGroup;
  get form() { return this.newIssueForm.controls; }

  constructor(
    public globals: Globals,
    private surespaceService: SurespaceService,
    private formBuilder: FormBuilder
  ) { }

  reportIssue() {

    this.submitted = true;
    if (this.newIssueForm.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.surespaceService.reportBug(this.newIssueForm.value).subscribe(
      (response:HttpResponse<BugResponse>) => {
        this.newIssueForm.reset();
      }
    )
  }

  ngOnInit() {
    this.newIssueForm = this.formBuilder.group({
      submitterName: ['', Validators.required],
      submitterEmail: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

}
