import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { SurespaceService } from 'src/app/services/surespace.service';
import { FAQ, FAQResponse } from 'src/app/_shared/models/faq.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  authenticated = this.globals.AUTENTICATED;
  questions: any[] = [];
  categories: any[] = [];

  selectedCategory: number = 1;
  faq: FAQ;

  constructor(
    public globals: Globals,
    private surespaceService: SurespaceService
  ) {

    this.categories = [
      {
        id: 1,
        category: 'Clients'
      }, {
        id: 2,
        category: 'Employees'
      }, {
        id: 3,
        category: 'Admin'
      }, {
        id: 4,
        category: 'Insurance'
      }, {
        id: 5,
        category: 'Reporting'
      }
    ]

    this.questions = [
      {
        question: 'How do I add a new client?',
        answer: `SureSpace allows you to add any kind of client.  Clients can be added by clicking on the <b>'personal'</b> or <b>'commercial'</b> buttons in the main navigation on
        the left side of the screen.  The next screen will show you a list of clients already added and assigned to you. On this screen you will also be able to filter your client
        by role, or search individual clients.  You can also view the clients of all other employees, of you have the proper permissions <i>(see permissions under the employee category.)</i>
        <br /><br />
        From this screen, click on the 'Add client' button to add a new client.  On the 'new client' page, fill in all of the required info.  On the 'Insurance information' page,
        select the consultant that will be responsible for the client.  Also select the type of insurance that the employee would like.  If vehicle insurance is selected for
        example, only vehicles will be able to be insured for the client.
        <br /><br />
        After a client has been added, the client will now be visible in the list of clients.`,
        category: 1
      }, {
        question: 'What is the difference between a personal client and a commercial client?',
        answer: `A <b>personal client<b/> is any person in a personal capacity.  A personal client can be you a neighbour or a friend.  A <b>commercial client</b> is the 
        business that the neighbour is running.`,
        category: 1
      }, {
        question: 'What do I do after I have added a client?',
        answer: `When a new client is added, the client will be visible in the list of clients already added, whether a personal or commercial client.  Clients can be edited
        or removed from here.  When editing a client, all information can be changed, except for the ID number.  If a new consultant should be assigned to the client, the client
        can be edited and a new consultant can then be assigned to said client.
        <br /><br />
        Clients can also be removed.  If a client is no longer a client, the client can then be removed.  The client will no longer be visible in the list of known clients.`,
        category: 1
      }, {
        question: 'How do I assign a broker to a client.',
        answer: `When a new client is added, or an existing client is edited, an existing broker can be assigned to a client.  Under the Insurance Information section, in the
        consultant input, select the broker that you would like to assign to the client.
        <br /><br />
        The newly assigned employee will now be able to work with this client.`,
        category: 1
      }, {
        question: 'How do I add a new product to a clients\' policy?',
        answer: `When you are the broker who is assigned to a client, you can add products to an exiting policy of that user.  When viewing the list of clients, click on the
        'pencil' icon to edit the client.  On the client edit screen, click on the 'products' tab.  From here you can be able to add any product that was specified in the client
        file.`,
        category: 1
      }, {
        question: 'Can I share a file with a client?',
        answer: `Yes.  SureSpace has the ability to send files to client via email.  In the client edit section, there is an email tab which allows a broker send an email to
        their clients.  The email section has the option to add a file attachment.  Only files generated by SureSpace can be added as attachments on emails.`,
        category: 1
      }, {
        question: 'How can I send an email to a client?',
        answer: `In the client edit section there is an email tab that allows the broker to send an email to their clients with the optional file attachment.
        <br /><br />
        To be able to send emails from the system, emails need to be set up.  After logging in, click on the profile icon the top right corner of the screen in the top navigation
        bar.  A dropdown will appear.  Click on the <b>'settings'</b> button.  On this you can navigate to the <b>'email settings'</b> tab.  Here you need to setup your email smtp settings
        that is allocated to your email address.  These settings will be used to send emails from the system to your clients.
        You should already have these settings set up on your local email client, if you do not have these settings you can contact your email service provider.
        <br /><br />
        You can also setup your email signature that will be added to all outgoing emails.  Any email sent from the system will bear your email signature that is set up here.`,
        category: 1
      },

      {
        question: 'How do I add an employee?',
        answer: `Employees are an integral part of your business.  You can add employees by clicking on the <b>'Employees'</b> button in the main side navigation on the left
        hand side of the portal.  On the next screen you will se a grid layout list of already added employees.  You can filter the employee view to show employees based on their role
        or employment status.  You can also search employees by name or email.
        <br /><br />
        To add a new employee, click on the 'Add employee' button in the top right side of the page.  On the add employee page, fill in all the required employee information.
        After all the information has been added, click on the 'Add employee' button to save the new employee.  The employee will now be visible in the grid of employees that
        has already been added.
        <br /><br />
        Click on this link to see a video preview of how to add an employee:  <br />
        <a href="https://youtu.be/6rr0qVPZIgw" target="_blank">https://youtu.be/6rr0qVPZIgw</a>`,
        category: 2
      }, {
        question: 'What happens after I add an employee?',
        answer: `When a new employee is added via the steps above, the new employee will get an email to let them know that a SureSpace profile has been created for them.
        The email will contain their username and a temporary password.  They can log into the portal using their username and the temporary password given, but will also be
        requested to change their password immediately after logging in.  This is only a security issue as the password sent via email is unsafe because it is optainable via email.`,
        category: 2
      }, {
        question: 'How do I, as an employee, change my password for the SureSpace portal?',
        answer: `Passwords can be changed as many times as you like. <b>PLEASE REMEMBER, ALWAYS KEEP YOUR PASSWORD SAFE!</b>  SureSpace does everything it can to keep passwords
        safe.  We will always be upgrading and improving our site to make sure that passwords are as safe as we can make it.
        <br /><br />
        After logging in, click on the profile icon the top right corner of the screen in the top navigation bar.  A dropdown will appear.  Click on the <b>'settings'</b> button.
        On this screen, navigate to the 'My password' tab.  From here you can change your password.  Type your current password, then the new password that you will like to use
        in the future.  Confirm your new password and click on the <b>'Change password'</b> button for the changes to take effect.
        <br /><br />
        You can now ONLY use your new password to log in.`,
        category: 2
      }, {
        question: 'How do I prevent an employee from performing certain tasks?',
        answer: `Certain employees should only be able to do certain things.  When creating, or editing, an employee, an employee role can be set in the permission select box.
        Multiple roles can be assigned to a single employee.  This is to handle overlapping permissions.
        <br /><br />
        Here is a breakdown of what these roles have permissions to:<br />
        <u>Director</u><br />
        A director role has global permissions.  A director can add, edit, view or remove anything on the system.  This role has no limits.
        <br /><br />
        
        <u>HR</u><br />
        HR handles mostly administrative work and employees.  They are unable to work with clients and client related issues.  Here is what the HR role can do:<br />
        
        <ul>
          <li>View Clients</li>
          <li>Create, View, Edit, Delete Employees</li>
          <li>View Client Products</li>
          <li>View Administrators</li>
          <li>View Underwriters</li>
          <li>View Policies</li>
          <li>View Claims</li>
          <li>View Quotes</li>
          <li>View Reports</li>
        </ul>
        <br /><br />

        <u>Manager</u><br />
        Managers are there to manage clients.  Here is what the Manager role can do:<br />
        
        <ul>
          <li>Create, View, Edit &amp; Delete Clients</li>
          <li>View Employees</li>
          <li>Create, View, Edit &amp; Delete Client Products</li>
          <li>Create, View, Edit &amp; Delete Administrators</li>
          <li>Create, View, Edit &amp; Delete Underwriters</li>
          <li>Create, View, Edit &amp; Delete Policies</li>
          <li>Create, View &amp; Edit Claims</li>
          <li>Create, View &amp; Edit Quotes</li>
          <li>View Reports</li>
        </ul>
        <br /><br />


        <u>Team Leader</u><br />
        Team Leaders should lead the teams they are assigned to.  They should manage and assist clients.  Here is what the Team Leader role can do:<br />
        
        <ul>
          <li>Create, View &amp Edit Clients</li>
          <li>View Employees</li>
          <li>Create, View, Edit &amp; Delete Client Products</li>
          <li>View Administrators</li>
          <li>View Underwriters</li>
          <li>Create, View, Edit &amp; Delete Policies</li>
          <li>Create, View &amp; Edit Claims</li>
          <li>Create, View &amp; Edit Quotes</li>
          <li>View Reports</li>
        </ul>
        <br /><br />


        <u>Claims Technician</u><br />
        Claim Technicians should be created to handle client claims.  Here is what the Claims Technician role can do:<br />
        
        <ul>
          <li>View Clients</li>
          <li>View Employees</li>
          <li>View Client Products</li>
          <li>View Administrators</li>
          <li>View Underwriters</li>
          <li>View Policies</li>
          <li>Create, View &amp; Edit Claims</li>
          <li>View Quotes</li>
        </ul>
        <br /><br />

        <u>Administrator</u><br />
        Administrators should handle clients and client related issues.  Here is what the Administrator role can do:<br />
        
        <ul>
          <li>Create, View &amp Edit Clients</li>
          <li>View Employees</li>
          <li>Create, View, Edit &amp; Delete Client Products</li>
          <li>View Administrators</li>
          <li>View Underwriters</li>
          <li>Create, View &amp; Edit Policies</li>
          <li>View Claims</li>
          <li>Create, View &amp; Edit Quotes</li>
        </ul>
        <br /><br />

        <u>Clerk</u><br />
        Clerks are there to handle policies.  Here is what the Clerk role can do:<br />
        
        <ul>
          <li>View Clients</li>
          <li>View Client Products</li>
          <li>View Administrators</li>
          <li>View Underwriters</li>
          <li>Create, View &amp; Edit Policies</li>
          <li>Create, View &amp; Edit Claims</li>
        </ul>
        <br /><br />


        <u>Lead Provider</u><br />
        Lead providers are just there to provide leads to the company.  Here is what the Lead Provider role can do:<br />
        
        <ul>
          <li>Create &amp; View Clients</li>
          <li>Create, View, Edit &amp; Delete Client Products</li>
          <li>View Quotes</li>
        </ul>`,
        category: 2
      },


      {
        question: 'What is an instruction, and how do I add one?',
        answer: `Instructions are basically tasks assigned to clients and their products.  Whenever a client requests a change or addition to their policies, an instruction
        is created.  Instructions are created automatically when products are created in the client file.
        <br /><br />
        Instructions are step based.  So they can be edited constantly whenever a new step has been completed to keep track of tasks and what stage the task, or instruction,
        is in.
        <br /><br />
        Click on this link to see a video preview of how to add an instruction:  <br />
        <a href="https://youtu.be/aS6QzzcVTQM target="_blank">https://youtu.be/aS6QzzcVTQM</a>`,
        category: 3
      }, {
        question: 'How do I add a policy?',
        answer: `Policies are linked to clients and their products.  To add a new policy, click on the <b>'Policies'</b> button in the side navigation on the left side of the page.
        On the policies page, a list of previous policies will show up.  You can filter these policies by 'active' or 'cancelled' to only show you relevant information.  Policies
        can also be searched for by any filed, from the search input bar on this screen.
        <br /><br />
        Adding a new policy from here is as simple as clicking the <b>'Add new policy'</b> button in the top right corner.  After filling in all the required info, click on the
        <b>'Save policy'</b> button to save all of the information.
        <br /><br />
        When adding a new policy, items from a previous policy can be transfered to the new policy.  To do this, simply select the policy holder.  When a policy holder is selected
        a list of all the products belonging to that client will pop up.  Select the items that you would like to transfer to the new policy.  When saving the new policy, items
        will be cancelled on the previous policy and added to the new policy.
        <br /><br />
        Click on this link to see a video preview of how to add a policy:  <br />
        <a href="https://youtu.be/aS6QzzcVTQM target="_blank">https://youtu.be/aS6QzzcVTQM</a>`,
        category: 3
      }, {
        question: 'How do I cancel a policy?',
        answer: `Cancelling policies when they are no longer necessary is real simple.  Navigate to the Policies page by clicking on the <b>'Policies'</b> button in the main
        navigation on the left side of the page.  On the next page you will se a list of all the policies that have previously been added.
        <br /><br />
        To cancel, or deactivate, a policy simple select the policy that you want to deactivate and click on the red trash icon.  This will deactivate that policy.`,
        category: 3
      }, {
        question: 'How do I add a new claim on a product?',
        answer: `When a client gets in contact about lost or stolen items, a claim needs te be registered.  Claims can be created by clicking on the <b>'Claims'</b> button in
        the main navigation on the left side of the page.  On the next screen a list of previously added claims will appear.  On this screen claims can be filtered by status to
        only show claims that you are interested in.  Claims can also be searched for based on any field.
        <br /><br />
        To add a new claim from this page, simply click on th <b>'Add new claim'</b> button in the top right corner.  Claims can be very complicated, but it does not have to be.
        After filling in all of the required information regarding the claim, clicking on the <b>'Save claim'</b> button in the top right hand corner saves the claim.  Just like 
        instuctions, Claims are a multi step process so they will need to be edited regularly.  Claim steps keep track of the status of a claim.  You can easily found out where
        or what is happening to a claim by navigating to the claims page and clicking on the required claim and follow up from there.`,
        category: 3
      }, {
        question: 'How do I create a new quote?',
        answer: `An important part of managing a client is to be able to add new products to existing policies.  When a client does request a new item be added, a quote needs
        to be created in order to do so.  To create a new quote simply click on the <b>'Quote'</b> button in the main navigation on the left of the screen.  Here a list of previous
        quotes will be displayed.  Quotes can also be filtered by status, or a specific quote can be search in any field to display only the relevant quotes.  Clicking on the 
        <b>'Add new quote button'</b> will open the next screen and start adding a quote.
        <br /><br />
        On the new quote page, select the client that would like a new item added.  In the <b>Administrator</b> and <b>Underwriter</b> fields, simply add the parties that a quote
        should be requested from.  Please take note:  A quote will only be requested from these parties if a quote email has been registered on said Administrator or Underwriter.
        For more information on this, please read the Administrator and Underwriter section.
        <br /><br />
        In the <b>Products</b> section add the products that the client requested.  Clicking the <b>'Add product'</b> button will create a new product specific to the quote.  The
        product will only be visible in the client file after a quote has been accepted.  In the <b>Notes</b> section special requests for the quote receiver can be entered.  Make
        sure that all the details of the quote is correct and press the 'Finish' button to create the quote.  If more information is needed from the client, a quote can be saved as 
        a draft to finish it later.  Click on the down arrow next to the 'Finish' button and select 'Save as draft.  This will save the quote, but only in draft format, so that
        it can be completed and be sent later.  If the <b>'Finish'</b> button is clicked a quote will be generated and saved under the client file.  This quote will also be emailed
        to the Administrator and Underwriter that was selected in step 1.  Please note that the Administrator or Underwriter needs to have a <b>quote</b> email added to be able
        to email them a quote.  For more information see the 'Administrators' and 'Underwriters' section.
        <br /><br />
        If a client is happy with a quote that was received from the Administrator or Underwriter, the quote created by the system can be accepted.  To do this simply navigate back
        to the list of quotes and find the required quote.  Simple click on the three dots icon and select 'Approve quote', or alternatively 'Reject quote'.  When a quote is
        accepted the product that was created needs to be amended.  A popup screen showing these products will pop up so the premium of these products can be added.  The added
        products will also now be active on the client file.`,
        category: 3
      }, {
        question: 'What happens if a client did not pay his policy last month?',
        answer: `If a client does not pay their policy, a <b>Returned debit</b> can be created to follow up on this issue.  Click on the <b>'Returned debit'</b> button in the
        main side navigation on the left side of the page.  This will then lead to a page where all returned debits can be found in a list view.  These returned debits
        can be filtered on status, or even searched, to show only the required debit orders.  Click on the <b>'Add new debit order'</b> button in the top right to register
        a new returned debit order.
        <br /><br />
        On the new debit order page, simply fill in the required information and click on the <b>'Save returned debit</b> button to save the debit order.  Returned debit orders
        works in the same way that instructions do, meaning a multi step process.  After following up with clients on the status of a payment, the steps can then be followed
        to find out exactly where the process is.`,
        category: 3
      },


      {
        question: 'How do I add a new Administrator or Underwriter?',
        answer: `To add a new Administrator or Underwriter simple navigate to the <b>'Administrator'</b> or <b>'Underwriter'</b> buttons in the main navigation on the left
        side of the page.  These buttons will take you to the 'Administrators' or 'Underwriters page where previously added 'Administrators' or 'Underwriters' can be viewed.
        To add a new 'Administrator' or 'Underwriter' just click on the green 'Add -' button in the top right corner.
        <br /><br />
        Click on this link to see a video preview of how to add a policy:  <br />
        <a href="https://youtu.be/7UFrGccN3Pk target="_blank">https://youtu.be/7UFrGccN3Pk</a>`,
        category: 4
      }, {
        question: 'How do I add more than one email address to an Administrator or Underwriter?',
        answer: `When adding or editing an Administrator or Underwriter there is an <b>'Add another email address'</b> button just below the email address input box.  As many
        emails as needed can be added, just remember to select the type of email address that is being added.  For example, to be able to request quotes from and Administrator
        or Underwriter, a quote email address is needed.  Simply enter the email address and select 'Quote' in the email address type.  This will now register a quoting email
        on the Administrator or Underwriter.`,
        category: 4
      },

      {
        question: 'What kind of reports can I get on the system?',
        answer: `SureSpace tracks anything and everything.  To get to the reports page just click on the <b>'Reports'</b> button in the main navigation on the left side
        of the screen.  <b>Note: You have to have the correct permissions to view this page.</b>.  From here you will be able to see all reports that are available on SureSpace.
        <br /><br />
        Every time something is created, removed or edited, a note is saved.  Navigate to the '<b>Company notes</b>' to see everything that was done on the portal, and by whom.`,
        category: 5
      }
    ]

  }

  getFaq(noload?:string, question?:string){

    let searchQuery = {
      question: question
    }

    // this.surespaceService.getFaq(noload === 'noload', searchQuery).subscribe(
    //   (response:HttpResponse<FAQResponse>) => {
    //     this.faq = response.body.faq;
    //   },
    //   (error: HttpErrorResponse) => {
    //     this.alert.error(error);
    //   }
    // )
  }

  ngAfterViewInit() {
    this.getFaq();
  }

  ngOnInit() {
  }

}
