import { AuthenticationService } from './../../../../../../services/authentication.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { BrokerClientService } from 'src/app/services/broker-client.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'select-new-policy-vehicle-items',
  templateUrl: './select-new-policy-vehicle-items.component.html',
  styleUrls: ['./select-new-policy-vehicle-items.component.scss']
})
export class SelectNewPolicyVehicleItemsComponent implements OnInit {

  @Input() clientId:string;
  @Input() selection

  displayedColumns: string[] = ['select', 'make', 'model', 'year', 'registrationNumber'];
  policyItems = new MatTableDataSource<Vehicle>();
  // selection = new SelectionModel<Vehicle>(true, []);

  currentCompany: string = this.authenticationService.currentCompany.companyName;

  constructor(
    private brokerClientService: BrokerClientService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientVehicle()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.policyItems.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.policyItems.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getClientVehicle(){
    this.brokerClientService.getAllVehiclesForClient(this.clientId, this.currentCompany).subscribe(
      (response:HttpResponse<VehicleResponse>) => {
        this.policyItems = new MatTableDataSource<Vehicle>(response.body.vehicle);
      }
    )
  }

}
