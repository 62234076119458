import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const date = new Date(value)
    const now = new Date();

    //If same day
    if(date.getFullYear() === now.getFullYear() &&
       date.getMonth() === now.getMonth() &&
       date.getDate() === now.getDate()){
        return moment(date).fromNow();
    }

    //If less than a week
    if(date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() &&
       now.getDate() % date.getDate() <= 7 && now.getDate() % date.getDate() > 0){
        return moment(date).subtract(0, 'days').calendar().toLocaleLowerCase();
    }

    //if less than a year
    if(date.getFullYear() === now.getFullYear()){
      return moment(date).format('DD MMMM , kk:mm').replace(',', 'at');
    }

    //if more than a year
    if(date.getFullYear() !== now.getFullYear()){
      return moment(date).format('DD MMMM YYYY, , kk:mm').replace(' , ', ' at ').toLocaleLowerCase();
    }

  }

}
