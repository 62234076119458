import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-renew-session',
  templateUrl: './renew-session.component.html',
  styleUrls: ['./renew-session.component.scss']
})
export class RenewSessionComponent implements OnInit {

  timeRemaining: number;
  navigationSubscription;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private dialogRef: MatDialogRef<RenewSessionComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.timeRemaining = data.timeRemaining;
  }

  ngOnInit() {

    const countdown = interval(1000).subscribe(() => {
      this.timeRemaining--;

      if(this.timeRemaining < 1){
        this.endSession();      
        countdown.unsubscribe();
      }
    });

    

  }

  close(){
    this.dialogRef.close();
  }

  renewSession(){
    this.authService.renewSession().subscribe(
      (response:HttpResponse<any>) => {
        localStorage.setItem('SureSpace-userToken', response.body.token);
      }
    );
    this.router.navigate([this.router.url])
    this.close();
  }

  endSession(){
    this.close();
    this.router.navigate(['/logout']);
  }
}

