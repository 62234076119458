import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class Investment implements Basemodel {
  constructor(
	public _id: string,
	public policyNumber: string,
	public type: string,
	public investmentCompany?: string,
	public investedAmount?: string,
	public currentValue?: string,
	public brokerCompany?: string,
	public brokerCode?: string,
	public investmentDate?: Date,
	public calculationPeriod?: string,
	public payoutDate?: Date,
	public lastUpdated?: Date,
	public lastUpdatedBy?: string,
	public changes?: any,
	public userRef?: string
  ) {}
}

export class InvestmentResponse {
  constructor(
		public investment: Investment[],
		public count: number,
		public message: SuccessMessage[] 
  ) {}
}

