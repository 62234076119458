import { BrokerReportingClientsComponent } from './broker/reporting/clients/broker-reporting-clients.component';
import { BrokerReportingCalendarEventsComponent } from './broker/reporting/calendarEvents/broker-reporting-calendar-events.component';
import { BrokerReportingEmployeesComponent } from './broker/reporting/employees/broker-reporting-employees.component';
import { BrokerReportingReturnedDebitComponent } from './broker/reporting/returnedDebits/broker-reporting-returned-debit.component';
import { BrokerReportingQuotesComponent } from './broker/reporting/quotes/broker-reporting-quotes.component';
import { BrokerReportingPoliciesComponent } from './broker/reporting/policies/broker-reporting-policies.component';
import { BrokerReportingClaimsComponent } from './broker/reporting/claims/broker-reporting-claims.component';
import { IdNumberTesterComponent } from './_helpers/id-number-tester/id-number-tester.component';
import { BrokerReportingInstructionsComponent } from './broker/reporting/instructions/broker-reporting-instructions.component';
import { BrokerReportingComponent } from './broker/reporting/broker-reporting.component';
import { BrokerNotificationsComponent } from './broker/clients/notifications/broker-notifications.component';
import { BrokerCommercialClientsProductsEditComponent } from './broker/commercial/products/edit-products/broker-commercial-clients-products-edit.component';
import { BrokerCommercialClientsProductsNewComponent } from './broker/commercial/products/new-products/broker-commercial-clients-products-new.component';
import { BrokerCommercialClientsProductsComponent } from './broker/commercial/products/broker-commercial-clients-products.component';
import { BrokerCommercialClientsEditComponent } from './broker/commercial/edit-client/broker-commercial-clients-edit.component';
import { BrokerCommercialClientsNewComponent } from './broker/commercial/new-client/broker-commercial-clients-new.component';
import { BrokerCommercialClientsComponent } from './broker/commercial/broker-commercial-clients.component';
import { BrokerReturnedDebitEditDebitComponent } from './broker/returned-debits/edit-returned-debit/broker-returned-debit-edit-debit.component';
import { BrokerReturnedDebitNewDebitComponent } from './broker/returned-debits/new-returned-debit/broker-returned-debit-new-debit.component';
import { BrokerReturnedDebitsComponent } from './broker/returned-debits/broker-returned-debits.component';
import { BrokerAdminInstructionEditComponent } from './broker/admin-instructions/edit-instruction/broker-admin-instruction-edit.component';
import { BrokerAdminInstructionNewComponent } from './broker/admin-instructions/new-instruction/broker-admin-instruction-new.component';
import { BrokerClaimsEditClaimComponent } from './broker/claims/editClaim/broker-claims-edit-claim.component';
import { BrokerClaimsNewClaimComponent } from './broker/claims/newClaim/broker-claims-new-claim.component';
import { ApproveQuoteModalComponent } from './broker/quotes/approveQuote/approve-quote-modal.component';
import { BrokerSettingsComponent } from './broker/settings/broker-settings.component';
import { BrokerCompanySettingsComponent } from './broker/settings/companySettings/broker-company-settings.component';
import { BrokerNewEditClaimsComponent } from './broker/claims/newEditClaim/broker-new-edit-claims.component';
import { BrokerClaimsComponent } from './broker/claims/broker-claims.component';
import { BrokerNewEditAdminInstructionComponent } from './broker/admin-instructions/newEditInstruction/broker-new-edit-admin-instruction.component';
import { BrokerAdminInstructionComponent } from './broker/admin-instructions/broker-admin-instruction.component';
import { BrokerNewEditUnderwriterComponent } from './broker/underwriters/newEditUnderwriter/broker-new-edit-underwriter.component';
import { BrokerNewEditAdministratorComponent } from './broker/administrators/newEditAdministrator/broker-new-edit-administrator.component';
import { BrokerUnderwritersComponent } from './broker/underwriters/broker-underwriters.component';
import { BrokerAdministratorsComponent } from './broker/administrators/broker-administrators.component';
import { BrokerNewEditClientPoliciesComponent } from './broker/clients/policies/NewEditPolicies/broker-new-edit-client-policies.component';
import { BrokerNewEditClientFamilyMemberComponent } from './broker/clients/familyMembers/NewEditFamilyMember/broker-new-edit-client-family-member.component';
import { BrokerNewEditClientVehicleComponent } from './broker/short-term-insurance/vehicle/broker-new-edit-client-vehicle.component';
import { BrokerClientPoliciesComponent } from './broker/clients/policies/broker-client-policies.component';
import { BrokerActivityLogComponent } from './broker/reporting/activityLog/broker-activity-log.component';
import { BrokerEmployeeInformationComponent } from './broker/employees/view-broker/broker-employee-information.component';
import { BrokerEditVehicleComponent } from './broker/short-term-insurance/vehicle/edit-vehicle/broker-edit-vehicle.component';
import { ClientSettingsComponent } from './client/settings/client-settings.component';
import { ForgotPasswordCreateNewComponent } from './forgot-password/new/forgot-password-create-new.component';
import { ClientNewStructureComponent } from './client/insurance/structureInsurance/new/client-new-structure.component';
import { ClientEditStructureComponent } from './client/insurance/structureInsurance/edit/client-edit-structure.component';
import { ClientEditPersonalItemComponent } from './client/insurance/personalInsurance/edit/client-edit-personal-item.component';
import { ClientNewPersonalItemComponent } from './client/insurance/personalInsurance/new/client-new-personal-item.component';
import { ClientEditVehicleComponent } from './client/insurance/vehicleInsurance/edit/client-edit-vehicle.component';
import { ClientNewVehicleComponent } from './client/insurance/vehicleInsurance/new/client-new-vehicle.component';
import { ClientStructureInsuranceComponent } from './client/insurance/structureInsurance/client-structure-insurance.component';
import { ClientVehicleInsuranceComponent } from './client/insurance/vehicleInsurance/client-vehicle-insurance.component';
import { ClientPersonalInsuranceComponent } from './client/insurance/personalInsurance/client-personal-insurance.component';
import { ClientNewHouseholdInsuranceComponent } from './client/insurance/householdInsurance/new/client-new-household-insurance.component';
import { ClientEditHouseholdInsuranceItemComponent } from './client/insurance/householdInsurance/household-items/edit/client-edit-household-insurance-item.component';
import { ClientNewHouseholdInsuranceItemComponent } from './client/insurance/householdInsurance/household-items/new/client-new-household-insurance-item.component';
import { ClientEditHouseholdInsuranceComponent } from './client/insurance/householdInsurance/edit/client-edit-household-insurance.component';
import { ClientEditFamilyMemberComponent } from './client/about/information/family/edit-member/client-edit-family-member.component';
import { CardsComponent } from './design/custom/cards/cards.component';
import { NewClientInvestmentComponent } from './broker/clients/modules/investments/new-investment/new-client-investment.component';
import { EditClientInvestmentComponent } from './broker/clients/modules/investments/edit-investment/edit-client-investment.component';
import { ClientInvestmentsComponent } from './broker/clients/modules/investments/client-investments.component';
import { EditBrokerClientComponent } from './broker/clients/edit-client/edit-client.component';
import { NewBrokerClientComponent } from './broker/clients/new-client/new-client.component';
import { BrokerEmployeesComponent } from './broker/employees/broker-employees.component';
import { BootstrapTabsComponent } from './design/base/tabs/bootstrap-tabs/bootstrap-tabs.component';
import { DropdownComponent } from './design/base/dropdown/dropdown.component';
import { ButtonGroupComponent } from './design/base/button-group/button-group.component';
import { ButtonsComponent } from './design/base/buttons/buttons.component';
import { ViewClientInvestmentsComponent } from './client/investments/view-client-investments/view-client-investments.component';
import { ClientHouseholdInsuranceComponent } from './client/insurance/householdInsurance/client-household-insurance.component';
import { ClientNewBrokerComponent } from './client/brokers/new-broker/client-new-broker.component';
import { ClientAddNewBrokerComponent } from './client/brokers/add-broker/client-add-new-broker.component';
import { ClientViewBrokerComponent } from './client/brokers/view-brokers/client-view-broker.component';
import { ClientFamilyComponent } from './client/about/information/client-family.component';
import { ClientInformationComponent } from './client/about/information/client-information.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ActivateComponent } from './activate/activate.component';
import { RegisterComponent } from './register/register.component';
import { RegisterBrokerCompanyComponent } from './register/broker-company/broker-company.component';
import { LogoutComponent } from './logout/logout.component';
import { AboutComponent } from './about/about.component';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { FaqComponent } from './faq/faq.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrokerDashboardComponent } from './dashboard/broker-dashboard/broker-dashboard.component';
import { BulletinBoardComponent } from './bulletin-board/bulletin-board.component';
import { InvestmentsComponent } from './investments/investments.component';
import { PoliciesComponent } from './policies/policies.component';

import { AuthGuardService, AnonymousGuardService, BrokerGuardService, AdminGuardService, UserGuardService } from './services/auth-guard.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CancelAccountComponent } from './account/cancel-account/cancel-account.component';
import { ManagementComponent } from './management/management.component';
import { ManagementUsersComponent } from './management/users/management-users.component';
import { ManagementConsoleComponent } from './management/console/management-console.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ClientDashboardComponent } from './client/dashboard/client-dashboard.component';
import { PricingComponent } from './pricing/pricing.component';
import { ColorsComponent } from './design/base/colors/colors.component';
import { TypographyComponent } from './design/base/typography/typography.component';
import { TablesComponent } from './design/base/tables/tables.component';
import { ModalComponent } from './design/base/modal/modal.component';
import { MiscellaneousComponent } from './design/custom/miscellaneous/miscellaneous.component';
import { BrokerClientsComponent } from './broker/clients/broker-clients.component';
import { ClientNewFamilyMemberComponent } from './client/about/information/family/new-member/client-new-family-member.component';
import { TimelineComponent } from './design/custom/timeline/timeline.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ManagementFAQComponent } from './management/management-faq/management-faq.component';
import { BrokerNewEditClientHomeownerInsuranceComponent } from './broker/short-term-insurance/homeOwners/broker-new-edit-client-homeowner-insurance.component';
import { BrokerNewEditClientHomeContentInsuranceComponent } from './broker/short-term-insurance/home-content/broker-new-edit-client-home-content-insurance.component';
import { BrokerNewEditClientAllRiskInsuranceComponent } from './broker/short-term-insurance/all-risk/broker-new-edit-client-all-risk-insurance.component';
import { BrokerNewEditClientCaranvansAndTrailersComponent } from './broker/short-term-insurance/trailersAndCaravans/broker-new-edit-client-caranvans-and-trailers.component';
import { BrokerQuotesComponent } from './broker/quotes/broker-quotes.component';
import { BrokerNewEditQuotesComponent } from './broker/quotes/newEditQuotes/broker-new-edit-quotes.component';
import { BrokerCalendarComponent } from './broker/calendar/broker-calendar.component';
import { BrokerNewEditClientValueAddedProductsComponent } from './broker/short-term-insurance/value-added-products/broker-new-edit-client-value-added-products/broker-new-edit-client-value-added-products.component';
import { BrokerCompanyDocumentsComponent } from './broker/settings/companyDocuments/broker-company-documents.component';

export const AppRoutes: Routes = [
    {
      path: '404',
      component: NotFoundComponent
    },
    {
      path: 'style-guide',
      component: StyleGuideComponent
    },
    {
      path: 'helpers',
      children: [
        {
          path: 'idNumberTester',
          component: IdNumberTesterComponent
        }
      ]
    },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    },
    {
      path: 'login',
      component: LoginComponent,
      canActivate: [AnonymousGuardService]
    },
    {
      path: 'activate/:token',
      component: ActivateComponent,
      canActivate: [AnonymousGuardService]
    },
    {
      path: 'logout',
      component: LogoutComponent
    },
    {
      path: 'register/:type',
      canActivate: [AnonymousGuardService],
      component: RegisterComponent,
      // children: [
      //   {
      //     path: '',
      //     component: RegisterComponent,
      //   },
      //   {
      //     path: 'broker-company',
      //     component: RegisterBrokerCompanyComponent
      //   },
      // ]
    },
    {
      path: 'about',
      component: AboutComponent
    },
    {
      path: 'faq',
      component: FaqComponent
    },
    {
      path: 'suggestions',
      component: SuggestionsComponent
    },
    {
      path: 'reportIssue',
      component: ReportIssueComponent
    },
    {
      path: 'termsAndConditions',
      component: TermsAndConditionsComponent
    },
    {
      path: 'forgot-password',
      children: [{
        path: '',
        component: ForgotPasswordComponent
      }, {
        path: 'new',
        component: ForgotPasswordCreateNewComponent
      }]
    },
    {
      path: 'u',
      canActivate: [AuthGuardService, UserGuardService],
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: 'dashboard',
          component: ClientDashboardComponent,
        },
        {
          path: 'me',
          children: [
            {
              path: 'my-information',
              component: ClientInformationComponent
            }, {
              path: 'my-family',
              children: [
                {
                  path: '',
                  component: ClientFamilyComponent
                }, {
                  path: 'new-member',
                  component: ClientNewFamilyMemberComponent
                }, {
                  path: 'edit-member',
                  component: ClientEditFamilyMemberComponent
                }
              ]
            }
          ]
        }, {
          path: 'my-brokers',
          canActivate: [AuthGuardService, UserGuardService],
          runGuardsAndResolvers: 'always',
          children: [
            {
              path: '',
              component: ClientViewBrokerComponent,
            }, {
              path: 'add-broker',
              component: ClientAddNewBrokerComponent
            }, {
              path: 'new-broker',
              component: ClientNewBrokerComponent
            }
          ]
        }, {
          path: 'short-term-insurance',
          canActivate: [AuthGuardService, UserGuardService],
          runGuardsAndResolvers: 'always',
          children: [
            {
              path: 'household',
              children: [{
                path: '',
                component: ClientHouseholdInsuranceComponent
              }, {
                path: 'add',
                component: ClientNewHouseholdInsuranceComponent
              }, {
                path: 'edit',
                component: ClientEditHouseholdInsuranceComponent
              }, {
                path: 'view-item',
                component: ClientEditHouseholdInsuranceItemComponent
              }, {
                path: 'add-item',
                component: ClientNewHouseholdInsuranceItemComponent
              }, {
                path: 'edit-item',
                component: ClientEditHouseholdInsuranceItemComponent
              }]
            }, {
              path: 'personal-item',
              children: [{
                path: '',
                component: ClientPersonalInsuranceComponent
              }, {
                path: 'new',
                component: ClientNewPersonalItemComponent
              }, {
                path: 'edit',
                component: ClientEditPersonalItemComponent
              }]
            }, {
              path: 'vehicle',
              children: [{
                path: '',
                component: ClientVehicleInsuranceComponent
              }, {
                path: 'new',
                component: ClientNewVehicleComponent
              }, {
                path: 'edit',
                component: ClientEditVehicleComponent
              }]
            }, {
              path: 'structure',
              children: [{
                path: '',
                component: ClientStructureInsuranceComponent
              }, {
                path: 'new',
                component: ClientNewStructureComponent
              }, {
                path: 'edit',
                component: ClientEditStructureComponent
              }]
            }, {
              path: 'settings',
              component: ClientSettingsComponent
            }
          ]
        }, {
          path: 'investments',
          component: ViewClientInvestmentsComponent
        }, {
          path: 'notifications',
          component: NotificationsComponent
        }
      ]
    },
    {
      path: 'b',
      canActivate: [AuthGuardService, BrokerGuardService],
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: 'dashboard',
          component: BrokerDashboardComponent,
          pathMatch: 'full'
        }, {
          path: 'calendar',
          component: BrokerCalendarComponent,
          pathMatch: 'full'
        }, {
          path: 'settings',
          component: BrokerSettingsComponent
        }, {
          path: 'companySettings',
          component: BrokerCompanySettingsComponent
        }, {
          path: 'companyDocuments',
          component: BrokerCompanyDocumentsComponent
        }, {
          path: 'administrators',
          children: [{
            path: '',
            component: BrokerAdministratorsComponent,
          }, {
            path: 'new',
            component: BrokerNewEditAdministratorComponent
          }, {
            path: 'view/:id',
            component: BrokerNewEditAdministratorComponent
          }, {
            path: 'edit/:id',
            component: BrokerNewEditAdministratorComponent
          }]
        }, {
          path: 'underwriters',
          children: [{
            path: '',
            component: BrokerUnderwritersComponent,
          }, {
            path: 'new',
            component: BrokerNewEditUnderwriterComponent
          }, {
            path: 'edit/:id',
            component: BrokerNewEditUnderwriterComponent
          }, {
            path: 'view/:id',
            component: BrokerNewEditUnderwriterComponent
          }]
        },
        // {
        //   path: 'clients',
        //   redirectTo: '/b/clients/personal',
        //   pathMatch: 'full',
        // },
        {
          path: 'clients',
          children: [
            {
              path: 'personal',
              children: [
                {
                  path: '',
                  component: BrokerClientsComponent,
                  pathMatch: 'full'
                }, {
                  path: 'new',
                  component: NewBrokerClientComponent,
                  pathMatch: 'full'
                }, {
                  path: 'edit/:id',
                  children: [
                    {
                      path: '',
                      component: EditBrokerClientComponent,
                    }, {
                      path: 'investments',
                      children: [
                        {
                          path: 'edit',
                          component: EditClientInvestmentComponent
                        }, {
                          path: 'new',
                          component: NewClientInvestmentComponent
                        }
                      ]
                    }, {
                      path: 'short-term-insurance',
                      canActivate: [AuthGuardService],
                      runGuardsAndResolvers: 'always',
                      children: [{
                        path: 'vehicle',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientVehicleComponent
                        }, {
                          path: 'edit/:vehicleId',
                          component: BrokerNewEditClientVehicleComponent
                        }]
                      }, {
                        path: 'structure',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientHomeownerInsuranceComponent
                        }, {
                          path: 'edit/:structureId',
                          component: BrokerNewEditClientHomeownerInsuranceComponent
                        }]
                      }, {
                        path: 'homeContent',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientHomeContentInsuranceComponent
                        }, {
                          path: 'edit/:homeContentId',
                          component: BrokerNewEditClientHomeContentInsuranceComponent
                        }]
                      }, {
                        path: 'allRisk',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientAllRiskInsuranceComponent
                        }, {
                          path: 'edit/:itemId',
                          component: BrokerNewEditClientAllRiskInsuranceComponent
                        }]
                      }, {
                        path: 'trailersAndCaravans',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientCaranvansAndTrailersComponent
                        }, {
                          path: 'edit/:productId',
                          component: BrokerNewEditClientCaranvansAndTrailersComponent
                        }]
                      }, {
                        path: 'valueAddedProducts',
                        children: [{
                          path: 'new',
                          component: BrokerNewEditClientValueAddedProductsComponent
                        }, {
                          path: 'edit/:productId',
                          component: BrokerNewEditClientValueAddedProductsComponent
                        }]
                      }]
                    }, {
                      path: 'family-members',
                      canActivate: [AuthGuardService],
                      runGuardsAndResolvers: 'always',
                      children: [
                        {
                          path: '',
                          component: BrokerNewEditClientFamilyMemberComponent,
                        }, {
                          path: 'new',
                          component: BrokerNewEditClientFamilyMemberComponent,
                        }, {
                          path: 'edit/:familyMemberId',
                          component: BrokerNewEditClientFamilyMemberComponent,
                        }
                      ]
                    },
                  ]
                },
              ]
            }, {
              path: 'commercial',
              children: [
                {
                  path: '',
                  component: BrokerCommercialClientsComponent
                },{
                  path: 'new',
                  component: BrokerCommercialClientsNewComponent
                }, {
                  path: 'edit/:clientId',
                  children: [
                    {
                      path: '',
                      component: BrokerCommercialClientsEditComponent
                    },
                    {
                      path: 'short-term-insurance',
                      children: [{
                        path: 'commercialProduct',
                        children: [{
                          path: '',
                          component: BrokerCommercialClientsProductsComponent
                        },{
                          path: 'new',
                          component: BrokerCommercialClientsProductsNewComponent
                        }, {
                          path: 'edit/:productId',
                          component: BrokerCommercialClientsProductsNewComponent
                        }]
                      }]
                    }
                  ]
                  
                }
              ]
            }, {
              path: 'policies',
              canActivate: [AuthGuardService],
              runGuardsAndResolvers: 'always',
              children: [{
                path: '',
                component: BrokerClientPoliciesComponent,
              },{
                path: 'new',
                component: BrokerNewEditClientPoliciesComponent
              }, {
                path: 'edit/:policyId',
                component: BrokerNewEditClientPoliciesComponent
              }, {
                path: 'view/:policyId',
                component: BrokerNewEditClientPoliciesComponent
              }]
            }
          ]
        }, {
          path: 'employees',
          children: [
            {
              path: '',
              component: BrokerEmployeesComponent
            }, {
              path: 'view',
              component: BrokerEmployeeInformationComponent
            }, {
              path: 'new',
              component: BrokerEmployeeInformationComponent
            }, {
              path: 'edit',
              component: BrokerEmployeeInformationComponent
            }
          ]
        }, {
          path: 'instructions',
          children: [
            {
              path: '',
              component: BrokerAdminInstructionComponent
            }, {
              path: 'new',
              component: BrokerAdminInstructionNewComponent
            }, {
              path: 'edit/:instructionId',
              component: BrokerAdminInstructionEditComponent,
            }
          ]
        }, {
          path: 'claims',
          children: [
            {
              path: '',
              component: BrokerClaimsComponent
            }, {
              path: 'new',
              component: BrokerClaimsNewClaimComponent
            }, {
              path: 'edit/:claimId',
              component: BrokerClaimsEditClaimComponent
            }
          ]
        }, {
          path: 'quotes',
          children: [
            {
              path: '',
              component: BrokerQuotesComponent
            }, {
              path: 'new',
              component: BrokerNewEditQuotesComponent
            }, {
              path: 'edit/:id',
              component: BrokerNewEditQuotesComponent
            }, {
              path: 'view/:id',
              component: BrokerNewEditQuotesComponent
            }, {
              path: 'approve/:id',
              component: ApproveQuoteModalComponent
            }
          ]
        }, {
          path: 'returned-debits',
          children: [
            {
              path: '',
              component: BrokerReturnedDebitsComponent
            }, {
              path: 'new',
              component: BrokerReturnedDebitNewDebitComponent
            }, {
              path: 'edit/:returnedDebitId',
              component: BrokerReturnedDebitEditDebitComponent
            }
          ]
        }, {
          path: 'reporting',
          children: [
            {
              path: '',
              component: BrokerReportingComponent
            }, {
              path: 'activity-log',
              component: BrokerActivityLogComponent
            }, {
              path: 'instructions',
              component: BrokerReportingInstructionsComponent
            }, {
              path: 'claims',
              component: BrokerReportingClaimsComponent
            }, {
              path: 'policies',
              component: BrokerReportingPoliciesComponent
            }, {
              path: 'quotes',
              component: BrokerReportingQuotesComponent
            }, {
              path: 'returnedDebits',
              component: BrokerReportingReturnedDebitComponent
            }, {
              path: 'employees',
              component: BrokerReportingEmployeesComponent
            }, {
              path: 'calendar-events',
              component: BrokerReportingCalendarEventsComponent
            }, {
              path: 'clients',
              component: BrokerReportingClientsComponent
            }
          ]
        }, 
        {
          path: 'products',
          children: [
            {
              path: 'short-term-insurance',
              canActivate: [AuthGuardService],
              runGuardsAndResolvers: 'always',
              children: [{
                path: 'vehicle/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientVehicleComponent
                }, {
                  path: 'edit/:vehicleId',
                  component: BrokerNewEditClientVehicleComponent
                }]
              }, {
                path: 'structure/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientHomeownerInsuranceComponent
                }, {
                  path: 'edit/:structureId',
                  component: BrokerNewEditClientHomeownerInsuranceComponent
                }]
              }, {
                path: 'homeContent/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientHomeContentInsuranceComponent
                }, {
                  path: 'edit/:homeContentId',
                  component: BrokerNewEditClientHomeContentInsuranceComponent
                }]
              }, {
                path: 'allRisk/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientAllRiskInsuranceComponent
                }, {
                  path: 'edit/:itemId',
                  component: BrokerNewEditClientAllRiskInsuranceComponent
                }]
              }, {
                path: 'trailersAndCaravans/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientCaranvansAndTrailersComponent
                }, {
                  path: 'edit/:productId',
                  component: BrokerNewEditClientCaranvansAndTrailersComponent
                }]
              }, {
                path: 'valueAddedProducts/:clientId',
                children: [{
                  path: 'new',
                  component: BrokerNewEditClientValueAddedProductsComponent
                }, {
                  path: 'edit/:productId',
                  component: BrokerNewEditClientValueAddedProductsComponent
                }]
              }]
            }
          ]
        }, {
          path: 'notifications',
          component: BrokerNotificationsComponent
        }
      ]
    },
    {
      path: 'management',
      canActivate: [AdminGuardService],
      runGuardsAndResolvers: 'always',
      component: ManagementComponent,
      children: [
        // {
        //   path: '',
        //   component: ManagementComponent,
        // },
        {
          path: 'console',
          component: ManagementConsoleComponent,
        }, {
          path: 'users',
          component: ManagementUsersComponent,
        }, {
          path: 'faq',
          component: ManagementFAQComponent
        },
      ]
    }, {
      path: 'bulletinBoard',
      component: BulletinBoardComponent,
      canActivate: [AuthGuardService],
      runGuardsAndResolvers: 'always'
    }, {
      path: 'policies',
      component: PoliciesComponent,
      canActivate: [AuthGuardService],
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'investmentss',
      component: InvestmentsComponent,
      canActivate: [AuthGuardService],
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'settings',
      canActivate: [AuthGuardService],
      runGuardsAndResolvers: 'always',
      children: [
        {
          path: 'reset-password',
        component: ResetPasswordComponent
        },
        {
          path: 'cancel-account',
          component: CancelAccountComponent
        }
      ]
    }, {
      path: 'pricing',
      component: PricingComponent
    }, {
      path: 'design',
      // canActivate: [AdminGuardService],
      children: [
        {
          path: 'base',
          children: [
            {
              path: 'colors',
              component: ColorsComponent
            }, {
              path: 'typography',
              component: TypographyComponent
            }, {
              path: 'buttons',
              component: ButtonsComponent
            }, {
              path: 'button-group',
              component: ButtonGroupComponent
            }, {
              path: 'dropdown',
              component: DropdownComponent
            }, {
              path: 'tabs',
              children: [{
                path: 'bootstrap-tabs',
                component: BootstrapTabsComponent
              }]
            }, {
              path: 'tables',
              component: TablesComponent
            }, {
              path: 'modal',
              component: ModalComponent
            },
          ]
        },
        {
          path: 'custom',
          children: [
            {
              path: 'miscellaneous',
              component: MiscellaneousComponent
            }, {
              path: 'timeline',
              component: TimelineComponent
            }, {
              path: 'cards',
              component: CardsComponent
            }
          ]
        }
      ]
    }
  ];
