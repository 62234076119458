<div id="content" class="row">

        <div class="secondary-nav">
          <div class="subnav-background"></div>
          <div class="row h-100">
            <div class="col-12 v-align">
              <h5 class="d-inline-block">{{ editPolicy ? 'Edit' : 'Add new' }} policy</h5>
              <ng-container *ngIf="editPolicy">
                <div class="separator"></div>
                <!-- <h6 class="d-inline-block">{{ policyFormGroup.get('policyHolder').value?.firstName }} {{ policyFormGroup.get('policyHolder').value?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" [routerLink]="['/b/clients/personal/edit', policyFormGroup.get('policyHolder').value?._id]" [queryParams]="{file: '5'}"><img src="assets/icons/goto.svg" /></i></h6> -->
                <h6 class="d-inline-block">{{ policyFormGroup.get('policyHolder').value?.firstName }} {{ policyFormGroup.get('policyHolder').value?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" (click)="goToClientFile()"><img src="assets/icons/goto.svg" /></i></h6>
              </ng-container>
            </div>
          </div>  
        </div>
      
        <div class="col-12">
      
          <div class="panel panel-primary">
            <form id="policyFormGroup" [formGroup]="policyFormGroup">
      
              <div class="panel-heading">
                <h5>Policy details</h5>
      
                <div class="my-auto">
      
                  <button type="button" matTooltip="Go back to view all policies" class="btn btn-secondary my-auto" (click)="goBack()">
                    <i class="fi flaticon-back"></i>Back
                  </button>
      
                  <div class="btn-group" ngbDropdown *ngIf="!viewPolicy && canViewItem">
                    <button type="submit" class="btn btn-primary" mat-tooltip="Save policy and go back." (click)="editPolicy ? updatePolicy('back') : addNewPolicy('back')">
                      Save policy
                    </button>
                    <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      aria-controls="dropdown-basic">
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="editPolicy ? updatePolicy('continue') : addNewPolicy('continue')">Save &amp; continue</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editPolicy ? updatePolicy('new') : addNewPolicy('new')">Save &amp; add new</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editPolicy ? updatePolicy('back') : addNewPolicy('back')">Save &amp; go back</a></li>
                    </ul>
                  </div> <!-- Close div for .btn-group -->
      
                </div> <!-- Close div for .my-auto -->
      
              </div>
      
              <div class="panel-body">
                  
                    <div class="row">

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field>
                                    <input
                                      matInput 
                                      type="text"
                                      aria-label="Policy holder"
                                      placeholder="Policy holder"
                                      formControlName='policyHolder'
                                      [matAutocomplete]="auto"
                                    />
                                    <mat-hint [hidden]="policyForm['policyHolder'].dirty">Type a name to start the search</mat-hint>

                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="mapClient($event)">
                                        <mat-option *ngIf="isLoading" class="is-loading">Finding clients...</mat-option>

                                        <ng-container *ngIf="!isLoading">
                                            <mat-option disabled *ngIf="clients?.length === 0 && policyFormGroup.get('policyHolder').value">
                                                <span>No client found</span>
                                            </mat-option>
                                            <mat-option *ngFor="let client of clients" [value]="client">
                                                <span>{{ client?.firstName }} {{ client?.lastName }}{{client?.companyName }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
    
                                    <mat-error>
                                        Please select a <strong>policy holder</strong> for this policy.
                                    </mat-error>
    
                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>
    
                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        [ngClass]="{ 'is-invalid': formSubmitted && policyForm['policyNumber'].errors }"
                                        placeholder="Policy number"
                                        name="policyNumber"
                                        formControlName="policyNumber"
                                    />
    
                                    <mat-error>
                                        Please enter a <strong>policy number</strong>.
                                    </mat-error>
    
                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>
        
                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="policyStartDate"
                                    [matDatepicker]="policyStartDate"
                                    name="policyStartDate"
                                    formControlName="policyStartDate"
                                    placeholder="Policy start date"
                                >
                                <mat-datepicker-toggle matSuffix [for]="policyStartDate"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #policyStartDate></mat-datepicker>
        
                                <mat-error>
                                    Please provide the <strong>policy inception date</strong>.
                                </mat-error>
        
                            </mat-form-field>
                        </div>
    
                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field>
                                    <mat-select placeholder="Administrator" name="administrator" formControlName="administrator">
                                      <mat-option *ngIf="administrators?.length === 0">No administrators found</mat-option>
                                      <mat-option *ngFor="let administrator of administrators" [value]="administrator._id">{{ administrator.name }}</mat-option>
                                    </mat-select>
    
                                    <mat-error>
                                        Please provide an <strong>administrator</strong> for this policy.
                                    </mat-error>
    
                                </mat-form-field>   
                            </div> <!-- Close div for .input-group -->
                        </div>
    
                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field>
                                    <mat-select placeholder="Underwriter" name="underwriter" formControlName="underwriter">
                                      <mat-option *ngIf="underwriters?.length === 0">No underwriters found</mat-option>
                                      <mat-option *ngFor="let underwriter of underwriters" [value]="underwriter._id">{{ underwriter.name }}</mat-option>
                                    </mat-select>
    
                                    <mat-error>
                                        Please provide an <strong>underwriter</strong> for this policy.
                                    </mat-error>
    
                                </mat-form-field>   
                            </div> <!-- Close div for .input-group -->
                        </div>  
                        
                        <div class="col-sm-6 col-12 mb-3">
                          <div class="input-group">
                              <mat-form-field>
                                  <input
                                    matInput 
                                    type="text"
                                    aria-label="Co Insured"
                                    placeholder="Co Insured"
                                    formControlName='coInsured'
                                    [disabled]="!policyFormGroup.get('policyHolder').value || policyFormGroup.get('policyHolder').value == ''"
                                    [matAutocomplete]="coInsuredAuto"
                                  />
                                  <mat-hint [hidden]="policyForm['coInsured'].dirty">Type a name to start the search</mat-hint>

                                  <mat-autocomplete autoActiveFirstOption #coInsuredAuto="matAutocomplete" [displayWith]="displayFamily.bind(this)">
                                      <mat-option *ngIf="isLoading" class="is-loading">Finding family members...</mat-option>

                                      <ng-container *ngIf="!isLoading">
                                          <mat-option disabled *ngIf="familyMembers?.length === 0 && policyFormGroup.get('coInsured').value">
                                            <span>No family members found</span>
                                          </mat-option>
                                          <mat-option [value]="null">
                                            <span>None</span>
                                          </mat-option>
                                          <mat-option *ngFor="let member of familyMembers" [value]="member">
                                              <span>{{ member?.name }} {{ member?.surname }}</span>
                                          </mat-option>
                                      </ng-container>
                                  </mat-autocomplete>
  
                                  <mat-error>
                                      Please select a <strong>policy holder</strong> for this policy.
                                  </mat-error>
  
                              </mat-form-field>
                          </div> <!-- Close div for .input-group -->
                      </div>
        
                    </div> <!-- Close div for .row -->
              </div> <!-- Close div for .panel-body -->
      
            </form>
          </div> <!-- Close div for .panel -->

          <div class="panel panel-primary" *ngIf="!editPolicy && clientId">
      
            <div class="panel-heading">
                <h5>Policy items</h5>
            </div>
      
            <div class="panel-body">

              <p>Select products that you would like to add to this policy.</p>
        
              <mat-tab-group mat-align-tabs="start">
                <mat-tab label="Vehicles"><select-new-policy-vehicle-items [selection]="selectedPolicyItems" [clientId]="clientId"></select-new-policy-vehicle-items></mat-tab>
                <mat-tab label="Home buildings"><select-new-policy-item-building-component [selection]="selectedPolicyItems" [clientId]="clientId" [companyName]="brokerComponent.brokerCompany.companyName"></select-new-policy-item-building-component></mat-tab>
                <mat-tab label="Home content"><select-new-policy-household-content-items [selection]="selectedPolicyItems" [clientId]="clientId" [companyName]="brokerComponent.brokerCompany.companyName"></select-new-policy-household-content-items></mat-tab>
                <mat-tab label="All risk items"><select-new-policy-all-risk-items [selection]="selectedPolicyItems" [clientId]="clientId" [companyName]="brokerComponent.brokerCompany.companyName"></select-new-policy-all-risk-items></mat-tab>
                <mat-tab label="Trailers and caravans"><select-new-policy-items-trailers-and-caravans [selection]="selectedPolicyItems" [clientId]="clientId" [companyName]="brokerComponent.brokerCompany.companyName"></select-new-policy-items-trailers-and-caravans></mat-tab>
                <mat-tab label="Value added products"><select-new-policy-items-value-added-prodducts [selection]="selectedPolicyItems" [clientId]="clientId" [companyName]="brokerComponent.brokerCompany.companyName"></select-new-policy-items-value-added-prodducts></mat-tab>
              </mat-tab-group>

            </div> <!-- Close div for .panel-body -->
      
        </div> <!-- Close div for .panel -->

          <div class="panel panel-primary" *ngIf="editPolicy">
      
                <div class="panel-heading">
                    <h5>Policy items</h5>
                </div>
          
                <div class="panel-body">
      
                  <!-- <div class="mb-5 col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                      <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="userSearch" (ngModelChange)="findAllPolicies($event, true)"/>
                      <mat-hint>
                        Search in all fields.
                      </mat-hint>
                    </mat-form-field>
                  </div> -->
            
                  <table mat-table [dataSource]="policyItems" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
            
                    <!-- Item Column -->
                    <ng-container matColumnDef="itemType">
                      <th mat-header-cell *matHeaderCellDef>Item type</th>
                      <td mat-cell *matCellDef="let row" data-label="Item">{{row?.itemType | sentenceCase }}</td>
                    </ng-container>

                    <!-- Item Column -->
                    <ng-container matColumnDef="field1">
                      <th mat-header-cell *matHeaderCellDef>Item</th>
                      <td mat-cell *matCellDef="let row" data-label="Item">{{row?.itemField1}}</td>
                    </ng-container>

                    <!-- Item Column -->
                    <ng-container matColumnDef="field2">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let row" data-label="">{{row?.itemField2}}</td>
                    </ng-container>

                    <!-- Item Column -->
                    <ng-container matColumnDef="field3">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let row" data-label="">{{row?.itemField3}}</td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  </table>

                  <p *ngIf="!policyItems.length" class="text-center mt-3">No items available in this policy</p>
                
                  <mat-paginator [hidden]="!policyItems.length" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>                       

                </div> <!-- Close div for .panel-body -->
          
            </div> <!-- Close div for .panel -->
      
        </div> <!-- Close div for .col-12 -->
      
      </div> <!-- Close div for #content -->
      