import { BrokerService } from './../../services/broker.service';
import { BrokerClaim } from 'src/app/_shared/models/brokerClaims.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerClaimsService } from 'src/app/services/broker-claims.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-claims',
  templateUrl: './broker-claims.component.html',
  styleUrls: ['./broker-claims.component.scss']
})
export class BrokerClaimsComponent implements OnInit {

  claims: BrokerClaim[];
  
  displayedColumns: string[] = ['clientName', 'clientNumber', 'referenceNumber', 'claimType', 'currentStep', 'status', 'dateApproved', 'view'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  companyEmployees: any;
  userToGetClaims: string = this.authenticationService.currentUserValue._id;

  claimSearch:string = '';
  claimSearchStatus: string = 'pending';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private brokerComponent: BrokerComponent,
    private brokerClaimsService: BrokerClaimsService,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.findAllClaims();
    this.getAllCompanyEmployees();
  }

  selectFinalisedFilter(){
    this.claimSearchStatus = 'finalised';
    this.userToGetClaims = 'allCompanyEmployees';
    this.findAllClaims()
  }

  findAllClaims(searchParam:string = '', noLoad:boolean = false, completed = this.claimSearchStatus){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam || '',
      completed: completed,
      user: this.userToGetClaims
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.brokerClaimsService.getAllClaims(this.brokerComponent.brokerCompany.companyName, req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.claim;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.claims = data);
  }

  getAllCompanyEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.companyEmployees = response.body.user.filter(e => e._id !== this.currentUser._id);
    })
  }

  isAdmin(){
    return this.currentUserPermissions.includes('brokerDirector') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager');
  }

}
