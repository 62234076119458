import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'style-guide-input',
  templateUrl: './style-guide-input.component.html',
  styleUrls: ['./style-guide-input.component.scss']
})
export class StyleGuideInputComponent implements OnInit {

  select: string;

  constructor() { }

  ngOnInit() {
  }

}
