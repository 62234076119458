import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Employee, EmployeeResponse } from './../../../_shared/models/employeeModel';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from './../../../services/broker.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-broker-reporting-instructions',
  templateUrl: './broker-reporting-instructions.component.html',
  styleUrls: ['./broker-reporting-instructions.component.scss']
})
export class BrokerReportingInstructionsComponent implements OnInit {

  instructionFilters: FormGroup;

  instructionTotals: any;
  employeesInstructions: any;

  employees: Employee[];
  filteredEmployees: Observable<Employee[]>;

  displayedColumns: string[] = ['employee', 'totalInstructions', 'completedInstructions', 'mostPopularProduct', 'mostPopularType', 'avgResolutionTime'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService
  ) {

    this.instructionFilters = this.formBuilder.group({
      timePeriod: [""],
      dateFrom: [""],
      dateTo: [""],
      productType: [""],
      instructionType: [""],
      status: [""],
      employee: [""],
      employeeId: [""]
    })

  }

  ngOnInit(): void {
    this.getEmployees();
    this.getInstructionReport();
  }

  getInstructionReport(){
    this.reportingService.getInstructionReports(this.authService.currentCompany.companyName, this.instructionFilters.value).subscribe(response => {

      console.log('response => ', response.body)
      this.instructionTotals = response.body.totals;
      this.employeesInstructions = response.body.employees.employeeHandledInstructions;

    })
  }

  getHoursFromMs(ms:number){
    if(!ms) return 'No completes'
    return `${moment.utc(ms).format('HH:mm')} hrs`;
  }

  findBiggestValue(array){
    return array.reduce((a, b) => a.count > b.count ? a : b, []);
  }

  createDate(selection){

    if(selection.value === 'custom') return;

    if(!selection.value || selection.value == ""){
      this.instructionFilters.get('dateTo').setValue("");
      this.instructionFilters.get('dateFrom').setValue("");
    } else {
      let today = moment();

      this.instructionFilters.get('dateTo').setValue(today.toDate());
      this.instructionFilters.get('dateFrom').setValue(today.subtract(Number(selection.value), "days").toDate());
    }

  }

  getEmployees() {
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;

        this.filteredEmployees = this.instructionFilters.get('employee').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    )
  }

  private _filter(value: string): Employee[] {
    if(typeof value !== 'string') return;

    const filterValue = value.toLowerCase();
    return this.employees.filter(employee => employee.firstName.toLowerCase().includes(filterValue) || employee.lastName.toLowerCase().includes(filterValue));
  }

  displayEmployeeName(employee: Employee) {
    return employee.lastName ? `${employee?.firstName} ${employee?.lastName}` : 'All employees'
  }

  mapEmployee(event:MatAutocompleteSelectedEvent):void {
    if(event.option.value._id === 0){ return this.instructionFilters.get('employeeId').setValue("")}
    this.instructionFilters.get('employeeId').setValue(event.option.value._id)
  }

}
