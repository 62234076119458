import { DialogData } from 'src/app/components/modal/delete/delete.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from './../../../../../services/authentication.service';
import { BrokerService } from 'src/app/services/broker.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';

@Component({
  selector: 'app-broker-delete-company-location',
  templateUrl: './broker-delete-company-location.component.html',
  styleUrls: ['./broker-delete-company-location.component.scss']
})
export class BrokerDeleteCompanyLocationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<BrokerDeleteCompanyLocationComponent>,
    private brokerService: BrokerService,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(status){
    this.dialogRef.close(status)
  }

  deleteLocation(){
    this.brokerService.deleteCompanyAddress(this.authenticationService.currentCompany.companyName, this.data['_id']).subscribe(response => {
      this.closeDialog('success');
    })
  }

}
