import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';

@Component({
  template: ''
})
export class VehicleInsuranceQuoteComponent {

  dialogRef: any;
  vehicles: Vehicle;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(vehicles: Vehicle[]): void {

    this.dialogRef = this.dialog.open(VehicleInsuranceQuoteComponentPreview, {
      width: '800px',
      data: vehicles
    });

  }

}

@Component({
  templateUrl: './vehicle-insurance-quote.component.html',
  styleUrls: ['./vehicle-insurance-quote.component.scss']
})
export class VehicleInsuranceQuoteComponentPreview {

  quoteVehicles: Vehicle[] = [];

  constructor(
    public dialogRef: MatDialogRef<VehicleInsuranceQuoteComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public vehicles: Vehicle[],
    public personalInsuranceService: PersonalInsuranceService
  ) { }

  addVehicleToQuote(vehicle:Vehicle){

    if(!this.quoteVehicles.includes(vehicle)){
      this.quoteVehicles.push(vehicle);
      vehicle['getQuote'] = true;
    } else {
      this.quoteVehicles = this.quoteVehicles.filter(vehicles =>  vehicles != vehicle);
      vehicle['getQuote'] = false;
    }
    
  }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

}

