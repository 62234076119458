import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[showHidePassword]'
})
export class ShowHidePasswordDirective {

  private _shown = false;

  constructor(
    private el: ElementRef
  ) {
    this.setup();
  }

  toggle(element: HTMLElement) {
    this._shown = !this._shown;
    
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      element.classList.add('flaticon-hide');
      element.classList.remove('flaticon-view');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      element.classList.remove('flaticon-hide');
      element.classList.add('flaticon-view');
    }
  }

  createAppend() {
    const parent = this.el.nativeElement.parentNode;

    const div = document.createElement('div')
    div.classList.add('input-group-append');
    div.setAttribute('_ngcontent-c9', '') //this is for styling to work

    const span = document.createElement('span');
    span.classList.add('input-group-text');
    span.setAttribute('_ngcontent-c9', '') //this is for styling to work

    div.appendChild(span)
    parent.appendChild(div)
  }

  setup() {
    this.createAppend();
    const parent = this.el.nativeElement.nextSibling.children[0];

    const icon = document.createElement('i');
    icon.classList.add('fi', 'flaticon-view');
    icon.setAttribute('_ngcontent-c9', '') //this is for styling to work

    icon.addEventListener('click', (event) => {
      this.toggle(icon);
    });

    parent.appendChild(icon);
  }
}