import { BrokerAdministratorService } from './../../../../services/broker-administrator.service';
import { BrokerUnderwriterService } from './../../../../services/broker-underwriter.service';
import { CommercialClientsService } from 'src/app/services/commercial-clients.service';
import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { ReturnedDebitOrders } from './../../../../_shared/models/returnedDebitOrders.model';
import { BrokerReturnedDebitService } from './../../../../services/broker-returned-debit.service';
import { Instruction } from 'src/app/_shared/models/brokerClaims.model';
import { BrokerEmailAddAttachmentComponent } from './attachments/broker-email-add-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_shared/models/User.model';
import { BrokerAdminInstruction, Client } from './../../../../_shared/models/brokerAdminInstruction.model';
import { BrokerClaimsService } from './../../../../services/broker-claims.service';
import { BrokerAdminInstructionService } from 'src/app/services/broker-admin-instruction.service';
import { AuthenticationService } from './../../../../services/authentication.service';
import { Policy } from './../../../../_shared/models/policy.model';
import { BrokerClaim } from './../../../../_shared/models/brokerClaims.model';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BrokerService } from './../../../../services/broker.service';
import { FamilyMemberResponse } from './../../../../_shared/models/familyMember.model';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as BalloonEditor from '@ckeditor/ckeditor5-editor-balloon';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { BrokerEmailService } from 'src/app/services/broker-email.service';
import { HttpResponse } from '@angular/common/http';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { Observable } from 'rxjs';

import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { formatDate } from '@angular/common';
import { MatRadioChange } from '@angular/material/radio';
import { BrokerQuote } from 'src/app/_shared/models/brokerQuotes.model';

@Component({
  selector: 'broker-compose-new-email',
  templateUrl: './broker-compose-new-email.component.html',
  styleUrls: ['./broker-compose-new-email.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerComposeNewEmailComponent implements OnInit {

  @Input() sendToEmail:string

  @ViewChild('recipientsInput') recipientsInput: ElementRef<HTMLInputElement>;
  @ViewChild('recipients') matAutocomplete: MatAutocomplete;

  @ViewChild('recipientsCcInput') recipientsCcInput: ElementRef<HTMLInputElement>;

  @ViewChild('recipientsBccInput') recipientsBccInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  visible = true;
  selectable = true;
  removable = true;

  familyMembers: any[] = [];
  client: any;

  filteredFamilyMembers: Observable<any[]>;
  addedRecipients: any[] = [];
  ccRecipients: any[] = [];
  bccRecipients: any[] = [];

  selectedUser = this.brokerEmailService.clientId;

  noteReference: string;

  instructions: BrokerAdminInstruction[] = [];
  totalInstructions: number = 0;
  instructionOffset: number = 0;

  claims: BrokerClaim[] = [];
  totalClaims:number = 0;
  claimOffset: number = 0;

  policies: Policy[] = [];
  totalPolicies:number = 0;
  policyOffset: number = 0;

  quotes: BrokerQuote[] = [];
  totalQuotes: number = 0;
  quoteOffset: number = 0;

  returnedDebits: ReturnedDebitOrders[] = [];
  totalReturnedDebits: number = 0;
  returnedDebitOffset: number = 0;

  brokerCompany: string = this.authenticationService.currentCompany.companyName;

  public Editor = ClassicEditor;
  public ckEditorConfig = {
    toolbar: [ 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
  }

  newEmailFormGroup: FormGroup;
  formSubmitted: boolean = false;

  uploader: FileUploader;

  emailAttachments: any[] = [];
  emailBodyDetails = {
    client: null,
    policy: null,
    instruction: null,
    claim: null,
    returnedDebit: null,
    claimNumber: null,
    insuranceClaimNumber: null
  };
  emailSignature = '';

  emailOptions:any = null;

  constructor(
    private formBuilder: FormBuilder,
    private brokerEmailService: BrokerEmailService,
    private brokerComponent: BrokerComponent,
    private toastr: ToastrService,
    private brokerService: BrokerService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private instructionService: BrokerAdminInstructionService,
    private claimsService: BrokerClaimsService,
    private dialog: MatDialog,
    private brokerReturnedDebitService: BrokerReturnedDebitService,
    private brokerQuoteService: BrokerQuoteService,
    private commercialClientService: CommercialClientsService,
    private underwriterService: BrokerUnderwriterService,
    private administratorService: BrokerAdministratorService
  ) {
    this.uploader = new FileUploader({});
  }

  ngOnInit() {
    this.newEmailFormGroup = this.formBuilder.group({
      autoComplete: [''],
      recipients: this.formBuilder.array([]),
      cc: this.formBuilder.array([]),
      bcc: this.formBuilder.array([]),
      emailBodyDetails: [],
      emailSubject: ['', Validators.required],
      emailBody: ['', Validators.required],
      attachments: this.formBuilder.array([]),
      client: [''],
      noteReference: ['', Validators.required],
      claim: [{
        value: null,
        disabled: this.emailOptions?.forward
      }],
      instruction: [{
        value: null,
        disabled: this.emailOptions?.forward
      }],
      policy: [{
        value: null,
        disabled: this.emailOptions?.forward
      }],
      returnedDebit: [{
        value: null,
        disabled: this.emailOptions?.forward
      }],
      quote: [{
        value: null,
        disabled: this.emailOptions?.forward
      }]
    });

    this.newEmailFormGroup.reset();

    this.getClient();
    this.getEmployees();

    this.getAllClaimsPerUser();
    this.getAllReturnedDebitsPerUser();
    this.getAllInstructionsPerUser();
    this.getAllPoliciesPerUser();

    if(this.brokerEmailService.emailAdress !== ''){
      // this.newEmailFormGroup.get('recipients').setValue(this.brokerEmailService.emailAdress)
      this.brokerEmailService.emailAdress = '';
    }

    this.newEmailFormGroup.get('client').setValue(this.selectedUser);
    this.newEmailFormGroup.updateValueAndValidity();

    this.emailBodyDetails.client = this.newEmailFormGroup.get('client').setValue(this.selectedUser);

    if(this.brokerEmailService.emailOptions){
      this.emailOptions = this.brokerEmailService.emailOptions

      // emailOptions.to.forEach(mailAddress => {
      //   this.addRecipient({ value: mailAddress } as MatChipInputEvent)
      // })
      this.newEmailFormGroup.get('emailSubject').setValue(this.emailOptions.subject);
      this.newEmailFormGroup.get('policy').setValue(this.emailOptions.policy);
      this.newEmailFormGroup.get('instruction').setValue(this.emailOptions.instruction);
      this.newEmailFormGroup.get('claim').setValue(this.emailOptions.claim);

      this.emailAttachments = this.emailOptions.attachments;

      this.brokerEmailService.emailOptions = null;
    }

  }

  private _filter(value: string): string[] {

    const filterValue = value?.toLowerCase();

    return [...this.familyMembers].filter(member => 
      member.email.toLowerCase().includes(filterValue) || 
      (member.surname && member.surname.toLowerCase().includes(filterValue)) ||
      (member.name && member.name.toLowerCase().includes(filterValue)) ||
      (member.firstName && member.firstName.toLowerCase().includes(filterValue)) ||
      (member.lastName && member.lastName.toLowerCase().includes(filterValue))
    );
  }

  sendEmail(){

    this.formSubmitted = true;

    if(this.newEmailFormGroup.get('recipients').value.length === 0){
      this.toastr.error('Please add at lease 1 recipient', 'Could not send email.');
      return;
    }

    this.newEmailFormGroup.get('attachments').setValue([])

    this.newEmailFormGroup.markAllAsTouched();

    if(this.emailAttachments && this.emailAttachments.length > 0){
      this.emailAttachments.forEach(attachment => {
        this.newEmailFormGroup.get('attachments').value.push({ path: attachment.documentFile, filename: attachment.documentName });
      })
    }

    if(this.newEmailFormGroup.invalid){
      console.warn(this.newEmailFormGroup)
      this.toastr.error('There are some errors on your form.', 'Could not send email.')
      return
    }

    this.newEmailFormGroup.get('emailBodyDetails').setValue(this.emailBodyDetails);

    this.brokerEmailService.sendEmailToClient(this.brokerComponent.brokerCompany.companyName, this.newEmailFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {
        this.brokerComponent.toggleSendingEmail(this.selectedUser);
      }
    )
  }

  toggleSendingEmail(){
    this.brokerEmailService.composingNewEmail = !this.brokerEmailService.composingNewEmail;
  }

  getClient(){

    this.brokerService.getSingleClient(this.selectedUser).subscribe(response => {
      this.client = response.body.user;
      this.emailBodyDetails['client'] = `${this.client.firstName} ${this.client.lastName}`; 
      this.familyMembers = [response.body.user];
      this.getClientFamilyMembers();

      this.getEmailSignature();
    }, error => {
      this.commercialClientService.getSingleClient(this.selectedUser).subscribe(response => {
        this.client = response.body.user;
        this.emailBodyDetails['client'] = `${this.client.companyName}`; 
        this.familyMembers = [response.body.user];
        this.getClientFamilyMembers();

        let recipients = this.newEmailFormGroup.get('recipients') as FormArray;
        recipients.push(new FormControl(this.client.email));
        this.addedRecipients.push(this.client.email);
  
        this.getEmailSignature();
      })
    })
  }

  getClientFamilyMembers(createNewFamilyObj:boolean = true){
    this.brokerService.getClientFamilyMembers(this.selectedUser).subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        if(createNewFamilyObj) this.familyMembers = [...this.familyMembers, ...response.body.family];

        this.filteredFamilyMembers = this.newEmailFormGroup.get('autoComplete').valueChanges.pipe(
          startWith(''),
          map((email: string | null) => this._filter(email) ));
        }
    )
  }

  getClientInsuranceEmails(policy: string){
    this.brokerService.getSelectedPolicyDetails(this.policies.find(p => p.policyNumber === policy)._id).subscribe(response => {

      this.underwriterService.getSelectedUnderwriter(response.body.policies['underwriter']).subscribe(underwriterResponse => {
        
        const foundUnderwriter = underwriterResponse.body.underwriter;

        let under = foundUnderwriter['email'].map(admEml => {
          return {
            firstName: foundUnderwriter['name'],
            lastName: admEml.emailAddressType,
            email: admEml.emailAddress
          }
        })

        this.familyMembers = [...this.familyMembers, ...under];

      this.administratorService.getSelectedAdministrator(response.body.policies['administrator']).subscribe(administratorResponse => {
        
        const foundAdmin = administratorResponse.body.administrator;

        let admin = foundAdmin['email'].map(admEml => {
          return {
            firstName: foundAdmin['name'],
            lastName: admEml.emailAddressType,
            email: admEml.emailAddress
          }
        })

        this.familyMembers = [...this.familyMembers, ...admin];

        this.getClientFamilyMembers(false);

      })

    })

    })
  }

  getEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(response => { 
      this.familyMembers = [...this.familyMembers, ...response.body.user]
    })
  }

  addRecipient(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.addedRecipients.push(value.trim());

      let recipients = this.newEmailFormGroup.get('recipients') as FormArray;
      recipients.push(new FormControl(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }

  addCcRecipient(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.ccRecipients.push(value.trim());

      let recipients = this.newEmailFormGroup.get('cc') as FormArray;
      recipients.push(new FormControl(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.newEmailFormGroup.get('cc').setValue(null);
  }

  addBccRecipient(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.bccRecipients.push(value.trim());

      let recipients = this.newEmailFormGroup.get('bcc') as FormArray;
      recipients.push(new FormControl(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.newEmailFormGroup.get('bcc').setValue(null);
  }

  removeRecipientEmail(email: string): void {
    const index = this.addedRecipients.indexOf(email);

    if (index >= 0) {
      this.addedRecipients.splice(index, 1);

      let recipients = this.newEmailFormGroup.get('recipients') as FormArray;
      recipients.removeAt(index);
    }
  }

  removeCcEmail(email: string): void {
    const index = this.ccRecipients.indexOf(email);

    if (index >= 0) {
      this.ccRecipients.splice(index, 1);

      let recipients = this.newEmailFormGroup.get('cc') as FormArray;
      recipients.removeAt(index);
    }
  }
  removeBccEmail(email: string): void {
    const index = this.bccRecipients.indexOf(email);

    if (index >= 0) {
      this.bccRecipients.splice(index, 1);
      let recipients = this.newEmailFormGroup.get('bcc') as FormArray;
      recipients.removeAt(index);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    if(!event.option.value) return

    this.addedRecipients.push(event.option.value);
    this.recipientsInput.nativeElement.blur();
    this.newEmailFormGroup.get('autoComplete').setValue('');
    // this.recipientsInput.nativeElement.focus();

    // this.newEmailFormGroup.get('recipients').setValue(null);
    let recipients = this.newEmailFormGroup.get('recipients') as FormArray;
    recipients.push(new FormControl(event.option.value));
  }

  selectedCc(event: MatAutocompleteSelectedEvent): void {

    if(!event.option.value) return

    this.ccRecipients.push(event.option.value);
    this.recipientsCcInput.nativeElement.value = '';
    // this.newEmailFormGroup.get('cc').setValue(null);

    let recipients = this.newEmailFormGroup.get('cc') as FormArray;
    recipients.push(new FormControl(event.option.value));
  }

  selectedBcc(event: MatAutocompleteSelectedEvent): void {

    if(!event.option.value) return

    this.bccRecipients.push(event.option.value);
    this.recipientsBccInput.nativeElement.value = '';
    // this.newEmailFormGroup.get('bcc').setValue(null);

    let recipients = this.newEmailFormGroup.get('bcc') as FormArray;
    recipients.push(new FormControl(event.option.value));
  }

  getAllPoliciesPerUser(){

    if(this.policies.length === this.totalPolicies && this.totalPolicies != 0) return;

    let req = {
      offset: this.policyOffset,
      limit: 100,
      active: true
    }

    this.brokerService.getPoliciesPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {
      
      this.policyOffset = this.policyOffset + response.body.policies.length;
      this.totalPolicies = response.body.count;
      
      this.policies = [
        ...this.policies,
        ...response.body.policies
      ]
    })
  }

  getAllInstructionsPerUser(){

    if(this.instructions.length === this.totalInstructions && this.totalInstructions != 0) return;

    let req = {
      // completed: 'incomplete',
      offset: this.instructionOffset,
      limit: 100,
      completed: 'incomplete'
    }

    this.instructionService.getAllInstructionsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {

      this.instructionOffset = this.instructionOffset + response.body.instruction.length;

      console.log('instructions => ', this.instructions)
      
      this.instructions = [
        ...this.instructions,
        ...response.body.instruction
      ]
    })
  }

  getAllClaimsPerUser(){

    if(this.claims.length === this.totalClaims && this.totalClaims != 0) return;

    let req = {
      offset: this.claimOffset,
      limit: 100,
      completed: 'incomplete'
    }

    this.claimsService.getAllClaimsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {
      
      this.claimOffset = this.claimOffset + response.body.claim.length;
      
      this.claims = [
        ...this.claims,
        ...response.body.claim
      ]
    })
  }

  getAllReturnedDebitsPerUser(){

    if(this.returnedDebits.length === this.totalReturnedDebits && this.totalReturnedDebits != 0) return;

    let req = {
      offset: this.returnedDebitOffset,
      limit: 100,
      completed: 'incomplete'
    }

    this.brokerReturnedDebitService.getAllReturnedDebitsPerUser(this.brokerCompany, this.selectedUser, req).subscribe(response => {
      
      this.returnedDebitOffset = this.returnedDebitOffset + response.body.returnedDebit.length;
      
      this.returnedDebits = [
        ...this.returnedDebits,
        ...response.body.returnedDebit
      ]
    })
  }

  getAllQuotesPerUser(){

    if(this.quotes.length === this.totalQuotes && this.totalQuotes != 0) return;

    let req = {
      offset: this.quoteOffset,
      limit: 100,
      status: ['sent', 'inProgress', 'accepted']
    }

    this.brokerQuoteService.getAllClientQuotes(this.brokerCompany, this.selectedUser, req).subscribe(response => {      
      this.quoteOffset = this.quoteOffset + response.body.quotes.length;
      
      this.quotes = [
        ...this.quotes,
        ...response.body.quotes
      ]
    })
  }

  addAttachment(){
    const dialogRef = this.dialog.open(BrokerEmailAddAttachmentComponent, { 
      width: '1000px',
      data: { 
        selectedUser: this.selectedUser 
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response){
        this.emailAttachments = response;
      }
    })
  }

  removeAttachment(attachment){
    let attIndex = this.emailAttachments.findIndex(file => file.documentName === attachment.documentName);
    this.emailAttachments.splice(attIndex, 1);
  }

  getEmailSignature(){
    this.brokerEmailService.getEmailSignature().subscribe(response => {
      this.newEmailFormGroup.get('emailBody').setValue(response.body.signature)
      this.emailSignature = response.body.signature;

      if(this.emailOptions.forward){
        let forwardMessage = `
        <p><br></p>
        ---------- Forwarded message ---------<br>
        From: ${this.emailOptions.from.replace('<', '&#60;').replace('>', '&#62;')}<br>
        Date: ${formatDate(this.emailOptions.dateSent, "EEE, MMM dd, yyyy 'at' HH:MM", 'en')} <br>
        Subject: ${this.emailOptions.subject}<br>
        To: &#60;${this.emailOptions.to}&#62;<br>
        <br>
        ${this.emailOptions.body}
        `

        this.newEmailFormGroup.get('emailBody').setValue(`${response.body.signature}${forwardMessage}`)

      }

      // this.newEmailFormGroup.get('emailBody').setValue('&nbsp;');
      // this.setEmailBodyValue();
    })
  }

  

  setEmailBodyDetails(event, prop){
    if(prop === 'claim'){
      let chosenClaim = this.claims.find(c => c.referenceNumber === event.value);
      this.emailBodyDetails['claimNumber'] = chosenClaim['claimNumber'];
      this.emailBodyDetails['insuranceClaimNumber'] = chosenClaim['underwritersClaimNumber'];
    }
    this.emailBodyDetails[prop] = event.value;
    // this.setEmailBodyValue();
  }

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.recipientsInput.nativeElement, value : this.recipientsInput.nativeElement.value};
      this.addRecipient(matChipEvent);
    }
  }

  addOnBlurCC(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.recipientsCcInput.nativeElement, value : this.recipientsCcInput.nativeElement.value};
      this.addCcRecipient(matChipEvent);
    }
  }

  addOnBlurBcc(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.recipientsBccInput.nativeElement, value : this.recipientsBccInput.nativeElement.value};
      this.addBccRecipient(matChipEvent);
    }
  }

  selectNoteReference(reference: MatRadioChange){
    if(reference.value === 'policy') this.getAllPoliciesPerUser();
    if(reference.value === 'instruction') this.getAllInstructionsPerUser();
    if(reference.value === 'claim') this.getAllClaimsPerUser();
    if(reference.value === 'quote') this.getAllQuotesPerUser();
    if(reference.value === 'returnedDebit') this.getAllReturnedDebitsPerUser();
  }

  getCurrentClient(){
    if(this.client?.lastName){
      return `${this.client?.firstName} ${this.client?.lastName}`
    } else {
      return `${this.client?.companyName}`
    }
  }

  getPolicyEmails(event){
    this.getClientInsuranceEmails(event.value);
  }

}
