import { HttpResponse } from '@angular/common/http';
import { CommercialProductsService } from './../../../../services/commercialProduct.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-broker-commercial-product-delete',
  templateUrl: './broker-commercial-product-delete.component.html',
  styleUrls: ['./broker-commercial-product-delete.component.scss']
})
export class BrokerCommercialProductDeleteComponent {

  constructor(
    public dialogRef: MatDialogRef<BrokerCommercialProductDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private commercialProductService: CommercialProductsService
  ) {
    console.log('data here => ', data)
  }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeProduct(data: any){
    console.log('data => ', data)
    this.commercialProductService.deleteCommercialProduct(data.clientId, data._id).subscribe(
      (response:HttpResponse<any>) => {
        this.closeDialog('success');
      }
    )
  }

}
