<div class="col-md-8 col-sm-6 col-6 d-inline-block mb-3">
    <button class="btn btn-sm btn-pill" (click)="view = 'current'" [ngClass]="view == 'current' ? 'btn-info' : 'btn-label-info'">Currently insured</button>
    <button class="btn btn-sm btn-pill" (click)="view = 'previous'" [ngClass]="view == 'previous' ? 'btn-info' : 'btn-label-info'">Previously insured</button>
  </div>
  
  <ng-container *ngIf="view === 'current'">
    <div class="example-table-container">
  
      <table mat-table [dataSource]="userProducts" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
  
          <!-- Vehicle Registration Column -->
          <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row" data-label="Description">{{ row?.description }}</td>
          </ng-container>
  
          <!-- Vehicle make Column -->
          <ng-container matColumnDef="productType">
              <th mat-header-cell *matHeaderCellDef>Product type</th>
              <td mat-cell *matCellDef="let row" data-label="Product type">{{ row?.productType | sentenceCase }}</td>
          </ng-container>

          <ng-container matColumnDef="productValue">
            <th mat-header-cell *matHeaderCellDef>Product value</th>
            <td mat-cell *matCellDef="let row" data-label="Product type">{{ row?.productValue | currency: 'R ' }}</td>
          </ng-container>
  
          <!-- Vehicle model Column -->
          <ng-container matColumnDef="premium">
              <th mat-header-cell *matHeaderCellDef>Premium</th>
              <td mat-cell *matCellDef="let row" data-label="Premium">{{ row?.insurance?.premium | currency: 'R ' }}</td>
          </ng-container>

          <ng-container matColumnDef="policyNumber">
              <th mat-header-cell *matHeaderCellDef>Policy number</th>
              <td mat-cell *matCellDef="let row" data-label="Policy number">{{ row?.insurance?.policyNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="inceptionDate">
            <th mat-header-cell *matHeaderCellDef>Inception date</th>
            <td mat-cell *matCellDef="let row" data-label="Premium">{{ row?.insurance?.inceptionDate | date: 'dd MMM yyyy' }}</td>
        </ng-container>
  
          <!-- Menu Column -->
          <ng-container matColumnDef="menu">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row" data-label="Actions">
                <ng-container *ngIf="canViewItem" >
                  <button class="btn btn-light btn-pill color-brand" matTooltip="Edit product" [routerLink]="['short-term-insurance/commercialProduct/edit/', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
                  <button class="btn btn-light btn-pill color-danger" matTooltip="Remove product" (click)="deleteProduct(row)"><i class="fi flaticon-trash mr-0"></i></button>
                </ng-container>
              </td>
          </ng-container>
  
          <!-- Menu Column --
          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" data-label="Actions">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <i class="fi flaticon-menu"></i>
              </button>
  
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                  [routerLink]="['short-term-insurance/vehicle/', ({id: row._id, r: this.router.url})]">
                  <span>View vehicle</span>
                </button>
  
                <button mat-menu-item
                  [routerLink]="['short-term-insurance/vehicle/edit/', ({ registration: row.registrationNumber, r: this.router.url})]">
                  <span>Edit vehicle</span>
                </button>
  
                <button mat-menu-item (click)="deleteVehicle(row)">
                  <span>Delete vehicle</span>
                </button>
              </mat-menu>
            </td>
          </ng-container> -->
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  
  <div class="col-12 text-center mt-3" *ngIf="userProducts?.length === 0">
      <p>No commercial products found</p>
  </div>
  
  <mat-paginator [hidden]="userProducts?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getUserVaps($event)">
  </mat-paginator>
  </ng-container>
  
  <ng-container *ngIf="view === 'previous'">
    <div class="example-table-container">
  
      <table mat-table [dataSource]="previouslyInsuredProducts" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
  
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let row" data-label="Description">{{ row?.item?.description }}</td>
        </ng-container>

        <!-- Vehicle make Column -->
        <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef>Product type</th>
            <td mat-cell *matCellDef="let row" data-label="Product type">{{ row?.item?.productType | sentenceCase }}</td>
        </ng-container>

        <ng-container matColumnDef="productValue">
          <th mat-header-cell *matHeaderCellDef>Product value</th>
          <td mat-cell *matCellDef="let row" data-label="Product value">{{ row?.item?.productValue | currency: 'R ' }}</td>
      </ng-container>

        <!-- Vehicle model Column -->
        <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef>Premium</th>
            <td mat-cell *matCellDef="let row" data-label="Premium">{{ row?.item?.premium | currency: 'R ' }}</td>
        </ng-container>

        <ng-container matColumnDef="policyNumber">
          <th mat-header-cell *matHeaderCellDef>Policy number</th>
          <td mat-cell *matCellDef="let row" data-label="Policy number">{{ row?.item?.policyNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="inceptionDate">
          <th mat-header-cell *matHeaderCellDef>Termination date</th>
          <td mat-cell *matCellDef="let row" data-label="Premium">{{ row?.item?.removedDate | date: 'dd MMM yyyy' }}</td>
      </ng-container>
  
          <!-- Menu Column -->
          <ng-container matColumnDef="menu">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row" data-label="Actions">
                  <!-- <button class="btn btn-light btn-pill color-brand" matTooltip="View product" [routerLink]="['short-term-insurance/valueAddedProducts/edit/', row?.item?._id]" [view]="true"><i class="fi flaticon-edit mr-0"></i></button> -->
                  <!-- <button class="btn btn-light btn-pill color-danger" matTooltip="Remove product" (click)="deleteProduct(row)"><i class="fi flaticon-trash mr-0"></i></button> -->
              </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  
  <div class="col-12 text-center mt-3" *ngIf="previouslyInsuredProducts?.length === 0">
      <p>No previously insured commercial products found.</p>
  </div>
  
  <mat-paginator [hidden]="userProducts?.length === 0" [length]="resultsLengthPreviousInsurance" [pageSize]="pageSizePreviousInsurance" [pageSizeOptions]="pageSizeOptions" (page)="getPreviousInsuredProducts($event)">
  </mat-paginator>
  </ng-container>
  