import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit, forwardRef } from '@angular/core';
import { MyAccountService } from 'src/app/services/my-account.service';

@Component({
  selector: 'app-broker-new-edit-client-family-member',
  templateUrl: './broker-new-edit-client-family-member.component.html',
  styleUrls: ['./broker-new-edit-client-family-member.component.scss']
})
export class BrokerNewEditClientFamilyMemberComponent implements OnInit, AfterViewInit {

  routerPath: string = this.activeRoute.routeConfig.path;
  editFamilyMember = this.routerPath.includes('edit');

  clientId = this.activeRoute.snapshot.paramMap.get('id');
  familyMemberId = this.activeRoute.snapshot.paramMap.get('familyMemberId');
  familyMember: FamilyMember[];

  familyMemberFormGroup: FormGroup;
  formSubmitted = false;
  get familyMemberForm(){ return this.familyMemberFormGroup.controls; }

  validId: boolean = true;

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  constructor(
    private activeRoute: ActivatedRoute,
    private verifyId: IdNumberVerifyService,
    private myAccountService: MyAccountService,
    public location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) {}

  addNewFamilyMember(saveType?: string){

    // activate mat-error
    this.familyMemberFormGroup.markAllAsTouched();

    this.formSubmitted = true;
    let verifyId: any = this.verifyId.validateIdNumber(this.familyMemberFormGroup.value['idNumber']);
    this.validId = verifyId.isValid;

    delete this.familyMemberFormGroup.value._id

    if (this.familyMemberFormGroup.invalid || !this.validId){
      console.warn(this.familyMemberFormGroup)
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.','Could not add family member.')
      return;
    }

    this.myAccountService.saveNewFamilyMember(this.familyMemberFormGroup.value, this.clientId).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  getCurrentFamilyMember(memberId: string){
    this.myAccountService.getSelectedFamilyMembers(memberId).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        console.log('getting current family member => ', response.body.family)
        this.familyMemberFormGroup.patchValue(response.body.family)
      }
    )
  };

  updateFamilyMember(saveType: string){

    // activate mat-error
    this.familyMemberFormGroup.markAllAsTouched();

    this.formSubmitted = true;
    let verifyId: any = this.verifyId.validateIdNumber(this.familyMemberFormGroup.value['idNumber']);
    this.validId = verifyId.isValid;

    if (this.familyMemberFormGroup.invalid || !this.validId){
      console.warn(this.familyMemberFormGroup)
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.','Could not save family member.')
      return;
    }

    this.myAccountService.updateFamilyMember(this.familyMemberId, this.familyMemberFormGroup.value).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack()
        break;

      case 'continue':
        break;

      default:
        this.goBack()
        break;
    }
  }

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      this.location.back();
      // this.router.navigate(['b/clients/personal/edit', { id: this.clientId }])
    };
  }

  onFormChanges(): void {
    this.familyMemberFormGroup.valueChanges.subscribe(value => {
      let verifyId: any = this.verifyId.validateIdNumber(value['idNumber']);
      this.validId = verifyId.isValid;
    });
  }

  ngOnInit() {
    this.familyMemberFormGroup = this.formBuilder.group({
      _id: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      relationship: ['', Validators.required],
      anniversaryDate: [''],
      idNumber: ['', Validators.required],
      driversLicence: this.formBuilder.group({
        licenceCode: [''],
        licenceFirstIssue: [''],
        licenceExpiryDate: ['']
      }),
      email: [''],
      cellphone: ['']
    });

    this.onFormChanges();

  }

  ngAfterViewInit(){
    if(this.editFamilyMember){
      this.getCurrentFamilyMember(this.familyMemberId);
    }
  }

}
