import { DashboardComponent } from './../../dashboard/dashboard.component';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BulletinService } from 'src/app/services/bulletin.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { formatDate } from '@angular/common';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit, AfterViewInit {

  bulletins:any = [];
  currentUser = this.authService.currentUserValue;
  today = new Date();

  insuredVehicleInformation: any;
  vehicleInformation: any;

  insuredPersonalItemInformation: any;
  personalItemInformation: any;

  householdItemInformation: any;
  householdInsuranceInformation: any;

  insuredStructureInformation: any;

  constructor(
    private dashboardService: DashboardService,
    private bulletinService: BulletinService,
    private authService: AuthenticationService
  ) { }

  getRecentBulletins(){
    this.bulletinService.getRecentBulletins().subscribe(
      (response:HttpResponse<any>) => {        

        let bulletins = response.body.bulletin.reduce((acc, obj) => {
          let key = formatDate(obj['dateAdded'], 'MM-dd', 'en');

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(obj);
          return acc;
        }, {});

        this.bulletins = Object.keys(bulletins).map(bullet => bulletins[bullet]);

      }
    )
  }

  getVehicleInformation(){
    this.dashboardService.getInsuredVehicleInformation().subscribe(
      (response:HttpResponse<any>) => {
        console.log('response => ', response.body)
        this.insuredVehicleInformation = response.body.information;
      }
    )

    this.dashboardService.getAllVehicleInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.vehicleInformation = response.body.information;
      }
    )
  };

  getPersonalItemInformation(){
    this.dashboardService.getInsuredPersonalItemInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.insuredPersonalItemInformation = response.body.information;
      }
    )

    this.dashboardService.getAllPersonalItemInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.personalItemInformation = response.body.information;
      }
    )
  };

  getHouseholdItemsInformation(){
    this.dashboardService.getHouseholdItemsInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.householdItemInformation = response.body.information;
      }
    )
  }

  getHouseholdInsuranceInformation(){
    this.dashboardService.getHouseholdInsuranceInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.householdInsuranceInformation = response.body.information;
      }
    )
  }

  getInsuredStructureInformation(){
    this.dashboardService.getInsuredStructureInformation().subscribe(
      (response:HttpResponse<any>) => {
        this.insuredStructureInformation = response.body.information;
      }
    )
  }



  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getRecentBulletins();
    this.getVehicleInformation();
    this.getPersonalItemInformation();
    this.getHouseholdItemsInformation();
    this.getHouseholdInsuranceInformation();
    this.getInsuredStructureInformation();
  }

}

