<div class="modal-header">
    <h6 class="mb-0"><strong>Request a quotation?</strong></h6>
</div>

<div class="modal-body text-center">

    <h6>Please select the vehicle(s) that you would like a quote for, or create a new one.</h6>

    <button class="btn btn-primary mt-3" (click)="closeDialog('redirect')" [routerLink]="['/u/short-term-insurance/vehicle/new']">Add new vehicle</button>

    <div class="row mt-5">

        <div class="col-lg-4 col-md-6 col-12" *ngFor="let vehicle of vehicles">
            <div class="card" (click)="addVehicleToQuote(vehicle)" [ngClass]="{getQuote: vehicle.getQuote}">

                <div class="card-backdrop" *ngIf="vehicle?.getQuote">
                    <div class="check">
                        <i class="fi flaticon-success color-success"></i>
                    </div>
                </div>
        
                <div class="card-header text-center">
        
                <!-- <div class="card-header-menu" ngbDropdown>
                    <button ngbDropdownToggle type="button" class="btn btn-icon color-info mr-0" aria-controls="dropdown-basic">
                        <i class="fi flaticon-menu fi-rotate-90"></i>
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a class="dropdown-item" [routerLink]="['add-broker']">Select new broker</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="removeBroker(broker)">Remove broker</a></li>
                    </ul>        
                </div> -->
        
                <span class="userpic userpic-info userpic-circle userpic-lg">
                    <img [src]="vehicle?.selectedPhoto" class="img-fluid" />
                </span>
                    
                </div>
        
                <div class='card-body text-center'>
                    <strong class="card-heading m-0">{{ vehicle?.year }} {{ vehicle?.make }} {{ vehicle?.model }}</strong><br />
                    <span class="card-subheading">{{ vehicle?.registrationNumber }}</span>
                </div>
        
            </div> <!-- Close div for .card -->
        
        </div> <!-- Close div for .col-lg-4 .col-md-6 .col-12 -- BROKER LOOP -->
    </div> <!-- Close div for .row -->
        
</div> <!-- Close div for .modal-body -->

<div class="modal-footer">
    <div class="inline-buttons">
        <button cdkFocusInitial class="btn btn-secondary mr-2" (click)="closeDialog('cancel')"><i class="fi flaticon-cancel"></i>Cancel</button>
        <button class="btn btn-info" (click)="closeDialog('cancel')">Request quote</button>
    </div>
</div> <!-- Close div for .modal-footer -->