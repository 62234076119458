import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { BrokerAdminInstructionResponse } from './../../../_shared/models/brokerAdminInstruction.model';
import { BrokerAdminInstructionService } from 'src/app/services/broker-admin-instruction.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse } from '@angular/common/http';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerService } from 'src/app/services/broker.service';
import { KeyValue } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { RequestService } from 'src/app/services/request.service';
import { RejectInstructionModalComponent } from 'src/app/components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';
import { UserRequest } from 'src/app/_shared/models/userRequest.interface';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { Instruction } from 'src/app/_shared/models/brokerClaims.model';
import { Location } from '@angular/common';


@Component({
  selector: 'app-broker-new-edit-admin-instruction',
  templateUrl: './broker-new-edit-admin-instruction.component.html',
  styleUrls: ['./broker-new-edit-admin-instruction.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerNewEditAdminInstructionComponent implements OnInit, AfterViewInit {

  selectedInstruction = this.activeRoute.snapshot.paramMap.get('id');
  routerPath: string = this.activeRoute.routeConfig.path;
  editInstruction = this.routerPath.includes('edit');

  hasRequestAttached: boolean = false;
  request: UserRequest[];

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions = [];

  isLoading: boolean = false;
  clients: User[];
  employees: Employee[];
  products: any[];
  currentInstructionSteps: Instruction

  instructionFormGroup: FormGroup;
  formSubmitted = false;
  get instructionForm(){ return this.instructionFormGroup.controls; }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  instructionSteps:any;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent,
    private toastr: ToastrService,
    private brokerAdminInstructionService: BrokerAdminInstructionService,
    private homeService: BrokerClientHomeService,
    private homeContentService: BrokerClientHomeContentService,
    private allRiskService: BrokerClientAllRiskServiceService,
    private trailersAndCaravansService: TrailersAndCaravansService,
    private requestService: RequestService,
    private rejectInstructionModal: RejectInstructionModalComponent,
    private valueAddedProductsService: ValueAddedProductsService,
    public location: Location
  ) {
    this.activeRoute.queryParamMap.subscribe(param => {
      Object.keys(param['params']).forEach((key, index) => {
        if(key === 'request'){
          this.hasRequestAttached = true;

          this.requestService.getSingleRequest(param['params']['request']).subscribe(response => {
            this.request = response.body.requests
          })
        }
      })
    })
  }

  ngOnInit() {

    this.getInstructionSteps();

    this.instructionFormGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        display: '',
        _id: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        clientNumber: [''],
        username: ['', Validators.required]
      }),
      // client: ['', Validators.required],
      productType: ['', Validators.required],
      product: this.formBuilder.group({
        _id: ['', Validators.required],
        description: ['', Validators.required],
        path: ['']
      }),
      instructionType: ['', Validators.required],
      instructionDescription: [''],
      instructionSteps: this.formBuilder.array([]),
      employee: this.formBuilder.group({
        display: '',
        _id: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required]
      }),
      assignedEmployee: ['', Validators.required],
      userRef: ['', Validators.required],
      companyRef: ['', Validators.required],
      additionalComments: ['']
    });

    this.instructionFormGroup.get('companyRef').setValue(this.brokerComponent.brokerCompany.companyName);

    // GET CLIENTS
    this.instructionFormGroup
    .get('client')['controls'].display
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllClients(this.brokerComponent.brokerCompany.companyName, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

    // GET EMPLOYEES
    this.instructionFormGroup
    .get('employee')['controls'].display
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.brokerComponent.brokerCompany.companyName, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

    // GET PRODUCTS
    this.instructionFormGroup
    .get('product')['controls'].description
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => {
        if(this.instructionFormGroup.get('productType').value === 'vehicle')
          return this.brokerService.getUserVehicle(this.instructionFormGroup.get('client').value._id, { search: value }, true).pipe(finalize(() => this.isLoading = false))
      
        if(this.instructionFormGroup.get('productType').value === 'building')
        return this.homeService.getAllHomeInsuranceForClient(this.instructionFormGroup.get('client').value._id, this.brokerComponent.brokerCompany.companyName, { search: value }).pipe(finalize(() => this.isLoading = false))

        if(this.instructionFormGroup.get('productType').value === 'homeContent')
        return this.homeContentService.getAllHomeContentInsuranceForClient(this.instructionFormGroup.get('client').value._id, this.brokerComponent.brokerCompany.companyName, { search: value }).pipe(finalize(() => this.isLoading = false))

        if(this.instructionFormGroup.get('productType').value === 'allRisk')
        return this.allRiskService.getAllAllRiskInsuranceForClient(this.instructionFormGroup.get('client').value._id, this.brokerComponent.brokerCompany.companyName, { search: value }).pipe(finalize(() => this.isLoading = false))

        if(this.instructionFormGroup.get('productType').value === 'trailersAndCaravans')
        return this.trailersAndCaravansService.getAllTrailersAndCaravansInsuranceForClient(this.instructionFormGroup.get('client').value._id, this.brokerComponent.brokerCompany.companyName, { search: value }).pipe(finalize(() => this.isLoading = false))

        if(this.instructionFormGroup.get('productType').value === 'vaps')
        return this.valueAddedProductsService.getAllVapsForClient(this.instructionFormGroup.get('client').value._id, this.brokerComponent.brokerCompany.companyName, { search: value }).pipe(finalize(() => this.isLoading = false))

        })
    )
    .subscribe(
      (response: HttpResponse<any>) => {
        if(this.instructionFormGroup.get('productType').value === 'vehicle') this.products = response.body.vehicle;
        if(this.instructionFormGroup.get('productType').value === 'building') this.products = response.body.home;
        if(this.instructionFormGroup.get('productType').value === 'homeContent') this.products = response.body.homeContent;
        if(this.instructionFormGroup.get('productType').value === 'allRisk') this.products = response.body.item;
        if(this.instructionFormGroup.get('productType').value === 'trailersAndCaravans') this.products = response.body.trailerAndCaravan;
        if(this.instructionFormGroup.get('productType').value === 'vaps') this.products = response.body.products;
      }
    )
  }

  ngAfterViewInit(){
    if(this.editInstruction){
      this.getCurrentInstruction(this.selectedInstruction);
    }
  }

  addNewInstructionStep(step, index){

    let instructionStepArray = this.instructionFormGroup.get('instructionSteps') as FormArray;

    let stepData = new FormGroup({
      stepId: new FormControl(step._id),
      stepTitle: new FormControl(step.stepTitle),
      stepDescription:  new FormControl(step.stepDescription),
      dateCompleted:  new FormControl(new Date()),
      stepComment:  new FormControl(null),
      stepStatus: new FormControl(false),
      completedBy: new FormGroup({
        brokerId:  new FormControl(this.currentUser._id),
        firstName:  new FormControl(this.currentUser.firstName),
        lastName:  new FormControl(this.currentUser.lastName),
      })
    })

    instructionStepArray.insert(index, stepData);

  }

  removeInstructionStep(index){
    let instructionStepArray = this.instructionFormGroup.get('instructionSteps') as FormArray;
    instructionStepArray.removeAt(index)
  }

  goBack(){
    // this.router.navigate(['b/instructions'])
    this.location.back()
  }

  displayFn(client?: User): string | undefined {
    return client ? `${client.firstName} ${client.lastName}` : undefined;
  }
  mapClient(client){
    this.instructionFormGroup.get('userRef').setValue(client._id);
    this.instructionFormGroup.get('client')['controls']._id.setValue(client._id);
    this.instructionFormGroup.get('client')['controls'].firstName.setValue(client.firstName);
    this.instructionFormGroup.get('client')['controls'].lastName.setValue(client.lastName);
    this.instructionFormGroup.get('client')['controls'].clientNumber.setValue(client.broker.find(broker => broker.companyName === this.brokerComponent.brokerCompany.companyName).clientNumber);
    this.instructionFormGroup.get('client')['controls'].username.setValue(client.username);
  }

  displayFnProd(product?: any): string | undefined {
    return product ? `${product}` : undefined;
  }
  mapVehicle(vehicle){
    this.instructionFormGroup.get('product')['controls']._id.setValue(vehicle._id);
    this.instructionFormGroup.get('product')['controls'].description.setValue(`${vehicle.make} ${vehicle.model} - ${vehicle.registrationNumber}`);
    this.instructionFormGroup.get('product')['controls'].path.setValue(vehicle.registrationNumber);
  }

  mapTrailersAndCaravans(product){
    this.instructionFormGroup.get('product')['controls']._id.setValue(product._id);
    this.instructionFormGroup.get('product')['controls'].description.setValue(`${product.type} - ${product.make} ${product.model}`);
    this.instructionFormGroup.get('product')['controls'].path.setValue(product._id);
  }

  mapBuilding(building){
    this.instructionFormGroup.get('product')['controls']._id.setValue(building._id);
    this.instructionFormGroup.get('product')['controls'].description.setValue(building.address.formattedAddress);
    this.instructionFormGroup.get('product')['controls'].path.setValue(building._id);
  }

  mapAllRisk(item){
    this.instructionFormGroup.get('product')['controls']._id.setValue(item._id);
    this.instructionFormGroup.get('product')['controls'].path.setValue(item._id);
    this.instructionFormGroup.get('product')['controls'].description.setValue(item.address.itemName);
  }

  mapVaps(item){
    this.instructionFormGroup.get('product')['controls']._id.setValue(item._id);
    this.instructionFormGroup.get('product')['controls'].path.setValue(item._id);
    this.instructionFormGroup.get('product')['controls'].description.setValue(item.description);
  }

  displayFnEmployee(employee?: Employee): string | undefined {
    return employee ? `${employee.firstName} ${employee.lastName}` : undefined;
  }
  mapEmployee(employee){
    this.instructionFormGroup.get('assignedEmployee').setValue(employee._id);
    this.instructionFormGroup.get('employee').get('_id').setValue(employee._id);
    this.instructionFormGroup.get('employee').get('firstName').setValue(employee.firstName);
    this.instructionFormGroup.get('employee').get('lastName').setValue(employee.lastName);
  }

  getCurrentInstruction(instructionId:string){
    this.brokerAdminInstructionService.getSelectedInstruction(this.brokerComponent.brokerCompany.companyName, instructionId).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {

        // SET THE NEW INSTRUCTION STEPS DATA
        let instruction = response.body.instruction['instructionSteps'].map(step => {
          if(!step.stepStatus){
            step['completedBy'] = {
              brokerId:  this.currentUser._id,
              firstName:  this.currentUser.firstName,
              lastName:  this.currentUser.lastName,
            }
            step['dateCompleted'] = new Date()
          }
          return { ...step}
        })

        let instructionFormData = {
          ... response.body.instruction,
          instructionSteps: instruction
        }

        console.log('instructions => ', instructionFormData)

        this.currentInstructionSteps = response.body.instruction['instructionSteps'];
        this.instructionFormGroup.patchValue(instructionFormData);
        this.instructionFormGroup.get('client')['controls'].display.patchValue(instructionFormData['client'])
        this.instructionFormGroup.get('employee')['controls'].display.patchValue(instructionFormData['employee']);
        this.mapEmployee(instructionFormData['employee']);
      }
    )
  }

  addNewInstruction(saveType?:string){

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.instructionFormGroup.invalid) {
      console.warn(this.instructionFormGroup)
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new instruction.')
      return;
    }

    this.brokerAdminInstructionService.addNewInstruction(this.brokerComponent.brokerCompany.companyName, this.instructionFormGroup.value).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  updateInstruction(saveType?:string){

    console.warn(this.instructionFormGroup)

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.instructionFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save instruction.')
      return;
    }

    this.brokerAdminInstructionService.editInstruction(this.brokerComponent.brokerCompany.companyName, this.selectedInstruction, this.instructionFormGroup.value).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.saveType(saveType)
      }
    )

  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.instructionFormGroup.reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  acceptRequest(requestId:string){
    console.log('requestId -> ', requestId)
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.hasRequestAttached = false;
        this.instructionFormGroup.get('employee')['controls'].display.patchValue(response.body.instruction['employee']);
        this.mapEmployee(response.body.instruction['employee']);
        this.brokerComponent.getUserRequests();
      }
    )
  }

  // rejectRequest(request:Request){
  //   this.rejectInstructionModal.openDialog({ request });
  //   this.rejectInstructionModal.dialogRef.afterClosed().subscribe(response => {
  //     this.hasRequestAttached = false;
  //     if(response === 'success'){
  //       this.brokerComponent.getUserRequests()
  //     }
  //   })
  // }

  getInstructionSteps(){
    this.brokerAdminInstructionService.getInstructionSteps().subscribe(response => {
      this.instructionSteps = response.body.instructionSteps;

      for(let i = 0; i < response.body.instructionSteps.length; i++){
        this.addNewInstructionStep(response.body.instructionSteps[i], i)
      }
    })
  }

}
