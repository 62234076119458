import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { TrailersAndCaravans, TrailersAndCaravansResponse } from '../_shared/models/trailersAndCaravans.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrailersAndCaravansService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientTrailersAndCaravansInsurance(clientId:string, productFormData:TrailersAndCaravans, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.post<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientTrailersAndCaravansQuote(clientId:string, companyName: string, productFormData:TrailersAndCaravans, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.post<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/company/${companyName}/quote`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientTrailersAndCaravansQuote(clientId:string, productId:string, companyName: string, productFormData:TrailersAndCaravans, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.put<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/company/${companyName}/quote/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllTrailersAndCaravansInsuranceForClient(clientId:string, companyName: string, req:any = {}): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.get<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousTrailerAndCaravansInsuranceForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.get<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/previouslyInsured/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleTrailerAndCaravansInsuranceForClient(clientId:string, productId:string, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.get<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/product/${productId}`, {
      observe: 'response',
      params: req
    })
  }

  updateClientTrailerAndCaravansInsurance(clientId:string, productId:string, productFormData:TrailersAndCaravans, req?:any): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.put<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/product/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteInsuranceFromTrailerAndCaravansItem(clientId:string, productId:string): Observable<HttpResponse<TrailersAndCaravansResponse>>{
    return this.http.delete<TrailersAndCaravansResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/trailersAndCaravans/client/${clientId}/product/${productId}`, {
      observe: 'response'
    })
  }
  
}
