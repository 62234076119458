<h1>Panels</h1>

<div id="panels" class="row">

  <div class="col-6">
    <div class="panel panel-surespace">

      <div class="panel-heading">
        <h4>Heading</h4>
      </div>

      <div class="panel-body">
        Some body content here
      </div>

    </div> <!-- Close div for .panel -->
  </div> <!-- Close div for .col-6 -->

  <div class="col-6">
      <div class="panel panel-surespace border-bottom">
  
        <div class="panel-heading">
          <h4>Heading</h4>
        </div>
  
        <div class="panel-body">
          Some body content here
        </div>
  
      </div> <!-- Close div for .panel -->
    </div> <!-- Close div for .col-6 -->

</div> <!-- Close div for .row -->