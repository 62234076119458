<div class="col-md-8 col-sm-6 col-6 d-inline-block mb-3">
  <button class="btn btn-sm btn-pill" (click)="view = 'current'" [ngClass]="view == 'current' ? 'btn-info' : 'btn-label-info'">Currently insured</button>
  <button class="btn btn-sm btn-pill" (click)="view = 'previous'" [ngClass]="view == 'previous' ? 'btn-info' : 'btn-label-info'">Previously insured</button>
</div>

<ng-container *ngIf="view === 'current'">
  <div class="example-container">

    <div class="example-table-container">
  
      <table mat-table [dataSource]="userStructures" matSort matSortActive="created" matSortDisableClear
        matSortDirection="desc">
  
        <!-- Structure name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Structure type</th>
          <td mat-cell *matCellDef="let row">{{ row?.residenceType | sentenceCase }}</td>
        </ng-container>
  
        <!-- Structure suburb Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">{{ row?.address?.formattedAddress }}</td>
        </ng-container>
  
        <!-- Insured Value Column -->
        <ng-container matColumnDef="insuredValue">
          <th mat-header-cell *matHeaderCellDef>Insured Value</th>
          <td mat-cell *matCellDef="let row">{{ row?.insurance?.insuredValue | currency: 'R ' }}</td>
        </ng-container>

        <ng-container matColumnDef="inceptionDate">
          <th mat-header-cell *matHeaderCellDef>Inception date</th>
          <td mat-cell *matCellDef="let row" data-label="Inception date">{{ row?.insurance?.inceptionDate | date: 'dd MMM yyyy' }}</td>
      </ng-container>
  
        <!-- Menu Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row" data-label="Actions">
            <ng-container *ngIf="canViewItem" >
              <button class="btn btn-light btn-pill color-brand" matTooltip="Edit building insurance" [routerLink]="['short-term-insurance/structure/edit/', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
              <button class="btn btn-light btn-pill color-danger" matTooltip="Cancel building insurance" (click)="deleteHome(row)"><i class="fi flaticon-trash mr-0"></i></button>
            </ng-container>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div class="col-12 text-center mt-3" *ngIf="userStructures?.length === 0">
      <p>No buildings found</p>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getUserStructure($event)">
    </mat-paginator>
  
  </div>
</ng-container>

<ng-container *ngIf="view === 'previous'">
  <div class="example-container">

    <div class="example-table-container">
  
      <table mat-table [dataSource]="previouslyInsuredUserStructures" matSort matSortActive="created" matSortDisableClear
        matSortDirection="desc">
  
        <!-- Structure name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Structure type</th>
          <td mat-cell *matCellDef="let row">{{ row?.item?.residenceType | sentenceCase }}</td>
        </ng-container>
  
        <!-- Structure suburb Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">{{ row?.item?.address?.formattedAddress }}</td>
        </ng-container>
  
        <!-- Insured Value Column -->
        <ng-container matColumnDef="insuredValue">
          <th mat-header-cell *matHeaderCellDef>Insured Value</th>
          <td mat-cell *matCellDef="let row">{{ row?.item?.insurance?.insuredValue | currency: 'R ' }}</td>
        </ng-container>

        <ng-container matColumnDef="terminationDate">
          <th mat-header-cell *matHeaderCellDef>Termination date</th>
          <td mat-cell *matCellDef="let row" data-label="Termination date">{{ row?.item?.removedDate | date: 'dd MMM yyyy' }}</td>
      </ng-container>
  
        <!-- Menu Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row" data-label="Actions">
              <button class="btn btn-light btn-pill color-brand" matTooltip="View home" [routerLink]="['short-term-insurance/structure/edit/', row._id]" [view]="true"><i class="fi flaticon-edit mr-0"></i></button>
              <!-- <button class="btn btn-light btn-pill color-danger" matTooltip="Cancel home" (click)="deleteHome(row)"><i class="fi flaticon-trash mr-0"></i></button> -->
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedPreviousColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedPreviousColumns;"></tr>
      </table>
    </div>

    <div class="col-12 text-center mt-3" *ngIf="previouslyInsuredUserStructures?.length === 0">
      <p>No previously insured buildings found</p>
    </div>
  
    <mat-paginator [length]="resultsLengthPreviousInsure" [pageSize]="pageSizePreviousInsure" (page)="getUserStructure($event)" [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  
  </div>
</ng-container>
