import { Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { DeleteStructureComponent } from 'src/app/components/modal/delete-structure/delete-structure.component';
import { Structure, StructureResponse } from 'src/app/_shared/models/structure.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-client-structure-insurance',
  templateUrl: './client-structure-insurance.component.html',
  styleUrls: ['./client-structure-insurance.component.scss'],
  providers: [DeleteStructureComponent]
})
export class ClientStructureInsuranceComponent implements OnInit, AfterViewInit {

  structures: Structure[];

  displayedColumns: string[] = ['image', 'name', 'address', 'insuredWith', 'action'];
  resultsLength = 0;
  pageSize = 25;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private personalInsurance: PersonalInsuranceService,
    private deleteStructureModal: DeleteStructureComponent
  ) { }

  // getAllInsuredStructures(){
  //   this.personalInsurance.getAllInsuredStructures().subscribe(
  //     (response: HttpResponse<StructureResponse>) => {
  //       this.structures = response.body.structure;
  //     }
  //   )
  // };

  getAllInsuredStructures(noLoad?:boolean, eventData?:number){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: eventData ? (eventData * this.pageSize) - 1: this.paginator.pageIndex,
      limit: this.pageSize,
      registrationNumber: '',
      vehicleMake: '',
      vehicleModel: ''
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.personalInsurance.getAllInsuredStructures(req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.structure;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.structures = data);
  }

  deleteStructure(structure){
    this.deleteStructureModal.openDialog(structure);
    this.deleteStructureModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.getAllInsuredStructures();
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllInsuredStructures();
  }

}
