import { ActivatedRoute } from '@angular/router';
import { ApproveQuoteModalComponent } from './../../../../quotes/approveQuote/approve-quote-modal.component';
import { RejectQuoteModalComponent } from './../../../../quotes/rejectQuote/reject-quote-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { BrokerQuote, BrokerQuoteResponse } from 'src/app/_shared/models/brokerQuotes.model';

@Component({
  selector: 'broker-view-user-quotes',
  templateUrl: './broker-view-user-quotes.component.html',
  styleUrls: ['./broker-view-user-quotes.component.scss']
})
export class BrokerViewUserQuotesComponent implements OnInit {

  quotes: BrokerQuote[];

  clientId:string = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  
  displayedColumns: string[] = ['quoteNr', 'dateAdded', 'clientName', 'status', 'actions'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  userSearch:string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  quoteSearch:string = '';
  quoteSearchStatus: 'all'|'sent'|'accepted'|'rejected'|'inProgress'|'approved' = 'sent';

  constructor(
    private quoteService: BrokerQuoteService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  resetPaginator(){
    this.paginator.pageIndex = 0
  }

  findAllCompanyQuotes(searchParam?:string, noLoad?:boolean){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.resetPaginator());

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: this.quoteSearch,
      status: this.quoteSearchStatus
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex; 
          req['limit'] = this.paginator.pageSize;
          return this.quoteService.getAllClientQuotes(this.authService.currentCompany.companyName, this.clientId, req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.quotes;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.quotes = data);
  }

  cancelQuote(quoteId){
    this.quoteService.cancelQuote(quoteId).subscribe(
      (response:HttpResponse<BrokerQuoteResponse>) => {
        this.quotes = this.quotes.filter(quote => quote._id !== quoteId)
      }
    )
  }

  ngAfterViewInit(): void {
    this.findAllCompanyQuotes();
  }

  ngOnInit(): void {
  }

  approveQuote(quoteId){
    const modalRef = this.dialog.open(ApproveQuoteModalComponent)
  }

  acceptQuote(quote){
    this.quoteService.acceptQuote(quote._id).subscribe(() => {
      this.findAllCompanyQuotes();
    })
  }

  rejectQuote(quoteId, quoteNumber){
    const modalRef = this.dialog.open(RejectQuoteModalComponent, { data: {quoteId, quoteNumber}});

    modalRef.afterClosed().subscribe(response => {
      if(response === 'success'){
        let selectedQuoteIndex = this.quotes.findIndex(quote => quote._id === quoteId);
        this.quotes[selectedQuoteIndex].quoteStatus = 'rejected'
      }
    })
  }

}
