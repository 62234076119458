import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  authenticated = this.globals.AUTENTICATED;

  constructor(
    public globals: Globals
  ) { }

  ngOnInit() {
  }

}
