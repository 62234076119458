<h2 mat-dialog-title>
    Edit company location?
    <button class="close" mat-dialog-close></button>
</h2>

<mat-dialog-content>

    <form [formGroup]="addressFormGroup">

        <div class="row">

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12">
                <label for="addressLine1">Location description</label>
            </div>

            <div class="col-12">
            <input 
                class="form-control"
                name="addressDescription"
                formControlName="addressDescription"
                placeholder="Location description"
            >
            </div>
  
              <div class="col-12 clearfix mt-3"></div>

            <div class="col-12">
              <label for="addressLine1">Address</label>
            </div>

            <div class="col-12">
              <input
                ngx-google-places-autocomplete
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)"
                formControlName="addressLine1"
                [options]="addressOptions"
                class="form-control"
              />
            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 clearfix">
              <label for="addressLine1">Address Line 1</label>
            </div>

            <div class="col-12">
                <input 
                    class="form-control"
                    name="addressLine1"
                    formControlName="addressLine1"
                    placeholder="Address line 1"
                >
            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12">
              <label for="addressLine2">Address Line 2</label>
            </div>

            <div class="col-12">
                <input 
                  class="form-control"
                  name="addressLine2"
                  formControlName="addressLine2"
                  placeholder="Address line 2"
                >
            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 col-md-6">

              <div class="row">

                <div class="col-12">
                  <label for="suburb">Suburb</label>
                </div>

                <div class="col-12">
                    <input 
                        class="form-control"
                        name="suburb"
                        formControlName="suburb"
                        placeholder="Suburb"
                    />
                </div>

              </div>

            </div>

            <div class="col-12 clearfix mt-3 d-block d-md-none"></div>

            <div class="col-12 col-md-6">

              <div class="row">

                <div class="col-12">
                  <label for="city">City</label>
                </div>

                <div class="col-12">
                  <input 
                    class="form-control"
                    name="city"
                    formControlName="city"
                    placeholder="City"
                  >
                </div>

              </div>

            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 col-md-6">

              <div class="row">

                <div class="col-12">
                  <label for="postalCode">Postal Code</label>
                </div>

                <div class="col-12">
                  <input 
                    class="form-control"
                    name="postalCode"
                    formControlName="postalCode"
                    placeholder="Postal Code"
                  >
                </div>

              </div>

            </div>

        </div> <!-- Close div for .row-->

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="btn btn-secondary mr-2" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" (click)="editLocation()">Submit</button>
</mat-dialog-actions>
      