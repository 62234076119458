<div id="content" class="row">

    <sub-navigation heading="{{ editItem ? 'Edit' : 'Add new' }} all risk item"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="allRiskItemFormGroup" [formGroup]="allRiskItemFormGroup">

              <mat-horizontal-stepper linear #stepper>

                <mat-step>
                  <ng-template matStepLabel>Item</ng-template>

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Describe the item</strong></h5>
                                </div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemName">Item name</label>
                                </div>

                                <div class="col-12 col-sm-7 col-md-8" >
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="itemName" />
                                    </div>
                                </div>
        
                                <div class="col-12 clearfix mb-3"></div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemDescription">Item description</label>
                                </div>

                                <div class="col-12 col-sm-7 col-md-8" >
                                    <div class="form-group">
                                        <textarea type="text" class="form-control" formControlName="itemDescription" row="3" ></textarea>
                                    </div>
                                </div>

                                <ng-container *ngIf="!isQuoteProduct">

                                  <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemSerial">Item serial number</label>
                                  </div>

                                  <div class="col-12 col-sm-7 col-md-8" >
                                      <div class="form-group">
                                          <input type="text" class="form-control" formControlName="itemSerial" />
                                      </div>
                                  </div>
          
                                  <div class="col-12 clearfix mb-3"></div>

                                </ng-container>

                                <div class="col-12 clearfix mb-3"></div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemName">Item category</label>
                                </div>

                                <div class="col-12 col-sm-7 col-md-8" >
                                    <div class="input-group">
                                        <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': formSubmitted && allRiskItemFormGroup.get('itemCategory').hasError('required') }">
                                          <mat-select name="itemCategory" formControlName="itemCategory">
                                            <mat-option option value="" disabled>None</mat-option>
                                            <mat-option value="unspecified">Unspecified</mat-option>
                                            <mat-option value="other">Other</mat-option>
                                            <mat-option value="artworkAntiques">Artwork &amp; antiques</mat-option>
                                            <mat-option value="blanketCover">Blanket cover</mat-option>
                                            <mat-option value="camera">Camera / equipment</mat-option>
                                            <mat-option value="carRadio">Car radio</mat-option>
                                            <mat-option value="campingEquipment">Camping equipment </mat-option>
                                            <mat-option value="caravanContent">Caravan content</mat-option>
                                            <mat-option value="cellPhone">Cellular phone</mat-option>
                                            <mat-option value="cellPhoneAccessories">Cellular phone accessories</mat-option>
                                            <mat-option value="collectables">Collectables</mat-option>
                                            <mat-option value="computer">Computer</mat-option>
                                            <mat-option value="contactLenses">Contact lenses</mat-option>
                                            <mat-option value="divingEquipment">Diving equipment</mat-option>
                                            <mat-option value="drones">Drones</mat-option>
                                            <mat-option value="fireArm">Firearm</mat-option>
                                            <mat-option value="fishingEquipment">Fishing equipment</mat-option>
                                            <mat-option value="golfCart">Golf cart</mat-option>
                                            <mat-option value="golfEquipment">Golf equipment</mat-option>
                                            <mat-option value="goodsInVault">Goods in vault</mat-option>
                                            <mat-option value="gps">GPS</mat-option>
                                            <mat-option value="hearingAid">Hearing aid</mat-option>
                                            <mat-option value="householdGoodsInTransit">Household good in transit</mat-option>
                                            <mat-option value="jewelery">Jewelery</mat-option>
                                            <mat-option value="keys">Keys, locks and remote control units</mat-option>
                                            <mat-option value="laptops">Laptops</mat-option>
                                            <mat-option value="musicalInstruments">Musical instruments</mat-option>
                                            <mat-option value="musicalInstrumentsPro">Musical instruments professional</mat-option>
                                            <mat-option value="pedalCycles">Pedal cycles</mat-option>
                                            <mat-option value="personalDocuments">Personal documents</mat-option>
                                            <mat-option value="radio">Portable radio</mat-option>
                                            <mat-option value="tv">Portable TV</mat-option>
                                            <mat-option value="glasses">Prescription glasses</mat-option>
                                            <mat-option value="quadBike">Quad bike</mat-option>
                                            <mat-option value="sportEquipment">Sport equipment</mat-option>
                                            <mat-option value="sunglasses">Sunglasses</mat-option>
                                            <mat-option value="tablet">Tablet – cellular device</mat-option>
                                            <mat-option value="trackingDevice">Tracking device</mat-option>
                                            <mat-option value="watch">Watch – smart cellular device</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                    
                                        <div *ngIf="formSubmitted && allRiskItemFormGroup.get('itemCategory').errors" class="invalid-feedback">
                                          <div *ngIf="allRiskItemFormGroup.get('itemCategory').errors.required">Please select a category for your item.</div>
                                        </div>
                                        
                                      </div> <!-- Close div for .input-group-->
                                </div>

                                <!-- <div class="col-12 clearfix mb-3"></div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="specified">Is the item specified</label>
                                </div>

                                <div class="col-12 col-sm-7 col-md-8" >
                                    <div class="input-group">
                                        <mat-form-field class="form-control">
                                            <mat-select name="specified" formControlName="specified">
                                              <mat-option option value="" disabled>Please select an option</mat-option>
                                              <mat-option [value]="true">Yes</mat-option>
                                              <mat-option [value]="false">No</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </div>
                                </div> -->

                            </div> <!-- Close div for .mat-stepper-body -->

                            <div class="mat-stepper-footer">
                                <button class="btn btn-outline-secondary btn-bg" (click)="location.back()">Cancel</button>
                              <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                              <!-- <button *ngIf="isQuoteProduct" class="btn btn-success btn-bg float-right mr-0" (click)="editItem ? updateAllRiskItem() : addNewAllRiskItem()">Finish</button> -->
                            </div>

                        </div>

                    </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>Insurance</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body" formGroupName="insurance">

                        <div class="col-12">
                          <h5 class="mb-5"><strong>Add insurance to item</strong></h5>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="insuredValue">Insured value</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="insuredValue"
                                formControlName="insuredValue"
                                placeholder="Insured value"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>insured value</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <ng-container *ngIf="!isQuoteProduct">

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="premium">Premium</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="premium"
                                formControlName="premium"
                                placeholder="Premium"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>premium</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="policy">Policy number</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <div class="input-group">
                            <mat-form-field>
                              <mat-select formControlName="policyNumber">
                                <mat-option disabled *ngIf="policies?.length === 0 && allRiskItemFormGroup.get('insurance').get('policyNumber').value">
                                  <span>No policy found</span>
                              </mat-option>
                              <mat-option class="px-0 py-0" style="height: unset" *ngIf="policies?.length === 0 && allRiskItemFormGroup.get('insurance').get('policyNumber').value">
                                  <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                              </mat-option>
                                <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                  <span>{{ policy?.policyNumber }}</span>
                              </mat-option>
                              </mat-select>

                              <mat-error>
                                Please select a <strong>policy number</strong> for this item.
                              </mat-error>

                            </mat-form-field>
                          </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="inceptionDate">Item inception date</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="inceptionDate"
                                [matDatepicker]="inceptionDate"
                                name="inceptionDate"
                                formControlName="inceptionDate"
                            >
                            <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #inceptionDate></mat-datepicker>
      
                            <mat-error>
                                Please provide the <strong>item inception date</strong>.
                            </mat-error>
      
                          </mat-form-field>
                        </div>

                      </ng-container>

                      </div> <!-- Close div for .row-->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                        <button *ngIf="!view && canViewItem" class="btn btn-success btn-bg float-right mr-0" (click)="editItem ? updateAllRiskItem() : addNewAllRiskItem()">Finish</button>
                      </div>

                    </div>
                  
                  </div> <!-- Close div for .row -->

                </mat-step>
                
              </mat-horizontal-stepper>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->