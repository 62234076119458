<div class="container mb-5">

  <div class="row text-center">

    <div class="col-12 mt-3" *ngIf=!authenticated>
        <img width="150px" src="assets/images/logo.png">
    </div>

    <div class="col-12 mt-5">

      <h2 class="page-header text-center">&mdash; Our mission &mdash;</h2>
      <p class="text-center">
        To provide a secure online environment where you can store and keep track of all your insurance items.
      </p>
  
      <br />

      <h2 class="page-header">&mdash; About SureSpace &mdash;</h2>

      <p class="mb-0">
        Dealing with your insurance company might be putting you under a bit of pressure. <br>
          <br>
        Are you fully insured ? Do you know exactly what is covered? Did you not perhaps miss a few extremely important items when specifying valued items? Have you ever been told that you are over, or under insured when claiming?<br>
          <br>
        Ever been a victim of theft, and had to give information to the police about specific items? Or had to prove that items belonged to you after they were recovered?<br>
          <br>
        SureSpace will provide a secure environment where you can list all your items, in a manageable online database.
        List your items, edit, remove, add new items and have an up to date profile of your inventory. Have proof of any new purchase by adding a picture and an invoice to it.<br>
          <br>
        Remove or add items from your policy by simply updating your own personal profile, and your broker or insurance company can be notified instantaneously.
        Your insurance profile is no longer a guessing game. Both insurer and insured are fully aware of exactly what is insured, but you are now in control.
      </p>

    </div>

  </div> <!-- Close div for .row -->

</div> <!-- Close div for .container -->