<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
      <h6 class="d-inline-block mb-0">Bootstrap tabs</h6>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-6 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Basic Tabs</h5>
      </div>

      <div class="panel-body">

        <ngb-tabset>
          <ngb-tab title="Active">
            <ng-template ngbTabContent>
              <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee
              squid.
              <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Link">
            <ng-template ngbTabContent>
              <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                feugiat.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Icon tabs</h5>
      </div>

      <div class="panel-body">

        <ngb-tabset>
          <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-danger mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
              <p>
                Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
              </p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-error mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
              <p>
                Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                butcher vero
                sint qui sapiente accusamus tattooed echo park.
              </p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-success mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
              <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                feugiat.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab [disabled]="true">
            <ng-template ngbTabTitle><i class="fi flaticon-cancel mr-2"></i>Disabled</ng-template>
            <ng-template ngbTabContent>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Adjusted Tabs</h5>
      </div>

      <div class="panel-body">

        <ngb-tabset justify="justified">
          <ngb-tab title="Active">
            <ng-template ngbTabContent>
              <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>Link</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee
              squid.
              <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Link">
            <ng-template ngbTabContent>
              <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                feugiat.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

  <div class="col-6">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Pills</h5>
      </div>

      <div class="panel-body">

        <ngb-tabset type="pills">
            <ngb-tab title="Active">
            <ng-template ngbTabContent>
                <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
            </ngb-tab>
            <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee
                squid.
                <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
            </ngb-tab>
            <ngb-tab title="Link">
            <ng-template ngbTabContent>
                <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                feugiat.</p>
            </ng-template>
            </ngb-tab>
            <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
            </ng-template>
            </ngb-tab>
        </ngb-tabset>

        </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

    <div class="panel panel-primary">

        <div class="panel-heading">
            <h5>Pill icon tabs</h5>
        </div>

        <div class="panel-body">

        <ngb-tabset type="pills">
            <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-danger mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
                <p>
                Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
            </ng-template>
            </ngb-tab>
            <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-error mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
                <p>
                Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                butcher vero
                sint qui sapiente accusamus tattooed echo park.
                </p>
            </ng-template>
            </ngb-tab>
            <ngb-tab>
            <ng-template ngbTabTitle><i class="fi flaticon-success mr-2"></i>Active icon</ng-template>
            <ng-template ngbTabContent>
                <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                feugiat.</p>
            </ng-template>
            </ngb-tab>
            <ngb-tab [disabled]="true">
            <ng-template ngbTabTitle><i class="fi flaticon-cancel mr-2"></i>Disabled</ng-template>
            <ng-template ngbTabContent>
            </ng-template>
            </ngb-tab>
        </ngb-tabset>

        </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

    <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>Adjusted pills</h5>
            </div>
    
            <div class="panel-body">
    
            <ngb-tabset type="pills" justify="justified">
                <ngb-tab>
                <ng-template ngbTabTitle>Active</ng-template>
                <ng-template ngbTabContent>
                    <p>
                    Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
                    synth
                    master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
                    keffiyeh
                    dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                    salvia cillum
                    iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
                    </p>
                </ng-template>
                </ngb-tab>
                <ngb-tab>
                <ng-template ngbTabTitle>Link</ng-template>
                <ng-template ngbTabContent>
                    <p>
                    Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
                    farm-to-table
                    craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco
                    ad vinyl
                    cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS
                    salvia
                    yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                    sustainable jean
                    shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr
                    butcher vero
                    sint qui sapiente accusamus tattooed echo park.
                    </p>
                </ng-template>
                </ngb-tab>
                <ngb-tab>
                <ng-template ngbTabTitle>Link</ng-template>
                <ng-template ngbTabContent>
                    <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla
                    facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt
                    eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus
                    ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor
                    arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis. Morbi ut bibendum est, eu
                    tincidunt odio. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                    Mauris aliquet odio ac lorem aliquet ultricies in eget neque. Phasellus nec tortor vel tellus pulvinar
                    feugiat.</p>
                </ng-template>
                </ngb-tab>
                <ngb-tab [disabled]="true">
                <ng-template ngbTabTitle>Disabled</ng-template>
                <ng-template ngbTabContent>
                </ng-template>
                </ngb-tab>
            </ngb-tabset>
    
            </div> <!-- Close div for .panel-body -->
    
        </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

</div> <!-- Close div for .row -->
