import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { MyAccountService } from 'src/app/services/my-account.service';
import { DeleteFamilyMemberComponent } from 'src/app/components/modal/delete-family-member/delete-family-member.component';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-client-family',
  templateUrl: './client-family.component.html',
  styleUrls: ['./client-family.component.scss'],
  providers: [DeleteFamilyMemberComponent]
})
export class ClientFamilyComponent implements OnInit, AfterViewInit {

  familyMembers: FamilyMember[];

  currentUserPermissions: any[];
  currentUser = this.authenticationService.currentUserValue;

  constructor(
    private myAccountService: MyAccountService,
    private deleteFamilyMember: DeleteFamilyMemberComponent,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getFamilyMembers(){
    this.myAccountService.getAllFamilyMembers()
    .subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        this.familyMembers = response.body.family;
      }
    )
  };

  removeFamilyMember(member: FamilyMember){
    this.deleteFamilyMember.openDialog(member);
    this.deleteFamilyMember.dialogRef.afterClosed().subscribe(response => {
      // if(response && response === 'success'){
      //   this.router.navigate(['/u/me/my-family'])
      // }
      this.getFamilyMembers();
    })
  }

  ngAfterViewInit(){
    this.getFamilyMembers();
  }

  ngOnInit() {
  }

}
