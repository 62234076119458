<div class="container-fluid px-5">

    <div class="row text-center">
  
      <div class="col-12 mt-3">
        <img src="assets/images/logoicon.png" height="50" />
      </div>
  
      <div class="col-12 mt-5">
  
        <h2 class="page-header text-center">Questions? Look here!<br />
          <small class="text-muted">Can't find the answers you are looking for? Email us at questions@surespace.co.za.</small>
        </h2>
    
        <br />
  
        <div class="row mt-4 mb-5">

          <div class="col-lg-3 col-md-4 text-left">
            <h5>Table of contents</h5>

            <button
              class="btn btn-block"
              *ngFor="let category of categories"
              [ngClass]="selectedCategory == category?.id ? 'btn-primary' : 'btn-secondary'"
              (click)="selectedCategory = category?.id"
            >
              {{ category?.category }}
            </button>
          </div>

          <div class="col-lg-9 col-md-8">

            <mat-accordion class="example-headers-align">

              <ng-container *ngFor="let faq of questions">
                <mat-expansion-panel *ngIf="faq.category === selectedCategory">
                  <mat-expansion-panel-header>
                    <h5>{{ faq?.question }}</h5>
                  </mat-expansion-panel-header>

                  <mat-expansion-panel-body class="text-left">
                    <p [innerHTML]="faq?.answer"></p>
                  </mat-expansion-panel-body>
                  

                </mat-expansion-panel>
              </ng-container>

            </mat-accordion>

          </div>

        </div> <!-- Close div for .row -->
  
      </div>
  
    </div> <!-- Close div for .row -->
  
  </div> <!-- Close div for .container -->