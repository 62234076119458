<h2 mat-dialog-title>Upload Document</h2>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="documentFormGroup">

        <div class="row mt-3">
            <div class="col-12">
              <label for="documentName">Document name:</label>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Document name" formControlName="documentName" />
            </div>
          </div>

        <div class="row mt-3">
          <div class="col-12">
            <label for="accessDate">Document description:</label>
          </div>
          <div class="col-12">
            <textarea type="text" class="form-control" placeholder="Document description" formControlName="documentDescription"></textarea>
          </div>
        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-4 mb-1">
  <button class="btn btn-outline-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" (click)="editFileDetails()" cdkFocusInitial>Save</button>
</mat-dialog-actions>