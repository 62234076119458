<div class="example-container mat-elevation-z8">

    <div class="example-table-container">
  
      <table mat-table [dataSource]="userPersonalItems" matSort matSortActive="created" matSortDisableClear
        matSortDirection="desc">
  
        <!-- Item name Column -->
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef>Item name</th>
          <td mat-cell *matCellDef="let row">{{ row.item }}</td>
        </ng-container>
  
        <!-- Category Column -->
        <ng-container matColumnDef="itemCategory">
          <th mat-header-cell *matHeaderCellDef>Item category</th>
          <td mat-cell *matCellDef="let row">{{ row.itemCategory }}</td>
        </ng-container>
  
        <!-- Specified Column -->
        <ng-container matColumnDef="specified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Specified
          </th>
          <td mat-cell *matCellDef="let row">{{ row.specified }}</td>
        </ng-container>
  
        <!-- Insured Value Column -->
        <ng-container matColumnDef="insuredValue">
          <th mat-header-cell *matHeaderCellDef>Insured Value</th>
          <td mat-cell *matCellDef="let row">{{ row.insuredValue | currency: 'R ' }}</td>
        </ng-container>
  
        <!-- Menu Column -->
        <ng-container matColumnDef="menu">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <i class="fi flaticon-menu"></i>
            </button>
  
            <mat-menu #menu="matMenu">
              <button mat-menu-item
                [routerLink]="['/shortTermInsurance/personalItem/', ({id: row._id, r: this.router.url})]">
                <span>View item</span>
              </button>
  
              <button mat-menu-item
                [routerLink]="['/shortTermInsurance/personalItem/', ({id: row._id, r: this.router.url})]"
                [queryParams]="{'edit': 'true'}">
                <span>Edit item</span>
              </button>
  
              <button mat-menu-item (click)="deletePersonalItem(row)">
                <span>Delete item</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" (page)="getUserPersonalItem($event)">
    </mat-paginator>
  
  </div>