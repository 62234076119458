import { AuthenticationService } from 'src/app/services/authentication.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { IdNumberVerifyService } from './../../../services/id-number-verify.service';
import { BrokerComponent } from './../../broker.component';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from 'src/app/services/broker.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { User, UserResponse} from 'src/app/_shared/models/User.model';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';

class InvalidId implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-broker-employee-information',
  templateUrl: './broker-employee-information.component.html',
  styleUrls: ['./broker-employee-information.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerEmployeeInformationComponent implements OnInit, AfterViewInit {

  routerPath: string = this.activeRoute.routeConfig.path;
  editEmployee = this.routerPath === 'edit';
  viewEmployee = this.routerPath === 'view';

  formSubmitted = false;
  employeeFormGroup: FormGroup;
  get employeeForm(){ return this.employeeFormGroup.controls; }

  employeeId:string = this.activeRoute.snapshot.paramMap.get('id');
  employee: Employee[];

  validId: boolean = true;
  matcher = new InvalidId();

  companyLocations:any[] = [];

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    public location: Location,
    private activeRoute: ActivatedRoute,
    private brokerComponent: BrokerComponent,
    private verifyIdService: IdNumberVerifyService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  idValidator = (field:FormGroup) => {
    const condition = this.verifyIdService.validateIdNumber(field.value)
    const isValid = condition && condition.isValid;

    return !isValid ? { invalidIdNumber: true } : null;
  }

  addEmployee(saveType?:string){

    let verifyId: any = this.verifyIdService.validateIdNumber(this.employeeFormGroup.value['username']);
    this.validId = verifyId.isValid;

    this.formSubmitted = true;
    this.employeeFormGroup.markAllAsTouched();

    if (this.employeeForm.invalid || !this.validId) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.brokerService.addEmployee(this.brokerComponent.brokerCompany.companyName, this.employeeFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {
        this.detectSaveType(saveType)
      }
    );

  };

  saveEmployee(saveType?:string){

    let verifyId: any = this.verifyIdService.validateIdNumber(this.employeeFormGroup.value['username']);
    this.validId = verifyId.isValid;

    this.formSubmitted = true;
    this.employeeFormGroup.markAllAsTouched();

    if (this.employeeForm.invalid || !this.validId) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.brokerService.saveEmployee(this.brokerComponent.brokerCompany.companyName, this.employeeId, this.employeeFormGroup.value).subscribe(
      (response:HttpResponse<EmployeeResponse>) => {
        this.detectSaveType(saveType)
      }
    );

  };

  getEmployee(){
    this.brokerService.getEmployee(this.brokerComponent.brokerCompany.companyName, this.employeeId).subscribe(
      (response:HttpResponse<EmployeeResponse>) => {

        this.employee = response.body.user;

        // SET EMPLOYEE CURRENT COMPANY
        // this.employee['company'] = this.employee['company'].find(company => company.companyName === this.brokerComponent.brokerCompany.companyName)

        this.employeeFormGroup.patchValue(this.employee);

        //set the employee permission
        // this.employee['permissions'] = this.employee['permissions'].map(permission => {
        //   if(permission == 'brokerUser' || permission == 'brokerAdmin'){
        //     this.form['permissions'].setValue(permission)
        //   }
        // })
      }
    )
  }

  determineGender(){
    let verifyId: any = this.verifyIdService.validateIdNumber(this.employeeFormGroup.value['username']);
    this.employeeForm['gender'].setValue(verifyId.gender);

    this.checkTitle();
  }

  checkTitle(){

    let title = this.employeeForm['title'].value;
    let gender = this.employeeForm['gender'].value;

    if(!title || title === '')
      this.employeeForm['title'].setValue(gender === 'Male' ? 'Mr' : 'Mrs');

    if(title === 'Mr' && gender === 'Female')
      this.employeeForm['title'].setValue('Mrs');

    if(title === 'Mrs' || title === 'Miss' && gender === 'Male')
      this.employeeForm['title'].setValue('Mr');
  }

  ngAfterViewInit() {
    if(this.editEmployee || this.viewEmployee) this.getEmployee();
  }

  resetForm(){
    this.employeeFormGroup.reset();
  }

  detectSaveType(saveType){
    switch(saveType){
      case 'back':
        this.location.back();
        break;

      case 'new':
        this.employeeFormGroup.reset()

      case 'continue':
        // do nothing
        break;

      default:
        this.location.back();
        break;
    }
  }

  ngOnInit() {
    this.employeeFormGroup = this.formBuilder.group({
      firstName:        ['', Validators.required],
      lastName:         ['', Validators.required],
      gender:           [''],
      title:            [''],
      username:         ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), this.idValidator]],
      email:            ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z2-9.-]+\.[a-z]{2,4}$')]],
      cellphone:        ['', Validators.required],
      company:          this.formBuilder.group({
        startDate:        [''],
        employeeCode:     [''],
        permissions:      [[''], Validators.required],
        capacity:         [null, Validators.required],
        branch:           ['']
      }),
      userType:         ['broker'],
      language:         ['English']
    });

    this.getAllCompanyLocations();

  }

  getAllCompanyLocations(){
    this.brokerService.getCompanyAddress(this.authenticationService.currentCompany.companyName).subscribe(response => {
      console.log('response => ', response.body)
      this.companyLocations = response.body['locations'];
    })
  }

}
