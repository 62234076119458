import { Component, OnInit, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { HttpResponse } from '@angular/common/http';
import { DeleteBrokerComponent } from 'src/app/components/modal/delete/delete-broker/delete-broker.component';

@Component({
  selector: 'app-client-view-broker',
  templateUrl: './client-view-broker.component.html',
  styleUrls: ['./client-view-broker.component.scss'],
  providers: [DeleteBrokerComponent]
})
export class ClientViewBrokerComponent implements OnInit, AfterViewInit {

  brokers: Broker[];
  customBrokers: Broker[];
  insuranceCompanies: InsuranceCompany[];
  searchBroker:string;

  constructor(
    private insuranceService: InsuranceService,
    private deleteBroker: DeleteBrokerComponent
  ) { }

  getAllBrokers(query?:string, noLoad?:boolean){

    const req = {
      search: query || ''
    }

    this.insuranceService.getAllAssignedBrokers(req, noLoad).subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
        this.getCustomBrokers(req, noLoad);
      }
    )
  };

  getCustomBrokers(query?:any, noLoad?:boolean){
    this.insuranceService.getCustomBrokers(query, noLoad).subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.customBrokers = response.body.broker;
      }
    )
  }

  getAllInsuranceCompanies(){
    this.insuranceService.getAllInsuranceCompanies().subscribe(
      (response: HttpResponse<InsuranceCompanyResponse>) => {
        this.insuranceCompanies = response.body.insuranceCompany;
      }
    )
  };

  removeBroker(broker){
    this.deleteBroker.openDialog(broker);
    this.deleteBroker.dialogRef.afterClosed().subscribe(response => {
      if(response && response === 'success'){
        this.getAllBrokers()
      }
    })
  };

  // deleteInsuranceCompany(company){
  //   this.deleteModal.openDialog('insurance company', company);
  //   this.deleteModal.dialogRef.afterClosed()
  //   .subscribe(response => {
  //     if (response === 'success'){
  //       this.getAllInsuranceCompanies();
  //     }
  //   })
  // };

  ngOnInit() {}

  ngAfterViewInit() {
    this.getAllBrokers();
  }

}
