import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  formSubmitted = false;
  registerFormGroup: FormGroup;

  get form(){ return this.registerFormGroup.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) { }

  forgotPassword(){

    this.formSubmitted = true;

    // stop here if form is invalid
    if (this.registerFormGroup.invalid) {
      this.toastr.error('There were some errors on your form.  Please confirm everything is correct and try again.', 'Could not reset password.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.authenticationService.forgotPassword(this.registerFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {
      }
    )
  }

  ngOnInit() {
    this.registerFormGroup = this.formBuilder.group({
      email:            ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z2-9.-]+\.[a-z]{2,4}$')]],
    });
  }

}
