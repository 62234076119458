import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { ValueAddedProduct, ValueAddedProductResponse } from 'src/app/_shared/models/vaps.model';

@Component({
  selector: 'select-new-policy-items-value-added-prodducts',
  templateUrl: './select-new-policy-items-value-added-prodducts.component.html',
  styleUrls: ['./select-new-policy-items-value-added-prodducts.component.scss']
})
export class SelectNewPolicyItemsValueAddedProdductsComponent implements OnInit {

  @Input() clientId:string;
  @Input() companyName:string;
  @Input() selection;

  displayedColumns: string[] = ['select', 'product', 'productType', 'premium'];
  policyItems = new MatTableDataSource<ValueAddedProduct>();

  constructor(
    private vapsService: ValueAddedProductsService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientProducts()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.policyItems.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.policyItems.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getClientProducts(){
    this.vapsService.getAllVapsForClient(this.clientId, this.companyName).subscribe(
      (response:HttpResponse<ValueAddedProductResponse>) => {
        this.policyItems = new MatTableDataSource<ValueAddedProduct>(response.body.products);
      }
    )
  }

}
