<div class="page-description">
    <div class="row h-100">
        <div class="col-6 my-auto">
            <h5 class="d-inline-block mb-0 font-weight-400">Structure insurance</h5>
            <h6 class="d-inline-block mb-0"></h6>
        </div>

        <div class="col-6 text-right my-auto">

            <button [routerLink]="['new']" type="button" class="btn btn-primary" matTooltip="Add a new insured structure to your insurance.">
                Add structrue
            </button>

        </div> <!-- Close div for .col-6 -->

    </div>  <!-- Close div for .row -->

</div> <!-- Close div for .page-description -->

<div class="data-table" [ngClass]="{'d-none': structures?.length === 0}">

        <table mat-table [dataSource]="structures" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
      
          <!-- Number Column -->
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="table-image table-image-rounded">
                <img [src]="row?.selectedPhoto" class="img-fluid" />
              </div>
            </td>
          </ng-container>
    
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Structure name</th>
            <td mat-cell *matCellDef="let row" data-label="Structure name">{{ row?.name }}</td>
          </ng-container>
    
          <!-- Address Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row" data-label="Address">{{ row?.city }}, {{ row?.suburb }}</td>
          </ng-container>

          <!-- Insured With Column -->
          <ng-container matColumnDef="insuredWith">
            <th mat-header-cell *matHeaderCellDef>Insured with</th>
            <td mat-cell *matCellDef="let row" data-label="Insured with">{{ row?.insuredWith }}</td>
            </ng-container>
    
          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row" data-label="Action">
                <button class="color-info btn btn-icon btn-label-hover-info mr-0" [matMenuTriggerFor]="menu">
                  <i class="fi flaticon-menu fi-rotate-90"></i>
                </button>
        
                  <mat-menu #menu="matMenu" xPosition="before">
                    <!-- <button mat-menu-item [routerLink]="['view/', ({ registration: row.registrationNumber })]">
                      <span>View structure</span>
                    </button> -->
    
                    <button mat-menu-item [routerLink]="['edit/', ({ id: row._id })]">
                      <span>Edit structure</span>
                    </button>
      
                    <button mat-menu-item (click)="deleteStructure(row)">
                      <span>Delete structure</span>
                    </button>
                  </mat-menu>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <div class="row table-footer">
          <div class="col-12 col-sm-6 my-auto">
            <ngb-pagination [collectionSize]="resultsLength" [(page)]="page" [boundaryLinks]="true" [pageSize]=pageSize (pageChange)="getAllInsuredStructures(false, $event)">
              <ng-template ngbPaginationFirst><i class="fi fi-sm flaticon-caret fi-rotate-90"></i><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
              <ng-template ngbPaginationPrevious><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
              <ng-template ngbPaginationNext><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
              <ng-template ngbPaginationLast><i class="fi fi-sm flaticon-caret fi-rotate-270"></i><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
            </ngb-pagination>
          </div>
    
          <div class="col-12 col-sm-6 my-auto">
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
          </div>
        </div> <!-- Close div for .row -->
    
      </div> <!-- Close div for .data-table -->