<div id="content" class="row">

    <sub-navigation heading="Dashboard"></sub-navigation>

    <div class="col-12 mb-4">
        <h1>Hey {{ currentUser?.firstName}}, welcome to SureSpace</h1>
    </div>

    <div class="col-md-9 col-sm-8 col-12">

        <div class="row">

            <div class="col-xl-3 col-lg-4">
                <div class="panel panel-secondary pt-4">

                    <div class="panel-body">
                        <div class="row">    
                            <div ngClass="col-12">
                                <h3 class="color-darker-grey font-weight-500">
                                    {{ totalUserInstructions }}
                                    <sup matTooltip="Total company instructions"> / {{ totalCompanyInstructions }}</sup>
                                </h3>
                                <small class="my-0" style="opacity: .8">Total instructions</small>
                            </div>
                        </div> <!-- Close div for .row -->
                    </div>

                </div>
            </div>

            <!-- --------- -->

            <div class="col-xl-3 col-lg-4">
                <div class="panel panel-secondary pt-4">

                    <div class="panel-body">
                        <div class="row">    
                            <div ngClass="col-12">
                                <h3 class="color-darker-grey font-weight-500">
                                    {{ totalUserClaims }}
                                    <sup matTooltip="Total company claims"> / {{ totalCompanyClaims }}</sup>
                                </h3>
                                <small class="my-0" style="opacity: .8">Total claims</small>
                            </div>
                        </div> <!-- Close div for .row -->
                    </div>

                </div>
            </div>

            <!-- --------- -->

            <div class="col-xl-3 col-lg-4">
                <div class="panel panel-secondary pt-4">

                    <div class="panel-body">
                        <div class="row">    
                            <div ngClass="col-12">
                                <h3 class="color-darker-grey font-weight-500">
                                    {{ totalUserPolicies }}
                                    <sup matTooltip="Total company policies"> / {{ totalCompanyPolicies }}</sup>
                                </h3>
                                <small class="my-0" style="opacity: .8">Total policies</small>
                            </div>
                        </div> <!-- Close div for .row -->
                    </div>

                </div>
            </div>

            <!-- --------- -->

            <div class="col-xl-3 col-lg-4">
                <div class="panel panel-secondary pt-4">

                    <div class="panel-body">
                        <div class="row">    
                            <div ngClass="col-12">
                                <h3 class="color-darker-grey font-weight-500">
                                    {{ totalUserQuotes }}
                                    <sup matTooltip="Total company quotes"> / {{ totalCompanyQuotes }}</sup>
                                </h3>
                                <small class="my-0" style="opacity: .8">Total quotes</small>
                            </div>
                        </div> <!-- Close div for .row -->
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
        <div class="panel panel-secondary pt-4">
            <div class="panel-body text-center">
                <div class="row">
                    <div class="col-12">
                        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                    </div>
                </div> <!-- Close div for .row -->
            </div>
        </div>
    </div>



    <!-- <div class="col-md-4 col-sm-6 col-12">
        <div class="panel panel-secondary pt-4">
            <div class="panel-body text-center">
                <div class="row">

                    <div class="col-6 col-sm-12 col-md-12 col-lg-6" *ngIf="isCompanyAdmin()">
                        <h2 class="color-darker-grey font-weight-500">{{ totalCompanyInstructions }}</h2>
                        <p class="my-0" style="opacity: .8">Total company instructions</p>
                    </div>

                    <div class="col-sm-12 d-none d-block-sm d-block-lg mt-3" *ngIf="isCompanyAdmin()"></div>

                    <div [ngClass]="isCompanyAdmin() ? 'col-6 col-sm-12 col-md-12 col-lg-6' : 'col-12'">
                        <h2 class="color-darker-grey font-weight-500">{{ totalUserInstructions }}</h2>
                        <p class="my-0" style="opacity: .8">Total instructions</p>
                    </div>

                </div>
            </div>
            <div class="panel-footer">
                <button class="btn btn-primary btn-block" [routerLink]="['/b/instructions']">View all</button>
            </div>
        </div>
    </div>

    <div class="col-md-4 col-sm-6 col-12">
        <div class="panel panel-secondary pt-4">
            <div class="panel-body text-center">
                <div class="row">

                    <div class="col-6 col-sm-12 col-md-12 col-lg-6" *ngIf="isCompanyAdmin()">
                        <h2 class="color-darker-grey font-weight-500">{{ totalCompanyClaims }}</h2>
                        <p class="my-0" style="opacity: .8">Total Company Claims</p>
                    </div>

                    <div class="col-sm-12 d-none d-block-sm d-block-lg mt-3" *ngIf="isCompanyAdmin()"></div>

                    <div [ngClass]="isCompanyAdmin() ? 'col-6 col-sm-12 col-md-12 col-lg-6' : 'col-12'">
                        <h2 class="color-darker-grey font-weight-500">{{ totalUserClaims }}</h2>
                        <p class="my-0" style="opacity: .8">Total Claims</p>
                    </div>

                </div>
            </div>
            <div class="panel-footer">
                <button class="btn btn-primary btn-block" [routerLink]="['/b/claims']">View all</button>
            </div>
        </div>
    </div>

    <div class="col-md-4 col-sm-6 col-12">
        <div class="panel panel-secondary pt-4">
            <div class="panel-body text-center">
                <div class="row">

                    <div class="col-6 col-sm-12 col-md-12 col-lg-6" *ngIf="isCompanyAdmin()">
                        <h2 class="color-darker-grey font-weight-500">{{ totalCompanyLeads }}</h2>
                        <p class="my-0" style="opacity: .8">Total Company Leads</p>
                    </div>

                    <div class="col-sm-12 d-none d-block-sm d-block-lg mt-3" *ngIf="isCompanyAdmin()"></div>

                    <div [ngClass]="isCompanyAdmin() ? 'col-6 col-sm-12 col-md-12 col-lg-6' : 'col-12'">
                        <h2 class="color-darker-grey font-weight-500">{{ totalUserLeads }}</h2>
                        <p class="my-0" style="opacity: .8">Total Leads</p>
                    </div>

                </div>
            </div>
            <div class="panel-footer">
                <button class="btn btn-primary btn-block" [routerLink]="['/b/clients/personal']" [queryParams]="{'status': 'Lead'}">View all</button>
            </div>
        </div>
    </div> -->

    <!-- <div *ngIf="isBrokerAdmin" class="col-md-3 col-sm-6 col-12">
        <div class="panel panel-secondary">
            <div class="panel-body pt-4">
                <h4 class="color-darker-grey font-weight-500">{{ totalUsers }}</h4>
                <p style="opacity: .8">Total company users</p>
            </div>
        </div>
    </div>

    <div *ngIf="isBrokerAdmin" class="col-md-3 col-sm-6 col-12">
        <div class="panel panel-secondary">
            <div class="panel-body pt-4">
                <h4 class="color-darker-grey font-weight-500">{{ totalCompanyInvestments }}</h4>
                <p style="opacity: .8">Total company investments</p>
            </div>
        </div>
    </div> -->

</div>
<!-- Close div for #content -->
