import { FamilyMemberResponse } from './../_shared/models/familyMember.model';
import { PolicyResponse, Policy } from 'src/app/_shared/models/policy.model';
import { Employee } from 'src/app/_shared/models/employeeModel';
import { User, UserResponse, Company } from 'src/app/_shared/models/User.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { StructureResponse } from 'src/app/_shared/models/structure.model';
import { BrokerResponse } from 'src/app/_shared/models/broker.model';
import { EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { NotificationResponse } from 'src/app/_shared/models/notifications.model';
import { UserRequestResponse } from 'src/app/_shared/models/userRequest.interface';
import { ActivityLog, ActivityLogResponse } from '../_shared/models/activityLogs.model';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getUserRequests(userId: string, companyName:string): Observable<HttpResponse<UserRequestResponse>> {
    return this.http.get<UserRequestResponse>(`${this.globals.SERVER_URL}/api/broker/requests/user/${userId}/company/${companyName}`, {
      observe: 'response'
    });
  }

  getSingleRequest(requestId): Observable<HttpResponse<UserRequestResponse>> {
    return this.http.get<UserRequestResponse>(`${this.globals.SERVER_URL}/api/broker/requests/request/${requestId}`, {
      observe: 'response'
    });
  }

  handleRequest(id:string, status: string, requestReason = {}): Observable<HttpResponse<UserRequestResponse>> {
    return this.http.put<UserRequestResponse>(`${this.globals.SERVER_URL}/api/broker/requests/${status}/${id}`, requestReason, {
      observe: 'response'
    });
  }
}
