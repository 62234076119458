import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable } from 'rxjs';
import { HomeResponse, Home } from '../_shared/models/homeInsurance.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerClientHomeService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientHomeInsurance(clientId:string, homeFormData:Home, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.post<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}`, homeFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientHomeQuote(clientId:string, companyName: string, homeFormData:Home, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.post<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/company/${companyName}/quote`, homeFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientHomeQuote(clientId:string, structureId:string, companyName: string, homeFormData:Home, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.put<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/company/${companyName}/quote/${structureId}`, homeFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllHomeInsuranceForClient(clientId:string, companyName: string, req:any = {}): Observable<HttpResponse<HomeResponse>>{
    return this.http.get<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousHomeInsuranceForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.get<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/previouslyInsured/client/${clientId}/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleHomeInsuranceForClient(clientId:string, homeId:string, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.get<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/home/${homeId}`, {
      observe: 'response',
      params: req
    })
  }

  updateClientHomeInsurance(clientId:string, homeId:string, homeFormData:Home, req?:any): Observable<HttpResponse<HomeResponse>>{
    return this.http.put<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/${homeId}`, homeFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteInsuranceFromHome(clientId:string, homeId:string): Observable<HttpResponse<HomeResponse>>{
    return this.http.delete<HomeResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/home/client/${clientId}/${homeId}`, {
      observe: 'response'
    })
  }

}