<div class="mb-5 col-md-4 col-sm-6 col-6">
    <mat-form-field>
    <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="returnedDebitSearch" (ngModelChange)="findAllReturnedDebits($event, true)"/>
    <mat-hint>
        Search in all fields.
    </mat-hint>
    </mat-form-field>
</div>

<div class="col-md-8 col-sm-6 col-6 mb-4">
    <p class="d-inline-block mr-3"><strong>Filter returned debit orders</strong></p>
    <button class="btn btn-sm btn-pill" (click)="returnedDebitSearchStatus = 'incomplete'; findAllReturnedDebits()" [ngClass]="returnedDebitSearchStatus == 'incomplete' ? 'btn-info' : 'btn-label-info'">Incomplete</button>
    <button class="btn btn-sm btn-pill" (click)="returnedDebitSearchStatus = 'completed'; findAllReturnedDebits()" [ngClass]="returnedDebitSearchStatus == 'completed' ? 'btn-info' : 'btn-label-info'">Completed</button>
    <button class="btn btn-sm btn-pill" (click)="returnedDebitSearchStatus = 'all'; findAllReturnedDebits()" [ngClass]="returnedDebitSearchStatus == 'all' ? 'btn-info' : 'btn-label-info'">All</button>
</div>

<table mat-table [dataSource]="returnedDebits" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

    <!-- Client Column -->
    <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" data-label="Client">{{row?.client.companyName}}{{ row?.client?.firstName }} {{ row?.client?.lastName }}</td>
    </ng-container>


    <!-- Reference Number Column ->
    <ng-container matColumnDef="refNr">
        <th mat-header-cell *matHeaderCellDef>Ref number</th>
        <td mat-cell *matCellDef="let row" data-label="Ref number">{{ row?.refNr }}</td>
    </ng-container>-->

    <!-- Client Number Column -->
    <ng-container matColumnDef="clientNr">
        <th mat-header-cell *matHeaderCellDef>Client number</th>
        <td mat-cell *matCellDef="let row" data-label="Client number">{{ row?.client?.clientNumber }}</td>
    </ng-container>

        <!-- Reference Number Column -->
        <ng-container matColumnDef="refNr">
        <th mat-header-cell *matHeaderCellDef>Reference number</th>
        <td mat-cell *matCellDef="let row" data-label="Reference number">{{ row?.referenceNumber }}</td>
    </ng-container>

    <!-- Admin RD Column -->
    <ng-container matColumnDef="policy">
        <th mat-header-cell *matHeaderCellDef>Policy Number</th>
        <td mat-cell *matCellDef="let row" data-label="Policy Number">{{ row?.policyNumber | sentenceCase }}</td>
    </ng-container>

    <!-- Admin Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" data-label="Status">{{ row?.currentStep | sentenceCase }}</td>
    </ng-container>

    <!-- Admin RD Approved Date Column -->
    <ng-container matColumnDef="dateApproved">
        <th mat-header-cell *matHeaderCellDef>Signed Off</th>
        <td mat-cell *matCellDef="let row" data-label="Signed Off">
            <span *ngIf="row?.returnedDebitCompleted?.value">{{ row?.returnedDebitCompleted?.completedBy?.firstName }} {{ row?.returnedDebitCompleted?.completedBy?.lastName }}<br /><small class="text-muted">on {{ row?.returnedDebitCompleted?.dateCompleted | date: "dd MMM yyyy 'at' HH:mm" }}</small></span>
            <span *ngIf="!row?.returnedDebitCompleted?.value">Not signed off</span>
        </td>
    </ng-container>

    <!-- View Column -->
    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" data-label="Actions">
            <button
                *ngIf="currentUserPermissions.includes('brokerAdmin') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')"
                class="btn btn-light btn-pill color-brand"
                matTooltip="Edit returned debit order"
                [routerLink]="['/b/returned-debits/edit/', row._id]"
            >
                <i class="fi flaticon-edit mr-0"></i>
        </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<p class="mt-3 text-center" *ngIf="returnedDebits?.length === 0">No returned debit orders found</p>

<mat-paginator [hidden]="returnedDebits?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>