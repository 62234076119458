<div mat-dialog-content class="text-center">

    <img src="https://image.flaticon.com/icons/svg/136/136552.svg" class="mt-5" width="150px" >

    <h3 class="mx-3 my-5">
        It seems that we do not have a preview of this document that we can show you.
    </h3>
</div>
<div mat-dialog-footer class="text-center">
    <a class="btn btn-success" [href]="data?.document?.documentFile" target="_blank" download>Download attachment</a>
</div>