import { AuthenticationService } from './../../services/authentication.service';
import { BrokerAdministratorService } from 'src/app/services/broker-administrator.service';
import { BrokerAdministratorResponse } from './../../_shared/models/brokerAdministrator.model';
import { BrokerAdministrator } from 'src/app/_shared/models/brokerAdministrator.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Globals } from 'src/app/app.globals';

@Component({
  selector: 'app-broker-administrators',
  templateUrl: './broker-administrators.component.html',
  styleUrls: ['./broker-administrators.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerAdministratorsComponent implements OnInit, AfterViewInit {

  administrators: BrokerAdministrator[];
  searchAdministrator: string;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public globals: Globals,
    private brokerAdministratorService: BrokerAdministratorService,
    private router: Router,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getAdministrators() {
    this.brokerAdministratorService.getAllAdministrators(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<BrokerAdministratorResponse>) => {
        this.administrators = response.body.administrator;
      }
    )
  }

  deleteAdministrator(administratorId:string) {
    this.brokerAdministratorService.deleteAdministrator(administratorId).subscribe(
      (response: HttpResponse<BrokerAdministratorResponse>) => {
        this.administrators = this.administrators.filter(admin => admin._id != administratorId)
      }
    )
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAdministrators();
  }

}

