import { AuthenticationService } from './../../../services/authentication.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommercialClientsService } from 'src/app/services/commercial-clients.service';

@Component({
  selector: 'app-broker-commercial-client-delete',
  templateUrl: './broker-commercial-client-delete.component.html',
  styleUrls: ['./broker-commercial-client-delete.component.scss']
})
export class BrokerCommercialClientDeleteComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BrokerCommercialClientDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commercialClientService: CommercialClientsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    console.log('data => ', this.data)
  }

  removeClient(){
    this.commercialClientService.removeClient(this.data._id, this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.dialogRef.close('success')
    })

  }

}
