<mat-tab-group>

    <form [formGroup]="clientInformationFormGroup">

        <mat-tab label="Company information">
        <div class="row">

            <div id="basicInformation" class="col-12">

            <p><strong>Company information</strong></p>

                <div class="row">

                    <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                            <input matInput name="companyName" placeholder="Company name" type="text" formControlName="companyName" />
                            <mat-error>
                                Please enter <strong>company name</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                            <input matInput placeholder="Tax number" name="taxNumber" type="text" formControlName="taxNumber" />
                            <mat-error>
                                Please enter <strong>tax number</strong>.
                            </mat-error>
                        </mat-form-field>  
                    </div>

                    <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                            <input matInput placeholder="Registration number" name="companyRegistrationNumber" type="text" formControlName="companyRegistrationNumber" />
                            <mat-error>
                                Please enter <strong>registration number</strong>.
                            </mat-error>
                        </mat-form-field>  
                    </div>

                    <div class="col-12">
                        <hr />
                    </div>

                    <ng-container class="row" formGroupName="physicalAddress">

                        <div class="col-12">
                            <mat-form-field>
                                <input
                                    ngx-google-places-autocomplete
                                    matInput
                                    #placesRef="ngx-places"
                                    (onAddressChange)="handleAddressChange($event)"
                                    formControlName="addressLine1"
                                    [options]="addressOptions"
                                    placeholder="Address"
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="suburb"
                                    formControlName="suburb"
                                    placeholder="Suburb"
                                >
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="city"
                                    formControlName="city"
                                    placeholder="City"
                                > 
                            </mat-form-field>
                            
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="postalCode"
                                    formControlName="postalCode"
                                    placeholder="Postal Code"
                                >
                            </mat-form-field>
                        </div>

                    </ng-container>

                    <div class="col-12">
                        <hr />
                    </div>

                    <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                        <mat-select placeholder="Language" name="language" formControlName="language">
                            <mat-option value="" disabled read-only>
                            Please select a language
                            </mat-option>
                            <mat-option value="Afrikaans">Afrikaans</mat-option>
                            <mat-option value="English">English</mat-option>
                            <mat-option value="Zulu">Zulu</mat-option>
                            <mat-option value="Xhosa">Xhosa</mat-option>
                            <mat-option value="Venda">Venda</mat-option>
                            <mat-option value="Southern Sotho">Southern Sotho</mat-option>
                            <mat-option value="Tswana">Tswana</mat-option>
                            <mat-option value="Northern Sotho">Northern Sotho</mat-option>
                            <mat-option value="Tsongo">Tsongo</mat-option>
                            <mat-option value="Swati">Swati</mat-option>
                            <mat-option value="Ndebele">Ndebele</mat-option>
                        </mat-select>

                        <mat-error>
                            Please select the user <strong>language</strong>.
                        </mat-error>

                        </mat-form-field>
                    </div>

                </div> <!-- Close div for .row -->

            </div> <!-- Close div for .col-12 -->

        </div> <!-- Close div for .row-->
        </mat-tab>

        <mat-tab label="Contact information">
        <div class="row"> 

            <div id="contactInformation" class="col-12">

            <p><strong>Contact information</strong></p>

                <div class="row">

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                        <input matInput placeholder="Email" name="email" type="email" formControlName="email" />

                        <mat-error *ngIf="clientInformationFormGroup.get('email').errors && clientInformationFormGroup.get('email').errors.required">
                            Please enter a <strong>email address</strong> for this user.
                        </mat-error>
                        <mat-error *ngIf="clientInformationFormGroup.get('email').errors && clientInformationFormGroup.get('email').errors.email">
                            Please enter a <strong>valid</strong> email address.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <hr />
                </div>

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                    <input matInput placeholder="Contact name" name="contactPersonName" type="text" formControlName="contactPersonName" />

                    <mat-error>
                        Please enter a <strong>contact name</strong> for this client.
                    </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                    <input matInput placeholder="Contact surname" name="contactPersonSurname" type="text" formControlName="contactPersonSurname" />

                    <mat-error>
                        Please enter a <strong>contact surname</strong> for this client.
                    </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                    <input matInput placeholder="Contact number" name="contactPhone" type="text" formControlName="contactPhone" />
                    </mat-form-field>
                </div>

                </div> <!-- Close div for .row -->

            </div> <!-- Close div for .col-12 -->

        </div> <!-- Close div for .row-->
        </mat-tab>

        <mat-tab label="Insurance information">

        <p><strong>Consultant information</strong></p>

            <div class="row" formGroupName="broker">

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                    <mat-select placeholder="Consultant" name="consultant" formControlName="_id">
                        <mat-option value="" disabled read-only>
                        Please select a consultant
                        </mat-option>
                        <mat-option *ngFor="let employee of employees" [value]="employee._id">
                            {{ employee.firstName }} {{ employee.lastName }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                    <mat-select placeholder="Client status" name="clientStatus" formControlName="clientStatus">
                        <mat-option value="" disabled read-only>
                            Please select client status
                        </mat-option>
                        <mat-option value="lead">
                            Lead
                        </mat-option>
                        <mat-option value="quote">
                            Quote
                        </mat-option>
                        <mat-option value="active">
                            Active
                        </mat-option>
                        <mat-option value="cancelled">
                            Cancelled
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>

            </div> <!-- Close div for .row -->

        </mat-tab>

    </form>

    <ng-container *ngIf="inEditMode">

        <mat-tab label="Documents">
            <ng-template matTabContent>
                <broker-view-client-documents></broker-view-client-documents>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Notes" [hidden]="currentUserPermissions.includes('brokerClaimsTechnician')" >
            <ng-template matTabContent>
                <broker-view-user-notes [newestNote]="newestNote"></broker-view-user-notes>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Policies">
            <ng-template matTabContent>
                <broker-view-user-policies></broker-view-user-policies>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Instructions" [hidden]="currentUserPermissions.includes('brokerClaimsTechnician')" >
            <ng-template matTabContent>
                <broker-view-client-instructions></broker-view-client-instructions>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Claims">
            <ng-template matTabContent>
                <broker-view-client-claims></broker-view-client-claims>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Quotes">
            <ng-template matTabContent>
                <broker-view-user-quotes></broker-view-user-quotes>
            </ng-template>
        </mat-tab>
    
        <mat-tab label="Returned debit orders">
            <ng-template matTabContent>
                <broker-view-user-returned-debit></broker-view-user-returned-debit>
            </ng-template>
        </mat-tab>

    </ng-container>

</mat-tab-group>