<div class="col-md-8 col-sm-6 col-6 d-inline-block mb-3">
  <button class="btn btn-sm btn-pill" (click)="view = 'current'" [ngClass]="view == 'current' ? 'btn-info' : 'btn-label-info'">Currently insured</button>
  <button class="btn btn-sm btn-pill" (click)="view = 'previous'" [ngClass]="view == 'previous' ? 'btn-info' : 'btn-label-info'">Previously insured</button>
</div>

<ng-container *ngIf="view === 'current'">

  <div class="example-table-container">
    <table mat-table [dataSource]="userHomeContent" matSort matSortActive="created" matSortDisableClear
      matSortDirection="desc">

      <!-- Item name Column -->
      <ng-container matColumnDef="structureType">
        <th mat-header-cell *matHeaderCellDef>Structure type</th>
        <td mat-cell *matCellDef="let row">{{ row?.residenceType | sentenceCase }}</td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="structureAddress">
        <th mat-header-cell *matHeaderCellDef>Structure address</th>
        <td mat-cell *matCellDef="let row">{{ row?.address?.formattedAddress }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="contentValue">
        <th mat-header-cell *matHeaderCellDef>Content Value</th>
        <td mat-cell *matCellDef="let row">{{ row?.insurance?.contentValue | currency: 'R ' }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="jeweleryValue">
        <th mat-header-cell *matHeaderCellDef>Jewelery Value</th>
        <td mat-cell *matCellDef="let row">{{ row?.insurance?.jeweleryValue | currency: 'R ' }}</td>
      </ng-container>

      <ng-container matColumnDef="inceptionDate">
        <th mat-header-cell *matHeaderCellDef>Inception date</th>
        <td mat-cell *matCellDef="let row" data-label="Inception date">{{ row?.insurance?.inceptionDate | date: 'dd MMM yyyy' }}</td>
      </ng-container>

      <!-- Menu Column -->
      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" data-label="Actions">
          <ng-container *ngIf="canViewItem" >
            <button class="btn btn-light btn-pill color-brand" matTooltip="Edit content" [routerLink]="['short-term-insurance/homeContent/edit/', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
            <button class="btn btn-light btn-pill color-danger" matTooltip="Delete content" (click)="deleteContent(row)"><i class="fi flaticon-trash mr-0"></i></button>
          </ng-container>
      </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getUserHouseholdItem($event)">
  </mat-paginator>

</ng-container>

<ng-container *ngIf="view === 'previous'">

  <div class="example-table-container">
    <table mat-table [dataSource]="previouslyInsuredUserHomeContent" matSort matSortActive="created" matSortDisableClear
      matSortDirection="desc">

      <!-- Item name Column -->
      <ng-container matColumnDef="structureType">
        <th mat-header-cell *matHeaderCellDef>Structure type</th>
        <td mat-cell *matCellDef="let row">{{ row?.item?._id?.residenceType | sentenceCase }}</td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="structureAddress">
        <th mat-header-cell *matHeaderCellDef>Structure address</th>
        <td mat-cell *matCellDef="let row">{{ row?.item?._id?.address?.formattedAddress }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="contentValue">
        <th mat-header-cell *matHeaderCellDef>Content Value</th>
        <td mat-cell *matCellDef="let row">{{ row?.item?.contentValue | currency: 'R ' }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="jeweleryValue">
        <th mat-header-cell *matHeaderCellDef>Jewelery Value</th>
        <td mat-cell *matCellDef="let row">{{ row?.item?.jeweleryValue | currency: 'R ' }}</td>
      </ng-container>

      <ng-container matColumnDef="inceptionDate">
        <th mat-header-cell *matHeaderCellDef>Termination date</th>
        <td mat-cell *matCellDef="let row" data-label="Termination date">{{ row?.item?.insurance?.removedDate | date: 'dd MMM yyyy' }}</td>
    </ng-container>

      <!-- Menu Column -->
      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" data-label="Actions">
          <button class="btn btn-light btn-pill color-brand" matTooltip="View content" [routerLink]="['short-term-insurance/homeContent/edit/', row._id]" [view]="true"><i class="fi flaticon-edit mr-0"></i></button>
          <!--<button class="btn btn-light btn-pill color-danger" matTooltip="Delete content" (click)="deleteContent(row)"><i class="fi flaticon-trash mr-0"></i></button> -->
      </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLengthPreviousInsurance" [pageSize]="pageSizePreviousInsurance" [pageSizeOptions]="pageSizeOptions" (page)="getUserHouseholdItem($event)">
  </mat-paginator>

</ng-container>