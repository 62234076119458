<div id="content" class="row">

  <sub-navigation heading="SureSpace settings"></sub-navigation>

  <div class="col-12">

    <div class="panel panel-primary">

      <div class="panel-heading">

        <h5>Settings</h5>

        <!--<div class="my-auto">

          <button matTooltip="Go back to view clients" class="btn btn-secondary my-auto" (click)="location.back()"><i class="fi flaticon-back"></i>Back</button>
          <button
            matTooltip="Add new client and go back"
            class="btn btn-brand my-auto"
            (click)="addClient('back')"
            >
              Add client
          </button>

        </div> <!-- Close div for .my-auto -->

      </div> <!-- Close div for .panel-heading -->

      <div class="panel-body pb-5">

        <mat-tab-group>

          <mat-tab label="My password">

            <form id="newVehicleForm" [formGroup]="resetPasswordFormGroup" (ngSubmit)="resetPassword()">

            <p><strong>Change my password</strong></p>

            <!-- Current Password -->
            <div class="row my-2">

              <div class="col-12 col-md-8">
                <mat-form-field>
                  <input matInput name="currentPassword" placeholder="Current password" type="password" [value]="resetPasswordFormGroup.get('password').value" formControlName="password"  />
                  <mat-error *ngIf="resetPasswordFormGroup.get('password').errors && resetPasswordFormGroup.get('password').errors.required">
                    We need your <strong>current password</strong> to be able to change it.
                  </mat-error>
                </mat-form-field>
              </div>

            </div> <!-- Close div for .row -->

            <hr class="hr-dashed hr-small" />

            <!-- Password -->
            <div class="row my-2">

              <div class="col-12 col-md-8">
                <mat-form-field>
                  <input #newPassword matInput name="newPassword" placeholder="New password" type="password" [value]="resetPasswordFormGroup.get('newPassword').value" formControlName="newPassword"  />
                  <mat-error *ngIf="resetPasswordFormGroup.get('newPassword').errors && resetPasswordFormGroup.get('newPassword').errors.required">
                    Please provide a <strong>new password</strong>.
                  </mat-error>
                  <mat-error *ngIf="resetPasswordFormGroup.get('newPassword').errors && resetPasswordFormGroup.get('newPassword').errors.minLength">
                    New password must be at least <strong>8 characters</strong> long.
                  </mat-error>
                </mat-form-field>

                <!-- <mat-password-strength
                  #passwordStengthMeter
                  (onStrengthChanged)="onStrengthChanged($event)"
                  [password]="newPassword.value">
                </mat-password-strength>

                <p class="color-dark-grey smallest mt-1">
                  <small>To conform with our Strong Password policy, you are required to use a
                  sufficiently strong password. Password must be more than 8 characters.</small>
                </p>

                <mat-password-strength-info
                  *ngIf="resetPasswordFormGroup.get('newPassword'].dirty"
                  [passwordComponent]="passwordStengthMeter">
                </mat-password-strength-info> -->

              </div>


              <div class="col-12 col-md-8 mt-3">
                <mat-form-field>
                  <input matInput name="confirmNewPassword" placeholder="Confirm new password" type="password" [value]="resetPasswordFormGroup.get('confirmNewPassword').value" formControlName="confirmNewPassword"  />
                  <mat-error *ngIf="resetPasswordFormGroup.get('confirmNewPassword').errors && resetPasswordFormGroup.get('confirmNewPassword').errors.required">
                    We need your <strong>current password</strong> to be able to change it.
                  </mat-error>
                  <mat-error *ngIf="resetPasswordFormGroup.get('confirmNewPassword').errors && resetPasswordFormGroup.get('confirmNewPassword').errors.minLength">
                      ID number must be <strong>13 characters</strong> long.
                  </mat-error>
                  <mat-error *ngIf="resetPasswordFormGroup.get('confirmNewPassword').errors && resetPasswordFormGroup.get('confirmNewPassword').errors.invalidIdNumber">
                    Please enter a valid <strong>SA ID number</strong>.
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <!-- Close div for .row -->

            <hr class="hr-dashed hr-small" />

            <div class="panel-footer-inside">
              <button matTooltip="Save changes" class="btn btn-primary my-auto" type="submit">Change password</button>
            </div>

            </form>

          </mat-tab>

          <mat-tab label="Notifications">

            <div class="row">

              <div class="col-12 col-lg-8 offset-lg-2">

                <p class="mb-4"><strong>What notifications you receive.</strong></p>

                <form [formGroup]="notificationFormGroup" >

                  <div class="row" formGroupName="productChanges">
                    <div class="col-12">

                      <h5 class="mb-4">
                        <strong>Product changes</strong><br />
                        <small>These are notifications for whenever a change is made to your products</small>
                      </h5>

                      <div class="row my-2">

                        <div class="col-6">Push</div>
                        <div class="col-6"><mat-slide-toggle formControlName="push">{{ notificationFormGroup.get('productChanges').get('push').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                      <div class="row my-2">

                        <div class="col-6">Email</div>
                        <div class="col-6"><mat-slide-toggle formControlName="email">{{ notificationFormGroup.get('productChanges').get('email').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                      <div class="row my-2">

                        <div class="col-6">SMS</div>
                        <div class="col-6"><mat-slide-toggle formControlName="sms">{{ notificationFormGroup.get('productChanges').get('sms').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                    </div>
                  </div>


                  <hr class="hr-dashed hr-light" />

                  <div class="row" formGroupName="reminders">
                    <div class="col-12">

                      <h5 class="mb-4">
                        <strong>Reminders</strong><br />
                        <small>These are notifications to remind you of expiry dates on some items.</small>
                      </h5>

                      <div class="row my-2">

                        <div class="col-6">Push</div>
                        <div class="col-6"><mat-slide-toggle formControlName="push">{{ notificationFormGroup.get('productChanges').get('push').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                      <div class="row my-2">

                        <div class="col-6">Email</div>
                        <div class="col-6"><mat-slide-toggle formControlName="email">{{ notificationFormGroup.get('productChanges').get('email').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                      <div class="row my-2">

                        <div class="col-6">SMS</div>
                        <div class="col-6"><mat-slide-toggle formControlName="sms">{{ notificationFormGroup.get('productChanges').get('sms').value ? 'On' : 'Off' }}</mat-slide-toggle></div>

                      </div> <!-- Close div for .row -->

                    </div> <!-- Close div for .col-12 -->
                  </div> <!-- Close div for .row -->

                </form>

              </div>

            </div> <!-- Close div for .row -->

          </mat-tab>

          <mat-tab label="Email settings">
            <broker-settings-email></broker-settings-email>
          </mat-tab>

        </mat-tab-group>

      </div>

    </div>

  </div>

</div>
