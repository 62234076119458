import { User } from './../_shared/models/User.model';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Globals } from 'src/app/app.globals';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('username', { static: true }) username: ElementRef;
  @ViewChild('password', { static: true }) password: ElementRef;

  formSubmitted: Boolean = false;
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private globals: Globals,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private alert: ToastrService
  ) {
    window.location.href = 'https://mysurespace.co.za'
  }

  authenticated = this.globals.AUTENTICATED;
  
  get form() { return this.loginForm.controls; }

  loginUser(){

    this.formSubmitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authenticationService.loginUser(this.loginForm.value).subscribe(
      (response:HttpResponse<UserResponse>) => {
        const user = response;
     
        let dashboard = () => {
          switch(user['userType']){
            case 'broker':
              return '/b/dashboard'
              break;

            default:
              return '/u/dashboard'
              break;
          }
        }

        // SET NAVIGATE TO
        const navigateToRoute = this.activeRoute.snapshot.queryParams.redirectTo || dashboard();

        this.router.navigate([`${navigateToRoute}`]);
      },
      (error: HttpErrorResponse) => {
        let errors = error.error.errors;

        if(errors.type === 'cancelled' || errors.type === 'inactive'){
          this.alert.error(`${errors.message} <br /> ${errors.sub_message}`, errors.heading)
          .onTap
          .subscribe(() => {this.resendActivationLink(errors.type)})
        }

      }
    );

    this.loginForm.controls['password'].reset();
    this.password.nativeElement.focus();
  }

  resendActivationLink(activationType:string) {
    this.authenticationService.resendActivation(this.loginForm.value, activationType).subscribe()
  }

  resendActivation() {

  }

  ngAfterViewInit(){
    // this.username.nativeElement.focus();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  };

}
