import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../app.globals';

import { Shared } from 'src/app/_shared/shared';
import { PersonalItem, PersonalItemResponse } from '../_shared/models/personalItem.model';
import { VehicleResponse } from '../_shared/models/vehicle.model';
import { HouseholdInsuranceResponse } from '../_shared/models/householdInsurane.model';
import { HouseholdItemResponse } from '../_shared/models/householdItem.model';
import { StructureResponse } from '../_shared/models/structure.model';

@Injectable({
  providedIn: 'root'
})
export class PersonalInsuranceService {

  constructor(
    private http: HttpClient,
    public globals: Globals,
    private shared: Shared
  ) {}

  // TODO : Remove this
  createNewBulletin(item){
    return this.http.post(`${this.globals.SERVER_URL}/api/bulletin/`, item, {
      observe: 'response'
    });
  };

  getAllBulletinNotices(){
    return this.http.get(`${this.globals.SERVER_URL}/api/bulletin`, {
      observe: 'response'
    });
  };

  getHouseholdInsurance(): Observable<HttpResponse<HouseholdInsuranceResponse>>{
    return this.http.get<HouseholdInsuranceResponse>(`${this.globals.SERVER_URL}/api/householdInsurance`, {
      observe: 'response'
    });
  };

  addHouseholdInsurance(insurance): Observable<HttpResponse<HouseholdInsuranceResponse>>{
    return this.http.post<HouseholdInsuranceResponse>(`${this.globals.SERVER_URL}/api/householdInsurance`, insurance, {
      observe: 'response'
    });
  };

  editHouseholdInsurance(data): Observable<HttpResponse<HouseholdInsuranceResponse>>{
    return this.http.put<HouseholdInsuranceResponse>(`${this.globals.SERVER_URL}/api/householdInsurance`, data, {
      observe: 'response'
    });
  };

  sendBrokerEmailUnderInsured(req){
    return this.http.post(`${this.globals.SERVER_URL}/api/householdInsurance/underInsured`, req, {
      observe: 'response'
    });
  };


  addNewVehicle(vehicle): Observable<HttpResponse<VehicleResponse>>{
    return this.http.post<VehicleResponse>(`${this.globals.SERVER_URL}/api/vehicle`,  this.shared.createFormData(vehicle), {
      observe: 'response'
    });
  }

  getAllAssignedVehicles(req:any, noload?:boolean): Observable<HttpResponse<VehicleResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/client/vehicle/all`, {
      observe: 'response',
      params: req
    });
  }

  getAllVehicles(req:any, noload?:boolean): Observable<HttpResponse<VehicleResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/vehicle`, {
      observe: 'response',
      params: req
    });
  }

  getSelectedVehicle(registrationNumber): Observable<HttpResponse<VehicleResponse>>{
    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/vehicle/${registrationNumber}`, {
      observe: 'response'
    });
  };

  updateVehicle(registrationNumber, updatedVehicle): Observable<HttpResponse<VehicleResponse>>{ 
    return this.http.put<VehicleResponse>(`${this.globals.SERVER_URL}/api/vehicle/${registrationNumber}`, updatedVehicle, {
      observe: 'response'
    });
  };

  removeVehicle(registrationNumber){
    return this.http.delete(`${this.globals.SERVER_URL}/api/vehicle/${registrationNumber}`, {
      observe: 'response'
    });
  }

  addNewHouseholdItem(householdItem): Observable<HttpResponse<HouseholdItemResponse>>{
    return this.http.post<HouseholdItemResponse>(`${this.globals.SERVER_URL}/api/householdItem`,  this.shared.createFormData(householdItem), {
      observe: 'response'
    });
  };

  removeHouseholdItem(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/householdItem/${id}`, {
      observe: 'response'
    });
  }

  getAllHouseholdItems(req, noload?:boolean): Observable<HttpResponse<HouseholdItemResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<HouseholdItemResponse>(`${this.globals.SERVER_URL}/api/householdItem`, {
      headers: headers,
      params: req,
      observe: 'response'
    });
  };

  getSelectedHouseholdItem(id):Observable<HttpResponse<HouseholdItemResponse>>{
    return this.http.get<HouseholdItemResponse>(`${this.globals.SERVER_URL}/api/householdItem/${id}`, {
      observe: 'response'
    });
  };

  updateHouseholdItem(updatedHouseholdItem): Observable<HttpResponse<HouseholdItemResponse>>{ 
    return this.http.put<HouseholdItemResponse>(`${this.globals.SERVER_URL}/api/householdItem/${updatedHouseholdItem._id}`, this.shared.createFormData(updatedHouseholdItem), {
      observe: 'response'
    });
  };

  addNewPersonalItem(item): Observable<HttpResponse<PersonalItemResponse>>{
    return this.http.post<PersonalItemResponse>(`${this.globals.SERVER_URL}/api/personalItem`,  this.shared.createFormData(item), {
      observe: 'response'
    });
  };

  getAllPersonalItems(req:any, noload?:boolean): Observable<HttpResponse<PersonalItemResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<PersonalItemResponse>(`${this.globals.SERVER_URL}/api/personalItem`, {
      params: req,
      observe: 'response'
    });
  };

  getSelectedPersonalItem(id): Observable<HttpResponse<PersonalItemResponse>>{
    return this.http.get<PersonalItemResponse>(`${this.globals.SERVER_URL}/api/personalItem/${id}`, {
      observe: 'response'
    });
  };

  updatePersonalItem(item): Observable<HttpResponse<PersonalItemResponse>>{ 
    return this.http.put<PersonalItemResponse>(`${this.globals.SERVER_URL}/api/personalItem/${item._id}`, this.shared.createFormData(item), {
      observe: 'response'
    });
  };

  removePersonalItem(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/personalItem/${id}`, {
      observe: 'response'
    });
  }

  addNewStructure(structure): Observable<HttpResponse<any>>{
    return this.http.post<any>(`${this.globals.SERVER_URL}/api/structure`,  this.shared.createFormData(structure), {
      observe: 'response'
    });
  };

  getAllInsuredStructures(req:any, noload?:boolean): Observable<HttpResponse<StructureResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.get<StructureResponse>(`${this.globals.SERVER_URL}/api/structure`, {
      params: req,
      observe: 'response'
    });
  };

  getStructure(id): Observable<HttpResponse<StructureResponse>>{
    return this.http.get<StructureResponse>(`${this.globals.SERVER_URL}/api/structure/${id}`, {
      observe: 'response'
    });
  };

  updateStructure(updatedStructure): Observable<HttpResponse<StructureResponse>>{ 
    return this.http.put<StructureResponse>(`${this.globals.SERVER_URL}/api/structure/${updatedStructure._id}`, this.shared.createFormData(updatedStructure), {
      observe: 'response'
    });
  };

  removeStructure(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/structure/structure/${id}`, {
      observe: 'response'
    });
  }

}
