<div class="page-description">
    <div class="row h-100">
      <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
        <h6 class="d-inline-block mb-0">Buttons</h6>
      </div>
  
      <!-- <div class="col-6 text-right align-self-center">
        <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button>
      </div> -->
    </div>  
  </div>
  
  <div class="row">
  
    <div class="col-6 float-left">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Basic buttons</h5>
        </div>
  
        <div class="panel-body">

          <p>Normal buttons</p>

          <div class="section">

            <button class="btn btn-primary">Primary</button>
            <button class="btn btn-secondary btn-hover-brand">Secondary</button>
            <button class="btn btn-success">Success</button>
            <button class="btn btn-info">Info</button>
            <button class="btn btn-warning">Warning</button>
            <button class="btn btn-danger">Danger</button>
            <button class="btn btn-link">Link</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>The <code>&lt;btn&gt;</code> classes are designed to be used with <code>&lt;button&gt;</code>, <code>&lt;a&gt;</code> and <code>&lt;input&gt;</code> elements.</p>

          <div class="section">

            <a href="#" class="btn btn-primary">Link</a>
            <button class="btn btn-success">Button</button>
            <input class="btn btn-warning" type="button" value="Input" />
            <input class="btn btn-info" type="submit" value="Submit" />
            <input class="btn btn-danger" type="reset" value="Reset" />
            <a href="#" class="btn btn-dark">Dark</a>
            <button class="btn btn-brand">Brand</button>

          </div> <!-- Close div for .section -->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Button states:</p>

          <div class="section">

            <button class="btn btn-brand active">Active</button>
            <button class="btn btn-dark active">Active</button>
            <button class="btn btn-outline-danger active">Active</button>
            <button disabled="true" class="btn btn-primary">Disabled</button>
            <button disabled class="btn btn-success">Disabled</button>
            <button disabled class="btn btn-outline-success">Disabled</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>SureSpace custom buttons:</p>

          <div class="section">

            <button class="btn btn-brand">Brand</button>
            <button class="btn btn-dark">Dark</button>
            <button class="btn btn-light">Light</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>SureSpace base buttons:</p>

          <div class="section">

            <button class="btn btn-default btn-bold btn-upper">Button</button>
            <button class="btn btn-clean btn-bold btn-upper">Button</button>
            <button class="btn btn-default btn-sm btn-bold btn-upper">Button</button>
            <button class="btn btn-clean btn-sm btn-bold btn-upper">Button</button>

          </div> <!-- Close div for .section-->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->


      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Button Customization</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Large buttons:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-lg">Primary</button>
            <button class="btn btn-secondary btn-lg">Secondary</button>
            <button class="btn btn-success btn-lg">Success</button>

            <br />
            <br />

            <button class="btn btn-info btn-lg">Info</button>
            <button class="btn btn-warning btn-lg">Warning</button>
            <button class="btn btn-danger btn-lg">Danger</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Small buttons</p>
  
          <div class="section">

            <button class="btn btn-primary btn-sm">Primary</button>
            <button class="btn btn-secondary btn-sm">Secondary</button>
            <button class="btn btn-success btn-sm">Success</button>  
            <button class="btn btn-info btn-sm">Info</button>
            <button class="btn btn-warning btn-sm">Warning</button>
            <button class="btn btn-danger btn-sm">Danger</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Font settings:</p>
  
          <div class="section">

            <button class="btn btn-info btn-font-lg">Large font</button>
            <button class="btn btn-success btn-font-sm">Small font</button>
            <button class="btn btn-primary btn-upper">Uppercase</button>  
            <button class="btn btn-secondary btn-upper">Lowercase</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Wide buttons:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-wide">Wide button</button>
            <button class="btn btn-secondary btn-wider">Wider button</button>
            <button class="btn btn-success btn-widest">Widest button</button>  

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Tall buttons:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-tall">Tall button</button>
            <button class="btn btn-secondary btn-taller">Taller button</button>
            <button class="btn btn-success btn-tallest">Tallest button</button>  

          </div> <!-- Close div for .section-->
  
        </div>
  
      </div> <!-- Close div for .panel -->


      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Outline buttons</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Default outline buttons:</p>
  
          <div class="section">

            <button class="btn btn-outline-primary">Primary</button>
            <button class="btn btn-outline-secondary">Secondary</button>
            <button class="btn btn-outline-success">Success</button>
            <button class="btn btn-outline-info">Info</button>
            <button class="btn btn-outline-warning">Warning</button>
            <button class="btn btn-outline-danger">Danger</button>
            <button class="btn btn-outline-dark">Dark</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Hover outline buttons:</p>
  
          <div class="section">

            <button class="btn btn-outline-hover-primary">Primary</button>
            <button class="btn btn-outline-hover-brand">Brand</button>
            <button class="btn btn-outline-hover-success">Success</button>
            <button class="btn btn-outline-hover-info">Info</button>
            <button class="btn btn-outline-hover-warning">Warning</button>
            <button class="btn btn-outline-hover-danger">Danger</button>
            <button class="btn btn-outline-hover-dark">Dark</button>

          </div> <!-- Close div for .section-->
  
        </div> <!-- Close div for .panel-body-->
  
      </div> <!-- Close div for .panel -->


      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Pill buttons</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Default options:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-pill">Primary</button>
            <button class="btn btn-brand btn-pill">Solid</button>
            <button class="btn btn-secondary btn-pill">Secondary</button>
            <button class="btn btn-outline-brand btn-pill">Outline</button>
            <button class="btn btn-outline-hover-danger btn-pill">Hover outline</button>
            <button class="btn btn-light btn-hover-brand btn-pill">Hover solid</button>

          </div> <!-- Close div for .section-->
  
        </div> <!-- Close div for .panel-body-->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->
  
    <div class="col-6">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Square buttons</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Default options:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-square">Primary</button>
            <button class="btn btn-brand btn-square">Solid</button>
            <button class="btn btn-secondary btn-square">Secondary</button>
            <button class="btn btn-outline-brand btn-square">Outline</button>
            <button class="btn btn-outline-hover-danger btn-square">Hover outline</button>
            <button class="btn btn-light btn-hover-brand btn-square">Hover solid</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Elevation options: <strong>(NOT AVAILABLE)</strong></p>
  
          <div class="section">

            <button disabled class="btn btn-light btn-elevate-hover btn-square">Hover flat</button>
            <button disabled class="btn btn-brand btn-elevate btn-square">Solid</button>
            <button disabled class="btn btn-secondary btn-elevate btn-square">Secondary</button>
            <button disabled class="btn btn-outline-brand btn-elevate btn-square">Outline</button>
            <button disabled class="btn btn-outline-hover-danger btn-elevate btn-square">Hover outline</button>
            <button disabled class="btn btn-light btn-hover-brand btn-elevate-hover btn-square">Raise elevation</button>

          </div> <!-- Close div for .section-->
  
        </div>
  
      </div> <!-- Close div for .panel -->
  
  
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>With icons</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Default options with icons:</p>
  
          <div class="section">

            <button class="btn btn-primary"><i class="fi flaticon-edit"></i> Primary</button>
            <button class="btn btn-brand"><i class="fi flaticon-add"></i> Solid</button>
            <button class="btn btn-secondary"><i class="fi flaticon-cancel"></i> Secondary</button>
            <button class="btn btn-outline-brand"><i class="fi flaticon-trash"></i> Outline</button>
            <button class="btn btn-outline-hover-danger"><i class="fi flaticon-danger"></i> Hover outline</button>
            <button class="btn btn-light btn-hover-brand"><i class="fi flaticon-error"></i> Hover solid</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Pill options with icons:</p>
  
          <div class="section">

            <button class="btn btn-light btn-pill"><i class="fi flaticon-upload"></i> Hover flat</button>
            <button class="btn btn-brand btn-pill"><i class="fi flaticon-settings"></i> Solid</button>
            <button class="btn btn-secondary btn-pill"><i class="fi flaticon-management"></i> Secondary</button>
            <button class="btn btn-outline-brand btn-pill"><i class="fi flaticon-bell"></i> Outline</button>
            <button class="btn btn-outline-hover-info btn-pill"><i class="fi flaticon-zoom"></i> Hover outline</button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Size options:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-sm"><i class="fi flaticon-edit"></i> Primary</button>
            <button class="btn btn-brand"><i class="fi flaticon-add"></i> Solid</button>
            <button class="btn btn-secondary btn-lg"><i class="fi flaticon-cancel"></i> Secondary</button>
            <button class="btn btn-outline-brand"><i class="fi flaticon-trash"></i> Outline</button>
            <button class="btn btn-outline-hover-danger btn-sm"><i class="fi flaticon-danger"></i> Hover outline</button>
  
          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Social network solid buttons:</p>
  
          <div class="section">

            <button class="btn btn-facebook"><i class="fi flaticon-facebook"></i> Facebook</button>
            <button class="btn btn-twitter"><i class="fi flaticon-twitter"></i> Twitter</button>
            <button class="btn btn-google"><i class="fi flaticon-google"></i> Google</button>
            <button class="btn btn-instagram"><i class="fi flaticon-instagram"></i> Instagram</button>
            <button class="btn btn-linkedin"><i class="fi flaticon-linkedin"></i> Linkedin</button>
  
          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Social network label buttons:</p>
  
          <div class="section">

            <button class="btn btn-label-facebook"><i class="fi flaticon-facebook"></i> Facebook</button>
            <button class="btn btn-label-twitter"><i class="fi flaticon-twitter"></i> Twitter</button>
            <button class="btn btn-label-google"><i class="fi flaticon-google"></i> Google</button>
            <button class="btn btn-label-instagram"><i class="fi flaticon-instagram"></i> Instagram</button>
            <button class="btn btn-label-linkedin"><i class="fi flaticon-linkedin"></i> Linkedin</button>
  
          </div> <!-- Close div for .section-->  
  
        </div>
  
      </div> <!-- Close div for .panel -->


      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Icon only</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Default options with icons only:</p>
  
          <div class="section">

            <button class="btn btn-primary"><i class="fi flaticon-edit mr-0"></i></button>
            <button class="btn btn-brand"><i class="fi flaticon-add mr-0"></i></button>
            <button class="btn btn-secondary"><i class="fi flaticon-cancel mr-0"></i></button>
            <button class="btn btn-outline-brand"><i class="fi flaticon-trash mr-0"></i></button>
            <button class="btn btn-outline-hover-danger"><i class="fi flaticon-danger mr-0"></i></button>
            <button class="btn btn-light btn-hover-brand"><i class="fi flaticon-error mr-0"></i></button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Circle options with icons:</p>
  
          <div class="section">

            <button class="btn btn-light btn-pill"><i class="fi flaticon-upload mr-0"></i></button>
            <button class="btn btn-brand btn-pill"><i class="fi flaticon-settings mr-0"></i></button>
            <button class="btn btn-secondary btn-pill"><i class="fi flaticon-management mr-0"></i></button>
            <button class="btn btn-outline-brand btn-pill"><i class="fi flaticon-bell mr-0"></i></button>
            <button class="btn btn-outline-hover-info btn-pill"><i class="fi flaticon-zoom mr-0"></i></button>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Size options:</p>
  
          <div class="section">

            <button class="btn btn-primary btn-sm"><i class="fi mr-0 flaticon-edit"></i></button>
            <button class="btn btn-primary btn-pill btn-sm"><i class="fi mr-0 flaticon-edit"></i></button>
            <button class="btn btn-brand"><i class="fi mr-0 flaticon-add"></i></button>
            <button class="btn btn-brand btn-pill"><i class="fi mr-0 flaticon-add"></i></button>
            <button class="btn btn-secondary btn-lg"><i class="fi mr-0 flaticon-cancel"></i></button>
            <button class="btn btn-secondary btn-pill btn-lg"><i class="fi mr-0 flaticon-cancel"></i></button>
            <button class="btn btn-outline-brand"><i class="fi mr-0 flaticon-trash"></i></button>
            <button class="btn btn-outline-brand btn-pill"><i class="fi mr-0 flaticon-trash"></i></button>
            <button class="btn btn-outline-hover-danger btn-sm"><i class="fi mr-0 flaticon-danger"></i></button>
            <button class="btn btn-outline-hover-danger btn-pill btn-sm"><i class="fi mr-0 flaticon-danger"></i></button>
  
          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Social network icon buttons:</p>
  
          <div class="section">

            <button class="btn btn-facebook"><i class="fi flaticon-facebook mr-0"></i></button>
            <button class="btn btn-twitter"><i class="fi flaticon-twitter mr-0"></i></button>
            <button class="btn btn-google"><i class="fi flaticon-google mr-0"></i></button>
            <button class="btn btn-instagram"><i class="fi flaticon-instagram mr-0"></i></button>
            <button class="btn btn-linkedin"><i class="fi flaticon-linkedin mr-0"></i></button>
  
          </div> <!-- Close div for .section-->
  
        </div>
  
      </div> <!-- Close div for .panel -->



      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Label style</h5>
        </div>
  
        <div class="panel-body">
  
          <p>You can use the buttons styles for labels with <code>span</code> tag:</p>
  
          <div class="section">

            <span class="btn btn-label-primary">Primary</span>
            <span class="btn btn-label-success">Success</span>
            <span class="btn btn-label-info">Info</span>
            <span class="btn btn-label-danger">Danger</span>
            <span class="btn btn-label-warning">Warning</span>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Label pill style:</p>
  
          <div class="section">

            <span class="btn btn-label-primary btn-pill">Primary</span>
            <span class="btn btn-label-success btn-pill">Success</span>
            <span class="btn btn-label-info btn-pill">Info</span>
            <span class="btn btn-label-danger btn-pill">Danger</span>
            <span class="btn btn-label-warning btn-pill">Warning</span>

          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Link labels:</p>
  
          <div class="section">

            <a href="#" class="btn btn-label-primary btn-pill">Primary</a>
            <a href="#" class="btn btn-label-success btn-pill">Success</a>
            <a href="#" class="btn btn-label-info btn-pill">Info</a>
            <a href="#" class="btn btn-label-danger btn-pill">Danger</a>
            <a href="#" class="btn btn-label-warning btn-pill">Warning</a>

          </div> <!-- Close div for .section-->
  
        </div>
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->
  
  </div> <!-- Close div for .row -->