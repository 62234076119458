<div id="content" class="row">

    <sub-navigation heading="Admin"></sub-navigation>
  
    <div class="col-12">
  
        <div class="panel panel-primary">
  
            <div class="panel-heading">
              <div class="row w-100">
                <div class="col-12 col-sm-6">
                  <h5>Claims</h5>
                </div>
                <div class="col-12 col-sm-6 text-right">
                    <app-add-button
                    *ngIf="currentUserPermissions.includes('brokerClerk') || currentUserPermissions.includes('brokerClaimsTechnician') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')"
                    class="mr-0"
                    [routerLink]="['new']"
                    buttonText="Add new claim"
                    matTooltip="Add a new claim for a client."
                  >
                  </app-add-button>
                </div>
              </div> <!-- Close div for .row -->
             
            </div>
      
            <div class="panel-body">
  
              <div class="mb-5 col-md-4 col-sm-6 col-6">
                <mat-form-field>
                <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="claimSearch" (ngModelChange)="findAllClaims($event, true)"/>
                <mat-hint>
                    Search in all fields.
                </mat-hint>
                </mat-form-field>
            </div>

            <div class="col-md-8 col-sm-6 col-6 mb-4">
                <p class="d-inline-block mr-3"><strong>Filter claims</strong></p>
                <!-- <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'incomplete'; findAllClaims()" [ngClass]="claimSearchStatus == 'incomplete' ? 'btn-info' : 'btn-label-info'">Incomplete</button> -->
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'pending'; findAllClaims()" [ngClass]="claimSearchStatus == 'pending' ? 'btn-info' : 'btn-label-info'">Pending</button>
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'approved'; findAllClaims()" [ngClass]="claimSearchStatus == 'approved' ? 'btn-info' : 'btn-label-info'">Approved</button>
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'repudiated'; findAllClaims()" [ngClass]="claimSearchStatus == 'repudiated' ? 'btn-info' : 'btn-label-info'">Repudiated</button>
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'withdrawn'; findAllClaims()" [ngClass]="claimSearchStatus == 'withdrawn' ? 'btn-info' : 'btn-label-info'">Withdrawn</button>
                <button class="btn btn-sm btn-pill" *ngIf="isAdmin()" (click)="selectFinalisedFilter()" [ngClass]="claimSearchStatus == 'finalised' ? 'btn-info' : 'btn-label-info'">To be signed off</button>
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'completed'; findAllClaims()" [ngClass]="claimSearchStatus == 'completed' ? 'btn-info' : 'btn-label-info'">Completed</button>
                <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'all'; findAllClaims()" [ngClass]="claimSearchStatus == 'all' ? 'btn-info' : 'btn-label-info'">All</button>
            </div>

            <div class="col-md-8 col-sm-6 col-6 mb-4" *ngIf="isAdmin()">
              <p class="d-inline-block mr-3"><strong>Claims per employee</strong></p>
              <div class="col-md-4 col-sm-6 col-6 px-0">
                <mat-form-field>
                  <mat-select placeholder="Employee" name="employee" [(ngModel)]="userToGetClaims" (selectionChange)="findAllClaims()">
                    <mat-option value="" disabled read-only>
                      Please select an employee
                    </mat-option>
                    <mat-option value="allCompanyEmployees">
                      All company employees
                    </mat-option>
                    <mat-option [value]="currentUser._id">
                      Me ({{ currentUser?.firstName }} {{ currentUser?.lastName }})
                    </mat-option>
                    <mat-option *ngFor="let employee of companyEmployees" [value]="employee._id">
                      {{ employee.firstName }} {{ employee.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
        
              <table mat-table [dataSource]="claims" matSort matSortActive="created" matSortDisableClear
                matSortDirection="desc">
        
                <!-- Client Column -->
                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef>Client</th>
                    <td mat-cell *matCellDef="let row" data-label="Client">{{ row.client.companyName }}{{ row.client.firstName }} {{ row.client.lastName }}</td>
                </ng-container>

                <ng-container matColumnDef="clientNumber">
                  <th mat-header-cell *matHeaderCellDef>Client number</th>
                  <td mat-cell *matCellDef="let row" data-label="Client number">{{ row?.client?.clientNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="referenceNumber">
                  <th mat-header-cell *matHeaderCellDef>Reference number</th>
                  <td mat-cell *matCellDef="let row" data-label="Reference number">{{ row?.referenceNumber }}</td>
                </ng-container>

                <!-- Claim Type Column -->
                <ng-container matColumnDef="claimType">
                    <th mat-header-cell *matHeaderCellDef>Type of claim</th>
                    <td mat-cell *matCellDef="let row" data-label="Type of claim">{{ row.claimType | sentenceCase }}</td>
                </ng-container>

                <!-- Admin Status Column -->
                <ng-container matColumnDef="currentStep">
                    <th mat-header-cell *matHeaderCellDef>Current step</th>
                    <td mat-cell *matCellDef="let row" data-label="currentStep">{{ row?.currentStep | sentenceCase }}</td>
                </ng-container>

                <!-- Admin Instruction Approved Date Column -->
                <ng-container matColumnDef="dateApproved">
                  <th mat-header-cell *matHeaderCellDef>Signed off</th>
                  <td mat-cell *matCellDef="let row" data-label="Signed off">
                    <span *ngIf="row?.claimCompleted?.value">{{ row?.claimCompleted?.completedBy?.firstName }} {{ row?.claimCompleted?.completedBy?.lastName }}<br /><small class="text-muted">on {{ row?.claimCompleted?.dateCompleted | date: "dd MMM yyyy 'at' HH:mm" }}</small></span>
                    <span *ngIf="!row?.claimCompleted?.value">Not signed off</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let row" data-label="Status">{{ row?.claimSteps?.claimStatus?.value || 'Not set' | sentenceCase }}</td>
                </ng-container>

                 <!-- View Column -->
                 <ng-container matColumnDef="view">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let row" data-label="Actions">
                      <button
                        *ngIf="currentUserPermissions.includes('brokerClerk') || currentUserPermissions.includes('brokerClaimsTechnician') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')"
                        class="btn btn-light btn-pill color-brand"
                        matTooltip="Edit claim"
                        [routerLink]="['edit', row._id]"
                      >
                        <i class="fi flaticon-edit mr-0"></i>
                      </button>
                    </td>
                  </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>      
      
            </div> <!-- Close div for .panel-body -->
      
          </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for #content -->
  