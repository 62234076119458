<div class="row">

    <div id="emails" class="col-12 col-sm-4 col-md-3" *ngIf="emails?.length > 0">
        
        <div class="row">
            <div *ngFor="let email of emails; let i = index" class="col-12 border-bottom pt-3"  [ngClass]="{ 'selected': mailToReadIndex === i }" (click)="mailToReadIndex = i">
                <div class="row">
                    <div class="col-6">
                        <small class="text-muted">{{ email?.brokerRef?.firstName }} {{ email?.brokerRef?.lastName }}</small>
                    </div>
                    <div class="col-6 text-right">
                        <small class="text-muted"><strong>{{ email?.createdAt | date: 'dd MMM yy HH:mm' }}</strong></small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <span>To: </span><span *ngFor="let to of email?.to; let toIndex = index">{{ to }}{{ toIndex === email?.to?.length - 1 ? '' : ', ' }}</span><br />
                    </div>

                    <div class="col-12">
                        <span class="font-size-14"><strong>{{ email?.emailSubject }}</strong></span>
                        <!-- <span class="text-muted font-size-13 no-padding" [innerHtml]="createEmailBody(email?.emailBody)"></span> -->        
                    </div>

                    <div class="col-12 text-right my-auto" *ngIf="email?.attachments?.length > 1">
                        <span class="color-brand" *ngIf="email?.attachments?.length > 2">
                            <strong>{{ email?.attachments?.length }} </strong>
                        </span>
                        <svg class="icon-paperclip" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 611.998 611.998" style="enable-background:new 0 0 611.998 611.998;" xml:space="preserve">
                        <g>
                            <path d="M562.107,318.582c-13.223-13.221-34.661-13.223-47.882,0.002L325.982,506.831c-24.154,24.152-56.264,37.454-90.42,37.454
                                c-34.154,0-66.266-13.302-90.42-37.456s-37.456-56.264-37.456-90.42s13.304-66.27,37.456-90.422L386.847,84.281
                                c22.136-22.134,58.154-22.132,80.288-0.002c10.724,10.724,16.629,24.983,16.629,40.148c0,15.163-5.905,29.418-16.629,40.144
                                L262.052,369.663c-13.221,13.221-13.221,34.659,0.002,47.882c13.223,13.221,34.659,13.223,47.882-0.002l205.084-205.092
                                c23.515-23.513,36.464-54.773,36.464-88.026c0-33.249-12.949-64.511-36.464-88.026c-48.534-48.532-127.511-48.534-176.049,0.002
                                L97.263,278.107c-36.944,36.943-57.289,86.062-57.289,138.303c0,52.243,20.344,101.359,57.289,138.301
                                c36.943,36.941,86.06,57.287,138.301,57.287s101.359-20.346,138.299-57.289l188.243-188.247
                                C575.329,353.241,575.329,331.804,562.107,318.582z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        </svg>
                    </div>
                </div> <!-- Close div for .row -->
            
            </div>
        </div>

    </div>

    <div id="display" class="col-12 col-sm-8 col-md-9">

        <ng-container *ngIf="emails?.length > 0">

            <div class="row mb-3">
                <div class="col-6">
                    <span>{{ emails[mailToReadIndex]?.brokerRef?.firstName }} {{ emails[mailToReadIndex]?.brokerRef?.lastName }}</span>
                </div>
                <div class="col-6 text-right">
                    <span><strong>{{ emails[mailToReadIndex]?.createdAt | date: 'dd MMM yy HH:mm' }}</strong></span>
                </div>
            </div>
    
            <h5 class="mb-3"><strong>{{ emails[mailToReadIndex]?.emailSubject }}</strong></h5>
            <span class="color-label-3" [innerHtml]="emails[mailToReadIndex]?.emailBody"></span>
    
            <hr />
    
            <p class="text-muted">Click here to <a (click)="forwardEmail(emails[mailToReadIndex])">Forward</a> this message.</p>
    

        </ng-container>

        <ng-container *ngIf="emails?.length === 0">
            <div class="row">
                <div class="text-center col-12">
                    <p>No emails found</p>
                </div>
            </div>
        </ng-container>
        

    </div>
            
</div> <!-- Close div for .row -->