import { HttpResponse } from '@angular/common/http';
import { ClientService } from 'src/app/services/client-service.service';
import { Notifications, NotificationResponse } from 'src/app/_shared/models/notifications.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Notifications[];
  newNotifications: number;

  constructor(
    private clientService: ClientService
  ) {}

  getUserNotifications(){
    this.clientService.getUserNotifications().subscribe(
      (response: HttpResponse<NotificationResponse>) => {
        this.notifications = response.body.notifications;
        this.newNotifications = response.body.newNotifications
      }
    )
  }

  markNotificationAsRead(notification:Notifications){
    if(!notification.isSeen){
      this.clientService.markNotificationAsRead(notification._id).subscribe(
        (response: HttpResponse<NotificationResponse>) => {
            this.newNotifications -= 1;
            notification.isSeen = true;
          }
      )
    }
  }

  ngAfterViewInit(){
    this.getUserNotifications();
  }

  ngOnInit() {
  }

}
