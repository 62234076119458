<div id="content" class="row">

    <sub-navigation heading="Reports"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Per employee report</h5>
        </div>

        <div class="panel-body">

            <table mat-table [dataSource]="employeeReport" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

                <!-- Client Column -->
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>Employee</th>
                    <td mat-cell *matCellDef="let row" data-label="Employee">{{ row?.firstName }} {{ row?.lastName }}</td>
                </ng-container>

                <!-- Client Number Column -->
                <ng-container matColumnDef="totalInstructions">
                    <th mat-header-cell *matHeaderCellDef>Total instructions</th>
                    <td mat-cell *matCellDef="let row" data-label="Total instructions">{{ row?.totalInstructions || "0"}}</td>
                </ng-container>

                 <!-- Reference Number Column -->
                 <ng-container matColumnDef="totalClaims">
                    <th mat-header-cell *matHeaderCellDef>Total claims</th>
                    <td mat-cell *matCellDef="let row" data-label="Total claims">{{ row?.totalClaims || "0" }}</td>
                </ng-container>

                <ng-container matColumnDef="totalPolicies">
                    <th mat-header-cell *matHeaderCellDef>Total policies</th>
                    <td mat-cell *matCellDef="let row" data-label="Total policies">{{ row?.totalPolicies || '0' }}</td>
                </ng-container>

                <ng-container matColumnDef="totalQuotes">
                    <th mat-header-cell *matHeaderCellDef>Total quotes</th>
                    <td mat-cell *matCellDef="let row" data-label="Total quotes">{{ row?.totalQuotes || '0' }}</td>
                </ng-container>

                <ng-container matColumnDef="totalReturnedDebits">
                    <th mat-header-cell *matHeaderCellDef>Total returned debits</th>
                    <td mat-cell *matCellDef="let row" data-label="Total returned debits">{{ row?.totalReturnedDebits || '0' }}</td>
                </ng-container>

                <ng-container matColumnDef="totalCalendarEvents">
                    <th mat-header-cell *matHeaderCellDef>Total calendar events</th>
                    <td mat-cell *matCellDef="let row" data-label="Total calendar events">{{ row?.totalCalendarEvents || '0' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- <mat-paginator [hidden]="quotes?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator> -->

        </div> <!-- Close div for panel-body -->

    </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for content-->