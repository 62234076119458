import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getInsuredVehicleInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/insuredVehicle`, {
      observe: 'response'
    });
  };

  getAllVehicleInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/allVehicle`, {
      observe: 'response'
    });
  };

  getInsuredPersonalItemInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/insuredPersonalItems`, {
      observe: 'response'
    });
  };

  getAllPersonalItemInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/allPersonalItems`, {
      observe: 'response'
    });
  };

  getHouseholdItemsInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/householdItems`, {
      observe: 'response'
    });
  };

  getHouseholdInsuranceInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/householdInsurance`, {
      observe: 'response'
    });
  };

  getInsuredStructureInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/dashboard/insuredStructure`, {
      observe: 'response'
    });
  };


}
