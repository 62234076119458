import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordStrength: number;

  submitted = false;
  resetPasswordForm: FormGroup;
  get form() { return this.resetPasswordForm.controls; }

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder
  ) { }

  resetPassword(){

    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.authService.resetPassword(this.resetPasswordForm.value).subscribe(
      (response:HttpResponse<any>) => {
      }
    )
  }

  onStrengthChanged(strength: number) {
    this.passwordStrength = strength;
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', [ Validators.required, Validators.minLength(7) ]],
      confirmNewPassword: ['', [ Validators.required, Validators.minLength(7) ]]
    });
  }

}
