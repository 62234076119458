import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MyAccountService } from 'src/app/services/my-account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { Location } from '@angular/common';

import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service'

@Component({
  selector: 'app-client-edit-family-member',
  templateUrl: './client-edit-family-member.component.html',
  styleUrls: ['./client-edit-family-member.component.scss']
})
export class ClientEditFamilyMemberComponent implements OnInit, AfterViewInit {

  familyMemberId = this.activeRoute.snapshot.paramMap.get('id');
  FamilyMember: FamilyMember[];

  validId: boolean = true;

  familyMemberForm: FormGroup;
  submittedForm = false;
  get form(){ return this.familyMemberForm.controls; }

  constructor(
    private myAccountService: MyAccountService,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private verifyId: IdNumberVerifyService,
    public location: Location,
  ) { }

  getFamilyMember(member){
    this.myAccountService.getSelectedFamilyMembers(member).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        this.familyMemberForm.patchValue(response.body.family)
      }
    )
  };

  saveFamilyMember(saveType: string){

    this.submittedForm = true;
    let verifyId: any = this.verifyId.validateIdNumber(this.familyMemberForm.value['idNumber']);
    this.validId = verifyId.isValid;

    
    if (this.familyMemberForm.invalid || !this.validId) {
      return;
    }

    this.myAccountService.updateFamilyMember(this.familyMemberId, this.familyMemberForm.value).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        switch(saveType){
          case 'back':
            this.location.back();
            break;

          case 'continue':
            break;

          default:
            this.location.back();
            break;
        }
      }
    )
  };

  ngAfterViewInit(){
    this.getFamilyMember(this.familyMemberId);
  }

  ngOnInit() {
    this.familyMemberForm = this.formBuilder.group({
      _id:            [''],
      name:           ['', Validators.required],
      surname:        ['', Validators.required],
      relationship:   ['', Validators.required],
      idNumber:       ['', Validators.required],
      driversLicence: ['']
    });
  };

}
