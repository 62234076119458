<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
      <h6 class="d-inline-block mb-0">Base colors</h6>
    </div>

    <!-- <div class="col-6 text-right align-self-center">
      <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button>
    </div> -->
  </div>  
</div>

<div class="row">

  <div class="col-6 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>State colors</h5>
      </div>

      <div class="panel-body">

        <div class="table-responsive">
          <table class="table table-bordered table-head-solid">
            <thead>
              <tr>
                <th>State</th>
                <th>Class postfix</th>
                <th>Usage example</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td colspan="3"><strong>Bootstrap states</strong></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-success">Success</span></td>
                <td><code>*-success</code></td>
                <td><code>.btn-success .color-success</code></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-warning">Warning</span></td>
                <td><code>*-warning</code></td>
                <td><code>.btn-warning .color-warning</code></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-danger">Danger</span></td>
                <td><code>*-danger</code></td>
                <td><code>.btn-danger .color-danger</code></td>
              </tr>
              
              <tr>
                <td><span class="badge badge-inline badge-info">Info</span></td>
                <td><code>*-info</code></td>
                <td><code>.btn-info .color-info</code></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-primary">Primary</span></td>
                <td><code>*-primary</code></td>
                <td><code>.btn-primary .color-primary</code></td>
              </tr>

              <tr>
                <td colspan="3"><strong>SureSpace custom states</strong></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-brand">Brand</span></td>
                <td><code>*-brand</code></td>
                <td><code>.btn-brand .color-brand</code></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-dark">Dark</span></td>
                <td><code>*-dark</code></td>
                <td><code>.btn-dark .color-dark</code></td>
              </tr>

              <tr>
                <td><span class="badge badge-inline badge-light">Light</span></td>
                <td><code>*-light</code></td>
                <td><code>.btn-light .color-light</code></td>
              </tr>

            </tbody>          
          </table>
        </div>

      </div>

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

  <div class="col-6">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Typography examples</h5>
      </div>

      <div class="panel-body">

        <p>Apply state color classes to any typography element:</p>

        <span class="color-success">Success state text</span>
        <span class="ml-2 color-warning">Warning state text</span>
        <span class="ml-2 color-info">Info state text</span>

          <br />
          <br />

        <span class="color-danger"><strong>Danger state text</strong></span>
        <span class="ml-2 color-primary"><strong>Primary state text</strong></span>
        <span class="ml-2 color-brand"><strong>Brand state text</strong></span>

        <hr class="hr-light hr-dashed" />

        <p>Apply state color classes to any typography element:</p>

        <span class="color-label-4">Label color level 4</span>
        <span class="ml-2 color-label-3">Label color level 3</span>
        <span class="ml-2 color-label-2">Label color level 2</span>
        <span class="ml-2 color-label-1">Label color level 1</span>

      </div>

    </div> <!-- Close div for .panel -->



    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Button examples</h5>
      </div>

      <div class="panel-body">

        <p>Apply color classes to any component:</p>

        <button class="btn btn-primary ml-1">Primary</button>
        <button class="btn btn-success ml-1">Success</button>
        <button class="btn btn-warning ml-1">Warning</button>
        <button class="btn btn-danger ml-1">Danger</button>
        <button class="btn btn-brand ml-1">Brand</button>
        <button class="btn btn-dark ml-1">Dark</button>
        <!-- <button class="btn btn-light ml-1">Light</button> -->

        <hr class="hr-light hr-dashed" />

        <button class="btn btn-outline-success ml-1">Success</button>
        <button class="btn btn-outline-warning ml-1">Warning</button>
        <button class="btn btn-outline-danger ml-1">Danger</button>
        <button class="btn btn-outline-dark ml-1">Dark</button>
        <button class="btn btn-outline-brand ml-1">Brand</button>
        <button class="btn btn-outline-primary ml-1">Primary</button>
        <!-- <button class="btn btn-outline-light ml-1">Light</button> -->

      </div>

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

</div> <!-- Close div for .row -->