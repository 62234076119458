import { BrokerUnderwriter, BrokerUnderwriterResponse } from 'src/app/_shared/models/brokerUnderwriter.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrokerUnderwriterService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllUnderwriters(brokerCompany:string, req = {}): Observable<HttpResponse<BrokerUnderwriterResponse>> {
    return this.http.get<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/all/${brokerCompany}`, {
      observe: 'response',
      params: req
    });
  }

  searchUnderwriters(brokerCompany:string, req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerUnderwriterResponse>> {

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getSelectedUnderwriter(underwriterId:string): Observable<HttpResponse<BrokerUnderwriterResponse>> {
    return this.http.get<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/${underwriterId}`, {
      observe: 'response'
    });
  }

  addUnderwriter(companyName:string, underwriter:BrokerUnderwriter): Observable<HttpResponse<BrokerUnderwriterResponse>> {
    return this.http.post<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/${companyName}`, underwriter, {
      observe: 'response'
    });
  }

  editUnderwriter(underwriter:BrokerUnderwriter, underwriterId:string): Observable<HttpResponse<BrokerUnderwriterResponse>> {
    return this.http.put<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/${underwriterId}`, underwriter, {
      observe: 'response'
    });
  }

  deleteUnderwriter(underwriterId:string): Observable<HttpResponse<BrokerUnderwriterResponse>> {
    return this.http.delete<BrokerUnderwriterResponse>(`${this.globals.SERVER_URL}/api/brokerUnderwriter/${underwriterId}`, {
      observe: 'response'
    });
  }

}
