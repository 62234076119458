import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { BrokerService } from 'src/app/services/broker.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { PolicyResponse } from 'src/app/_shared/models/policy.model';
import { BrokerUnderwriterService } from 'src/app/services/broker-underwriter.service';
import { BrokerUnderwriter, BrokerUnderwriterResponse } from 'src/app/_shared/models/brokerUnderwriter.model';
import { BrokerAdministratorService } from 'src/app/services/broker-administrator.service';
import { BrokerAdministrator, BrokerAdministratorResponse } from 'src/app/_shared/models/brokerAdministrator.model';
import { BrokerClientService } from 'src/app/services/broker-client.service';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { AllRiskItem, AllRiskItemResponse } from 'src/app/_shared/models/allRiskItem.model';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { SelectionModel } from '@angular/cdk/collections';
import { element } from 'protractor';


@Component({
  selector: 'app-broker-new-edit-client-policies',
  templateUrl: './broker-new-edit-client-policies.component.html',
  styleUrls: ['./broker-new-edit-client-policies.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerNewEditClientPoliciesComponent implements OnInit, AfterViewInit {

  currentUser = this.authenticationService.currentUserValue;

  clients: User[];
  underwriters: BrokerUnderwriter[];
  administrators: BrokerAdministrator[];
  familyMembers: FamilyMember[];

  displayedColumns: string[] = ['itemType', 'field1', 'field2', 'field3'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  routerPath: string = this.activeRoute.routeConfig.path;
  editPolicy = this.routerPath.includes('edit');
  viewPolicy = this.routerPath.includes('view');

  clientId:string = null;
  policyId = this.activeRoute.snapshot.paramMap.get('policyId');

  activePolicyItems: any[] = [];
  inactivePolicyItems: any[] = [];
  policyItems: any[] = [];

  policyFormGroup: FormGroup;
  formSubmitted = false;
  get policyForm(){ return this.policyFormGroup.controls; }

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  isLoading: boolean = false;

  selectedPolicyItems = new SelectionModel<any>(true, []);

  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private brokerService: BrokerService,
    public location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private brokerComponent: BrokerComponent,
    private brokerAdministratorService: BrokerAdministratorService,
    private brokerUnderwriterService: BrokerUnderwriterService,
    private brokerClientService: BrokerClientService,
    private brokerClientAllRiskService: BrokerClientAllRiskServiceService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  addNewPolicy(saveType?: string){
    // activate mat-error
    this.policyFormGroup.markAllAsTouched();

    this.formSubmitted = true;

    if (this.policyFormGroup.invalid) return;

    // SET THE BROKER FIELD
    this.policyForm.broker['controls']._id.setValue(this.currentUser._id);
    this.policyForm.broker['controls'].firstName.setValue(this.currentUser.firstName);
    this.policyForm.broker['controls'].lastName.setValue(this.currentUser.lastName);


    this.brokerService.createNewPolicy(this.brokerComponent.brokerCompany.companyName, this.policyFormGroup.value).subscribe(
      (response:HttpResponse<PolicyResponse>) => {
        this.saveType(saveType)

        let policyItems = [];

        this.selectedPolicyItems.selected.forEach(element => {

          let field1;
          let field2;
          let field3;
          let itemType;

          if(element && element.make && element.model){
              field1 = element.make;
              field2 = element.model;
              field3 = element.registrationNumber;
              itemType = 'Vehicle';
          }

          if(element && element.itemName){
            field1 = element.itemName;
            field2 = element.specified;
            field3 = null;
            itemType = 'AllRisk';
          }
          
          if(element && element.address){
            field1 = element.residenceType;
            field2 = element.address.formattedAddress;
            field3 = element.isPrimaryResidence ? 'Primary residence' : element.isHolidayHome ? 'Holiday home' : null;
            itemType = 'Home';
          }

          if(element && element.address && element.yearsLivingAtProperty){
            itemType = 'HomeContent';
          }

          if(element && element.type === 'trailer' || element.type === 'caravan'){
            itemType = 'TrailersAndCaravans';
            field1 = element.type,
            field2 = `${element.make} ${element.model}`,
            field3 = element.registrationNumber
          }

          if(element && element.productName){
            itemType = 'VAPS';
            field1 = element.productName,
            field2 = element.productType,
            field3 = null
          }

          policyItems.push({
            ...element,
            itemInceptionDate: element.insurance.inceptionDate,
            itemField1: field1,
            itemField2: field2,
            itemField3: field3,
            itemType: itemType
          })
        });

        this.brokerService.addPolicyItems(this.policyFormGroup.get('policyNumber').value, this.brokerComponent.brokerCompany.companyName, policyItems).subscribe()
        this.brokerService.removePolicyItems(this.brokerComponent.brokerCompany.companyName, policyItems).subscribe()

      }
    );
  };

  getCurrentPolicy(policyId: string){
    this.brokerService.getSelectedPolicyDetails(policyId).subscribe(
      (response:HttpResponse<PolicyResponse>) => {
        this.policyFormGroup.patchValue(response.body.policies);
        this.policyFormGroup.get('policyHolder').setValue(response.body.policies['userRef'])

        this.getPolicyItems();

        this.activePolicyItems = response.body.policies['policyItems'].filter(item => item.active) || []

        console.log('items => ', this.activePolicyItems)
      }
    )
  };

  getPolicyItems(){
    this.brokerService.getPolicyItems(this.policyFormGroup.get('policyNumber').value, this.brokerComponent.brokerCompany.companyName).subscribe(
      response => {
        this.policyItems = response.body.policyItems
        console.log('items => ', this.policyItems)
      }
    )
  }

  updatePolicy(saveType: string){

    // activate mat-error
    this.policyFormGroup.markAllAsTouched();
    this.formSubmitted = true;

    if (this.policyFormGroup.invalid) return;

    this.brokerService.updatePolicy(this.policyId, this.policyFormGroup.value).subscribe(
      (response:HttpResponse<PolicyResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.location.back();
        break;

      case 'new':
        this.policyFormGroup.reset()
        break

      case 'continue':
        break;

      default:
        this.location.back();
        break;
    }
  }

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      this.location.back();
      // this.router.navigate(['b/clients/policies'])
    };
  }

  displayFn(client?: User): string | undefined {

    console.log('client => ', client);

    if(client){
      if(client.companyName){
        return client.companyName
      } else {
        return `${client.firstName} ${client.lastName}`
      }
    } else {
      return undefined
    }
  }

  displayFamily(member?: FamilyMember): string | undefined {
    console.log('memnber => ', member)
    return member ? `${member.name} ${member.surname}` : null;
  }

  getAllAdministrators() {
    this.brokerAdministratorService.getAllAdministrators(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<BrokerAdministratorResponse>) => {
        this.administrators = response.body.administrator;
      }
    )
  }

  getAllUnderwriters() {
    this.brokerUnderwriterService.getAllUnderwriters(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<BrokerUnderwriterResponse>) => {
        this.underwriters = response.body.underwriter;
      }
    )
  }

  ngAfterViewInit(){
    if(this.editPolicy) this.getCurrentPolicy(this.policyId);
    this.getAllAdministrators();
    this.getAllUnderwriters();
  }

  ngOnInit() {

    this.policyFormGroup = this.formBuilder.group({
      policyNumber:     ['', Validators.required],
      policyStartDate:  ['', Validators.required],
      administrator:    [null],
      underwriter:      [null],
      broker: this.formBuilder.group({
        _id: '',
        firstName: '',
        lastName: ''
      }),
      policyHolder:     [null, Validators.required],
      coInsured:     [null]
    });

    this.getAllClients()

      this.policyFormGroup
      .get('coInsured')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.brokerService.getClientFamilyMembers(this.policyFormGroup.get('policyHolder').value._id)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response: HttpResponse<FamilyMemberResponse>) => {
          this.familyMembers = response.body.family;
        }
      )

    this.getClientFromRoute();
  }

  mapClient(event){
    this.clientId = event.option.value._id;

    this.brokerService.getClientFamilyMembers(event.option.value._id).subscribe(response => {
      this.familyMembers = response.body.family
    })
  }

  getClientFromRoute(){
    this.activeRoute.queryParams.subscribe(params => {
      if(params && params['id']){
        this.brokerService.getSingleClient(params['id']).subscribe(
          (response:HttpResponse<UserResponse>) => {
            this.clientId = params['id'];
            this.policyFormGroup.get('policyHolder').patchValue(response.body.user)
          }
        );

      }
    })
  }

  getClientHomeBuildings(){}

  getClientHouseholdContent(){}

  userAllRiskItems: AllRiskItem[]
  getClientAllRiskItems(){
    this.brokerClientAllRiskService.getAllAllRiskInsuranceForClient(this.policyFormGroup.get('policyHolder').value._id, this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<AllRiskItemResponse>) => {
        this.userAllRiskItems = response.body.item;
      }
    )
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

  getAllClients(){

    this.brokerService.getAllCompanyClientsAndCommercial(this.brokerComponent.brokerCompany.companyName, null, true).subscribe(response => {

      this.clients = response.body.user;

      // GET DATA
      this.policyFormGroup.get('policyHolder').valueChanges.pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.brokerComponent.brokerCompany.companyName, { search: value }, true)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response: HttpResponse<UserResponse>) => {
          this.clients = response.body.user;
        }
      )
      
    })

  }

  goToClientFile(){
    let client = this.policyFormGroup.value.policyHolder;

    if(client.companyName){
      this.router.navigate(['/b/clients/commercial/edit', client._id], { queryParams: { file: '5' } })
    } else {
      this.router.navigate(['/b/clients/personal/edit', client._id], { queryParams: { file: '5' } })
    }
  }

}

