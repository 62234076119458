import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'style-guide-panels',
  templateUrl: './style-guide-panels.component.html',
  styleUrls: ['./style-guide-panels.component.scss']
})
export class StyleGuidePanelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
