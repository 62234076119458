<table mat-table [dataSource]="policyItems">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="itemName">
      <th mat-header-cell *matHeaderCellDef> Item name </th>
      <td mat-cell *matCellDef="let item"> {{item.itemName}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="specified">
      <th mat-header-cell *matHeaderCellDef> Specified </th>
      <td mat-cell *matCellDef="let item"> {{item.specified ? 'Yes' : 'No'}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let item"> {{ item.itemCategory | sentenceCase }} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>