<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">My brokers</h5>
      <h6 class="d-inline-block mb-0">{{ brokers?.length + customBrokers?.length }} Total</h6>
        <div class="search d-inline-block ml-4">
          <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="color-primary fi fi-bold flaticon-zoom"></i></div>
          </div>
          <input placeholder="Search" type="text" class="form-control" name="broker-search" [(ngModel)]="searchBroker" (ngModelChange)="getAllBrokers($event, true)" />
        </div> <!-- Close div for .input-group .search -->
      </div>
    </div>

    <div class="col-6 text-right align-self-center">
      <!-- <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button> -->

      <div class="btn-group" ngbDropdown>
        <button type="button" [routerLink]="['add-broker']" class="btn btn-primary">
          Add new broker
        </button>
        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item" href="#" matTooltip="Create a broker that is not listed under broker companies." [routerLink]="['new-broker']">Create new broker</a></li>
        </ul>
      </div> <!-- Close div for .btn-group -->

    </div>
  </div>  
</div>

<div class="row">

  <div class="col-12 mt-3" *ngIf="brokers?.length < 1 && customBrokers?.length < 1">
    <p class="text-center">You currently have no brokers linked to your account.</p>
  </div>

  <div class="col-lg-4 col-md-6 col-12" *ngFor="let broker of brokers">
    <div class="card mb-3">

      <div class="card-header d-flex flex-wrap">

        <div class="card-header-menu" ngbDropdown>
            <button ngbDropdownToggle type="button" class="btn btn-icon color-info mr-0" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" [routerLink]="['add-broker']">Select new broker</a></li>
              <li role="menuitem"><a class="dropdown-item" (click)="removeBroker(broker)">Remove broker</a></li>
            </ul>        
        </div>

        <span class="userpic userpic-info userpic-lg mr-3">
          <span>{{ broker?.firstName.substring(0, 1) }} {{ broker?.lastName.substring(0, 1) }}</span>
        </span>

        <div class="d-inline-block my-auto">
          <h5 class="card-heading m-0">{{ broker?.firstName }} {{ broker?.lastName }}</h5>
          <span class="card-subheading">{{ broker?.companyName }}</span>
        </div>
          
      </div>

      <div class='card-body'>
        <p>
          <strong class="color-black">Email:</strong>
          <span class="float-right color-label-2">{{ broker?.email }}</span>
            <br />
          <strong class="color-black">Phone:</strong>
          <span class="float-right color-label-2">{{ broker?.cellphone }}</span>
        </p>
      </div>

    </div> <!-- Close div for .card -->

  </div> <!-- Close div for .col-lg-4 .col-md-6 .col-12 -- BROKER LOOP -->

  <div class="col-lg-4 col-md-6 col-12" *ngFor="let broker of customBrokers">
      <div class="card mb-3">
  
        <div class="card-header d-flex flex-wrap">
  
          <div class="card-header-menu" ngbDropdown>
              <button ngbDropdownToggle type="button" class="btn btn-icon color-info mr-0" aria-controls="dropdown-basic">
                <i class="fi flaticon-menu fi-rotate-90"></i>
              </button>
              <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" [routerLink]="['add-broker']">Select new broker</a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="removeBroker(broker)">Remove broker</a></li>
              </ul>        
          </div>
  
          <span class="userpic userpic-info userpic-lg mr-3">
            <span>{{ broker?.firstName.substring(0, 1) }} {{ broker?.lastName.substring(0, 1) }}</span>
          </span>
  
          <div class="d-inline-block my-auto">
            <h5 class="card-heading m-0">{{ broker?.firstName }} {{ broker?.lastName }}</h5>
            <span class="card-subheading">{{ broker?.company }}</span>
          </div>
            
        </div>
  
        <div class='card-body'>
          <p>
            <strong class="color-black">Email:</strong>
            <span class="float-right color-label-2">{{ broker?.email }}</span>
              <br />
            <strong class="color-black">Phone:</strong>
            <span class="float-right color-label-2">{{ broker?.cellphone }}</span>
          </p>
        </div>
  
      </div> <!-- Close div for .card -->
  
    </div> <!-- Close div for .col-lg-4 .col-md-6 .col-12 -- BROKER LOOP -->

</div> <!-- Close div for .row -->