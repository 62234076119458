<table mat-table [dataSource]="policyItems">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>Product</th>
        <td mat-cell *matCellDef="let row" data-label="Product">{{ row.productName }}</td>
    </ng-container>

    <ng-container matColumnDef="productType">
        <th mat-header-cell *matHeaderCellDef>Product type</th>
        <td mat-cell *matCellDef="let row" data-label="Product type">{{ row.productType | sentenceCase }}</td>
    </ng-container>

    <ng-container matColumnDef="premium">
        <th mat-header-cell *matHeaderCellDef>Premium</th>
        <td mat-cell *matCellDef="let row" data-label="Premium">{{ row.insurance.premium | currency: 'R ' }}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>