<div class="modal-header">
  <h4>Are you sure?</h4>
</div>

<div class="modal-body">
  <div mat-dialog-content>
    <!-- <h5>Delete {{ data.deleteWhat }}?</h5> -->
    <p>
        You are about to delete 
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'family member'">{{ data.selectedItem?.name }} {{ data.selectedItem?.surname }}</strong>
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'broker'">{{ data.selectedItem?.firstName }} {{ data.selectedItem?.lastName }}</strong>
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'insurance company'">{{ data.selectedItem?.companyName }}</strong>
        <span *ngIf="data.deleteWhat.toLowerCase() === 'vehicle'">your following vehicle:</span>
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'household content'">
          <span *ngIf="data.selectedItem.insured === 'Yes'">your insured </span>{{ data.selectedItem?.item }}
        </strong>
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'personal item'">{{ data.selectedItem?.item }}</strong>
        <strong *ngIf="data.deleteWhat.toLowerCase() === 'insured structure'">{{ data.selectedItem?.name }}</strong>


        <span *ngIf="data.deleteWhat.toLowerCase() === 'vehicle'">
          <br />
          <br />
          <strong>Vehicle make: </strong>{{ data.selectedItem?.make }}<br />
          <strong>Vehicle model: </strong>{{ data.selectedItem?.model }}<br />
          <strong>Registration number: </strong>{{ data.selectedItem?.registrationNumber }}
          <br />
          <br />
        </span>
        
        <span *ngIf="data.deleteWhat.toLowerCase() === 'broker'"> from {{ data.selectedItem?.company }}</span>

        <!-- returns as/an -->
        <span *ngIf="data.deleteWhat.toLowerCase() !== 'vehicle' && data.deleteWhat.toLowerCase() !== 'household content'"> as {{ data.deleteWhat | indefinite }}.</span>
        <span *ngIf="data.deleteWhat.toLowerCase() === 'household content'">from your {{ data.deleteWhat }}?</span>
        <!-- <span *ngIf="data.deleteWhat.toLowerCase() === 'vehicle'">Are you sure you want to delete this {{ data.deleteWhat }}?</span> -->
        
        <br />
        This action cannot be undone.
    </p>
  </div>
</div>

<div class="modal-footer">
  <div mat-dialog-actions class="inline-buttons">
    <app-delete-button (click)="delete(data.deleteWhat, data.selectedItem)" cdkFocusInitial></app-delete-button>
    <app-cancel-button (click)="onNoClick()"></app-cancel-button>
  </div>
</div>
