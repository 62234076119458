import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { BrokerAdminInstruction, BrokerAdminInstructionResponse } from 'src/app/_shared/models/brokerAdminInstruction.model';


@Injectable({
  providedIn: 'root'
})
export class BrokerAdminInstructionService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getInstructionSteps(){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/steps`, {
      observe: 'response',
    });
  }

  getAllInstructions(brokerCompany:string, req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerAdminInstructionResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getAllInstructionsPerUser(brokerCompany:string, clientId:string, req?:any): Observable<HttpResponse<BrokerAdminInstructionResponse>>{
    return this.http.get<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/${brokerCompany}/user/${clientId}`, {
      params: req,
      observe: 'response'
    });
  };

  getSelectedInstruction(brokerCompany:string, instructionId:string){
    return this.http.get<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/${brokerCompany}/${instructionId}`, {
      observe: 'response'
    });
  };

  addNewInstruction(brokerCompany:string, formData:BrokerAdminInstruction): Observable<HttpResponse<BrokerAdminInstructionResponse>> {
    return this.http.post<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/${brokerCompany}`, formData, {
      observe: 'response'
    });
  }

  editInstruction(brokerCompany:string, instructionId:string, formData:BrokerAdminInstruction): Observable<HttpResponse<BrokerAdminInstructionResponse>> {
    return this.http.put<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/brokerAdminInstruction/${brokerCompany}/${instructionId}`, formData, {
      observe: 'response'
    });
  }

}
