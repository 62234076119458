import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { ClientService } from 'src/app/services/client-service.service';
import { Notifications, NotificationResponse } from 'src/app/_shared/models/notifications.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-notifications',
  templateUrl: './broker-notifications.component.html',
  styleUrls: ['./broker-notifications.component.scss']
})
export class BrokerNotificationsComponent implements OnInit {

  notifications: Notifications[];
  newNotifications: number;

  constructor(
    private clientService: ClientService,
    private router: Router
  ) {}

  getUserNotifications(){
    this.clientService.getUserNotifications().subscribe(
      (response: HttpResponse<NotificationResponse>) => {
        console.log('response => ', response)
        this.notifications = response.body.notifications;
        this.newNotifications = response.body.newNotifications
      }
    )
  }

  markNotificationAsRead(notification:Notifications){
    if(!notification.isSeen){
      this.clientService.markNotificationAsRead(notification._id).subscribe(
        (response: HttpResponse<NotificationResponse>) => {
            this.newNotifications -= 1;
            notification.isSeen = true;
          }
      )
    }

    if(notification.category === "Instruction"){
      this.router.navigate(['/b/instructions/edit', notification.itemRef])
    }
  }

  ngAfterViewInit(){
    this.getUserNotifications();
  }

  ngOnInit() {
  }

}
