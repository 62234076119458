import { Subscription } from 'rxjs';
import { CommercialClient } from './../../../_shared/models/commercialClient.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../../services/authentication.service';
import { BrokerService } from './../../../services/broker.service';
import { Employee, EmployeeResponse } from './../../../_shared/models/employeeModel';
import { Broker } from './../../../_shared/models/brokerClaims.model';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, ViewChild, forwardRef, OnDestroy } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'broker-commercial-clients-form-group',
  templateUrl: './broker-commercial-clients-form-group.component.html',
  styleUrls: ['./broker-commercial-clients-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrokerCommercialClientsFormGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BrokerCommercialClientsFormGroupComponent),
      multi: true
    }
  ]
})
export class BrokerCommercialClientsFormGroupComponent implements ControlValueAccessor, OnDestroy {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  clientInformationFormGroup: FormGroup;

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  employees: Employee[];
  subscriptions: Subscription[] = [];

  inEditMode: boolean = false;

  get clientFormValue(): CommercialClient {
    return this.clientInformationFormGroup.value;
  }

  set clientFormValue(value: CommercialClient) {
    this.clientInformationFormGroup.patchValue(value);

    if(value){
      this.inEditMode = true;
    }

    this.onChange(value);
    this.onTouched();
  }

  get registrationNumberControl() {
    return this.clientInformationFormGroup.controls.companyRegistrationNumber;
  }

  constructor(
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private authenticationService: AuthenticationService
  ) { 

    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions

    this.clientInformationFormGroup = this.formBuilder.group({
      companyRegistrationNumber: ['', Validators.required],
      taxNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      contactPersonName: [''],
      contactPersonSurname: [''],
      contactPhone: [''],
      email: ['', [Validators.required, Validators.email]],
      language: 'English',
      broker: this.formBuilder.group({
        _id: [],
        firstName: [''],
        lastName: [''],
        companyName: [''],
        clientNumber: [''],
        dateAssigned: [''],
        clientStatus: [''],
      }),
      physicalAddress: this.formBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          suburb: [''],
          city: [''],
          province: [''],
          postalCode: [''],
          formattedAddress: ['']
      }),
      clientType: 'commercial'
    });

    this.getEmployees();

    this.subscriptions.push(
      this.clientInformationFormGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let clientInformationFormGroupAddress = this.clientInformationFormGroup.get('physicalAddress');

    clientInformationFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    clientInformationFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    clientInformationFormGroupAddress.get('city').setValue(findInAddress('locality'));
    clientInformationFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    // clientInformationFormGroupAddress.get('country').setValue(findInAddress('country'));
    clientInformationFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    clientInformationFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

  getEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.clientFormValue = value;
    }

    if (value === null) {
      this.clientInformationFormGroup.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.clientInformationFormGroup.valid ? null : { client: { valid: false } };
  }


}
