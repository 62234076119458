import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerUploadAnyDocumentModalComponent } from './../../clients/documents/uploadAnyDocumentModal/broker-upload-any-document-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BrokerCreateUserCustomNoteComponent } from 'src/app/broker/clients/edit-client/templates/broker-view-user-notes/custom-note/broker-create-user-custom-note.component';
import { ActivityLog } from './../../../_shared/models/activityLogs.model';
import { Employee, EmployeeResponse } from './../../../_shared/models/employeeModel';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from './../../../services/broker.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../services/authentication.service';
import { CommercialClientsService } from './../../../services/commercial-clients.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommercialClient } from 'src/app/_shared/models/commercialClient.model';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-broker-commercial-clients-edit',
  templateUrl: './broker-commercial-clients-edit.component.html',
  styleUrls: ['./broker-commercial-clients-edit.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerCommercialClientsEditComponent implements OnInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  currentCommercialClient: string;

  clientInformationFormGroup: FormGroup;
  currentCompany: string;

  employees: Employee[];

  selectedIndex: number = 0;

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private commercialClientService: CommercialClientsService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private brokerService: BrokerService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    public brokerComponent: BrokerComponent
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;
    this.currentCommercialClient = this.activeRoute.snapshot.paramMap.get('clientId');
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  
    // this.selectedIndex = JSON.parse(localStorage.getItem('SureSpaceSettings')).clients[this.currentCommercialClient].selectedIndex;

    this.activeRoute.queryParamMap.subscribe(param => {
      if(param['params'].file){
        this.selectedIndex = Number(param['params'].file)
      }
    })
  }

  ngOnInit(): void {
  
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions

    this.clientInformationFormGroup = this.formBuilder.group({
      companyRegistrationNumber: ['', Validators.required],
      taxNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      clientNumber: [''],
      contactPersonName: [''],
      contactPersonSurname: [''],
      contactPhone: [''],
      email: ['', [Validators.required, Validators.email]],
      language: 'English',
      broker: this.formBuilder.group({
        _id: [],
        firstName: [''],
        lastName: [''],
        companyName: [''],
        clientNumber: [''],
        dateAssigned: [''],
        clientStatus: [''],
      }),
      physicalAddress: this.formBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          suburb: [''],
          city: [''],
          province: [''],
          postalCode: [''],
          formattedAddress: ['']
      }),
      clientType: 'commercial'
    });

    this.getEmployees();

    this.getCurrentClient();
  
  }

  saveClient(saveType:string = 'back'){

    if(this.clientInformationFormGroup.invalid){
      this.toastr.error('There were some errors on your form.', 'Could not add commercial client.')
      return
    };

    this.commercialClientService.saveClientDetails(this.currentCommercialClient, this.clientInformationFormGroup.value, this.currentCompany).subscribe(response => {
      this.afterSave(saveType)
    })

  }

  getCurrentClient(){
    this.commercialClientService.getSingleClient(this.currentCommercialClient).subscribe(response => {
      response.body.user['broker'] = response.body.user['broker'].find(b => b.companyName === this.currentCompany);
      this.clientInformationFormGroup.patchValue(response.body.user)
    })
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let clientInformationFormGroupAddress = this.clientInformationFormGroup.get('physicalAddress');

    clientInformationFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    clientInformationFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    clientInformationFormGroupAddress.get('city').setValue(findInAddress('locality'));
    clientInformationFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    // clientInformationFormGroupAddress.get('country').setValue(findInAddress('country'));
    clientInformationFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    clientInformationFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

  getEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )
  }

  goBack(){
    this.location.back();
  }

  afterSave(saveType){
    switch(saveType){
      case 'back':
        this.location.back();
        break;

      case 'new':
        this.clientInformationFormGroup.reset();
        break;

      case 'continue':
        break;

      default:
        this.location.back();
        break;
    }
  }

  setSelectedIndex(event){
    this.selectedIndex = event;

  //   let surespaceSettings = JSON.parse(localStorage.getItem('SureSpaceSettings')) || {};
  //   surespaceSettings['client_selectedIndex'] = event;
  //   localStorage.setItem('SureSpaceSettings', JSON.stringify(surespaceSettings))
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

  newestNote:ActivityLog = null;
  addManualNote(){
    let dialogRef = this.dialog.open(BrokerCreateUserCustomNoteComponent, {
      width: '800px',
      data: {
        userId: this.currentCommercialClient,
        companyName: this.authService.currentCompany.companyName
      }
    });

    dialogRef.afterClosed().subscribe(response => {

      if(response.status === 'success'){
        this.newestNote = response.data
        // this.notesComponent.getActivityLogs();
      }
    })

  }

  openUploadDocumentModal(){
    const dialogRef = this.dialog.open(BrokerUploadAnyDocumentModalComponent, { width: '1000px', data: { selectedUser: this.currentCommercialClient } });

    dialogRef.afterClosed().subscribe(result => {
    });
}

}
