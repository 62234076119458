import { BrokerUnderwriterResponse } from 'src/app/_shared/models/brokerUnderwriter.model';
import { BrokerUnderwriterService } from 'src/app/services/broker-underwriter.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MyAccountService } from 'src/app/services/my-account.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-broker-new-edit-underwriter',
  templateUrl: './broker-new-edit-underwriter.component.html',
  styleUrls: ['./broker-new-edit-underwriter.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerNewEditUnderwriterComponent implements OnInit, AfterViewInit {

  currentUser = this.authenticationService.currentUserValue;

  clients: User[];

  routerPath: string = this.activeRoute.routeConfig.path;
  editUnderwriter = this.routerPath.includes('edit');
  viewUnderwriter = this.routerPath.includes('view');

  underwriterId = this.activeRoute.snapshot.paramMap.get('id');

  underwriterFormGroup: FormGroup;
  formSubmitted = false;
  get underwriterForm(){ return this.underwriterFormGroup.controls; }

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  isLoading: boolean = false;

  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private verifyId: IdNumberVerifyService,
    private myAccountService: MyAccountService,
    private brokerAdminService: BrokerUnderwriterService,
    public location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private brokerComponent: BrokerComponent,
    private toastr: ToastrService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  addNewUnderwriter(saveType?: string){

    // activate mat-error
    this.underwriterFormGroup.markAllAsTouched();

    this.formSubmitted = true;

    if (this.underwriterFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Could not save underwriter.')
      return;
    };

    this.brokerAdminService.addUnderwriter(this.brokerComponent.brokerCompany.companyName, this.underwriterFormGroup.value).subscribe(
      (response:HttpResponse<BrokerUnderwriterResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  getCurrentUnderwriter(adminId: string){
    this.brokerAdminService.getSelectedUnderwriter(adminId).subscribe(
      (response:HttpResponse<BrokerUnderwriterResponse>) => {
        response.body.underwriter['telephone'].map(() => {
          this.addTelephoneNumber();
        });
        response.body.underwriter['email'].map(() => {
          this.addEmailAddress();
        });
        this.underwriterFormGroup.patchValue(response.body.underwriter)
      }
    )
  };

  updateUnderwriter(saveType: string){

    // activate mat-error
    this.underwriterFormGroup.markAllAsTouched();
    this.formSubmitted = true;

    if (this.underwriterFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Could not save underwriter.')
      return;
    };

    this.brokerAdminService.editUnderwriter(this.underwriterFormGroup.value, this.underwriterId).subscribe(
      (response:HttpResponse<BrokerUnderwriterResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.location.back();
        break;

      case 'new':
        this.underwriterFormGroup.reset()
        break

      case 'continue':
        break;

      default:
        this.location.back();
        break;
    }
  }

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.location.back();
      this.router.navigate(['b/underwriters'])
    };
  }

  initTelephoneNumberField(): FormGroup{
    return this.formBuilder.group({
      number: ['', Validators.required]
    });
   };

  addTelephoneNumber(): void{
    const control = <FormArray>this.underwriterForm.telephone;
    control.push(this.initTelephoneNumberField());
  };

  removeTelephoneNumber(i : number): void {
      const control = <FormArray>this.underwriterForm.telephone;
      control.removeAt(i);
  };

  initEmailField(): FormGroup{
    return this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      emailAddressType: ['', Validators.required]
    });
   };

  addEmailAddress(): void{
    const control = <FormArray>this.underwriterForm.email;
    control.push(this.initEmailField());
  };

  removeEmailAddress(i : number): void {
      const control = <FormArray>this.underwriterForm.email;
      control.removeAt(i);
  };

  ngOnInit() {
    this.underwriterFormGroup = this.formBuilder.group({
      name:             ['', Validators.required],
      referenceNumber:  ['', Validators.required],
      email:            this.formBuilder.array([]),
      taxNumber:        ['', Validators.required],
      telephone:        this.formBuilder.array([]),
      address: this.formBuilder.group({
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        city: '',
        postalCode: ''
      }),
      brokerConsultant: this.formBuilder.group({
        consultantName: '',
        consultantSurname: '',
        consultantEmail: '',
        consultantNumber: ''
      }),
      companyRef:          [''],
    });

    if(!this.editUnderwriter){
      this.addTelephoneNumber();
      this.addEmailAddress();
    }

  }

  ngAfterViewInit(){
    if(this.editUnderwriter || this.viewUnderwriter){
      this.getCurrentUnderwriter(this.underwriterId);
    }
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}

