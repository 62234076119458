import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../delete/delete.component';
import { RequestService } from 'src/app/services/request.service';
import { HttpResponse } from '@angular/common/http';
import { UserRequestResponse } from 'src/app/_shared/models/userRequest.interface';
import { BrokerComponent } from 'src/app/broker/broker.component';

@Component({
  template: '',
})
export class RejectInstructionModalComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  dialogRef = null

  openDialog(data, type: 'instruction' | 'claim'): void {

    data['rejectType'] = type;

    this.dialogRef = this.dialog.open(RejectInstructionModalComponentPreview, {
      // width: '600px',
      data: data
    });
  }

}

@Component({
  selector: 'app-reject-instruction-modal',
  templateUrl: './reject-instruction-modal.component.html',
  styleUrls: ['./reject-instruction-modal.component.scss'],
})
export class RejectInstructionModalComponentPreview implements OnInit {

  rejectReason: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectInstructionModalComponentPreview>,
    private requestService: RequestService,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  rejectRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'reject', { requestHandledMessage: this.rejectReason }).subscribe(
      (response: HttpResponse<UserRequestResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
