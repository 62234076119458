import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class Broker implements Basemodel {
  constructor(
    public _id: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public workPhone?: string,
    public cellphone?: string,
    public company?: string,
    public brokerCode?: string,
    public policyNumber?: string,
    public addedOn?: Date,
    public userRef?: string
  ){}
}

export class BrokerResponse {
    constructor(
      public broker: Broker[],
      public message: SuccessMessage
    ) {
    }
  }