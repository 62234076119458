import { InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';

import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Router } from '@angular/router';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany } from 'src/app/_shared/models/insuranceCompany.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-client-new-structure',
  templateUrl: './client-new-structure.component.html',
  styleUrls: ['./client-new-structure.component.scss']
})
export class ClientNewStructureComponent implements OnInit, AfterViewInit {

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  submitted = false;
  newStructureFormGroup: FormGroup;
  get form() { return this.newStructureFormGroup.controls; }

  constructor(
    private insuranceService: InsuranceService,
    private formBuilder: FormBuilder,
    private personalInsuranceService: PersonalInsuranceService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.options = { concurrency: 2, maxUploads: 6, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  maxDate() {
    return new Date();
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  };

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  };
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  };
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  };

  initStructureFields(): FormGroup{
    return this.formBuilder.group({
      structure 		: ['', Validators.required]
    });
   };
  
   addNewInputField(): void{
      const control = <FormArray>this.form.additionalStructures;
      control.push(this.initStructureFields());
   };

  removeInputField(i : number): void {
      const control = <FormArray>this.form.additionalStructures;
      control.removeAt(i);
   };

   getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  getAllInsuranceCompanies(){
    this.insuranceService.getAllInsuranceCompanies().subscribe(
      (response: HttpResponse<InsuranceCompanyResponse>) => {
        this.insuranceCompanies = response.body.insuranceCompany;
      }
    )
  };

  addNewStructure(saveType:string){
    this.submitted = true;

    if (this.newStructureFormGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new insured structure.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    // Set photos == files
    this.files.map(file => {
      this.newStructureFormGroup.value.photos.push(file.nativeFile);
    });

    this.personalInsuranceService.addNewStructure(this.newStructureFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            break;

          case 'new':
            this.newStructureFormGroup.reset();
            window.scroll({ top: 0, behavior: 'smooth' });
            break;

          default:
            this.goBack();
            break;
        }

      }
    )
  };

  goBack(){
    this.router.navigate(['/u/short-term-insurance/structure']);
  }

  addNewBroker(){
    localStorage.setItem('SureSpace-newInsuredStructure', JSON.stringify(this.newStructureFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-newInsuredStructure')){
      this.newStructureFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-newInsuredStructure')));
      localStorage.removeItem('SureSpace-newInsuredStructure');
    }
  }; 

  ngOnInit() {
    this.newStructureFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      photos: [[]],
      stand: ['', Validators.required],
      suburb: ['', Validators.required],
      city : ['',  Validators.required],
      municipalAccNr : ['',  Validators.required],
      structureValue : ['',  Validators.required],
      roof: this.formBuilder.group({
        corrugatedIron: false,
        tiles: false,
        grass: false,
        glass: false,
        wood: false
      }),
      externalWalls: this.formBuilder.group({
        brick: false,
        cement: false,
        corrugatedIron: false,
        glass: false,
        grass: false,
        prefab: false,
        tiles: false,
        wood: false
      }),
      floor: this.formBuilder.group({
        carpet: false,
        tiles: false,
        sealed: false,
        woodenFloors: false
      }),
      additions: this.formBuilder.group({
        birdCage: false,
        deckOrPatio: false,
        fishPond: false,
        garden: false,
        specialGarden: false,
        poolAndAccessories: false,
        outdoorFurniture: false,
        outdoorOrnaments: false,
        solar: false,
        waterFeature: false
      }),
      security: this.formBuilder.group({
        alarm: false,
        armedResponse: false,
        infraredBeams: false,
        outsideFence: false,
        outsideWall: false,
        pallisades: false,
        lockableGarage: false,
        securityGates: false,
        burglarBars: false,
        trellisDoor: false,
        securityCameras: false,
        intercom: false
      }),
      structures: ['No'],
      additionalStructures: this.formBuilder.array([
        this.formBuilder.group({
          structure 		: ['']
        })
      ]),
      insuredWith: ['', Validators.required],
      dateInsured: ['', Validators.required],
      insuredValue: ['', Validators.required],
      premium: [''],
      policyNumber: ['']
    });
  }

  ngAfterViewInit() {
    this.getAllBrokers();
    this.getAllInsuranceCompanies();
  }

}
