import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  pricingTable:number = 0;
  authenticated: Boolean;

  constructor(
    private authenticateService: AuthenticationService,
  ) {
    this.authenticateService.currentUser.subscribe(user => {
      this.authenticated = !!user;  
    })
  }

  ngOnInit() {
  }

}
