import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    private meta: Meta
  ) {
    meta.addTag({ name: 'description', content: 'SureSpace is a secure online environment where you can store and keep track of all your insurance items' }, true);
    meta.addTag({ name: 'author', content: 'Janneman Erasmus' }, true); 
    meta.addTag({ name: 'keywords', content: 'surespace, insurance, insurance quote, household insurance, vehicle insurance, personal item insurance, building insurance, insurance storage, stolen item registry' }, true)
  }
}
