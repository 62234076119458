<div class="col-12 d-flex mb-5">
    <p class="mr-3 my-auto"><strong>Search document</strong></p>
    <input type="text" placeholder="document name" class="form-control w-50" name="searchDocument" [(ngModel)]="searchDocument" (ngModelChange)="filterDocuments($event)"/>
</div>

<div class="sub-page-heading mb-4">

    <div class="row">

        <div class="col-12">
            <span class="font-14 text-dark-50" [ngClass]="folderLevel.length > 0 ? 'opacity-50 not-last' : 'strong'" (click)="openFolder(null, 0)">Documents</span>
            <span class="font-14 text-dark-50" *ngFor="let folder of folderLevel; last as isLast; first as isFirst; index as i" [ngClass]="!isLast || isFirst ? 'opacity-50 not-last' : 'strong'">
                <span (click)="goToFolder(folder, i + 1)">{{ folder }}</span>
            </span>
        </div>

    </div> <!-- Close div for .row -->

</div> <!-- Close div for .page-header -->

<ng-container *ngIf="filesFound && false">
    <div class="row">

        <div *ngFor="let document of filteredDocuments" id="documentDisplay" class="col-lg-2 col-sm-3 col-6 text-center mx-3 pt-2">

            <div class="header text-right">
                <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="menu">
                    <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
                </button>
        
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="updateDocumentDetails(document)">
                        <span>Edit document</span>
                    </button>
                </mat-menu>
            </div>

            <div class="icon">
                <img [src]="getImageSource(document.documentTypeExtension)" />
            </div>

            <p class="documentName my-3">
                <strong>{{ document?.documentDescription || document?.documentName }}</strong><br />
                <small>{{ document?.documentItemType | sentenceCase }}</small>
            </p>

            <button (click)="viewDocument(document)" class="btn btn-success">View</button>

        </div>

        <div class="col-12 text-center" *ngIf="filteredDocuments.length === 0">
            <p>No files found</p>
        </div>

        <!-- <img [src]="document.documentFile" width="150px" /> -->

    </div>
</ng-container>

<ng-container *ngIf="!filesFound && false">
    <div class="row">
        <div *ngFor="let folder of documentFolderStructures" id="documentDisplay" class="col-lg-2 col-sm-3 col-6 text-center mx-3" (dblclick)="openFolder(folder)">
            <div class="icon mt-4">
                <img src="https://image.flaticon.com/icons/svg/3143/3143008.svg" />
            </div>

            <p class="documentName my-3">
                {{ folder | sentenceCase }}
            </p>

        </div>
    </div>
</ng-container>

<div class="content px-0">

    <table class="mat-table document-list-view">
        <thead role="rowgroup">
            <tr class="mat-header-row" role="row">
                <th class="mat-header-cell" width="50px"></th>
                <th class="mat-header-cell" width="45%">Description</th>
                <th class="mat-header-cell" width="45%">Name</th>
                <th class="mat-header-cell" width="45%">Type</th>
                <th class="mat-header-cell" width="50px"></th>
            </tr>
        </thead>
        <tbody role="rowgroup" [hidden]="searchDocument">
            <tr class="mat-row" role="row" *ngFor="let folder of foldersToDisplay" (dblclick)="openFolder(folder, currentFolderIndex + 1)">
                <td class="mat-cell"><i class="fi flaticon-folder"></i></td>
                <td class="mat-cell">{{ folder | sentenceCase }}</td>
                <td class="mat-cell">Folder</td>
                <td class="mat-cell"></td>
            </tr>
            <tr *ngIf="showCompanyDocuments && filesToDisplay?.length == 0" class="mat-row" role="row" (dblclick)="openFolder('Company Documents', 1)">
                <td class="mat-cell"><i class="fi flaticon-folder"></i></td>
                <td class="mat-cell">Company Documents</td>
                <td class="mat-cell">Folder</td>
                <td class="mat-cell"></td>
            </tr>
            <tr class="mat-row" role="row" *ngFor="let file of filesToDisplay" (dblclick)="!addAsAttachment ? viewDocument(file) : !file?.isAttachment ? addFileAsEmailAttachment(file) : removeFileFromEmailAttachment(file)">
                <td class="mat-cell"><i class="fi flaticon-file"></i></td>
                <td class="mat-cell">{{ file?.documentDescription }}</td>
                <td class="mat-cell">{{ file?.documentName }}</td>
                <td class="mat-cell">File</td>
                <td class="mat-cell" *ngIf="!addAsAttachment">
                    <button class="btn btn-clean btn-icon btn-sm" [matMenuTriggerFor]="fileMenu">
                        <i class="fi flaticon-menu"></i>
                    </button>
                    <mat-menu #fileMenu="matMenu">
                        <button mat-menu-item (click)="viewDocument(file)">
                            <i class="fi flaticon-edit mr-2"></i>View file
                        </button>
                        <a [href]="file.documentFile" target="_blank" mat-menu-item>
                            <i class="fi flaticon-edit mr-2"></i>Download file
                        </a>
                        <!-- <button mat-menu-item (click)="deleteDocument(file)">
                            <i class="fi flaticon-trash mr-2"></i>Delete file
                        </button> -->
                    </mat-menu>
                </td>
                <td class="mat-cell" *ngIf="addAsAttachment">
                    <button class="btn btn-primary btn-sm" *ngIf="!file?.isAttachment" (click)="addFileAsEmailAttachment(file)">Add as attachment</button>
                    <button class="btn btn-danger btn-sm" *ngIf="file?.isAttachment" (click)="removeFileFromEmailAttachment(file)">Remove as attachment</button>
                </td>
            </tr>
            <tr class="no-hover" *ngIf="filesToDisplay?.length == 0 && foldersToDisplay?.size == 0">
                <td class="mat-cell text-center" colspan="4">No documents found</td>
            </tr>
        </tbody>
        <!-- <tbody role="rowgroup" [hidden]="!searchDocument">
            <tr class="mat-row" role="row" *ngFor="let file of searchedFiles">
                <td class="mat-cell"><i class="fi flaticon-file"></i></td>
                <td class="mat-cell">{{ file?.documentTypeDescription }}</td>
                <td class="mat-cell">File</td>
                <td class="mat-cell">
                    <button class="btn btn-clean btn-icon btn-sm" [matMenuTriggerFor]="fileMenu">
                        <i class="fi flaticon-menu"></i>
                    </button>
                    <mat-menu #fileMenu="matMenu">
                        <button mat-menu-item (click)="downloadDocument(file)">
                            <i class="fi flaticon-edit mr-2"></i>Download file
                        </button>
                        <button mat-menu-item (click)="deleteDocument(file)">
                            <i class="fi flaticon-trash mr-2"></i>Delete file
                        </button>
                    </mat-menu>
                </td>
            </tr>
            <tr *ngIf="searchedFiles?.length == 0">
                <td class="mat-cell text-center" colspan="3">No documents found</td>
            </tr>
        </tbody> -->
    </table>

</div> <!-- Close div for .content -->