<h2 mat-dialog-title>
    Accept claim handover?
    <button class="close" mat-dialog-close></button>
</h2>

<mat-dialog-content>
    This claim is being handed over from {{ data.employee }} to you.<br />
    Do you accept this handover?
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="btn btn-danger text-white" (click)="rejectRequest(data.requestId)">Reject</button>
    <button class="btn btn-success text-white" (click)="acceptRequest(data.requestId)">Accept</button>
</mat-dialog-actions>