import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, AfterViewInit {

  token: string = this.activeRoute.snapshot.paramMap.get('token');

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private autenticationService: AuthenticationService
  ) { }

  activateUser(){
    this.autenticationService.activateUser(this.token).subscribe(
      (response:HttpResponse<any>) => {
      }
    )

    this.router.navigate(['/login']);
  }

  ngOnInit(){ }

  ngAfterViewInit() {
    this.activateUser();
  }

}
