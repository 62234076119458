import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogData } from 'src/app/components/modal/delete/delete.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from 'src/app/services/document.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-broker-edit-client-document-details',
  templateUrl: './broker-edit-client-document-details.component.html',
  styleUrls: ['./broker-edit-client-document-details.component.scss']
})
export class BrokerEditClientDocumentDetailsComponent implements OnInit {

  formSubmitted: boolean = false;
  documentFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<BrokerEditClientDocumentDetailsComponent>,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.documentFormGroup = this.formBuilder.group({
      documentName: ['', Validators.required],
      documentDescription: ['', Validators.required],
    });

    this.documentFormGroup.patchValue(this.data['document'])
    this.documentFormGroup.get('documentName').setValue(this.documentFormGroup.get('documentName').value.replace(`.${this.data['document'].documentTypeExtension}`, ''))
  }

  editFileDetails(){

    if(this.documentFormGroup.invalid){
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save document details.')
      return;
    }

    this.documentFormGroup.get('documentName').setValue(`${this.documentFormGroup.get('documentName').value}.${this.data['document'].documentTypeExtension}`);

    this.documentService.editDocumentDetails(this.data['document']._id, this.documentFormGroup.value).subscribe(() => {
      this.toastr.success('The document details have been updated.', 'Document details saved.')
      this.closeModal({status: 'success', data: this.documentFormGroup.value});
    })
  }

  closeModal(status?){
    this.dialogRef.close(status);
  }

}
