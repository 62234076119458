<div id="content" class="row">

    <sub-navigation *ngIf="!editEmployee" heading="New employee"></sub-navigation>
    <sub-navigation *ngIf="editEmployee" heading="{{ employeeFormGroup.controls['firstName'].value }} {{ employeeFormGroup.controls['lastName'].value }}"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>Employee information</h5>

                <div class="my-auto">

                    <button matTooltip="Go back to view employees" class="btn btn-secondary my-auto" (click)="location.back()"><i class="fi flaticon-back"></i>Back</button>
                    <button *ngIf="!viewEmployee" matTooltip="{{ editEmployee ? 'Save' : 'Add new' }} employee and go back" type="button" class="btn btn-brand my-auto" (click)="editEmployee ? saveEmployee('back') : addEmployee('back')">
                {{ editEmployee ? 'Save' : 'Add' }} employee
            </button>

            <button *ngIf="!viewEmployee" class="btn btn-light btn-pill my-auto color-brand" [matMenuTriggerFor]="menuEdit">
                <i class="fi flaticon-menu mr-0"></i>
            </button>

                    <mat-menu xPosition="before" yPosition="below" #menuEdit="matMenu">
                        <button type="button" (click)="editEmployee ? saveEmployee('back') : addEmployee('back')" mat-menu-item>
                      <span>{{ editEmployee ? 'Save' : 'Add' }} &amp; go back</span>
                  </button>
                        <button type="button" *ngIf="!editEmployee" (click)="editEmployee ? saveEmployee('new') : addEmployee('new')" mat-menu-item>
                      <span>Save &amp; add new</span>
                  </button>
                    <button type="button" *ngIf="editEmployee" (click)="editEmployee ? saveEmployee('continue') : addEmployee('continue')" mat-menu-item>
                      <span>Save &amp; continue</span>
                  </button>
                    </mat-menu>

                </div>
                <!-- Close div for .my-auto -->

            </div>
            <!-- Close div for .panel-heading -->

            <div class="panel-body">

                <form [formGroup]="employeeFormGroup">

                    <div class="row mt-3">

                        <div class="col-sm-4 col-12">
                            <!-- ID Number -->
                            <mat-form-field>
                                <input matInput name="username" placeholder="ID number" type="text" formControlName="username" (blur)="determineGender()" />
                                <mat-error *ngIf="employeeForm['username'].errors && employeeForm['username'].errors.required">
                                    Please enter employee <strong>ID number</strong>.
                                </mat-error>
                                <mat-error *ngIf="employeeForm['username'].errors && employeeForm['username'].errors.minLength">
                                    ID number must be <strong>13 characters</strong> long.
                                </mat-error>
                                <mat-error *ngIf="employeeForm['username'].errors && employeeForm['username'].errors.invalidIdNumber">
                                    Please enter a valid <strong>SA ID number</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-4 col-12">
                            <!-- First Name -->
                            <mat-form-field>
                                <input matInput name="firstName" placeholder="First name" type="text" formControlName="firstName" />
                                <mat-error>
                                    Please enter employee <strong>first name</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-4 col-12">
                            <!-- Last Name -->
                            <mat-form-field>
                                <input matInput name="lastName" placeholder="Last name" type="text" formControlName="lastName" />
                                <mat-error>
                                    Please enter employee <strong>last name</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr class="hr-dashed hr-light" />
                        </div>

                        <div class="col-sm-6 col-12">
                            <!-- Email -->
                            <mat-form-field>
                                <input matInput name="email" placeholder="Email address" type="text" formControlName="email" />
                                <mat-error *ngIf="employeeForm['email']?.errors?.required">
                                    Please enter employee <strong>email address</strong>.
                                </mat-error>
                                <mat-error *ngIf="employeeForm['email']?.errors?.pattern">
                                    Please enter a <strong>valid email address</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-6 col-12">
                            <!-- Cellphone -->
                            <mat-form-field>
                                <input matInput name="cellphone" placeholder="Mobile number" type="text" formControlName="cellphone" />
                                <mat-error>
                                    Please enter employee <strong>cellphone number</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <hr class="hr-dashed hr-light" />
                        </div>

                        <div class="col-sm-4 col-12" formGroupName="company">
                            <!-- Start Date -->
                            <mat-form-field class="example-full-width">
                                <input placeholder="Start date" matInput [matDatepicker]="startDate" name="startDate" formControlName="startDate">
                                <mat-error>
                                    Please select the <strong>starting date</strong> for your employee.
                                </mat-error>

                                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #startDate></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-4 col-12" formGroupName="company">
                            <!-- Employee code -->
                            <mat-form-field>
                                <input matInput name="employeeCode" placeholder="Employee code" type="text" formControlName="employeeCode" />
                                <!-- <mat-error>
                    Please enter <strong>employee code</strong>.
                  </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div class="col-sm-4 col-12" *ngIf="currentUserPermissions.includes('brokerDirector') || currentUserPermissions.includes('brokerHR')">
                            <div class="form-group" formGroupName="company">
                                <mat-form-field>
                                    <mat-select placeholder="Permission" name="permissions" formControlName="permissions" multiple>
                                        <mat-option value="brokerDirector">
                                            Director
                                        </mat-option>
                                        <mat-option value="brokerHR">
                                            HR
                                        </mat-option>
                                        <mat-option value="brokerManager">
                                            Manager
                                        </mat-option>
                                        <mat-option value="brokerTeamLead">
                                            Team leader
                                        </mat-option>
                                        <mat-option value="brokerClaimsTechnician">
                                            Claims technician
                                        </mat-option>
                                        <mat-option value="brokerAdmin">
                                            Administrator
                                        </mat-option>
                                        <mat-option value="brokerClerk">
                                            Clerk
                                        </mat-option>
                                        <mat-option value="brokerLeadProvider">
                                            Lead provider
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <!-- Close div for .form-group -->
                        </div>

                        <div class="col-sm-4 col-12">
                            <div class="form-group" formGroupName="company">
                                <mat-form-field>
                                    <mat-select placeholder="Capacity" name="capacity" formControlName="capacity" multiple>
                                        <mat-option value="intermediary">
                                            Intermediary
                                        </mat-option>
                                        <mat-option value="advice">
                                            Advice
                                        </mat-option>
                                        <mat-option value="keyIndividual">
                                            Key individual
                                        </mat-option>
                                        <mat-option value="complianceOfficer">
                                            Compliance officer
                                        </mat-option>
                                    </mat-select>

                                    <mat-error>
                                        Please select an employee <strong>role</strong>.
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <!-- Close div for .form-group -->
                        </div>

                        <div class="col-sm-4 col-12">
                            <div class="form-group" formGroupName="company">
                                <mat-form-field>
                                    <mat-select placeholder="Location" name="branch" formControlName="branch">
                                        <mat-option disabled *ngIf="companyLocations?.length === 0">
                                            No addresses found
                                        </mat-option>
                                        <mat-option *ngFor="let location of companyLocations" [value]="location.addressDescription">
                                            {{ location.addressDescription }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <!-- Close div for .form-group -->
                        </div>

                    </div>
                    <!-- Close div for .row -->

                </form>

            </div>
            <!-- Close div for .panel-body -->

        </div>
        <!-- Close div for .panel-primary -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for .container -->