<div class="modal-header">
    <h6 class="mb-0">Remove broker?</h6>
  </div>
    
  <div class="modal-body">
    <div mat-dialog-content>
      <p class="mb-4 mt-4">
          Are you sure you want to permanently remove <strong>{{ broker.firstName }} {{ broker.lastName }}</strong> as a broker?
          <br />
          This action cannot be undone.
      </p>
    </div>
  </div>
  
  <div class="modal-footer">
    <div class="inline-buttons">
      <app-cancel-button cdkFocusInitial (click)="closeDialog('cancel')"></app-cancel-button>
      <app-delete-button (click)="deleteBroker(broker)"></app-delete-button>
    </div>
  </div>
  