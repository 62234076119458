import { HttpResponse } from '@angular/common/http';
import { ClientService } from 'src/app/services/client-service.service';
import { Component, OnInit, Inject, HostListener, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Notifications, NotificationResponse } from 'src/app/_shared/models/notifications.model';

import { trigger, state, transition, style, animate } from '@angular/animations';  
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-client-component',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
  )]
})
export class ClientComponent implements OnInit, AfterViewInit {

  authenticated;
  currentUser = this.authService.currentUserValue;

  notifications: Notifications[];
  newNotifications: number;

  messages: any = [];
  newMessages: number = 0;

  constructor(
    @Inject(DOCUMENT) document,
    private authService: AuthenticationService,
    private clientService: ClientService
  ) { }

  logout(){
    this.authService.logoutUser();
  }

  getUserNotifications(){
    this.clientService.getUserNotifications().subscribe(
      (response: HttpResponse<NotificationResponse>) => {
        this.notifications = response.body.notifications;
        this.newNotifications = response.body.newNotifications
      }
    )
  }

  notificationClick(notification:Notifications){

    // TODO: Go to notification content - if any;

    if(!notification.isSeen){
      this.clientService.markNotificationAsRead(notification._id).subscribe(
        (response: HttpResponse<NotificationResponse>) => {
            this.newNotifications -= 1;
            notification.isSeen = true;
          }
      )
    }
  }

  ngAfterViewInit(){
    this.getUserNotifications();
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(token => {
      this.authenticated = !!token;  
    })
  }

  messagesDropdownStatus: string = "ddClosed";
  onMessagesMenuOpenChange(isOpen: boolean):void {
    isOpen ? this.messagesDropdownStatus = 'ddMessageOpened' : this.messagesDropdownStatus = "ddClosed";
  }

  notificationDropdownStatus: string = "ddClosed";
  onNotificationMenuOpenChange(isOpen: boolean):void {
    isOpen ? this.notificationDropdownStatus = 'ddNotifyOpened' : this.notificationDropdownStatus = "ddClosed";
  }

  userDropdownStatus: string = "ddClosed";
  onUserMenuOpenChange(isOpen: boolean):void {
    isOpen ? this.userDropdownStatus = 'ddOpened' : this.userDropdownStatus = "ddClosed";
  }

  navDropdownStatus: string = "ddClosed";
  onNavMenuOpenChange(isOpen: boolean):void {
    isOpen ? this.navDropdownStatus = 'navDropdownOpened' : this.navDropdownStatus = "ddClosed";
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 180) {
       let element = document.getElementById('header');
       element.classList.remove('slideInUp')
       element.classList.add('slideInDown', 'animated', 'faster', 'fixed-top', 'header-scrolled');
     } else {
      let element = document.getElementById('header');
        element.classList.remove('slideInDown', 'fixed-top', 'header-scrolled');
        element.classList.add('slideInUp')
     }
  }

}
