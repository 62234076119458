import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { DocumentModel, DocumentResponseModel } from '../_shared/models/document.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  uploadDocument(formData: FormData, companyName:string, clientId:string): Observable<HttpResponse<DocumentModel>>{
    return this.http.post<DocumentModel>(`${this.globals.SERVER_URL}/api/documents/${companyName}/${clientId}`, formData, {
      observe: 'response'
    });
  };

  getClientDocuments(companyName:string, clientId:string): Observable<HttpResponse<DocumentResponseModel>>{
    return this.http.get<DocumentResponseModel>(`${this.globals.SERVER_URL}/api/documents/${companyName}/${clientId}`, {
      observe: 'response'
    });
  };

  editDocumentDetails(documentId:string, documentFormData): Observable<HttpResponse<DocumentResponseModel>>{
    return this.http.put<DocumentResponseModel>(`${this.globals.SERVER_URL}/api/documents/${documentId}`, documentFormData, {
      observe: 'response'
    });
  };

  uploadCompanyDocument(formData: FormData, companyName:string): Observable<HttpResponse<DocumentModel>>{
    return this.http.post<DocumentModel>(`${this.globals.SERVER_URL}/api/documents/companyDocuments/company/${companyName}`, formData, {
      observe: 'response'
    });
  };

  getCompanyDocuments(companyName:string): Observable<HttpResponse<DocumentResponseModel>>{
    return this.http.get<DocumentResponseModel>(`${this.globals.SERVER_URL}/api/documents/companyDocuments/company/${companyName}`, {
      observe: 'response'
    });
  };

}
