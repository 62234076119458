<div id="content" class="row">

    <sub-navigation heading="ID number tester"></sub-navigation>
  
    <div class="col-12">
  
        <div class="panel panel-primary">
    
            <div class="panel-heading">
                <h5>Test an ID Number</h5>

                <div class="my-auto">
                    <button (click)="validateIdNumber()" class="btn btn-brand my-auto">Validate ID number</button>
                </div>          
            </div> <!-- Close div for .panel-heading -->
    
            <div class="panel-body pb-5">

                <form [formGroup]="formGroup">
    
                    <div class="row">
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput name="idNumber" placeholder="ID number" type="text" formControlName="idNumber" />
                          <!-- <mat-error *ngIf="formGroup.get('idNumber').errors.required">
                            Please enter client <strong>ID number</strong>.
                          </mat-error>
                          <mat-error *ngIf="formGroup.get('idNumber').errors.minLength">
                              ID number must be <strong>13 characters</strong> long.
                          </mat-error>
                          <mat-error *ngIf="formGroup.get('idNumber').errors.invalidIdNumber">
                            Please enter a valid <strong>SA ID number</strong>.
                          </mat-error> -->
                        </mat-form-field>
                      </div>

                      <div *ngIf="testingId" class="col-12">
                        <p>
                            <span><strong>Date of birth: </strong> {{ idNumberValidation?.dob | date: 'dd MMMM yyyy' }} </span><br />
                            <span><strong>Age: </strong> {{ idNumberValidation?.age }} </span><br />
                            <span><strong>Citizenship: </strong> {{ idNumberValidation?.citizenship }} </span><br />
                            <span><strong>Gender: </strong> {{ idNumberValidation?.gender }} </span><br />
                            <span><strong>Race: </strong> {{ idNumberValidation?.race }} </span><br />
                            <span><strong>Valid: </strong> {{ idNumberValidation?.isValid ? 'YES' : 'NO' }} </span><br />
                            <span *ngIf="idNumberValidation?.error"><strong>Error: </strong> {{ idNumberValidation?.error }} </span>
                        </p>
                      </div>

                    </div>

                </form>

            </div> <!-- Close div for .panel-body -->

        </div> <!-- Close div for .panel -->

    </div>

</div>