import { MatTableDataSource } from '@angular/material/table';
import { BrokerCommercialProductDeleteComponent } from './delete-products/broker-commercial-product-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { CommercialProductsService } from './../../../services/commercialProduct.service';
import { CommercialProduct, CommercialProductResponse } from './../../../_shared/models/commercialProduct.model';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'broker-commercial-clients-products',
  templateUrl: './broker-commercial-clients-products.component.html',
  styleUrls: ['./broker-commercial-clients-products.component.scss']
})
export class BrokerCommercialClientsProductsComponent implements OnInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('clientId');

  userProducts: MatTableDataSource<CommercialProduct>
  previouslyInsuredProducts: CommercialProduct[]

  displayedColumns: string[] = ['description', 'productType', 'productValue', 'premium', 'policyNumber', 'inceptionDate', 'menu'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100]
  isLoadingResults = true;
  isProductRateLimitReached = false;
  isPreviousProductRateLimitReached = false;

  resultsLengthPreviousInsurance = 0;
  pageSizePreviousInsurance = 25;

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private commercialProductService: CommercialProductsService,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getUserVaps(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isProductRateLimitReached){

      this.isLoadingResults = true;

      this.commercialProductService.getAllCommercialProductForClient(this.selectedUser, this.authService.currentCompany.companyName, req).subscribe(
        (response:HttpResponse<CommercialProductResponse>) => {
          this.isLoadingResults = false;
          // this.userProducts = response.body.products;
          this.userProducts = new MatTableDataSource(response.body.products)

          this.resultsLength = response.body.count;
          if(this.userProducts.data.length < this.pageSize){
            this.isProductRateLimitReached = true;
          }
        }
      )
    }
  };

  getPreviousInsuredProducts(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.previouslyInsuredProducts){

      this.isLoadingResults = true;

      this.commercialProductService.getPreviousCommercialProductForClient(this.selectedUser, this.authService.currentCompany.companyName, req).subscribe(
        (response:HttpResponse<CommercialProductResponse>) => {
          console.log('previously => ', response.body.products)
          this.isLoadingResults = false;
          this.previouslyInsuredProducts = response.body.products;

          this.resultsLengthPreviousInsurance = response.body.count;
          if(this.previouslyInsuredProducts.length < this.pageSize){
            this.isPreviousProductRateLimitReached = true;
          }
        }
      )
    }

  }

  deleteProduct(product){
    let dialogRef = null;

    product['clientId'] = this.selectedUser;

    dialogRef = this.dialog.open(BrokerCommercialProductDeleteComponent, {
      width: '600px',
      data: product
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response === 'success'){
        this.userProducts.data = [...this.userProducts.data].filter(p => p._id !== product._id);
        this.getPreviousInsuredProducts();
      }
    })

  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserVaps();
    this.getPreviousInsuredProducts();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') ||  this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
