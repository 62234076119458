<div class="page-description">
    <div class="row h-100">
      <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">My family</h5>
        <h6 class="d-inline-block mb-0">New family member</h6>
      </div>
  
      <div class="col-6 text-right align-self-center">

        <button type="button" class="btn btn-secondary" (click)="location.back()">
            Back
        </button>
  
        <div class="btn-group" ngbDropdown>
          <button type="button" class="btn btn-primary" (click)="addNewFamilyMember()">
            Save member
          </button>
          <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
          </button>
          <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem"><a class="dropdown-item" href="#">Save &amp; continue</a></li>
            <li role="menuitem"><a class="dropdown-item" href="#">Save &amp; go back</a></li>
          </ul>
        </div> <!-- Close div for .btn-group -->
  
      </div>
    </div>  
  </div>
  
  <div class="row">
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
            <h5>Add a new family member</h5>
        </div> <!-- Close div for .panel-heading -->
  
            <div class="panel-body pt-3 pb-5">
            
                <form id="newFamilyMemberForm" [formGroup]="newFamilyMemberForm" (ngSubmit)="addNewFamilyMember()">
          
                      <div class="row">
          
                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>Name:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">
                          <input name="name" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedForm && form.name.errors }" formControlName="name" />
          
                          <div *ngIf="submittedForm && form['name'].errors" class="invalid-feedback">
                            <div *ngIf="form['name'].errors.required">Please enter a name for your new family member.</div>
                          </div>
          
                        </div>
          
                        <div class="col-12 clearfix">
                        </div>
          
                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>Surname:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">
                          <input name="surname" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedForm && form.surname.errors }" formControlName="surname" />
          
                          <div *ngIf="submittedForm && form['surname'].errors" class="invalid-feedback">
                            <div *ngIf="form['surname'].errors.required">Please enter a surname for your new family member.</div>
                          </div>
          
                        </div>
          
                        <div class="col-12 clearfix">
                        </div>
          
                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>Relationship:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">

                            <div class="input-group">
                                <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submittedForm && form['relationship'].errors }">
                                    <mat-select name="relationship" formControlName="relationship">
                                    <mat-option value="Spouse">Spouse</mat-option>
                                    <mat-option value="Child">Child</mat-option>
                                    <mat-option value="Parent">Parent</mat-option>
                                    <mat-option value="Nominated">Nominated</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div *ngIf="submittedForm && form['relationship'].errors" class="invalid-feedback">
                                    <div *ngIf="form['relationship'].errors.required">Please choose family member relationship.</div>
                                </div>

                            </div> <!-- Close div for .input-group -->
                        </div>
          
                        <div class="col-12 clearfix">
                        </div>
          
                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>ID Number:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">
                          <input name="idNumber" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedForm && (!validId || form.idNumber.errors) }" formControlName="idNumber" />
          
                          <div *ngIf="submittedForm && (!validId || form['idNumber'].errors)" class="invalid-feedback d-block">
                            <div *ngIf="form['idNumber'].errors && form['idNumber'].errors.required">Please enter a valid id number.</div>
                            <div *ngIf="!validId">Please enter a valid SA ID number.</div>
                          </div>
                        </div>
          
                      </div> <!-- Close div for .row -->

                      <div class="row">

                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>Email address:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">
                          <input name="email" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedForm && form.email.errors }" formControlName="email" />
                        </div>

                        <div class="col-12 clearfix">
                        </div>

                        <div class="col-xl-3 col-lg-3 text-right my-auto">
                          <strong>Cellphone number:</strong>
                        </div>
          
                        <div class="col-lg-9 col-xl-6">
                          <input name="cellphone" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedForm && form.cellphone.errors }" formControlName="cellphone" />
                        </div>

                      </div>
          
                  </form>
  
            </div> <!-- Close div for .panel-body-->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for .row -->