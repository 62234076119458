<h1>Input</h1>

<div id="input" class="row">

  <div class="col-6">
    <input class="form-control" type='text' placeholder="placholder" />
  </div>

  <div class="col-6">
    <input class="form-control is-invalid" type='text' placeholder="placholder"  />
  </div>

  <div class="col-12 input-group">
    <mat-form-field>
      <mat-select name="itemCategory" [(ngModel)]="select">
        <mat-option option value="" disabled>None</mat-option>
        <mat-option value="Option 1">Option 1</mat-option>
        <mat-option value="Option 2">Option 2</mat-option>
        <mat-option value="Option 3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>      
  </div> <!-- Close div for .input-group-->

  <div class="input-group col-12">
    <div class="input-group-prepend">
      <div class="input-group-text">R</div>
    </div>
    <input type="text" class="form-control" name="purchasePrice" />
  </div>

</div> <!-- Close div for #input -->