<div *ngIf="hasRequestAttached" id="instruction-handover" class="panel panel-primary">
    <div class="row py-3 px-3">
        <div class="col-12 col-md-8 my-auto">
            <h5 class="mb-0">
                <strong *ngIf="request?.whatNeedsToHappen === 'handover'">
                    This claim is being handed over from {{ claimFormGroup.get('employee').get('firstName').value }} to you.
                    Do you accept this handover?
                </strong>
                <strong *ngIf="request?.whatNeedsToHappen === 'signOff'">
                    {{ request?.message }}  Do you approve this signoff?
                </strong>
            </h5>
        </div>
        <div class="col-12 col-md-4 text-center text-md-right">
            <button class="btn btn-success text-white" (click)="acceptRequest(request._id)">Accept</button>
            <button class="btn btn-danger text-white" (click)="rejectRequest(request._id)">Reject</button>
        </div>
    </div> <!-- Close div for .row -->
</div>

<div id="content" class="row">
    <sub-navigation heading="{{ editClaim ? 'Edit' : 'Add new' }} claim"></sub-navigation>

    <div class="col-12">
  
        <div class="panel panel-primary">

            <div class="panel-heading">
                <h5>Claim details</h5>
      
                <div class="my-auto">
      
                  <button type="button" matTooltip="Go back to view all claims" class="btn btn-secondary my-auto" (click)="goBack()">
                    <i class="fi flaticon-back"></i>Back
                  </button>
      
                  <div class="btn-group" ngbDropdown>
                    <button type="submit" class="btn btn-primary" mat-tooltip="Save claim and go back." (click)="editClaim ? updateClaim('back') : addNewClaim('back')">
                      Save claim
                    </button>
                    <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      aria-controls="dropdown-basic">
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('continue') : addNewClaim('continue')">Save &amp; continue</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('new') : addNewClaim('new')">Save &amp; add new</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('back') : addNewClaim('back')">Save &amp; go back</a></li>
                    </ul>
                  </div> <!-- Close div for .btn-group -->
      
                </div> <!-- Close div for .my-auto -->
      
              </div>
      
              <div class="panel-body">
                  
                    <div class="row">
                        <broker-claim-form-group></broker-claim-form-group>
                    </div>

                </div>

            
        </div>

    </div>

</div>

<div id="content" class="row d-none">

    <sub-navigation heading="{{ editClaim ? 'Edit' : 'Add new' }} claim"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
        <form id="claimFormGroup" [formGroup]="claimFormGroup">
  
          <div class="panel-heading">
            <h5>Claim details</h5>
  
            <div class="my-auto">
  
              <button type="button" matTooltip="Go back to view all claims" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>
  
              <div class="btn-group" ngbDropdown>
                <button type="submit" class="btn btn-primary" mat-tooltip="Save claim and go back." (click)="editClaim ? updateClaim('back') : addNewClaim('back')">
                  Save claim
                </button>
                <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('continue') : addNewClaim('continue')">Save &amp; continue</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('new') : addNewClaim('new')">Save &amp; add new</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="editClaim ? updateClaim('back') : addNewClaim('back')">Save &amp; go back</a></li>
                </ul>
              </div> <!-- Close div for .btn-group -->
  
            </div> <!-- Close div for .my-auto -->
  
          </div>
  
          <div class="panel-body">
              
                <div class="row">

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group" formGroupName="client">
                            <mat-form-field>
                                <input
                                  matInput 
                                  type="text"
                                  aria-label="Client"
                                  placeholder="Client"
                                  formControlName='display'
                                  [matAutocomplete]="user"
                                />
                                <mat-hint [hidden]="claimForm['client']['controls'].display.dirty">Type a name to start the search</mat-hint>

                                <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayFn.bind(this)">
                                    <mat-option *ngIf="isLoading" class="is-loading">Finding clients...</mat-option>

                                    <ng-container *ngIf="!isLoading">
                                        <mat-option disabled *ngIf="clients?.length === 0 && claimFormGroup.get('client').value">
                                            <span>No client found</span>
                                        </mat-option>
                                        <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="mapClient(client)">
                                            <span>{{ client?.firstName }} {{ client?.lastName }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>

                                <mat-error>
                                    Please select a <strong>client</strong> for this claim.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <mat-label>Please select a product type</mat-label>
                                <mat-select
                                    name="productType"
                                    [disabled]="!claimFormGroup.get('client').get('_id').value"
                                    formControlName="productType"
                                    [ngClass]="{ 'is-invalid': formSubmitted && claimForm['productType'].errors }"
                                    (selectionChange)="getProducts()"
                                >
                                    <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                                    <mat-option value="vehicle">Vehicle</mat-option>
                                    <mat-option value="building">Building</mat-option>
                                    <mat-option value="homeContent">Home contents</mat-option>
                                    <mat-option value="allRisk">All risk item</mat-option>
                                </mat-select>

                                <mat-error>
                                    Please select a <strong>product type</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <!--<div class="col-sm-6 col-12 mb-3">
                        <div class="input-group" formGroupName="product">

                            <mat-form-field class="mb-0" >
                                <mat-label>Product</mat-label>
                                <mat-select
                                    name="description"
                                    [disabled]="!claimFormGroup.get('productType').value"
                                    [ngClass]="{ 'is-invalid': formSubmitted && claimFormGroup.get('product').get('description').errors }"
                                >
                                    <mat-option disabled *ngIf="products?.length === 0 && claimFormGroup.get('product').value">
                                        <span>No {{ claimFormGroup.get('productType').value | sentenceCase }}s found</span>
                                    </mat-option>
                                    <span *ngIf="claimFormGroup.get('productType').value === 'vehicle'">
                                        <mat-option *ngFor="let product of products" [value]="product.make + ' ' + product.model + ' - ' + product.registrationNumber" (onSelectionChange)="mapVehicle(product)">
                                            <span>{{ product.make }} {{ product.model }} - {{ product.registrationNumber }}</span>
                                        </mat-option>
                                    </span>
                                    <span *ngIf="claimFormGroup.get('productType').value === 'building' || claimFormGroup.get('productType').value === 'homeContent'">
                                        <mat-option *ngFor="let product of products" [value]="product?.address?.formattedAddress" (onSelectionChange)="mapBuilding(product)">
                                            <span>{{ product?.address?.formattedAddress }}</span>
                                        </mat-option>
                                    </span>
                                    <span *ngIf="claimFormGroup.get('productType').value === 'allRisk'">
                                        <mat-option *ngFor="let product of products" [value]="product?.itemName" (onSelectionChange)="mapAllRisk(product)">
                                            <span>{{ product?.itemName }}</span>
                                        </mat-option>
                                    </span>
                                </mat-select>

                                <mat-error>
                                    Please select <strong>{{ claimFormGroup.get('productType').value | sentenceCase | indefinite }}</strong> for this claim.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group --
                    </div> -->

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group" formGroupName="product">

                            <!-- FAKE INPUT TO SIMULATE DISABLED -->
                            <mat-form-field [hidden]="claimFormGroup.get('productType').value">
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Product"
                                    name="product"
                                    disabled
                                />
                                <mat-hint>Select a product to be able to search for it.</mat-hint>
                            </mat-form-field>

                            <mat-form-field [hidden]="!claimFormGroup.get('productType').value">
                                <input
                                    matInput
                                    type="text"
                                    aria-label="Product"
                                    placeholder="Product"
                                    formControlName='description'
                                    [matAutocomplete]="product"
                                />
                                <mat-hint [hidden]="claimForm['product']['controls'].description.dirty">Type a {{ claimFormGroup.get('productType').value | sentenceCase }} to start the search</mat-hint>

                                <mat-autocomplete autoActiveFirstOption #product="matAutocomplete" [displayWith]="displayFnProd.bind(this)">
                                    <mat-option *ngIf="isLoading" class="is-loading">Finding {{ claimFormGroup.get('productType').value | sentenceCase }}s...</mat-option>

                                    <ng-container *ngIf="!isLoading">
                                        <mat-option disabled *ngIf="products?.length === 0 && claimFormGroup.get('product').value">
                                            <span>No {{ claimFormGroup.get('productType').value }}s found</span>
                                        </mat-option>
                                        <span *ngIf="claimFormGroup.get('productType').value === 'vehicle'">
                                            <mat-option *ngFor="let product of products" [value]="product.make + ' ' + product.model + ' - ' + product.registrationNumber" (onSelectionChange)="mapVehicle(product)">
                                                <span>{{ product.make }} {{ product.model }} - {{ product.registrationNumber }}</span>
                                            </mat-option>
                                        </span>
                                        <span *ngIf="claimFormGroup.get('productType').value === 'trailersAndCaravans'">
                                            <mat-option *ngFor="let product of products" [value]="product.type + ' - ' + product.make + ' ' + product.model" (onSelectionChange)="mapTrailersAndCaravans(product)">
                                                <span>{{ product.type }} - {{ product.make }} {{ product.model }}</span>
                                            </mat-option>
                                        </span>
                                        <span *ngIf="claimFormGroup.get('productType').value === 'building' || claimFormGroup.get('productType').value === 'homeContent'">
                                            <mat-option *ngFor="let product of products" [value]="product?.address?.formattedAddress" (onSelectionChange)="mapBuilding(product)">
                                                <span>{{ product?.address?.formattedAddress }}</span>
                                            </mat-option>
                                        </span>
                                        <span *ngIf="claimFormGroup.get('productType').value === 'allRisk'">
                                            <mat-option *ngFor="let product of products" [value]="product?.itemName" (onSelectionChange)="mapAllRisk(product)">
                                                <span>{{ product?.itemName }}</span>
                                            </mat-option>
                                        </span>
                                        <span *ngIf="claimFormGroup.get('productType').value === 'vaps'">
                                            <mat-option *ngFor="let product of products" [value]="product?.desctiption" (onSelectionChange)="mapVaps(product)">
                                                <span>{{ product?.description }}</span>
                                            </mat-option>
                                        </span>
                                    </ng-container>
                                </mat-autocomplete>

                                <mat-error>
                                    Please select a <strong>{{ claimFormGroup.get('productType').value }}</strong> for this claim.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <!--<div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <mat-label>Please select a claim type</mat-label>
                                <mat-select
                                    name="claimType"
                                    formControlName="claimType"
                                    [ngClass]="{ 'is-invalid': formSubmitted && claimForm['claimType'].errors }">
                                    <!-- <mat-option value="" disabled>Please select a claim type</mat-option> ->
                                    <mat-option value="motor">Motor</mat-option>
                                    <mat-option value="nonMotor">Non-motor</mat-option>
                                </mat-select>

                                <mat-error>
                                    Please select a <strong>claim type</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group ->
                    </div>-->

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group" formGroupName="employee">

                            <mat-form-field>
                                <input
                                    matInput 
                                    type="text"
                                    aria-label="Employee"
                                    placeholder="Employee"
                                    formControlName='display'
                                    [matAutocomplete]="employee"
                                />
                                <mat-hint [hidden]="claimForm['employee']['controls'].display.dirty">Type an employee name to start the search</mat-hint>

                                <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayFnEmployee.bind(this)">
                                    <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>

                                    <ng-container *ngIf="!isLoading">
                                        <mat-option disabled *ngIf="employees?.length === 0 && claimFormGroup.get('employee').value">
                                            <span>No employee found</span>
                                        </mat-option>
                                        <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="mapEmployee(employee)">
                                            <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>

                                <mat-error>
                                    Please select an employee for this claim.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <mat-label>Claim description</mat-label>
                                <mat-select
                                    name="claimDescription"
                                    formControlName="claimDescription"
                                    [ngClass]="{ 'is-invalid': formSubmitted && claimForm['claimDescription'].errors }">
                                    <!-- <mat-option value="" disabled>Please select a claim type</mat-option> -->
                                    <mat-option value="partialLoss">Partial loss</mat-option>
                                    <mat-option value="totalLoss">Total loss</mat-option>
                                </mat-select>

                                <mat-error>
                                    Please select a <strong>claim type</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input placeholder="Date of loss" matInput [matDatepicker]="dateOfLoss" name="dateOfLoss"
                                    formControlName="dateOfLoss">
                                <mat-datepicker-toggle matSuffix [for]="dateOfLoss"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #dateOfLoss></mat-datepicker>

                                <mat-error>
                                    Please select a date the <strong>claim loss</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <ng-container *ngIf="claimForm['claimDescription'].value === 'partialLoss'" formGroupName="authorization">

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <textarea 
                                        matInput
                                        type="text"
                                        placeholder="Authorization granted to"
                                        name="company"
                                        formControlName="company"
                                    ></textarea>
    
                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>
    
                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <textarea 
                                        matInput
                                        type="text"
                                        placeholder="Authorization telephone"
                                        name="telephone"
                                        formControlName="telephone"
                                    ></textarea>
    
                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>
    
                    </ng-container>

                    <div class="col-sm-6 col-12 mb-3">
                            <mat-form-field class="mb-0">
                                <input placeholder="Date reported" matInput [matDatepicker]="claimReportDate" name="claimReportDate"
                                    formControlName="dateReported">
                                <mat-datepicker-toggle matSuffix [for]="claimReportDate"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #claimReportDate></mat-datepicker>

                                <mat-error>
                                    Please select a date the claim was <strong>reported</strong>.
                                </mat-error>

                            </mat-form-field>
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="text"
                                    placeholder="Claim number"
                                    name="claimNo"
                                    formControlName="claimNo"
                                />

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="text"
                                    placeholder="Underwriters claim number"
                                    name="underwritersClaimNo"
                                    formControlName="underwritersClaimNo"
                                />

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <mat-label>Does the product need to be recovered?</mat-label>
                                <mat-select
                                    name="recovery"
                                    formControlName="recovery"
                                    [ngClass]="{ 'is-invalid': formSubmitted && claimForm['recovery'].errors }"
                                >
                                    <!-- <mat-option value="" disabled>Does the product need to be recovered?</mat-option> -->
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                </mat-select>


                                <mat-error>
                                    Please select product <strong>recovery</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="number"
                                    placeholder="Claim excess"
                                    name="excess"
                                    formControlName="excess"
                                />

                                <span matPrefix>R</span>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="number"
                                    placeholder="Total cost of claim"
                                    name="totalCost"
                                    formControlName="totalCost"
                                />

                                <span matPrefix>R</span>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <textarea 
                                    matInput
                                    type="text"
                                    placeholder="Description of loss"
                                    name="claimSummary"
                                    formControlName="claimSummary"
                                ></textarea>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <!-- Claim stages -->
                    <div class="col-12 mb-3">

                        <div class="row" *ngFor="let claim of claimFormGroup.get('claimSteps')['controls']; index as i">
                        <!-- <div class="row" *ngFor="let claim of claimFormGroup.get('stage')['controls'] | keyvalue: originalOrder" [formGroupName]="claim.key"> -->

                            <!-- <div class="w-100" *ngIf="claimFormGroup.get('stage').value[claim.key].display.includes(claimFormGroup.get('claimType').value) && claimFormGroup.get('stage').value[claim.key].display.includes(claimFormGroup.get('claimDescription').value)">

                                <div class="col-12 col-sm-6 col-md-4 my-auto">
                                    <mat-checkbox [disabled]="editClaim && claim.value.value.brokerRef.firstName" disableRipple formControlName="value">{{ claim.key | sentenceCase : 'SureSpace' }}</mat-checkbox>
                                </div>
    
                                <div class="col-12 col-sm-6 col-md-8" [ngClass]="{'mb-4': editClaim && claim.value.value.brokerRef.firstName}">
                                    <mat-form-field [hidden]="editClaim && claim.value.value.brokerRef.firstName" >
                                        <textarea matInput placeholder="Additional comments" formControlName="comment"></textarea>
                                    </mat-form-field>
                                    <ng-container *ngIf="editClaim && claim?.value?.value?.brokerRef?.firstName">
                                        <p class="mb-0">{{ claim?.value?.value?.comment || 'No comment provided' }}</p>
                                        <small class="text-muted" *ngIf="editClaim && claim?.value?.value?.brokerRef?.firstName">Completed by: {{ claim?.value?.value?.brokerRef?.firstName }} {{ claim?.value?.value?.brokerRef?.lastName }}  - {{ claim?.value?.value?.date | date: 'dd MMM yyyy HH:mm' }}</small>
                                    </ng-container>
                                </div>

                            </div> -->

                            <ng-container [formGroup]="claim" *ngIf="claim.get('display').value.includes(claimFormGroup.get('claimType').value) && claim.get('display').value.includes(claimFormGroup.get('claimDescription').value)">

                                <div class="col-12 col-sm-6 col-md-6 my-auto">
                                    <mat-checkbox disableRipple formControlName="stepStatus" [disabled]="editClaim && currentClaimSteps[i]?.stepStatus">{{ claim.get('stepDescription').value }}</mat-checkbox>
                                    <small class="text-muted" *ngIf="editClaim && claim?.get('stepStatus').value"><br />Completed by: {{ claim?.get('completedBy').get('firstName').value }} {{ claim?.get('completedBy').get('lastName').value }}  - {{ claim?.get('dateCompleted').value | date: 'dd MMM yyyy HH:mm' }}</small>
                                </div>

                                <div class="col-12 col-sm-6 col-md-6" [ngClass]="{'my-3': editClaim }">
                                    <mat-form-field>
                                        <textarea [readonly]="editClaim && currentClaimSteps[i]?.stepStatus" matInput placeholder="Additional comments" formControlName="stepComment"></textarea>
                                    </mat-form-field>
                                </div>

                            </ng-container>

                        </div>
                    </div>

                    
    
                </div> <!-- Close div for .row -->
          </div> <!-- Close div for .panel-body -->
  
        </form>
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for #content -->
  