import { BrokerAdministrator, BrokerAdministratorResponse } from 'src/app/_shared/models/brokerAdministrator.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrokerAdministratorService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllAdministrators(brokerCompany:string): Observable<HttpResponse<BrokerAdministratorResponse>> {
    return this.http.get<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/all/${brokerCompany}`, {
      observe: 'response'
    });
  }

  searchAdministrators(brokerCompany:string, req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerAdministratorResponse>> {

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/all/${brokerCompany}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getSelectedAdministrator(administratorId:string): Observable<HttpResponse<BrokerAdministratorResponse>> {
    return this.http.get<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/${administratorId}`, {
      observe: 'response'
    });
  }

  addAdministrator(companyName: string, administrator:BrokerAdministrator): Observable<HttpResponse<BrokerAdministratorResponse>> {
    return this.http.post<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/${companyName}`, administrator, {
      observe: 'response'
    });
  }

  editAdministrator(administrator:BrokerAdministrator, administratorId:string): Observable<HttpResponse<BrokerAdministratorResponse>> {
    return this.http.put<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/${administratorId}`, administrator, {
      observe: 'response'
    });
  }

  deleteAdministrator(administratorId:string): Observable<HttpResponse<BrokerAdministratorResponse>> {
    return this.http.delete<BrokerAdministratorResponse>(`${this.globals.SERVER_URL}/api/brokerAdministrator/${administratorId}`, {
      observe: 'response'
    });
  }

}
