import { Injectable } from '@angular/core';
import { Globals } from '../app.globals';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  getUserEmailSettings(userId): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/user/settings/email/smtp/${userId}`, {
      observe: 'response'
    });
  }

  getUserEmailSignature(userId): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/user/settings/email/signature/${userId}`, {
      observe: 'response'
    });
  }

  saveUserEmailSettings(userId: string, settings: any): Observable<HttpResponse<any>>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/user/settings/email/smtp/${userId}`, settings, {
      observe: 'response'
    });
  }

  saveUserEmailSignature(userId: string, settings: any): Observable<HttpResponse<any>>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/user/settings/email/signature/${userId}`, settings, {
      observe: 'response'
    });
  }

}
