<div id="content" class="clients row">

    <sub-navigation heading="{{ user.firstName }} {{ user.lastName }}" subheading="Id: {{ user.username }} | Client nr: {{ basicForm['clientNumber'].value }}"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">

            <div class="panel-heading">

                <h5>Edit client</h5>

                <!-- BUTTONS FOR PERSONAL INFORMATION -->
                <div id="personalButtons" class="my-auto" *ngIf="selectedIndex === 0">

                    <button matTooltip="Back to client list" matTooltipClass='mt-3' class="btn btn-secondary my-auto" [routerLink]="['/b/clients/personal']"><i class="fi flaticon-back"></i>Back</button>
                    <button matTooltip="Reset changes" matTooltipClass='mt-3' class="btn btn-secondary my-auto" (click)="resetForms()"><i class="fi flaticon-reset"></i>Reset</button>
                    <button matTooltip="Save and continue" matTooltipClass='mt-3' class="btn btn-brand my-auto" (click)="updateUserInformation('continue')">Save</button>

                    <!-- <button class="btn btn-light btn-pill my-auto color-brand" [matMenuTriggerFor]="menuEdit">
                  <i class="fi flaticon-menu mr-0"></i>
                </button>

                <mat-menu xPosition="before" yPosition="below" #menuEdit="matMenu">
                  <button (click)="updateUserInformation('back')" mat-menu-item>
                    <span>Save &amp; go back</span>
                  </button>
                  <button (click)="updateUserInformation('continue')" mat-menu-item>
                    <span>Save &amp; continue</span>
                  </button>
                </mat-menu> -->

                </div>

                <div id="emailButtons" class="my-auto" *ngIf="selectedIndex === 1">
                    <app-add-button (click)="brokerComponent.toggleSendingEmail(user?._id, {to: user?.email})" class="my-auto" buttonText="New email" matTooltip="Send an email to client"></app-add-button>
                </div>

                <ng-container *ngIf="canViewItem">

                    <!-- BUTTONS FOR PRODUCTS > VEHICLE INSURANCE -->
                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 0">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/vehicle/new']"><i class="fi flaticon-add"></i> Add new vehicle</button>
                    </div>

                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 1">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/structure/new']"><i class="fi flaticon-add"></i> Add new building</button>
                    </div>

                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 2">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/homeContent/new']"><i class="fi flaticon-add"></i> Add new home content</button>
                    </div>

                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 3">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/allRisk/new']"><i class="fi flaticon-add"></i> Add new item</button>
                    </div>

                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 4">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/trailersAndCaravans/new']"><i class="fi flaticon-add"></i> Add new product</button>
                    </div>

                    <div class="my-auto" *ngIf="selectedIndex === 2 && selectedProductIndex === 5">
                        <button class="btn btn-info" [routerLink]="['short-term-insurance/valueAddedProducts/new']"><i class="fi flaticon-add"></i> Add new product</button>
                    </div>

                </ng-container>


                <!-- BUTTONS FOR DOCUMENTS -->
                <div id="documentButtons" class="my-auto" *ngIf="selectedIndex === 3">
                    <button class="btn btn-info" (click)="openUploadDocumentModal()"><i class="fi flaticon-add"></i> Add document</button>
                </div>
                
                <!-- BUTTONS FOR NOTES -->
                <div id="documentButtons" class="my-auto" *ngIf="selectedIndex === 4">
                    <button (click)="addManualNote()" class="btn btn-info"><i class="fi flaticon-add"></i> Add new note</button>
                </div>

                <!-- BUTTONS FOR POLICIES -->
                <div id="documentButtons" class="my-auto" *ngIf="selectedIndex === 5">
                    <button [routerLink]="['/b/clients/policies/new']" [queryParams]="{'id': selectedUser}" class="btn btn-info"><i class="fi flaticon-add"></i> Add new policy</button>
                </div>

                <!-- BUTTONS FOR FAMILY MEMBERS -->
                <div id="documentButtons" class="my-auto" *ngIf="selectedIndex === 6">
                    <button [routerLink]="['family-members/new']"  class="btn btn-info"><i class="fi flaticon-add"></i> Add new family member</button>
                </div>

            </div>
            <!-- Close div for .panel-heading -->

            <div class="panel-body pb-5">

                <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="setSelectedIndex($event)">

                    <mat-tab label="Personal information">

                        <div class="row">

                            <div id="basicInformation" class="col-12">

                                <p><strong>Basic information</strong></p>

                                <form [formGroup]="basicInformationForm">

                                    <div class="row">

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput name="idNumber" placeholder="ID number" type="text" [value]="basicForm['username'].value" disabled="true" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput name="passportNumber" placeholder="Passport number" type="text" [value]="basicForm['passportNumber'].value" disabled="true" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-sm-4 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Tax number" name="taxNumber" type="text" formControlName="taxNumber" />

                                                <mat-error *ngIf="basicForm['taxNumber'].errors && basicForm['taxNumber'].errors.required">
                                                    Please enter user <strong>tax number</strong>.
                                                </mat-error>

                                            </mat-form-field>
                                        </div>

                                        <div class="col-sm-4 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Client number" name="clientNumber" type="text" [value]="basicForm['clientNumber'].value" disabled="true" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <hr />
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="First name" name="firstName" type="text" formControlName="firstName" />

                                                <mat-error *ngIf="basicForm['firstName'].errors && basicForm['firstName'].errors.required">
                                                    Please enter user <strong>first name</strong>.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Last name" name="lastName" type="text" formControlName="lastName" />

                                                <mat-error *ngIf="basicForm['lastName'].errors && basicForm['lastName'].errors.required">
                                                    Please enter user <strong>last name</strong>.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput name="gender" placeholder="gender" type="text" [value]="basicForm['gender'].value" disabled="true" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                              <mat-select placeholder="Marital status" name="maritalStatus" formControlName="maritalStatus">
                                                <mat-option value="" disabled read-only>
                                                  Please select marital status
                                                </mat-option>
                                                <mat-option value="single">
                                                  Single
                                                </mat-option>
                                                <mat-option value="married">
                                                  Married
                                                </mat-option>
                                                <mat-option value="separated">
                                                  Separated
                                                </mat-option>
                                                <mat-option value="divorced">
                                                  Divorced
                                                </mat-option>
                                                <mat-option value="widow/widower">
                                                  Widow / Widower
                                                </mat-option>
                                              </mat-select>
                                            </mat-form-field>
                                          </div>

                                        <div class="col-12">
                                            <hr />
                                          </div>

                                          <ng-container class="row" formGroupName="physicalAddress">

                                            <div class="col-12">
                                                <mat-form-field>
                                                    <input
                                                        ngx-google-places-autocomplete
                                                        matInput
                                                        #placesRef="ngx-places"
                                                        (onAddressChange)="handleAddressChange($event)"
                                                        formControlName="addressLine1"
                                                        [options]="addressOptions"
                                                        placeholder="Address"
                                                    />
                                                </mat-form-field>
                                            </div>
                    
                                            <div class="col-12 clearfix mb-3"></div>
                    
                                            <div class="col-12 col-md-4 col-sm-6">
                                                <mat-form-field>
                                                    <input 
                                                        matInput
                                                        name="suburb"
                                                        formControlName="suburb"
                                                        placeholder="Suburb"
                                                    >
                                                </mat-form-field>
                                            </div>
                    
                                            <div class="col-12 col-md-4 col-sm-6">
                                                <mat-form-field>
                                                    <input 
                                                        matInput
                                                        name="city"
                                                        formControlName="city"
                                                        placeholder="City"
                                                    > 
                                                </mat-form-field>
                                                
                                            </div>
                    
                                            <div class="col-12 col-md-4 col-sm-6">
                                                <mat-form-field>
                                                    <input 
                                                        matInput
                                                        name="postalCode"
                                                        formControlName="postalCode"
                                                        placeholder="Postal Code"
                                                    >
                                                </mat-form-field>
                                            </div>
                    
                                        </ng-container>
                    
                                        <div class="col-12">
                                            <hr />
                                        </div>
                      
                                          <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                              <mat-select placeholder="Language" name="language" formControlName="language">
                                                <mat-option value="" disabled read-only>
                                                  Please select a language
                                                </mat-option>
                                                <mat-option *ngFor="let language of languages" [value]="language.language">{{ language.language }}</mat-option>
                                              </mat-select>
                    
                                              <mat-error *ngIf="basicForm['language'].errors && basicForm['language'].errors.required">
                                                Please select the user <strong>language</strong>.
                                              </mat-error>
                    
                                            </mat-form-field>
                                          </div>

                                        <!-- <div class="col-12">
                              <hr />
                            </div>

                            <div class="col-12" formGroupName="insuranceType">
                              <small class="color-dark-grey">Insurance types:</small>
                              <br />
                              <mat-checkbox class="d-inline-block mr-5" formControlName="householdInsurance">Household insurance</mat-checkbox>
                              <mat-checkbox class="d-inline-block mr-5" formControlName="vehicleInsurance">Vehicle insurance</mat-checkbox>
                              <mat-checkbox class="d-inline-block mr-5" formControlName="buildingInsurance">Building insurance</mat-checkbox>
                              <mat-checkbox class="d-inline-block" formControlName="personalItemInsurance">Personal item insurance</mat-checkbox>
                            </div> -->

                                    </div>
                                    <!-- Close div for .row -->

                                </form>

                            </div>
                            <!-- Close div for .col-12 -->

                            <div class='col-12 page-split'>
                                <hr />
                            </div>

                            <div id="additionalInformation" class="col-12">

                                <p><strong>Additional information</strong></p>

                                <form [formGroup]="additionalInformationForm">

                                    <div class="row">

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Middle name(s)" name="middlename" type="text" formControlName="middleName" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence">
                                            <mat-form-field>
                                                <mat-select placeholder="Drivers license" name="licenceCode" formControlName="licenceCode">
                                                    <mat-option value="None">
                                                        None
                                                    </mat-option>

                                                    <mat-optgroup label="Motorcycles">
                                                        <mat-option value="Code A1">Code A1</mat-option>
                                                        <mat-option value="Code A">Code A</mat-option>
                                                    </mat-optgroup>

                                                    <mat-optgroup label="Light motor vehicles">
                                                        <mat-option value="Code B">Code B</mat-option>
                                                        <mat-option value="Code EB">Code EB</mat-option>
                                                    </mat-optgroup>

                                                    <mat-optgroup label="Heavy motor vehicles">
                                                        <mat-option value="Code C1">Code C1</mat-option>
                                                        <mat-option value="Code C">Code C</mat-option>
                                                        <mat-option value="Code EC1">Code EC1</mat-option>
                                                        <mat-option value="Code EC">Code EC</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence" *ngIf="additionalForm['driversLicence'].value.licenceCode && additionalForm['driversLicence'].value.licenceCode != 'None'">

                                            <mat-form-field class="example-full-width">
                                                <input placeholder="First issue" matInput [matDatepicker]="driversLicenseFirstIssue" name="driversLicenceFirstIssue" formControlName="licenceFirstIssue">
                                                <mat-datepicker-toggle matSuffix [for]="driversLicenseFirstIssue"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #driversLicenseFirstIssue></mat-datepicker>
                                            </mat-form-field>

                                            <!-- <mat-form-field class="example-full-width">
                                <input placeholder="Valid until" matInput [matDatepicker]="driversLicenseValidUntil" name="driversLicenceExpiryDate"
                                  formControlName="licenceExpiryDate">
                                <mat-datepicker-toggle matSuffix [for]="driversLicenseValidUntil"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #driversLicenseValidUntil></mat-datepicker>
                              </mat-form-field> -->

                                        </div>

                                        <div class="col-12">
                                            <hr />
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Employer" name="employer" type="text" formControlName="employer" />

                                                <mat-error *ngIf="additionalForm['employer'].errors && additionalForm['employer'].errors.required">
                                                    Please enter the <strong>employer</strong> for the user.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Job description" name="jobDescription" type="text" formControlName="jobDescription" />
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <!-- Close div for .row -->

                                </form>

                            </div>
                            <!-- Close div for .col-12 -->

                            <div class='col-12 page-split'>
                                <hr />
                            </div>

                            <div id="contactInformation" class="col-12">

                                <p><strong>Contact information</strong></p>

                                <form [formGroup]="contactInformationForm">

                                    <div class="row">

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Email" name="firstName" type="email" formControlName="email" />

                                                <mat-error *ngIf="contactForm['email'].errors && (contactForm['email'].errors.email || contactForm['email'].errors.pattern)">
                                                    Please enter a valid <strong>email address</strong>.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Cellphone" name="cellphone" type="text" formControlName="cellphone" />

                                                <mat-error *ngIf="contactForm['cellphone'].errors && contactForm['cellphone'].errors.required">
                                                    Please enter a <strong>cellphone number</strong> for the user.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Home phone" name="homePhone" type="text" formControlName="homePhone" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <hr />
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Work phone" name="workPhone" type="text" formControlName="workPhone" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <input matInput placeholder="Alternative number" name="alternateNumber" type="text" formControlName="alternateNumber" />
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <!-- Close div for .row -->

                                </form>

                            </div>
                            <!-- Close div for .col-12 -->

                            <div class='col-12 page-split'>
                                <hr />
                            </div>

                            <div id="insuranceInformation" class="col-12">

                                <p><strong>Insurance information</strong></p>

                                <form [formGroup]="basicInformationForm">

                                    <div class="row" formGroupName="broker">

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field>
                                                <mat-select placeholder="Consultant" name="consultant" formControlName="_id" (selectionChange)="mapEmployee($event)">
                                                    <mat-option value="" disabled read-only>
                                                        Please select a consultant
                                                    </mat-option>
                                                    <mat-option *ngFor="let employee of employees" [value]="employee._id">
                                                        {{ employee.firstName }} {{ employee.lastName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-6 col-6">
                                            <mat-form-field *ngIf="!canChangeClientStatus()">
                                                <input matInput placeholder="Client status" name="clientStatus" type="text" [value]="basicForm['clientStatus'].value" />
                                            </mat-form-field>
                                            <mat-form-field *ngIf="canChangeClientStatus()">
                                                <mat-select placeholder="Client status" name="clientStatus" formControlName="clientStatus">
                                                  <mat-option value="" disabled read-only>
                                                    Please select client status
                                                  </mat-option>
                                                  <mat-option [value]="'lead'">
                                                    Lead
                                                  </mat-option>
                                                  <mat-option [value]="'quote'">
                                                    Quote
                                                  </mat-option>
                                                  <mat-option [value]="'active'">
                                                    Active
                                                  </mat-option>
                                                  <mat-option [value]="'quote_cancelled'">
                                                    Quote Cancelled
                                                  </mat-option>
                                                  <mat-option [value]="'cancelled'">
                                                    Cancelled
                                                  </mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                        </div>

                                    </div>

                                    <br />

                                    <div class="row">

                                        <!-- INSURANCE TYPES -->
                                        <div class="form-group" formGroupName="insuranceType">

                                            <hr />

                                            <div class="row mx-0 mt-2">

                                                <div class="checkbox col-12">
                                                    <mat-checkbox color="brand" class="mr-4" formControlName="householdInsurance">Household insurance</mat-checkbox>

                                                    <mat-checkbox color="brand" class="mr-4" formControlName="vehicleInsurance">Vehicle insurance</mat-checkbox>

                                                    <mat-checkbox color="brand" class="mr-4" formControlName="buildingInsurance">Building insurance</mat-checkbox>

                                                    <mat-checkbox color="brand" class="mr-4" formControlName="personalItemInsurance">Personal item insurance</mat-checkbox>

                                                    <mat-checkbox color="brand" class="mr-4" formControlName="trailersAndCaravansInsurance">Trailers and caravans insurance</mat-checkbox>

                                                    <mat-checkbox color="brand" class="mr-4" formControlName="vapsInsurance">Value added products</mat-checkbox>

                                                </div>

                                            </div>
                                            <!-- Close div for .row -->

                                        </div>
                                        <!-- Close div for .form-group -->

                                    </div>
                                    <!-- Close div for .row -->

                                </form>

                            </div>
                            <!-- Close div for .col-12 -->

                        </div>
                        <!-- Close div for .row-->

                    </mat-tab>

                    <mat-tab label="Emails">
                        <ng-template matTabContent>
                            <broker-view-client-emails></broker-view-client-emails>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Products">

                        <ng-template matTabContent>

                            <mat-tab-group [selectedIndex]="selectedProductIndex" (selectedIndexChange)="setSelectedProductIndex($event)">

                                <!-- <mat-tab label="Household insurance" *ngIf="user?.insuranceType?.householdInsurance">
                                    <ng-template matTabContent>
                                    <app-broker-view-user-household-insurance></app-broker-view-user-household-insurance>
                                    </ng-template>
                                </mat-tab> -->

                                <!-- <mat-tab label="Personal item insurance" *ngIf="user?.insuranceType?.personalItemInsurance">
                                    <ng-template matTabContent>
                                    <app-broker-view-user-personal-insurance></app-broker-view-user-personal-insurance>
                                    </ng-template>
                                </mat-tab> -->

                                <mat-tab label="Vehicle insurance" [disabled]="user?.insuranceType?.vehicleInsurance == false">
                                    <ng-template matTabContent>
                                        <app-broker-view-user-vehicle-insurance></app-broker-view-user-vehicle-insurance>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Home building insurance" [disabled]="user?.insuranceType?.buildingInsurance == false">
                                    <ng-template matTabContent>
                                        <app-broker-view-user-building-insurance></app-broker-view-user-building-insurance>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Home content insurance" [disabled]="user?.insuranceType?.householdInsurance == false">
                                    <ng-template matTabContent>
                                        <app-broker-view-user-household-insurance></app-broker-view-user-household-insurance>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="All risk item insurance" [disabled]="user?.insuranceType?.personalItemInsurance == false">
                                    <ng-template matTabContent>
                                        <app-broker-view-user-all-risk-insurance></app-broker-view-user-all-risk-insurance>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Trailers and caravans insurance" [disabled]="user?.insuranceType?.trailersAndCaravansInsurance == false">
                                    <ng-template matTabContent>
                                        <broker-view-user-trailers-and-caravans-insurance></broker-view-user-trailers-and-caravans-insurance>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab label="Value added products" [disabled]="user?.insuranceType?.vapsInsurance == false" >
                                    <ng-template matTabContent>
                                        <broker-view-user-value-added-products></broker-view-user-value-added-products>
                                    </ng-template>
                                </mat-tab>

                            </mat-tab-group>

                        </ng-template>

                    </mat-tab>

                    <mat-tab label="Documents">
                        <ng-template matTabContent>
                            <broker-view-client-documents></broker-view-client-documents>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Notes" [hidden]="currentUserPermissions.includes('brokerClaimsTechnician')" >
                        <ng-template matTabContent>
                            <broker-view-user-notes [newestNote]="newestNote"></broker-view-user-notes>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Policies">
                        <ng-template matTabContent>
                            <broker-view-user-policies></broker-view-user-policies>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Family members">
                        <ng-template matTabContent>
                            <broker-view-client-family-members></broker-view-client-family-members>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Instructions" [hidden]="currentUserPermissions.includes('brokerClaimsTechnician')" >
                        <ng-template matTabContent>
                            <broker-view-client-instructions></broker-view-client-instructions>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Claims">
                        <ng-template matTabContent>
                            <broker-view-client-claims></broker-view-client-claims>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Quotes">
                        <ng-template matTabContent>
                            <broker-view-user-quotes></broker-view-user-quotes>
                        </ng-template>
                    </mat-tab>

                    <mat-tab label="Returned debit orders">
                        <ng-template matTabContent>
                            <broker-view-user-returned-debit></broker-view-user-returned-debit>
                        </ng-template>
                    </mat-tab>


                    <!-- <mat-tab label="Investments">
                  <client-investments></client-investments>
                </mat-tab> -->

                </mat-tab-group>

            </div>
            <!-- Close div for .panel-body -->

        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->