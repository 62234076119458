<div id="client-header" *ngIf="authenticated" (scroll)="onWindowScroll($event)">

    <header id="header" class="col-12">

      <div class="container-fluid h-100">

        <div class="row h-100">

          <div class="col-6 align-self-center">
            <div id="logo">
              <img src="assets/images/logo-white.png" />
              <p>SureSpace</p>
            </div>            
          </div> <!-- Close div for .col-6 -->

          <div class="navbar-buttons col-6 text-right align-self-center">
            <button class="btn" [routerLink]="['/faq']"><i class="fi mr-0 flaticon-faq fi-bold"></i></button>

            <!-- USER MESSAGES -->
            <span *ngIf="false" class="dropdown-default" ngbDropdown (openChange)="onMessagesMenuOpenChange($event)">
              <button class="btn" ngbDropdownToggle><span [matBadge]="newMessages" matBadgeSize="small" [matBadgeHidden]="newMessages === 0" class="fi mr-0 flaticon-comment fi-bold"></span></button> 
              <div [ngClass]='messagesDropdownStatus' class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownManual" x-placement="bottom-right">
                
                <div class="dropdown-header text-center">
                  <p class="mb-0 my-auto">
                    <span class="font-size-18 color-dark font-weight-bold">User messages</span> 
                    <span class="btn btn-label-info btn-sm btn-bold ml-2 mr-0">{{ newMessages }} New</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <div class="col-12 text-center">
                    <p class="mt-3">No new messages</p>
                  </div>
                </div>

                <div class="dropdown-footer text-center">
                    <button [routerLink]="['/u/messages']" class="btn btn-light btn-sm">Show all messages</button>
                </div>

              </div>
            </span>

            <!-- USER NOTIFICATIONS -->
            <span class="dropdown-default" ngbDropdown (openChange)="onNotificationMenuOpenChange($event)">
              <button class="btn" ngbDropdownToggle><span [matBadge]="newNotifications" matBadgeSize="small" [matBadgeHidden]="newNotifications === 0" class="fi mr-0 flaticon-bell fi-bold"></span></button> 
              <div [ngClass]='notificationDropdownStatus' class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownManual" x-placement="bottom-right">
                
                <div class="dropdown-header text-center">
                  <p class="mb-0 my-auto">
                    <span class="font-size-18 color-dark font-weight-bold">User notifications</span> 
                    <span class="btn btn-label-info btn-sm btn-bold ml-2 mr-0">{{ newNotifications }} New</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <button class="d-flex notification" *ngFor="let notification of notifications" ngbDropdownItem [ngClass]="{'unread': !notification.isSeen}" (click)="notificationClick(notification)">
                    <div class="d-inline-block h-100 my-auto mr-3">
                      <span class='icon'><i class="fi mr-0 flaticon-comment fi-bold"></i></span>
                    </div>
                    <div id="notify-holder" class="d-inline-block">
                      <span class='heading'>{{ notification?.notification }}</span>
                      <span class='description'>{{ notification?.dateAdded | date: 'dd MMM yyyy - HH:mm' }}</span>
                    </div>
                  </button>
                </div>

                <div class="dropdown-footer text-center pt-1 pb-1">
                  <button (click)="this.notificationDropdownStatus = 'ddClosed'" [routerLink]="['/u/notifications']" class="btn btn-light btn-sm">Show all</button>
                </div>

              </div>
            </span>
            <!-- <button class="btn" mat-flat-button color="basic"><i class="fi flaticon-dashboard"></i></button>  -->
              

            <div id="me" class="btn pr-0" ngbDropdown (openChange)="onUserMenuOpenChange($event)">
              <div ngbDropdownToggle>
                <span class="welcome">Hi, </span> <span class="color-white"> {{ currentUser?.firstName }}</span>
                <span class="userpic userpic-info userpic-md ml-2">
                  <span>{{ currentUser?.firstName.substring(0, 1) }}</span>
                </span>
              </div>

              <div [ngClass]='userDropdownStatus' class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownManual" x-placement="bottom-right">
                <div class="dropdown-header d-flex">
                  <div class="userpic userpic-lg userpic-dark">
                    <span>{{ this.currentUser?.firstName.substring(0, 1) }}{{ this.currentUser?.lastName.substring(0, 1) }}</span>
                  </div>
                  <p class="mb-0 ml-3 my-auto">
                    <span class="font-size-18 color-dark">{{ this.currentUser?.firstName }} {{ this.currentUser?.lastName }}</span><br>
                    <span class="color-dark">{{ this.currentUser?.idNumber }}</span>
                  </p>
                </div>

                <div class="dropdown-body">
                  <button ngbDropdownItem [routerLink]="['/u/settings']">
                    <span class='heading'>My Profile</span>
                    <span class='description'>Account settings and more</span>
                  </button>

                  <button ngbDropdownItem [routerLink]="['/management']">
                    <span class='heading'>Management</span>
                    <span class='description'>Managment settings and more</span>
                  </button>

                </div>

                <div class="dropdown-footer">
                  <button [routerLink]="['/logout']" class="btn btn-label-info">Sign out</button>
                </div>
                
              </div>
            
            </div> <!-- Close div for #me -->
                

          </div> <!-- Close div for .col-6 -->

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .container-fluid -->

    </header>
    
    <nav class="col-12">

      <div class="container-fluid h-100">

        <div class="row h-100">

          <div class="col-12 my-auto">

            <button [routerLink]="['/u/dashboard']" routerLinkActive="active" class="btn btn-hover-dark"><strong>Dashboard</strong></button>

            <div class="d-inline-block" ngbDropdown (openChange)="onNavMenuOpenChange($event)">
              <button routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }" class="btn btn-hover-dark" ngbDropdownToggle>
                <strong>About me</strong>
              </button>
              <div [ngClass]='navDropdownStatus' ngbDropdownMenu aria-labelledby="dropdownManual">                
                <button [routerLink]="['/u/me/my-information']" class="dropdown-item text-left">My information</button>
                <button [routerLink]="['/u/me/my-family']" class="dropdown-item text-left">My family</button>  
              </div>
            </div>


            <button [routerLink]="['/u/my-brokers']" routerLinkActive="active" class="btn btn-hover-dark"><strong>My brokers</strong></button>
            

            <div class="d-inline-block" ngbDropdown (openChange)="onNavMenuOpenChange($event)">
              <button routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="btn btn-hover-dark" ngbDropdownToggle>
                <strong>My insurance</strong>
              </button>
              <div [ngClass]='navDropdownStatus' ngbDropdownMenu aria-labelledby="dropdownManual">                
                <button *ngIf="currentUser?.insuranceType?.householdInsurance" [routerLink]="['/u/short-term-insurance/household']" class="dropdown-item text-left"><i class="fi flaticon-umbrella mr-2"></i>Household insurance</button>
                <button *ngIf="currentUser?.insuranceType?.personalItemInsurance" [routerLink]="['/u/short-term-insurance/personal-item']" class="dropdown-item text-left"><i class="fi flaticon-umbrella mr-2"></i>Personal item insurance</button>  
                <button *ngIf="currentUser?.insuranceType?.vehicleInsurance" [routerLink]="['/u/short-term-insurance/vehicle']" class="dropdown-item text-left"><i class="fi flaticon-umbrella mr-2"></i>Vehicle insurance</button>
                <button *ngIf="currentUser?.insuranceType?.buildingInsurance" [routerLink]="['/u/short-term-insurance/structure']" class="dropdown-item text-left"><i class="fi flaticon-umbrella mr-2"></i>Structure insurance</button>  
              </div>
            </div>

            <!-- <button class="btn btn-hover-dark"><strong>My medical aid</strong></button>
            <button class="btn btn-hover-dark" [routerLink]="['/u/investments']" routerLinkActive="active"><strong>My investments</strong></button> -->
            

          </div> <!-- Close div for .col-12 -->

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .container-fluid -->

    </nav>

</div> <!-- Close div for #client-header -->

<div class="surespace-body" [ngClass]="authenticated?'background':'login-background'">

    <div id="content" class="mt-0">

      <div class="container-fluid">
          <router-outlet></router-outlet>
      </div> <!-- Close div for .container-->

    </div> <!-- Close div for .content-->

</div> <!-- Close div for .surespace-body -->

