<h2 mat-dialog-title>
    Remove commercial client?
    <button class="close" mat-dialog-close></button>
</h2>

<mat-dialog-content>
    <p class="mb-4 mt-4">
        Are you sure you want to permanently delete <strong>{{ data.companyName }}</strong> as a commercial client?
        <br />
        This action cannot be undone.
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="btn btn-secondary mr-2" mat-dialog-close>Cancel</button>
    <button class="btn btn-danger" (click)="removeClient()"><i class="fi flaticon-trash"></i>Delete</button>
</mat-dialog-actions>
      