import { Pipe, PipeTransform } from '@angular/core';
import * as indefinite from 'indefinite';

@Pipe({
  name: 'indefinite'
})
export class IndefinitePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? indefinite(value) : null;
  }

}
