<div id="content" class="row">

    <sub-navigation heading="Notifications" subheading="{{ newNotifications }} New"></sub-navigation>

    <div class="container">

        <div class="row">

            <div class="col-12" *ngIf="notifications?.length === 0">
                <p class="text-center">No notifications found.</p>
            </div>

            <div class="col-12 notification" *ngFor="let notification of notifications" [ngClass]="{'unread': !notification.isSeen}" (click)="markNotificationAsRead(notification)">

                <span class="d-flex my-auto">
                    <div class="d-inline-block h-100 my-auto mr-3">
                        <span class='icon'><i class="fi mr-0 flaticon-bell fi-bold"></i></span>
                    </div>
                    <div class="d-inline-block">
                        <span class='heading'>{{ notification?.message }}</span>
                        <span class='description'>{{ notification?.createdAt | date: 'dd MMM yyyy - HH:mm' }}</span>
                    </div>
                </span>

            </div> <!-- Close div for .col-12 -->

        </div> <!-- Close div for .row -->

    </div> <!-- Close div for .container -->

</div> <!-- Close div for .content -->