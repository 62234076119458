import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

import Quill from 'quill'

import ImageResize from 'quill-image-resize-module';
import ImageCompress from 'quill-image-compress';
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageCompressor", ImageCompress);

@Component({
  selector: 'broker-settings-email',
  templateUrl: './broker-settings-email.component.html',
  styleUrls: ['./broker-settings-email.component.scss']
})
export class BrokerSettingsEmailComponent implements OnInit, AfterViewInit {

  emailSetupFormGroup: FormGroup;
  emailSignatureFormGroup: FormGroup;

  currentUser = this.authenticationService.currentUserValue;
  
  quillConfig = {
    imageResize: {},
    imageCompressor: {}
  }

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.emailSetupFormGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      host: ['', Validators.required],
      port: ['', Validators.required]
    });

    this.emailSignatureFormGroup = this.formBuilder.group({
      emailSignature: ['', Validators.required]
    })

  }

  ngAfterViewInit(){
    this.getUserEmailSettings();
    this.getUserEmailSiganture();
  }

  saveEmailSettings() {

    this.emailSetupFormGroup.markAllAsTouched()

    if(this.emailSetupFormGroup.invalid){
      return
    }

    this.settingsService.saveUserEmailSettings(this.currentUser._id, this.emailSetupFormGroup.value).subscribe()
  }

  saveEmailSignature(){
    this.emailSignatureFormGroup.markAllAsTouched()

    if(this.emailSignatureFormGroup.invalid){
      return
    }

    this.settingsService.saveUserEmailSignature(this.currentUser._id, this.emailSignatureFormGroup.value).subscribe()
  }

  getUserEmailSettings(){
    this.settingsService.getUserEmailSettings(this.currentUser._id).subscribe(
      response => {
        this.emailSetupFormGroup.patchValue(response.body.settings);
      }
    )
  }

  getUserEmailSiganture(){
    this.settingsService.getUserEmailSignature(this.currentUser._id).subscribe(
      response => {
        console.log('testing => ', response.body)
        this.emailSignatureFormGroup.patchValue(response.body.settings);
      }
    )
  }

}
