
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DeleteModalComponent } from 'src/app/components/modal/delete/delete.component';
import { BrokerClientService } from 'src/app/services/broker-client.service';
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-view-user-vehicle-insurance',
  templateUrl: './broker-view-user-vehicle-insurance.component.html',
  styleUrls: ['./broker-view-user-vehicle-insurance.component.scss'],
  providers: [DeleteVehicleComponent]
})
export class BrokerViewUserVehicleInsuranceComponent implements OnInit, AfterViewInit {

  currentCompany: string;

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userVehicles: Vehicle[]
  previouslyInsuredVehicles: Vehicle[]

  displayedColumns: string[] = ['registrationNumber', 'make', 'model', 'year', 'insuredValue', 'inceptionDate', 'menu'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = true;
  isInsuredVehiclesRateLimitReached = false;
  isPreviousInsuredVehiclesRateLimitReached = false;

  resultsLengthPreviousInsurance: number = 0;
  pageSizePreviousInsure = 25;
  isRateLimitReachedPreviousInsurance = false;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private deleteVehicleModal: DeleteVehicleComponent,
    private brokerClientService: BrokerClientService,
    private authenticationService: AuthenticationService
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.currentCompany).permissions
  }

  getUserVehicle(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isInsuredVehiclesRateLimitReached){

      this.isLoadingResults = true;

      this.brokerClientService.getAllVehiclesForClient(this.selectedUser, this.currentCompany, req).subscribe(
        (response:HttpResponse<VehicleResponse>) => {
          this.isLoadingResults = false;
          this.userVehicles = response.body.vehicle;
          this.resultsLength = response.body.count;
          
          if(this.userVehicles.length < this.pageSize){
            this.isInsuredVehiclesRateLimitReached = true;
          }
        }
      )
    }
  };

  getPreviousInsuredVehicles(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isPreviousInsuredVehiclesRateLimitReached){

      this.isLoadingResults = true;

      this.brokerClientService.getPreviousInsuredVehiclesForClient(this.selectedUser, req).subscribe(
        (response:HttpResponse<VehicleResponse>) => {
          this.isLoadingResults = false;
          this.previouslyInsuredVehicles = response.body.vehicle;
          this.resultsLengthPreviousInsurance = response.body.count;

          if(this.previouslyInsuredVehicles.length < this.pageSize){
            this.isPreviousInsuredVehiclesRateLimitReached = true;
          }
        }
      )
    }

  }

  deleteVehicle(vehicle){
    vehicle['clientId'] = this.selectedUser;
    this.deleteVehicleModal.openDialog(vehicle);
    this.deleteVehicleModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userVehicles = this.userVehicles.filter(item => item != vehicle);
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserVehicle();
    this.getPreviousInsuredVehicles();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
