<div class="col-md-8 col-sm-6 col-6 mb-4">
    <p class="d-inline-block mr-3"><strong>Filter instructions</strong></p>
    <button class="btn btn-sm btn-pill" (click)="instructionSearchStatus = 'incomplete'; findAllInstructions()" [ngClass]="instructionSearchStatus == 'incomplete' ? 'btn-info' : 'btn-label-info'">Incomplete</button>
    <button class="btn btn-sm btn-pill" (click)="instructionSearchStatus = 'completed'; findAllInstructions()" [ngClass]="instructionSearchStatus == 'completed' ? 'btn-info' : 'btn-label-info'">Completed</button>
    <button class="btn btn-sm btn-pill" (click)="instructionSearchStatus = 'all'; findAllInstructions()" [ngClass]="instructionSearchStatus == 'all' ? 'btn-info' : 'btn-label-info'">All</button>
</div>

<table mat-table [dataSource]="instructions" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

    <!-- Client Column -->
    <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" data-label="Client">{{ row?.client?.firstName }} {{ row?.client?.lastName }}</td>
    </ng-container>


    <!-- Reference Number Column ->
    <ng-container matColumnDef="refNr">
        <th mat-header-cell *matHeaderCellDef>Ref number</th>
        <td mat-cell *matCellDef="let row" data-label="Ref number">{{ row?.refNr }}</td>
    </ng-container>-->

    <!-- Client Number Column -->
    <ng-container matColumnDef="clientNr">
        <th mat-header-cell *matHeaderCellDef>Client number</th>
        <td mat-cell *matCellDef="let row" data-label="Client number">{{ row?.client?.clientNumber }}</td>
    </ng-container>

     <!-- Reference Number Column -->
     <ng-container matColumnDef="refNr">
        <th mat-header-cell *matHeaderCellDef>Reference number</th>
        <td mat-cell *matCellDef="let row" data-label="Reference number">{{ row?.referenceNumber }}</td>
    </ng-container>

    <!-- Admin Instruction Column -->
    <ng-container matColumnDef="instruction">
        <th mat-header-cell *matHeaderCellDef>Instruction Type</th>
        <td mat-cell *matCellDef="let row" data-label="Instruction Type">{{ row?.instructionType | sentenceCase }}</td>
    </ng-container>

    <!-- Admin Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" data-label="Status">{{ row?.currentStep | sentenceCase }}</td>
    </ng-container>

    <!-- Admin Instruction Approved Date Column -->
    <ng-container matColumnDef="dateApproved">
        <th mat-header-cell *matHeaderCellDef>Signed Off</th>
        <td mat-cell *matCellDef="let row" data-label="Signed Off">
          <span *ngIf="row?.instructionCompleted?.value">{{ row?.instructionCompleted?.completedBy?.firstName }} {{ row?.instructionCompleted?.completedBy?.lastName }}<br /><small class="text-muted">on {{ row?.instructionCompleted?.dateCompleted | date: "dd MMM yyyy 'at' HH:mm" }}</small></span>
          <span *ngIf="!row?.instructionCompleted?.value">Not signed off</span>
        </td>
    </ng-container>

    <!-- View Column -->
    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" data-label="Actions">
            <button class="btn btn-light btn-pill color-brand" matTooltip="Edit instruction" [routerLink]="['/b/instructions/edit', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<p class="mt-3 text-center" *ngIf="instructions?.length === 0">No instructions found</p>

<mat-paginator [hidden]="instructions?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
