import { RouterModule } from '@angular/router';
import { SharedModule } from './_shared/shared.module';
import { ClientModule } from './client/client.module';
import { BrokerModule } from './broker/broker.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingScreenInterceptor } from './components/loading-screen/loading-screen.interceptor';

//THIRD PARTY
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
// import { RecaptchaFormsModule } from 'ng-recaptcha/recaptcha/recaptcha-forms.module';

import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ngx-toastr';

import { Globals } from './app.globals';
import { Shared } from './_shared/shared';
import { AppComponent } from './app.component';

import { AdvertisingComponent } from './advertising/advertising.component';
import { AuthInterceptorService } from './services/authInterceptor.service';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { DeleteModalComponent, DeleteModalPreview } from './components/modal/delete/delete.component';

import { LoginComponent } from './login/login.component';
import { ActivateComponent } from './activate/activate.component';
import { RegisterComponent } from './register/register.component';
import { LogoutComponent } from './logout/logout.component';
import { AboutComponent } from './about/about.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BulletinBoardComponent } from './bulletin-board/bulletin-board.component';

import { AuthGuardService, AnonymousGuardService, BrokerGuardService, AdminGuardService, UserGuardService } from './services/auth-guard.service';

import { AppRoutes } from './app.routing';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CancelAccountComponent } from './account/cancel-account/cancel-account.component';
import { RenewSessionComponent } from './components/modal/renew-session/renew-session.component';

// BROKERS
import { ManagementComponent } from './management/management.component';
import { ManagementUsersComponent } from './management/users/management-users.component';
import { FaqComponent } from './faq/faq.component';
import { ManagementConsoleComponent } from './management/console/management-console.component';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { StyleGuideBackgroundComponent } from './style-guide/background/style-guide-background.component';
import { StyleGuideTypographyComponent } from './style-guide/typography/style-guide-typography.component';
import { StyleGuideButtonsComponent } from './style-guide/buttons/style-guide-buttons.component';
import { StyleGuideInputComponent } from './style-guide/input/style-guide-input.component';
import { StyleGuidePanelsComponent } from './style-guide/panels/style-guide-panels.component';
import { StyleGuideColorsComponent } from './style-guide/colors/style-guide-colors.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CancelAccountModalComponent } from './components/modal/cancel-account/cancel-account-modal.component';
import { RegisterBrokerCompanyComponent } from './register/broker-company/broker-company.component';
import { PoliciesComponent } from './policies/policies.component';
import { InvestmentsComponent } from './investments/investments.component';
import { DeleteInvestmentComponent, DeleteInvestmentComponentPreview } from './components/modal/delete/delete-investment/delete-investment.component';
import { BrokerDashboardComponent } from './dashboard/broker-dashboard/broker-dashboard.component';
import { DeleteBrokerComponent, DeleteBrokerComponentPreview } from './components/modal/delete/delete-broker/delete-broker.component';
import { PricingComponent } from './pricing/pricing.component';
import { ColorsComponent } from './design/base/colors/colors.component';
import { TypographyComponent } from './design/base/typography/typography.component';
import { ButtonsComponent } from './design/base/buttons/buttons.component';
import { ButtonGroupComponent } from './design/base/button-group/button-group.component';
import { DropdownComponent } from './design/base/dropdown/dropdown.component';
import { BootstrapTabsComponent } from './design/base/tabs/bootstrap-tabs/bootstrap-tabs.component';
import { TablesComponent } from './design/base/tables/tables.component';
import { ModalComponent } from './design/base/modal/modal.component';
import { MiscellaneousComponent } from './design/custom/miscellaneous/miscellaneous.component';
import { DeleteClientComponent, DeleteClientComponentPreview } from './components/modal/delete-client/delete-client.component';
import { DeleteEmployeeComponent, DeleteEmployeeComponentPreview } from './components/modal/delete-employee/delete-employee.component';
import { CardsComponent } from './design/custom/cards/cards.component';
import { TimelineComponent } from './design/custom/timeline/timeline.component';
import { ForgotPasswordCreateNewComponent } from './forgot-password/new/forgot-password-create-new.component';

import { NotificationsComponent } from './notifications/notifications.component';
import { ManagementFAQComponent } from './management/management-faq/management-faq.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RejectInstructionModalComponent, RejectInstructionModalComponentPreview } from './components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { DeleteValueAddedProductComponent } from './components/modal/delete-value-added-product/delete-value-added-product.component';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SURESPACE_DATE_FORMATS } from 'src/app/_helpers/format-dates';
import { RejectClaimModalComponent, RejectClaimModalComponentPreview } from './components/modal/rejectClaimModal/reject-claim-modal.component';
import { IdNumberTesterComponent } from './_helpers/id-number-tester/id-number-tester.component';
import { DeleteTrailersAndCaravansModalComponent } from './components/modal/deleteTrailersAndCaravans/delete-trailers-and-caravans-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ActivateComponent,
    RegisterComponent,
    AboutComponent,
    TermsAndConditionsComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    BulletinBoardComponent,
    AdvertisingComponent,
    LogoutComponent,
    LoadingScreenComponent,
    DeleteModalComponent,
    DeleteModalPreview,
    ActivateComponent,
    ResetPasswordComponent,
    CancelAccountComponent,
    RenewSessionComponent,
    ManagementComponent,
    ManagementUsersComponent,
    FaqComponent,
    ManagementConsoleComponent,
    ReportIssueComponent,
    SuggestionsComponent,
    StyleGuideComponent,
    StyleGuideBackgroundComponent,
    StyleGuideTypographyComponent,
    StyleGuideButtonsComponent,
    StyleGuideInputComponent,
    StyleGuidePanelsComponent,
    StyleGuideColorsComponent,
    NotFoundComponent,
    CancelAccountModalComponent,
    RegisterBrokerCompanyComponent,
    PoliciesComponent,
    InvestmentsComponent,
    DeleteInvestmentComponent,
    DeleteInvestmentComponentPreview,
    BrokerDashboardComponent,
    DeleteBrokerComponent,
    DeleteBrokerComponentPreview,
    PricingComponent,
    ColorsComponent,
    TypographyComponent,
    ButtonsComponent,
    ButtonGroupComponent,
    DropdownComponent,
    BootstrapTabsComponent,
    TablesComponent,
    ModalComponent,
    MiscellaneousComponent,
    DeleteClientComponent,
    DeleteClientComponentPreview,
    DeleteEmployeeComponent,
    DeleteEmployeeComponentPreview,
    CardsComponent,
    TimelineComponent,
    ForgotPasswordCreateNewComponent,
    NotificationsComponent,
    ManagementFAQComponent,
    RejectInstructionModalComponent,
    RejectInstructionModalComponentPreview,
    DeleteValueAddedProductComponent,
    RejectClaimModalComponent,
    RejectClaimModalComponentPreview,
    IdNumberTesterComponent,
    DeleteTrailersAndCaravansModalComponent
  ],
  entryComponents:[
    DeleteModalPreview,
    RenewSessionComponent,
    CancelAccountModalComponent,
    DeleteInvestmentComponent,
    DeleteInvestmentComponentPreview,
    DeleteBrokerComponent,
    DeleteBrokerComponentPreview,
    DeleteClientComponent,
    DeleteClientComponentPreview,
    DeleteEmployeeComponent,
    DeleteEmployeeComponentPreview,
    RejectInstructionModalComponentPreview,
    RejectClaimModalComponentPreview
  ],
  imports: [
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    }),
    SharedModule,
    BrowserModule,
    ClientModule,
    BrokerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LightboxModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      extendedTimeOut: 3000,
      positionClass: 'toast-top-center',
      closeButton: true,
      enableHtml: true,
      autoDismiss: true,
      tapToDismiss: false,
      preventDuplicates: true
    }),
    DragDropModule
  ],
  providers: [
    Globals,
    Shared,
    AuthGuardService,
    AnonymousGuardService,
    UserGuardService,
    BrokerGuardService,
    AdminGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { 
      provide: RECAPTCHA_SETTINGS,
      useValue: { 
        siteKey: '6LezlcAUAAAAAPAvJq2fL67EKmmXdS-6v92DAjgS'
      } as RecaptchaSettings
    },
    RejectInstructionModalComponent,
    RejectClaimModalComponent,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: SURESPACE_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
