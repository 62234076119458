import { RejectClaimModalComponent } from './../../../../components/modal/rejectClaimModal/reject-claim-modal.component';
import { BrokerComponent } from './../../../broker.component';
import { RequestService } from 'src/app/services/request.service';
import { RejectInstructionModalComponent } from 'src/app/components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-broker-claims-handover-modal',
  templateUrl: './broker-claims-handover-modal.component.html',
  styleUrls: ['./broker-claims-handover-modal.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerClaimsHandoverModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BrokerClaimsHandoverModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rejectClaimModal: RejectClaimModalComponent,
    private requestService: RequestService,
    private brokerComponent: BrokerComponent
  ) { }

  ngOnInit(): void {
  }

  acceptRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.brokerComponent.getUserRequests();
        this.dialogRef.close();
      }
    )
  }

  rejectRequest(request){
    this.rejectClaimModal.openDialog({ request });
    this.rejectClaimModal.dialogRef.afterClosed().subscribe(response => {
      this.brokerComponent.getUserRequests();
      this.dialogRef.close();
    })
  }

}
