import { BrokerClaimsHandoverModalComponent } from './claim-handover/broker-claims-handover-modal.component';
import { RejectInstructionModalComponent } from './../../../components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from './../../../services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerClaimResponse } from './../../../_shared/models/brokerClaims.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../../services/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BrokerClaimsService } from './../../../services/broker-claims.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_shared/models/User.model';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BrokerComponent } from 'src/app/broker/broker.component';

@Component({
  selector: 'app-broker-claims-edit-claim',
  templateUrl: './broker-claims-edit-claim.component.html',
  styleUrls: ['./broker-claims-edit-claim.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerClaimsEditClaimComponent implements OnInit {

  hasRequestAttached: boolean = false;
  request: any; //<!-- SCREW YOU TOO (cannot set as UserRequest[])

  selectedClaim = this.activeRoute.snapshot.paramMap.get('claimId');
  currentClaimStep:string;

  clients: User[];
  currentCompany: string;

  claimsFormGroup: FormGroup;

  dialogRef = null;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  get claimRejectedReason(){
    return this.claimsFormGroup.get('claim').value.claimRejected
  }

  constructor(
    private formBuilder: FormBuilder,
    public location: Location,
    private brokerClaimService: BrokerClaimsService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private requestService: RequestService,
    private dialog: MatDialog,
    private rejectInstructionModal: RejectInstructionModalComponent,
    private brokerComponent: BrokerComponent,
    private router: Router
  ) {

    this.currentCompany = this.authenticationService.currentCompany.companyName;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions

    this.claimsFormGroup = this.formBuilder.group({
      claim: []
    })

    this.activeRoute.queryParamMap.subscribe(param => {
      Object.keys(param['params']).forEach((key, index) => {

        if(key === 'request'){
          this.hasRequestAttached = true;

          this.requestService.getSingleRequest(param['params']['request']).subscribe(response => {
            this.request = response.body.requests;

            if(this.request?.whatNeedsToHappen === 'assign'){
              this.openHandoverModal(this.request._id);
            }
            
          })
        }
      })
    })

  }

  ngOnInit(): void {
    this.getCurrentClaim(this.selectedClaim);
  }

  setCurrentStep(event){
    this.currentClaimStep = event;
  }

  openHandoverModal(requestId:string){

   this.dialogRef = this.dialog.open(BrokerClaimsHandoverModalComponent, { 
     width: '800px',
     data: {
       requestId,
       employee: `${this.request.createdBy.firstName} ${this.request.createdBy.lastName}`
     }
    })
  }

  saveClaim(saveType: string = 'back'){

    // this.formSubmitted = true;
    // // stop here if form is invalid
    if (this.claimsFormGroup.get('claim').invalid) {
      console.warn('form => ', this.claimsFormGroup)
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save claim.')
      return;
    }

    this.brokerClaimService.editClaim(this.currentCompany, this.selectedClaim, this.claimsFormGroup.getRawValue().claim).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  goBack(){
    this.location.back()
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.claimsFormGroup.get('claims').reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  getCurrentClaim(claimId:string){
    this.brokerClaimService.getSelectedClaim(this.authenticationService.currentCompany.companyName, claimId).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {

        response.body.claim['productDisplay'] = response.body.claim['product'];
        this.claimsFormGroup.get('claim').patchValue(response.body.claim);
      }
    )
  }

  acceptRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.hasRequestAttached = false;
        // this.claimFormGroup.get('employee')['controls'].display.patchValue(response.body.instruction['employee']);
        this.brokerComponent.getUserRequests();
        this.location.back();
      }
    )
  }

  rejectRequest(request){
    this.rejectInstructionModal.openDialog({ request }, 'claim');
    this.rejectInstructionModal.dialogRef.afterClosed().subscribe(response => {
      if(response == 'success'){
        this.brokerComponent.getUserRequests();
        this.hasRequestAttached = false;
      }
    })
  }

  canSignoff(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  goToClientFile(){
    let client = this.claimsFormGroup.get('claim').value.client;

    if(client.companyName){
      this.router.navigate(['/b/clients/commercial/edit', client._id], { queryParams: { file: '8' } })
    } else {
      this.router.navigate(['/b/clients/personal/edit', client._id], { queryParams: { file: '8' } })
    }
  }

}
