<div id="content" class="row">

        <sub-navigation heading="New investment"></sub-navigation>
      
        <div class="col-12">
      
          <div class="panel panel-primary">
      
            <div class="panel-heading">
      
                <h5>New investment</h5>
      
                <div class="my-auto">
                  <button matTooltip="Go back to client" class="btn btn-secondary my-auto" (click)="location.back()"><i class="fi flaticon-back"></i>Back</button>
                  <button
                    matTooltip="Add new investment and go back"
                    class="btn btn-brand my-auto"
                    (click)="addInvestment('back')"
                    >
                      Save
                  </button>
      
                  <button class="btn btn-light btn-pill my-auto color-brand" [matMenuTriggerFor]="menuNew">
                    <i class="fi flaticon-menu"></i>
                  </button>
      
                  <mat-menu xPosition="before" yPosition="below" #menuNew="matMenu">
                    <button (click)="addInvestment('back')" mat-menu-item>
                      <span>Save &amp; go back</span>
                    </button>
                    <button (click)="addInvestment('new')" mat-menu-item>
                      <span>Save &amp; add new</span>
                    </button>
                  </mat-menu>
      
                </div> <!-- Close div for .my-auto-->
      
            </div> <!-- Close div for .panel-heading -->
      
            <div class="panel-body pb-5">

                  <form [formGroup]="investmentForm">
      
                    <div class="row">
          
                      <div class="col-12">
                        <label class="upload-input w-100">
                          <small class="d-block mb-2">Upload policy documents</small>
                          <button mat-flat-button type="button" class="btn btn-primary">
                            Choose files
                            <input mat-flat-button #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                            (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                          </button>
                          <div class="list-timeline">
                            <div class="list-timeline-item" *ngFor="let file of files; let first = first" [ngClass]="(first && files.length > 1) ? 'first' : 'lonely'">
                              <span class="list-timeline_badge"></span>
                              <span class="list-timeline_text">{{ file.name }}</span>
                              <span class="list-timeline_action"><i class="fi flaticon-trash color-danger" matTooltip="Remove file" (click)="removeFile(file.id)"></i></span>    
                            </div>
                          </div>
                        </label> <!-- Close .upload-button -->
          
                      </div>
          
                        <div class="col-12">
                          <hr />
                        </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Investment type" name="type" formControlName="type">
                            <mat-option *ngFor="let type of investmentType" [value]="type">{{ type }}</mat-option>
                          </mat-select>
          
                          <mat-error *ngIf="form['type'].errors && form['type'].errors.required">
                            Please select the <strong>investment type</strong>.
                          </mat-error>
          
                        </mat-form-field>
                      </div>
          
                      <div class="col-sm-4 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Policy number" name="policyNumber" type="text" formControlName="policyNumber" />
                          
                          <mat-error *ngIf="form['policyNumber'].errors && form['policyNumber'].errors.required">
                            Please enter a <strong>policy number</strong> for this investment.
                          </mat-error>
                        
                        </mat-form-field>  
                      </div>
          
                      <div class="col-12">
                        <hr />
                      </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Investment company" name="investmentCompany" type="text" formControlName="investmentCompany" />
          
                          <mat-error *ngIf="form['investmentCompany'].errors && form['investmentCompany'].errors.required">
                            Please enter the <strong>investment company</strong> with whom the investment is invested.
                          </mat-error>
                        </mat-form-field>
                      </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <span matPrefix>R &nbsp;</span>
                          <input matInput placeholder="Amount invested" name="investedAmount" type="text" formControlName="investedAmount" />
          
                          <mat-error *ngIf="form['investedAmount'].errors && form['investedAmount'].errors.required">
                            Please enter the <strong>invested amount</strong> for this investment.
                          </mat-error>
                        </mat-form-field>
                      </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input placeholder="Investment date" matInput [matDatepicker]="investmentDate" name="investmentDate"
                            formControlName="investmentDate" [max]="today">
                          <mat-datepicker-toggle matSuffix [for]="investmentDate"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #investmentDate></mat-datepicker>
          
                          <mat-error *ngIf="form['investedAmount'].errors && form['investedAmount'].errors.required">
                            Please enter an <strong>investment date</strong> for this investment.
                          </mat-error>
                        </mat-form-field>
                      </div>
          
                      <div class="col-12">
                        <hr />
                      </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Calculation period" name="type" formControlName="calculationPeriod">
                            <mat-option [value]="1">1 Month</mat-option>
                            <mat-option [value]="2">2 Months</mat-option>
                            <mat-option [value]="3">3 Months</mat-option>
                            <mat-option [value]="4">4 Months</mat-option>
                            <mat-option [value]="5">5 Months</mat-option>
                            <mat-option [value]="6">6 Months</mat-option>
                            <mat-option [value]="7">7 Months</mat-option>
                            <mat-option [value]="8">8 Months</mat-option>
                            <mat-option [value]="9">9 Months</mat-option>
                            <mat-option [value]="10">10 Months</mat-option>
                            <mat-option [value]="11">11 Months</mat-option>
                            <mat-option [value]="12">12 Months</mat-option>
                          </mat-select>
          
                          <mat-error *ngIf="form['calculationPeriod'].errors && form['calculationPeriod'].errors.required">
                            Please select the <strong>calculation period</strong> for this investment.
                          </mat-error>
                        </mat-form-field>
                      </div>
          
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input placeholder="Payout date" matInput [matDatepicker]="payoutDate" name="payoutDate"
                            formControlName="payoutDate" [min]="today">
                          <mat-datepicker-toggle matSuffix [for]="payoutDate"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #payoutDate></mat-datepicker>
                        </mat-form-field>
                      </div>
          
                    </div> <!-- Close div for .row -->
          
                  </form>     
      
            </div> <!-- Close div for .panel-body -->
      
          </div> <!-- Close div for .panel -->
      
        </div> <!-- Close div for .col-12 -->
      </div> <!-- Close div for #content -->