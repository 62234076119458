<div class="page-description">
        <div class="row h-100">
          <div class="col-6 my-auto">
            <h5 class="d-inline-block mb-0 font-weight-400">Structure insurance</h5>
            <h6 class="d-inline-block mb-0">Edit structure details and submit</h6>
          </div>
      
          <div class="col-6 text-right my-auto">
      
            <button type="button" class="btn btn-secondary" (click)="goBack()">
              Back
            </button>
      
            <div class="btn-group" ngbDropdown>
              <button type="button" class="btn btn-primary" mat-tooltip="Add new vehicle and go back."
                (click)="updateStructure('continue')">
                Submit
              </button>
              <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                aria-controls="dropdown-basic">
              </button>
              <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="updateStructure('continue')">Save &amp; continue</a>
                </li>
                <li role="menuitem"><a class="dropdown-item" (click)="updateStructure('back')">Save &amp; go back</a></li>
              </ul>
            </div> <!-- Close div for .btn-group -->
      
          </div>
        </div> <!-- Close div for .row -->
      </div> <!-- Close div for .page-description -->
      
      <div class="row">
      
        <div class="col-12">
      
          <div class="panel panel-primary">
            <form id="newHouseholdItemForm" [formGroup]="structureFormGroup">
      
              <div class="panel-heading">
                <h5>Information</h5>
              </div>
      
              <div class="panel-body">

                <div class="row">
                    <div class="col-12">
                        <label for="item"><strong>Item photos:</strong></label>
                    </div>
                    <div class="col-sm-2 col-4" *ngFor="let photo of structureFormGroup.value.s3Files; let i=index">
                        <div class="show-remove-image thumbnails">
    
                            <div class="remove-image-holder edit-image-view" (click)="open(i)">
                                <i class="fi flaticon-zoom"></i>
                            </div>
        
                            <img [src]="photo.src" class="img-fluid" />
                        </div>
                        <button (click)="removeExistingStructurePhoto(photo)" class="btn btn-danger btn-block mt-2">Remove image</button>
                    </div>
                </div> <!-- Close div for .row -->

                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="name"><strong>Building name:</strong></label>
                    <input formControlName="name" name="name" type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form.name.errors }" />
      
                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback">
                      <div *ngIf="form['name'].errors.required">Please enter your structure name.</div>
                    </div>
      
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-12">
      
                    <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                      [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
      
                      <i class="fi flaticon-upload"></i>
                      <strong>Drop photos here<br />or <span class="text-bold text-surespace">click to select</span></strong>
      
                      <label class="upload-button">
                        <input #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                          (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                      </label> <!-- Close .upload-button -->
      
                    </div><!-- close .drop-container -->
      
                    <div>
                      <div id="uploads" class="row" *ngIf="files?.length">
                        <div id="upload-item" class="col-sm-2 col-4" *ngFor="let f of files; let i = index;">
      
                          <div class="thumbnail">
                            <img src="{{ f['imagePreview'] }}" class="img-fluid" />
                            <!-- <button (click)="removeFile(f.id)"></button> -->
                            <div class="removeImage">
                              <div class="remove" (click)="removeFile(f.id)">
                                x
                              </div> <!-- Close div for .remove -->
                            </div> <!-- Close div for .removeImage -->
                          </div>
      
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="stand"><strong>Stand number:</strong></label>
                    <input formControlName="stand" name="stand" type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form.stand.errors }" />
      
                    <div *ngIf="submitted && form['stand'].errors" class="invalid-feedback">
                      <div *ngIf="form['stand'].errors.required">Please enter your structure stand number.</div>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                  </div>
      
                  <div class="col-sm-6 col-12">
                    <label for="suburb"><strong>Suburb:</strong></label>
                    <input formControlName="suburb" name="suburb" type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form.suburb.errors }" />
      
                    <div *ngIf="submitted && form['suburb'].errors" class="invalid-feedback">
                      <div *ngIf="form['suburb'].errors.required">Please enter your structure suburb.</div>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="city"><strong>City/Town:</strong></label>
                    <input formControlName="city" name="city" type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form.city.errors }" />
      
                    <div *ngIf="submitted && form['city'].errors" class="invalid-feedback">
                      <div *ngIf="form['city'].errors.required">Please enter your structure city.</div>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                  </div>
      
                  <div class="col-sm-6 col-12">
                    <label for="municipalAccNr"><strong>Municipal account number:</strong></label>
                    <input formControlName="municipalAccNr" name="municipalAccNr" type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form.municipalAccNr.errors }" />
      
                    <div *ngIf="submitted && form['municipalAccNr'].errors" class="invalid-feedback">
                      <div *ngIf="form['municipalAccNr'].errors.required">Please enter your structure municipal account
                        number.</div>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="structureValue"><strong>Structure value:</strong></label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">R</div>
                      </div>
                      <input formControlName="structureValue" name="structureValue" type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.structureValue.errors }" />
      
                      <div *ngIf="submitted && form['structureValue'].errors" class="invalid-feedback">
                        <div *ngIf="form['structureValue'].errors.required">Please enter your structure value.</div>
                      </div>
      
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
              </div> <!-- Close div for .panel-body -->
      
              <div class="panel-heading mt-4">
                <h5>Building description</h5>
              </div>
      
              <div class="panel-body">
      
                <div class="row">
      
                  <div class="col-sm-6 col-12" formGroupName="roof">
                    <label for="roof"><strong>Roof:</strong></label>
      
                    <div class="checkbox" *ngFor="let roof of structureFormGroup.get('roof')['controls'] | keyvalue">
                      <mat-checkbox disableRipple [id]="'roof' + roof.key"
                      [name]="'roof' + roof.key" [formControlName]="roof.key">{{ roof.key | sentenceCase }}</mat-checkbox>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                    <br />
                  </div>
      
                  <div class="col-sm-6 col-12" formGroupName="floor">
                    <label for="floor"><strong>Floor:</strong></label>
                    <div class="checkbox" *ngFor="let floor of structureFormGroup.get('floor')['controls'] | keyvalue">
                      <mat-checkbox disableRipple [id]="'floor' + floor.key"
                      [name]="'floor' + floor.key" [formControlName]="floor.key">{{ floor.key | sentenceCase }}</mat-checkbox>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
      
                <div class="row">      
                  <div class="col-sm-6 col-12" formGroupName="externalWalls">
                    <label for="externalWalls"><strong>External Wall:</strong></label>
                    <div class="checkbox"
                      *ngFor="let externalWalls of structureFormGroup.get('externalWalls')['controls'] | keyvalue">
                      <mat-checkbox disableRipple [id]="'externalWalls' + externalWalls.key"
                      [name]="'externalWalls' + externalWalls.key" [formControlName]="externalWalls.key">{{ externalWalls.key | sentenceCase }}
                      </mat-checkbox>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                    <br />
                  </div>
      
                  <div class="col-sm-6 col-12" formGroupName="additions">
                    <label for="additions"><strong>Building additions:</strong></label>
                    <div class="checkbox"
                      *ngFor="let additions of structureFormGroup.get('additions')['controls'] | keyvalue">
                      <mat-checkbox disableRipple [id]="'additions' + additions.key"
                      [name]="'additions' + additions.key" [formControlName]="additions.key">{{ additions.key | sentenceCase }}
                      </mat-checkbox>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
      
                <div class="col-12 clearfix mt-3">
                </div>
      
      
                <div class="row">
      
                  <div class="col-sm-6 col-12" formGroupName="security">
                    <label for="security"><strong>Security:</strong></label>
                    <div class="checkbox"
                      *ngFor="let security of structureFormGroup.get('security')['controls'] | keyvalue">
                      <mat-checkbox disableRipple [id]="'security' + security.key"
                      [name]="'security' + security.key" [formControlName]="security.key">{{ security.key | sentenceCase }}
                      </mat-checkbox>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                    <br />
                  </div>
      
                  <div class="col-sm-6 col-12">
                    <label for="structures"><strong>Does the building have any additional structures:</strong></label>
                    <div class="input-group">
                      <mat-form-field class="form-control">
                        <mat-select name="structures" formControlName="structures">
                          <mat-option value="Yes">Yes</mat-option>
                          <mat-option value="No">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
      
      
                    <div class="row">
      
                      <div *ngIf="form['structures'].value === 'Yes'" class="col-12 mt-3">
                        <label for="additionalStructures"><strong>Structures:</strong></label>
                        <div formArrayName="additionalStructures">
                          <div id="additionalStructures" class="row mt-2" [formGroupName]="i"
                            *ngFor="let addition of form['additionalStructures'].controls; let i = index">
                            <div class="form-group col-10 my-auto">
                              <input type="text" class="form-control" name="additionalStructures"
                                formControlName="structure" />
                            </div>
                            <div class="form-group col-2 my-auto">
                              <button class="btn btn-icon btn-danger btn-sm float-right float-sm-left"
                                (click)="removeInputField(i)">
                                <i class="fi flaticon-trash mr-0"></i>
                              </button>
                            </div>
                          </div> <!-- Close div for .row -->
      
                          <div class="row mt-2">
                            <div class="col-12">
                              <button class="btn btn-label-success btn-sm" (click)="addNewInputField()">Add structure</button>
                            </div>
                          </div>
      
                        </div>
      
                      </div> <!-- Close div for .col-12 -->
      
                    </div> <!-- Close div for .row -->
      
                  </div> <!-- Close div for .col-sm-6 col-12 -->
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
              </div> <!-- Close div for .panel-body -->
      
              <div class="panel-heading mt-4">
                <h5>Insurance</h5>
              </div>
      
              <div class="panel-body">
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="insuredWith"><strong>Insured with:</strong></label>
                    <div class="input-group">
                      <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form.insuredWith.errors }">
                        <mat-select name="insuredWith" formControlName="insuredWith">
      
                          <mat-optgroup label="Brokers" *ngIf="brokers && brokers.length > 0">
                            <mat-option *ngFor="let broker of brokers" [value]="broker">{{ broker?.company }}
                            </mat-option>
                          </mat-optgroup>
      
                          <!-- <mat-optgroup label="Insurance companies"
                              *ngIf="insuranceCompanies && insuranceCompanies.length > 0">
                              <mat-option *ngFor="let company of insuranceCompanies" [value]="company">
                              {{ company?.companyName }}</mat-option>
                          </mat-optgroup> -->
      
                          <mat-option
                            *ngIf="brokers && brokers?.length === 0"
                            disabled>No insurers added
                          </mat-option>
      
                          <div class="row mt-2">
                              <button (click)="addNewBroker()" class="btn btn-square btn-label-primary btn-block">
                                  New broker
                              </button>
                              <!-- <div class="dropdown-buttons col-12 col-md-6">
                              <button class="btn btn-secondary btn-block h-100 btn-square">New insurance company</button>
                              </div> -->
                          </div>
      
                        </mat-select>
      
                      </mat-form-field>
                    </div>
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                  </div>
      
                  <div class="col-sm-6 col-12">
                    <label for="structureValue"><strong>Date insured:</strong></label>
                    <div class="input-group">
                      <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form.dateInsured.errors }">
                        <input matInput [matDatepicker]="dateInsured" name="dateInsured" formControlName="dateInsured"
                          [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="dateInsured"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dateInsured></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                </div>
      
                <div class="row">
      
                  <div class="col-sm-6 col-12">
                    <label for="insuredValue"><strong>Insured value:</strong></label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">R</div>
                      </div>
                      <input type="text" class="form-control" name="insuredValue" formControlName="insuredValue"
                        [ngClass]="{ 'is-invalid': submitted && form.insuredValue.errors }" />
                    </div> <!-- Close div for .input-group-->
                  </div>
      
                  <div class="col-12 clearfix d-sm-none d-block">
                  </div>
      
                  <div class="col-sm-6 col-12">
                    <label for="premium"><strong>Premium:</strong></label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">R</div>
                      </div>
                      <input type="text" class="form-control" name="premium" formControlName="premium"
                        [ngClass]="{ 'is-invalid': submitted && form.premium.errors }" />
                    </div> <!-- Close div for .input-group-->
                  </div>
      
                </div> <!-- Close div for .row -->
      
                <div class="col-12 clearfix mt-3">
                  </div>
        
                  <div class="row">
        
                    <div class="col-sm-6 col-12">
                      <label for="policyNumber"><strong>Policy number:</strong></label>
                      <div class="input-group">
                        <input type="text" class="form-control" name="policyNumber" formControlName="policyNumber"
                          [ngClass]="{ 'is-invalid': submitted && form.policyNumber.errors }" />
                      </div> <!-- Close div for .input-group-->
                    </div>
        
                  </div> <!-- Close div for .row -->
      
              </div> <!-- Close div for .panel-body -->
      
            </form>
          </div> <!-- Close div for .panel -->
      
        </div> <!-- Close div for .col-12 -->
      
      </div> <!-- Close div for .row -->
      