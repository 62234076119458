<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">My family members</h5>
      <h6 class="d-inline-block mb-0">{{ familyMembers?.length }} Total</h6>
        <div class="search d-inline-block ml-4">
          <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="color-primary fi fi-bold flaticon-zoom"></i></div>
          </div>
          <input placeholder="Search" type="text" class="form-control" />
        </div> <!-- Close div for .input-group .search -->
      </div>
    </div>

    <div class="col-6 text-right align-self-center">
      <!-- <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button> -->

      <div class="btn-group" ngbDropdown *ngIf="currentUserPermissions.includes('brokerLeadProvider') || currentUserPermissions.includes('brokerClerk') || currentUserPermissions.includes('brokerAdmin') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerHR') || currentUserPermissions.includes('brokerDirector')">
        <button type="button" [routerLink]="['new-member']" class="btn btn-primary">
          Add new family member
        </button>
      </div> <!-- Close div for .btn-group -->

    </div>
  </div>  
</div>

<div class="row">

  <div class="col-12 mt-3" *ngIf="familyMembers?.length < 1">
    <p class="text-center">You have not added any family members to your account.</p>
  </div>

  <div class="col-lg-4 col-md-6 col-12" *ngFor="let member of familyMembers">
    <div class="card">

      <div class="card-header d-flex flex-wrap">

        <div class="card-header-menu" ngbDropdown *ngIf="currentUserPermissions.includes('brokerLeadProvider') || currentUserPermissions.includes('brokerClerk') || currentUserPermissions.includes('brokerAdmin') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerHR') || currentUserPermissions.includes('brokerDirector')">
            <button ngbDropdownToggle type="button" class="btn btn-icon color-info mr-0" aria-controls="dropdown-basic">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
            <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" [routerLink]="['edit-member/', ({id: member._id})]">Edit family member</a></li>
              <li role="menuitem"><a class="dropdown-item" (click)="removeFamilyMember(member)">Remove family member</a></li>
            </ul>        
        </div>

        <span class="userpic userpic-info userpic-lg mr-3">
          <span>{{ member?.name.substring(0, 1) }} {{ member?.surname.substring(0, 1) }}</span>
        </span>

        <div class="d-inline-block my-auto">
          <h5 class="card-heading m-0">{{ member?.name }} {{ member?.surname }}</h5>
          <span class="card-subheading">{{ member?.relationship }}</span>
        </div>
          
      </div>

      <div class='card-body'>
        <p>
          <strong class="color-black">ID Number:</strong>
          <span class="float-right color-label-2">{{ member?.idNumber }}</span>
          <span *ngIf="member.relationship === 'Spouse'">
              <br />
              <strong class="color-black" >Anniversary date:</strong>
              <span class="float-right color-label-2">{{ member?.anniversary }}</span>
          </span>
        </p>
      </div>

    </div> <!-- Close div for .card -->

  </div> <!-- Close div for .col-lg-4 .col-md-6 .col-12 -- FAMILY MEMBER LOOP -->

</div> <!-- Close div for .row -->