import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BrokerService } from 'src/app/services/broker.service';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { Policy, PolicyResponse } from 'src/app/_shared/models/policy.model';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { ValueAddedProduct, ValueAddedProductResponse } from 'src/app/_shared/models/vaps.model';

@Component({
  selector: 'app-broker-new-edit-client-value-added-products',
  templateUrl: './broker-new-edit-client-value-added-products.component.html',
  styleUrls: ['./broker-new-edit-client-value-added-products.component.scss']
})
export class BrokerNewEditClientValueAddedProductsComponent implements OnInit, AfterViewInit {

  @Input() view: boolean = false;

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  routerPath: string = this.activeRoute.routeConfig.path;
  editProduct = this.routerPath.includes('edit');

  maxDate = new Date();

  clientId = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  clientInformation: any;

  productIdNumber = this.activeRoute.snapshot.paramMap.get('productId');
  product: ValueAddedProduct[];

  brokers: Employee[];
  // insuranceCompanies: InsuranceCompany[];
  policies: Policy[];

  formSubmitted = false;
  productFromGroup: FormGroup;
  get productForm() { return this.productFromGroup.controls; }

  formData: FormData;
  isLoading: boolean = false;

  isQuoteProduct: boolean;

  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (!control.value && this.isQuoteProduct) {
      return { 'required': true };
    }
    return null;
  }

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    // private deleteVehicleModal: DeleteVehicleComponent,
    // private lightbox: Lightbox,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private brokerService: BrokerService,
    private vapsService: ValueAddedProductsService,
    private authService: AuthenticationService,
    private location: Location
  ) {
    this.isQuoteProduct = this.activeRoute.snapshot.queryParams['quote'] === 'true';
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getCurrentProduct(productId:string){

    this.vapsService.getSingleVapsForClient(this.clientId, productId).subscribe(
      (response:HttpResponse<ValueAddedProductResponse>) => {
        this.product = response.body.products;
        this.productFromGroup.patchValue(this.product);
        this.productFromGroup.controls.insurance.patchValue(this.product['insurance']);
      }
    )
  };

  getClientInformation(client_id:string){
    this.brokerService.getSingleClient(client_id).subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clientInformation = response.body.user;
      }
    )
  }

  updateProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save product.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(this.isQuoteProduct){
      this.editQuoteProduct();
      return
    }

    this.vapsService.updateVaps(this.clientId, this.productIdNumber, this.productFromGroup.value).subscribe(
      (response:HttpResponse<ValueAddedProductResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save ${this.productFromGroup.get('type').value}.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
      return;
    }

    this.vapsService.addNewClientVaps(this.clientId, this.productFromGroup.value).subscribe(
      (response:HttpResponse<ValueAddedProductResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewQuotedProduct(){
    this.vapsService.addNewClientVapsQuote(this.clientId, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<ValueAddedProductResponse>) => {
        let newVAP = response.body.products;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newVAP))
        this.location.back()
      }
    )
  }

  editQuoteProduct(){
    this.vapsService.editClientVapsQuote(this.clientId, this.productIdNumber, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<ValueAddedProductResponse>) => {
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(response.body.products))
        this.location.back()
      }
    )
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
      this.goBack();
        break;

      case 'continue':
        break;

      default:
        this.goBack();
        break;
    }
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.router.navigate(['/b/clients/personal/edit', this.clientId]);
      this.location.back();
    };
  }

  addNewPolicy(){
    localStorage.setItem('SureSpace-ValueAddedProducts', JSON.stringify(this.productFromGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-ValueAddedProducts')){
      this.productFromGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-ValueAddedProducts')));
      localStorage.removeItem('SureSpace-ValueAddedProducts');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  ngOnInit() {
    this.productFromGroup = this.formBuilder.group({
      vapType: ['', Validators.required],
      vapName: ['', Validators.required],
      vapValue: ['', Validators.required],
      description: [''],
      insurance: this.formBuilder.group({
        companyName: [''],
        premium: [''],
        policyNumber: [''],
        inceptionDate: [''],
      }),
      isQuoteProduct: false,
    });

    this.productFromGroup.get('insurance').get('companyName').setValue(this.authService.currentCompany.companyName);

    if(this.isQuoteProduct){
      this.productFromGroup.get('isQuoteProduct').setValue(true);
    }

  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, this.clientId, { active: true }).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  ngAfterViewInit(){
    this.getClientInformation(this.clientId);

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicyNumber();
    }

    if(this.editProduct){
      this.getCurrentProduct(this.productIdNumber);
    }

    this.getLocalStorageItem();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}
