<div class="modal-header">
    <h6 class="mb-0"><strong>Timeout warning!</strong></h6>
</div>

<div class="modal-body">
  <div mat-dialog-content>

    <div class="col-12 text-center logout-timer">
        <span>{{ timeRemaining }}</span>
    </div>

    <p>Your session is about to expire.<br />
      Would you like to renew your session?</p>

    </div>
</div> <!-- Close div for .modal-body -->

<div class="modal-footer">
  <div mat-dialog-actions class="inline-buttons">
    <button class="btn btn-primary" (click)="renewSession()" cdkFocusInitial>Yes</button>
    <button class="btn btn-secondary" (click)="endSession()">No</button>
  </div>
</div> <!-- Close div for .modal-footer -->
              