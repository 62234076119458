<h1>Typography</h1>

<div class="row">
  <div class="col-12 table-responsive">

    <table class="table table-sm table-borderless">
      <tr>
        <td class="align-middle">
          <p class="mb-0">header 1</p>
        </td>
        <td class="align-middle">
          <h1>Proxima Nova 40px</h1>
        </td>
      </tr>
      <tr>
        <td class="align-middle">
          <p class="mb-0">header 2</p>
        </td>
        <td class="align-middle">
          <h2>Proxima Nova 32px</h2>
        </td>
      </tr>
      <tr>
        <td class="align-middle">
          <p class="mb-0">header 3</p>
        </td>
        <td class="align-middle">
          <h3>Proxima Nova 28px</h3>
        </td>
      </tr>
      <tr>
        <td class="align-middle">
          <p class="mb-0">header 4</p>
        </td>
        <td class="align-middle">
          <h4>Proxima Nova 24px</h4>
        </td>
      </tr>
      <tr>
        <td class="align-middle">
          <p class="mb-0">header 5</p>
        </td>
        <td class="align-middle">
          <h5>Proxima Nova 20px</h5>
        </td>
      </tr>
    </table>

  </div>

  <div id="font-weights" class="col-12">
    <p><b>Font weights</b><br />
      <span class="mr-3 font-weight-900">weight 900</span>
      <span class="mr-3 font-weight-800">weight 800</span>
      <span class="mr-3 font-weight-700">weight 700</span>
      <span class="mr-3 font-weight-600">weight 600</span>
      <span class="mr-3 font-weight-500">weight 500</span>
      <span class="mr-3 font-weight-400">weight 400</span>
      <span class="mr-3 font-weight-300">weight 300</span>
      <span class="mr-3 font-weight-200">weight 200</span>
      <span class="font-weight-100">weight 100</span>
    </p>
  </div>

  <div class="col-12">
    <pre>
      <p>This is a normal paragraph.     |     <strong>This is a bold paragraph.</strong>     |     <i>This is an italics paragraph.</i>     |     <small>This is a small paragraph.</small></p>
    </pre>
  </div>

  <div class="col-12 table-responsive">

      <table class="table table-sm table-borderless">
        <tr>
          <td>
            <ul>
              <li>unordered list</li>
              <li>unordered list</li>
              <li>unordered list</li>
            </ul>
          </td>
          <td>
            <ol>
              <li>ordered list</li>
              <li>ordered list</li>
              <li>ordered list</li>
            </ol>
          </td>
        </tr>
      </table>
  
    </div>

</div> <!-- Close div for .row -->
