<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">My brokers</h5>
      <h6 class="d-inline-block mb-0">New broker</h6>
    </div>

    <div class="col-6 text-right align-self-center">

      <button type="button" class="btn btn-secondary" (click)="location.back()">
          Back
      </button>

      <div class="btn-group" ngbDropdown>
        <button type="button" class="btn btn-primary" (click)="addNewBroker('back')">
          Save broker
        </button>
        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item" (click)="addNewBroker('continue')">Save &amp; continue</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addNewBroker('back')">Save &amp; go back</a></li>
        </ul>
      </div> <!-- Close div for .btn-group -->

    </div>
  </div>  
</div>

<div class="row">
  
  <div class="col-12">

    <div class="panel panel-primary">

      <div class="panel-heading">
          <h5>Add a new broker</h5>
      </div> <!-- Close div for .panel-heading -->

          <div class="panel-body pt-3 pb-5">
          
              <form id="newBrokerForm" [formGroup]='newBrokerForm'>
          
                    <div class="row">
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker company:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" name="company" class="form-control"
                          [ngClass]="{ 'is-invalid': submittedForm && form.company.errors }" formControlName='company' />
                        <div *ngIf="submittedForm && form['company'].errors" class="invalid-feedback help-block errorMessage">
                          <div *ngIf="form['company'].errors.required">Please enter the company that your broker works for.</div>
                        </div>
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker name:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" name="firstName" class="form-control"
                          [ngClass]="{ 'is-invalid': submittedForm && form.firstName.errors }" formControlName='firstName' />
                        <div *ngIf="submittedForm && form['firstName'].errors" class="invalid-feedback help-block errorMessage">
                          <div *ngIf="form['firstName'].errors.required">Your broker needs a name.</div>
                        </div>
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker surname:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" name="lastName" class="form-control"
                          [ngClass]="{ 'is-invalid': submittedForm && form.lastName.errors }" formControlName='lastName' />
                        <div *ngIf="submittedForm && form['lastName'].errors" class="invalid-feedback help-block errorMessage">
                          <div *ngIf="form['lastName'].errors.required">Your broker needs a surname.</div>
                        </div>
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker code:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" class="form-control" name="brokerCode" formControlName='brokerCode' />
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker email:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" name="email" class="form-control"
                          [ngClass]="{ 'is-invalid': submittedForm && form.company.errors }" formControlName='email' />
                        <div *ngIf="submittedForm && form['email'].errors" class="invalid-feedback help-block errorMessage">
                          <div *ngIf="form['email'].errors.required">Your broker needs an email address.</div>
                        </div>
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker telephone:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" class="form-control" name="workPhone" formControlName='workPhone' />
                      </div>
          
                      <div class="col-12 clearfix">
                      </div>
          
                      <div class="col-xl-3 col-lg-3 text-right my-auto">
                        <strong>Broker cellphone:</strong>
                      </div>
          
                      <div class="col-lg-9 col-xl-6">
                        <input type="text" class="form-control" name="cellphone" formControlName='cellphone' />
                        <div *ngIf="submittedForm && form['cellphone'].errors" class="invalid-feedback">
                          <div *ngIf="form['cellphone'].errors.required">Your broker needs a cellphone number.</div>
                        </div>
                      </div>
          
                    </div> <!-- Close div for .row -->
                </form>

          </div> <!-- Close div for .panel-body-->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for .row -->