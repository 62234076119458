
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { InsuranceService } from 'src/app/services/insurance.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { BrokerCompanyResponse } from 'src/app/_shared/models/brokerCompany.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-client-add-new-broker',
  templateUrl: './client-add-new-broker.component.html',
  styleUrls: ['./client-add-new-broker.component.scss']
})
export class ClientAddNewBrokerComponent implements OnInit {

  newBrokerForm: FormGroup;
  formSubmitted = false;
  get form(){ return this.newBrokerForm.controls; }

  redirectLink = this.activeRoute.snapshot.paramMap.get('r')

  brokerCompanies: any = [];
  brokers: Broker[];

  isLoading = false;

  constructor(
    private insuranceService: InsuranceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public location: Location
  ) { }

  selectedCompany(event: MatAutocompleteSelectedEvent){
    this.getBrokersWorkingForCompany(event.option.value.companyName, 10);
  }

  getBrokersWorkingForCompany(company, limit?:number){
    this.insuranceService.getBrokersWorkingForCompany(company, limit).subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  }

  selectBroker(broker){
    this.insuranceService.addNewBroker(broker).subscribe(
      (response: HttpResponse<any>) => {
        if(this.redirectLink){
          this.router.navigate([this.redirectLink])
        } else {
          this.location.back();
        }
      }
    )
  }

  ngOnInit() {
    this.newBrokerForm = this.formBuilder.group({
      broker: ['']
    })

      this.newBrokerForm
      .get('broker')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.insuranceService.getBrokerCompanies(value, 10)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response: HttpResponse<BrokerCompanyResponse>) => {
          this.brokerCompanies = response.body.brokerCompany;
        },
        (error: HttpErrorResponse) => {
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
  }

  displayFn(broker: Broker) {
    if (broker) { return broker.company; }
  }

}
