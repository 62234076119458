import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service'
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Router } from "@angular/router"
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MyAccountService } from 'src/app/services/my-account.service';

@Component({
  selector: 'app-client-new-vehicle',
  templateUrl: './client-new-vehicle.component.html',
  styleUrls: ['./client-new-vehicle.component.scss']
})
export class ClientNewVehicleComponent implements OnInit, AfterViewInit {

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  currentUser = this.authService.currentUserValue;
  familyMembers: FamilyMember[];

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  submitted = false;
  newVehicleFormGroup: FormGroup;
  get form() { return this.newVehicleFormGroup.controls; }

  maxDate = new Date();

  constructor(
    private insuranceService: InsuranceService,
    private personalInsuranceService: PersonalInsuranceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private myAccountService: MyAccountService
  ) {
    this.options = { concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

   previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  // getAllInsuranceCompanies(){
  //   this.insuranceService.getAllInsuranceCompanies().subscribe(
  //     (response: HttpResponse<InsuranceCompanyResponse>) => {
  //       this.insuranceCompanies = response.body.insuranceCompany;
  //     }
  //   )
  // };

  getAllFamilyMembers(){
    this.myAccountService.getAllFamilyMembers()
    .subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        this.familyMembers = response.body.family;
      }
    )
  };

  addNewVehicle(saveType?:string){

    this.submitted = true;
    // stop here if form is invalid
    if (this.newVehicleFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new vehicle.')
      return;
    }

    // Set photos === files
    this.files.map(file => {
      this.newVehicleFormGroup.value.photos.push(file.nativeFile)
    });

    this.personalInsuranceService.addNewVehicle(this.newVehicleFormGroup.value).subscribe(
      (response:HttpResponse<VehicleResponse>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            break;

          case 'new':
            this.newVehicleFormGroup.reset();
            window.scroll({ top: 0, behavior: 'smooth' });
            break;

          default:
            this.goBack();
            break;
        }
      }
    )
  };

  addNewBroker(){
    localStorage.setItem('SureSpace-NewVehicle', JSON.stringify(this.newVehicleFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  addNewRegularDriver(){
    localStorage.setItem('SureSpace-Vehicle', JSON.stringify(this.newVehicleFormGroup.value))
    this.router.navigate([`/u/me/my-family/new-member`, { r: this.router.url }])
  }


  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-NewVehicle')){
      this.newVehicleFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-NewVehicle')));
      localStorage.removeItem('SureSpace-NewVehicle');
    }
  };  

  initOptionalExtraField(): FormGroup{
    return this.formBuilder.group({
      extra 		: ['', Validators.required]
    });
   };

  addNewOptionalExtra(): void{
    const control = <FormArray>this.form.optionalExtras;
    control.push(this.initOptionalExtraField());
  };

  removeOptionalExtra(i : number): void {
      const control = <FormArray>this.form.optionalExtras;
      control.removeAt(i);
  };

  goBack(){
    this.router.navigate(['/u/short-term-insurance/vehicle']);
  }

  ngOnInit() {
    this.newVehicleFormGroup = this.formBuilder.group({
      registrationNumber: ['', Validators.required],
      photos: [[]],
      make: ['', Validators.required],
      model: ['', Validators.required],
      year: ['', Validators.required],
      color: ['', Validators.required],
      status: ['', Validators.required],
      variant: [''],
      licenceExpiryDate: [''],
      milage: ['', Validators.required],
      use: ['', Validators.required],
      engineSize: [''],
      // vin: ['', Validators.required],
      engineNumber: ['', Validators.required],
      homeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      officeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      optionalSafetyExtras: this.formBuilder.group({
        alarm: false,
        centralLocking: false,
        dataDot: false,
        gearLock: false,
        immobiliser: false,
        smashAndGrab: false,
        steeringLock: false,
        tracker: false
      }),
      optionalExtras: this.formBuilder.array([
        this.formBuilder.group({
          extra: ['']
        })
      ]),
      insured: ['No'],
      dateInsured: [undefined],
      insuredWith: [undefined],
      insuredValue: [undefined],
      premium: [undefined],
      policyNumber: [undefined],
      regularDriver: [''],
      claimFreeYears: ['']
    });  
  }

  ngAfterViewInit() {
    this.getAllBrokers();
    this.getAllFamilyMembers();
    // this.getAllInsuranceCompanies();
    this.getLocalStorageItem();
  }

}

