import { RequestService } from 'src/app/services/request.service';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { BrokerClaimResponse, Claim } from './../../../_shared/models/brokerClaims.model';
import { BrokerClaimsService } from 'src/app/services/broker-claims.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Vehicle } from 'src/app/_shared/models/vehicle.model';
import { HttpResponse } from '@angular/common/http';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerService } from 'src/app/services/broker.service';
import { KeyValue } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { UserRequest } from 'src/app/_shared/models/userRequest.interface';
import { RejectInstructionModalComponent } from 'src/app/components/modal/reject-instruction-modal/reject-instruction-modal.component';


@Component({
  selector: 'app-broker-new-edit-claims',
  templateUrl: './broker-new-edit-claims.component.html',
  styleUrls: ['./broker-new-edit-claims.component.scss'],
})
export class BrokerNewEditClaimsComponent implements OnInit, AfterViewInit {

  selectedClaim = this.activeRoute.snapshot.paramMap.get('id');
  routerPath: string = this.activeRoute.routeConfig.path;
  editClaim = this.routerPath.includes('edit');

  hasRequestAttached: boolean = false;
  request: UserRequest[];

  currentUser = this.authenticationService.currentUserValue;

  isLoading: boolean = false;
  clients: User[];
  employees: Employee[];
  products: any[];
  currentClaimSteps: Claim
  productType: string;

  claimFormGroup: FormGroup;
  formSubmitted = false;
  get claimForm(){ return this.claimFormGroup.controls; }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  claimSteps: any;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private toastr: ToastrService,
    private brokerClaimService: BrokerClaimsService,
    private homeService: BrokerClientHomeService,
    private homeContentService: BrokerClientHomeContentService,
    private allRiskService: BrokerClientAllRiskServiceService,
    private requestService: RequestService,
    private brokerComponent: BrokerComponent,
    private rejectInstructionModal: RejectInstructionModalComponent,
  ) {
    this.activeRoute.queryParamMap.subscribe(param => {
      Object.keys(param['params']).forEach((key, index) => {
        if(key === 'request'){
          this.hasRequestAttached = true;

          this.requestService.getSingleRequest(param['params']['request']).subscribe(response => {
            this.request = response.body.requests
          })
        }
      })
    })
  }

  ngOnInit() {

    this.getClaimSteps();

    this.claimFormGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        display: '',
        _id: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        clientNumber: [''],
        username: ['', Validators.required]
      }),
      // client: ['', Validators.required],
      productType: ['', Validators.required],
      product: this.formBuilder.group({
        _id: ['', Validators.required],
        description: ['', Validators.required],
        path: ['', Validators.required]
      }),
      claimType: ['nonMotor'],
      claimDescription: ['', Validators.required],
      claimCompleted: this.formBuilder.group({
        value: false,
        dateCompleted: null,
        completedBy: this.formBuilder.group({
          brokerId: null,
          firstName: null,
          lastName: null
        })
      }),
      authorization: this.formBuilder.group({
        company: [''],
        telephone: ['']
      }),
      claimSummary: ['', Validators.required],
      dateOfLoss: ['', Validators.required],
      dateReported: ['', Validators.required],
      claimNo: [''],
      underwritersClaimNo: [''],
      excess: [],
      totalCost: [],
      recovery: ['', Validators.required],
      claimSteps:  this.formBuilder.array([]),
      employee: this.formBuilder.group({
        display: '',
        _id: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required]
      }),
      assignedEmployee: ['', Validators.required],
      userRef: ['', Validators.required],
      companyRef: ['', Validators.required],
      additionalComments: ['']
    });

    this.claimFormGroup.get('companyRef').setValue(this.authenticationService.currentCompany.companyName);

    // GET CLIENTS
    this.claimFormGroup
    .get('client')['controls'].display
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllClients(this.authenticationService.currentCompany.companyName, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

    // GET EMPLOYEES
    this.claimFormGroup
    .get('employee')['controls'].display
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

  }

  ngAfterViewInit(){
    if(this.editClaim){
      this.getCurrentClaim(this.selectedClaim);
    }
  }

  getProducts() {

    if(this.claimFormGroup.get('productType').value === 'vehicle'){
      this.brokerService.getUserVehicle(this.claimFormGroup.get('client').value._id, true).subscribe(
        (response: HttpResponse<any>) => {
          this.products = response.body.vehicle;
          this.claimFormGroup.get('claimType').setValue('motor');
        }
      )
    }

    if(this.claimFormGroup.get('productType').value === 'building'){
      this.homeService.getAllHomeInsuranceForClient(this.claimFormGroup.get('client').value._id, this.authenticationService.currentCompany.companyName).subscribe(
        (response: HttpResponse<any>) => {
          this.products = response.body.home
          this.claimFormGroup.get('claimType').setValue('nonMotor');
        }
      )
    }

    if(this.claimFormGroup.get('productType').value === 'homeContent'){
      this.homeContentService.getAllHomeContentInsuranceForClient(this.claimFormGroup.get('client').value._id, this.authenticationService.currentCompany.companyName).subscribe(
        (response: HttpResponse<any>) => {
          this.products = response.body.homeContent
          this.claimFormGroup.get('claimType').setValue('nonMotor');
        }
      )
    }

    if(this.claimFormGroup.get('productType').value === 'allRisk'){
      this.allRiskService.getAllAllRiskInsuranceForClient(this.claimFormGroup.get('client').value._id, this.authenticationService.currentCompany.companyName).subscribe(
        (response: HttpResponse<any>) => {
          this.products = response.body.item
          this.claimFormGroup.get('claimType').setValue('nonMotor');
        }
      )
    }
  }

  addNewClaimStep(step, index){

    let claimStepArray = this.claimFormGroup.get('claimSteps') as FormArray;

    let stepData = new FormGroup({
      stepId: new FormControl(step._id),
      stepTitle: new FormControl(step.stepTitle),
      stepDescription:  new FormControl(step.stepDescription),
      dateCompleted:  new FormControl(new Date()),
      stepComment:  new FormControl(null),
      stepStatus: new FormControl(null),
      display: new FormControl(step.display),
      completedBy: new FormGroup({
        brokerId:  new FormControl(this.currentUser._id),
        firstName:  new FormControl(this.currentUser.firstName),
        lastName:  new FormControl(this.currentUser.lastName),
      })
    })

    claimStepArray.insert(index, stepData);

  }

  removeClaimStep(index){
    let claimStepArray = this.claimFormGroup.get('instructionSteps') as FormArray;
    claimStepArray.removeAt(index)
  }

  goBack(){
    this.router.navigate(['b/claims'])
  }

  displayFn(client?: User): string | undefined {
    return client ? `${client.firstName} ${client.lastName}` : undefined;
  }
  mapClient(client){
    this.claimFormGroup.get('userRef').setValue(client._id);
    this.claimFormGroup.get('client')['controls']._id.setValue(client._id);
    this.claimFormGroup.get('client')['controls'].firstName.setValue(client.firstName);
    this.claimFormGroup.get('client')['controls'].lastName.setValue(client.lastName);
    this.claimFormGroup.get('client')['controls'].clientNumber.setValue(client.broker.find(broker => broker.companyName === this.authenticationService.currentCompany.companyName).clientNumber);
    this.claimFormGroup.get('client')['controls'].username.setValue(client.username);
  }

  displayFnProd(product?: any): string | undefined {
    return product ? `${product}` : undefined;
  }
  mapVehicle(vehicle){
    this.claimFormGroup.get('product')['controls']._id.setValue(vehicle._id);
    this.claimFormGroup.get('product')['controls'].description.setValue(`${vehicle.make} ${vehicle.model} - ${vehicle.registrationNumber}`);
    this.claimFormGroup.get('product')['controls'].path.setValue(vehicle.registrationNumber);
  }

  mapBuilding(building){
    this.claimFormGroup.get('product')['controls']._id.setValue(building._id);
    this.claimFormGroup.get('product')['controls'].description.setValue(building.address.formattedAddress);
    this.claimFormGroup.get('product')['controls'].path.setValue(building._id);
  }

  mapAllRisk(item) {
    this.claimFormGroup.get('product')['controls']._id.setValue(item._id);
    this.claimFormGroup.get('product')['controls'].description.setValue(item.itemName);
    this.claimFormGroup.get('product')['controls'].path.setValue(item._id);
  }


  displayFnEmployee(employee?: Employee): string | undefined {
    return employee ? `${employee.firstName} ${employee.lastName}` : undefined;
  }
  mapEmployee(employee){
    this.claimFormGroup.get('assignedEmployee').setValue(employee._id);
    this.claimFormGroup.get('employee').get('_id').setValue(employee._id);
    this.claimFormGroup.get('employee').get('firstName').setValue(employee.firstName);
    this.claimFormGroup.get('employee').get('lastName').setValue(employee.lastName);
  }

  getCurrentClaim(claimId:string){
    this.brokerClaimService.getSelectedClaim(this.authenticationService.currentCompany.companyName, claimId).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {

        // SET THE NEW CLAIM STEPS DATA
        let claim = response.body.claim['claimSteps'].map(step => {
          if(!step.stepStatus){
            step['completedBy'] = {
              brokerId:  this.currentUser._id,
              firstName:  this.currentUser.firstName,
              lastName:  this.currentUser.lastName,
            }
            step['dateCompleted'] = new Date()
          }
          return { ...step}
        })

        let claimFormData = {
          ... response.body.claim,
          instructionSteps: claim
        }

        this.currentClaimSteps = response.body.claim['claimSteps'];
        this.claimFormGroup.patchValue(response.body.claim);
        this.getProducts();
        this.claimFormGroup.get('client')['controls'].display.patchValue(response.body.claim['client'])
        this.claimFormGroup.get('employee')['controls'].display.patchValue(response.body.claim['employee']);
        this.mapEmployee(claimFormData['employee']);
      }
    )
  }

  addNewClaim(saveType?:string){

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.claimFormGroup.invalid) {
      console.warn('form => ', this.claimFormGroup)
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new claim.')
      return;
    }

    this.brokerClaimService.addNewClaim(this.authenticationService.currentCompany.companyName, this.claimFormGroup.value).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  updateClaim(saveType?:string){

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.claimFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new claim.')
      return;
    }

    this.brokerClaimService.editClaim(this.authenticationService.currentCompany.companyName, this.selectedClaim, this.claimFormGroup.value).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.saveType(saveType)
      }
    )

  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.claimFormGroup.reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  acceptRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.hasRequestAttached = false;
        this.claimFormGroup.get('employee')['controls'].display.patchValue(response.body.instruction['employee']);
        this.mapEmployee(response.body.instruction['employee']);
        this.brokerComponent.getUserRequests();
      }
    )
  }

  // rejectRequest(requestId:string){
  //   this.rejectInstructionModal.openDialog({ requestId });
  //   this.rejectInstructionModal.dialogRef.afterClosed().subscribe(response => {
  //     this.hasRequestAttached = false;
  //     if(response === 'success'){
  //       this.brokerComponent.getUserRequests()
  //     }
  //   })
  // }

  getClaimSteps(){
    this.brokerClaimService.getClaimSteps().subscribe(response => {
      this.claimSteps = response.body.claimSteps;

      for(let i = 0; i < this.claimSteps.length; i++){
        this.addNewClaimStep(this.claimSteps[i], i)
      }
    })
  }

}
