import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { NotificationResponse } from 'src/app/_shared/models/notifications.model';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getUserNotifications(): Observable<HttpResponse<NotificationResponse>>{
    return this.http.get<NotificationResponse>(`${this.globals.SERVER_URL}/api/client/notifications`, {
      observe: 'response'
    });
  }

  markNotificationAsRead(_id:string): Observable<HttpResponse<NotificationResponse>>{

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.put<NotificationResponse>(`${this.globals.SERVER_URL}/api/client/notifications/${_id}`, {}, {
      observe: 'response',
      headers: headers
    });
  }

}
