import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { BrokerQuoteService } from 'src/app/services/broker-quote.service';

@Component({
  selector: 'app-reject-quote-modal',
  templateUrl: './reject-quote-modal.component.html',
  styleUrls: ['./reject-quote-modal.component.scss']
})
export class RejectQuoteModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RejectQuoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private quoteService: BrokerQuoteService,
    private toastr: ToastrService
  ) { }

  rejectedReason: string;

  ngOnInit(): void {
  }

  closeDialog(result: string){
    this.dialogRef.close(result)
  }

  rejectQuote(){

    if(!this.rejectedReason){
      this.toastr.error('Please provide a reason for the rejection of this quote.', 'Could not reject quote.');
      return;
    }

    this.quoteService.rejectQuote(this.data.quoteId, this.rejectedReason).subscribe(response => {
      this.closeDialog('success')
    })
  }

}
