<h1 mat-dialog-title>
    Create new event
    <button class="close" mat-dialog-close></button>
</h1>
<div mat-dialog-content>

    <form id="calendarEventFormGroup" [formGroup]="calendarEventFormGroup">

        <div class="row form-group">

            <div class="col-12 col-sm-5 col-md-4 my-auto">
                <label for="type">Event type:</label>
            </div>
            <div class="col-12 col-sm-7 col-md-8 my-auto input-group">
                <mat-form-field>
                    <mat-select
                        name="type"
                        formControlName="type"
                        [ngClass]="{ 'is-invalid': formSubmitted && calendarEventFormGroup.get('type').errors }"
                    >
                    <mat-optgroup label="Meeting">
                        <mat-option value="meeting_insurer">Meeting with an insurer</mat-option>
                        <mat-option value="meeting_newClient">Meeting with a new client</mat-option>
                        <mat-option value="meeting_followUp">Follow up meeting</mat-option>
                    </mat-optgroup>
                    
                    <mat-optgroup label="Other">
                        <mat-option value="leave">Leave</mat-option>
                        <mat-option value="personal">Personal</mat-option>
                    </mat-optgroup>

                    </mat-select>

                    <mat-error>
                        Please select the <strong>event type</strong>.
                    </mat-error>
                </mat-form-field>
               
            </div>

            <div class="col-12 clearfix mt-3"></div>    

            <ng-container *ngIf="calendarEventFormGroup.get('type').value !== 'leave'">

                <div class="col-12 col-sm-5 col-md-4 my-auto">
                    <label for="client">Client:</label>
                </div>
                <div class="col-12 col-sm-7 col-md-8 my-auto">
                    <div class="input-group">
                        <mat-form-field>
                            <input
                              matInput 
                              type="text"
                              aria-label="Client"
                              formControlName='clientAutoCompleteDisplay'
                              [matAutocomplete]="client"
                            />
                            <mat-hint [hidden]="calendarEventFormGroup.get('clientAutoCompleteDisplay').dirty">Type a name to start the search</mat-hint>
    
                            <mat-autocomplete autoActiveFirstOption #client="matAutocomplete" [displayWith]="displayFn.bind(this)">
                                <mat-option *ngIf="isLoading" class="is-loading">Finding clients...</mat-option>
    
                                <ng-container *ngIf="!isLoading">
                                    <mat-option disabled *ngIf="clients?.length === 0 && calendarEventFormGroup.get('client').value">
                                        <span>No client found</span>
                                    </mat-option>
                                    <mat-option [value]="0">
                                        <span>No client</span>
                                    </mat-option>
                                    <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="mapClient(client)">
                                        <span>{{ client?.companyName }}{{ client?.firstName }} {{ client?.lastName }}</span>
                                    </mat-option>
    
                                </ng-container>
                            </mat-autocomplete>
    
                            <mat-error>
                                Please select a <strong>client</strong> for this event.
                            </mat-error>
    
                        </mat-form-field>
                    </div> <!-- Close div for .input-group -->
                </div>
    

            </ng-container>

            <ng-container *ngIf="currentUserPermissions.includes('brokerAdmin') || currentUserPermissions.includes('brokerDirector')">

                <div class="col-12 clearfix mt-3"></div><div class="col-12 col-sm-5 col-md-4 my-auto">
                    <label for="employee">Employee:</label>
                </div>
                <div class="col-12 col-sm-7 col-md-8 my-auto">
                    <div class="input-group">
                        <mat-form-field>
                            <input
                            matInput 
                            type="text"
                            aria-label="Employee"
                            formControlName='employeeAutoCompleteDisplay'
                            [matAutocomplete]="employee"
                            />
                            <mat-hint [hidden]="calendarEventFormGroup.get('employeeAutoCompleteDisplay').dirty">Type a name to start the search</mat-hint>

                            <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayEmployee.bind(this)">
                                <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>

                                <ng-container *ngIf="!isLoading">
                                    <mat-option disabled *ngIf="employees?.length === 0 && calendarEventFormGroup.get('employee').value">
                                        <span>No employee found</span>
                                    </mat-option>
                                    <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="mapEmployee(employee)">
                                        <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                                    </mat-option>

                                </ng-container>
                            </mat-autocomplete>

                            <mat-error>
                                Please select a <strong>employee</strong> for this event.
                            </mat-error>

                        </mat-form-field>
                    </div> <!-- Close div for .input-group -->
                </div>

                <div class="col-12 clearfix mt-3"></div>

            </ng-container>

            <div class="col-12 col-sm-5 col-md-4 my-auto">
                <label for="title">Title:</label>
            </div>
            <div class="col-12 col-sm-7 col-md-8 my-auto">
                <mat-form-field>
                    <input matInput class="form-control" formControlName="title" type="text">
                    <mat-error>
                        Please enter a <strong>title</strong> for the event.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 col-sm-5 col-md-4 my-auto">
                <label for="description">Description:</label>
            </div>
            <div class="col-12 col-sm-7 col-md-8 my-auto">
                <textarea class="form-control" formControlName="description"></textarea>
            </div>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 col-sm-5 col-md-4 my-auto">
                <label for="start">Start time:</label>
            </div>
            <div class="col-12 col-sm-7 col-md-8 my-auto">
                <mat-form-field>
                    <input class="form-control" matInput [ngxMatDatetimePicker]="start" formControlName="start">
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #start>
                    </ngx-mat-datetime-picker>

                    <mat-error>
                        Please provide the <strong>start date and time</strong> for the event.
                    </mat-error>

                </mat-form-field>
            </div>

            <ng-container *ngIf="!isAllDayEvent">
                <div class="col-12 col-sm-5 col-md-4 my-auto">
                    <label for="end">End time:</label>
                </div>
                <div class="col-12 col-sm-7 col-md-8 my-auto input-group">
                    <mat-form-field>
                        <input class="form-control" matInput [ngxMatDatetimePicker]="end" formControlName="end">
                        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #end>
                        </ngx-mat-datetime-picker>

                        <mat-error>
                            Please provide the <strong>end date and time</strong> for the event.
                        </mat-error>

                    </mat-form-field>
                </div>
            </ng-container>

            <div class="col-12 clearfix mt-3"></div>

            <div class="col-12 col-sm-5 col-md-4 my-auto">
                <label for="allDay">All day event:</label>
            </div>
            <div class="col-12 col-sm-7 col-md-8 my-auto">
                <mat-checkbox disableRipple formControlName="allDay" (change)="markEnd($event)"></mat-checkbox>
            </div>


        </div>

    </form>

</div>

<mat-dialog-actions align="end">
    <button class="btn btn-outline-secondary" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" (click)="addNewCalendarEvent()">Save</button>
</mat-dialog-actions>