<div id="content" class="row">

    <sub-navigation heading="Reports"></sub-navigation>
    
    <div class="col-12">
      
      <div class="panel panel-primary">
  
        <div class="panel-heading">
            <h5>Report types</h5>
        </div> <!-- Close div for .panel-heading -->
  
        <div class="panel-body">
  
          <div  class="col-12">
  
            <div class="row">
  
              <div id="report" class="col-md-2 col-sm-3 col-6 mb-3" *ngFor="let category of categories" [routerLink]="[category.path]">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="text-center mb-3">
                      <span class="userpic userpic-md userpic-dark">
                        <img *ngIf="category?.src" [src]="category.src" />
                      </span>

                      <h5 class="card-title text-center color-label-4 mt-3">{{ category?.name }}</h5>
                    </div>
                  </div>
                </div> <!-- Close div for .card -->
              </div>
  
            </div> <!-- Close div for .row -->
  
          </div> <!-- Close div for #employees -->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for #content -->