import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { VehicleInsuranceQuoteComponent } from './quote/vehicle-insurance-quote.component';

@Component({
  selector: 'app-client-vehicle-insurance',
  templateUrl: './client-vehicle-insurance.component.html',
  styleUrls: ['./client-vehicle-insurance.component.scss'],
  providers: [DeleteVehicleComponent, VehicleInsuranceQuoteComponent]
})
export class ClientVehicleInsuranceComponent implements OnInit, AfterViewInit {

  vehicles: Vehicle[];

  displayedColumns: string[] = ['image', 'vehicle', 'registrationNumber', 'insuredWith', 'action'];
  resultsLength = 0;
  pageSize = 25;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private personalInsurance: PersonalInsuranceService,
    private deleteVehicleModal: DeleteVehicleComponent,
    private vehicleInsuranceQuote: VehicleInsuranceQuoteComponent
  ) {}

  // getAllVehicles(){
  //   this.personalInsurance.getAllVehicles().subscribe(
  //     (response: HttpResponse<VehicleResponse>) => {
  //       this.vehicles = response.body.vehicle;
  //     }
  //   )
  // };

  getAllVehicles(noLoad?:boolean, eventData?:number){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: eventData ? (eventData * this.pageSize) - 1: this.paginator.pageIndex,
      limit: this.pageSize,
      registrationNumber: '',
      vehicleMake: '',
      vehicleModel: ''
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.personalInsurance.getAllAssignedVehicles(req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.vehicle;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.vehicles = data);
  }

  deleteVehicle(vehicle){
    this.deleteVehicleModal.openDialog(vehicle);
    this.deleteVehicleModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.getAllVehicles();
      }
    })
  };

  getVehicleQuote(){
    this.vehicleInsuranceQuote.openDialog(this.vehicles);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllVehicles()
  }

}
