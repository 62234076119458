<div id="content" class="row">

  <sub-navigation heading="Investments"></sub-navigation>

  <div *ngFor="let investment of investments" class="col-12 mb-5">

    <div class="panel panel-primary">
      <div class="panel-heading">
        <small class="color-dark-grey">Investment type</small>
        <h5>{{ investment?.type }}</h5>
      </div>
      <div class="panel-body">

        <div class="row">

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Invested with</small><br />
             {{ investment?.investmentCompany }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Invesment date</small><br />
              {{ investment?.investmentDate | date: 'dd MMMM yyyy' }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Amount invested</small><br />
              {{ investment?.investedAmount | currency: 'R' }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Current value</small><br />
              {{ investment.currentValue || investment.investedAmount | currency: 'R' }}
            </p>
          </div>

        </div> <!-- Close div for .row -->

      </div>
    </div> <!-- Close div for .panel-->

  </div> <!-- Close div for .col-12 -->
