<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">My information</h5>
      <h6 class="d-inline-block mb-0">{{ tabHeading(selectedTab) }}</h6>
    </div>

    <div class="col-6 text-right align-self-center">
      <div class="btn-group" ngbDropdown>
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateUserInformation()"
        >
          Save changes
        </button>
        <!-- <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item" (click)="updateUserInformation('continue')">Save &amp; continue</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="updateUserInformation('back')">Save &amp; go back</a></li>
        </ul> -->
      </div>
      <!-- Close div for .btn-group -->
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="panel panel-tabs">
      <div class="panel-heading">
        <mat-tab-group
          [selectedIndex]="selectedTab"
          (selectedIndexChange)="selectedTab = $event"
        >
          <mat-tab label="Basic information"> </mat-tab>

          <mat-tab label="Additional information"> </mat-tab>

          <mat-tab label="Contact information"> </mat-tab>

          <mat-tab label="Insurance information"> </mat-tab>
        </mat-tab-group>
      </div>
      <!-- Close div for .panel-heading -->

      <div class="panel-body pt-3 pb-5">
        <form id="basicInformationForm" [formGroup]="basicInformationForm">
          <div class="row" *ngIf="selectedTab === 0">
            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>ID number:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="idNumber"
                type="text"
                class="form-control"
                formControlName="idNumber"
              />
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Passport number:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="passportNumber"
                type="text"
                class="form-control"
                formControlName="passportNumber"
              />
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Tax number:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="taxNumber"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['taxNumber'].errors
                }"
                formControlName="taxNumber"
              />

              <div
                *ngIf="submittedForm && basicForm['taxNumber'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['taxNumber'].errors.required">
                  Please enter your tax number.
                </div>
              </div>
            </div>

            <div class="clearfix col-12"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>First name:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="firstName"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['firstName'].errors
                }"
                formControlName="firstName"
              />

              <div
                *ngIf="submittedForm && basicForm['firstName'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['firstName'].errors.required">
                  Please enter your name.
                </div>
              </div>
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Last name:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="lastName"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['lastName'].errors
                }"
                formControlName="lastName"
              />

              <div
                *ngIf="submittedForm && basicForm['lastName'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['lastName'].errors.required">
                  Please enter your surname.
                </div>
              </div>
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-md-3 col-sm-6 col-6 text-right align-self-center">
              <label>Gender:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="gender"
                type="text"
                class="form-control"
                formControlName="gender"
              />
            </div>

            <div class="col-12 clearfix"></div>

            <!-- <div class="col-md-4 col-sm-6 col-6">
                  <strong>Age:</strong>
                </div>

                <div class="col-lg-9 col-xl-6">
                  <span>{{ calculateAge(this.currentUser?.idNumber)?.age | json }}</span>
                </div>

                <div class="col-12 clearfix">
                </div> -->

            <!-- <div class="col-md-4 col-sm-6 col-6">
                  <strong>Birthday:</strong>
                </div>

                <div class="col-lg-9 col-xl-6">
                  <span>{{ calculateBirthday(this.currentUser?.idNumber) | date: 'dd MMMM' }}</span>
                </div>

                <div class="col-12 clearfix">
                </div> -->

            <!-- <div class="col-md-4 col-sm-6 col-6">
                  <strong>Birth year:</strong>
                </div>

                <div class="col-lg-9 col-xl-6">
                  <span>{{ calculateAge(this.currentUser?.idNumber)?.year }}</span>
                </div>

                <div class="col-12 clearfix">
                </div> -->

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Insurance types:</label>
            </div>

            <div class="col-md-8 col-sm-6 col-6" formGroupName="insuranceType">
              <div class="checkbox">
                <mat-checkbox color="brand" formControlName="householdInsurance"
                  >Household insurance</mat-checkbox
                >
              </div>

              <div class="checkbox">
                <mat-checkbox color="brand" formControlName="vehicleInsurance"
                  >Vehicle insurance</mat-checkbox
                >
              </div>

              <div class="checkbox">
                <mat-checkbox color="brand" formControlName="buildingInsurance"
                  >Building insurance</mat-checkbox
                >
              </div>

              <div class="checkbox">
                <mat-checkbox
                  color="brand"
                  formControlName="personalItemInsurance"
                  >Personal item insurance</mat-checkbox
                >
              </div>
            </div>
          </div>
          <!-- Close div for .row -->
        </form>

        <form id="additionalInfoForm" [formGroup]="basicInformationForm">
          <div class="row" *ngIf="selectedTab === 1">
            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Middle name(s):</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                type="text"
                class="form-control"
                name="middlename"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['middleName'].errors
                }"
                formControlName="middleName"
              />
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Drivers license:</label>
            </div>

            <div class="col-lg-9 col-xl-6" formGroupName="driversLicence">
              <div class="row">
                <div class="col-12 input-group">
                  <mat-form-field
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        submittedForm &&
                        basicForm['driversLicence'].value.licenceCode.errors
                    }"
                  >
                    <mat-select
                      name="licenceCode"
                      formControlName="licenceCode"
                    >
                      <mat-option value="None">
                        None
                      </mat-option>

                      <mat-optgroup label="Motorcycles">
                        <mat-option value="Code A1">Code A1</mat-option>
                        <mat-option value="Code A">Code A</mat-option>
                      </mat-optgroup>

                      <mat-optgroup label="Light motor vehicles">
                        <mat-option value="Code B">Code B</mat-option>
                        <mat-option value="Code EB">Code EB</mat-option>
                      </mat-optgroup>

                      <mat-optgroup label="Heavy motor vehicles">
                        <mat-option value="Code C1">Code C1</mat-option>
                        <mat-option value="Code C">Code C</mat-option>
                        <mat-option value="Code EC1">Code EC1</mat-option>
                        <mat-option value="Code EC">Code EC</mat-option>
                      </mat-optgroup>

                      <div
                        *ngIf="
                          submittedForm &&
                          basicForm['driversLicence'].value.licenceCode.errors
                        "
                        class="invalid-feedback d-block"
                      >
                        <div
                          *ngIf="
                            basicForm['driversLicence'].value.licenceCode.errors
                              .required
                          "
                        >
                          Please select your drivers licence code.
                        </div>
                      </div>
                    </mat-select>
                  </mat-form-field>

                  <section
                    *ngIf="
                      basicForm['driversLicence'].value.licenceCode !==
                        'None' && basicForm['driversLicence'].value.licenceCode
                    "
                    class="w-100"
                  >
                    <label class="mb-0 mt-2"><small>First issue</small></label>
                    <mat-form-field class="form-control">
                      <input
                        matInput
                        [matDatepicker]="driversLicenseFirstIssue"
                        name="driversLicenceFirstIssue"
                        formControlName="licenceFirstIssue"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="driversLicenseFirstIssue"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        touchUi
                        #driversLicenseFirstIssue
                      ></mat-datepicker>
                    </mat-form-field>

                    <label class="mb-0 mt-2"><small>Valid until</small></label>
                    <mat-form-field class="form-control">
                      <input
                        matInput
                        [matDatepicker]="driversLicenseValidUntil"
                        name="driversLicenceExpiryDate"
                        formControlName="licenceExpiryDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="driversLicenseValidUntil"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        touchUi
                        #driversLicenseValidUntil
                      ></mat-datepicker>
                    </mat-form-field>
                  </section>
                </div>
              </div>
            </div>

            <div class="clearfix col-12"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Employer:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                type="text"
                name="employer"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['employer'].errors
                }"
                formControlName="employer"
              />

              <div
                *ngIf="submittedForm && basicForm['employer'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['employer'].errors.required">
                  Please enter the name of your employer.
                </div>
              </div>
            </div>

            <div class="clearfix col-12"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Job description:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                type="text"
                class="form-control"
                name="jobDescription"
                [ngClass]="{
                  'is-invalid':
                    submittedForm && basicForm['jobDescription'].errors
                }"
                formControlName="jobDescription"
              />
            </div>
          </div>
          <!-- Close div for .row -->
        </form>

        <form id="contactInfoForm" [formGroup]="basicInformationForm">
          <div class="row" *ngIf="selectedTab === 2">
            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Email:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="email"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['email'].errors
                }"
                formControlName="email"
              />

              <div
                *ngIf="submittedForm && basicForm['email'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['email'].errors.required">
                  Please enter your email address.
                </div>
                <div
                  *ngIf="
                    basicForm['email'].errors.email ||
                    basicForm['email'].errors.pattern
                  "
                >
                  Please enter a valid email address.
                </div>
              </div>
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Cellphone:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="cellphone"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['cellphone'].errors
                }"
                formControlName="cellphone"
              />

              <div
                *ngIf="submittedForm && basicForm['cellphone'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="basicForm['cellphone'].errors.required">
                  Please enter your cellphone number.
                </div>
              </div>
            </div>

            <div class="clearfix col-12"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Home phone:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="homePhone"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['homePhone'].errors
                }"
                formControlName="homePhone"
              />
            </div>

            <div class="clearfix col-12"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Work phone:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="workPhone"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submittedForm && basicForm['workPhone'].errors
                }"
                formControlName="workPhone"
              />
            </div>

            <div class="col-12 clearfix"></div>

            <div class="col-xl-3 col-lg-3 text-right my-auto">
              <label>Alternative number:</label>
            </div>

            <div class="col-lg-9 col-xl-6">
              <input
                name="alternateNumber"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    submittedForm && basicForm['alternateNumber'].errors
                }"
                formControlName="alternateNumber"
              />
            </div>
          </div>
          <!-- Close div for .row -->
        </form>

        <div class="row" *ngIf="selectedTab === 3">
          Insurance Information
        </div>
      </div>
    </div>
    <!-- Close div for .panel -->
  </div>
  <!-- Close div for .col-12 -->
</div>
<!-- Close div for .row -->
