import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-reporting',
  templateUrl: './broker-reporting.component.html',
  styleUrls: ['./broker-reporting.component.scss']
})
export class BrokerReportingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  categories: any[] = [
    { name: 'Instructions', path: 'instructions' },
    { name: 'Claims', path: 'claims' },
    { name: 'Policies', path: 'policies' },
    { name: 'Quotes', path: 'quotes' },
    { name: 'Returned debits', path: 'returnedDebits' },
    { name: 'Employees', path: 'employees' },
    { name: 'Clients', path: 'clients' },
    { name: 'Calendar events', path: 'calendar-events' },
    { name: 'Company notes', path: 'activity-log'}
  ]

}
