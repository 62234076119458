import { Policy, PolicyResponse } from './../../../_shared/models/policy.model';
import { UserResponse, User } from 'src/app/_shared/models/User.model';
import { BrokerService } from 'src/app/services/broker.service';
import { Component, OnInit, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { UploadInput, UploadFile, UploaderOptions } from 'ngx-uploader';
import { ActivatedRoute, Router } from "@angular/router"
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { Lightbox } from 'ngx-lightbox';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { InsuranceCompany } from 'src/app/_shared/models/insuranceCompany.model';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';
import { BrokerClientService } from 'src/app/services/broker-client.service';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';
import { TrailersAndCaravansResponse, TrailersAndCaravans } from 'src/app/_shared/models/trailersAndCaravans.model';

@Component({
  selector: 'app-broker-new-edit-client-caranvans-and-trailers',
  templateUrl: './broker-new-edit-client-caranvans-and-trailers.component.html',
  styleUrls: ['./broker-new-edit-client-caranvans-and-trailers.component.scss'],
  providers: [DeleteVehicleComponent]
})
export class BrokerNewEditClientCaranvansAndTrailersComponent implements OnInit, AfterViewInit {

  @Input() view:boolean;

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  routerPath: string = this.activeRoute.routeConfig.path;
  editProduct = this.routerPath.includes('edit');

  maxDate = new Date();

  clientId = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  clientInformation: any;

  productIdNumber = this.activeRoute.snapshot.paramMap.get('productId');
  product: TrailersAndCaravans[];

  brokers: Employee[];
  insuranceCompanies: InsuranceCompany[];
  policies: Policy[];

  formSubmitted = false;
  productFromGroup: FormGroup;
  get productForm() { return this.productFromGroup.controls; }

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  maxUploads: number = 6

  familyMembers: FamilyMember[];
  isLoading: boolean = false;

  isQuoteProduct: boolean;

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private deleteVehicleModal: DeleteVehicleComponent,
    private lightbox: Lightbox,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private brokerClientService: BrokerClientService,
    private brokerService: BrokerService,
    private trailersAndCaravansService: TrailersAndCaravansService,
    private location: Location
  ) {
    this.isQuoteProduct = this.activeRoute.snapshot.queryParams['quote'] === 'true';
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getCurrentProduct(productId:string){

    this.trailersAndCaravansService.getSingleTrailerAndCaravansInsuranceForClient(this.clientId, productId).subscribe(
      (response:HttpResponse<TrailersAndCaravansResponse>) => {
        this.product = response.body.trailerAndCaravan;

        this.productFromGroup.patchValue(this.product);

        this.productFromGroup.controls.insurance.patchValue(this.product['insurance']);
      }
    )
  };

  getClientInformation(client_id:string){
    this.brokerService.getSingleClient(client_id).subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clientInformation = response.body.user;
      }
    )
  }

  updateProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save ${ this.productFromGroup.get('type').value }.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(this.isQuoteProduct){
      this.editQuotedProduct();
      return
    }

    this.trailersAndCaravansService.updateClientTrailerAndCaravansInsurance(this.clientId, this.productIdNumber, this.productFromGroup.value).subscribe(
      (response:HttpResponse<TrailersAndCaravansResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save ${this.productFromGroup.get('type').value}.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
    return;
    }

    this.productFromGroup.get('isInsured').setValue(true);

    this.trailersAndCaravansService.addNewClientTrailersAndCaravansInsurance(this.clientId, this.productFromGroup.value).subscribe(
      (response:HttpResponse<TrailersAndCaravansResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewQuotedProduct(){

    this.productFromGroup.get('isInsured').setValue(false);

    this.trailersAndCaravansService.addNewClientTrailersAndCaravansQuote(this.clientId, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<TrailersAndCaravansResponse>) => {
        let newTrailerAndCaravan = response.body.trailerAndCaravan;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newTrailerAndCaravan))
        this.location.back()
      }
    )
  }

  editQuotedProduct(){
    this.trailersAndCaravansService.editClientTrailersAndCaravansQuote(this.clientId, this.productIdNumber, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<TrailersAndCaravansResponse>) => {
        let newTrailerAndCaravan = response.body.trailerAndCaravan;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newTrailerAndCaravan))
        this.location.back()
      }
    )
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
      this.goBack();
        break;

      case 'continue':
        break;

      default:
        this.goBack();
        break;
    }
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  getClientFamilyMembers(client_Id:string){
    this.brokerService.getClientFamilyMembers(client_Id)
    .subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        this.familyMembers = response.body.family;
      }
    )
  };

  open(index: number): void {
    this.lightbox.open(this.productFromGroup.value.s3Files, index);
  }

  close(): void {
    this.lightbox.close();
  }

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.router.navigate(['/b/clients/personal/edit', this.clientId]);
      this.location.back()
    };
  }

  addNewFamilyMember(){
    localStorage.setItem('SureSpace-TrailersAndCaravans', JSON.stringify(this.productFromGroup.value))
    this.router.navigate([`/b/clients/personal/edit/${this.clientId}/family-members/new`, { r: this.router.url }])
  }

  addNewPolicy(){
    localStorage.setItem('SureSpace-TrailersAndCaravans', JSON.stringify(this.productFromGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-TrailersAndCaravans')){
      this.productFromGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-TrailersAndCaravans')));
      localStorage.removeItem('SureSpace-TrailersAndCaravans');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  ngOnInit() {
    this.productFromGroup = this.formBuilder.group({
      // _id: [''],
      registrationNumber: ['', Validators.required],
      type: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      year: [null, Validators.required],
      isInsured: [true],
      insurance: this.formBuilder.group({
        companyName: [''],
        insuredValue: [''],
        insuredValueRetail: [false],
        premium: [''],
        policyNumber: [''],
        inceptionDate: [''],
        cfg: []
      }),
      registeredTo: this.formBuilder.group({
        _id: [''],
        idNumber: [''],
        firstName: [''],
        lastName: [''],
        clientNumber: ['']
      }),
      isQuoteProduct: false,
    });

    this.productFromGroup.get('insurance').get('companyName').setValue(this.authService.currentCompany.companyName);
    if(this.isQuoteProduct){
      this.productFromGroup.get('isQuoteProduct').setValue(true);
    }

  }

  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (!control.value && (this && this.isQuoteProduct)) {
      return { 'required': true };
    }
    return null;
  }

  setRegisteredOwner(value:FamilyMember): void {
    let registeredTo = this.productFromGroup.get('registeredTo');
    registeredTo.get('_id').setValue(value._id);
    registeredTo.get('idNumber').setValue(value.idNumber);
    registeredTo.get('firstName').setValue(value.name || value['firstName']);
    registeredTo.get('lastName').setValue(value.surname || value['lastName']);
    registeredTo.get('clientNumber').setValue(this.clientInformation.broker.find(broker => broker.companyName === this.authService.currentCompany.companyName).clientNumber);
    
  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, this.clientId, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  ngAfterViewInit(){

    this.getClientInformation(this.clientId);
    this.getClientFamilyMembers(this.clientId);

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicyNumber();
    }

    if(this.editProduct){
      this.getCurrentProduct(this.productIdNumber);
    }

    this.getLocalStorageItem();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}

