import { Policy } from 'src/app/_shared/models/policy.model';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BrokerService } from 'src/app/services/broker.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { PolicyResponse } from 'src/app/_shared/models/policy.model';

@Component({
  selector: 'broker-view-user-policies',
  templateUrl: './broker-view-user-policies.component.html',
  styleUrls: ['./broker-view-user-policies.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerViewUserPoliciesComponent implements OnInit, AfterViewInit {

  selectedUser = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  policies: Policy[];

  constructor(
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.getPolicies();
  }

  getPolicies(){
    this.brokerService.getPoliciesPerUser(this.brokerComponent.brokerCompany.companyName, this.selectedUser).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        console.log('all -> ', response.body)
        this.policies = response.body.policies;
      }
    )
  }

  deactivatePolicy(policyId){
    this.brokerService.deactivatePolicy(policyId).subscribe(
      (response:HttpResponse<PolicyResponse>) => {
        this.getPolicies();
        // this.policies = this.policies.filter(policy => policy._id != policyId)
      }
    )
  }

}
