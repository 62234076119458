<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Custom components</h5>
      <h6 class="d-inline-block mb-0">Timeline</h6>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-12 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Timeline v1</h5>
      </div>

      <div class="panel-body">

        <div class="col-10 offset-1">

          <div class="timeline-v1">

            <div class="timeline-items">

              <div class="timeline-item">

                <div class="timeline-item-circle">
                  <div class="circle circle-danger"></div>
                </div>

                <span class="timeline-time">10:00</span>
                <div class="timeline-item-content">

                  <div class="timeline-item-content-title">
                    Users joined today
                  </div>

                  <div class="timeline-item-content-body">
                    <div class="list-pics mb-2">

                      <div class="userpic userpic-sm userpic-success mr-3">
                        <span>JE</span>
                      </div>

                      <div class="userpic userpic-sm userpic-dark mr-3">
                        <span>KV</span>
                      </div>

                      <div class="userpic userpic-sm userpic-primary mr-3">
                        <span>CZ</span>
                      </div>

                      <div class="userpic userpic-sm userpic-danger mr-3">
                        <span>TH</span>
                      </div>

                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                      aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                      exercitationem nobis sequi delectus.</p>
                  </div>

                  <div class="timeline-item-content-actions">
                    <button class="btn btn-label-info btn-sm btn-bold">Show more ...</button>
                  </div>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

              <div class="timeline-item">

                <div class="timeline-item-circle">
                  <div class="circle circle-danger"></div>
                </div>

                <span class="timeline-time">12:00</span>
                <div class="timeline-item-content">

                  <div class="timeline-item-content-title">
                    Users joined today
                  </div>

                  <div class="timeline-item-content-body">
                    <div class="list-pics">

                      <div class="userpic userpic-sm userpic-success mr-3">
                        <span>JE</span>
                      </div>

                      <div class="userpic userpic-sm userpic-dark mr-3">
                        <span>KV</span>
                      </div>

                      <div class="userpic userpic-sm userpic-primary mr-3">
                        <span>CZ</span>
                      </div>

                      <div class="userpic userpic-sm userpic-danger mr-3">
                        <span>TH</span>
                      </div>

                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                      aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                      exercitationem nobis sequi delectus.</p>
                  </div>

                  <div class="timeline-item-content-actions">
                    <button class="btn btn-info btn-sm btn-bold">Show more ...</button>
                  </div>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

            </div> <!-- Close div for timeline-items -->

            <div class="text-center">
              <button class="btn btn-label-info btn-bold mr-0">Load more</button>
            </div>

          </div> <!-- Close div for .timeline-v1 -->

        </div> <!-- Close div for .col-10 -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->




  <div class="col-12 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Timeline v2</h5>
      </div>

      <div class="panel-body">

        <div class="col-8 offset-2">

          <div class="timeline-v2">

            <div class="timeline-items">

              <div class="timeline-item">

                <div class="timeline-item-circle">
                  <div class="circle circle-danger"></div>
                </div>

                <span class="timeline-time">10:00</span>
                <div class="timeline-item-content">

                  <div class="timeline-item-content-title">
                    Users joined today
                  </div>

                  <div class="timeline-item-content-body">
                    <div class="list-pics mb-2">

                      <div class="userpic userpic-sm userpic-success mr-3">
                        <span>JE</span>
                      </div>

                      <div class="userpic userpic-sm userpic-dark mr-3">
                        <span>KV</span>
                      </div>

                      <div class="userpic userpic-sm userpic-primary mr-3">
                        <span>CZ</span>
                      </div>

                      <div class="userpic userpic-sm userpic-danger mr-3">
                        <span>TH</span>
                      </div>

                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                      aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                      exercitationem nobis sequi delectus.</p>
                  </div>

                  <div class="timeline-item-content-actions">
                    <button class="btn btn-label-info btn-sm btn-bold">Show more ...</button>
                  </div>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

              <div class="timeline-item">

                <div class="timeline-item-circle">
                  <div class="circle circle-danger"></div>
                </div>

                <span class="timeline-time">12:00</span>
                <div class="timeline-item-content">

                  <div class="timeline-item-content-title">
                    Users joined today
                  </div>

                  <div class="timeline-item-content-body">
                    <div class="list-pics">

                      <div class="userpic userpic-sm userpic-success mr-3">
                        <span>JE</span>
                      </div>

                      <div class="userpic userpic-sm userpic-dark mr-3">
                        <span>KV</span>
                      </div>

                      <div class="userpic userpic-sm userpic-primary mr-3">
                        <span>CZ</span>
                      </div>

                      <div class="userpic userpic-sm userpic-danger mr-3">
                        <span>TH</span>
                      </div>

                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                      aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                      exercitationem nobis sequi delectus.</p>
                  </div>

                  <div class="timeline-item-content-actions">
                    <button class="btn btn-info btn-sm btn-bold">Show more ...</button>
                  </div>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

            </div> <!-- Close div for timeline-items -->

            <div class="text-center">
              <button class="btn btn-label-info btn-bold mr-0">Load more</button>
            </div>

          </div> <!-- Close div for .timeline-v1 -->

        </div> <!-- Close div for .col-10 -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->




  <div class="col-12 col-md-6 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Timeline v3</h5>
      </div>

      <div class="panel-body">

        <div class="timeline-v3">

            <div class="timeline-items">

              <div class="timeline-item">

                <span class="timeline-time">10:00</span>

                <div class="timeline-item-circle">
                  <div class="circle circle-danger"></div>
                </div>

                <div class="timeline-item-content">

                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                        aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                        exercitationem nobis sequi delectus.
                    </p>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

              <div class="timeline-item">

                <span class="timeline-time">12:00</span>

                <div class="timeline-item-circle">
                  <div class="circle circle-warning"></div>
                </div>

                <div class="timeline-item-content">

                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                        aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                        exercitationem nobis sequi delectus.
                    </p>

                </div> <!-- Close div for .timeline-content -->

              </div> <!-- Close div for .timeline-item -->

            </div> <!-- Close div for timeline-items -->

        </div> <!-- Close div for .timeline-v3 -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->

  <div class="col-12 col-md-6 float-left">

        <div class="panel panel-primary">
    
          <div class="panel-heading">
            <h5>Timeline v4</h5>
          </div>
    
          <div class="panel-body">
    
            <div class="timeline-v4">
    
                <div class="timeline-items">
    
                  <div class="timeline-item">
    
                    <span class="timeline-time">10:00</span>
    
                    <div class="timeline-item-circle">
                      <div class="circle circle-primary"></div>
                    </div>
    
                    <div class="timeline-item-content">
    
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                            aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                            exercitationem nobis sequi delectus.
                        </p>
    
                    </div> <!-- Close div for .timeline-content -->
    
                  </div> <!-- Close div for .timeline-item -->
    
                  <div class="timeline-item">
    
                    <span class="timeline-time">12:00</span>
    
                    <div class="timeline-item-circle">
                      <div class="circle circle-success"></div>
                    </div>
    
                    <div class="timeline-item-content">
    
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis vitae nobis alias ab
                            aspernatur sit quos eaque dolorum est, ex ipsam velit minus assumenda! Quia necessitatibus
                            exercitationem nobis sequi delectus.
                        </p>
    
                    </div> <!-- Close div for .timeline-content -->
    
                  </div> <!-- Close div for .timeline-item -->
    
                </div> <!-- Close div for timeline-items -->
    
            </div> <!-- Close div for .timeline-v4 -->
    
          </div> <!-- Close div for .panel-body -->
    
        </div> <!-- Close div for .panel -->
    
      </div> <!-- Close div for .col-12 -->


</div> <!-- Close div for .row -->
