<div class="col-md-8 col-sm-6 col-6 d-inline-block mb-3">
  <button class="btn btn-sm btn-pill" (click)="view = 'current'" [ngClass]="view == 'current' ? 'btn-info' : 'btn-label-info'">Currently insured</button>
  <button class="btn btn-sm btn-pill" (click)="view = 'previous'" [ngClass]="view == 'previous' ? 'btn-info' : 'btn-label-info'">Previously insured</button>
</div>

<ng-container *ngIf="view === 'current'">
  <div class="example-table-container">

    <table mat-table [dataSource]="userVehicles" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

        <!-- Vehicle Registration Column -->
        <ng-container matColumnDef="registrationNumber">
            <th mat-header-cell *matHeaderCellDef>Registration number</th>
            <td mat-cell *matCellDef="let row" data-label="Registration number">{{ row.registrationNumber }}</td>
        </ng-container>

        <!-- Vehicle make Column -->
        <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef>Vehicle make</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle make">{{ row.make }}</td>
        </ng-container>

        <!-- Vehicle model Column -->
        <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Vehicle model</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle model">{{ row.model }}</td>
        </ng-container>

        <!-- Vehicle year Column -->
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef>Vehicle year</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle year">{{ row.year }}</td>
        </ng-container>

        <!-- Insured Value Column -->
        <ng-container matColumnDef="insuredValue">
            <th mat-header-cell *matHeaderCellDef>Insured Value</th>
            <td mat-cell *matCellDef="let row" data-label="Insured Value">{{ row?.insurance?.insuredValueRetail ? 'Retail' : row.insurance?.insuredValue | currency: 'R ' }}</td>
        </ng-container>

        <ng-container matColumnDef="inceptionDate">
          <th mat-header-cell *matHeaderCellDef>Inception Date</th>
          <td mat-cell *matCellDef="let row" data-label="Inception Date">{{ row?.insurance?.inceptionDate | date: 'dd MMM yyyy' }}</td>
        </ng-container>

        <!-- Menu Column -->
        <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" data-label="Actions">
              <ng-container *ngIf="canViewItem" >
                <button class="btn btn-light btn-pill color-brand" matTooltip="Edit vehicle" [routerLink]="['short-term-insurance/vehicle/edit/', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
                <button class="btn btn-light btn-pill color-danger" matTooltip="Remove vehicle" (click)="deleteVehicle(row)"><i class="fi flaticon-trash mr-0"></i></button>
              </ng-container>
            </td>
        </ng-container>

        <!-- Menu Column --
        <ng-container matColumnDef="menu">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row" data-label="Actions">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <i class="fi flaticon-menu"></i>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item
                [routerLink]="['short-term-insurance/vehicle/', ({id: row._id, r: this.router.url})]">
                <span>View vehicle</span>
              </button>

              <button mat-menu-item
                [routerLink]="['short-term-insurance/vehicle/edit/', ({ registration: row.registrationNumber, r: this.router.url})]">
                <span>Edit vehicle</span>
              </button>

              <button mat-menu-item (click)="deleteVehicle(row)">
                <span>Delete vehicle</span>
              </button>
            </mat-menu>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="col-12 text-center mt-3" *ngIf="userVehicles?.length === 0">
    <p>No vehicles found</p>
</div>

<mat-paginator [hidden]="userVehicles?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getUserVehicle($event)">
</mat-paginator>
</ng-container>

<ng-container *ngIf="view === 'previous'">
  <div class="example-table-container">

    <table mat-table [dataSource]="previouslyInsuredVehicles" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

        <!-- Vehicle Registration Column -->
        <ng-container matColumnDef="registrationNumber">
            <th mat-header-cell *matHeaderCellDef>Registration number</th>
            <td mat-cell *matCellDef="let row" data-label="Registration number">{{ row?.item?._id?.registrationNumber }}</td>
        </ng-container>

        <!-- Vehicle make Column -->
        <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef>Vehicle make</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle make">{{ row?.item?._id?.make }}</td>
        </ng-container>

        <!-- Vehicle model Column -->
        <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Vehicle model</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle model">{{ row?.item?._id?.model }}</td>
        </ng-container>

        <!-- Vehicle year Column -->
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef>Vehicle year</th>
            <td mat-cell *matCellDef="let row" data-label="Vehicle year">{{ row?.item?._id?.year }}</td>
        </ng-container>

        <!-- Insured Value Column -->
        <ng-container matColumnDef="insuredValue">
            <th mat-header-cell *matHeaderCellDef>Insured Value</th>
            <td mat-cell *matCellDef="let row" data-label="Insured Value">{{ row?.item?.insuredValue | currency: 'R ' }}</td>
        </ng-container>

        <ng-container matColumnDef="inceptionDate">
          <th mat-header-cell *matHeaderCellDef>Termination Date</th>
          <td mat-cell *matCellDef="let row" data-label="Termination Date">{{ row?.item?.removedDate | date: 'dd MMM yyyy' }}</td>
        </ng-container>

        <!-- Menu Column -->
        <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" data-label="Actions">
                <button class="btn btn-light btn-pill color-brand" matTooltip="View vehicle" [routerLink]="['short-term-insurance/vehicle/edit/', row?.item?._id._id]" [view]="true"><i class="fi flaticon-edit mr-0"></i></button>
                <!-- <button class="btn btn-light btn-pill color-danger" matTooltip="Remove vehicle" (click)="deleteVehicle(row)"><i class="fi flaticon-trash mr-0"></i></button> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="col-12 text-center mt-3" *ngIf="userVehicles?.length === 0">
    <p>No vehicles found</p>
</div>

<mat-paginator [hidden]="userVehicles?.length === 0" [length]="resultsLengthPreviousInsurance" [pageSize]="pageSizePreviousInsurance" [pageSizeOptions]="pageSizeOptions" (page)="getUserVehicle($event)">
</mat-paginator>
</ng-container>
