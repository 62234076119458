<table mat-table [dataSource]="policyItems">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let item"> {{ item.type | sentenceCase }} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="make">
      <th mat-header-cell *matHeaderCellDef> Make </th>
      <td mat-cell *matCellDef="let item"> {{ item.make }} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef> Model </th>
      <td mat-cell *matCellDef="let item"> {{ item.model }} </td>
    </ng-container>

    <ng-container matColumnDef="registrationNumber">
        <th mat-header-cell *matHeaderCellDef> Registration Number </th>
        <td mat-cell *matCellDef="let item"> {{ item.registrationNumber }} </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>