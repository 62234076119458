import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { InvestmentService } from 'src/app/services/investment.service';
import { InvestmentResponse, Investment } from 'src/app/_shared/models/investment.model';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit, AfterViewInit {

  investments: Investment[]

  constructor(
    private investmentService: InvestmentService
  ) { }

  getInvestments() {
    this.investmentService.getAllInvestments().subscribe(
      (response:HttpResponse<InvestmentResponse>) => {
        this.investments = response.body.investment;
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getInvestments();
  }

}
