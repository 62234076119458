import { UserResponse, Company } from './../_shared/models/User.model';
import { BrokerCompany, BrokerCompanyResponse } from 'src/app/_shared/models/brokerCompany.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable, BehaviorSubject, } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { LoadingScreenService } from '../components/loading-screen/loading-screen.service';
import { User } from 'src/app/_shared/models/User.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>
  public currentCompany: Company;

  constructor(
    private http: HttpClient,
    public globals: Globals,
    private loading: LoadingScreenService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('SureSpace-currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  };

  public get currentUserValue(): User {
    if(this.currentUserSubject.value) 
      this.currentCompany = this.currentUserSubject.value.company[0];
    
    return this.currentUserSubject.value;
  }

  getToken(): string {
    return localStorage.getItem('SureSpace-userToken');
  };

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  };

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  };

  registerNewUser(userData){
    return this.http.post(`${this.globals.SERVER_URL}/api/users/register`, userData, {
      observe: 'response'
    });
  };

  registerNewBrokerCompany(company: BrokerCompany):Observable<HttpResponse<BrokerCompanyResponse>>{
    return this.http.post<BrokerCompanyResponse>(`${this.globals.SERVER_URL}/api/users/brokerCompany`, company, {
      observe: 'response'
    });
  };
  
  loginUser(userData){ //: Observable<HttpResponse<UserResponse>>{
    this.loading.startLoading();
    return this.http.post<any>(`${this.globals.SERVER_URL}/api/users/login`, userData, {
      observe: 'response'
    }).pipe(map(response => {
      const user = response.body.user;

      if(user && user.token){
        localStorage.setItem('SureSpace-userToken', user.token);
        localStorage.setItem('SureSpace-currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }

      return user

    }));
  };

  resendActivation(user, activationType:string){

    const params = {
      activationType: activationType
    }

    return this.http.put(`${this.globals.SERVER_URL}/api/users/resendActivation`, user, {
      params: params,
      observe: 'response'
    });
  }

  activateUser(token){
    return this.http.put(`${this.globals.SERVER_URL}/api/users/activate/${token}`, {
      observe: 'response'
    });
  };

  getLoggedinUser(){
    return this.http.get(`${this.globals.SERVER_URL}/api/users/current`, {
      observe: 'response'
    }).pipe(map(response => {
      const user = response.body['user'];

      localStorage.setItem('SureSpace-userToken', user.token);
      localStorage.setItem('SureSpace-currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);

      return user

    }));
  };

  renewSession(){
    return this.http.get(`${this.globals.SERVER_URL}/api/users/renewSession`, {
      observe: 'response'
    })
  }

  resetPassword(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/users/changeExistingPassword`, data, {
      observe: 'response'
    })
  };

  cancelAccount(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/users/cancelAccount`, data, {
      observe: 'response'
    })
  }

  forgotPassword(formData:any){
    return this.http.put(`${this.globals.SERVER_URL}/api/users/forgotPassword`, formData, {
      observe: 'response'
    })
  }

  createNewPassword(token:string, formData:any){
    return this.http.put(`${this.globals.SERVER_URL}/api/users/resetPassword/${token}`, formData, {
      observe: 'response'
    })
  }

  findResetPasswordUser(token:string){
    return this.http.get(`${this.globals.SERVER_URL}/api/users/forgotPassword/${token}`, {
      observe: 'response'
    })
  }

  logoutUser(){    
    localStorage.removeItem('SureSpace-userToken');
    localStorage.removeItem('SureSpace-currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
    // console.clear();
  };

}
