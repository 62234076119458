import { OptionalSafetyExtras } from 'src/app/_shared/models/vehicle.model';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service'
import { HttpResponse } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ActivatedRoute, Router } from "@angular/router"
import { DeleteVehicleComponent } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { Lightbox } from 'ngx-lightbox';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MyAccountService } from 'src/app/services/my-account.service';

@Component({
  selector: 'app-client-edit-vehicle',
  templateUrl: './client-edit-vehicle.component.html',
  styleUrls: ['./client-edit-vehicle.component.scss'],
  providers: [DeleteVehicleComponent]
})
export class ClientEditVehicleComponent implements OnInit, AfterViewInit {

  maxDate = new Date();

  vehicleRegistrationNumber = this.activeRoute.snapshot.paramMap.get('registration');
  vehicle: Vehicle[];

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  submitted = false;
  vehicleFormGroup: FormGroup;
  get form() { return this.vehicleFormGroup.controls; }

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  maxUploads: number = 6

  currentUser = this.authService.currentUserValue;
  familyMembers: FamilyMember[];

  constructor(
    private personalInsuranceService: PersonalInsuranceService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private deleteVehicleModal: DeleteVehicleComponent,
    private insuranceService: InsuranceService,
    private lightbox: Lightbox,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private myAccountService: MyAccountService
  ) {
    this.options = { concurrency: 2, maxUploads: this.maxUploads, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
            this.vehicleFormGroup.value.newPhotos.push(file.nativeFile)
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
      this.removeAllFiles()
        break;
    }
  };

  getCurrentVehicle(registrationNumber){

    this.personalInsuranceService.getSelectedVehicle(registrationNumber).subscribe(
      (response:HttpResponse<VehicleResponse>) => {
        this.vehicle = response.body.vehicle;
        
        // create array where extras needs to be patched
        this.vehicle['optionalExtras'].map(() => {
          this.addNewOptionalExtra()
        })

        this.vehicleFormGroup.patchValue(this.vehicle);
        console.log('current vehicle => ', this.vehicle)
        console.log('from => ', this.vehicleFormGroup)
        if(this.vehicle['insurance'].companyName){
          this.form['insured'].setValue('Yes')
        }

        // this.maxUploads = this.maxUploads - this.vehicle['s3Files'].length;

        // this.vehicleFormGroup.value.newPhotos = [];
        // this.vehicleFormGroup.value.removedPhotos = [];
      }
    )
  };

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  removeExistingVehiclePhoto(photo){
    //remove the image from the array so that it is not in the view
    this.vehicleFormGroup.value.s3Files = this.vehicleFormGroup.value.s3Files.filter((name, photoIndex) => { return name.key !== photo.key });
    this.vehicleFormGroup.value.photos = this.vehicleFormGroup.value.photos.filter((name, photoIndex) => { return name.key !== photo.key });

    this.vehicleFormGroup.value.removedPhotos.push(photo)
  }

  updateVehicle(saveType?:string){

    this.submitted = true;
    // stop here if form is invalid
    if (this.vehicleFormGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save vehicle.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    // Set photos == files
    this.files.map(file => {
      this.vehicleFormGroup.value.photos.push(file.nativeFile)
    });

    this.personalInsuranceService.updateVehicle(this.vehicleRegistrationNumber, this.vehicleFormGroup.value).subscribe(
      (response:HttpResponse<VehicleResponse>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            // this.resetForm()
            break;

          default:
            break;
        }
        
      }
    )
  };

  deleteVehicle(){
    this.deleteVehicleModal.openDialog(this.vehicleFormGroup.value);
    this.goBack();
  };

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  // getAllInsuranceCompanies(){
  //   this.insuranceService.getAllInsuranceCompanies().subscribe(
  //     (response: HttpResponse<InsuranceCompanyResponse>) => {
  //       this.insuranceCompanies = response.body.insuranceCompany;
  //     }
  //   )
  // };

  getAllFamilyMembers(){
    this.myAccountService.getAllFamilyMembers()
    .subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        this.familyMembers = response.body.family;
      }
    )
  };

  open(index: number): void {
    this.lightbox.open(this.vehicleFormGroup.value.s3Files, index);
  }
 
  close(): void {
    this.lightbox.close();
  }

  initOptionalExtraField(): FormGroup{
    return this.formBuilder.group({
      extra 		: ['', Validators.required]
    });
   };

  addNewOptionalExtra(): void{
    const control = <FormArray>this.form.optionalExtras;
    control.push(this.initOptionalExtraField());
  };

  removeOptionalExtra(i : number): void {
      const control = <FormArray>this.form.optionalExtras;
      control.removeAt(i);
  };

  goBack(){
    this.router.navigate(['/u/short-term-insurance/vehicle']);
  }

  addNewBroker(){
    localStorage.setItem('SureSpace-Vehicle', JSON.stringify(this.vehicleFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  addNewRegularDriver(){
    localStorage.setItem('SureSpace-Vehicle', JSON.stringify(this.vehicleFormGroup.value))
    this.router.navigate([`/u/me/my-family/new-member`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-Vehicle')){
      this.vehicleFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-Vehicle')));
      localStorage.removeItem('SureSpace-Vehicle');
    }
  }; 

  ngOnInit() {
    this.vehicleFormGroup = this.formBuilder.group({
      _id: [''],
      registrationNumber: ['', Validators.required],
      photos: [[]],
      s3Files: [[]],
      newPhotos: [[]],
      removedPhotos: [[]],
      make: ['', Validators.required],
      model: ['', Validators.required],
      year: ['', Validators.required],
      color: ['', Validators.required],
      status: ['', Validators.required],
      variant: ['', Validators.required],
      licenceExpiryDate: [''],
      milage: ['', Validators.required],
      use: ['', Validators.required],
      // engineSize: ['', Validators.required],
      vin: ['', Validators.required],
      // engineNumber: ['', Validators.required],
      homeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      officeEnvironment: this.formBuilder.group({
        garage: false,
        carPark: false,
        markedParkingArea: false,
        openLotParking: false,
        undercoverParking: false,
        behindClosedGate: false,
        houseCarport: false,
        securityEstateCarport: false,
        street: false,
        driveway: false
      }),
      optionalSafetyExtras: this.formBuilder.group({
        alarm: false,
        centralLocking: false,
        dataDot: false,
        gearLock: false,
        immobiliser: false,
        smashAndGrab: false,
        steeringLock: false,
        tracker: false
      }),
      optionalExtras: this.formBuilder.array([]),
      insured: ['No'],
      dateInsured: [''],
      insuredWith: [''],
      insuredValue: [''],
      premium: [''],
      policyNumber: [''],
      regularDriver: [''],
      claimFreeYears: [''],
      insurance: this.formBuilder.group({
        currentlyInsured: [''],
        brokerId: [""],
        brokerFirstName: [''],
        brokerLastName: [''],
        companyName: [''],
        insuredValue: [''],
        inceptionDate: [''],
        premium: [''],
        policyNumber: [''],
        terminationDate: [''],
        regularDriver: [''],
        createdAt: [''],
        updatedAt: [''],
      })
    });  
  }

  ngAfterViewInit(){
    this.getCurrentVehicle(this.vehicleRegistrationNumber);
    this.getLocalStorageItem();

    this.getAllBrokers();
    // this.getAllInsuranceCompanies();
    this.getAllFamilyMembers();
  }

}

