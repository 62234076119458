import { Company } from './User.model';
import { SuccessMessage } from './successMessage.model';

export class Employee {
    constructor(
        public _id?: string,
        public firstName?: string,
        public lastName?: string,
        public username?: string,
        public email?: string,
        public cellphone?: Date,
        public company?: [Company],
        public userType?: string,
        public permissions?: [string]
    ){}
  }

export class EmployeeResponse {
    constructor(
        public user: [Employee],
        public message?: SuccessMessage
    ) {}
}