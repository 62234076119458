<h1>Colors</h1>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">primary: </p>
  <div class="float-left color color-primary-light"></div>
  <div class="float-left color color-primary"></div>
  <div class="float-left color color-primary-dark"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">secondary: </p>
  <div class="float-left color color-secondary"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">white: </p>
  <div class="float-left color color-white"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">grey: </p>
  <div class="float-left color color-grey"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">dark-grey: </p>
  <div class="float-left color color-dark-grey"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">black: </p>
  <div class="float-left color color-black"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">success: </p>
  <div class="float-left color color-success"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">warning: </p>
  <div class="float-left color color-warning"></div>
</div>

<div class="col-12 d-inline-block">
  <p class="float-left mr-3">danger: </p>
  <div class="float-left color color-danger"></div>
</div>
