<div class="row">

        <div class="col-12">
      
          <div class="table-responsive">
      
            <table mat-table [dataSource]="investments" matSort matSortActive="created" matSortDisableClear
            matSortDirection="desc">
      
            <ng-container matColumnDef="policyNumber">
              <th mat-header-cell *matHeaderCellDef>Policy number</th>
              <td mat-cell *matCellDef="let row" data-label="Policy number">{{row.policyNumber}}</td>
            </ng-container>
            
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Investment type</th>
              <td mat-cell *matCellDef="let row" data-label="Investment type">{{ row.type }}</td>
            </ng-container>
      
            <ng-container matColumnDef="investmentCompany">
              <th mat-header-cell *matHeaderCellDef>Investment company</th>
              <td mat-cell *matCellDef="let row" data-label="Investment company">{{ row.investmentCompany }}</td>
            </ng-container>
            
            <ng-container matColumnDef="investmentDate">
              <th mat-header-cell *matHeaderCellDef>Investment date</th>
              <td mat-cell *matCellDef="let row" data-label="Investment date">{{ row.investmentDate | date: 'dd/MM/yyyy' }}</td>
            </ng-container>    
            
            <ng-container matColumnDef="investedAmount">
              <th mat-header-cell *matHeaderCellDef>Amount invested</th>
              <td mat-cell *matCellDef="let row" data-label="Amount invested">{{ row.investedAmount | currency: 'R ' }}</td>
            </ng-container>
            
            <ng-container matColumnDef="currentValue">
              <th mat-header-cell *matHeaderCellDef>Current value</th>
              <td mat-cell *matCellDef="let row" data-label="Current value">{{ row.currentValue || row.investedAmount | currency: 'R ' }}</td>
            </ng-container>
      
            <ng-container matColumnDef="calculationDate">
              <th mat-header-cell *matHeaderCellDef>Calculation date</th>
              <td mat-cell *matCellDef="let row" data-label="Calculation date">{{ calculateCalculationDate(row.investmentDate, row.calculationPeriod) | date: 'dd/MM/yyyy' }}</td>
            </ng-container>
      
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row" data-label="Actions">
                  <button class="btn btn-light btn-pill color-brand" matTooltip="Edit investment" [routerLink]="['investments/edit', ({i: row._id})]"><i class="fi flaticon-edit mr-0"></i></button>
                  <button class="btn btn-light btn-pill color-danger" matTooltip="Remove investment" (click)="removeInvestment(row)"><i class="fi flaticon-trash mr-0"></i></button>
                </td>
              </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <p class="text-center mt-3" *ngIf="investments?.length === 0">Currently no investments made</p>
            
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>      
      
          </div> <!-- Close div for .table -->
      
        </div>
      
      </div>