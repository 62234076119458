import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service'
import { Globals } from 'src/app/app.globals';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ActivatedRoute, Router } from '@angular/router';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';

import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Structure } from 'src/app/_shared/models/structure.model';

@Component({
  selector: 'app-client-edit-structure',
  templateUrl: './client-edit-structure.component.html',
  styleUrls: ['./client-edit-structure.component.scss']
})
export class ClientEditStructureComponent implements OnInit, AfterViewInit {

  maxDate = new Date();

  editStructure: boolean;

  structure: Structure[];
  structureId =  this.activeRoute.snapshot.paramMap.get('id');

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  maxUploads: number = 6

  submitted = false;
  structureFormGroup: FormGroup;
  get form() { return this.structureFormGroup.controls; }

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private insuranceService: InsuranceService,
    private personalInsuranceService: PersonalInsuranceService,
    private formBuilder: FormBuilder,
    private lightbox: Lightbox,
    private toastr: ToastrService
  ) {
    this.options = { concurrency: 2, maxUploads: this.maxUploads, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  };

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
            this.structureFormGroup.value.newPhotos.push(file.nativeFile)
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
      this.removeAllFiles()
        break;
    }
  };

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  };
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  };
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  };

  removeExistingStructurePhoto(photo){
    //remove the image from the array so that it is not in the view
    this.structureFormGroup.value.s3Files = this.structureFormGroup.value.s3Files.filter((name, photoIndex) => { return name.key !== photo.key });
    this.structureFormGroup.value.photos = this.structureFormGroup.value.photos.filter((name, photoIndex) => { return name.key !== photo.key });

    this.structureFormGroup.value.removedPhotos.push(photo)
  }

  getStructure(id){

    this.personalInsuranceService.getStructure(id).subscribe(
      (response:HttpResponse<any>) => {

        this.structure = response.body.structure;

        this.structureFormGroup.patchValue(this.structure);
        this.maxUploads = this.maxUploads - this.structure['s3Files'].length;

        const control = <FormArray>this.structureFormGroup.controls.additionalStructures;
        this.structure['additionalStructures'].map(structure => {
          control.push(this.initStructureFields(structure.structure));
        });

        this.structureFormGroup.value.newPhotos = [];
        this.structureFormGroup.value.removedPhotos = [];
      }
    )
  };

  updateStructure(saveType:string){

    this.submitted = true;
    // stop here if form is invalid
    if (this.structureFormGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save Insured structure.')
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    // Set photos == files
    this.files.map(file => {
      this.structureFormGroup.value.photos.push(file.nativeFile)
    });

    this.personalInsuranceService.updateStructure(this.structureFormGroup.value).subscribe(
      (response:HttpResponse<any>) => {
        
        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            break;

          default:
            this.goBack();
            break;
        }

      }
    )
  };

  initStructureFields(structure) : FormGroup {
    return this.formBuilder.group({
        structure 		: [structure, Validators.required]
    });
  };

  addNewInputField() : void {
    const control = <FormArray>this.structureFormGroup.controls.additionalStructures;
    control.push(this.initStructureFields(''));
  };

  removeInputField(i : number) : void {
    const control = <FormArray>this.structureFormGroup.controls.additionalStructures;
    control.removeAt(i);
  };

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<any>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  // getAllInsuranceCompanies(){
  //   this.insuranceService.getAllInsuranceCompanies().subscribe(
  //     (response: HttpResponse<any>) => {
  //       this.insuranceCompanies = response.body.insuranceCompany;
  //     }
  //   )
  // };

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.structureFormGroup.value.s3Files, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  goBack(){
    this.router.navigate(['/u/short-term-insurance/structure']);
  }

  addNewBroker(){
    localStorage.setItem('SureSpace-InsuredStructure', JSON.stringify(this.structureFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-InsuredStructure')){
      this.structureFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-InsuredStructure')));
      localStorage.removeItem('SureSpace-InsuredStructure');
    }
  }; 

  ngOnInit() {

    this.structureFormGroup = this.formBuilder.group({
      _id: [''],
      name: ['', Validators.required],
      photos: [[]],
      s3Files: [[]],
      newPhotos: [[]],
      removedPhotos: [[]],
      stand: ['', Validators.required],
      suburb: ['', Validators.required],
      city : ['',  Validators.required],
      municipalAccNr : ['',  Validators.required],
      structureValue : ['',  Validators.required],
      roof: this.formBuilder.group({
        corrugatedIron: false,
        tiles: false,
        grass: false,
        glass: false,
        wood: false
      }),
      externalWalls: this.formBuilder.group({
        brick: false,
        cement: false,
        corrugatedIron: false,
        glass: false,
        grass: false,
        prefab: false,
        tiles: false,
        wood: false
      }),
      floor: this.formBuilder.group({
        carpet: false,
        tiles: false,
        sealed: false,
        woodenFloors: false
      }),
      additions: this.formBuilder.group({
        birdCage: false,
        deckOrPatio: false,
        fishPond: false,
        garden: false,
        specialGarden: false,
        poolAndAccessories: false,
        outdoorFurniture: false,
        outdoorOrnaments: false,
        solar: false,
        waterFeature: false
      }),
      security: this.formBuilder.group({
        alarm: false,
        armedResponse: false,
        infraredBeams: false,
        outsideFence: false,
        outsideWall: false,
        pallisades: false,
        lockableGarage: false,
        securityGates: false,
        burglarBars: false,
        trellisDoor: false,
        securityCameras: false,
        intercom: false
      }),
      structures: ['No'],
      additionalStructures: this.formBuilder.array([]),
      insuredWith: ['', Validators.required],
      dateInsured: ['', Validators.required],
      insuredValue: ['', Validators.required],
      premium: [''],
      policyNumber: ['']
    });
  }

  ngAfterViewInit() {
    this.getStructure(this.structureId);
    this.getLocalStorageItem();
    this.getAllBrokers();
  }

}
