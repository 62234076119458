<div id="content" class="row">

    <sub-navigation heading="Company settings"></sub-navigation>
  
    <div class="col-12">
  
        <div class="panel panel-primary">
    
            <div class="panel-heading">
    
                <h5>Company settings</h5>

                <button
                    *ngIf="selectedTabIndex === 1"
                    type="button"
                    class="btn btn-brand my-auto"
                    (click)="addNewLocation()"
                    matTooltip="Add a new company location"
                    >
                    <i class="fi flaticon-add"></i>Add location
                </button>
    
            </div> <!-- Close div for .panel-heading -->
    
            <div class="panel-body pb-5">

                <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">

                    <mat-tab label="Company details">

                        <form [formGroup]="companyDetailsFormGroup">

                            <div class="row">

                                <div class="col-12 clearfix mt-3"></div>
                    
                                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                                    <label for="registrationNumber">Company registration number</label>
                                </div>
                    
                                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                                <input 
                                    class="form-control"
                                    name="registrationNumber"
                                    formControlName="registrationNumber"
                                    placeholder="Registration number"
                                />
                                </div>
                      
                                  <div class="col-12 clearfix mt-3"></div>

                                  <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                                    <label for="fspNumber">Company FSP number</label>
                                </div>
                    
                                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                                <input 
                                    class="form-control"
                                    name="fspNumber"
                                    formControlName="fspNumber"
                                    placeholder="FSP number"
                                />
                                </div>

                                <div class="col-12">

                                    <hr class="hr-dashed hr-small" />

                                    <div class="panel-footer-inside">
                                        <button class="btn btn-success" (click)="saveCompanyDetails()">Save details</button>
                                    </div>

                                </div>

                                

                            </div> <!-- Close div for .row -->

                        </form>

                    </mat-tab>

                    <mat-tab label="Company location">

                        <div class="row">

                            <div class="col-12 text-center" *ngIf="companyLocations?.length === 0">
                                <p class="text-center">No company addresses found</p>
                            </div>
                            

                            <div class="col-12 col-sm-6 col-lg-4" *ngFor="let location of companyLocations">
                                
                                <div class="card h-100">
                                    <div class="card-body">   
                                      
                                        <div class="text-right">
                                            <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="locationMenu">
                                                <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
                                            </button>
                                  
                                            <mat-menu #locationMenu="matMenu">
                                                <button mat-menu-item (click)="editLocation(location)">
                                                    <span>Edit location</span>
                                                </button>
                                                <button mat-menu-item (click)="deleteLocation(location)">
                                                    <span>Remove location</span>
                                                </button>
                                            </mat-menu>
                                        </div>
              
                                        <h5 class="card-title color-label-4 mb-3">{{ location?.addressDescription }}</h5>
                                        <p class="card-text mb-0">
                                            {{ location?.addressLine1 }}<br />
                                            {{ location?.city }}<br />
                                            {{ location?.suburb }}<br />
                                            {{ location?.province }}
                                        </p>
                                    </div>
                                </div> <!-- Close div for .card -->

                            </div>

                        </div> <!-- Close div for .row -->

                    </mat-tab>

                    <mat-tab label="Company logo">

                        <div class="col-12 text-center">
                            <img *ngIf="this.currentLogo?.file && uploader.queue.length < 1" class="companyLogo" [src]="this.currentLogo?.file" />
                            <img *ngIf="uploader.queue.length > 0" class="companyLogo" [src]="sanitizer.bypassSecurityTrustUrl(uploadedLogo)" />
                        </div>

                        <div ng2FileDrop
                            (fileOver)="fileOverBase($event)"
                            (onFileSelected)="onFileSelected($event)"
                            [uploader]="uploader"
                            [ngClass]="{ 'fileOver': hasBaseDropZoneOver }"
                            class="well my-drop-zone"
                            *ngIf="!this.currentLogo?.file && uploader.queue.length < 1"
                        >

                        <div class="desc">
                            <img src="https://www.flaticon.com/svg/vstatic/svg/1160/1160155.svg?token=exp=1617640964~hmac=24606ffc3c5cd368ebf642f5fc4e62d8" />
                            <h5 class="my-3">Drop your logo here, or click to browse.</h5>
                        </div>
                            
                           <input type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />

                        </div>

                        <div class="col-12 text-center" *ngIf="uploader?.queue?.length < 1 && !this.currentLogo">
                            <input type="file" name="file" id="file" class="inputfile" ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
                            <label for="file">Choose a new logo</label>
                        </div>
                        

                        <hr class="hr-dashed hr-small" />

                        <div class="panel-footer-inside">
                            <button *ngIf="uploader.queue.length > 0" class="btn btn-outline-secondary mr-2" (click)="clearUploaderQueue()">Cancel</button>
                            <button class="btn btn-success" (click)="updateCompanyLogo(uploader.queue[0])">Upload logo</button>
                        </div>

                    </mat-tab>

                </mat-tab-group>

            </div> <!-- Close div for panel-body -->

        </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->
</div> <!-- Close div for #content -->