import { Component, OnInit, ViewChildren, AfterViewInit, ViewChild } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { Location } from '@angular/common';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { ToastrService } from 'ngx-toastr';

import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BrokerUploadAnyDocumentModalComponent } from 'src/app/broker/clients/documents/uploadAnyDocumentModal/broker-upload-any-document-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BrokerCreateUserCustomNoteComponent } from 'src/app/broker/clients/edit-client/templates/broker-view-user-notes/custom-note/broker-create-user-custom-note.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivityLog } from 'src/app/_shared/models/activityLogs.model';
import { BrokerViewUserNotesComponent } from './templates/broker-view-user-notes/broker-view-user-notes.component';
import { BrokerComponent } from '../../broker.component';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-edit-broker-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss'],
  providers: [BrokerUploadAnyDocumentModalComponent, BrokerCreateUserCustomNoteComponent, BrokerViewUserNotesComponent, BrokerComponent]
})
export class EditBrokerClientComponent implements OnInit, AfterViewInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');
  currentUser = this.authenticationService.currentUserValue;

  user: any = {};
  userAdditional: any = {};
  allUsers: any[];
  employees: Employee[];

  selectedIndex: number = 0;
  selectedProductIndex: number = 0;

  @ViewChildren(MatPaginator) paginator: any;
  pageEvent: PageEvent;

  basicInformationForm: FormGroup;
  get basicForm(){ return this.basicInformationForm.controls; }

  additionalInformationForm: FormGroup;
  get additionalForm(){ return this.additionalInformationForm.controls; }

  contactInformationForm: FormGroup;
  get contactForm(){ return this.contactInformationForm.controls; }

  formSubmitted = false;

  languages: any = [
    { language: 'Afrikaans'},
    { language: 'English' },
    { language: 'Zulu' },
    { language: 'Xhosa' },
    { language: 'Venda' },
    { language: 'Southern Sotho' },
    { language: 'Tswana' },
    { language: 'Northern Sotho' },
    { language: 'Tsongo' },
    { language: 'Swati' },
    { language: 'Ndebele' },
  ]

  currentUserPermissions: any[];

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  constructor(
    private brokerService: BrokerService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public location: Location,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private notesComponent: BrokerViewUserNotesComponent,
    public brokerComponent: BrokerComponent
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  
    this.selectedIndex = JSON.parse(localStorage.getItem('SureSpaceSettings'))?.client_selectedIndex || 0;

    this.activeRoute.queryParamMap.subscribe(param => {

      if(param['params'].file){
        this.selectedIndex = Number(param['params'].file)
      }
    })
  }

  getBrokerUser(){
    this.brokerService.getSingleClient(this.selectedUser).subscribe(
      (response:HttpResponse<UserResponse>) => {

        this.user = {...response.body.user};
        const clientNumber = response.body.user['broker'][0].clientNumber;

        // MAP USER BROKER
        this.user['testbroker'] = this.employees.find(emp => emp._id === response.body.user['broker'][0]._id);
        this.user.broker = this.user.broker.find(broker => broker.companyName === this.authService.currentCompany.companyName);

        console.log('testing => ', this.user.broker)

        this.patchForm();
        this.basicInformationForm.get('clientNumber').setValue(clientNumber);
        // this.basicInformationForm.get('broker').get('clientNumber').setValue(clientNumber);
        // this.basicInformationForm.get('broker').get('firstName').setValue(this.user.broker.firstName);
        // this.basicInformationForm.get('broker').get('lastName').setValue(this.user.broker.lastName);
        this.basicInformationForm.get('broker').patchValue(this.user.broker)

      }
    )
  };

  updateUserInformation(saveType:string) {

    this.formSubmitted = true;

    // MARK ALL INPUTS FOR MAT-ERROR TO TAKE EFFECT
    this.basicInformationForm.markAllAsTouched();
    this.additionalInformationForm.markAllAsTouched();
    this.contactInformationForm.markAllAsTouched();

    if (this.basicInformationForm.invalid || this.additionalInformationForm.invalid || this.contactInformationForm.invalid ) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add client.')

      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    let formData = {
      ...this.basicInformationForm.value,
      ...this.contactInformationForm.value,
      ...this.additionalInformationForm.value
    }

   this.brokerService.saveUserBasicInformation(this.selectedUser, this.authService.currentCompany.companyName, formData).subscribe(
      (response:HttpResponse<UserResponse>) => {

        this.user.insuranceType = this.basicInformationForm.value.insuranceType;

        switch(saveType){
          case 'back':
            // this.location.back();
            this.router.navigate(['/b/clients/personal']);
            break;

          case 'continue':
            break;

          default:
            // this.location.back();
            this.router.navigate(['/b/clients/personal']);
            break;
        }

      }
    )
  }

  patchForm(){
    this.basicInformationForm.patchValue(this.user);
    this.contactInformationForm.patchValue(this.user);
    this.additionalInformationForm.patchValue(this.user);
  }

  resetForms(){
    this.basicInformationForm.reset();
    this.additionalInformationForm.reset();
    this.contactInformationForm.reset();
    this.patchForm()
  }

  setSelectedIndex(event){
    this.selectedIndex = event;

    let surespaceSettings = JSON.parse(localStorage.getItem('SureSpaceSettings')) || {};
    surespaceSettings['client_selectedIndex'] = event;
    localStorage.setItem('SureSpaceSettings', JSON.stringify(surespaceSettings))
  }

  setSelectedProductIndex(event){
    this.selectedProductIndex = event;
  }

  getEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName, {}, true).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;

        this.getBrokerUser();

      }
    )
  }

  ngAfterViewInit() {
    this.getEmployees();
  }

  ngOnInit() {
    this.basicInformationForm = this.formBuilder.group({
      username:         [''],
      passportNumber: [''],
      taxNumber:        [''],
      clientNumber:     [''],
      firstName:        ['', Validators.required],
      lastName:         ['', Validators.required],
      broker:           this.formBuilder.group({
        _id:            '',
        clientStatus:   [''],
        clientNumber:   '',
        firstName: [''],
        lastName: ['']
      }),
      gender:           ['', Validators.required],
      maritalStatus:    [''],
      language:         ['', Validators.required],
      clientStatus:     [''],
      physicalAddress: this.formBuilder.group({
          addressLine1: [''],
          addressLine2: [''],
          suburb: [''],
          city: [''],
          province: [''],
          postalCode: [''],
          formattedAddress: ['']
      }),
      insuranceType: this.formBuilder.group({
        householdInsurance:     false,
        vehicleInsurance:       false,
        buildingInsurance:      false,
        personalItemInsurance:  false,
        trailersAndCaravansInsurance: false,
        vapsInsurance: false
      }),
    });

    this.additionalInformationForm = this.formBuilder.group({
      middleName:       [''],
      employer:         [''],
      jobDescription:   [''],
      salary:           [''],
      driversLicence: this.formBuilder.group({
        licenceCode:        [''],
        licenceFirstIssue:  [''],
        licenceExpiryDate:  ['']
      }),
    });

    this.contactInformationForm = this.formBuilder.group({
      email:            ['', [Validators.required, Validators.email]],
      cellphone:        ['', Validators.required],
      homePhone:        [''],
      workPhone:        [''],
      alternateNumber:  [''],
    });

    // this.maritalInformationForm = this.formBuilder.group({
    //   maritalStatus:    ['', Validators.required],
    //   anniversaryDate:  ['']
    // });

  }

  openUploadDocumentModal(){
      const dialogRef = this.dialog.open(BrokerUploadAnyDocumentModalComponent, { width: '1000px', data: { selectedUser: this.selectedUser } });
  
      dialogRef.afterClosed().subscribe(result => {
      });
  }

  canChangeClientStatus(){
    let userPermissions = this.currentUser.company.find(c => c.companyName === this.authService.currentCompany.companyName).permissions;
    return userPermissions.includes('brokerManager') || userPermissions.includes('brokerDirector');
  }

  newestNote:ActivityLog = null;
  addManualNote(){
    let dialogRef = this.dialog.open(BrokerCreateUserCustomNoteComponent, {
      width: '800px',
      data: {
        userId: this.selectedUser,
        companyName: this.authService.currentCompany.companyName
      }
    });

    dialogRef.afterClosed().subscribe(response => {

      if(response.status === 'success'){
        this.newestNote = response.data
        // this.notesComponent.getActivityLogs();
      }
    })

  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let basicInformationFormGroupAddress = this.basicInformationForm.get('physicalAddress');

    basicInformationFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    basicInformationFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    basicInformationFormGroupAddress.get('city').setValue(findInAddress('locality'));
    basicInformationFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    // basicInformationFormGroupAddress.get('country').setValue(findInAddress('country'));
    basicInformationFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    basicInformationFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

  mapEmployee(event){
    let employee = this.employees.find(e => e._id === event.value);
    this.basicInformationForm.get('broker').get('firstName').setValue(employee.firstName)
    this.basicInformationForm.get('broker').get('lastName').setValue(employee.lastName)
  }

}

import { Directive, ElementRef,ChangeDetectorRef } from '@angular/core';   
@Directive({
  selector: '[appHideMe]',
  exportAs:'appHideMe',

})
export class HideMeDirective implements AfterViewInit {   
  hide:Boolean;    
  constructor(
    private el: ElementRef,
    private cdr:ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.hide=false;
    this.cdr.detectChanges();
  }
}