<div class="modal-header">
        <h6 class="mb-0"><strong>Delete home content insurance?</strong></h6>
    </div>
        
    <div class="modal-body">
        <div mat-dialog-content>
            <p class="mb-4 mt-4">
                Are you sure you want to remove the insurance from <strong>{{ householdItem?.residenceType | sentenceCase }}</strong> at {{ householdItem?.address?.formattedAddress}}?
                <br />
                This action cannot be undone.
            </p>
        </div>
        </div>
        
        <div class="modal-footer">
        <div class="inline-buttons">
            <button cdkFocusInitial class="btn btn-secondary mr-2" (click)="closeDialog('cancel')"><i class="fi flaticon-cancel"></i>Cancel</button>
            <button class="btn btn-danger" (click)="removeHomeContent(householdItem)"><i class="fi flaticon-trash"></i>Remove</button>
        </div>
    </div>
                  