<div class="row">

    <div class="col-12 text-center" *ngIf="familyMembers.length === 0">
        <p>Client has no family members</p>
    </div>

    <div class="col-md-3 col-sm-4 col-6" *ngFor="let member of familyMembers">

        <div class="card h-100">
            <div class="card-body">

                <div class="text-right">
                    <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="menu">
                        <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['family-members/edit/', member._id]">
                            <span>Edit family member</span>
                        </button>
                        <button mat-menu-item (click)="deleteFamilyMember(member._id)">
                            <span>Remove family member</span>
                        </button>
                    </mat-menu>
                </div>

                <div class="text-center mb-3">
                    <span class="userpic userpic-lg userpic-circle userpic-dark">
                        <span>{{ member?.name.substring(0, 1) }}{{ member?.surname.substring(0, 1) }}</span>
                    </span>
                </div>

                <!-- <div class="text-center mb-0">
                    <span class="color-dark-grey"></span>
                </div> -->

                <h5 class="card-title text-center color-label-4 mb-4">{{ member?.name }} {{ member?.surname }}</h5>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>ID Number:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ member?.idNumber }}</small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Relationship:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ member?.relationship }}</small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Drivers licence:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ member?.driversLicence?.licenceCode || 'No licence specified' }}</small>
                </p>
            </div>
        </div>
        <!-- Close div for .card -->
    </div>

</div>
<!-- Close div for .row -->