import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable } from 'rxjs';
import { FamilyMemberResponse } from '../_shared/models/familyMember.model';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  updateUserInformation(data: any):Observable<HttpResponse<any>>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/myAccount/userInformation`, data, {
      observe: 'response'
    });
  }

  saveBasicInformation(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/myAccount/basicInformation`, data, {
      observe: 'response'
    });
  }

  saveAdditionalInformation(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/myAccount/additionalInformation`, data, {
      observe: 'response'
    });
  }

  getAdditionalInformation(){
    return this.http.get(`${this.globals.SERVER_URL}/api/myAccount/additionalInformation`, {
      observe: 'response'
    });
  }

  saveContactInformation(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/myAccount/contactInformation`, data, {
      observe: 'response'
    });
  };

  saveRelationshipStatus(data){
    return this.http.put(`${this.globals.SERVER_URL}/api/myAccount/relationshipStatus`, data, {
      observe: 'response'
    });
  };

  getRelationshipStatus(){
    return this.http.get(`${this.globals.SERVER_URL}/api/myAccount/relationshipStatus`, {
      observe: 'response'
    });
  };

  saveNewFamilyMember(data, userId){
    return this.http.post(`${this.globals.SERVER_URL}/api/myAccount/familyMember/${userId}`, data, {
      observe: 'response'
    });
  };

  getAllFamilyMembers():Observable<HttpResponse<FamilyMemberResponse>>{
    return this.http.get<FamilyMemberResponse>(`${this.globals.SERVER_URL}/api/myAccount/familyMember`, {
      observe: 'response'
    });
  };

  getSelectedFamilyMembers(id){
    return this.http.get(`${this.globals.SERVER_URL}/api/myAccount/familyMember/${id}`, {
      observe: 'response'
    });
  };

  updateFamilyMember(id, data){
    return this.http.put(`${this.globals.SERVER_URL}/api/myAccount/familyMember/${id}`, data, {
      observe: 'response'
    });
  };

  removeFamilyMember(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/myAccount/familyMember/${id}`, {
      observe: 'response'
    });
  };


}
