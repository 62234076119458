import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { AppRoutes } from 'src/app/app.routing';

import { LoginComponent } from 'src/app/login/login.component';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";

// CUSTOM PIPES
import { IndefinitePipe } from 'src/app/pipe/indefinite.pipe';
import { TimeagoPipe } from 'src/app/pipe/timeago.pipe';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { KeyValueUnsortedPipe } from 'src/app/pipe/key-value-unsorted.pipe';
import { SentenceCasePipe } from 'src/app/pipe/sentence-case.pipe';

// CUSTOM BUTTONS
import { EditButtonComponent } from 'src/app/components/buttons/edit-button/edit-button.component';
import { SaveButtonComponent } from 'src/app/components/buttons/save-button/save-button.component';
import { CancelButtonComponent } from 'src/app/components/buttons/cancel-button/cancel-button.component';
import { AddButtonComponent } from 'src/app/components/buttons/add-button/add-button.component';
import { BackButtonComponent } from 'src/app/components/buttons/back-button/back-button.component';
import { DeleteButtonComponent } from 'src/app/components/buttons/delete-button/delete-button.component';

//ANGULAR MATERIAL
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';


// NG-BOOTSTRAP
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbDropdownModule, NgbTabsetModule, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

// CUSOM COMPONENTS
import { SubNavComponent } from 'src/app/components/sub-nav/sub-nav.component';
import { ShowHidePasswordDirective } from 'src/app/_helpers/directives/show-hide-password.directive';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

//THIRD PARTY
import { NgxUploaderModule } from 'ngx-uploader';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    LoginComponent,
    IndefinitePipe,
    TimeagoPipe,
    ReplacePipe,
    KeyValueUnsortedPipe,
    SentenceCasePipe,
    EditButtonComponent,
    SaveButtonComponent,
    CancelButtonComponent,
    AddButtonComponent,
    BackButtonComponent,
    DeleteButtonComponent,
    // CUSTOM COMPONENTS
    SubNavComponent,
    ShowHidePasswordDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    }),
    // MATERIAL
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatPasswordStrengthModule.forRoot(),
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatStepperModule,
    MatBadgeModule,
    ScrollingModule,
    MatSlideToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatIconModule,
    MatChipsModule,
    // BOOTSTRAP
    // BsDropdownModule.forRoot()
    NgbDropdownModule,
    NgbTabsetModule,
    NgbModalModule,
    NgbPaginationModule,
    // THIRD PARTY
    NgxUploaderModule,
    CKEditorModule,
    InfiniteScrollModule,
    FileUploadModule,
    GooglePlaceModule,
    FullCalendarModule,
    QuillModule.forRoot(),
    RxReactiveFormsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    // MATERIAL
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatPasswordStrengthModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatStepperModule,
    MatBadgeModule,
    ScrollingModule,
    MatSlideToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatIconModule,
    MatChipsModule,
    //BOOTSTRAP
    // BsDropdownModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbModalModule,
    NgbPaginationModule,
    //END BOOTSTRAP
    IndefinitePipe,
    TimeagoPipe,
    ReplacePipe,
    KeyValueUnsortedPipe,
    SentenceCasePipe,
    EditButtonComponent,
    SaveButtonComponent,
    CancelButtonComponent,
    AddButtonComponent,
    BackButtonComponent,
    DeleteButtonComponent,
    // CUSTOM COMPONENTS
    SubNavComponent,
    ShowHidePasswordDirective,
    // THIRD PARTY
    NgxUploaderModule,
    CKEditorModule,
    InfiniteScrollModule,
    FileUploadModule,
    GooglePlaceModule,
    FullCalendarModule,
    QuillModule,
    RxReactiveFormsModule
  ]
})
export class SharedModule { }
