<!-- <h1 [ngClass]="authenticated ? 'text-black' : 'text-white'">Suggestion</h1> -->

<form id="suggestionForm" [formGroup]="suggestionForm" (ngSubmit)="reportIssue()">

    <label for="name"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Your name:</strong></label>
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.submitterName.errors }"
      name="submitterName"
      formControlName="submitterName"
    />
    <div *ngIf="submitted && form['submitterName'].errors" class="invalid-feedback">
      <div *ngIf="form['submitterName'].errors.required">Please provide your name.</div>
    </div>

    <label for="email"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Your email:</strong></label>
    <input
      type="email"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form['submitterEmail'].errors }"
      name="submitterEmail"
      formControlName="submitterEmail"
      pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    />
    <div *ngIf="submitted && form['submitterEmail'].errors" class="invalid-feedback">
      <div *ngIf="form['submitterEmail'].errors.required">Please provide your email address.</div>
      <div *ngIf="form['submitterEmail'].errors.email || form['submitterEmail'].errors.pattern">Please provide a valid email address.</div>
    </div>

    <!-- <label for="subject"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Suggestion subject:</strong></label>
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form['subject'].errors }"
      name="subject"
      formControlName="subject"
    />
    <div *ngIf="submitted && form['subject'].errors" class="invalid-feedback">
      <div *ngIf="form['subject'].errors.required">Please provide a subject for your suggestion.</div>
    </div> -->

    <label for="suggestion"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Suggestion:</strong></label>
    <!-- <ckeditor
      id="editor"
      name="suggestion"
      [editor]="Editor"
      [ngClass]="{ 'is-invalid': submitted && form['suggestion'].errors }"
      formControlName="suggestion">
    </ckeditor> -->
    <br />
    <textarea
      class="form-control"
      name="suggestion"
      [ngClass]="{ 'is-invalid': submitted && form['suggestion'].errors }"
      formControlName="suggestion">
    </textarea>
    <div *ngIf="submitted && form['suggestion'].errors" class="invalid-feedback">
      <div *ngIf="form['suggestion'].errors.required">Please provide your suggestion.</div>
    </div>

    <app-save-button class="mt-3" type="submit" buttonText="Send"></app-save-button>

</form>