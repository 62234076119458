import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'page-description',
  templateUrl: './page-description.component.html',
  styleUrls: ['./page-description.component.scss']
})
export class PageDescriptionComponent implements OnInit {

  @Input() describe: string;
  @Input() icon: string;
  @Input() subheading: string;

  constructor() { }

  ngOnInit() {
  }

}
