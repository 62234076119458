<div class="mb-5 col-md-4 col-sm-6 col-6">
    <mat-form-field>
    <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="quoteSearch" (ngModelChange)="findAllCompanyQuotes($event, true)"/>
    <mat-hint>
        Search in all fields.
    </mat-hint>
    </mat-form-field>
</div>

<div class="col-md-8 col-sm-6 col-6 mb-4">
    <p class="d-inline-block mr-3"><strong>Filter quotes</strong></p>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'inProgress'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'inProgress' ? 'btn-info' : 'btn-label-info'">In Progress</button>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'sent'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'sent' ? 'btn-info' : 'btn-label-info'">Sent</button>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'accepted'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'accepted' ? 'btn-info' : 'btn-label-info'">Accepted</button>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'rejected'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'rejected' ? 'btn-info' : 'btn-label-info'">Rejected</button>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'approved'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'approved' ? 'btn-info' : 'btn-label-info'">Approved</button>
    <button class="btn btn-sm btn-pill" (click)="quoteSearchStatus = 'all'; findAllCompanyQuotes(); resetPaginator()" [ngClass]="quoteSearchStatus == 'all' ? 'btn-info' : 'btn-label-info'">All</button>
</div>

    <p *ngIf="!quotes || quotes?.length === 0" class="text-center">No quotes found</p>

  <table [hidden]="!quotes || quotes?.length === 0" mat-table [dataSource]="quotes" matSort matSortActive="created" matSortDisableClear
    matSortDirection="desc">

    <!-- Claim Number Column -->
    <ng-container matColumnDef="quoteNr">
        <th mat-header-cell *matHeaderCellDef>Quote Nr</th>
        <td mat-cell *matCellDef="let row" data-label="Quote Nr">{{ row.quoteNumber }}</td>
    </ng-container>
    

    <!-- Claim Date Column -->
    <ng-container matColumnDef="dateAdded">
        <th mat-header-cell *matHeaderCellDef>Quote date</th>
        <td mat-cell *matCellDef="let row" data-label="Quote date">{{ row.createdAt | date: 'dd MMM yyyy' }}</td>
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef>Client name</th>
        <td mat-cell *matCellDef="let row" data-label="Client name">{{ row.client.firstName }} {{ row.client.lastName }}</td>
    </ng-container>

    <!-- Admin Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" data-label="Status">{{ row.quoteStatus | sentenceCase }}</td>
    </ng-container>

     <!-- View Column -->
     <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" data-label="Actions">

          <ng-container *ngIf="row?.quoteStatus == 'sent'">

            <button 
              *ngIf="(currentUserPermissions.includes('brokerAdmin') || currentUserPermissions.includes('brokerTeamLead') || currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector'))" 
              class="btn btn-light btn-pill color-brand mr-0"
              matTooltip="Edit quote"
              [routerLink]="['/b/quotes/edit', row._id]"
            >
              <i class="fi flaticon-edit mr-0"></i>
            </button>

          </ng-container>
            
            <button
              *ngIf="row?.quoteStatus !== 'accepted' || row?.quoteStatus !== 'sent'"
              class="btn btn-light btn-pill color-label-1"
              [matMenuTriggerFor]="menu"
            >
              <i class="fi flaticon-menu mr-0 fi-rotate-90"></i>
            </button>

            <mat-menu #menu="matMenu">
              <button *ngIf="row?.quoteStatus == 'sent'" mat-menu-item (click)="acceptQuote(row)">Accept quote</button>
              <button *ngIf="row?.quoteStatus == 'accepted'" mat-menu-item [routerLink]="['/b/quotes/approve', row._id]">Approve quote</button>
              <button mat-menu-item (click)="rejectQuote(row._id, row.quoteNumber)">Reject quote</button>
            </mat-menu>

        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [hidden]="!quotes || quotes?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>      
