<div class="data-table" [ngClass]="{'d-none': users?.length === 0}">

    <table mat-table [dataSource]="users" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

      <!-- Item Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First name</th>
        <td mat-cell *matCellDef="let row" data-label="First name">{{ row?.firstName }}</td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last name</th>
        <td mat-cell *matCellDef="let row" data-label="Last name">{{ row?.lastName }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="idNumber">
        <th mat-header-cell *matHeaderCellDef>ID Number</th>
        <td mat-cell *matCellDef="let row" data-label="ID Number">{{ row?.username }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email address</th>
        <td mat-cell *matCellDef="let row" data-label="Email address">{{ row?.email }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef>User type</th>
        <td mat-cell *matCellDef="let row" data-label="User type">{{ row?.userType }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let row" data-label="Active">{{ row?.active ? 'Yes' : 'No' }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef>Cancelled</th>
        <td mat-cell *matCellDef="let row" data-label="Cancelled">{{ row?.cancelled ? 'Yes' : 'No' }}</td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="permissions">
        <th mat-header-cell *matHeaderCellDef>Permissions</th>
        <td mat-cell *matCellDef="let row" data-label="Permissions"><span *ngFor="let permission of row.permissions; let $last = last">{{ permission }}<span *ngIf="!$last">, </span></span></td>
      </ng-container>

      <!-- Insured Value Column -->
      <ng-container matColumnDef="circa">
        <th mat-header-cell *matHeaderCellDef>Member since</th>
        <td mat-cell *matCellDef="let row" data-label="Member since">{{ row?.createdAt | date: 'dd MMM yyyy' }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row" data-label="Action">
            <button class="color-info btn btn-icon btn-label-hover-info mr-0" [matMenuTriggerFor]="menu">
              <i class="fi flaticon-menu fi-rotate-90"></i>
            </button>
    
              <mat-menu #menu="matMenu" xPosition="before">

                <button mat-menu-item disabled="true" (click)="editUser(row.username)">
                  <span>Edit user</span>
                </button>
  
                <button *ngIf="!row.disabled" mat-menu-item (click)="disableUser(row.username)">
                  <span>Disable user</span>
                </button>

                <button *ngIf="row.disabled" mat-menu-item (click)="enableUser(row.username)">
                    <span>Enable user</span>
                </button>

                <button *ngIf="!row.active" mat-menu-item (click)="activateUser(row.username)">
                    <span>Activate user</span>
                </button>

              </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="row table-footer">
      <div class="col-12 col-sm-6 my-auto">
        <ngb-pagination [collectionSize]="resultsLength" [(page)]="page" [boundaryLinks]="true" [pageSize]=pageSize (pageChange)="getAllUsers(false, $event)">
          <ng-template ngbPaginationFirst><i class="fi fi-sm flaticon-caret fi-rotate-90"></i><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
          <ng-template ngbPaginationPrevious><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
          <ng-template ngbPaginationNext><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
          <ng-template ngbPaginationLast><i class="fi fi-sm flaticon-caret fi-rotate-270"></i><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
        </ngb-pagination>
      </div>

      <div class="col-12 col-sm-6 my-auto">
        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div>
    </div> <!-- Close div for .row -->

  </div> <!-- Close div for .data-table -->