<div class="page-description">
    <div class="row h-100">
      <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">My brokers</h5>
        <h6 class="d-inline-block mb-0 font-weight-300">{{ newBrokerForm.get('broker').value.companyName }}</h6>
      </div>
  
      <div class="col-6 text-right align-self-center">
        <button type="button" (click)="location.back()" class="btn btn-secondary">
          Back
        </button>
      </div>
      
    </div>  
  </div>
  
  <div class="row">
    <div class="col-12">
  
      <div class="panel panel-primary no-page-header">
        <form id="newBrokerForm" [formGroup]='newBrokerForm'>
          <div class="panel-heading">
            <h5>Select a new broker</h5>
          </div> <!-- Close div for .panel-heading -->
  
          <div class="panel-body">
  
            <div class="row">
  
              <div class="col-md-4 col-sm-6 col-6 pt-4">
                <strong>Broker company:</strong>
              </div>
  
              <div id="autocomplete" class="col-md-8 col-sm-6 col-6 form-group input-group">
                <mat-form-field>
                  <input type="text" aria-label="Number" matInput class="form-control"
                    formControlName='broker' [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedCompany($event)">
                    <mat-option *ngIf="isLoading" class="is-loading">Finding brokers...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option disabled *ngIf="brokerCompanies.length === 0 && newBrokerForm.get('broker').value">
                        <span>No broker found</span>
                      </mat-option>
                      <mat-option *ngFor="let broker of brokerCompanies" [value]="broker">
                        <span>{{ broker?.companyName }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
  
  
              <!-- SHOW THIS IF NO BROKER FOUND -->
              <div class="col-12 text-right">
                <button type="button" class="btn btn-link" [routerLink]="['/u/my-brokers/new-broker']" [queryParams]="{ r: redirectLink }" *ngIf="!newBrokerForm.get('broker').value.company">
                  Broker company not listed?
                </button>
              </div>
  
              <div class="col-12" *ngIf="newBrokerForm.get('broker').value.companyName">
  
                <div class="row">
  
                  <div class="col-12 p-3 text-center">
                    <h5>Please select a broker from {{ newBrokerForm.get('broker').value.companyName }}</h5>
                  </div>

                  <div class="col-lg-4 col-md-6 col-12" *ngFor="let broker of brokers">

                    <div class="card">

                      <div class="card-header d-flex flex-wrap">

                        <span class="userpic userpic-info userpic-lg mr-3">
                          <span>{{ broker?.firstName.substring(0, 1) }} {{ broker?.lastName.substring(0, 1) }}</span>
                        </span>

                        <div class="d-inline-block my-auto">
                          <h5 class="card-heading m-0">{{ broker?.firstName }} {{ broker?.lastName }}</h5>
                          <span class="card-subheading">{{ broker?.brokerCode || 'No broker code' }}</span>
                        </div>
                          
                      </div>

                      <div class='card-body'>
                        <p>
                          <strong class="color-black">Email:</strong>
                          <span class="float-right color-label-2">{{ broker?.email }}</span>
                            <br />
                          <strong class="color-black">Phone:</strong>
                          <span class="float-right color-label-2">{{ broker?.cellphone }}</span>
                        </p>
                      </div>

                      <div class="card-footer">
                        <button class="btn btn-brand btn-block" (click)="selectBroker(broker)">Select broker</button>
                      </div>

                    </div> <!-- Close div for .card -->

                  </div> <!-- Close div for .col-lg-4 .col-md-6 .col-12 -- BROKER LOOP -->
  
                </div>
  
              </div> <!-- Close div for col-12 -->
  
            </div> <!-- Close div for .row -->
  
          </div> <!-- Close div for .panel-body -->
        </form>
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for .content-->
  