import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ManagementService } from 'src/app/services/management.service';
import { FAQResponse } from 'src/app/_shared/models/faq.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-management-faq',
  templateUrl: './management-faq.component.html',
  styleUrls: ['./management-faq.component.scss']
})
export class ManagementFAQComponent implements OnInit, AfterViewInit {

  public Editor = ClassicEditor;

  submitted = false;
  faqForm: FormGroup;
  get form() { return this.faqForm.controls; }

  constructor(
    private managementService: ManagementService,
    private formBuilder: FormBuilder
  ) { }

  saveNewFaq() {
    this.submitted = true;

    if (this.faqForm.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.managementService.createNewFAQ(this.faqForm.value).subscribe(
      (response:HttpResponse<FAQResponse>) => {
        this.faqForm.reset();
      }
    )
  }

  ngOnInit() {
    this.faqForm = this.formBuilder.group({
      question: ['', Validators.required],
      answer: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
  }

}
