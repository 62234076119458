import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { DeletePersonalItemComponent } from 'src/app/components/modal/delete-personal-item/delete-personal-item.component';
import { PersonalItem, PersonalItemResponse } from 'src/app/_shared/models/personalItem.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-client-personal-insurance',
  templateUrl: './client-personal-insurance.component.html',
  styleUrls: ['./client-personal-insurance.component.scss'],
  providers: [DeletePersonalItemComponent]
})
export class ClientPersonalInsuranceComponent implements OnInit, AfterViewInit {

  personalItems: PersonalItem[];

  displayedColumns: string[] = ['image', 'item', 'category', 'insured', 'action'];
  resultsLength = 0;
  pageSize = 25;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private personalInsuranceService: PersonalInsuranceService,
    private deletePersonalItemModal: DeletePersonalItemComponent,
  ) { }

  getAllHouseholdItems(noLoad?:boolean, eventData?:number){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: eventData ? (eventData * this.pageSize) - 1: this.paginator.pageIndex,
      limit: this.pageSize,
      itemName: '',
      itemCategory: '',
      specified: ''
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.personalInsuranceService.getAllPersonalItems(req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.personalItem;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.personalItems = data);
  }

  deletePersonalItem(personalItem){
    this.deletePersonalItemModal.openDialog(personalItem);
    this.deletePersonalItemModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.personalItems = this.personalItems.filter(item => item != personalItem)
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllHouseholdItems();
  }

}
