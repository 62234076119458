<div id="content" class="row">

    <sub-navigation heading="Reports"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Calendar event report</h5>
        </div>
  
        <div class="panel-body">

            <div class="row form-group" [formGroup]="calendarEventFilters">

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="">Time period</label>
                    <mat-select class="form-control" formControlName="timePeriod" (selectionChange)="createDate($event)">
                        <mat-option value="0">Today</mat-option>
                        <mat-option value="1">Yesterday</mat-option>
                        <mat-option value="7">Last 7 days</mat-option>
                        <mat-option value="30">Last 30 days</mat-option>
                        <mat-option value="365">Last year</mat-option>
                        <mat-option value="">All time</mat-option>
                        <mat-option class="custom" value="custom" disabled>{{ calendarEventFilters.get('dateFrom').value | date: 'dd/MM/yyyy' }} - {{ calendarEventFilters.get('dateTo').value | date: 'dd/MM/yyyy' }}</mat-option>
                    </mat-select>
                    
                    <mat-form-field id="datepicker" appearance="fill">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" formControlName="dateFrom">
                          <input matEndDate placeholder="End date" formControlName="dateTo">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <small class="link py-2" (click)="picker.open(); calendarEventFilters.get('timePeriod').setValue('custom')">Select custom date range</small>

                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="type">Type</label>
                    <mat-select class="form-control" formControlName="type">
                        <mat-option value="">All calendar events</mat-option>
                        <mat-option value="meeting_insurer">Meeting with an insurer</mat-option>
                        <mat-option value="meeting_newClient">Meeting with a new client</mat-option>
                        <mat-option value="meeting_followUp">Follow up meeting</mat-option>
                        <mat-option value="leave">Leave</mat-option>
                        <mat-option value="personal">Personal</mat-option>
                    </mat-select>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="employee">Employee</label>
                    <input type="text"
                        class="form-control"
                        placeholder="Employee"
                        formControlName="employee"
                        [matAutocomplete]="employee">
                    <mat-autocomplete #employee="matAutocomplete" [displayWith]="displayEmployeeName" (optionSelected)="mapEmployee($event)">
                    <mat-option [value]="{_id: 0}">
                        All employees
                    </mat-option>
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{ employee?.firstName }} {{ employee?.lastName }}
                    </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-auto">
                    <button (click)="getCalendarEventReport()" class="btn btn-primary">Apply filters</button>
                </div>

            </div>

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <div class="row">

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ calendarEventTotals?.totalCalendarEvents || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Total calendar events</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ calendarEventTotals?.totalMeetings || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Meetings had</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6" >
                                    <h4 class="color-darker-grey font-weight-500">{{ calendarEventTotals?.totalLeave || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Leave taken</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div> <!-- Close div for .row -->

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <table mat-table [dataSource]="employeesCalendarEvents" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

                <!-- Client Column -->
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>Employee</th>
                    <td mat-cell *matCellDef="let row" data-label="Employee">{{ row?.firstName }} {{ row?.lastName }}</td>
                </ng-container>

                <!-- Client Number Column -->
                <ng-container matColumnDef="totalCalendarEvents">
                    <th mat-header-cell *matHeaderCellDef>Total calendar events</th>
                    <td mat-cell *matCellDef="let row" data-label="Total calendar events">{{ row?.totalCalendarEvents || "0"}}</td>
                </ng-container>

                 <!-- Reference Number Column -->
                 <ng-container matColumnDef="meetings">
                    <th mat-header-cell *matHeaderCellDef>Total meetings had</th>
                    <td mat-cell *matCellDef="let row" data-label="Total meetings had">{{ row?.totalMeetings || "0" }}</td>
                </ng-container>

                <!-- Admin CalendarEvent Approved Date Column -->
                <ng-container matColumnDef="leave">
                    <th mat-header-cell *matHeaderCellDef>Leave taken</th>
                    <td mat-cell *matCellDef="let row" data-label="Leave taken">{{ row?.totalLeave || "0" }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- <mat-paginator [hidden]="calendarEvents?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator> -->

        </div> <!-- Close div for panel-body -->

    </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for content-->