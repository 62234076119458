<div class="col-md-8 col-sm-6 col-6 mb-4">
    <p class="d-inline-block mr-3"><strong>Filter claims</strong></p>
    <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'incomplete'; findAllClaims()" [ngClass]="claimSearchStatus == 'incomplete' ? 'btn-info' : 'btn-label-info'">Incomplete</button>
    <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'completed'; findAllClaims()" [ngClass]="claimSearchStatus == 'completed' ? 'btn-info' : 'btn-label-info'">Completed</button>
    <button class="btn btn-sm btn-pill" (click)="claimSearchStatus = 'all'; findAllClaims()" [ngClass]="claimSearchStatus == 'all' ? 'btn-info' : 'btn-label-info'">All</button>
    </div>

    <table mat-table [dataSource]="claims" matSort matSortActive="created" matSortDisableClear
    matSortDirection="desc">

    <!-- Client Column -->
    <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" data-label="Client">{{ row.client.firstName }} {{ row.client.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="clientNumber">
        <th mat-header-cell *matHeaderCellDef>Client number</th>
        <td mat-cell *matCellDef="let row" data-label="Client number">{{ row?.client?.clientNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef>Reference number</th>
        <td mat-cell *matCellDef="let row" data-label="Reference number">{{ row?.referenceNumber }}</td>
    </ng-container>

    <!-- Claim Type Column -->
    <ng-container matColumnDef="claimType">
        <th mat-header-cell *matHeaderCellDef>Type of claim</th>
        <td mat-cell *matCellDef="let row" data-label="Type of claim">{{ row.claimType | sentenceCase }}</td>
    </ng-container>

    <!-- Admin Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" data-label="Status">{{ row.currentStep | sentenceCase }}</td>
    </ng-container>

    <!-- Admin Instruction Approved Date Column -->
    <ng-container matColumnDef="dateApproved">
        <th mat-header-cell *matHeaderCellDef>Signed off</th>
        <td mat-cell *matCellDef="let row" data-label="Signed off">
            <span *ngIf="row?.claimCompleted?.value">{{ row?.claimCompleted?.completedBy?.firstName }} {{ row?.claimCompleted?.completedBy?.lastName }}<br /><small class="text-muted">on {{ row?.claimCompleted?.dateCompleted | date: "dd MMM yyyy 'at' HH:mm" }}</small></span>
            <span *ngIf="!row?.claimCompleted?.value">Not signed off</span>
        </td>
    </ng-container>

        <!-- View Column -->
        <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" data-label="Actions">
            <!-- <button class="btn btn-light btn-pill color-brand" matTooltip="Edit claim" [routerLink]="['/b/claims/edit', row._id]"><i class="fi flaticon-edit mr-0"></i></button> -->
        </td>
        </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>      