<div class="page-description">
        <div class="row h-100">
          <div class="col-6 align-self-center">
            <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
            <h6 class="d-inline-block mb-0">Cards</h6>
          </div>
        </div>
      </div>
      
      <div class="row">
      
        <div class="col-6 float-left">
      
        <div class="panel panel-primary">
      
            <div class="panel-heading">
              <h5>Basic Card</h5>
            </div>
      
            <div class="panel-body">

                <div class="card">

                    <div class='card-body'>
                        <p>Simple card</p>
                    </div>

                    
                </div>

            </div> <!-- Close div for .panel-body -->
      
        </div> <!-- Close div for .panel -->

        <div class="panel panel-primary">
      
            <div class="panel-heading">
                <h5>Card with image</h5>
            </div>
        
            <div class="panel-body">

                <div class="card">

                    <div class="card-image">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDW9UoKgaPO37yzN39S_4Bl7J2sYuVcqQgoXEAP6n1vwUl7iYJ" class="img-fluid" />
                    </div>

                    <div class='card-body'>
                        <p>Simple card</p>
                    </div>

                </div>

            </div> <!-- Close div for .panel-body -->
        
        </div> <!-- Close div for .panel -->
      
        </div> <!-- Close div for .col-6 -->

        <div class="col-6 float-left">
      
            <div class="panel panel-primary">
        
                <div class="panel-heading">
                <h5>Card with header</h5>
                </div>
        
                <div class="panel-body">
    
                    <div class="card">

                        <div class="card-header">
                            <h5 class="card-heading">Card heading</h5>
                            <span class="card-subheading">Card subheading</span>
                        </div>
    
                        <div class='card-body'>
                            <p>Simple card</p>
                        </div>
    
                        
                    </div> <!-- Close div for .card-->

                    <div class="card mt-3">

                            <div class="card-header d-flex flex-wrap">

                                <span class="userpic userpic-primary mr-3">
                                    <span>HS</span>
                                </span>

                                <div class="d-inline-block">
                                    <h5 class="card-heading">Card heading</h5>
                                    <span class="card-subheading">Card subheading</span>
                                </div>
                                
                            </div>
        
                            <div class='card-body'>
                                <p>Simple card</p>
                            </div>
        
                            
                        </div> <!-- Close div for .card-->
    
                </div> <!-- Close div for .panel-body -->
        
            </div> <!-- Close div for .panel -->

            <div class="panel panel-primary">
        
                <div class="panel-heading">
                    <h5>Card with footer</h5>
                </div>
        
                <div class="panel-body">
    
                    <div class="card">
    
                        <div class='card-body'>
                            <p>Simple card</p>
                        </div>
                        
                        <div class="card-footer text-right">
                            <button class="btn btn-secondary">Cancel</button>
                            <button class="btn btn-brand">Save</button>
                        </div>
    
                    </div> <!-- Close div for .card-->
    
                </div> <!-- Close div for .panel-body -->
        
            </div> <!-- Close div for .panel -->
        
        </div> <!-- Close div for .col-6 -->
      
      </div> <!-- Close div for .row -->
      