import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Input, Output } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { DocumentModel, DocumentResponseModel } from 'src/app/_shared/models/document.model';
import { BrokerDisplayClientDocumentModalComponent } from 'src/app/broker/clients/documents/displayDocumentsModal/broker-display-client-document-modal.component'
import { MatDialog } from '@angular/material/dialog';
import { BrokerEditClientDocumentDetailsComponent } from '../editDocumentDetails/broker-edit-client-document-details.component';

@Component({
  selector: 'broker-view-client-documents',
  templateUrl: './broker-view-client-documents.component.html',
  styleUrls: ['./broker-view-client-documents.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerViewClientDocumentsComponent implements OnInit, AfterViewInit {

  @Input() selectedUser: string = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  @Input() addAsAttachment: boolean = false;

  @Input() emailAttachedFiles: any[] = [];
  @Input() showCompanyDocuments: boolean = false;

  documentFolderStructures = new Set();

  documents: DocumentModel[];
  companyDocuments: any;
  filteredDocuments: DocumentModel[];

  searchDocument:string;

  constructor(
    private documentService: DocumentService,
    private activeRoute: ActivatedRoute,
    private brokerComponent: BrokerComponent,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit():void {
    this.getAllClientDocuments();
    this.getAllCompanyDocuments();
  }

  getAllClientDocuments(){
    this.documentService.getClientDocuments(this.brokerComponent.brokerCompany.companyName, this.selectedUser).subscribe(
      (response:HttpResponse<DocumentResponseModel>) => {
        let documents = response.body.documents.map(document => {
          // let companyName = new RegExp(`([${this.brokerComponent.brokerCompany.companyName}])\\w+/`)
          let userId = new RegExp(`([${this.selectedUser}])\\w+/`)
          document['folderStructure'] = document.documentKey.replace(`${this.brokerComponent.brokerCompany.companyName}/`, '').replace(userId, '').split('/');

          this.documentFolderStructures.add(document['folderStructure'][0])
          this.foldersToDisplay.add(document['folderStructure'][0])

          return document
        });

        this.documents = [...documents];
      }
    )
  }

  getAllCompanyDocuments(){
    this.documentService.getCompanyDocuments(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<any>) => {
        this.companyDocuments = response.body.documents;
      }
    )
  }

  currentFolder:string;
  currentFolderIndex: number = 0;
  filesFound:boolean = false;
  openFolder2(folder: string, folderIndex?:number){

    let startNew = false;

    this.currentFolder = folder;
    this.currentFolderIndex = folderIndex || this.currentFolderIndex;

    if(this.currentFolderIndex < 0) this.currentFolderIndex = 0

    this.documents.map(document => {
      if(document['folderStructure'][this.currentFolderIndex] === folder){

        if(!startNew){
          startNew = true;
          this.documentFolderStructures.clear();
        }

        if(this.currentFolderIndex !== document['folderStructure'].length - 1){
          this.currentFolderIndex++
          this.filteredDocuments = [...this.documents.filter(document => document['folderStructure'][this.currentFolderIndex - 1] === this.currentFolder)]
          this.documentFolderStructures.add(document['folderStructure'][this.currentFolderIndex])
        }

        // REACHED THE END OF THE FOLDER STRUCTURE, WE ARE NOW AT THE FILE
        if(this.currentFolderIndex === document['folderStructure'].length - 1){
          this.filesFound = true;
        }

      }
    })
  }

  filesToDisplay = [];
  foldersToDisplay = new Set();
  folderLevel = [];
  openFolder(folderName: string, level:number){

    if(folderName === 'Company Documents'){
      this.foldersToDisplay.clear();
      this.filesToDisplay = this.companyDocuments;
      this.folderLevel.push('Company Documents');
      this.currentFolderIndex = level;
      return;
    }

    if(!folderName) this.folderLevel = [];
    this.filesToDisplay = []; // HAS TO SEARCH FOR FILES EVERY TIME A FOLDER IS ENTERED

    this.currentFolderIndex = level;

    if(folderName) this.folderLevel.push(folderName);

    // CLEAR THE FOLDERS TO DISPLAY -> SO NEXT LEVEL CAN DISPLAY
    this.foldersToDisplay.clear();

    // CREATE THE FOLDERS TO DISPLAY
    this.documents.map(document => {

      // IF NOT THE END OF THE FOLDERSTRUCTURE REACHED
      if(document.folderStructure.length - 1 > level) {
        if(level <= 1){
          this.foldersToDisplay.add(document.folderStructure[level])
        }

        // IF MATCH TO PREVIOUS
        if(this.folderLevel.length > 1 && document.folderStructure[level - 1] === this.folderLevel[level - 1]){
          // ADD THE FOLDERS TO DISPLAY
            this.foldersToDisplay.add(document.folderStructure[level])
          }

      } else {
        // THIS IS A FILE
        // if(this.folderLevel.length === 1 && (document.folderStructure.length - 1 === level)){
        //   console.log
        //   this.filesToDisplay.push(document)
        // }

        if(this.calculateSameSubstructure(document, this.folderLevel)){
          this.filesToDisplay.push(document)
        }
      }

    })

  }

  calculateSameSubstructure(document, folderStructure){
    let isSame = true;
    for(let i = 0; i <= document.folderStructure.length; i++){

      if(!folderStructure[i] && i === folderStructure.length){
        break;
      }

      if(document.folderStructure[i] != folderStructure[i]){
        isSame = false;
        break;
      }

    }
    return isSame
  }

  goToFolder(folder, level){
    let index = this.folderLevel.findIndex(doc => doc === folder);
    this.folderLevel.splice(index)
    this.openFolder(folder, level)
  }

  viewDocument(document:any){
    this.dialog.open(BrokerDisplayClientDocumentModalComponent, {
      width: '800px',
      data: {
        document
      }
    });
  }

  updateDocumentDetails(document:any){
    let dialogRef = this.dialog.open(BrokerEditClientDocumentDetailsComponent, {
      width: '800px',
      data: {
        document
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response['status'] === 'success'){
        let documentIndex = this.filteredDocuments.findIndex(doc => doc == document);
        let documents = [...this.filteredDocuments];

        documents[documentIndex] = {
          ...documents[documentIndex],
          ...response['data']
        }

        this.filteredDocuments = [...documents];
      }
    })

  }

  getImageSource(extension:string){

    switch(extension){

      // https://www.flaticon.com/free-icon/doc_136521

      case 'pdf':
        return 'https://image.flaticon.com/icons/svg/136/136522.svg'

      case 'doc':
      case 'docx':
        return 'https://image.flaticon.com/icons/svg/136/136521.svg'

      case 'jpeg':
      case 'jpg':
        return 'https://image.flaticon.com/icons/svg/136/136524.svg'

      case 'png':
        return 'https://image.flaticon.com/icons/svg/136/136523.svg'

      case 'xls':
      case 'xlsx':
        return 'https://image.flaticon.com/icons/svg/136/136532.svg'

      case 'zip':
        return 'https://image.flaticon.com/icons/svg/136/136544.svg'

      default:
        return 'https://image.flaticon.com/icons/svg/136/136549.svg'

    }

  }

  filterDocuments(event){

    if(event === ''){
      this.filesFound = false;
      this.openFolder(this.currentFolder, this.currentFolderIndex);
    } else {
      this.filesFound = true;
    }

    const regex = new RegExp(`${event.toLowerCase()}`, 'g');

    this.filteredDocuments = [...this.documents.filter(document => document.documentName.toLowerCase().match(regex) && document.folderStructure[this.currentFolderIndex - 1] === this.currentFolder )]
  }

  downloadDocument(doc){
    console.log('doc => ', doc)
    const blob = new Blob([doc.documentFile.replace(/'%20'/g, '+')], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(blob)

    const downloadLink = document.createElement("a");
    const fileName = doc.documentName;

    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  deleteDocument(){
    // let dialogRef = this.dialog.open(BrokerEditClientDocumentDetailsComponent, {
    //   width: '800px',
    //   data: {
    //     document
    //   }
    // });
  }

  addFileAsEmailAttachment(file){
    this.emailAttachedFiles.push({ documentFile: file.documentFile, documentName: file.documentName });
    file['isAttachment'] = true;
  }

  removeFileFromEmailAttachment(file){
    let attachmentIndex = this.emailAttachedFiles.findIndex(f => f.documentFile === file.documentFile);
    this.emailAttachedFiles.splice(attachmentIndex, 1);
    file['isAttachment'] = false;
  }

}
