import { Structure, StructureResponse } from 'src/app/_shared/models/structure.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { Home, HomeResponse } from 'src/app/_shared/models/homeInsurance.model';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';

@Component({
  template: ''
})
export class DeleteStructureComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(structure: Home): void {

    this.dialogRef = this.dialog.open(DeleteStructureComponentPreview, {
      // width: '600px',
      data: structure
    });
  }

}

@Component({
  templateUrl: './delete-structure.component.html',
  styleUrls: ['./delete-structure.component.scss']
})
export class DeleteStructureComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteStructureComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public structure: Home,
    public personalInsuranceService: PersonalInsuranceService,
    private clientHomeService: BrokerClientHomeService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeStructure(structure: Structure){
    this.personalInsuranceService.removeStructure(structure._id).subscribe(
      (response:HttpResponse<StructureResponse>) => {
        this.closeDialog('success');
      }
    )
  }

  removeHome(structure: any){
    this.clientHomeService.deleteInsuranceFromHome(structure.clientId, structure._id).subscribe(
      (response:HttpResponse<HomeResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
