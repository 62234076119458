import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';
import { ExternalWalls } from './structure.model';

export class HomeContent implements Basemodel {
    constructor(
        public _id: string,
        public userRef: string,
        public address: Address,
        public insurance: Insurance,
        public isBusinessProperty: boolean,
        public residenceType: string,
        public isPrimaryResidence: boolean,
        public isHolidayHome: boolean,
        public yearsLivingAtProperty: number,
        public areaType: string[],
        public externalWalls: ExternalWalls,
        public roof: Roof,
        public floor: Floor,
        public underConstruction: boolean,
        public thatch: Thatch,
        // neighboringProperties: ['', Validators.required], // SAME AS AREATYPE??
        public swimmingPool: boolean,
        public unoccupiedConsecutively: boolean,
        public occupantsDuringDay: string[],
        public isCommune: boolean,
        public structureRef: string,
        public security: Security
    ){}
}

export class Address {
    constructor(
        public addressLine1: string,
        public addressLine2: string,
        public city: string,
        public suburb: string,
        public province: string,
        public country: string,
        public postalCode: string,
        public municipalAccNr?: string,
        public stand?: string,
        public formattedAddress?: string
    ){}
}

export class Insurance {
    constructor(
        public brokerId: string,
        public brokerFirstName: string,
        public brokerLastName: string,
        public companyName: string,
        public contentValue: Number,
        public jeweleryValue: Number,
        public premium: Number,
        public policyNumber: string,
        public inceptionDate: Date,
        public dateAdded: Date,
        public lastEdited: Date
    ){}
}

export class Security {
    constructor(
        public alarmType: string[],
        public burglarBars: string[],
        public securityCameras: boolean,
        public externalSlidingDoor: boolean,
        public outsidePermimeter: string[],
        public securityGates: boolean,
        public securityComplex: boolean
    ){}
}

export class Construction {
    constructor(
	    public brick?: boolean,
	    public cement?: boolean,
	    public corrugatedIron?: boolean,
	    public glass?: boolean,
	    public prefab?: boolean,
	    public tiles?: boolean,
        public wood?: boolean
    ) {}
}

export class Floor {
    constructor(
	    public carpet?: boolean,
	    public sealed?: boolean,
	    public tiles?: boolean,
	    public woodenFloors?: boolean
    ) {}
}

export class Roof {
    constructor(
        public concrete?: boolean,
        public corrugatedIron?: boolean,
        public glass?: boolean,
        public thatch?: boolean,
        public tiles?: boolean,
        public wood?: boolean,
        public asbestos?: boolean
    ){}
}

export class Thatch {
    constructor(
        hasThatch?: boolean,
        treated?: boolean,
        lightningConductorInstalled?: boolean,
        thatchWithin5Meters?: boolean
    ){}
}

export class HomeContentResponse {
    constructor(
        public homeContent: HomeContent[],
        public message: SuccessMessage[],
        public count?: number,
    ){}
}