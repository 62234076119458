import { AuthenticationService } from './../../../../../services/authentication.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { DeleteStructureComponent } from 'src/app/components/modal/delete-structure/delete-structure.component';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { HomeResponse, Home } from 'src/app/_shared/models/homeInsurance.model';
import { BrokerComponent } from 'src/app/broker/broker.component';

@Component({
  selector: 'app-broker-view-user-building-insurance',
  templateUrl: './broker-view-user-building-insurance.component.html',
  styleUrls: ['./broker-view-user-building-insurance.component.scss'],
  providers: [BrokerComponent, DeleteStructureComponent]
})
export class BrokerViewUserBuildingInsuranceComponent implements OnInit, AfterViewInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userStructures: Home[];
  previouslyInsuredUserStructures: Home[];

  displayedColumns: string[] = ['type', 'address', 'insuredValue', 'inceptionDate', 'actions'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  displayedPreviousColumns: string[] = ['type', 'address', 'insuredValue', 'terminationDate', 'actions'];
  resultsLengthPreviousInsure: number = 0;
  pageSizePreviousInsure = 25;
  isRateLimitReachedPreviousInsurance = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    private clientHomeService: BrokerClientHomeService,
    private deleteHomeModal: DeleteStructureComponent,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getUserStructure(event?:any){

    this.isLoadingResults = true;

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReached){
      this.clientHomeService.getAllHomeInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<HomeResponse>) => {

          this.isLoadingResults = false;

          this.userStructures = response.body.home;
          this.resultsLength = response.body.count;

          if(this.userStructures.length < this.pageSize){
            this.isRateLimitReached = true;
          }
        }
      )
    }
  };

  getUserPreviousInsuredStructure(event?:any){

    this.isLoadingResults = true;

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReachedPreviousInsurance){
      this.clientHomeService.getPreviousHomeInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<HomeResponse>) => {

          this.isLoadingResults = false;

          this.previouslyInsuredUserStructures = response.body.home;
          this.resultsLengthPreviousInsure = response.body.count;

          if(this.previouslyInsuredUserStructures.length < this.pageSize){
            this.isRateLimitReachedPreviousInsurance = true;
          }
        }
      )
    }
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserStructure();
    this.getUserPreviousInsuredStructure();
  }

  deleteHome(home:Home){
    home['clientId'] = this.selectedUser;
    this.deleteHomeModal.openDialog(home);
    this.deleteHomeModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userStructures = this.userStructures.filter(item => item != home);
      }
    })
  };

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}