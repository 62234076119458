<div id="register" class="container">

  <div class="row">

    <div class="col-12 text-right">
      <button routerLink="/login" class="btn btn-none color-dark-grey" mat-flat-button>Looking to login?</button>
    </div>

    <div class="col-12 text-center mb-3">
      <img class="logo" src="assets/images/logo_large.png" height="100" />
    </div>

    <div class="col-12">

      <h2 class="color-dark-grey text-center mb-4">
        Register a new broker company
        <small><br />Register now and experience our unique dashboard today.</small>
      </h2>

      <hr class="mt-5 mb-4" />

      <form [formGroup]="registerForm" (ngSubmit)="registerNewBrokerCompany()">

          <div class="row">

              <div class="col-12 col-md-6">
                <!-- First Name -->
                <div class="form-group">
    
                  <label for="companyName">Company name:</label>
                  <input
                    formControlName="companyName"
                    type="text"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': formSubmitted && form.companyName.errors }"
                    placeholder="Company name"
                    required
                  >
    
                  <div *ngIf="formSubmitted && form['companyName'].errors" class="invalid-feedback">
                    <div *ngIf="form['companyName'].errors.required">Please enter your company name.</div>
                  </div>
    
                </div> <!-- Close div for .form-group -->
              </div>
    
            </div> <!-- Close div for .row -->

        <div class="row">

          <div class="col-12 col-md-6">
            <!-- First Name -->
            <div class="form-group">

              <label for="firstName">First name:</label>
              <input
                formControlName="firstName"
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid': formSubmitted && form.firstName.errors }"
                placeholder="First name"
                required
              >

              <div *ngIf="formSubmitted && form['firstName'].errors" class="invalid-feedback">
                <div *ngIf="form['firstName'].errors.required">Please enter your first name.</div>
              </div>

            </div> <!-- Close div for .form-group -->
          </div>

          <div class="col-12 col-md-6">
            <!-- Last Name -->
            <div class="form-group">

              <label for="lastName">Last name:</label>
              <input
                formControlName="lastName"
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid': formSubmitted && form.lastName.errors }"
                placeholder="Last name"
                required
              >

              <div *ngIf="formSubmitted && form['lastName'].errors" class="invalid-feedback">
                <div *ngIf="form['lastName'].errors.required">Please enter your last name.</div>
              </div>

            </div> <!-- Close div for .form-group -->
          </div>

        </div> <!-- Close div for .row -->

        <div class="row">

          <div class="col-12 col-md-6">
            <div class="form-group">

              <label for="email">Email address:</label>
              <input
                formControlName="email"
                type="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': formSubmitted && form.email.errors }"
                placeholder="Email address"
                required
              >

              <div *ngIf="formSubmitted && form['email'].errors" class="invalid-feedback help-block errorMessage">
                <div *ngIf="form['email'].errors.required">Please enter your email.</div>
                <div *ngIf="form['email'].errors.pattern">Please enter a valid email address</div>
              </div>

            </div> <!-- Close div for .form-group -->
          </div>

          <div class="col-12 col-md-6">
              <div class="form-group">
  
                <label for="contactNumber">Contact number:</label>
                <input
                  formControlName="contactNumber"
                  type="text"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': formSubmitted && form.contactNumber.errors }"
                  placeholder="Contact number"
                  required
                >
  
                <div *ngIf="formSubmitted && form['contactNumber'].errors" class="invalid-feedback help-block errorMessage">
                  <div *ngIf="form['contactNumber'].errors.required">Please enter a your company contact number.</div>
                </div>
  
              </div> <!-- Close div for .form-group -->
            </div>

        </div> <!-- Close div for .row -->

        <div class="row">

          <div class="col-12 col-md-6">
            <div class="form-group">

              <label for="brokerCode">Broker code</label>
              <input
                formControlName="brokerCode"
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid': formSubmitted && form.brokerCode.errors }"
                placeholder="Contact number"
                required
              >

              <div *ngIf="formSubmitted && form['brokerCode'].errors" class="invalid-feedback help-block errorMessage">
                <div *ngIf="form['brokerCode'].errors.required">Please enter a your broker code.</div>
              </div>

            </div> <!-- Close div for .form-group -->
          </div>

          <div class="col-12 col-md-6">
            <!-- PASSWORD -->
            <div class="form-group">

              <label for="password">Password:</label>
              <input
                #password
                formControlName="password"
                type="password"
                class="form-control"
                [ngClass]="{ 'is-invalid': formSubmitted && form.password.errors }"
                placeholder="Password"
                required
              >

              <mat-password-strength 
                #passwordStengthMeter
                (onStrengthChanged)="onStrengthChanged($event)"
                [password]="password.value">
              </mat-password-strength>

              <div *ngIf="formSubmitted && form['password'].errors" class="invalid-feedback help-block errorMessage">
                  <div *ngIf="form['password'].errors.required">Password is required.</div>
                  <div *ngIf="form['password'].errors.minlength">Password must be at least 7 characters.</div>
              </div>

              <p class="color-dark-grey smallest mt-1">
                <small>To conform with our Strong Password policy, you are required to use a
                sufficiently strong password. Password must be more than 7 characters.</small>
              </p>

              <mat-password-strength-info
                *ngIf="form['password'].dirty"
                [passwordComponent]="passwordStengthMeter">
              </mat-password-strength-info>

            </div> <!-- Close div for .form-group -->
          </div>


        </div> <!-- Close div for .row -->

        <div class="row">
          <div class="col-12 captcha text-center w-100">
            <re-captcha
              name="captcha"
              [siteKey]="recaptcha_site_key"
              formControlName="captcha"
              (resolved)="resolved($event)"
              required
            ></re-captcha>
            <div *ngIf="formSubmitted && form['captcha'].errors" class="invalid-feedback help-block errorMessage">
              <div *ngIf="form['captcha'].errors.required">Please select the captcha.</div>
            </div>
          </div>
        </div> <!-- Close div for .row -->

        <div class="row">

          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
            <button type="submit" class="btn btn-primary btn-block mt-4 mb-3" mat-flat-button>Create my account</button>
          </div>

          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
            <button routerLink="/login" class="btn btn-none color-dark-grey mb-5" mat-flat-button>Back to login?</button>
          </div>

        </div> <!-- Close div for .row -->

      </form>

    </div> <!-- Close div for .col-12 -->

  </div> <!-- Close div for .row -->

</div> <!-- Close div for .container -->
