import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { HomeResponse, Home } from 'src/app/_shared/models/homeInsurance.model';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'select-new-policy-item-building-component',
  templateUrl: './select-new-policy-item-building-component.component.html',
  styleUrls: ['./select-new-policy-item-building-component.component.scss']
})
export class SelectNewPolicyItemBuildingComponentComponent implements OnInit {

  @Input() clientId:string;
  @Input() companyName:string;
  @Input() selection;

  displayedColumns: string[] = ['select', 'type', 'suburb', 'city', 'insuredValue'];
  policyItems = new MatTableDataSource<Home>();
  // selection = new SelectionModel<Home>(true, []);

  constructor(
    private clientHomeService: BrokerClientHomeService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientAllRiskItems()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.policyItems.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.policyItems.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getClientAllRiskItems(){
    this.clientHomeService.getAllHomeInsuranceForClient(this.clientId, this.companyName).subscribe(
      (response:HttpResponse<HomeResponse>) => {
        this.policyItems = new MatTableDataSource<Home>(response.body.home);
      }
    )
  }

}
