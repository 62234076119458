import { BrokerClaimResponse } from './../../../_shared/models/brokerClaims.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../../services/authentication.service';
import { BrokerClaimsService } from './../../../services/broker-claims.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from './../../../_shared/models/User.model';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-broker-claims-new-claim',
  templateUrl: './broker-claims-new-claim.component.html',
  styleUrls: ['./broker-claims-new-claim.component.scss']
})
export class BrokerClaimsNewClaimComponent implements OnInit {

  currentCompany: string;
  claimsFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private brokerClaimService: BrokerClaimsService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {

    this.currentCompany = this.authenticationService.currentCompany.companyName;

    this.claimsFormGroup = this.formBuilder.group({
      claim: []
    })

  }

  ngOnInit(): void {
  }

  createNewClaim(saveType: string = 'back'){

    // this.formSubmitted = true;
    // // stop here if form is invalid
    if (this.claimsFormGroup.get('claim').invalid) {
      console.warn('form => ', this.claimsFormGroup.get('claim'))
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new claim.')
      return;
    }

    this.brokerClaimService.addNewClaim(this.currentCompany, this.claimsFormGroup.getRawValue().claim).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  goBack(){
    this.location.back()
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.claimsFormGroup.get('claims').reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

}
