<div *ngIf="hasRequestAttached" id="instruction-handover" class="panel panel-primary">
    <div class="row py-3 px-3">
        <div class="col-12 col-md-8 my-auto">
            <h5 class="mb-0">
                <strong *ngIf="request?.whatNeedsToHappen === 'handover'">
                    This instruction is being handed over from {{ instructionFormGroup.get('employee').get('firstName').value }} to you.
                    Do you accept this handover?
                </strong>
                <strong *ngIf="request?.whatNeedsToHappen === 'signOff'">
                    {{ request?.message }}  Do you approve this signoff?
                </strong>
            </h5>
        </div>
        <div class="col-12 col-md-4 text-center text-md-right">
            <button class="btn btn-success text-white" (click)="acceptRequest(request._id)">Accept</button>
            <button class="btn btn-danger text-white" (click)="rejectRequest(request)">Reject</button>
        </div>
    </div> <!-- Close div for .row -->
</div>


<div id="content" class="row" [ngClass]="{'mt-0': hasRequestAttached}">

        <sub-navigation heading="{{ editInstruction ? 'Edit' : 'Add new' }} instruction"></sub-navigation>

        <div class="col-12">

          <div class="panel panel-primary">
            <form id="instructionFormGroup" [formGroup]="instructionFormGroup">

              <div class="panel-heading">
                <h5>Instruction details</h5>

                <div class="my-auto">

                  <button type="button" matTooltip="Go back to view all instructions" class="btn btn-secondary my-auto" (click)="goBack()">
                    <i class="fi flaticon-back"></i>Back
                  </button>

                  <div class="btn-group" ngbDropdown>
                    <button type="submit" class="btn btn-primary" mat-tooltip="Save instruction and go back." (click)="editInstruction ? updateInstruction('back') : addNewInstruction('back')">
                      Save instruction
                    </button>
                    <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      aria-controls="dropdown-basic">
                    </button>
                    <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="editInstruction ? updateInstruction('continue') : addNewInstruction('continue')">Save &amp; continue</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editInstruction ? updateInstruction('new') : addNewInstruction('new')">Save &amp; add new</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="editInstruction ? updateInstruction('back') : addNewInstruction('back')">Save &amp; go back</a></li>
                    </ul>
                  </div> <!-- Close div for .btn-group -->

                </div> <!-- Close div for .my-auto -->

              </div>

              <div class="panel-body">

                    <div class="row">

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group" formGroupName="client">
                                <mat-form-field>
                                    <input
                                      matInput
                                      type="text"
                                      aria-label="Client"
                                      placeholder="Client"
                                      formControlName='display'
                                      [matAutocomplete]="user"
                                    />
                                    <mat-hint [hidden]="instructionForm['client']['controls'].display.dirty">Type a name to start the search</mat-hint>

                                    <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayFn.bind(this)">
                                        <mat-option *ngIf="isLoading" class="is-loading">Finding clients...</mat-option>

                                        <ng-container *ngIf="!isLoading">
                                            <mat-option disabled *ngIf="clients?.length === 0 && instructionFormGroup.get('client').value">
                                                <span>No client found</span>
                                            </mat-option>
                                            <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="mapClient(client)">
                                                <span>{{ client?.firstName }} {{ client?.lastName }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>

                                    <mat-error>
                                        Please select a <strong>client</strong> for this instruction.
                                    </mat-error>

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <mat-select
                                        [disabled]="!instructionFormGroup.get('userRef').value"
                                        name="productType"
                                        formControlName="productType"
                                        placeholder="Product type"
                                        [ngClass]="{ 'is-invalid': formSubmitted && instructionForm['productType'].errors }"
                                    >
                                        <mat-option value="none" disabled>Please select a product</mat-option>
                                        <mat-option value="vehicle">Vehicle</mat-option>
                                        <mat-option value="building">Building</mat-option>
                                        <mat-option value="homeContent">Home content</mat-option>
                                        <mat-option value="allRisk">All risk item</mat-option>
                                        <mat-option value="trailersAndCaravans">Trailers and caravans</mat-option>
                                        <mat-option value="vaps">Value added product</mat-option>
                                        <mat-option value="general">General</mat-option>                                    </mat-select>

                                    <mat-error>
                                        Please select a <strong>product type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-sm-6 col-12 mb-3" *ngIf="instructionFormGroup.get('productType').value !== 'general'">
                            <div class="input-group" formGroupName="product">

                                <!-- FAKE INPUT TO SIMULATE DISABLED -->
                                <mat-form-field [hidden]="instructionFormGroup.get('productType').value">
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="Product"
                                        name="product"
                                        disabled
                                    />
                                    <mat-hint>Select a product to be able to search for it.</mat-hint>
                                </mat-form-field>

                                <mat-form-field [hidden]="!instructionFormGroup.get('productType').value">
                                    <input
                                        matInput
                                        type="text"
                                        aria-label="Product"
                                        placeholder="Product"
                                        formControlName='description'
                                        [matAutocomplete]="product"
                                    />
                                    <mat-hint [hidden]="instructionForm['product']['controls'].description.dirty">Type a {{ instructionFormGroup.get('productType').value | sentenceCase }} to start the search</mat-hint>

                                    <mat-autocomplete autoActiveFirstOption #product="matAutocomplete" [displayWith]="displayFnProd.bind(this)">
                                        <mat-option *ngIf="isLoading" class="is-loading">Finding {{ instructionFormGroup.get('productType').value | sentenceCase }}s...</mat-option>

                                        <ng-container *ngIf="!isLoading">
                                            <mat-option disabled *ngIf="products?.length === 0 && instructionFormGroup.get('product').value">
                                                <span>No {{ instructionFormGroup.get('productType').value }}s found</span>
                                            </mat-option>
                                            <span *ngIf="instructionFormGroup.get('productType').value === 'vehicle'">
                                                <mat-option *ngFor="let product of products" [value]="product.make + ' ' + product.model + ' - ' + product.registrationNumber" (onSelectionChange)="mapVehicle(product)">
                                                    <span>{{ product.make }} {{ product.model }} - {{ product.registrationNumber }}</span>
                                                </mat-option>
                                            </span>
                                            <span *ngIf="instructionFormGroup.get('productType').value === 'trailersAndCaravans'">
                                                <mat-option *ngFor="let product of products" [value]="product.type + ' - ' + product.make + ' ' + product.model" (onSelectionChange)="mapTrailersAndCaravans(product)">
                                                    <span>{{ product.type }} - {{ product.make }} {{ product.model }}</span>
                                                </mat-option>
                                            </span>
                                            <span *ngIf="instructionFormGroup.get('productType').value === 'building' || instructionFormGroup.get('productType').value === 'homeContent'">
                                                <mat-option *ngFor="let product of products" [value]="product?.address?.formattedAddress" (onSelectionChange)="mapBuilding(product)">
                                                    <span>{{ product?.address?.formattedAddress }}</span>
                                                </mat-option>
                                            </span>
                                            <span *ngIf="instructionFormGroup.get('productType').value === 'allRisk'">
                                                <mat-option *ngFor="let product of products" [value]="product?.itemName" (onSelectionChange)="mapAllRisk(product)">
                                                    <span>{{ product?.itemName }}</span>
                                                </mat-option>
                                            </span>
                                            <span *ngIf="instructionFormGroup.get('productType').value === 'vaps'">
                                                <mat-option *ngFor="let product of products" [value]="product?.desctiption" (onSelectionChange)="mapVaps(product)">
                                                    <span>{{ product?.description }}</span>
                                                </mat-option>
                                            </span>
                                        </ng-container>
                                    </mat-autocomplete>

                                    <mat-error>
                                        Please select a <strong>{{ instructionFormGroup.get('productType').value }}</strong> for this instruction.
                                    </mat-error>

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <mat-select
                                        name="instructionType"
                                        placeholder="Instruction type"
                                        formControlName="instructionType"
                                        [ngClass]="{ 'is-invalid': formSubmitted && instructionForm['instructionType'].errors }">
                                        <mat-option value="none" disabled>Please select an instruction type</mat-option>
                                        <mat-option value="new">New product</mat-option>
                                        <mat-option *ngIf="instructionFormGroup.get('productType').value === 'vehicle'" value="existing">Existing product</mat-option>
                                        <mat-option value="delete">Remove product</mat-option>
                                        <mat-option value="general">General</mat-option>
                                    </mat-select>

                                    <mat-error>
                                        Please select an <strong>instruction type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-sm-6 col-12 mb-3">
                            <div class="input-group">
                                <mat-form-field class="mb-0">
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="Instruction description"
                                        name="instructionDescription"
                                    />

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-sm-6 col-12 mb-3">

                              <div class="input-group" formGroupName="employee">
                                <mat-form-field>
                                    <input
                                      matInput
                                      type="text"
                                      aria-label="Employee"
                                      placeholder="Employee"
                                      formControlName='display'
                                      [matAutocomplete]="employee"
                                    />
                                    <mat-hint [hidden]="instructionForm['employee']['controls'].display.dirty">Type a name to start the search</mat-hint>

                                    <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayFnEmployee.bind(this)">
                                        <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>

                                        <ng-container *ngIf="!isLoading">
                                            <mat-option disabled *ngIf="employees?.length === 0 && instructionFormGroup.get('employee').value">
                                                <span>No employee found</span>
                                            </mat-option>
                                            <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="mapEmployee(employee)">
                                                <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>

                                    <mat-error>
                                        Please select an <strong>employee</strong> for this instruction.
                                    </mat-error>

                                </mat-form-field>
                            </div> <!-- Close div for .input-group -->

                        </div>

                        <!-- <div class="col-12 mb-3" formGroupName="instruction" *ngIf="false">

                            <div class="row" *ngFor="let instruction of instructionFormGroup.get('instruction')['controls'] | keyvalue: originalOrder" [formGroupName]="instruction.key">

                                <div class="col-12 col-sm-6 col-md-4 my-auto">
                                    <mat-checkbox [disabled]="editInstruction" disableRipple formControlName="value">{{ instruction.key | sentenceCase : 'SureSpace' }}</mat-checkbox>
                                </div>

                                <div class="col-12 col-sm-6 col-md-8" [ngClass]="{'my-3': editInstruction}">
                                    <mat-form-field [hidden]="editInstruction" >
                                        <textarea matInput placeholder="Additional comments" formControlName="comment"></textarea>
                                    </mat-form-field>
                                    <ng-container *ngIf="editInstruction">
                                        <p class="mb-0">{{ instruction?.value?.value?.comment || 'No comment provided' }}</p>
                                        <small class="text-muted" *ngIf="editInstruction">Completed by: {{ instruction?.value?.value?.brokerRef?.firstName }} {{ instruction?.value?.value?.brokerRef?.lastName }}  - {{ instruction?.value?.value?.date | date: 'dd MMM yyyy HH:mm' }}</small>
                                    </ng-container>
                                </div>

                            </div>
                        </div> -->

                        <div class="col-12 clearfix"></div>
                        

                        <div class="col-12 mb-3">

                            <div class="row" *ngFor="let instruction of instructionFormGroup.get('instructionSteps')['controls']; index as i">
                                <ng-container [formGroup]="instruction">

                                    <div class="col-12 col-sm-6 col-md-6 my-auto">
                                        <mat-checkbox disableRipple formControlName="stepStatus" [disabled]="editInstruction && (currentInstructionSteps && currentInstructionSteps[i]?.stepStatus)">{{ instruction?.get('stepDescription')?.value }}</mat-checkbox>
                                        <small class="text-muted" *ngIf="editInstruction && instruction?.get('stepStatus')?.value"><br />Completed by: {{ instruction.get('completedBy').get('firstName').value }} {{ instruction.get('completedBy').get('lastName').value }}  - {{ instruction.get('dateCompleted').value | date: 'dd MMM yyyy HH:mm' }}</small>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-6" [ngClass]="{'my-3': editInstruction }">
                                        <mat-form-field >
                                            <textarea [readonly]="editInstruction && (currentInstructionSteps && currentInstructionSteps[i]?.stepStatus)" matInput placeholder="Additional comments" formControlName="stepComment"></textarea>
                                        </mat-form-field>
                                    </div>

                                </ng-container>

                            </div>
                        </div>

                    </div> <!-- Close div for .row -->
              </div> <!-- Close div for .panel-body -->

            </form>
          </div> <!-- Close div for .panel -->

        </div> <!-- Close div for .col-12 -->

      </div> <!-- Close div for #content -->
