import { BrokerService } from './../../services/broker.service';
import { HttpResponse } from '@angular/common/http';
import { BrokerReturnedDebitService } from './../../services/broker-returned-debit.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReturnedDebitOrders } from './../../_shared/models/returnedDebitOrders.model';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-broker-returned-debits',
  templateUrl: './broker-returned-debits.component.html',
  styleUrls: ['./broker-returned-debits.component.scss']
})
export class BrokerReturnedDebitsComponent implements OnInit {

  returnedDebits: any;

  currentUser = this.authenticationService.currentUserValue;
  currentCompany = this.authenticationService.currentCompany.companyName;
  currentUserPermissions: any[];

  displayedColumns: string[] = ['client', 'clientNr', 'refNr', 'policy', 'status', 'dateApproved', 'view'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  companyEmployees: any;
  userToGetDebits: string = this.authenticationService.currentUserValue._id;

  returnedDebitSearch:string = '';

  returnedDebitSearchStatus: string = 'incomplete';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authenticationService: AuthenticationService,
    private brokerReturnedDebitService: BrokerReturnedDebitService,
    private brokerService: BrokerService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit(): void {
    this.findAllReturnedDebits();
    this.getAllCompanyEmployees();
  }

  selectFinalisedFilter(){
    this.returnedDebitSearchStatus = 'finalised';
    this.userToGetDebits = 'allCompanyEmployees';
    this.findAllReturnedDebits()
  }

  findAllReturnedDebits(searchParam:string = '', noLoad:boolean = false, completed = this.returnedDebitSearchStatus){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam,
      completed: completed,
      user: this.userToGetDebits
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.brokerReturnedDebitService.getAllReturnedDebits(this.currentCompany, req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.returnedDebit;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.returnedDebits = data);
  }

  getAllCompanyEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.companyEmployees = response.body.user.filter(e => e._id !== this.currentUser._id);
    })
  }

  isAdmin(){
    return this.currentUserPermissions.includes('brokerDirector') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager');
  }

}
