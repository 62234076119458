import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sub-navigation',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss']
})
export class SubNavComponent implements OnInit {

  default: string = this.getDefaultHeading();

  @Input() heading: string = this.default;
  @Input() subheading: string;

  constructor(
    private router: Router
  ) {
  }

  getDefaultHeading(){
    let route = this.router.url.split('/').pop();

    if(route.includes(';')){
      route = route.split(';')[0]
    }

    // Make the string sentence case
    return route.replace(/[a-z]/i, (first) => {
      return first.toUpperCase();
    }).trim();
  }

  ngOnInit() {
  }

}
