import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { BrokerQuote, BrokerQuoteResponse } from '../_shared/models/brokerQuotes.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerQuoteService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  saveQuoteAsDraft(companyName: string, quoteFormData:BrokerQuote): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.post<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/draft/company/${companyName}`, quoteFormData, {
      observe: 'response',
    });
  }

  saveNewQuote(companyName: string, quoteFormData:BrokerQuote): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.post<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/company/${companyName}`, quoteFormData, {
      observe: 'response',
    });
  }

  updateQuote(companyName: string, quoteId: string, quoteFormData: BrokerQuote): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.put<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/quote/${quoteId}/company/${companyName}`, quoteFormData, {
      observe: 'response',
    });
  }

  getAllQuotes(companyName: string, req?: any, noLoad?:boolean): Observable<HttpResponse<BrokerQuoteResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/all/company/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getAllClientQuotes(companyName: string, clientId:string, req?: any, noLoad?:boolean): Observable<HttpResponse<BrokerQuoteResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/company/${companyName}/user/${clientId}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getSelectedQuote(companyName: string, quoteId: string): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.get<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/quote/${quoteId}/company/${companyName}`, {
      observe: 'response',
    });
  }

  cancelQuote(quoteId: string): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.delete<BrokerQuoteResponse>(`${this.globals.SERVER_URL}/api/broker/quotes/cancel/quote/${quoteId}`, {
      observe: 'response',
    });
  }

  rejectQuote(quoteId: string, rejectedReason: string): Observable<any>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/broker/quotes/reject/quote/${quoteId}`, { rejectedReason }, {
      observe: 'response'
    })
  }

  approveQuote(quoteFormGroup: any): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/broker/quotes/approve/quote`, quoteFormGroup, {
      observe: 'response'
    })
  }

  acceptQuote(quoteId: string): Observable<HttpResponse<BrokerQuoteResponse>>{
    return this.http.put<any>(`${this.globals.SERVER_URL}/api/broker/quotes/accept/quote/${quoteId}`, null, {
      observe: 'response'
    })
  }

}
