import { HttpResponse } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { BrokerService } from 'src/app/services/broker.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-broker-reporting-quotes',
  templateUrl: './broker-reporting-quotes.component.html',
  styleUrls: ['./broker-reporting-quotes.component.scss']
})
export class BrokerReportingQuotesComponent implements OnInit {

  quoteFilters: FormGroup;

  quoteTotals: any;
  employeesQuotes: any;

  employees: Employee[];
  filteredEmployees: Observable<Employee[]>;

  displayedColumns: string[] = ['employee', 'totalQuotes', 'acceptedQuotes', 'rejectedQuotes'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService
  ) {

    this.quoteFilters = this.formBuilder.group({
      timePeriod: [""],
      dateFrom: [""],
      dateTo: [""],
      status: [""],
      employee: [""],
      employeeId: [""]
    })

  }

  ngOnInit(): void {
    this.getEmployees();
    this.getQuoteReport();
  }

  getQuoteReport(){
    this.reportingService.getQuoteReports(this.authService.currentCompany.companyName, this.quoteFilters.value).subscribe(response => {
      this.quoteTotals = response.body.totals;
      this.employeesQuotes = response.body.employees.polciesPerEmployee;
    })
  }

  getEmployees() {
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;

        this.filteredEmployees = this.quoteFilters.get('employee').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    )
  };

  private _filter(value: string): Employee[] {
    if(typeof value !== 'string') return;

    const filterValue = value.toLowerCase();
    return this.employees.filter(employee => employee.firstName.toLowerCase().includes(filterValue) || employee.lastName.toLowerCase().includes(filterValue));
  }

  displayEmployeeName(employee: Employee) {
    return employee.lastName ? `${employee?.firstName} ${employee?.lastName}` : 'All employees'
  }

  mapEmployee(event:MatAutocompleteSelectedEvent):void {
    if(event.option.value._id === 0){ return this.quoteFilters.get('employeeId').setValue("")}
    this.quoteFilters.get('employeeId').setValue(event.option.value._id)
  }

  createDate(selection){

    if(selection.value === 'custom') return;

    if(!selection.value || selection.value == ""){
      this.quoteFilters.get('dateTo').setValue("");
      this.quoteFilters.get('dateFrom').setValue("");
    } else {
      let today = moment();

      this.quoteFilters.get('dateTo').setValue(today.toDate());
      this.quoteFilters.get('dateFrom').setValue(today.subtract(Number(selection.value), "days").toDate());
    }

  }

}
