import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { BrokerReturnedDebitService } from 'src/app/services/broker-returned-debit.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse } from '@angular/common/http';
import { DocumentResponseModel, DocumentModel } from 'src/app/_shared/models/document.model';
import { DialogData } from 'src/app/components/modal/delete/delete.component';
import { debounceTime, tap, switchMap, finalize, map, takeUntil } from 'rxjs/operators';
import { BrokerService } from 'src/app/services/broker.service';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { PolicyResponse } from 'src/app/_shared/models/policy.model';
import { BrokerClaimsService } from 'src/app/services/broker-claims.service';
import { BrokerAdminInstructionService } from 'src/app/services/broker-admin-instruction.service';
import { fromEvent } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-broker-upload-any-document-modal',
  templateUrl: './broker-upload-any-document-modal.component.html',
  styleUrls: ['./broker-upload-any-document-modal.component.scss'],
  providers: [ BrokerComponent ]
})
export class BrokerUploadAnyDocumentModalComponent implements OnInit {

  @ViewChild('itemsAutoComplete') itemsAutoCompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  formSubmitted: boolean = false;
  uploadDocumentFormGroup: FormGroup;

  uploader: FileUploader;

  brokerCompany = this.brokerComponent.brokerCompany.companyName;
  uploadEmailDocument: boolean = false;

  isLoading: boolean = false;
  items: any = [];

  totalItems: number = 0;
  offset: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<BrokerUploadAnyDocumentModalComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private documentService: DocumentService,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent,
    private claimsService: BrokerClaimsService,
    private instructionService: BrokerAdminInstructionService,
    private returnedDebitOrderService: BrokerReturnedDebitService,
    private brokerQuoteService: BrokerQuoteService
  ) {
    this.uploader = new FileUploader({})
  }

  ngOnInit(): void {
    this.uploadDocumentFormGroup = this.formBuilder.group({
        documentFile: [null, Validators.required],
        documentName: [null],
        emailReference: [null],
        documentItemType: [null, Validators.required],
        documentDescription: [null],
        itemRef: [null],
        documentItem: [null],
        itemDisplayName: [null]
    })


    this.uploadDocumentFormGroup
    .get('documentItemType')
      .valueChanges
      .pipe(
        tap(() => this.isLoading = true),
        debounceTime(300),
        switchMap(value => {

          if(value === 'policies')
            return this.brokerService.getPoliciesPerUser(this.brokerCompany, this.data.selectedUser, null, true).pipe(finalize(() => this.isLoading = false))

          if(value === 'instructions')
            return this.instructionService.getAllInstructionsPerUser(this.brokerCompany, this.data.selectedUser, { completed: 'incomplete' }).pipe(finalize(() => this.isLoading = false))

          if(value === 'claims')
            return this.claimsService.getAllClaimsPerUser(this.brokerCompany, this.data.selectedUser, { limit: 99999 }).pipe(finalize(() => this.isLoading = false))
          
            if(value === 'returnedDebit')
            return this.returnedDebitOrderService.getAllReturnedDebitsPerUser(this.brokerCompany, this.data.selectedUser).pipe(finalize(() => this.isLoading = false))

            if(value === 'quotations')
            return this.brokerQuoteService.getAllClientQuotes(this.brokerCompany, this.data.selectedUser, { status: ['sent', 'inProgress', 'accepted'] }).pipe(finalize(() => this.isLoading = false))


          return

        })
      )
      .subscribe(
        (response: HttpResponse<PolicyResponse>) => {
          let value = this.uploadDocumentFormGroup.get('documentItemType').value;

          if(value === 'policies'){
            this.totalItems = response.body.count;
            this.offset = response.body['policies'].length;
            this.items = response.body['policies'];
          }
            

          if(value === 'instructions'){
            this.totalItems = response.body.count
            this.offset = response.body['instruction'].length;
            this.items = response.body['instruction'];
          }

          if(value === 'claims'){
            this.totalItems = response.body.count;
            this.offset = response.body['claim'].length;
            this.items = response.body['claim'];
          }

          if(value === 'returnedDebit'){
            this.totalItems = response.body.count;
            this.offset = response.body['returnedDebit'].length;
            this.items = response.body['returnedDebit'];
          }

          if(value === 'quotations'){
            this.totalItems = response.body.count;
            this.offset = response.body['quotes'].length;
            this.items = response.body['quotes'];
          }

        }
      )
  }

  getAllPoliciesPerUser(){

    if(this.items.length === this.totalItems) return;

    let req = {
      completed: 'incomplete',
      offset: this.offset,
      limit: 10
    }

    this.brokerService.getPoliciesPerUser(this.brokerCompany, this.data.selectedUser, req).subscribe(response => {
      
      this.offset = this.offset + response.body.policies.length;
      
      this.items = [
        ...this.items,
        ...response.body.policies
      ]
    })
  }

  getAllInstructionsPerUser(){

    if(this.items.length === this.totalItems) return;

    let req = {
      completed: 'incomplete',
      offset: this.offset,
      limit: 10
    }

    this.instructionService.getAllInstructionsPerUser(this.brokerCompany, this.data.selectedUser, req).subscribe(response => {
      
      this.offset = this.offset + response.body.instruction.length;
      
      this.items = [
        ...this.items,
        ...response.body.instruction
      ]
    })
  }

  getAllClaimsPerUser(){

    if(this.items.length === this.totalItems) return;

    let req = {
      completed: 'incomplete',
      offset: this.offset,
      limit: 10
    }

    this.claimsService.getAllClaimsPerUser(this.brokerCompany, this.data.selectedUser, req).subscribe(response => {
      
      this.offset = this.offset + response.body.claim.length;
      
      this.items = [
        ...this.items,
        ...response.body.claim
      ]
    })
  }

  autocompleteScroll() {
    setTimeout(() => {
      if (
        this.itemsAutoCompleteRef &&
        this.autocompleteTrigger &&
        this.itemsAutoCompleteRef.panel
      ) {
        fromEvent(this.itemsAutoCompleteRef.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.itemsAutoCompleteRef.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe(x => {
            const scrollTop = this.itemsAutoCompleteRef.panel.nativeElement.scrollTop;
            const scrollHeight = this.itemsAutoCompleteRef.panel.nativeElement.scrollHeight;
            const elementHeight = this.itemsAutoCompleteRef.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;
            if (atBottom) {
              if(this.uploadDocumentFormGroup.get('documentItemType').value === 'instructions'){
                this.getAllInstructionsPerUser();
              }
              
              if(this.uploadDocumentFormGroup.get('documentItemType').value === 'claims'){
                this.getAllClaimsPerUser();
              }

              if(this.uploadDocumentFormGroup.get('documentItemType').value === 'policies'){
                this.getAllPoliciesPerUser();
              }
            }
          });
      }
    });
  }

  displayFn(item?: any) {

    console.log('item => ', item)
    if(item && item.instructionType){
      return `${item.instructionType} - ${item.product.description ? item.product.description + ' - ' : ''}${item.referenceNumber}`
    }

    if(item && item.policyStartDate){
      return `${item.policyNumber}`
    }

    if(item && item.hasOwnProperty('claimType')){
      return `${item.claimType} - ${item.claimDescription} - ${item.product.description}`
    }

    if(item && item.returnedDebitDate){
      return `${item.referenceNumber} - ${item.policyNumber}`
    }

    if(item && item.quoteNumber){
      return `${item?.quoteNumber} - ${item?.client?.lastName || item?.client?.companyName}`;
    }

    return ''
  }

  documentItemTypeSelected(event){
    console.log('event => ', event)
  }

  handleUploadedFile(file:any){
    const documentFile = file[0];

    this.uploadDocumentFormGroup.get('documentFile').setValue(documentFile)
    this.uploadDocumentFormGroup.get('documentName').setValue(documentFile.name)
  }

  uploadFile(){

    this.formSubmitted = true;
    this.uploadDocumentFormGroup.markAllAsTouched();

    if(this.uploadDocumentFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Could not save file.')
      return;
    }

    if(this.uploadEmailDocument){
      this.uploadDocumentFormGroup.get('documentItemType').setValue('email');
    }

    let formData = new FormData();
    let controls = this.uploadDocumentFormGroup.controls;

    // MAKE FORMDATA
    for (let key in controls){
      if(key !== 'documentFile' && key !== 'itemRef') {
        formData.append(key, JSON.stringify(controls[key].value))
      }
    };

    formData.append('file',  this.uploadDocumentFormGroup.get('documentFile').value);
    
    if(this.uploadDocumentFormGroup.get('documentItem').value && this.uploadDocumentFormGroup.get('documentItem').value._id)
      formData.append('itemRef', JSON.stringify(this.uploadDocumentFormGroup.get('documentItem').value._id));

    this.documentService.uploadDocument(formData, this.brokerCompany, this.data.selectedUser).subscribe(
      (response: HttpResponse<DocumentModel>) => {
        this.closeModal();
      }
    )

  }

  closeModal(){
    this.dialogRef.close();
  }

  clearDocumentItem(): void{
    this.uploadDocumentFormGroup.get('documentItem').reset();
  }

}
