import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class FamilyMember implements Basemodel {
    constructor(
        public _id: string,
        public name?: string,
        public surname?: string,
        public relationship?: string,
        public idNumber?: string,
        public anniversaryDate?: Date,
        public email?: string,
        public cellphone?: string,
        public homePhone?: string,
        public addedOn?: Date,
        public driversLicence?: string,
        public userRef?: string
    ){}
  }

export class FamilyMemberResponse {
    constructor(
        public family: FamilyMember[],
        public message?: SuccessMessage
    ) {}
}