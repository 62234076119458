import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../app.globals';
import { ValueAddedProduct, ValueAddedProductResponse } from '../_shared/models/vaps.model';

@Injectable({
  providedIn: 'root'
})
export class ValueAddedProductsService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientVaps(clientId:string, productFormData:ValueAddedProduct, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.post<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientVapsQuote(clientId:string, companyName: string, productFormData:ValueAddedProduct, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.post<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/company/${companyName}/quote`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientVapsQuote(clientId:string, productId:string, companyName: string, productFormData:ValueAddedProduct, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.put<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/company/${companyName}/quote/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllVapsForClient(clientId:string, companyName: string, req:any = {}): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.get<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousVapsForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.get<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/previouslyInsured/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleVapsForClient(clientId:string, productId:string, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.get<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/product/${productId}`, {
      observe: 'response',
      params: req
    })
  }

  updateVaps(clientId:string, productId:string, productFormData:ValueAddedProduct, req?:any): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.put<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/product/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteVaps(clientId:string, productId:string): Observable<HttpResponse<ValueAddedProductResponse>>{
    return this.http.delete<ValueAddedProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/valueAddedProducts/client/${clientId}/product/${productId}`, {
      observe: 'response'
    })
  }

}
