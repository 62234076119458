import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    let argument = new RegExp(args[0], 'g');
    return value.replace(argument, args[1]);
  }

}