<div class="modal-header">
  <h6 class="mb-0"><strong>Delete investment?</strong></h6>
</div>
  
<div class="modal-body">
  <div mat-dialog-content>
    <p class="mb-4 mt-4">
        Are you sure you want to permanently delete <strong>{{ investment.type }}</strong> investment?
        <br />
        This action cannot be undone.
    </p>
  </div>
</div>

<div class="modal-footer">
  <div class="inline-buttons">
    <button cdkFocusInitial class="btn btn-secondary mr-2" (click)="closeDialog('cancel')"><i class="fi flaticon-cancel"></i>Cancel</button>
    <button class="btn btn-danger" (click)="deleteInvestment(investment)"><i class="fi flaticon-trash"></i>Delete</button>
  </div>
</div>
