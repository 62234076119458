import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router"
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/_shared/models/User.model';
import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { RecaptchaComponent } from 'ng-recaptcha'; 

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  userData: User;
  formSubmitted = false;
  registerForm: FormGroup;
  password: string;
  passwordStrength: number;

  validId: boolean = true;

  registrar:string = this.activeRoute.snapshot.paramMap.get('type');

  private reCaptchaResponse = new BehaviorSubject<string>('');
  ureCaptchaResponseid = this.reCaptchaResponse.asObservable();
  @ViewChild('reCaptchaRef', {static: false}) reCaptchaRef:RecaptchaComponent;

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private router: Router,
    private verifyId: IdNumberVerifyService,
    private activeRoute: ActivatedRoute
  ) { };  

  // convenience getter for easy access to form fields
  get form(){ return this.registerForm.controls; }

  onFormChanges(): void {
    this.registerForm.valueChanges.subscribe(value => {
      let verifyId: any = this.verifyId.validateIdNumber(value['username']);
      this.validId = verifyId.isValid;
    });
  }

  registerNewUser(){
    
    this.reCaptchaResponse.subscribe(captcha => {

      let verifyId: any = this.verifyId.validateIdNumber(this.registerForm.value['username']);
      this.validId = verifyId.isValid;

      this.formSubmitted = true;
      this.form.captcha.setValue(captcha);
      this.form.gender.setValue(verifyId.gender);
      this.form.title.setValue(this.registerForm.value['gender'] === 'Male' ? 'Mr' : 'Mrs');
  
      // stop here if form is invalid
      if (this.registerForm.invalid || !this.validId) {
        window.scroll({ top: 0, behavior: 'smooth' });
        return;
      }

      if(this.registrar === 'broker'){
        this.form.company['controls'].status.setValue('active');
      }
  
      this.authentication.registerNewUser(this.registerForm.value).subscribe(
        (response:HttpResponse<any>) => {
          this.router.navigate(['/login']);
        }, 
        (error:HttpErrorResponse) => {
          this.reCaptchaRef.reset();
        }
      );

    })

  };

  onStrengthChanged(strength: number) {
    this.passwordStrength = strength;
  }

  resolved(captchaResponse: string) {
    // this.registerForm.value['captcha'] = captchaResponse;
    this.reCaptchaResponse.next(captchaResponse);
  };

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      title:            [''],
      firstName:        ['', Validators.required],
      lastName:         ['', Validators.required],
      username:         ['', [Validators.required, Validators.minLength(13)]],
      email:            ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z2-9.-]+\.[a-z]{2,4}$')]],
      password:         ['', [Validators.required, Validators.minLength(7)]],
      // confirmPassword:  [''],
      gender:           [''],
      language:         ['English'],
      company: this.formBuilder.group({
        companyName: '',
        companyBrokerCode: '',
        status: '',
        employeeCode: ['']
      }), // TODO: Set required to true if registrar === 'broker
      //JUST CREATE DIFFERENT COMPONENTS DUMBASS
      userType:         [this.registrar],
      insuranceType: this.formBuilder.group({
        householdInsurance: false,
        vehicleInsurance: false,
        buildingInsurance: false,
        personalItemInsurance: false
      }),
      captcha:          [''],
    });

    this.onFormChanges();

  };

}
