import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { BrokerReturnedDebitService } from 'src/app/services/broker-returned-debit.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'broker-view-user-returned-debit',
  templateUrl: './broker-view-user-returned-debit.component.html',
  styleUrls: ['./broker-view-user-returned-debit.component.scss']
})
export class BrokerViewUserReturnedDebitComponent implements OnInit {

  returnedDebits: any;

  selectedUser = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');

  currentUser = this.authenticationService.currentUserValue;
  currentCompany = this.authenticationService.currentCompany.companyName;
  currentUserPermissions: any[];

  displayedColumns: string[] = ['client', 'clientNr', 'refNr', 'policy', 'status', 'dateApproved', 'view'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  returnedDebitSearch:string = '';

  returnedDebitSearchStatus: 'all'|'completed'|'incomplete' = 'incomplete';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authenticationService: AuthenticationService,
    private brokerReturnedDebitService: BrokerReturnedDebitService,
    private activeRoute: ActivatedRoute
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit(): void {
    this.findAllReturnedDebits();
  }

  findAllReturnedDebits(searchParam:string = '', noLoad:boolean = false, completed = this.returnedDebitSearchStatus){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam,
      completed: completed
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.brokerReturnedDebitService.getAllReturnedDebitsPerUser(this.currentCompany, this.selectedUser, req, true)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.returnedDebit;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.returnedDebits = data);
  }

}
