import { InvestmentResponse, Investment } from './../_shared/models/investment.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvestmentService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllInvestments(user?: string, req?:any): Observable<HttpResponse<InvestmentResponse>>{

    req ? req : req = {};
    if(user) req['user'] = user;

    return this.http.get<InvestmentResponse>(`${this.globals.SERVER_URL}/api/investments`, {
      params: req,
      observe: 'response'
    });
  };

  getCurrentInvestment(investment:string): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.get<InvestmentResponse>(`${this.globals.SERVER_URL}/api/investments/${investment}`, {
      observe: 'response'
    })
  }

  addNewInvestment(user:string, investment:Investment): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.post<InvestmentResponse>(`${this.globals.SERVER_URL}/api/investments/${user}`, investment, {
      observe: 'response'
    })
  }

  saveInvestment(user:string, investment:Investment): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.put<InvestmentResponse>(`${this.globals.SERVER_URL}/api/investments/${user}`, investment, {
      observe: 'response'
    })
  }

  removeInvestment(investment): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.delete<InvestmentResponse>(`${this.globals.SERVER_URL}/api/investments/${investment.userRef}`, {
      params: investment,
      observe: 'response'
    })
  }

}
