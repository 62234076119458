<div class="modal-header">
    <h6 class="mb-0"><strong>Delete company address?</strong></h6>
</div>
    
<div class="modal-body">
    <div mat-dialog-content>
        <p class="mb-4 mt-4">
            You are about to delete <strong>{{ data.addressDescription }}</strong> as a company address.
            <br />
            Are you sure? This action cannot be undone.
        </p>
    </div>
    </div>
    
    <div class="modal-footer">
    <div class="inline-buttons">
        <button cdkFocusInitial class="btn btn-secondary mr-2" (click)="closeDialog('cancel')"><i class="fi flaticon-cancel"></i>Cancel</button>
        <button class="btn btn-danger" (click)="deleteLocation()"><i class="fi flaticon-trash"></i>Remove</button>
    </div>
</div>