import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { BrokerReturnedDebitService } from './../../../services/broker-returned-debit.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ReturnedDebitOrdersResponse } from 'src/app/_shared/models/returnedDebitOrders.model';

@Component({
  selector: 'app-broker-returned-debit-edit-debit',
  templateUrl: './broker-returned-debit-edit-debit.component.html',
  styleUrls: ['./broker-returned-debit-edit-debit.component.scss']
})
export class BrokerReturnedDebitEditDebitComponent implements OnInit {

  currentCompany: string;
  returnedDebitFormGroup: FormGroup;

  selectedReturnedDebit = this.activeRoute.snapshot.paramMap.get('returnedDebitId');

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private location: Location,
    private returnedDebitService: BrokerReturnedDebitService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;

    this.returnedDebitFormGroup = this.formBuilder.group({
      returnedDebitOrder: []
    })
  }

  ngOnInit(): void {
    this.getCurrentReturnedDebit();
  }

  saveReturnedDebit(saveType: string = 'back'){
    delete this.returnedDebitFormGroup.get('returnedDebitOrder').value._id

    // this.formSubmitted = true;
    // // stop here if form is invalid
    if (this.returnedDebitFormGroup.get('returnedDebitOrder').invalid) {
      console.warn('form => ', this.returnedDebitFormGroup)
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new returned debit.')
      return;
    }

    this.returnedDebitService.editReturnedDebit(this.currentCompany, this.selectedReturnedDebit, this.returnedDebitFormGroup.getRawValue().returnedDebitOrder).subscribe(
      (response:HttpResponse<ReturnedDebitOrdersResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  getCurrentReturnedDebit(){
    this.returnedDebitService.getSelectedReturnedDebit(this.currentCompany, this.selectedReturnedDebit).subscribe(
      (response:HttpResponse<ReturnedDebitOrdersResponse>) => {
        this.returnedDebitFormGroup.get('returnedDebitOrder').patchValue(response.body.returnedDebit)        
      }
    )
  }

  goBack(){
    this.location.back()
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.returnedDebitFormGroup.get('returnedDebitOrder').reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  goToClientFile(){
    let client = this.returnedDebitFormGroup.get('returnedDebitOrder').value.client;

    if(client.companyName){
      this.router.navigate(['/b/clients/commercial/edit', client._id], { queryParams: { file: '9' } })
    } else {
      this.router.navigate(['/b/clients/personal/edit', client._id], { queryParams: { file: '9' } })
    }
  }

}
