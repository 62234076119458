import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemoveService } from '../../../services/remove.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '../../../app.globals'

export interface DialogData {
  selectedUser: string;
  deleteWhat: string;
  selectedItem: any
}

@Component({
  template: ''
})
export class DeleteModalComponent implements OnInit {

  selectedItem: any;
  deleteWhat: string;
  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(deleteWhat: string, item): void {
    this.deleteWhat = deleteWhat;
    this.selectedItem = item;

    this.dialogRef = this.dialog.open(DeleteModalPreview, {
      width: '600px',
      data: {deleteWhat: deleteWhat, selectedItem: item}
    });

    this.dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnInit() {
  }

}

@Component({
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteModalPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteModalPreview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public removeService: RemoveService,
    private router: Router,
    public globals: Globals,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete(whatToDelete, itemToDelete){

    if(whatToDelete.toLowerCase() === 'family member'){
      this.removeService.removeSelectedFamilyMember(itemToDelete._id).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/myAccount']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

    // if(whatToDelete.toLowerCase() === 'broker'){
    //   this.removeService.removeSelectedBroker(itemToDelete._id).subscribe(
    //     (response:HttpResponse<any>) => {
    //       this.dialogRef.close('success');
    //       this.alert.success(response.body.message)
    //       this.router.navigate(['/myAccount']);
    //     },
    //     (error:HttpErrorResponse) => {
    //       this.alert.error(error)
    //       this.dialogRef.close('failure');
    //       window.scroll({ top: 0, behavior: 'smooth' });
    //     }
    //   )
    // };

    if(whatToDelete.toLowerCase() === 'insurance company'){
      this.removeService.removeSelectedInsuranceCompany(itemToDelete._id).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/myAccount']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

    if(whatToDelete.toLowerCase() === 'household content'){
      this.removeService.removeSelectedHouseholdItem(itemToDelete._id).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/shortTermInsurance']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

    if(whatToDelete.toLowerCase() === 'personal item'){
      this.removeService.removePersonalItem(itemToDelete._id).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/shortTermInsurance']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

    if(whatToDelete.toLowerCase() === 'vehicle'){
      this.removeService.removeSelectedVehicle(itemToDelete.registrationNumber).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/shortTermInsurance']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

    if(whatToDelete.toLowerCase() === 'insured structure'){
      this.removeService.removeSelectedStructure(itemToDelete._id).subscribe(
        (response:HttpResponse<any>) => {
          this.dialogRef.close('success');
          this.router.navigate(['/shortTermInsurance']);
        },
        (error:HttpErrorResponse) => {
          this.dialogRef.close('failure');
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      )
    };

  }

}
