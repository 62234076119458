import { Component, OnInit, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany, InsuranceCompanyResponse} from 'src/app/_shared/models/insuranceCompany.model';
import { HouseholdInsurance, HouseholdInsuranceResponse } from 'src/app/_shared/models/householdInsurane.model';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-new-household-insurance',
  templateUrl: './client-new-household-insurance.component.html',
  styleUrls: ['./client-new-household-insurance.component.scss']
})
export class ClientNewHouseholdInsuranceComponent implements OnInit, AfterViewInit {

  brokers: Broker[];
  customBrokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  isLinear = false;

  householdFormGroup: FormGroup;
  formSubmitted = false;
  get form(){ return this.householdFormGroup.controls; }

  maxDate = new Date();

  constructor(
    private insuranceService: InsuranceService,
    private personalInsurance: PersonalInsuranceService,
    private router: Router,
    private formBuilder: FormBuilder,
    public location: Location,
    private toastr: ToastrService
  ) { }

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;

        this.getCustomBrokers()

      }
    )
  };

  getCustomBrokers(){
    this.insuranceService.getCustomBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.customBrokers = response.body.broker;
      }
    )
  };

  getAllInsuranceCompanies(){
    this.insuranceService.getAllInsuranceCompanies().subscribe(
      (response: HttpResponse<InsuranceCompanyResponse>) => {
        this.insuranceCompanies = response.body.insuranceCompany;
      }
    )
  };

  saveHousholdInsurance(saveType?:string){

    this.formSubmitted = true;

    // stop here if form is invalid
    if(this.householdFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There were some errors on your form.  Please confirm everything is correct and save again.', 'Could not save information.')
      return;
    }

    this.personalInsurance.addHouseholdInsurance(this.householdFormGroup.value).subscribe(
      (response: HttpResponse<HouseholdInsuranceResponse>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            // this.resetForm()
            break;

          default:
            break;
        }

      }
    );
  };

  addNewBroker(){
    localStorage.setItem('SureSpace-EditHouseholdInsurance', JSON.stringify(this.householdFormGroup.value))
    this.router.navigate([`/u/my-brokers/add-broker`, { r: this.router.url }])
  }

  goBack(){
    this.router.navigate(['/u/short-term-insurance/household'])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-EditHouseholdInsurance')){
      this.householdFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-EditHouseholdInsurance')));
      localStorage.removeItem('SureSpace-EditHouseholdInsurance');
    }
  };  

  ngOnInit() {
    this.householdFormGroup = this.formBuilder.group({
      addressLine1:   ['', Validators.required],
      addressLine2:   [''],
      suburb:         ['', Validators.required],
      city:           ['', Validators.required],
      postalCode:     ['', Validators.required],
      security: this.formBuilder.group({
        breakin: false,
        alarm: false,
        electricFence: false,
        occupied: false,
        neighbours: false,
        burglarBars: false,
        accessControl: false
      }),
      insuredWith:    ['', Validators.required],
      premium: ['', Validators.required],
      policyNumber:   ['', Validators.required],
      dateInsured:    [''],
      insuredHouseholdValue: ['', Validators.required],
      quote: false
    })

  };

  ngAfterViewInit(){
    this.getAllBrokers();
    // this.getAllInsuranceCompanies();
    this.getLocalStorageItem();
  }

  comparyBrokerCompany(broker1: Broker, broker2: Broker){
    return broker1.company == broker2.company;
  }

}
