import { Company } from './../../../_shared/models/User.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from 'src/app/services/broker.service';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';
import { BrokerComponent } from 'src/app/broker/broker.component';

@Component({
  template: ''
})
export class DeleteEmployeeComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(employee: Employee): void {
    this.dialogRef = this.dialog.open(DeleteEmployeeComponentPreview, {
      // width: '600px',
      data: employee
    });
  }

}

@Component({
  templateUrl: './delete-employee.component.html',
  styleUrls: ['./delete-employee.component.scss'],
  providers: [BrokerComponent]
})
export class DeleteEmployeeComponentPreview {

  employeeCompany: string = this.brokerComponent.brokerCompany.companyName

  constructor(
    public dialogRef: MatDialogRef<DeleteEmployeeComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public employee: Employee,
    public brokerService: BrokerService,
    private brokerComponent: BrokerComponent
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeEmployee(employee: Employee){
    console.log('employee => ', employee)
    this.brokerService.removeEmployee(employee, this.employeeCompany).subscribe(
      (response:HttpResponse<EmployeeResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
