import { FormGroup, FormBuilder } from '@angular/forms';
import { BrokerDeleteCompanyLocationComponent } from './address/deleteAddress/broker-delete-company-location.component';
import { BrokerEditCompanyLocationComponent } from './address/editAddress/broker-edit-company-location.component';
import { BrokerNewCompanyLocationComponent } from './address/newAddress/broker-new-company-location.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from './../../../services/authentication.service';
import { BrokerService } from './../../../services/broker.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-broker-company-settings',
  templateUrl: './broker-company-settings.component.html',
  styleUrls: ['./broker-company-settings.component.scss']
})
export class BrokerCompanySettingsComponent implements OnInit {

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean = false;

  uploadedLogo = null;
  currentLogo = null;

  selectedTabIndex: number = 0;
  companyLocations:any = [];

  companyDetailsFormGroup: FormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.uploader = new FileUploader({
      allowedFileType: ['image']
    })

    this.getCompanyDetails();
    this.getCurrentCompanyLogo();
    this.getAllLocations();
  }

  ngOnInit(): void {
    this.uploader.onAfterAddingFile = (fileItem) => {
      let url = (window.URL) ? window.URL.createObjectURL(fileItem._file) : (window as any).webkitURL.createObjectURL(fileItem._file);
      this.uploadedLogo = url
    }

    this.companyDetailsFormGroup = this.formBuilder.group({
      registrationNumber: [''],
      fspNumber: ['']
    })
  }

  clearUploaderQueue(){
    return this.uploader.clearQueue();
  }

  getCurrentCompanyLogo(){
    this.brokerService.getCompanyLogo(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.currentLogo = response.body['logo'];
    })
  }

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  updateCompanyLogo(file){
    const documentFile = file.file.rawFile;

    let formData = new FormData();
    formData.append('file', documentFile)

    this.brokerService.saveCompanyLogo(this.authenticationService.currentCompany.companyName, formData).subscribe(response => {
      console.log('response')
    })
  }

  onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    if(file.type.split('/')[0] !== 'image'){
      this.toastr.error('Only image files are supported.  Please upload an image file.', 'Filetype not supported!')
      return
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }

  addNewLocation(){
    const dialogRef = this.dialog.open(BrokerNewCompanyLocationComponent, { width: '800px' });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.getAllLocations()
      }
    });
  }

  editLocation(location){
    const dialogRef = this.dialog.open(BrokerEditCompanyLocationComponent, { width: '800px', data: location });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result.response === 'success'){
        this.companyLocations = result.data
      }
    });
  }

  deleteLocation(location){
    const dialogRef = this.dialog.open(BrokerDeleteCompanyLocationComponent, { width: '800px', data: location });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.companyLocations = [...this.companyLocations].filter(l => l._id != location._id);
      }
    });
  }

  getAllLocations(){
    this.brokerService.getCompanyAddress(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.companyLocations = response.body['locations']
    })
  }

  saveCompanyDetails(){
    this.brokerService.saveCompanyDetails(this.authenticationService.currentCompany.companyName, this.companyDetailsFormGroup.value).subscribe(response => {})
  }

  getCompanyDetails(){
    this.brokerService.getCompanyDetails(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.companyDetailsFormGroup.patchValue(response.body['companyDetails'])
    })
  }

}
