import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class AllRiskItem implements Basemodel {
  constructor(
    public _id: string,
    public itemName: string,
    public itemDescription: string,
    public itemCategory: string,
    public itemRoom: string,
    public purchaseDate: Date,
    public purchasePrice: Number,
    public warranty: Number,
    public itemSerial: string,
    public itemQuantity: Number,
    public specified: boolean,
    public storePurchased: string,
    public insurance: Insurance,
    public userRef: string,
    public dateAdded: Date,
    public lastEdited: Date
  ) {}
}

export class Insurance {
    constructor(
        public brokerId: string,
        public brokerFirstName: string,
        public brokerLastName: string,
        public companyName: string,
        public insuredValue: Number,
        public premium: Number,
        public policyNumber: string,
        public inceptionDate: Date,
        public dateAdded: Date,
        public lastEdited: Date
    ){}
}


export class AllRiskItemResponse {
  constructor(
		public item: AllRiskItem[],
		public count: number,
		public message: SuccessMessage[] 
  ) {}
}

