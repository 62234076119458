import { Component, OnInit } from '@angular/core';
import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { ActivatedRoute } from '@angular/router';
import { BrokerService } from 'src/app/services/broker.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'broker-view-client-family-members',
  templateUrl: './broker-view-client-family-members.component.html',
  styleUrls: ['./broker-view-client-family-members.component.scss']
})
export class BrokerViewClientFamilyMembersComponent implements OnInit {

  clientId = this.activeRoute.snapshot.paramMap.get('id');

  familyMembers: FamilyMember[] = [];

  constructor(
    private brokerService: BrokerService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getClientFamilyMember();
  }

  getClientFamilyMember(){
    this.brokerService.getClientFamilyMembers(this.clientId).subscribe(
      (response: HttpResponse<FamilyMemberResponse>) => {
        console.log('family members => ', response)
        this.familyMembers = response.body.family
      }
    )
  }

  deleteFamilyMember(memberId: string){

  }

}
