<div id="content" class="row">

    <sub-navigation heading="Approve quote"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
        <form id="quoteFormGroup" [formGroup]="quoteFormGroup">

            <mat-horizontal-stepper linear #quoteStepper>

                <mat-step label="Details">

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                        <div class="row mat-stepper-body">

                            <div class="col-12">
                                <h5 class="mb-4"><strong>Add quote details</strong></h5>
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Client:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">
                                    <input class="form-control" readonly [value]="quoteFormGroup.get('client')?.value?.firstName + ' ' + quoteFormGroup.get('client')?.value?.lastName">
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"><br /></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Quote number:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">
                                    <input class="form-control" readonly [value]="quoteFormGroup.get('quoteNumber').value">
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Policy number:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">
                                    <mat-form-field>
                                        <mat-select #quotePolicy placeholder="Policy number" formControlName="policyNumber">
                                            <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                                <span>{{ policy?.policyNumber }}</span>
                                            </mat-option>

                                            <mat-option class="px-0 py-0" style="height: unset">
                                                <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                                            </mat-option>
                                        </mat-select>

                                        <mat-error>
                                            Please select a <strong>policy number</strong> for this quote.
                                        </mat-error>
        
                                    </mat-form-field>

                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mt-3">
                            </div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Policy inception date:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <mat-form-field>
                                    <input 
                                        matInput
                                        name="inceptionDate"
                                        [matDatepicker]="inceptionDate"
                                        name="inceptionDate"
                                        formControlName="inceptionDate"
                                        placeholder="Policy inception date"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #inceptionDate></mat-datepicker>
            
                                    <mat-error>
                                        Please provide the <strong>policy inception date</strong>.
                                    </mat-error>
            
                                </mat-form-field>
                            </div>

                        </div> <!-- Close div for .mat-stepper-body -->

                        <div class="mat-stepper-footer">
                            <button class="btn btn-secondary btn-bg" (click)="goBack()">Cancel</button>
                            <button class="btn btn-primary btn-bg float-right mr-0" (click)="insureProducts()">Next Step</button>
                        </div>

                    </div>

                </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step [completed]="showNextStep" label="Vehicle insurance" *ngIf="quoteFormGroup.get('vehicles')['controls'].length > 0">

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Vehicle insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">
        
                                    <div class="row" *ngFor="let product of quoteFormGroup.get('vehicles')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <div class="input-group">
                                                <input class="form-control" readonly [value]="returnVehicleDescription(quoteFormGroup.controls.vehicles.controls[i])">
                                            </div> <!-- Close div for .input-group -->
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.vehicles.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the vehicle.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <hr />
                                        </div>

                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step [completed]="showNextStep" label="Trailer and Caravan insurance" *ngIf="quoteFormGroup.get('trailersAndCaravans')['controls'].length > 0">

                    <div class="row" >

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Trailer and Caravan insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">
        
                                    <div class="row" *ngFor="let product of quoteFormGroup.get('trailersAndCaravans')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <div class="input-group">
                                                <input class="form-control" readonly [value]="returnTrailersAndCaravansDescription(quoteFormGroup.controls.trailersAndCaravans.controls[i])">
                                            </div> <!-- Close div for .input-group -->
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.trailersAndCaravans.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the product.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <hr />

                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step [completed]="showNextStep" label="Home insurance" *ngIf="quoteFormGroup.get('home')['controls'].length > 0">

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Home insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">

                                    <div class="row" *ngFor="let product of quoteFormGroup.get('home')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <div class="input-group">
                                                <input class="form-control" readonly [value]="quoteFormGroup.controls.home.controls[i].get('address').get('formattedAddress').value">
                                            </div> <!-- Close div for .input-group -->
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.home.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the content.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <hr />  
                                        </div>

                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step [completed]="showNextStep" label="Home content insurance" *ngIf="quoteFormGroup.get('homeContent')['controls'].length > 0">

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Home content insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">

                                    <div class="row" *ngFor="let product of quoteFormGroup.get('homeContent')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <div class="input-group">
                                                <input class="form-control" readonly [value]="quoteFormGroup.controls.homeContent.controls[i].get('address').get('formattedAddress').value">
                                            </div> <!-- Close div for .input-group -->
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.homeContent.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the home content.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <hr />  
                                        </div>

                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step [completed]="showNextStep" label="All risk insurance" *ngIf="quoteFormGroup.get('allRisk')['controls'].length > 0">

                    <div class="row" >

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>All risk insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">

                                    <div class="row" *ngFor="let product of quoteFormGroup.get('allRisk')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8 text-left">
                                            {{ quoteFormGroup.controls.allRisk.controls[i].get('itemName').value }}
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item serial:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="text" placeholder="Serial number" [formControl]="quoteFormGroup.controls.allRisk.controls[i].get('itemSerial')" />
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.allRisk.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the item.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="inceptionDate">Inception date:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input 
                                                    matInput
                                                    name="inceptionDate"
                                                    [matDatepicker]="inceptionDate"
                                                    name="inceptionDate"
                                                    formControlName="inceptionDate"
                                                >
                                                <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #inceptionDate></mat-datepicker>
                                            
                                                <mat-error>
                                                    Please provide the <strong>item inception date</strong>.
                                                </mat-error>
                                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                           <hr /> 
                                        </div>

                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step [completed]="showNextStep" label="Value added product insurance" *ngIf="quoteFormGroup.get('valueAddedProducts')['controls'].length > 0">

                    <div class="row" >

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Value added product insurance</strong></h5>
                                </div>
            
                                <div class="col-12 text-left text-sm-right">
        
                                    <div class="row" *ngFor="let product of quoteFormGroup.get('valueAddedProducts')['controls']; index as i">

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Item:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <div class="input-group">
                                                <input class="form-control" readonly [value]="returnVapsDescription(quoteFormGroup.controls.valueAddedProducts.controls[i])">
                                            </div> <!-- Close div for .input-group -->
                                        </div>

                                        <div class="col-12 clearfix mb-3"></div>

                                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                                            <label for="itemName">Premium:</label>
                                        </div>
            
                                        <div class="col-12 col-sm-7 col-md-8">
                                            <mat-form-field>
                                                <input matInput type="number" placeholder="Premium" [formControl]="quoteFormGroup.controls.valueAddedProducts.controls[i].get('insurance').get('premium')" />
                                                <span matPrefix>R</span>
                                                <mat-error>
                                                    Please provide the <strong>monthly premium</strong> of the value added product.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                          <hr />  
                                        </div>
                                        
                                    </div>
        
                                </div>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step label="Submit">

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Submit changes:</strong></h5>
                                </div>

                            </div>

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button class="btn btn-success btn-bg float-right mr-0" (click)="approveQuote()">Finish</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row -->

                </mat-step>

            </mat-horizontal-stepper>

        </form>
      </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->

  </div> <!-- Close div for #content -->