import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { CalendarEventResponse, CalendarEventModel } from '../_shared/models/calendarEvent.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  createNewCalendarEvent(event:CalendarEventModel, companyName: string): Observable<HttpResponse<CalendarEventResponse>>{
    return this.http.post<CalendarEventResponse>(`${this.globals.SERVER_URL}/api/calendar/event/${companyName}`, event, {
      observe: 'response'
    });
  }

  getAllCalendarEvents(companyName:string, startDate, endDate): Observable<HttpResponse<CalendarEventResponse>>{

    // startDate = formatDate(startDate, 'dd/MM/yyyy', 'en');
    // endDate = formatDate(endDate, 'dd/MM/yyyy', 'en');

    return this.http.get<CalendarEventResponse>(`${this.globals.SERVER_URL}/api/calendar/allevents/${companyName}/${startDate}/${endDate}`, {
      observe: 'response'
    });
  }

  getAllEventsPerUser(userId:string, companyName:string, startDate, endDate): Observable<HttpResponse<CalendarEventResponse>>{
    return this.http.get<CalendarEventResponse>(`${this.globals.SERVER_URL}/api/calendar/events/${userId}/${companyName}/${startDate}/${endDate}`, {
      observe: 'response'
    });
  }

  deleteEvent(eventId: string): Observable<HttpResponse<CalendarEventResponse>>{
    return this.http.delete<CalendarEventResponse>(`${this.globals.SERVER_URL}/api/calendar/event/${eventId}`, {
      observe: 'response'
    });
  }

  editCalendarEvent(eventId: string, event:CalendarEventModel): Observable<HttpResponse<CalendarEventResponse>>{
    return this.http.put<CalendarEventResponse>(`${this.globals.SERVER_URL}/api/calendar/event/${eventId}`, event, {
      observe: 'response'
    });
  }

}
