<div id="content" class="row">

    <sub-navigation heading="Client policies"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">

            <div class="panel-heading">
                <div class="row w-100">
                    <div class="col-12 col-sm-6">
                        <h5>Policies</h5>
                    </div>
                    <div class="col-12 col-sm-6 text-right">
                        <app-add-button
                            *ngIf="canViewItem" 
                            class="mr-0"
                            [routerLink]="['new']"
                            buttonText="Add new policy"
                            matTooltip="Add a new client policy"
                        ></app-add-button>
                    </div>
                </div>
                <!-- Close div for .row -->

            </div>

            <div class="panel-body">

                <div class="mb-5 col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                        <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="userSearch" (ngModelChange)="findAllPolicies('all', $event, true)" />
                        <mat-hint>
                            Search in all fields.
                        </mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-md-8 col-sm-6 col-6 mb-4">
                    <p class="d-inline-block mr-3"><strong>Filter policies</strong></p>
                    <button class="btn btn-sm btn-pill" (click)="findAllPolicies('active')" [ngClass]="policySearchStatus === 'active' ? 'btn-info' : 'btn-label-info'">Active policies</button>
                    <button class="btn btn-sm btn-pill" (click)="findAllPolicies('cancelled')" [ngClass]="policySearchStatus === 'cancelled' ? 'btn-info' : 'btn-label-info'">Cancelled policies</button>
                    <button class="btn btn-sm btn-pill" (click)="findAllPolicies('all')" [ngClass]="policySearchStatus === 'all' ? 'btn-info' : 'btn-label-info'">All policies</button>
                </div>

                <table mat-table [dataSource]="policies" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

                        <!-- Policy Holder Column -->
                        <ng-container matColumnDef="policyNumber">
                            <th mat-header-cell *matHeaderCellDef>Policy number</th>
                            <td mat-cell *matCellDef="let row" data-label="Policy number">{{row?.policyNumber}}</td>
                        </ng-container>

                        <!-- Policy Number Column -->
                        <ng-container matColumnDef="policyHolder">
                            <th mat-header-cell *matHeaderCellDef>Policy holder</th>
                            <td mat-cell *matCellDef="let row" data-label="Policy holder">{{row?.userRef?.companyName}}{{row?.userRef?.firstName}} {{row?.userRef?.lastName}}</td>
                        </ng-container>

                        <!-- Policy Number Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let row" data-label="Status">
                                <span class="label label-inline font-weight-bold" [ngClass]="row?.active?.isActive ? 'label-light-primary' : 'label-light-danger'">
                                    {{ row?.active?.isActive ? 'Active' : 'Cancelled' }}
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="inceptionDate">
                            <th mat-header-cell *matHeaderCellDef>{{ policySearchStatus === 'active' ? 'Inception date' : 'Termination date' }}</th>
                            <td mat-cell *matCellDef="let row" data-label="Termination Date">{{ row?.policyStartDate || row?.policyTerminationDate | date: 'dd MMM yyyy'}}
                            </td>
                        </ng-container>

                        <!-- View Column -->
                        <ng-container matColumnDef="view">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let row" data-label="Actions">
                                <button *ngIf="currentUserPermissions.includes('brokerClaimsTechnician')" class="btn btn-light btn-pill color-brand" matTooltip="View policy details" [routerLink]="['view', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
                                <button *ngIf="canViewItem" class="btn btn-light btn-pill color-brand" matTooltip="Edit policy details" [routerLink]="['edit', row._id]"><i class="fi flaticon-edit mr-0"></i></button>
                                <button *ngIf="canViewItem" class="btn btn-light btn-pill color-danger" matTooltip="Deactivate policy" (click)="deactivatePolicy(row._id)"><i class="fi flaticon-trash mr-0"></i></button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <p *ngIf="policies?.length === 0" class="text-center mt-3">No policies found</p>
                <mat-paginator [hidden]="policies?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

            </div>
            <!-- Close div for .panel-body -->

        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->