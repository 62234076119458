import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { HttpResponse } from '@angular/common/http';
import { HomeResponse, Home } from 'src/app/_shared/models/homeInsurance.model';
import { ToastrService } from 'ngx-toastr';
import { BrokerService } from 'src/app/services/broker.service';
import { PolicyResponse, Policy } from 'src/app/_shared/models/policy.model';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { HomeContentResponse, HomeContent } from 'src/app/_shared/models/homeContentInsurance.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-new-edit-client-home-content-insurance',
  templateUrl: './broker-new-edit-client-home-content-insurance.component.html',
  styleUrls: ['./broker-new-edit-client-home-content-insurance.component.scss']
})
export class BrokerNewEditClientHomeContentInsuranceComponent implements OnInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @Input() view:boolean

  routerPath: string = this.activeRoute.routeConfig.path;
  editContent:boolean = this.routerPath.includes('edit');

  clientId:string = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  homeContentId = this.activeRoute.snapshot.paramMap.get('homeContentId');

  homeContent: HomeContent[];

  insuredBuildings: Home[];
  insuredBuildingSelect: Home;

  formSubmitted = false;
  homeContentFormGroup: FormGroup;

  isLoading: boolean = false;

  policies: Policy[];
  brokers: Employee[];

  insuredPreviousBuildingContent: boolean = false

  wallMaterial: string[] = ['brick', 'cement', 'corrugatedIron', 'glass', 'prefab', 'asbestos', 'wood'];
  roofMaterial: string[] = ['concrete', 'corrugatedIron', 'glass', 'thatch', 'tiles', 'wood', 'asbestos'];
  floorMaterial: string[] = ['carpet', 'sealed', 'tiles', 'woodenFloors'];
  securityAlarmType: string[] = ['none', 'alarm', 'radioLinkedAlarm', 'armedResponse', 'maintenance' ];
  securityBurglarBars: string[] = ['none', 'allWindows', 'openingOnly'];
  securityOutsidePerimeter: string[] = ['none', 'walls', 'palisades', 'electricFence', 'barbedWire'];
  dailyOccupants: string[] = ['householdMember', 'domesticEmployee', 'tenant', 'nobody', 'insured', 'outstanding', 'familyMember', 'other']

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  dialogRef = null;

  redirectTo: string = this.activeRoute.snapshot.queryParams['r'];
  isQuoteProduct: boolean;

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private clientHomeService: BrokerClientHomeService,
    private clientHomeContentService: BrokerClientHomeContentService,
    private brokerService: BrokerService,
    private router: Router,
    private dialog: MatDialog,
    public location: Location,
    private authService: AuthenticationService
  ) {
    this.isQuoteProduct = this.activeRoute.snapshot.queryParams['quote'] === 'true';
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (!control.value && (this && this.isQuoteProduct)) {
      return { 'required': true };
    }
    return null;
  }

  ngOnInit(): void {

    this.homeContentFormGroup = this.formBuilder.group({
      address: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        unit: [''],
        city: [''],
        suburb: [''],
        province: [''],
        country: ['South Africa'],
        postalCode: [''],
        municipalAccNr: [''],
        stand: [''],
        formattedAddress: ['']
      }),
      insurance: this.formBuilder.group({
        companyName: [''], // TODO: Add Validator this.insuranceRequired
        contentValue: [''], // TODO: Add Validator this.insuranceRequired
        jeweleryValue: [''], // TODO: Add Validator this.insuranceRequired
        premium: [''], // TODO: Add Validator this.insuranceRequired
        policyNumber: [''], // TODO: Add Validator this.insuranceRequired
        inceptionDate: [''], // TODO: Add Validator this.insuranceRequired
        excess: [''],
        accidentalDamage: [''],
        powerSurge: ['']
      }),
      isBusinessProperty: '',
      residenceType: ['', Validators.required],
      isPrimaryResidence: '',
      isHolidayHome: '',
      yearsLivingAtProperty: ['', Validators.required],
      areaType: ['', Validators.required],
      externalWalls: ['', Validators.required],
      roof: ['', Validators.required],
      floor: ['', Validators.required],
      underConstruction: false,
      thatch: this.formBuilder.group({
        hasThatch: false,
        lightningConductorInstalled: false,
        treated: false,
        thatchWithin5Meters: false
      }),
      // neighbouringProperties: ['', Validators.required], // SAME AS RESIDENCETYPE??
      swimmingPool: false,
      unoccupiedConsecutively: false,
      occupantsDuringDay: ['', Validators.required],
      isCommune: false,
      structureRef: null,
      security: this.formBuilder.group({
        alarmType: ['', Validators.required],
        burglarBars: ['', Validators.required],
        securityCameras: ['', Validators.required],
        externalSlidingDoor: ['', Validators.required],
        outsidePermimeter: ['', Validators.required],
        securityGates: ['', Validators.required],
        securityComplex: ['', Validators.required]
      }),
      isQuoteProduct: false,
    });

    if(this.isQuoteProduct){
      this.homeContentFormGroup.get('isQuoteProduct').setValue(true);
    }
  }

  getClientPolicies(){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, this.clientId, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  selectedPreviousBuilding(event, modal){

    if(!event.checked){
      this.homeContentFormGroup.get('structureRef').setValue('')
      return;
    }

    const options: MatDialogConfig = {
      width: '800px',
      disableClose: true
    }

    this.getAllClientBuildings();
    this.dialogRef = this.dialog.open(modal, options);

    this.dialogRef.afterClosed().subscribe(result => {
      if(result === 'cancel'){
        this.insuredPreviousBuildingContent = false;
      }
    });

  }

  selectedInsuredBuildingForContent(building){
    let formData = {...building, structureRef: building._id}
    delete formData._id;
    delete formData.insurance;

    this.homeContentFormGroup.patchValue(formData);
    // this.homeContentFormGroup.get('insurance').reset();
    this.dialogRef.close('success')
  }

  ngAfterViewInit(): void {

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicies();
    }

    this.getLocalStorageItem();

    if(this.editContent){
      this.getCurrentContent(this.homeContentId);
    }

    this.homeContentFormGroup.get('insurance').get('companyName').setValue(this.authService.currentCompany.companyName);
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  getAllClientBuildings(){
    this.clientHomeService.getAllHomeInsuranceForClient(this.clientId, this.authService.currentCompany.companyName).subscribe(
      (response:HttpResponse<HomeResponse>) => {
        this.insuredBuildings = response.body.home;
      }
    )
  }

  addNewPolicy(){
    localStorage.setItem('SureSpace-HomeContent', JSON.stringify(this.homeContentFormGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-HomeContent')){
      this.homeContentFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-HomeContent')));
      localStorage.removeItem('SureSpace-HomeContent');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  addNewContent(saveType?:string){

    if(this.homeContentFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Failed saving home.')
      return
    }

    if(!this.homeContentFormGroup.get('address').get('formattedAddress').value){
      let homeFormGroupAddress = this.homeContentFormGroup.get('address');
      let addressLine1 = homeFormGroupAddress.get('addressLine1').value;
      let suburb = homeFormGroupAddress.get('suburb').value;
      let city = homeFormGroupAddress.get('city').value;
      let postalCode = homeFormGroupAddress.get('postalCode').value;
      homeFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
      return;
    }

    this.clientHomeContentService.addNewClientHomeContentContentInsurance(this.clientId, this.homeContentFormGroup.value).subscribe(
      (response: HttpResponse<HomeContentResponse>) => {
        this.location.back()
      }
    )
  }

  addNewQuotedProduct(){
    this.clientHomeContentService.addNewClientHomeContentQuote(this.clientId, this.authService.currentCompany.companyName, this.homeContentFormGroup.value).subscribe(
      (response: HttpResponse<HomeContentResponse>) => {
        let newHomeContent = response.body.homeContent;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newHomeContent))
        this.location.back()
      }
    )
  }

  editQuotedProduct(){
    this.clientHomeContentService.editClientHomeContentQuote(this.clientId, this.homeContentId, this.authService.currentCompany.companyName, this.homeContentFormGroup.value).subscribe(
      (response: HttpResponse<HomeContentResponse>) => {
        let newHomeContent = response.body.homeContent;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newHomeContent))
        this.location.back()
      }
    )
  }

  getCurrentContent(homeContentId:string){
    this.clientHomeContentService.getSingleHomeContentInsuranceForClient(this.clientId, homeContentId).subscribe(
      (response:HttpResponse<HomeContentResponse>) => {
        this.homeContent = response.body.homeContent;

        this.homeContentFormGroup.patchValue(this.homeContent);
      }
    )
  };

  updateContent(saveType?:string){

    if(this.homeContentFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Failed saving home.')
      return
    }

    if(this.isQuoteProduct){
      this.editQuotedProduct();
      return;
    }

    if(!this.homeContentFormGroup.get('address').get('formattedAddress').value){
      let homeFormGroupAddress = this.homeContentFormGroup.get('address');
      let addressLine1 = homeFormGroupAddress.get('addressLine1').value;
      let suburb = homeFormGroupAddress.get('suburb').value;
      let city = homeFormGroupAddress.get('city').value;
      let postalCode = homeFormGroupAddress.get('postalCode').value;
      homeFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    this.clientHomeContentService.updateClientHomeContentInsurance(this.clientId, this.homeContentId, this.homeContentFormGroup.value).subscribe(
      (response: HttpResponse<HomeContentResponse>) => {
        this.location.back()
      }
    )
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let homeContentFormGroupAddress = this.homeContentFormGroup.get('address');

    homeContentFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    homeContentFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    homeContentFormGroupAddress.get('city').setValue(findInAddress('locality'));
    homeContentFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    homeContentFormGroupAddress.get('country').setValue(findInAddress('country'));
    homeContentFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    homeContentFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}

