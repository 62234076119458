<div id="content" class="row">

    <sub-navigation heading="{{ editProduct ? 'Edit' : 'Add new' }} trailers and caravans"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="productFromGroup" [formGroup]="productFromGroup">

                <div class="panel-heading">
                    <h5>Trailer and caravan information</h5>

                    <div class="my-auto">

                        <button matTooltip="Go back to view client trailers and caravans" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>

                        <div class="btn-group" ngbDropdown *ngIf="!view && canViewItem">
                            <button type="button" class="btn btn-primary" mat-tooltip="Save product and go back." (click)="editProduct ? updateProduct('back') : addNewProduct('back')">
                  Submit
                </button>
                            <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                </button>
                            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><a class="dropdown-item" (click)="editProduct ? updateProduct('continue') : addNewProduct('continue')">Save &amp; continue</a>
                                </li>
                                <li role="menuitem"><a class="dropdown-item" (click)="editProduct ? updateProduct('back') : addNewProduct('back')">Save &amp; go back</a></li>
                            </ul>
                        </div>
                        <!-- Close div for .btn-group -->

                    </div>
                    <!-- Close div for .my-auto -->

                </div>

                <div class="panel-body">

                    <div class="row">

                        <div class="col-sm-5 col-12">
                            <div class="input-group">
                                <mat-form-field [ngClass]="{ 'is-invalid': formSubmitted && productForm['type'].errors }">
                                    <mat-select placeholder="Type" name="use" formControlName="type">
                                        <mat-option value="trailer">Trailer</mat-option>
                                        <mat-option value="caravan">Caravan</mat-option>
                                    </mat-select>

                                    <mat-error>
                                        Please select a <strong>product type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-5 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Registration number" name="registrationNumber" formControlName="registrationNumber" />
                                <mat-error>
                                    Please enter a <strong>registration number</strong> for this product.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-3 col-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Product year" name="year" formControlName="year" />
                                <mat-error>
                                    Please enter the <strong>production year</strong> of this product.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-5 col-12">
                            <div class="input-group">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Product make" name="make" formControlName="make"  />

                                    <mat-error>
                                        Please select the <strong>make</strong> of this product.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-4 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Product model" name="model" formControlName="model"  />

                                <mat-error>
                                    Please select the <strong>model</strong> of this product.
                                </mat-error>

                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3">
                        </div>

                        <div class="col-sm-6 col-12" formGroupName="registeredTo">
                            <mat-form-field>
                                <mat-select name="registeredTo" placeholder="Who is the registered owner?" (selectionChange)="setRegisteredOwner($event.value)">
                                    <mat-option value="" disabled>Please select the registered owner</mat-option>
                                    <mat-option [value]="clientInformation">{{ clientInformation?.firstName }} {{ clientInformation?.lastName }}</mat-option>
                                    <mat-option *ngFor="let familyMember of familyMembers" [value]="familyMember">
                                        {{ familyMember?.name }} {{ familyMember?.surname }}
                                    </mat-option>

                                    <div class="mt-2">
                                        <button (click)="addNewFamilyMember()" class="btn btn-square btn-label-primary btn-block">
                                New owner
                                </button>
                                    </div>

                                </mat-select>

                                <mat-error>
                                    Please provide the <strong>registered owner</strong> of this product.
                                </mat-error>

                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->
                </div>
                <!-- Close div for .panel-body -->

                <ng-container>

                    <div class="panel-heading mt-4">
                        <h5>Insurance</h5>
                    </div>
    
                    <div class="panel-body" formGroupName="insurance">
    
                        <div class="row">
    
                            <div class="col-sm-6 col-12">
    
                                <div class="row">
    
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <mat-form-field>
                                            <input [disabled]="useRetailValue.checked" matInput type="number" placeholder="Insured value" name="insuredValue" formControlName="insuredValue" />
                                            <span matPrefix>R</span>
                                            <mat-error>
                                                Please provide the <strong>insured value</strong> for this product.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
    
                                    <div class="col-12 col-md-4 col-lg-3 my-auto">
                                        <mat-checkbox #useRetailValue disableRipple formControlName="insuredValueRetail" (click)="productFromGroup.get('insurance').get('insuredValue').setValue(0)">Use retail?</mat-checkbox>
                                    </div>
    
                                </div>
    
                            </div>
    
                        </div>
                        <!-- Close div for .row -->
    
                        <div class="col-12 clearfix mt-3">
                        </div>
    
                        <div class="row" *ngIf="!isQuoteProduct">
    
                            <div class="col-sm-6 col-12">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Premium" name="premium" formControlName="premium" />
                                    <span matPrefix>R</span>
                                    <mat-error>
                                        Please provide the <strong>monthly premium</strong> of the product.
                                    </mat-error>
                                </mat-form-field>
                            </div>
    
                            <div class="col-12 clearfix mt-3 d-sm-none d-block">
                            </div>
    
                            <div class="col-sm-6 col-12">
    
                                <div class="input-group">
                                    <mat-form-field>
                                        <mat-select placeholder="Policy number" formControlName="policyNumber">
                                          <mat-option disabled *ngIf="policies?.length === 0 && productFromGroup.get('insurance').get('policyNumber').value">
                                            <span>No policy found</span>
                                        </mat-option>
                                        <mat-option class="px-0 py-0" style="height: unset" *ngIf="policies?.length === 0 && productFromGroup.get('insurance').get('policyNumber').value">
                                            <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                                        </mat-option>
                                          <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                            <span>{{ policy?.policyNumber }}</span>
                                        </mat-option>
                                        </mat-select>
          
                                        <mat-error>
                                          Please select a <strong>policy number</strong> for this product.
                                        </mat-error>
          
                                      </mat-form-field>
                                </div>
                                <!-- Close div for .input-group -->
                            </div>
    
                        </div>
                        <!-- Close div for .row -->
    
                        <div class="col-12 clearfix mt-3"  *ngIf="!isQuoteProduct">
                        </div>
    
                        <div class="row">
                            
                            <div class="col-sm-6 col-12">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="CFG" name="cfg" formControlName="cfg" />
                                        <span matSuffix>years</span>
                                        <mat-error>
                                            Please provide the <strong>CFG</strong> for this product.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
    
                                <div class="col-12 clearfix mt-3 d-sm-none d-block">
                                </div>    
    
                            <div class="col-sm-6 col-12" *ngIf="!isQuoteProduct">
    
                                <mat-form-field>
                                    <input 
                                        matInput
                                        name="inceptionDate"
                                        [matDatepicker]="inceptionDate"
                                        name="inceptionDate"
                                        formControlName="inceptionDate"
                                        placeholder="Inception date"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #inceptionDate></mat-datepicker>
            
                                    <mat-error>
                                        Please provide the <strong>inception date</strong> for this product.
                                    </mat-error>
            
                                </mat-form-field>
                            </div>
    
                        </div>
                        <!-- Close div for .row -->
    
                    </div>
                    <!-- Close div for .panel-body-->

                </ng-container>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->