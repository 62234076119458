import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrokerCreateNewCalendarEventComponent } from './new-event/broker-create-new-calendar-event.component';
import { CalendarService } from 'src/app/services/calendar.service';
import { User } from 'src/app/_shared/models/User.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpResponse } from '@angular/common/http';
import { CalendarEventResponse, CalendarEventModel} from 'src/app/_shared/models/calendarEvent.model';
import { FullCalendarComponent, CalendarOptions, EventClickArg } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BrokerViewCalendarEventComponent } from './view-event/broker-view-calendar-event.component';

@Component({
  selector: 'app-broker-calendar',
  templateUrl: './broker-calendar.component.html',
  styleUrls: ['./broker-calendar.component.scss']
})
export class BrokerCalendarComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  currentUser: User;
  calendarDataEvents: any[] = [];

  currentUserPermissions: any[];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [ timeGridPlugin ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    // eventSources: ["http://localhost:8000/api/calendar/events/6ab6c437164c5c9a34231fff/SureSpace"],
    eventSources: this.calendarDataEvents,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    events: (event) => {
      if(this.currentUserPermissions.includes('brokerAdmin')){
        this.getAllEvents(event.startStr, event.endStr)
      } else {
        this.getAllEventsPerUser(event.startStr, event.endStr)
      }
      
    },
    editable: true,
    eventClick: this.viewUserEvent.bind(this)
  };

  constructor(
    public dialog: MatDialog,
    private calendarService: CalendarService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  addNewEvent(){
    let dialogRef = this.dialog.open(BrokerCreateNewCalendarEventComponent, { width: '800px' });

    dialogRef.afterClosed().subscribe(response => {
      if(response.status === 'success'){
        this.calendarDataEvents.push(response.data);
        this.calendarComponent.getApi().removeAllEventSources();
        this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);
      }
    })
  }

  deleteCalendarEvent(event:CalendarEventModel) {
    this.calendarService.deleteEvent(event._id).subscribe( response => {
      this.calendarDataEvents = this.calendarDataEvents.filter(e => e._id !== event._id)
    })
  }

  viewUserEvent(event:EventClickArg){
    let eventData = {
      ...event.event._def.extendedProps,
      title: event.event.title,
      allDay: event.event.allDay,
      start: event.event.start,
      end: event.event.end
    }
    let dialogRef = this.dialog.open(BrokerViewCalendarEventComponent, { data: eventData, width: '800px' });

    dialogRef.afterClosed().subscribe(response => {

      if(response.status === 'deleted'){
        this.calendarDataEvents = this.calendarDataEvents.filter(e => e._id !== response.data._id);
      }

      if(response.status === 'saved'){
        this.calendarDataEvents = this.calendarDataEvents.filter(e => e._id !== response.data._id);
        this.calendarDataEvents.push(response.data);
      }

      this.calendarComponent.getApi().removeAllEventSources();
      this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);

    })

  }

  getAllEvents(startDate, endDate){

    this.calendarService.getAllCalendarEvents(this.authService.currentCompany.companyName, startDate, endDate).subscribe(
      (response:HttpResponse<CalendarEventResponse>) => {
      this.calendarDataEvents = response.body.events;

      this.calendarComponent.getApi().removeAllEventSources();
      this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);
    })
  }

  getAllEventsPerUser(startDate, endDate){

    this.calendarService.getAllEventsPerUser(this.currentUser._id, this.authService.currentCompany.companyName, startDate, endDate).subscribe(
      (response:HttpResponse<CalendarEventResponse>) => {
      this.calendarDataEvents = response.body.events;

      this.calendarComponent.getApi().removeAllEventSources();
      this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);
    })
  }

}
