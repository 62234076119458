<div id="content" class="row">
    
    <div class="secondary-nav">
        <div class="subnav-background"></div>
        <div class="row h-100">
          <div class="col-12 v-align">
            <h5 class="d-inline-block">Edit claim</h5>
            <div class="separator"></div>
            <!-- <h6 class="d-inline-block">{{ claimsFormGroup.get('claim').value?.client?.firstName }} {{ claimsFormGroup.get('claim').value?.client?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" [routerLink]="['/b/clients/personal/edit', claimsFormGroup.get('claim').value?.client?._id]" [queryParams]="{file: '8'}"><img src="assets/icons/goto.svg" /></i></h6> -->
            <h6 class="d-inline-block">{{ claimsFormGroup.get('claim').value?.client?.firstName }} {{ claimsFormGroup.get('claim').value?.client?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" (click)="goToClientFile()"><img src="assets/icons/goto.svg" /></i></h6>
        </div>
        </div>  
      </div>

    <div class="col-12">

        <ng-container *ngIf="canSignoff()">

            <div *ngIf="hasRequestAttached && request?.whatNeedsToHappen === 'signOff'" id="instruction-handover" class="panel panel-primary">
                <div class="row py-3 px-3">
                    <div class="col-12 col-md-8 my-auto">
                        <h5 class="mb-0">
                            <strong>
                                {{ request?.message }}  Do you approve this sign off?
                            </strong>
                        </h5>
                    </div>
                    <div class="col-12 col-md-4 text-center text-md-right">
                        <button class="btn btn-success text-white" (click)="acceptRequest(request._id)">Accept</button>
                        <button class="btn btn-danger text-white" (click)="rejectRequest(request)">Reject</button>
                    </div>
                </div> <!-- Close div for .row -->
            </div>

        </ng-container>

        <ng-container *ngIf="!hasRequestAttached">
            <div *ngFor="let reason of claimRejectedReason; let last = last" id="claim-rejected">
                <div *ngIf="last" class="panel panel-primary border-danger">
                    <div class="row py-3 px-3">
                        <div class="col-12 my-auto">
                            <p class="mb-0">
                                This claim has been rejected on <strong>{{ reason?.createdAt | date: 'dd MMM yyyy' }}</strong> by <strong>{{ reason?.rejectedBy?.firstName }} {{ reason?.rejectedBy?.lastName }}</strong>.<br />
                                <strong>
                                    {{ reason?.rejectedReason }}
                                </strong>
                            </p>
                        </div>
                    </div> <!-- Close div for .row -->
                </div>
            </div>
        </ng-container>

        <form [formGroup]="claimsFormGroup">
  
            <div class="panel panel-primary">

                <div class="panel-heading">
                    <h5>Claim details</h5>
        
                    <div class="my-auto">
        
                    <button type="button" matTooltip="Go back to view all claims" class="btn btn-secondary my-auto" (click)="goBack()">
                        <i class="fi flaticon-back"></i>Back
                    </button>
        
                    <div class="btn-group" ngbDropdown>

                        <button type="submit" class="btn btn-primary" mat-tooltip="Save claim and go back." (click)="saveClaim('back')">
                            Save claim
                        </button>

                        <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                        </button>
                        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="saveClaim('continue')">Save &amp; continue</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="saveClaim('new')">Save &amp; add new</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="saveClaim('back')">Save &amp; go back</a></li>
                        </ul>

                    </div> <!-- Close div for .btn-group -->
        
                    </div> <!-- Close div for .my-auto -->
        
                </div>
        
                <div class="panel-body">
                    
                    <div class="row">
                        <broker-claim-form-group class="col-12" formControlName="claim" (currentClaimStep)="setCurrentStep($event)"></broker-claim-form-group>
                    </div>

                </div>

            </div>

        </form>

    </div>

</div>