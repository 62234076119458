import { HouseholdItem, HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { HomeContent, HomeContentResponse } from 'src/app/_shared/models/homeContentInsurance.model';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';

@Component({
  template: ''
})
export class DeleteHouseholdItemComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(householdItem: HomeContent): void {

    this.dialogRef = this.dialog.open(DeleteHouseholdItemComponentPreview, {
      // width: '600px',
      data: householdItem
    });
  }

}

@Component({
  templateUrl: './delete-household-item.component.html',
  styleUrls: ['./delete-household-item.component.scss']
})
export class DeleteHouseholdItemComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteHouseholdItemComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public householdItem: HomeContent,
    public personalInsuranceService: PersonalInsuranceService,
    private homeContentService: BrokerClientHomeContentService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  // removeHouseholdItem(householdItem: HouseholdItem){
  //   this.personalInsuranceService.removeHouseholdItem(householdItem._id).subscribe(
  //     (response:HttpResponse<HouseholdItemResponse>) => {
  //       this.closeDialog('success');
  //     }
  //   )
  // }

  removeHomeContent(content:any){
    this.homeContentService.deleteInsuranceFromHomeContent(content['clientId'], content._id).subscribe(
      (response:HttpResponse<HomeContentResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
