import { Observable } from 'rxjs';
import { FAQResponse } from 'src/app/_shared/models/faq.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Bug, BugResponse } from 'src/app/_shared/models/bug.model';
import { Suggestion, SuggestionResponse } from '../_shared/models/suggestion.model';

@Injectable({
  providedIn: 'root'
})
export class SurespaceService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getFaq(noload?:boolean, question?:any): Observable<HttpResponse<FAQResponse>>{

    let headers = new HttpHeaders();
    if(noload){
      headers = headers.set("noload", "true");
    }

    return this.http.post<FAQResponse>(`${this.globals.SERVER_URL}/api/management/get_faq`, question, {
      headers: headers,
      observe: 'response'
    });
  };

  reportBug(bug: Bug): Observable<HttpResponse<BugResponse>>{
    return this.http.post<BugResponse>(`${this.globals.SERVER_URL}/api/management/bug`, bug, {
      observe: 'response'
    });
  };

  addSuggestion(suggestion: Suggestion): Observable<HttpResponse<SuggestionResponse>>{
    return this.http.post<SuggestionResponse>(`${this.globals.SERVER_URL}/api/management/suggestion`, suggestion, {
      observe: 'response'
    });
  };

}
