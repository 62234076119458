import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { InvestmentService } from 'src/app/services/investment.service';
import { Investment, InvestmentResponse } from 'src/app/_shared/models/investment.model';

@Component({
  selector: 'app-view-client-investments',
  templateUrl: './view-client-investments.component.html',
  styleUrls: ['./view-client-investments.component.scss']
})
export class ViewClientInvestmentsComponent implements OnInit, AfterViewInit {

  investments: Investment[];

  constructor(
    private investmentService: InvestmentService
  ) { }

  getInvestments() {
    this.investmentService.getAllInvestments().subscribe(
      (response:HttpResponse<InvestmentResponse>) => {
        this.investments = response.body.investment;
      }
    );
  }

  calculateCalculationDate(date, calculationPeriod){
    let today = new Date();
    calculationPeriod = calculationPeriod || 0
    date = new Date(date);
    date.setMonth(date.getMonth() + calculationPeriod);

    // If calculation date is passed, calculate new one
    if(date < today){
      date.setMonth(today.getMonth() + calculationPeriod);
    }

    return date
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getInvestments();
  }

}
