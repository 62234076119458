import { Observable } from 'rxjs';
import { CommercialClient, CommercialClientResponse } from './../_shared/models/commercialClient.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Globals } from './../app.globals';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommercialClientsService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllClients(companyName:string, req?:any, noLoad?:boolean){

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<CommercialClientResponse>(`${this.globals.SERVER_URL}/api/clients/commercial/all/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  };

  getSingleClient(id: string): Observable<HttpResponse<CommercialClientResponse>>{
    return this.http.get<CommercialClientResponse>(`${this.globals.SERVER_URL}/api/clients/commercial/client/${id}`, {
      observe: 'response'
    });
  }

  addNewClient(formData:CommercialClient, companyName:string): Observable<HttpResponse<CommercialClientResponse>>{

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.post<CommercialClientResponse>(`${this.globals.SERVER_URL}/api/clients/commercial/${companyName}`, formData, {
      observe: 'response',
      headers: headers
    })
  }

  saveClientDetails(clientId:string, formData:CommercialClient, companyName:string): Observable<HttpResponse<CommercialClientResponse>>{
    return this.http.put<CommercialClientResponse>(`${this.globals.SERVER_URL}/api/clients/commercial/${clientId}/${companyName}`, formData, {
      observe: 'response',
    })
  }

  removeClient(clientId:string, companyName: string): Observable<HttpResponse<CommercialClientResponse>>{
    return this.http.delete<CommercialClientResponse>(`${this.globals.SERVER_URL}/api/clients/commercial/${clientId}/${companyName}`, {
      observe: 'response'
    })
  }

}
