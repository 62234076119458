import { User } from 'src/app/_shared/models/User.model';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Globals} from '../app.globals';
import { AuthenticationService } from '../services/authentication.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, interval } from 'rxjs';
import { RenewSessionComponent } from 'src/app/components/modal/renew-session/renew-session.component';
import decode from 'jwt-decode';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        public globals: Globals,
        private authService: AuthenticationService,
        private dialog: MatDialog
    ) {}

    openDialog(timeRemaining) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '550px';

        dialogConfig.data = {
            timeRemaining: timeRemaining
        }

        this.dialog.open(RenewSessionComponent, dialogConfig);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // const selectedRoute = route.routeConfig.path;
        // const currentUser = this.authService.getLoggedinUser();
        
        //get the logged in user every route change
        this.authService.getLoggedinUser().subscribe();

        const token = localStorage.getItem('SureSpace-userToken');

        const tokenPayload = decode(token);
        const tokenExpired = this.authService.isTokenExpired(token);

        const tokenExpiry = interval(1000).subscribe(value => {

            const secondsUntilExpire = tokenPayload.exp;
            const currentTime = Date.now() / 1000;
            const timeRemaining = Math.floor(secondsUntilExpire - currentTime);

            //session wil expire in 30 seconds
            if(timeRemaining <= 30){
                this.openDialog(timeRemaining);
                tokenExpiry.unsubscribe();
            }

            //unsubscribe to countdown on each route change
            this.router.events.subscribe((event:any) => {
                if(event instanceof NavigationEnd){
                    tokenExpiry.unsubscribe();
                }
            })

        })

        if (!this.globals.AUTENTICATED || tokenExpired) {
            this.authService.logoutUser();
            this.router.navigate(['/login'], { queryParams: { redirectTo:state.url}});
            return false;
        }
        
        return true;
    }
}

@Injectable()
export class AnonymousGuardService implements CanActivate {

    constructor(
        private router: Router,
        public globals: Globals
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.globals.AUTENTICATED) {
            return true;
        } else {
            this.router.navigate(['/dashboard']);
            return false;
        }
    }

}

@Injectable()
export class UserGuardService implements CanActivate {

    currentUser: User;
    isUser: boolean;

    constructor(
        private router: Router,
        public globals: Globals,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
            this.isUser = this.currentUser.userType === 'user';
        })
    }
    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.isUser) {
            return true;
        } else {
            this.router.navigate(['/404']);
            return false;
        }
    }

}

@Injectable()
export class BrokerGuardService implements CanActivate {

    currentUser: User;
    isBroker:boolean;

    constructor(
        private router: Router,
        public globals: Globals,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
            this.isBroker = this.currentUser.userType === 'broker';
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.isBroker) {
            return true;
        } else {
            this.router.navigate(['/404']);
            return false;
        }
    }

}

@Injectable()
export class AdminGuardService implements CanActivate {

    currentUser: User;

    constructor(
        private router: Router,
        public globals: Globals,
        private authenticationService: AuthenticationService,
    ) {
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.globals.AUTENTICATED && this.currentUser.permissions.includes('Administrator')) {
            return true;
        } else {
            this.router.navigate(['/dashboard']);
            return false;
        }
    }

}