<div id="content" class="row">

  <sub-navigation heading="Edit vehicle"></sub-navigation>

  <div class="col-12">

    <div class="panel panel-primary">
      <form id="vehicleFormGroup" [formGroup]="vehicleFormGroup">

        <div class="panel-heading">
          <h5>Vehicle information</h5>

          <div class="my-auto">

            <button matTooltip="Go back to view client vehicles" class="btn btn-secondary my-auto" (click)="goBack()">
              <i class="fi flaticon-back"></i>Back
            </button>

            <div class="btn-group" ngbDropdown>
              <button type="button" class="btn btn-primary" mat-tooltip="Add new vehicle and go back."
                (click)="updateVehicle('back')">
                Submit
              </button>
              <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                aria-controls="dropdown-basic">
              </button>
              <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="updateVehicle('continue')">Save &amp; continue</a>
                </li>
                <li role="menuitem"><a class="dropdown-item" (click)="updateVehicle('back')">Save &amp; go back</a></li>
              </ul>
            </div> <!-- Close div for .btn-group -->

          </div> <!-- Close div for .my-auto -->

        </div>

        <div class="panel-body">

          <div class="row">

            <div class="col-sm-5 col-12">
                <label for="make"><strong>Vehicle type</strong></label>
                <div class="input-group">
                    <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }">
                        <mat-select name="use" formControlName="type">
                            <mat-option value="" disabled>Please select vehicle type</mat-option>
                            <mat-option value="Car">Car</mat-option>
                            <mat-option value="LDV<1400">LDV < 1400</mat-option>
                            <mat-option value="4X4Luxury">4X4 Luxury</mat-option>
                            <mat-option value="4X4DCab">4X4 Double cab</mat-option>
                            <mat-option value="4X4SCab">4X4 Single cab</mat-option>
                            <mat-option value="LDV>1400">LDV > 1400</mat-option>
                            <mat-option value="Kombi">Kombi</mat-option>
                            <mat-option value="Venture">Venture</mat-option>
                            <mat-option value="4X2DCab">4X2 Double cab</mat-option>
                            <mat-option value="4X2SCab">4X2 Single cab</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
    
                <div class="col-12 clearfix mt-3 d-sm-none d-block">
                </div>

            <div class="col-sm-5 col-12">
              <label for="registrationNumber"><strong>Registration number:</strong></label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['registrationNumber'].errors }"
                name="registrationNumber"
                formControlName="registrationNumber"
                (blur)="getVehicleMakes($event)"
                />
              <div *ngIf="submitted && form['registrationNumber'].errors" class="invalid-feedback">
                <div *ngIf="form['registrationNumber'].errors.required">Please provide a registration number for this
                  vehicle.</div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-3 col-12">
                <label for="year"><strong>Vehicle year</strong></label>
                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['year'].errors }"
                    name="year" formControlName="year" />
                <div *ngIf="submitted && form['year'].errors" class="invalid-feedback">
                    <div *ngIf="form['year'].errors.required">Please provide the year of the vehicle.</div>
                </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-5 col-12">
                <label for="make"><strong>Vehicle make</strong></label>
                <div class="input-group">
                    <mat-form-field
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['make'].errors }"
                    >
                        <mat-select name="make" formControlName="make" [disabled]="!form['year'].value" (blur)="getVehicleModels(form['year'].value, form['make'].value)">
                            <mat-option value="" disabled>None</mat-option>
                            <mat-option *ngFor="let make of vehicleMakes" [value]="make.display">{{ make.display }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['make'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['make'].errors.required">Please select the vehicle make.</div>
                    </div>
                </div>
              <!-- <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['make'].errors }"
                name="make"
                formControlName="make"
                [disabled]="!form['years'].value"
                /> -->
              <div *ngIf="submitted && form['make'].errors" class="invalid-feedback">
                <div *ngIf="form['make'].errors.required">Please provide the make of the vehicle.</div>
              </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-4 col-12">
                <label for="model"><strong>Vehicle model</strong></label>
                <div class="input-group">
                    <mat-form-field
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['model'].errors }"
                    (blur)="getVehicleTrims($event)"
                    >
                        <mat-select name="model" formControlName="model" [disabled]="!form['make'].value">
                            <mat-option value="" disabled>None</mat-option>
                            <mat-option *ngFor="let model of vehicleModels" [value]="model.name">{{ model.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['model'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['model'].errors.required">Please select the vehicle model.</div>
                    </div>
                </div>
              <!-- <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['model'].errors }"
                name="model" formControlName="model" />
              <div *ngIf="submitted && form['model'].errors" class="invalid-feedback">
                <div *ngIf="form['model'].errors.required">Please provide the model of the vehicle.</div>
              </div> -->
            </div>            

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-12">

              <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">

                <i class="fi flaticon-upload"></i>
                <strong>Drop photos here<br />or <span class="color-primary">click to select</span></strong>

                <label class="upload-button">
                  <input #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                    (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                </label> <!-- Close .upload-button -->

              </div><!-- close .drop-container -->

              <div>
                <div id="uploads" class="row" *ngIf="files?.length">
                  <div id="upload-item" class="col-sm-2 col-4" *ngFor="let f of files; let i = index;">

                    <div class="thumbnail">
                      <img src="{{f['imagePreview']}}" class="img-fluid" />
                      <!-- <button (click)="removeFile(f.id)"></button> -->
                      <div class="removeImage">
                        <div class="remove" (click)="removeFile(f.id)">
                          x
                        </div> <!-- Close div for .remove -->
                      </div> <!-- Close div for .removeImage -->
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12">
                <label for="variant"><strong>Vehicle variant</strong></label>
                <div class="input-group">
                    <mat-form-field
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['variant'].errors }"
                    (blur)="getVehicleTrims($event)"
                    >
                        <mat-select name="variant" formControlName="variant" [disabled]="!form['model'].value">
                            <mat-option value="" disabled>None</mat-option>
                            <mat-option *ngFor="let variant of vehicleTrims" [value]="variant.trim">{{ variant.trim }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['variant'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['variant'].errors.required">Please select the vehicle variant.</div>
                    </div>
                </div>
                <!-- <div class="input-group">
                    <input type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['variant'].errors }" name="variant"
                    formControlName="variant" />
    
                    <div *ngIf="submitted && form['variant'].errors" class="invalid-feedback d-block">
                    <div *ngIf="form['variant'].errors.required">Please select the vehicle variant.</div>
                    </div>
                </div> -->
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12">
                <label for="engineSize"><strong>Vehicle engine size</strong></label>
                <!-- <div class="input-group">
                 <mat-form-field
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['engineSize'].errors }"
                    (blur)="getVehicleTrims($event)"
                    >
                        <mat-select name="engineSize" formControlName="engineSize" [disabled]="!form['model'].value">
                            <mat-option value="" disabled>None</mat-option>
                            <mat-option *ngFor="let engineSize of vehicleTrims" [value]="engineSize.engineCC">{{ engineSize.engineCC }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['engineSize'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['engineSize'].errors.required">Please select the vehicle engine size.</div>
                    </div>
                </div> -->
                <div class="input-group">
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form['engineSize'].errors }" name="engineSize"
                        formControlName="engineSize" />
                        <div class="input-group-append">
                            <span class="input-group-text">cc</span>
                        </div>
                    <div *ngIf="submitted && form['engineSize'].errors" class="invalid-feedback">
                        <div *ngIf="form['engineSize'].errors.required">Please provide the engine size your vehicle.</div>
                    </div>
                </div> <!-- Close div for .input-group -->
            </div>
            

            <!-- <div class="col-sm-6 col-12">
                      <label for="status"><strong>Vehicle status</strong></label>
                      <div class="input-group">
                          <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['status'].errors }">
                              <mat-select name="status" formControlName="status">
                                  <mat-option value="" disabled>None</mat-option>
                                  <mat-option value="Pre-owned">Pre-owned</mat-option>
                                  <mat-option value="Demo">Demo</mat-option>
                                  <mat-option value="Other">Other</mat-option>
                              </mat-select>
                          </mat-form-field>
          
                          <div *ngIf="submitted && form['status'].errors" class="invalid-feedback d-block">
                              <div *ngIf="form['status'].errors.required">Please select the vehicle status.</div>
                          </div>
                      </div>
      
                    </div> -->
          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12">
                <label for="color"><strong>Vehicle color</strong></label>
                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['color'].errors }"
                    name="color" formControlName="color" />
                <div *ngIf="submitted && form['color'].errors" class="invalid-feedback">
                    <div *ngIf="form['color'].errors.required">Please provide the color of the vehicle.</div>
                </div>
            </div>            

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12">
                <label for="use"><strong>Vehicle use</strong></label>
                <div class="input-group">
    
                    <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['use'].errors }">
                    <mat-select name="use" formControlName="use">
                        <mat-option value="" disabled>None</mat-option>
                        <mat-option value="Pre-owned">Work</mat-option>
                        <mat-option value="Demo">Private</mat-option>
                        <mat-option value="Other">Combined</mat-option>
                    </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['use'].errors" class="invalid-feedback d-block">
                    <div *ngIf="form['use'].errors.required">Please select the vehicle daily use.</div>
                    </div>
    
                </div> <!-- Close div for .input-group -->
    
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12">
                <label for="milage"><strong>Vehicle milage</strong></label>
    
                <div class="input-group">
                    <input type="text" class="form-control" name="milage" formControlName="milage" />
                    <div class="input-group-append">
                        <div class="input-group-text">km</div>
                    </div>
                </div>
            </div> <!-- Close div for .col-12 -->

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12" *ngIf="false">
              <label for="engineNumber"><strong>Vehicle engine number</strong></label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['engineNumber'].errors }" name="engineNumber"
                formControlName="engineNumber" />
              <div *ngIf="submitted && form['engineNumber'].errors" class="invalid-feedback">
                <div *ngIf="form['engineNumber'].errors.required">Please provide the engine number your vehicle.</div>
              </div>
            </div>

            <div class="col-sm-6 col-12">
                <label for="vin"><strong>Vehicle VIN</strong></label>
                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['vin'].errors }"
                    name="vin" formControlName="vin" />
                <div *ngIf="submitted && form['vin'].errors" class="invalid-feedback">
                    <div *ngIf="form['vin'].errors.required">Please provide the VIN number of the vehicle.</div>
                </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12" formGroupName="homeEnvironment">
              <label for="homeParking"><strong>Home parking environment:</strong></label>
              <div class="checkbox"
                *ngFor="let parking of vehicleFormGroup.get('homeEnvironment')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}
                </mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12" formGroupName="officeEnvironment">
              <label for="parkingEnvironment"><strong>Office parking environment:</strong></label>
              <div class="checkbox"
                *ngFor="let parking of vehicleFormGroup.get('officeEnvironment')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}
                </mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12" formGroupName="optionalSafetyExtras">
              <label for="optionalSafetyExtras"><strong>Optional safety extras:</strong></label>
              <div class="checkbox"
                *ngFor="let extra of vehicleFormGroup.get('optionalSafetyExtras')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="extra.key">{{ extra.key | sentenceCase }}</mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12" formArrayName="optionalExtras">
              <label for="optionalExtras"><strong>Optional added extras:</strong></label>
              <div id="optionalExtras" class="row mt-2" [formGroupName]="i"
                *ngFor="let addition of vehicleFormGroup.get('optionalExtras')['controls']; let i = index">
                <div class="form-group col-10 col-sm-6 col-md-9 my-auto">
                  <input type="text" class="form-control" name="optionalExtras" formControlName="extra" />
                </div>
                <div class="form-group col-2 col-sm-6 col-md-3 my-auto">
                  <button class="btn btn-icon btn-danger btn-sm float-right float-sm-left"
                    (click)="removeOptionalExtra(i)">
                    <i class="fi flaticon-trash mr-0"></i>
                  </button>
                </div>
              </div> <!-- Close div for .row -->

              <div class="row mt-2">
                <div class="col-12">
                  <button type="button" class="btn btn-label-success btn-sm" (click)="addNewOptionalExtra()">Add extra</button>
                </div>
              </div>
            </div>

          </div> <!-- Close div for .row -->
        </div> <!-- Close div for .panel-body -->

        <div class="panel-heading mt-4">
          <h5>Insurance</h5>
        </div>

        <div class="panel-body" formGroupName="insurance">

            <div class="row">

              <div class="col-sm-6 col-12">
                <label for="insuredWith"><strong>Insured with?</strong></label>
                <div class="input-group">
                  <mat-form-field class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].insuredWith.errors }">
                    <mat-select name="insuredWith" formControlName="insuredWith">

                      <mat-optgroup label="Brokers" *ngIf="brokers && brokers.length > 0">
                        <mat-option *ngFor="let broker of brokers" [value]="broker">
                          {{ broker?.firstName }} {{ broker.lastName }}
                        </mat-option>
                      </mat-optgroup>

                      <!-- <mat-optgroup label="Insurance companies" *ngIf="insuranceCompanies.length > 0">
                                  <mat-option *ngFor="let company of insuranceCompanies" [value]="company.companyName">{{
                                          company?.companyName }}</mat-option>
                              </mat-optgroup> -->

                      <mat-option *ngIf="brokers && brokers.length === 0" disabled>
                        No insurers added
                      </mat-option>

                    </mat-select>
                  </mat-form-field>

                  <div *ngIf="submitted && form['insurance']['controls'].insuredWith.errors" class="invalid-feedback d-block">
                    <div *ngIf="form['insurance']['controls'].insuredWith.errors.required">Please select the insurer for your item.</div>
                  </div>

                </div> <!-- Close div for .input-group -->

              </div>

              <div class="col-12 clearfix mt-3 d-sm-none d-block">
              </div>

              <div class="col-sm-6 col-12">
                <label for="insuredValue"><strong>Insured for:</strong></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">R</div>
                  </div>
                  <input type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].insuredValue.errors }" name="insuredValue"
                    formControlName="insuredValue" />
                  <div *ngIf="submitted && form['insurance']['controls'].insuredValue.errors" class="invalid-feedback d-block">
                    <div *ngIf="form['insurance']['controls'].insuredValue.errors.required">Please provide the insured value of the vehicle.
                    </div>
                  </div>
                </div>
              </div>

            </div> <!-- Close div for .row -->

            <div class="col-12 clearfix mt-3">
            </div>

            <div class="row">

                <div class="col-sm-6 col-12">
                    <label for="premium"><strong>Premium:</strong></label>
                    <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">R</div>
                    </div>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].premium.errors }" name="premium"
                        formControlName="premium" />
                      <div *ngIf="submitted && form['insurance']['controls'].premium.errors" class="invalid-feedback">
                          <div *ngIf="form['insurance']['controls'].premium.errors.required">Please provide the monthly premium of the vehicle.
                          </div>
                      </div>
                    </div>
                </div>

                <div class="col-12 clearfix mt-3 d-sm-none d-block">
                </div>

                <div class="col-sm-6 col-12">
                    <label for="policyNumber"><strong>Policy number:</strong></label>
                    <input type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].policyNumber.errors }" name="policyNumber"
                      formControlName="policyNumber" />
                      <div *ngIf="submitted && form['insurance']['controls'].policyNumber.errors" class="invalid-feedback">
                          <div *ngIf="form['insurance']['controls'].policyNumber.errors.required">Please provide the policy number of the vehicle.
                          </div>
                      </div>
                </div>

            </div> <!-- Close div for .row -->

            <div class="col-12 clearfix mt-3">
            </div>

            <div class="row">

                <div class="col-sm-6 col-12">
                    <label for="inceptionDate"><strong>Policy start date:</strong></label>
                    <div class="input-group">
                      <mat-form-field class="form-control">
                        <input matInput [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].inceptionDate.errors }" name="policyNumber" [matDatepicker]="inceptionDate" name="inceptionDate" formControlName="inceptionDate">
                        <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #inceptionDate></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="submitted && form['insurance']['controls'].inceptionDate.errors" class="invalid-feedback">
                        <div *ngIf="form['insurance']['controls'].inceptionDate.errors.required">Please provide the policy inception date.
                        </div>
                      </div>
                    </div>
                    
                </div>

            </div> <!-- Close div for .row -->

            <div class="col-12 clearfix mt-3">
            </div>

            <div class="row">

              <div class="col-sm-6 col-12">
                <label for="regularDriver"><strong>Who is the regular driver of the vehicle?</strong></label>
                <div class="input-group">
                  <mat-form-field class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['insurance']['controls'].regularDriver.errors }">
                    <mat-select name="regularDriver" formControlName="regularDriver">
                      <mat-option value="" disabled>Please select the regular driver</mat-option>
                      <mat-option [value]="clientInformation?._id">{{ clientInformation?.firstName }} {{ clientInformation?.lastName }}</mat-option>
                      <mat-option *ngFor="let familyMember of familyMembers" [value]="familyMember.idNumber">
                        {{ familyMember?.name }} {{ familyMember?.surname }}</mat-option>

                      <div class="mt-2">
                        <button (click)="addNewRegularDriver()" class="btn btn-square btn-label-primary btn-block">
                          New driver
                        </button>
                      </div>

                    </mat-select>
                  </mat-form-field>

                  <div *ngIf="submitted && form['insurance']['controls'].regularDriver.errors" class="invalid-feedback">
                    <div *ngIf="form['insurance']['controls'].regularDriver.errors.required">Please provide the regular driver of this vehicle.</div>
                  </div>

                </div>
              </div>

            </div> <!-- Close div for .row -->

        </div> <!-- Close div for .panel-body-->

      </form>
    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for #content -->
