import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class Home implements Basemodel {
  constructor(
    public userRef: string,
    public name: string,
    public address: Address,
    public insurance: Insurance,
	public residenceType: string,
	public isPrimaryResidence: boolean,
	public isHolidayHome: boolean,
	public buildingAge: string,
    public environment: Environment,
    public areaType: string,
	public externalWalls: Construction,
    public roof: Roof,
    public floor: Floor,
    public underConstruction: boolean,
    public thatch: Thatch,
    public swimmingPool: boolean,
    public gateMotor: boolean,
    public isCommune: boolean,
    public occupiedDuringDay: boolean,
    public geyser: Geyser
  ) {}
}

export class Address {
    constructor(
        public addressLine1: string,
        public addressLine2: string,
        public city: string,
        public suburb: string,
        public province: string,
        public country: string,
        public postalCode: string,
        public municipalAccNr?: string,
        public stand?: string,
        public formattedAddress?: string
    ){}
}

export class Insurance {
    constructor(
        public brokerId: string,
        public brokerFirstName: string,
        public brokerLastName: string,
        public companyName: string,
        public insuredValue: Number,
        public premium: Number,
        public policyNumber: string,
        public inceptionDate: Date,
        public coverType: string[],
        public excess: string,
        public accidentalDamage: string,
        public powerSurge: string,
        public dateAdded: Date,
        public lastEdited: Date
    ){}
}

export class Environment {
    constructor(
        public builtOnSteepSlope: boolean,
        public excessiveRetainingWalls: boolean,
        public closeToWater: boolean,
        public wallDamp: boolean,
        public crackedWalls: boolean,
        public subsidence: boolean,
        public underConstruction: boolean
    ){}
}

export class Construction {
    constructor(
	    public brick?: boolean,
	    public cement?: boolean,
	    public corrugatedIron?: boolean,
	    public glass?: boolean,
	    public prefab?: boolean,
	    public tiles?: boolean,
        public wood?: boolean
    ) {}
}

export class Floor {
    constructor(
	    public carpet?: boolean,
	    public sealed?: boolean,
	    public tiles?: boolean,
	    public woodenFloors?: boolean
    ) {}
}

export class Roof {
    constructor(
        public concrete?: boolean,
        public corrugatedIron?: boolean,
        public glass?: boolean,
        public thatch?: boolean,
        public tiles?: boolean,
        public wood?: boolean,
        public asbestos?: boolean
    ){}
}

export class Thatch {
    constructor(
        hasThatch?: boolean,
        treated?: boolean,
        lightningConductorInstalled?: boolean,
        thatchWithin5Meters?: boolean
    ){}
}

export class Geyser {
    constructor(
        public totalGeysers?: number,
        public geysers?: createGeysers[]
    ){}
}

export class createGeysers {
    constructor(
        _id: string,
        solarGeyser: boolean,
        installationDate: Date,
        installedOutsideHouse: boolean
    ){}
}

export class HomeResponse {
    constructor(
        public home: Home[],
        public message: SuccessMessage[],
        public count?: number,
    ){}
}