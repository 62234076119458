<div id="content" class="row">

    <sub-navigation heading="Calendar"></sub-navigation>

    <div class="col-12">

      <div class="panel panel-primary">

        <div class="panel-heading">

          <div class="row w-100">

            <div class="col-12 col-sm-6">
              <h5></h5>
            </div>

            <div class="col-12 col-sm-6 text-left text-sm-right">
              <!-- <app-add-button (click)="brokerComponent.toggleSendingEmail()" class="my-auto" buttonText="New email" matTooltip="Send an email to client"></app-add-button> -->
              <app-add-button class="my-auto" buttonText="Add event" (click)="addNewEvent()" matTooltip="Add a new event"></app-add-button>
            </div>

          </div>

        </div>

        <div class="panel-body" *ngIf="calendarOptions">

          <full-calendar #calendar [options]="calendarOptions"></full-calendar>

        </div>
      </div>

    </div>

</div> <!-- Close div for #content -->