import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class BulletinService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getAllBulletins(pge){
    let page = pge || 1;
    return this.http.get(`${this.globals.SERVER_URL}/api/bulletin/all/${page}`, {
      observe: 'response'
    });
  };

  getRecentBulletins(){
    return this.http.get(`${this.globals.SERVER_URL}/api/bulletin`, {
      observe: 'response'
    });
  };

  saveNewBulletin(bulletin){
    return this.http.post(`${this.globals.SERVER_URL}/api/bulletin`, bulletin, {
      observe: 'response'
    });
  }
}
