<div id="content" class="row">

  <sub-navigation heading="View all company notes"></sub-navigation>


  <div class="col-12">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Notes</h5>
      </div>

      <div class="panel-body">

        <div class="col-12">

          <div class="row">

            <div class="mb-5 col-md-4 col-sm-6 col-6">
            
              <div class="col-12 my-auto">
                <label for="employee">Employee:</label>
              </div>
              <div class="col-12 my-auto">
                  <div class="input-group">
                      <mat-form-field>
                          <input
                            matInput 
                            type="text"
                            aria-label="Employee"
                            [formControl]='brokerSearch'
                            [matAutocomplete]="employee"
                            placeholder="Enter employee name"
                          />
                          <mat-hint>Choose an employee to find stuff they worked on</mat-hint>
    
                          <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayFn.bind(this)">
                              <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>
    
                              <ng-container *ngIf="!isLoading">
                                  <mat-option disabled *ngIf="employees?.length === 0">
                                      <span>No employee found</span>
                                  </mat-option>
                                  <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="runBrokerSearch(employee._id)">
                                      <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                                  </mat-option>
    
                              </ng-container>
                          </mat-autocomplete>
    
                      </mat-form-field>
                  </div> <!-- Close div for .input-group -->
              </div>
    
            </div> <!-- Close div for .mb-5-->
    
            <div class="mb-5 col-md-4 col-sm-6 col-6">

              <div class="col-12 my-auto">
                <label for="employee">Note reference:</label>
              </div>
              <div class="col-12 my-auto">
                <mat-form-field>
                  <input matInput placeholder="Search" name="noteSearch" type="text" [(ngModel)]="noteSearch" (ngModelChange)="runSearch($event)"/>
                  <mat-hint>
                    Find a note reference
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="mb-5 col-md-4 col-sm-6 col-6">

              <div class="col-12 my-auto">
                <label for="employee">Date range:</label>
              </div>
              <div class="col-12 my-auto">

                <div class="row">

                  <div class="col-12 col-sm-6">
                    <mat-form-field>
                      <input placeholder="Start date" matInput [matDatepicker]="searchDateStartPicker" name="searchDateStartPicker"
                      [(ngModel)]="searchDateStart" (ngModelChange)="runSearch($event)">
                      <mat-datepicker-toggle matSuffix [for]="searchDateStartPicker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi #searchDateStartPicker></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-sm-6">
                    <mat-form-field>
                      <input placeholder="End date" matInput [matDatepicker]="searchDateEndPicker" name="searchDateEndPicker"
                      [(ngModel)]="searchDateEnd" (ngModelChange)="runSearch($event)">
                      <mat-datepicker-toggle matSuffix [for]="searchDateEndPicker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi #searchDateEndPicker></mat-datepicker>
                    </mat-form-field>
                  </div>

                </div> <!-- Close div for .row -->

              </div>
            </div>

          </div>

        </div>

        <div class="col-12 mt-2"></div>

        <div class="col-8 offset-2">

          <div class="timeline-v2">

            <div class="timeline-items">

                <div class="timeline-item mt-0 mb-3" *ngFor="let log of activityLogs; let $first = first">

                  <div class="timeline-item-circle">
                    <div class="circle circle-danger"></div>
                  </div>

                  <span class="timeline-time">{{ log?.createdAt | date: 'dd MMMM yyyy'}}</span>
                  <div class="timeline-item-content px-4 py-1">

                    <div class="timeline-item-content-title">
                      <small class="color-label-2">{{ log?.createdAt | date: 'HH:mm'}}</small>
                    </div>

                    <div class="timeline-item-content-body">
                      <p class="mb-1">{{ log?.message }}</p>
                      <p *ngIf="log?.information?.referenceNumber" class="mb-1"><strong>Ref no: </strong> {{ log?.information?.referenceNumber }}</p>
                      <p *ngIf="log?.information?.claim" class="mb-1"><strong>Claim no: </strong> {{ log?.information?.claim }}</p>
                      <p *ngIf="log?.information?.quoteNumber" class="mb-1"><strong>Quote no: </strong> {{ log?.information?.quoteNumber }}</p>
                      <p *ngIf="log?.information?.policyNumber" class="mb-1"><strong>Policy no: </strong> {{ log?.information?.PolicyNumber }}</p>
                      <p class="mb-1"><strong>By: </strong> {{ log?.createdBy?.firstName }} {{ log?.createdBy?.lastName }}</p>
                      <!-- <strong>Type: </strong> {{ log?.logType | sentenceCase }} -->

                      <div class="row mb-2" *ngFor="let item of log?.diff; let index = index">
                        <div class="col-12" *ngIf="index === 0">
                          <p *ngIf="item?.changeFrom?.value"><strong>Changes:</strong></p>
                          <p class="mb-0" *ngIf="!item?.changeFrom?.value">&nbsp;</p>
                        </div>
                        <div *ngIf="item?.changeFrom?.key && item?.changeFrom?.value" class="col-12 col-sm-6">
                          <p class="mb-0"><u>{{ item?.changeFrom?.key | sentenceCase }}</u></p>
                          <p class="mb-0" *ngIf="item?.changeFrom?.prop">{{ item?.changeFrom?.prop | sentenceCase }}</p>
                          <p class="mb-0"><strong>was: </strong>{{ item?.changeFrom?.value }}</p>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="item.changeFrom?.value">
                          <p class="d-none d-sm-block mb-0" *ngIf="item?.changeFrom?.key">&nbsp;</p>
                          <p class="mb-0" *ngIf="item?.changeTo?.prop">&nbsp;</p>
                          <p class="mb-0" *ngIf="item?.changeFrom?.key"><strong>is now: </strong>{{ item?.changeTo?.value }}</p>
                        </div>

                        <div class="col-12 col-sm-6" *ngIf="!item.changeFrom?.value">
                          <ng-container *ngFor="let note of item?.changeTo?.value | keyvalue">
                            <p *ngIf="note?.key !== '_id'" class="mb-0"><strong>{{ note?.key | sentenceCase }}</strong></p>
                            <p *ngIf="note?.key !== '_id' && note?.key !== 'emailBody'">{{ formatValue(note?.value) }}</p>
                            <p *ngIf="note?.key == 'emailBody'" [innerHtml]="note?.value"></p>
                          </ng-container>
                        </div>
                      </div>

                    </div>

                  </div> <!-- Close div for .timeline-content -->

                </div> <!-- Close div for .timeline-item -->

            </div> <!-- Close div for timeline-items -->

            <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getActivityLog()"></div>

            <div class="text-center mt-5">
                <p class="mb-0"><b>--- END OF NOTES ---</b></p>
            </div>

          </div> <!-- Close div for .timeline-v1 -->

        </div> <!-- Close div for .col-10 -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for .row -->
