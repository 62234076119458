<div id="content" class="row">
    <sub-navigation heading="Add new returned debit order "></sub-navigation>

    <div class="col-12">

        <form [formGroup]="returnedDebitFormGroup">
  
            <div class="panel panel-primary">

                <div class="panel-heading">
                    <h5>Returned debit order details</h5>
        
                    <div class="my-auto">
        
                    <button type="button" matTooltip="Go back to view all returned debit orders" class="btn btn-secondary my-auto" (click)="goBack()">
                        <i class="fi flaticon-back"></i>Back
                    </button>
        
                    <div class="btn-group" ngbDropdown>

                        <button type="submit" class="btn btn-primary" mat-tooltip="Save returned debit order and go back." (click)="createNewReturnedDebit('back')">
                            Save returned debit
                        </button>

                        <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                        </button>
                        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewReturnedDebit('continue')">Save &amp; continue</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewReturnedDebit('new')">Save &amp; add new</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="createNewReturnedDebit('back')">Save &amp; go back</a></li>
                        </ul>

                    </div> <!-- Close div for .btn-group -->
        
                    </div> <!-- Close div for .my-auto -->
        
                </div>
        
                <div class="panel-body">
                    
                    <div class="row">
                        <returned-debit-order-form-group class="col-12" formControlName="returnedDebitOrder"></returned-debit-order-form-group>
                    </div>

                </div>

            </div>

        </form>

    </div>

</div>