<h1 [ngClass]="authenticated ? 'text-black' : 'text-white'">Report a bug</h1>

<form id="newIssueForm" [formGroup]="newIssueForm" (ngSubmit)="reportIssue()">

    <label for="name"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Your name:</strong></label>
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.submitterName.errors }"
      name="submitterName"
      formControlName="submitterName"
    />
    <div *ngIf="submitted && form.submitterName.errors" class="invalid-feedback">
      <div *ngIf="form.submitterName.errors.required">Please provide your name.</div>
    </div>

    <label for="email"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Your email:</strong></label>
    <input
      type="email"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.submitterEmail.errors }"
      name="submitterEmail"
      formControlName="submitterEmail"
      pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    />
    <div *ngIf="submitted && form.submitterEmail.errors" class="invalid-feedback">
      <div *ngIf="form.submitterEmail.errors.required">Please provide your email address.</div>
      <div *ngIf="form.submitterEmail.errors.email || form.submitterEmail.errors.pattern">Please provide a valid email address.</div>
    </div>

    <label for="subject"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Bug/Issue subject:</strong></label>
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.subject.errors }"
      name="subject"
      formControlName="subject"
    />
    <div *ngIf="submitted && form.subject.errors" class="invalid-feedback">
      <div *ngIf="form.subject.errors.required">Please provide a subject for your bug.</div>
    </div>

    <label for="description"><strong [ngClass]="authenticated ? 'text-black' : 'text-white'">Bug:</strong></label>
    <ckeditor
      id="editor"
      name="description"
      [editor]="Editor"
      [ngClass]="{ 'is-invalid': submitted && form.description.errors }"
      formControlName="description">
    </ckeditor>
    <div *ngIf="submitted && form.description.errors" class="invalid-feedback">
      <div *ngIf="form.description.errors.required">Please provide a description of the bug.</div>
    </div>

    <app-save-button type="submit" buttonText="Report bug"></app-save-button>

</form>