import { Injectable } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Injectable()
export class Globals {

    TITLE: String = "SureSpace";

    SERVER_URL: String = "https://www.surespace.co.za"; //PROD
    // SERVER_URL: String = "https://uat.surespace.co.za"; //UAT
    // SERVER_URL: String = "http://localhost:8000"; //DEV

    ME:any = {};

    LOADING:Boolean = false;

    GET_USER_TOKEN(){
        return localStorage.getItem('SureSpace-userToken')
    }

    get AUTENTICATED(){
        // this.ME = JSON.parse(localStorage.getItem('user'));
        return !!this.GET_USER_TOKEN();
    }

    PI_TAB: string;
    MA_TAB: string;

}
