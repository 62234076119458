import { AuthenticationService } from './../../../../../services/authentication.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { HouseholdItem, HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DeleteModalComponent } from 'src/app/components/modal/delete/delete.component';
import { Broker } from 'src/app/_shared/models/brokerClaims.model';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { HomeContentResponse, HomeContent } from 'src/app/_shared/models/homeContentInsurance.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { DeleteHouseholdItemComponent } from 'src/app/components/modal/delete-household-item/delete-household-item.component';

@Component({
  selector: 'app-broker-view-user-household-insurance',
  templateUrl: './broker-view-user-household-insurance.component.html',
  styleUrls: ['./broker-view-user-household-insurance.component.scss'],
  providers: [DeleteHouseholdItemComponent, BrokerComponent]
})
export class BrokerViewUserHouseholdInsuranceComponent implements OnInit, AfterViewInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userHomeContent: HouseholdItem[];
  previouslyInsuredUserHomeContent: any;

  displayedColumns: string[] = ['structureType', 'structureAddress', 'contentValue', 'jeweleryValue', 'inceptionDate', 'menu'];
  resultsLength = 0;
  pageSize = 25;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSizeOptions: number[] = [5, 10, 25, 100]

  resultsLengthPreviousInsurance = 0;
  pageSizePreviousInsurance = 25;
  isRateLimitReachedPreviousInsurance = false;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private homeContentService: BrokerClientHomeContentService,
    private deleteHomeContentModal: DeleteHouseholdItemComponent,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getUserHouseholdItem(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReached){
      this.homeContentService.getAllHomeContentInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<HomeContentResponse>) => {

          console.log('testing => ', response)

          this.isLoadingResults = false;
          this.userHomeContent = response.body.homeContent;

          this.resultsLength = response.body.count;
          if(this.userHomeContent.length < this.pageSize){
            this.isRateLimitReached = true;
          }
        }
      )
    }
  };

  getUserPreviouslyInsuredHouseholdItem(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isRateLimitReachedPreviousInsurance){
      this.homeContentService.getPreviousHomeContentInsuranceForClient(this.selectedUser, this.brokerComponent.brokerCompany.companyName, req).subscribe(
        (response:HttpResponse<HomeContentResponse>) => {
          this.isLoadingResults = false;
          this.previouslyInsuredUserHomeContent = response.body.homeContent;

          this.resultsLengthPreviousInsurance = response.body.count;
          if(this.previouslyInsuredUserHomeContent.length < this.pageSize){
            this.isRateLimitReachedPreviousInsurance = true;
          }
        }
      )
    }
  };

  deleteContent(content:HomeContent){
    content['clientId'] = this.selectedUser;
    this.deleteHomeContentModal.openDialog(content);
    this.deleteHomeContentModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userHomeContent = this.userHomeContent.filter(item => item != content);
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserHouseholdItem();
    this.getUserPreviouslyInsuredHouseholdItem();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
