<div id="content" class="row">

    <sub-navigation heading="{{ editFamilyMember ? 'Edit' : 'Add new' }} family member"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
        <form id="familyMemberFormGroup" [formGroup]="familyMemberFormGroup">
  
          <div class="panel-heading">
            <h5>Family member information</h5>
  
            <div class="my-auto">
  
              <button type="button" matTooltip="Go back to view client family members" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>
  
              <div class="btn-group" ngbDropdown>
                <button type="submit" class="btn btn-primary" mat-tooltip="Save family member and go back." (click)="editFamilyMember ? updateFamilyMember('back') : addNewFamilyMember('back')">
                  Save family member
                </button>
                <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" (click)="editFamilyMember ? updateFamilyMember('continue') : addNewFamilyMember('continue')">Save &amp; continue</a>
                  </li>
                  <li role="menuitem"><a class="dropdown-item" (click)="editFamilyMember ? updateFamilyMember('back') : addNewFamilyMember('back')">Save &amp; go back</a></li>
                </ul>
              </div> <!-- Close div for .btn-group -->
  
            </div> <!-- Close div for .my-auto -->
  
          </div>
  
          <div class="panel-body">
              
                <div class="row">

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="text"
                                    [ngClass]="{ 'is-invalid': formSubmitted && familyMemberForm['name'].errors }"
                                    placeholder="Name"
                                    name="name"
                                    formControlName="name"
                                />

                                <mat-error>
                                    Please enter a <strong>name</strong> for the family member.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>
    
                    <div class="col-sm-6 col-12 mb-3">

                        <mat-form-field>
                            <input 
                                matInput
                                type="text"
                                [ngClass]="{ 'is-invalid': formSubmitted && familyMemberForm['surname'].errors }"
                                placeholder="Surname"
                                name="surname"
                                formControlName="surname"
                            />

                            <mat-error>
                                Please enter a <strong>surname</strong> for the family member.
                            </mat-error>

                        </mat-form-field>
                        
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field>
                                <mat-select placeholder="Relationship" name="relationship" formControlName="relationship">
                                    <mat-option value="Spouse">Spouse</mat-option>
                                    <mat-option value="Child">Child</mat-option>
                                    <mat-option value="Parent">Parent</mat-option>
                                    <mat-option value="Nominated">Nominated</mat-option>
                                </mat-select>

                                <mat-error>
                                    Please provide a <strong>relationship</strong> to the family member.
                                </mat-error>

                            </mat-form-field>   
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3" *ngIf="false && familyMemberForm['relationship'].value === 'Spouse'">
                        <div class="input-group">
                            <mat-form-field>
                                <input matInput name="anniversaryDate" placeholder="Anniversary date" [matDatepicker]="anniversaryDate" name="anniversaryDate" formControlName="anniversaryDate">
                                <mat-datepicker-toggle matSuffix [for]="anniversaryDate"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #anniversaryDate></mat-datepicker>
                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field>
                                <input 
                                    matInput
                                    type="text"
                                    [ngClass]="{ 'is-invalid': formSubmitted && familyMemberForm['idNumber'].errors }"
                                    placeholder="ID number"
                                    name="idNumber"
                                    formControlName="idNumber"
                                />

                                <mat-error *ngIf="familyMemberForm['idNumber'].errors">
                                    <div *ngIf="familyMemberForm['idNumber'].errors.required">Please enter an <strong>ID number</strong> for the family member.</div>
                                    <div *ngIf="familyMemberForm['idNumber'].errors.invalidIdNumber">Please enter a valid <strong>SA ID number</strong>.</div>
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-12 clearfix"></div>

                    <div class="col-6">
                        <mat-form-field>
                          <input matInput placeholder="Email" name="firstName" type="email" formControlName="email" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-6">
                        <mat-form-field>
                          <input matInput placeholder="Cellphone" name="cellphone" type="text" formControlName="cellphone" />
                        </mat-form-field>
                      </div>

                      <div class="col-12 clearfix"></div>

                    <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence">
                        <mat-form-field>
                            <mat-select placeholder="Drivers license" name="licenceCode" formControlName="licenceCode">
                                <mat-option value="None">
                                None
                                </mat-option>

                                <mat-optgroup label="Motorcycles">
                                <mat-option value="Code A1">Code A1</mat-option>
                                <mat-option value="Code A">Code A</mat-option>
                                </mat-optgroup>

                                <mat-optgroup label="Light motor vehicles">
                                <mat-option value="Code B">Code B</mat-option>
                                <mat-option value="Code EB">Code EB</mat-option>
                                </mat-optgroup>

                                <mat-optgroup label="Heavy motor vehicles">
                                <mat-option value="Code C1">Code C1</mat-option>
                                <mat-option value="Code C">Code C</mat-option>
                                <mat-option value="Code EC1">Code EC1</mat-option>
                                <mat-option value="Code EC">Code EC</mat-option>
                                </mat-optgroup>
                            </mat-select>

                            <mat-error>
                                Please provide a <strong>licence code</strong> to the family member drivers licence.
                            </mat-error>

                        </mat-form-field>      
                    </div>

                    <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence">
                        <mat-form-field class="example-full-width">
                            <input placeholder="First issue" matInput [matDatepicker]="driversLicenseFirstIssue" name="driversLicenceFirstIssue"
                            formControlName="licenceFirstIssue">
                            <mat-datepicker-toggle matSuffix [for]="driversLicenseFirstIssue"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #driversLicenseFirstIssue></mat-datepicker>

                            
                            <mat-error>
                                Please provide the <strong>first issue date</strong> on the family member drivers licence.
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence">
                        <mat-form-field class="example-full-width">
                            <input placeholder="Valid until" matInput [matDatepicker]="driversLicenseValidUntil" name="driversLicenceExpiryDate"
                                formControlName="licenceExpiryDate">
                            <mat-datepicker-toggle matSuffix [for]="driversLicenseValidUntil"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #driversLicenseValidUntil></mat-datepicker>
                        </mat-form-field>
                    </div>
    
    
                </div> <!-- Close div for .row -->
          </div> <!-- Close div for .panel-body -->
  
        </form>
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-12 -->
  
  </div> <!-- Close div for #content -->
  