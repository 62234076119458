<h2 mat-dialog-title>Upload Document</h2>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="uploadDocumentFormGroup">

        <input class="mb-3" type="file" ng2FileSelect [uploader]="uploader" (change)="handleUploadedFile($event.target.files)" />

        <mat-form-field>
            <mat-label>Document type</mat-label>
            <mat-select formControlName="documentItemType" (selectionChange)="clearDocumentItem()">
              <mat-option value="general" (click)="uploadEmailDocument = false">General</mat-option>
              <mat-option value="instructions" (click)="uploadEmailDocument = false">Admin Instruction</mat-option>
              <mat-option value="claims" (click)="uploadEmailDocument = false">Claims</mat-option>
              <mat-option value="quotations" (click)="uploadEmailDocument = false">Quotation</mat-option>
              <mat-option value="policies" (click)="uploadEmailDocument = false">Policy schedule</mat-option>
              <mat-option value="email" (click)="uploadEmailDocument = true">Email</mat-option>
              <mat-option value="returnedDebit" (click)="uploadEmailDocument = false">Returned debit order</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="uploadEmailDocument">
            <mat-label>Email reference</mat-label>
            <mat-select formControlName="documentItemType">
              <mat-option value="instructions">Admin Instruction</mat-option>
              <mat-option value="claims">Claims</mat-option>
              <mat-option value="quotations">Quotation</mat-option>
              <mat-option value="returnedDebit">Returned debit order</mat-option>
            </mat-select>
          </mat-form-field>

          <div
            class="input-group"
            *ngIf="uploadDocumentFormGroup.get('documentItemType').value && uploadDocumentFormGroup.get('documentItemType').value !== 'general'">
            <mat-form-field>
                <input matInput type="text" aria-label="Document number" placeholder="Document {{ uploadDocumentFormGroup.get('documentItemType').value | sentenceCase }}" formControlName='documentItem' [matAutocomplete]="itemsAutoComplete" />

                <mat-autocomplete autoActiveFirstOption #itemsAutoComplete="matAutocomplete" [displayWith]="displayFn.bind(this)" (opened)="autocompleteScroll()">
                    <mat-option *ngIf="isLoading" class="is-loading">Finding {{ uploadDocumentFormGroup.get('documentItemType').value }}...</mat-option>

                    <ng-container *ngIf="!isLoading">
                        <mat-option disabled *ngIf="items?.length === 0 && uploadDocumentFormGroup.get('documentItemType').value">
                            <span>No {{ uploadDocumentFormGroup.get('documentItemType').value }} found</span>
                        </mat-option>
                        <mat-option *ngFor="let item of items" [value]="item">
                          <span *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'instructions'">{{ item?.instructionType }} - {{ item?.product ? item?.product?.description : '' }}{{ item?.referenceNumber }}</span>
                          <span *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'policies'">{{ item?.policyNumber }}</span>
                          <span *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'claims'">{{ item?.claimType | sentenceCase }} - {{ item?.claimDescription | sentenceCase }} - {{ item?.product?.description }}</span>
                          <span *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'returnedDebit'">{{ item?.referenceNumber }} - {{ item?.policyNumber}}</span>
                          <span *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'quotations'">{{ item?.quoteNumber }} - {{ item?.client?.lastName}}{{item?.client?.companyName }}</span>
                        </mat-option>

                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select
                    <strong *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'policies'">{{ 'policy number' | indefinite }}</strong> 
                    <strong *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'instructions'">{{ 'instruction' | indefinite }}</strong> 
                    <strong *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'claims'">{{ 'claim number' | indefinite }}</strong> 
                    <strong *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'returnedDebit'">{{ 'returned debit order' | indefinite }}</strong> 
                    <strong *ngIf="uploadDocumentFormGroup.get('documentItemType').value === 'quotations'">{{ item?.quoteNumber }} - {{ item?.client?.lastName}}{{item?.client?.companyName }}</strong>
                    for this document.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->

        <div class="row mt-3">
          <div class="col-12">
            <label for="accessDate">Document description:</label>
          </div>
          <div class="col-12">
            <textarea type="text" class="form-control" placeholder="Document description" formControlName="documentDescription"></textarea>
          </div>
        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-4 mb-1">
  <button class="btn btn-outline-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" (click)="uploadFile()" cdkFocusInitial>Upload</button>
</mat-dialog-actions>