import { User } from './User.model';
import { Basemodel } from './basemodel';
import { SuccessMessage } from './successMessage.model';

export class ActivityLog implements Basemodel {
  constructor(
    public _id: string,
    public action: string,
    public category: string,
    public createdBy: User,
    public message: string,
    public diff: any,
    public company: string,
    public userRef: User,
    public itemRefNr: string,
    public createdAt: Date
  ){}
}

export class ActivityLogResponse {
    constructor(
      public activity: ActivityLog[],
      public message: SuccessMessage
    ) {
    }
  }