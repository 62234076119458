import { Pipe, PipeTransform } from '@angular/core';

let excludeList = ['SureSpace'];

@Pipe({
  name: 'sentenceCase'
})

export class SentenceCasePipe implements PipeTransform {

  public createSentence(sentence){
    if(typeof sentence === 'string'){
      const regExr = new RegExp(/(?<=\p{Ll})(?=\p{Lu})/u)
      sentence = sentence.split(regExr);

      sentence.forEach(item => {
        return item.toLowerCase()
      });
      
      sentence = sentence.join(' ');
      sentence = sentence[0].toUpperCase() + sentence.substr(1);
    }
    return sentence
  }

  transform(value: any, ...args: any): any {

    excludeList = [...new Set([...excludeList, ...args])];
    
    if(value && Array.isArray(value)){

      value.map(item => {

        let sentence = item.key || item;
        sentence = this.createSentence(sentence);

        return item.key = sentence
      })

      return value;
    } 
    
    if(value && !Array.isArray(value)){

        let sentence = value;

        // if the value is not an argument, proceed with split
        if(!args.includes(value)){

          // if the sentence is in the exclude list, exclude it
          if(!sentence.includes(excludeList.map(word => word))){
            sentence = this.createSentence(sentence);
          } else {

            let replacedWordLength;
            let replacedWord = sentence.search(excludeList.map(word => { replacedWordLength = word.length; return word })); //returns int
            replacedWord = new RegExp(`${sentence.substring(replacedWord, replacedWord + replacedWordLength)}`);

            sentence = sentence.replace(replacedWord, '...')
            sentence = this.createSentence(sentence)
            sentence = sentence.replace('...', ` ${replacedWord} `).replace(/\//g, '').trim();
          }

        } else {
          sentence = sentence.replace(/^./, letter => letter.toUpperCase())
        }

        return sentence
    }

    return null
  }

}
