import { Basemodel } from './basemodel';
import { Broker } from './broker.model'
import { SuccessMessage } from './successMessage.model';

export class User {
  constructor(
    public _id: string,
    public idNumber: string,
    public initials: string,
    public username: string,
    public taxNumber: string,
    public email: string,
    public firstName?: string,
    public lastName?: string,
    public gender?: string,
    public cellphone?: string,
    public homephone?: string,
    public workphone?: string,
    public alternateNumber?: string,
    public insuranceType?: InsuranceType,
    public userType?: string,
    public company?: [Company],
    public brokerCode?: string,
    public createdAt?: string,
    public permissions?: string[],
    public cancelled?: boolean,
    public disabled?: boolean,
    public captcha?: string,
    public token?: string,
    public middleName?: string,
    public driversLicence?: {
      licenceCode?: string,
      licenceFirstIssue?: Date,
      licenceExpiryDate?: Date
    },
    public employer?: string,
    public jobDescription?: string,
    public salary?: string,
    public userRef?: string,
    public clientNumber?: string,
    public broker?: Broker,
    public companyName?: string
  ){}
}

export class UserMaritalInfo implements Basemodel {
  constructor(
    public maritalStatus?: string,
    public anniversaryDate?: Date,
    public userRef?: string
  ){}
}

export class Company {
  constructor(
    public companyName?: string,
    public companyBrokerCode?: string,
    public startDate?: Date,
    public terminationDate?: Date,
    public status?: string,
    public currentlyEmployed?: boolean,
    public employeeCode?: string,
    public permissions?: string[]
  ){}
}

export class InsuranceType {
  constructor(
    public householdInsurance?: boolean,
    public vehicleInsurance?: boolean,
    public buildingInsurance?: boolean,
    public personalItemInsurance?: boolean,
    public trailersAndCaravansInsurance?: boolean
  ){}
}

export class UserResponse {
  constructor(
    public user: User[],
    public count?: number,
    public message?: SuccessMessage
  ) {
  }
}