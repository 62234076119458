import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bootstrap-tabs',
  templateUrl: './bootstrap-tabs.component.html',
  styleUrls: ['./bootstrap-tabs.component.scss']
})
export class BootstrapTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
