import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit {

  @Input() buttonText: string = 'Save';
  @Input() disabled: string = 'false';
  @Input() type: string = 'submit';
  @Input() class: string;

  constructor() { }

  ngOnInit() {
  }

}
