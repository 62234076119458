import { User } from 'src/app/_shared/models/User.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { BrokerService } from 'src/app/services/broker.service';
import { BrokerComponent } from 'src/app/broker/broker.component';

@Component({
  template: ''
})
export class DeleteClientComponent implements OnInit {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(client: User): void {

    this.dialogRef = this.dialog.open(DeleteClientComponentPreview, {
      // width: '600px',
      data: client
    });
  }

  ngOnInit() {
  }

}


@Component({
  templateUrl: './delete-client.component.html',
  styleUrls: ['./delete-client.component.scss'],
  providers: [BrokerComponent]
})
export class DeleteClientComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteClientComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public client: User,
    public brokerService: BrokerService,
    private router: Router,
    private brokerComponent: BrokerComponent
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeClient(client_id:string){
    this.brokerService.removeClient(client_id, this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<UserResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}