import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'style-guide-typography',
  templateUrl: './style-guide-typography.component.html',
  styleUrls: ['./style-guide-typography.component.scss']
})
export class StyleGuideTypographyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
