import { ActivityLogResponse } from './../_shared/models/activityLogs.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getActivityLogs(companyName: string, req: any = {}): Observable<HttpResponse<ActivityLogResponse>>{
    return this.http.get<ActivityLogResponse>(`${this.globals.SERVER_URL}/api/reporting/activityLog/${companyName}`, {
      observe: 'response',
      params: req
    });
  }

  getActivityLogsPerUser(companyName: string, userId: string, req: any = {}, noLoad = false): Observable<HttpResponse<ActivityLogResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }
    
    return this.http.get<ActivityLogResponse>(`${this.globals.SERVER_URL}/api/reporting/userActivityLog/${userId}/${companyName}`, {
      params: req,
      observe: 'response',
      headers: headers
    });
  }

  getInstructionReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/instructions/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getClaimReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/claims/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getPolicyReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/policies/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getQuoteReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/quotes/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getReturnedDebitReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/returnedDebits/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getPerEmployeeReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/employees/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getCalendarEventsReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/calendarEvents/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

  getClientsReports(companyName: string, req?: any){
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/reporting/clientReport/${companyName}`, {
      params: req,
      observe: 'response'
    })
  }

}
