import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {

  @Input() buttonText: string = 'Save';
  @Input() buttonColor: string = 'brand';
  @Input() buttonType: string = 'button';
  @Input() class:string;

  constructor() { }

  ngOnInit() {
  }

}
