<div class="page-description">
    <div class="row h-100">
      <div class="col-6 align-self-center">
        <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
        <h6 class="d-inline-block mb-0">Typography</h6>
      </div>
  
      <!-- <div class="col-6 text-right align-self-center">
        <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button>
      </div> -->
    </div>  
  </div>
  
  <div class="row">
  
    <div class="col-6 float-left">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Headings</h5>
        </div>
  
        <div class="panel-body">

          <p>All HTML headings, <code>&lt;h1&gt;</code> through <code>&lt;h6&gt;</code>, are available.</p>

          <div class="section">

            <div class="row">

              <div class="col-6">

                <h1>h1. Heading 1</h1>
                <h2>h2. Heading 2</h2>
                <h3>h3. Heading 3</h3>
                <h4>h4. Heading 4</h4>
                <h5>h5. Heading 5</h5>
                <h6>h6. Heading 6</h6>

              </div> <!-- Close div for .col-6 -->

              <div class="col-6">

                <h1 class="color-success">h1. Heading 1</h1>
                <h2 class="color-info">h2. Heading 2</h2>
                <h3 class="color-warning">h3. Heading 3</h3>
                <h4 class="color-danger">h4. Heading 4</h4>
                <h5 class="color-primary">h5. Heading 5</h5>
                <h6 class="color-brand">h6. Heading 6</h6>

              </div> <!-- Close div for .col-6 -->

            </div> <!-- Close div for .row -->

          </div> <!-- Close div for .section -->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Use the included utility classes to recreate the small secondary heading text.</p>

          <div class="section">
            <h3>
              Fancy display heading
                <small class="text-muted">with faded secondary text</small>
            </h3>
          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Larger, slightly more opinionated heading styles.</p>

          <div class="section">
            <h3 class="display-1">Display 1</h3>
            <h3 class="display-2">Display 2</h3>
            <h3 class="display-3">Display 3</h3>
          </div> <!-- Close div for .section-->

          <hr class="hr-light hr-dashed mb-2" />
          <p>Make a paragraph stand out by adding <code>.lead</code>.</p>

          <div class="section">
            <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div> <!-- Close div for .section-->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->
  
    <div class="col-6">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Text</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Basic text examples</p>
  
          <div class="section">
            <p>Example text</p>
            <p><span class="font-weight-light">Example light text</span></p>
            <p><strong>Example bold text</strong></p>
            <p><span class="font-weight-bold">Example bolder text</span></p>
            <p><span class="font-weight-bolder">Example boldest text</span></p>
            <p><span class="uppercase">Example uppercase text</span></p>
          </div> <!-- Close div for .section -->

          <p class="mt-4">State colors</p>
  
          <div class="section">
            <p class="color-success">Success state text</p>
            <p class="color-warning">Warning state text</p>
            <p class="color-info">Info state text</p>
            <p class="color-danger">Danger state text</p>
            <p class="color-primary">Primary state text</p>
          </div> <!-- Close div for .section -->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
  
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Links</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Basic link examples</p>

          <div class="section">
            <p><a>Example text</a></p>
            <p><a class="font-weight-light">Example light text</a></p>
            <p><a class="font-weight-bold">Example bolder text</a></p>
            <p><a class="font-weight-bolder">Example boldest text</a></p>
            <p><a class="uppercase">Example uppercase text</a></p>
          </div> <!-- Close div for .section -->

          <p class="mt-4">State colors</p>
  
          <div class="section">
            <p><a class="color-success">Success state text</a></p>
            <p><a class="color-warning">Warning state text</a></p>
            <p><a class="color-info">Info state text</a></p>
            <p><a class="color-danger">Danger state text</a></p>
            <p><a class="color-primary">Primary state text</a></p>
          </div> <!-- Close div for .section -->
  
        </div>
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->

    <div class="col-6"> 
    
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>General</h5>
        </div>
  
        <div class="panel-body">
  
          <p>Styling for common inline HTML5 elements:</p>
  
          <div class="section">

            <p>You can use the mark tag to <mark>highlight</mark> text.</p>
            <p><del>This line of text is meant to be treated as deleted text.</del></p>
            <p><s>This line of text is meant to be treated as no longer accurate</s></p>
            <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
            <p><u>This line of text will render as underlined</u></p>
            <p><small>This line of text is meant to be treated as fine print.</small></p>
            <p><strong>This line rendered as bold text.</strong></p>
            <p><em>This line rendered as italicized text.</em></p>

          </div> <!-- Close div for .section -->

          <p class="mt-4">Stylized abbreviations implementation of HTML's <code>&lt;abbr&gt;</code> element:</p>
  
          <div class="section">

            <p><abbr title="attribute">attr</abbr></p>
            <p><abbr title="HyperText Markup Language" class="initialism">HTML</abbr></p>

          </div> <!-- Close div for .section -->

          <p class="mt-4">Quoting blocks of content:</p>
  
          <div class="section">

            <blockquote class="blockquote">
              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              <div class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></div>
            </blockquote>

            <blockquote class="blockquote blockquote-reverse">
              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              <div class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></div>
            </blockquote>

          </div> <!-- Close div for .section -->
  
        </div> <!-- Close div for .panel-body -->
  
      </div> <!-- Close div for .panel -->
  
    </div> <!-- Close div for .col-6 -->

    <div class="col-6"> 
    
        <div class="panel panel-primary">
    
          <div class="panel-heading">
            <h5>Section</h5>
          </div>
    
          <div class="panel-body">
    
            <div class="panel-body-title">
              Panel body title
            </div>

            <div class="panel-body-desc">
              Panel body description
            </div>

            <div class="panel-body-content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <hr class="hr-light hr-dashed" />

            <div class="panel-body-desc">
              Another info text.
            </div>

            <div class="panel-body-content">
              <p>
                Aipiscing ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aadipiscing elit, sed do eiusmod tempor, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <hr class="hr-light hr-dashed" />

            <div class="panel-body-desc">
              Another description text.
            </div>

            <div class="panel-body-content">
              <p>
                Aipiscing ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aadipiscing elit, sed do eiusmod tempor, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
    
          </div> <!-- Close div for .panel-body -->
    
        </div> <!-- Close div for .panel -->
    
      </div> <!-- Close div for .col-6 -->
  
  </div> <!-- Close div for .row -->