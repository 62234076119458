import { HttpResponse } from '@angular/common/http';

import { User } from 'src/app/_shared/models/User.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Globals } from './app.globals';
import { AuthenticationService } from './services/authentication.service';
import { SEOService } from './services/seo.service';
import { ManagementService } from './services/management.service';
import { Location } from '@angular/common';
import {CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  currentUser;
  authenticated: Boolean;

  thisYear = new Date().getFullYear();

  showSuggestions: false;

  newTask:string;
  tasks = [];
  taskStatus:string = '';

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public globals: Globals,
    private authService: AuthenticationService,
    private seo: SEOService,
    private managementService: ManagementService,
    private _location: Location
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.authenticated = !!user;  
    })

  }

goToNew() {
  const newSite = 'https://mysurespace.co.za';
  // window.location.href = newSite;
  // this._location.go(newSite)
  window.open(newSite, "_blank")
}


  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  addTask(task:string){

    if(!this.newTask) return

    this.managementService.addSuggestion({ suggestion: task }).subscribe(
      (response:HttpResponse<any>) => {
        this.newTask = undefined;
        this.getTasks();
      }
    )
  }

  getTasks(status?:string){

    this.taskStatus = status;

    let req = {}

    if(status === 'done') req['doneTask'] = true;
    if(status === 'incomplete') req['doneTask'] = false;
    if(status === 'deleted') req['deletedTask'] = true;

    this.managementService.getSuggestions(req).subscribe(
      (response:HttpResponse<any>) => {
        this.tasks = response.body.suggestion;
      }
    )
  }

  completeTask(taskId:string, status:boolean){
    this.managementService.editSuggestion(taskId, { doneTask: status }).subscribe(
      (response:HttpResponse<any>) => {
        console.log('response -> ', response)
        this.getTasks(this.taskStatus)
      }
    )
  }

  deleteTask(taskId:string){
    this.managementService.editSuggestion(taskId, { deletedTask: true }).subscribe(
      (response:HttpResponse<any>) => {
        this.getTasks(this.taskStatus)
      }
    )
  }

  sortTasks(tasks){
    this.managementService.sortSuggestions(tasks).subscribe(
      (response:HttpResponse<any>) => {
        this.getTasks(this.taskStatus)
      }
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);
    this.sortTasks(this.tasks);
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(){
    // this.goToNew();
    if(this.currentUser && this.currentUser.userType === 'broker'){
      this.getTasks()
    }
    
  }

}
