import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from './../../../../../services/authentication.service';
import { BrokerService } from 'src/app/services/broker.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-broker-new-company-location',
  templateUrl: './broker-new-company-location.component.html',
  styleUrls: ['./broker-new-company-location.component.scss']
})
export class BrokerNewCompanyLocationComponent implements OnInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @Input() view:boolean

  addressFormGroup: FormGroup;

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<BrokerNewCompanyLocationComponent>,
    private brokerService: BrokerService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.addressFormGroup = this.formBuilder.group({
      locations: this.formBuilder.group({
        addressDescription: [''],
        addressLine1: [''],
        addressLine2: [''],
        unit: [''],
        city: [''],
        suburb: [''],
        province: [''],
        country: ['South Africa'],
        postalCode: [''],
        municipalAccNr: [''],
        stand: [''],
        formattedAddress: ['']
      })
    })
  }

  addLocation(){
    this.brokerService.addCompanyAddress(this.authenticationService.currentCompany.companyName, this.addressFormGroup.value).subscribe(response => {
      this.dialogRef.close('success')
    })
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let homeFormGroupAddress = this.addressFormGroup.get('locations');

    homeFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    homeFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    homeFormGroupAddress.get('city').setValue(findInAddress('locality'));
    homeFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    homeFormGroupAddress.get('country').setValue(findInAddress('country'));
    homeFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    homeFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

}
