import { User } from 'src/app/_shared/models/User.model';
import { BrokerService } from 'src/app/services/broker.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActivityLog, ActivityLogResponse } from 'src/app/_shared/models/activityLogs.model';
import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { ReportingService } from 'src/app/services/reporting.service';
import { HttpResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'broker-view-user-notes',
  templateUrl: './broker-view-user-notes.component.html',
  styleUrls: ['./broker-view-user-notes.component.scss'],
})
export class BrokerViewUserNotesComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() newestNote:ActivityLog;

  selectedUser = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  activityLogs: ActivityLog[] = [];

  page = 0;
  fetchActivities:boolean = true;

  noteSearch:string = '';
  brokerSearch = new FormControl(null);

  employees = [];

  loadingResults = false;
  isLoading:boolean = false;

  searchDateStart: Date;
  searchDateEnd: Date;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private brokerService: BrokerService
  ) { }

  ngOnInit() {
    this.getAllEmployees();
  }

  ngOnChanges(change: SimpleChanges){
    console.log('change => ', change)
    if(change.newestNote?.currentValue){
      change.newestNote.currentValue.createdBy = {
        firstName: this.authenticationService.currentUserValue.firstName,
        lastName:this.authenticationService.currentUserValue.lastName
      }
      this.activityLogs.unshift(change.newestNote.currentValue)
    }
  }

  getAllEmployees(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(response => {
      this.employees = response.body.user;

      this.brokerSearch.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        // switchMap(value => this.brokerService.getAllClients(this.currentCompany, {search: value}, true)
        switchMap(value => this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName, {search: value}, true)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response) => {
          this.employees = response.body.user;
        }
      )
    })
  }

  ngAfterViewInit() {
    this.getActivityLogs();
    this.changeDetection.detectChanges();
  }

  displayFn(user?: User) {
    if(!user) return
    return user._id || user['brokerId'] ? `${user.firstName} ${user.lastName}` : typeof user === 'string' ? user : undefined;
  }


  runSearch(search){
    this.loadingResults = true;
    this.page = 0;
    this.fetchActivities = true;
    this.activityLogs = [];

    this.getActivityLogs(this.noteSearch, true)
  }

  runBrokerSearch(employeeId:string){

    // this.loadingResults = true;
    this.page = 0;
    this.fetchActivities = true;
    this.activityLogs = [];

    setTimeout(() => {
      this.getActivityLogs()
    }, 500)
  }

  getActivityLogs(search = '', noLoad = false) {

    let req = {
      page: this.page,
      limit: 10,
      search: this.noteSearch,
      broker: this.brokerSearch.value?._id || null,
      startDate: this.searchDateStart,
      endDate: this.searchDateEnd
    }

    if(this.fetchActivities){
      this.reportingService.getActivityLogsPerUser(this.authService.currentCompany.companyName, this.selectedUser, req, noLoad).subscribe(
        (response: HttpResponse<ActivityLogResponse>) => {
  
          if(response.body.activity.length < req.limit){
            this.fetchActivities = false;
          }
  
          this.page = this.page + 1;

          response.body.activity.map(activity => {
            if(activity.action != 'POST') activity.diff = this.createData(activity.diff);
            this.activityLogs.push(activity)
          })

          this.changeDetection.detectChanges();
          
          
          setTimeout(() => {
            this.loadingResults = false
          }, 1000)
        }
      )
    }
  }

  createData(data){

    if(!data) return

    let changes = [];

    function mapData(item, key:string, prop?){

      let property = prop;

      for(let prop in item){

        if(prop === 'changeFrom' || prop === 'changeTo'){

          // THIS FILTERS OUT THE ObjectID's that stay the same
          if(item['changeFrom'] == item['changeTo']) return;

          if(item['changeFrom'] && item['changeFrom'].toString() == 'true')  item['changeFrom'] = 'added';
          if(item['changeTo'] && item['changeTo'].toString() == 'true')  item['changeTo'] = 'added';
          if(!item['changeFrom'])  item['changeFrom'] = 'removed';
          if(!item['changeTo'])  item['changeTo'] = 'removed';

          return changes.push({
            'changeFrom': { key: key, value: item['changeFrom'], prop: property},
            'changeTo': { key: key, value: item['changeTo'], prop: property}
          })
        }

        if(Array.isArray(item[prop])){
          item[prop].map(diff => mapData(item[prop], key, prop))
        }
  
        if(!Array.isArray(item[prop])) {
          mapData(item[prop], key, prop)
        }

      }

    }

    for (let [key, value] of Object.entries(data)){
      mapData(value, key)
    }

    return changes;

  }

  compareDates(index){

    let a;
    let b;

    if(index !== 0){
      a = formatDate(this.activityLogs[index]['createdAt'], 'yyyy-MM-dd', 'en');
      b = formatDate(this.activityLogs[index - 1]['createdAt'], 'yyyy-MM-dd', 'en');

      return formatDate(a, 'yyyy-MM-dd', 'en') != formatDate(b, 'yyyy-MM-dd', 'en')
    }
    return true
  }

}
