import { BrokerDisplayClientDocumentModalComponent } from 'src/app/broker/clients/documents/displayDocumentsModal/broker-display-client-document-modal.component';
import { HttpResponse } from '@angular/common/http';
import { DocumentModel } from 'src/app/_shared/models/document.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentService } from 'src/app/services/document.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploadCompanyDocumentsComponent } from './uploadDocuments/upload-company-documents.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-company-documents',
  templateUrl: './broker-company-documents.component.html',
  styleUrls: ['./broker-company-documents.component.scss']
})
export class BrokerCompanyDocumentsComponent implements OnInit {

  brokerCompany = this.authenticationService.currentCompany.companyName;
  companyDocuments: DocumentModel[] = [];

  constructor(
    public dialog: MatDialog,
    private documentService: DocumentService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getAllCompanyDocuments();
  }

  uploadCompanyDocument() {
    const dialogRef = this.dialog.open(UploadCompanyDocumentsComponent, { width: '800px' });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.getAllCompanyDocuments();
      }
    });
  }

  getAllCompanyDocuments(){
    this.documentService.getCompanyDocuments(this.brokerCompany).subscribe(
      (response: HttpResponse<any>) => {
        this.companyDocuments = response.body.documents.sort((a, b) => {
          if(a.documentName < b.documentName) { return -1; }
          if(a.documentName > b.documentName) { return 1; }
          return 0;
      });
      }
    )
  }

  viewDocument(document:any){
    this.dialog.open(BrokerDisplayClientDocumentModalComponent, {
      width: '800px',
      data: {
        document
      }
    });
  }

}
