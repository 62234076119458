<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
      <h6 class="d-inline-block mb-0">Button group</h6>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-6 float-left">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Basic example</h5>
      </div>

      <div class="panel-body">

        <p>Basic button group examples:</p>

        <div class="row">

          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-secondary">Left</button>
              <button class="btn btn-secondary">Middle</button>
              <button class="btn btn-secondary">Right</button>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-brand">Left</button>
              <button class="btn btn-brand">Middle</button>
              <button class="btn btn-brand">Right</button>
            </div>
          </div>

        </div> <!-- Close div for .row -->

        <hr class="hr-light hr-dashed mb-3 mt-3" />

        <div class="row">

          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-primary">Left</button>
              <button class="btn btn-primary">Middle</button>
              <button class="btn btn-primary">Right</button>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-success">Left</button>
              <button class="btn btn-success">Middle</button>
              <button class="btn btn-success">Right</button>
            </div>
          </div>

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Buttons toolbar</h5>
      </div>

      <div class="panel-body">

        <p>Combine sets of <code>btn-group</code> into a <code>btn-toolbar</code> for more complex components:</p>

          <div class="btn-toolbar">

            <div class="btn-group">
              <button class="btn btn-secondary"><i class="fi flaticon-faq mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-bell mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-chat mr-0"></i></button>
            </div>

            <div class="btn-group">
              <button class="btn btn-secondary"><i class="fi flaticon-dashboard mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-settings mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-zoom mr-0"></i></button>
            </div>

            <div class="btn-group">
              <button class="btn btn-secondary"><i class="fi flaticon-add mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-edit mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-trash mr-0"></i></button>
            </div>
            
          </div>

        <hr class="hr-light hr-dashed mb-3 mt-3" />

          <div class="btn-toolbar">

            <div class="btn-group">
              <button class="btn btn-secondary"><i class="fi flaticon-add mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-edit mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-trash mr-0"></i></button>
              <button class="btn btn-secondary"><i class="fi flaticon-cancel mr-0"></i></button>
            </div> <!-- Close div for .btn-toolbar -->

            <div class="input-group">

              <div class="input-group-prepend">
                <span class="input-group-text" id="btnGroupAddon">@</span>
                <input type="text" class="form-control" placeholder="Input group example" aria-describedby="btnGroupAddon">
              </div>

            </div> <!-- Close div for .input-group -->

          </div> <!-- Close div for .btn-toolbar -->


          <div class="btn-toolbar justify-content-between">

            <div class="btn-group">
              <button class="btn btn-primary"><i class="fi flaticon-add mr-0"></i></button>
              <button class="btn btn-success"><i class="fi flaticon-edit mr-0"></i></button>
              <button class="btn btn-warning"><i class="fi flaticon-trash mr-0"></i></button>
              <button class="btn btn-info"><i class="fi flaticon-cancel mr-0"></i></button>
            </div> <!-- Close div for .btn-toolbar -->

            <div class="input-group">

              <div class="input-group-prepend">
                <span class="input-group-text" id="btnGroupAddon">@</span>
                <input type="text" class="form-control" placeholder="Input group example" aria-describedby="btnGroupAddon">
              </div>

            </div> <!-- Close div for .input-group -->

          </div> <!-- Close div for .btn-toolbar -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

  <div class="col-6">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Sizing</h5>
      </div>

      <div class="panel-body">

        <p>Instead of applying button sizing classes to every button in a group, just add <code>.btn-group-*</code> to each <code>.btn-group</code>, including each one when nesting multiple groups.</p>

        <div class="btn-group btn-group-lg">
          <button class="btn btn-outline-success">Left</button>
          <button class="btn btn-outline-success">Middle</button>
          <button class="btn btn-outline-success">Right</button>
        </div>

        <div class="pt-2"></div>

        <div class="btn-group">
          <button class="btn btn-outline-warning">Left</button>
          <button class="btn btn-outline-warning">Middle</button>
          <button class="btn btn-outline-warning">Right</button>
        </div>

        <div class="pt-2"></div>

        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-info">Left</button>
          <button class="btn btn-outline-info">Middle</button>
          <button class="btn btn-outline-info">Right</button>
        </div>

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->



    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Nesting</h5>
      </div>

      <div class="panel-body">

        <p>Place a <code>.btn-group</code> within another <code>.btn-group</code> when you want dropdown menus mixed with a series of buttons.</p>

        <div class="btn-group">
          <button class="btn btn-secondary"><i class="fi flaticon-add mr-0"></i></button>
          <button class="btn btn-secondary"><i class="fi flaticon-edit mr-0"></i></button>
          <button class="btn btn-secondary"><i class="fi flaticon-trash mr-0"></i></button>
          <button class="btn btn-secondary"><i class="fi flaticon-cancel mr-0"></i></button>

          <div class="btn-group" dropdown>

            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              Dropdown <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
            </ul>

          </div> <!-- Close div for .btn-group -->

        </div> <!-- Close div for .btn-group -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->


    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Vertical</h5>
      </div>

      <div class="panel-body">

        <p>Make a set of buttons appear vertically stacked rather than horizontally.</p>

        <div class="btn-group-vertical">

          <button class="btn btn-secondary">Button</button>
          
          <div class="btn-group" dropdown>

            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              Dropdown <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
            </ul>

          </div> <!-- Close div for .btn-group -->

          <button class="btn btn-secondary">Button</button>
          
          <div class="btn-group" dropdown>

            <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-basic">
              Dropdown <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Dropdown link</a></li>
            </ul>

          </div> <!-- Close div for .btn-group -->

        </div> <!-- Close div for .btn-group -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-6 -->

</div> <!-- Close div for .row -->
