<form id="faqForm" [formGroup]="faqForm" (ngSubmit)="saveNewFaq()">

    <h1>Create new FAQ</h1>
  
      <label for="question"><strong>Question:</strong></label>
      <input
        type="text"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && form.question.errors }"
        name="question"
        formControlName="question"
      />
      <div *ngIf="submitted && form.question.errors" class="invalid-feedback">
        <div *ngIf="form.question.errors.required">Please provide a question.</div>
      </div>
  
      <label for="answer"><strong>Answer:</strong></label>
      <ckeditor
        id="editor"
        name="answer"
        [editor]="Editor"
        [ngClass]="{ 'is-invalid': submitted && form.answer.errors }"
        formControlName="answer">
      </ckeditor>
      <div *ngIf="submitted && form.answer.errors" class="invalid-feedback">
        <div *ngIf="form.answer.errors.required">Please provide an answer.</div>
      </div>
  
      <app-save-button type="submit" buttonText="Save FAQ"></app-save-button>
  
  
  </form>