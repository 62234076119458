import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { ValueAddedProductResponse } from 'src/app/_shared/models/vaps.model';

@Component({
  template: '',
})
export class DeleteValueAddedProductComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(productData): void {
    this.dialogRef = this.dialog.open(DeleteValueAddedProductComponentPreview, {
      // width: '600px',
      data: productData
    });
  }

}

@Component({
  templateUrl: './delete-value-added-product.component.html',
  styleUrls: ['./delete-value-added-product.component.scss']
})
export class DeleteValueAddedProductComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteValueAddedProductComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private vapsService: ValueAddedProductsService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeProduct(data: any){
    console.log('data => ', data)
    this.vapsService.deleteVaps(data.clientId, data._id).subscribe(
      (response:HttpResponse<ValueAddedProductResponse>) => {
        this.closeDialog('success');
      }
    )
  }
}
