import { AuthenticationService } from './../../services/authentication.service';
import { BrokerComponent } from './../broker.component';
import { User } from 'src/app/_shared/models/User.model';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Broker } from 'src/app/_shared/models/broker.model';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Globals } from 'src/app/app.globals';

import { UserResponse } from 'src/app/_shared/models/User.model';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';
import { DeleteEmployeeComponent, DeleteEmployeeComponentPreview } from 'src/app/components/modal/delete-employee/delete-employee.component';

@Component({
  selector: 'app-broker-employees',
  templateUrl: './broker-employees.component.html',
  styleUrls: ['./broker-employees.component.scss'],
  providers: [DeleteEmployeeComponent, BrokerComponent]
})
export class BrokerEmployeesComponent implements OnInit, AfterViewInit {

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  employees: Employee[];
  previousEmployees: Employee[];
  searchEmployee:string;
  filterByRole:string = '';
  employmentStatus: string = 'employed'

  constructor(
    public globals: Globals,
    private brokerService: BrokerService,
    private insuranceService: InsuranceService,
    private deleteEmployee: DeleteEmployeeComponent,
    private router: Router,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
   this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngAfterViewInit() {
      this.getEmployees();
  }

  getEmployees(event?:string, role?:string, noLoad?:boolean) {
    const req = {
      // offset
      // limit
      employee: event || '',
      role: role || ''
    }

    this.filterByRole = role;

    this.brokerService.getAllCompanyEmployees(this.brokerComponent.brokerCompany.companyName, req, noLoad).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )
  }

  getPreviousEmployees(role?:string) {
    this.employees = []
    this.employmentStatus = role;

    this.brokerService.getAllCompanyPreviousEmployees(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {

        response.body.user.map(u => {
          this.employees.push(<Employee>{
            ...u,
            ...u['userRef']
          })
        })

        // this.employees = response.body.user;
      }
    )
  }

  removeEmployee(employee: Employee){
    this.deleteEmployee.openDialog(employee);
    this.deleteEmployee.dialogRef.afterClosed().subscribe(response => {
      if(response && response === 'success'){
        this.employees = this.employees.filter( emp => emp.username !== employee.username)
      }
    })
  }

  ngOnInit() {
  }

}

