import { FamilyMember, FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { MyAccountService } from 'src/app/services/my-account.service';

@Component({
  template: ''
})
export class DeleteFamilyMemberComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(member: FamilyMember): void {

    this.dialogRef = this.dialog.open(DeleteFamilyMemberComponentPreview, {
      // width: '600px',
      data: member
    });
  }

}

@Component({
  templateUrl: './delete-family-member.component.html',
  styleUrls: ['./delete-family-member.component.scss']
})
export class DeleteFamilyMemberComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteFamilyMemberComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public member: FamilyMember,
    public myAccountService: MyAccountService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeFamilyMember(member: FamilyMember){
    this.myAccountService.removeFamilyMember(member._id).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}
