import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Broker } from 'src/app/_shared/models/broker.model';;
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { InsuranceService } from 'src/app/services/insurance.service';


@Component({
  template: ''
})
export class DeleteBrokerComponent implements OnInit {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(broker: Broker): void {

    this.dialogRef = this.dialog.open(DeleteBrokerComponentPreview, {
      data: broker
    });
  }

  ngOnInit() {
  }

};

@Component({
  templateUrl: './delete-broker.component.html',
  styleUrls: ['./delete-broker.component.scss']
})
export class DeleteBrokerComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteBrokerComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public broker: Broker,
    public insuranceService: InsuranceService,
    private router: Router
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  deleteBroker(broker:Broker){
  this.insuranceService.removeBroker(broker._id).subscribe(
    (response:HttpResponse<any>) => {
      this.closeDialog('success');
      this.router.navigate(['/u/my-brokers']);
    }
  )
  }

}
