import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Vehicle, VehicleResponse } from '../_shared/models/vehicle.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrokerClientService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  getAllVehiclesForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousInsuredVehiclesForClient(clientId:string, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/previouslyInsured/client/${clientId}`, {
      observe: 'response',
      params: req
    })
  }

  getClientSingleVehicle(clientId:string, vehicleId:string, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.get<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/${vehicleId}`, {
      observe: 'response',
      params: req
    })
  }

  addNewClientVehicle(clientId:string, vehicleFormData:Vehicle, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.post<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}`, vehicleFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientVehicleQuote(clientId:string, companyName: string, vehicleFormData:Vehicle, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.post<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/company/${companyName}/quote`, vehicleFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientVehicleQuote(clientId:string, vehicleId:string, companyName: string, vehicleFormData:Vehicle, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.put<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/company/${companyName}/quote/${vehicleId}`, vehicleFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientExistingVehicle(clientId:string, vehicleId:string, vehicleFormData:Vehicle, req?:any): Observable<HttpResponse<VehicleResponse>>{
    return this.http.put<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/${vehicleId}`, vehicleFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteInsuranceFromVehicle(clientId:string, vehicleId:string): Observable<HttpResponse<VehicleResponse>>{
    return this.http.delete<VehicleResponse>(`${this.globals.SERVER_URL}/api/broker/vehicles/client/${clientId}/${vehicleId}`, {
      observe: 'response'
    })
  }

}
