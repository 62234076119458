import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Investment } from 'src/app/_shared/models/investment.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { InvestmentService } from 'src/app/services/investment.service';
import { Router } from '@angular/router';

@Component({
  template: '',
})
export class DeleteInvestmentComponent implements OnInit {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(investment: Investment): void {

    this.dialogRef = this.dialog.open(DeleteInvestmentComponentPreview, {
      // width: '600px',
      data: investment
    });
  }

  ngOnInit() {
  }

}

@Component({
  templateUrl: './delete-investment.component.html',
  styleUrls: ['./delete-investment.component.scss']
})
export class DeleteInvestmentComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteInvestmentComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public investment: Investment,
    public investmentService: InvestmentService,
    private router: Router
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  deleteInvestment(investment:Investment){
  this.investmentService.removeInvestment(investment).subscribe(
    (response:HttpResponse<any>) => {
      this.closeDialog('success');
      this.router.navigate(['/broker-users/user', ({id: investment.userRef})]);
    }
  )
  }

}
