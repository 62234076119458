import { HttpResponse } from '@angular/common/http';
import { BrokerService } from './../../../services/broker.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { User, UserResponse } from './../../../_shared/models/User.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/components/modal/delete/delete.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CalendarService } from 'src/app/services/calendar.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-view-calendar-event',
  templateUrl: './broker-view-calendar-event.component.html',
  styleUrls: ['./broker-view-calendar-event.component.scss']
})
export class BrokerViewCalendarEventComponent implements OnInit {

  currentCompany = this.authService.currentCompany.companyName;

  calendarEventFormGroup: FormGroup;
  formSubmitted: boolean = false;

  isAllDayEvent: boolean = false;

  isLoading: boolean = false;
  clients: User[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef:MatDialogRef<BrokerViewCalendarEventComponent>,
    private formBuilder: FormBuilder,
    private calendarService: CalendarService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private brokerService: BrokerService
  ) { }

  ngOnInit(): void {

    console.log('data -> ', this.data)

    this.calendarEventFormGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        _id: [''],
        firstName: [''],
        lastName: [''],
        // refNr: [''],
        clientNr: [''],
        username: ['']
      }),
      clientAutoCompleteDisplay: [''],
      _id: [''],
      title: ['', Validators.required],
      description: [''],
      start: ['', Validators.required],
      end: ['', Validators.required],
      allDay: [false],
      type: ['', Validators.required],
      companyName: ['', Validators.required]
    })

    this.calendarEventFormGroup.patchValue(this.data);
    this.calendarEventFormGroup.get('clientAutoCompleteDisplay').setValue(this.data.client)
    this.mapClient(this.data)
    this.isAllDayEvent = this.data.allDay;

    // GET CLIENTS
    this.calendarEventFormGroup
    .get('clientAutoCompleteDisplay')
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllClients(this.currentCompany, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

  }

  deleteEvent() {
    this.calendarService.deleteEvent(this.calendarEventFormGroup.get('_id').value).subscribe(response => {
      this.dialogRef.close({ status: 'deleted', data: this.calendarEventFormGroup.value })
    })
  }

  saveEvent(){
    this.formSubmitted = true;
    this.calendarEventFormGroup.markAllAsTouched();

    if(this.isAllDayEvent && !this.calendarEventFormGroup.get('end').value){
      this.calendarEventFormGroup.get('end').setValue(this.calendarEventFormGroup.get('start').value)
    }

    if(this.calendarEventFormGroup.invalid){
      this.toastr.error('There were some errors while creating a calendar event.  Please make sure that all the required fields are filled.', 'Error creating calendar event.')
      return;
    }

    this.calendarService.editCalendarEvent(this.calendarEventFormGroup.get('_id').value, this.calendarEventFormGroup.value).subscribe(response => {
      this.dialogRef.close({ status:'saved', data: this.calendarEventFormGroup.value });
    })
  }

  markEnd(event){
    this.isAllDayEvent = event.checked;
  }

  displayFn(user?: User) {
    console.log('display -> ', user)
    return user._id || user['brokerId'] ? `${user.firstName} ${user.lastName}` : typeof user === 'string' ? user : undefined;
  }

  mapClient(client){
    this.calendarEventFormGroup.get('client').get('_id').setValue(client._id);
    this.calendarEventFormGroup.get('client').get('firstName').setValue(client.firstName);
    this.calendarEventFormGroup.get('client').get('lastName').setValue(client.lastName);
    // this.calendarEventFormGroup.get('client').get('clientNr').setValue(client.broker.find(company => company.companyName === this.currentCompany).clientNumber);
    this.calendarEventFormGroup.get('client').get('username').setValue(client.username);
  }

}
