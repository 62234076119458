import { Component, OnInit } from '@angular/core';
import { MyAccountService } from 'src/app/services/my-account.service';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { IdNumberVerifyService } from 'src/app/services/id-number-verify.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FamilyMemberResponse } from 'src/app/_shared/models/familyMember.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-client-new-family-member',
  templateUrl: './client-new-family-member.component.html',
  styleUrls: ['./client-new-family-member.component.scss']
})
export class ClientNewFamilyMemberComponent implements OnInit {

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  currentUser = this.authService.currentUserValue;

  newFamilyMemberForm: FormGroup;
  submittedForm = false;
  get form(){ return this.newFamilyMemberForm.controls; }

  validId: boolean = true;

  constructor(
    private myAccountService: MyAccountService,
    private formBuilder: FormBuilder,
    public location: Location,
    private verifyId: IdNumberVerifyService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) { }


  addNewFamilyMember(saveType?: string){

    this.submittedForm = true;
    let verifyId: any = this.verifyId.validateIdNumber(this.newFamilyMemberForm.value['idNumber']);
    this.validId = verifyId.isValid;

    
    if (this.newFamilyMemberForm.invalid || !this.validId) {
      return;
    }

    this.myAccountService.saveNewFamilyMember(this.newFamilyMemberForm.value, this.currentUser._id).subscribe(
      (response:HttpResponse<FamilyMemberResponse>) => {
        switch(saveType){
          case 'back':
            // this.location.back();
            this.goBack();
            break;

          case 'continue':
            break;

          default:
            // this.location.back();
            this.goBack();
            break;
        }
      }
    )
  };

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.router.navigate(['/b/clients/personal/edit', this.clientId]);
      this.location.back();
    };
  }

  ngOnInit() {
    this.newFamilyMemberForm = this.formBuilder.group({
      name:           ['', Validators.required],
      surname:        ['', Validators.required],
      relationship:   ['', Validators.required],
      idNumber:       ['', Validators.required],
      driversLicence: [''],
      email: [''],
      cellphone: ['']
    });
  }

}
