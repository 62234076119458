<div id="content" class="row">

    <sub-navigation heading="{{ editUnderwriter ? 'Edit' : 'Add new' }} underwriter"></sub-navigation>
    
    <div class="col-12">
    
        <div class="panel panel-primary">
        <form id="underwriterFormGroup" [formGroup]="underwriterFormGroup">
    
            <div class="panel-heading">
            <h5>Underwriter details</h5>
    
            <div class="my-auto">
    
                <button type="button" matTooltip="Go back to view all underwriters" class="btn btn-secondary my-auto" (click)="goBack()">
                    <i class="fi flaticon-back"></i>Back
                </button>
    
                <div class="btn-group" ngbDropdown *ngIf="!viewUnderwriter && canViewItem">
                <button
                    type="submit"
                    class="btn btn-primary"
                    mat-tooltip="Save underwriter and go back."
                    (click)="editUnderwriter ? updateUnderwriter('back') : addNewUnderwriter('back')"
                >
                    Save underwriter
                </button>
                <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" (click)="editUnderwriter ? updateUnderwriter('back') : addNewUnderwriter('back')">Save &amp; continue</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="editUnderwriter ? updateUnderwriter('new') : addNewUnderwriter('new')">Save &amp; add new</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="editUnderwriter ? updateUnderwriter('back') : addNewUnderwriter('back')">Save &amp; go back</a></li>
                </ul>
                </div> <!-- Close div for .btn-group -->
    
            </div> <!-- Close div for .my-auto -->
    
            </div>
    
            <div class="panel-body">
                
                <div class="row">

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="text"
                                    placeholder="Underwriter name"
                                    name="name"
                                    formControlName="name"
                                />

                                <mat-error>
                                    Please enter a <strong>name</strong> for the underwriter.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <div class="input-group">
                            <mat-form-field class="mb-0">
                                <input 
                                    matInput
                                    type="text"
                                    placeholder="Broker code"
                                    name="referenceNumber"
                                    formControlName="referenceNumber"
                                />

                                <mat-error>
                                    Please enter your underwriter <strong>broker code</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div> <!-- Close div for .input-group -->
                    </div>
    
                    <div class="col-sm-6 col-12" formArrayName="email">
                        <div 
                            class="row" 
                            [formGroupName]="i"
                            *ngFor="let email of underwriterFormGroup.get('email')['controls']; let i = index"
                            >

                            <div class="form-group col-12 col-md-5 my-auto">
                                <mat-form-field>
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Email Address"
                                        name="emailAddress"
                                        formControlName="emailAddress"
                                    />

                                    <mat-error>
                                        Please enter the <strong>email adress</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>

                            <div class="form-group col-12 col-md-5 my-auto">
                                <mat-form-field>
                                    <mat-select
                                            name="emailAddressType"
                                            formControlName="emailAddressType"
                                        >
                                            <mat-option value="" disabled>Please select the email address type</mat-option>
                                            <mat-option value="enquiries">Enquiries</mat-option>
                                            <mat-option value="general">General</mat-option>
                                            <mat-option value="quote">Quotes</mat-option>
                                            <mat-option value="claims">Claims</mat-option>
                                            <mat-option value="other">Other</mat-option>
                                        </mat-select>

                                    <mat-error>
                                        Please select the <strong>email adress type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>

                            <div class="form-group col-12 col-md-2 my-auto text-right">
                                <button class="mr-0 btn btn-icon btn-danger btn-sm float-right float-sm-left"
                                    (click)="removeEmailAddress(i)">
                                    <i class="fi flaticon-trash mr-0"></i>
                                </button>
                            </div>
                        </div> <!-- Close div for .row -->
            
                        <div class="row mt-2">
                            <div class="col-12">
                            <button type="button" class="btn btn-label-success btn-sm" (click)="addEmailAddress()">Add another email address</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-12 mb-3">
                        <mat-form-field class="mb-0">
                            <input 
                                matInput
                                type="text"
                                placeholder="Tax number"
                                name="taxNumber"
                                formControlName="taxNumber"
                            />

                            <mat-error>
                                Please enter the underwriter <strong>tax number</strong>.
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-12 mb-3">

                        <div class="row" formGroupName="brokerConsultant">

                            <div class="col-sm-6 col-12 mb-3">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Consultant name"
                                        name="consultantName"
                                        formControlName="consultantName"
                                    />
        
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-12 mb-3">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Consultant surname"
                                        name="consultantSurname"
                                        formControlName="consultantSurname"
                                    />
        
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-12 mb-3">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Consultant email"
                                        name="consultantEmail"
                                        formControlName="consultantEmail"
                                    />
        
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-12 mb-3">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Consultant number"
                                        name="consultantNumber"
                                        formControlName="consultantNumber"
                                    />
        
                                </mat-form-field>
                            </div>

                        </div> <!-- Close div for row -->
                    </div>

                    <div class="col-sm-6 col-12" formArrayName="telephone">
                        <div 
                            class="row" 
                            [formGroupName]="i"
                            *ngFor="let number of underwriterFormGroup.get('telephone')['controls']; let i = index"
                            >

                            <div class="form-group col-10 my-auto">
                                <mat-form-field>
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Telephone number {{ i + 1 }}"
                                        name="number"
                                        formControlName="number"
                                    />

                                    <mat-error>
                                        Please enter the <strong>telephone number</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>

                            <div class="form-group col-2 my-auto text-right">
                                <button class="mr-0 btn btn-icon btn-danger btn-sm float-right float-sm-left"
                                    (click)="removeTelephoneNumber(i)">
                                    <i class="fi flaticon-trash mr-0"></i>
                                </button>
                            </div>
                        </div> <!-- Close div for .row -->
            
                        <div class="row mt-2">
                            <div class="col-12">
                            <button type="button" class="btn btn-label-success btn-sm" (click)="addTelephoneNumber()">Add another number</button>
                            </div>
                        </div>
                    </div>   
                    
                    <div class="col-sm-6 col-12" formGroupName="address">

                        <div class="row">

                            <div class="col-12">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Address line 1"
                                        name="addressLine1"
                                        formControlName="addressLine1"
                                    />
        
                                    <mat-error>
                                        Please enter the underwriter <strong>tax number</strong>.
                                    </mat-error>
        
                                </mat-form-field>
                            </div> <!-- Close div for .col-12 -->

                            <div class="col-12">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Address line 2"
                                        name="addressLine2"
                                        formControlName="addressLine2"
                                    />
        
                                    <mat-error>
                                        Please enter the underwriter <strong>tax number</strong>.
                                    </mat-error>
        
                                </mat-form-field>
                            </div> <!-- Close div for .col-12 -->

                            <div class="col-12 col-md-6">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Suburb"
                                        name="suburb"
                                        formControlName="suburb"
                                    />
        
                                    <mat-error>
                                        Please enter the underwriter <strong>tax number</strong>.
                                    </mat-error>
        
                                </mat-form-field>
                            </div> <!-- Close div for .col-12 -->

                            <div class="col-12 col-md-6">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="City/Town"
                                        name="city"
                                        formControlName="city"
                                    />
        
                                    <mat-error>
                                        Please enter the underwriter <strong>tax number</strong>.
                                    </mat-error>
        
                                </mat-form-field>
                            </div> <!-- Close div for .col-12 -->

                            <div class="col-6">
                                <mat-form-field class="mb-0">
                                    <input 
                                        matInput
                                        type="text"
                                        placeholder="Postal code"
                                        name="postalCode"
                                        formControlName="postalCode"
                                    />
        
                                    <mat-error>
                                        Please enter the underwriter <strong>tax number</strong>.
                                    </mat-error>
        
                                </mat-form-field>
                            </div> <!-- Close div for .col-12 -->

                        </div> <!-- Close div for .row -->                        

                    </div>  
    
                </div> <!-- Close div for .row -->
            </div> <!-- Close div for .panel-body -->
    
        </form>
        </div> <!-- Close div for .panel -->
    
    </div> <!-- Close div for .col-12 -->
    
    </div> <!-- Close div for #content -->
    