import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable } from 'rxjs';
import { AllRiskItemResponse, AllRiskItem } from '../_shared/models/allRiskItem.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerClientAllRiskServiceService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientAllRiskInsurance(clientId:string, itemFormData:AllRiskItem, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.post<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}`, itemFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientAllRiskItemQuote(clientId:string, companyName: string, itemFormData:AllRiskItem, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.post<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/company/${companyName}/quote`, itemFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientAllRiskItemQuote(clientId:string, itemId:string, companyName: string, itemFormData:AllRiskItem, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.put<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/company/${companyName}/quote/${itemId}`, itemFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllAllRiskInsuranceForClient(clientId:string, companyName: string, req:any = {}): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.get<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousAllRiskInsuranceForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.get<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/previouslyInsured/client/${clientId}/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleAllRiskInsuranceForClient(clientId:string, itemId:string, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.get<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/item/${itemId}`, {
      observe: 'response',
      params: req
    })
  }

  updateClientAllRiskInsurance(clientId:string, itemId:string, itemFormData:AllRiskItem, req?:any): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.put<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/${itemId}`, itemFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteInsuranceFromAllRiskItem(clientId:string, itemId:string): Observable<HttpResponse<AllRiskItemResponse>>{
    return this.http.delete<AllRiskItemResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/allRisk/client/${clientId}/${itemId}`, {
      observe: 'response'
    })
  }
}
