import { BrokerClaimResponse } from './../../_shared/models/brokerClaims.model';
import { BrokerComponent } from './../../broker/broker.component';
import { BrokerAdminInstructionResponse } from 'src/app/_shared/models/brokerAdminInstruction.model';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { BrokerDashboardService } from 'src/app/services/broker-dashboard.service'
import { Investment, InvestmentResponse } from 'src/app/_shared/models/investment.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Globals } from 'src/app/app.globals';
import { User } from 'src/app/_shared/models/User.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { CalendarEventResponse, CalendarEventModel} from 'src/app/_shared/models/calendarEvent.model';
import { FullCalendarComponent, CalendarOptions, EventClickArg } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';

@Component({
  selector: 'app-broker-dashboard',
  templateUrl: './broker-dashboard.component.html',
  styleUrls: ['./broker-dashboard.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerDashboardComponent implements OnInit, AfterViewInit {

  currentUser = this.authService.currentUserValue;
  // isBrokerAdmin = this.currentUser.permissions.includes('brokerAdmin');

  totalUsers:number = 0;

  totalCompanyInstructions:number = 0;
  totalUserInstructions:number = 0;

  totalCompanyClaims:number = 0;
  totalUserClaims:number = 0;

  totalCompanyPolicies:number = 0;
  totalUserPolicies:number = 0;

  totalCompanyQuotes:number = 0;
  totalUserQuotes:number = 0;

  totalCompanyLeads:number = 0;
  totalUserLeads:number = 0;

  // ---------------------------

  currentUserPermissions: any[];

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  calendarDataEvents: any[] = [];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [ timeGridPlugin ],
    headerToolbar: {
      right: 'prev,next',
      left: 'title',
    },
    eventSources: this.calendarDataEvents,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    events: (event) => {
      if(this.currentUserPermissions.includes('brokerAdmin')){
        this.getAllEvents(event.startStr, event.endStr)
      } else {
        this.getAllEventsPerUser(event.startStr, event.endStr)
      }
      
    },
    editable: true,
  };

  constructor(
    private dashboardService: BrokerDashboardService,
    private authService: AuthenticationService,
    private brokerComponent: BrokerComponent,
    private calendarService: CalendarService,
  ) {
    this.currentUser = this.authService.currentUserValue;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getTotalCompanyBrokerInstructions(){
    this.dashboardService.getTotalCompanyBrokerInstructions(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.totalCompanyInstructions = response.body.count;
      }
    )
  }

  getTotalUserInstructions(){
    this.dashboardService.getTotalUserInstructions(this.brokerComponent.brokerCompany.companyName, this.currentUser._id).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.totalUserInstructions = response.body.count;
      }
    )
  }

  getTotalCompanyClaims(){
    this.dashboardService.getTotalCompanyClaims(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalCompanyClaims = response.body.count;
      }
    )
  }

  getTotalUserClaims(){
    this.dashboardService.getTotalUserClaims(this.brokerComponent.brokerCompany.companyName, this.currentUser._id).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalUserClaims = response.body.count;
      }
    )
  }

  getTotalCompanyPolicies(){
    this.dashboardService.getTotalCompanyPolicies(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalCompanyPolicies = response.body.count;
      }
    )
  }

  getTotalUserPolicies(){
    this.dashboardService.getTotalUserPolicies(this.brokerComponent.brokerCompany.companyName, this.currentUser._id).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalUserPolicies = response.body.count;
      }
    )
  }

  getTotalCompanyQuotes(){
    this.dashboardService.getTotalCompanyQuotes(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalCompanyQuotes = response.body.count;
      }
    )
  }

  getTotalUserQuotes(){
    this.dashboardService.getTotalUserQuotes(this.brokerComponent.brokerCompany.companyName, this.currentUser._id).subscribe(
      (response:HttpResponse<BrokerClaimResponse>) => {
        this.totalUserQuotes = response.body.count;
      }
    )
  }

  getTotalCompanyLeads(){
    this.dashboardService.getTotalCompanyLeads(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response:HttpResponse<any>) => {
        this.totalCompanyLeads = response.body.count;
      }
    )
  }

  getTotalUserLeads(){
    this.dashboardService.getTotalUserLeads(this.brokerComponent.brokerCompany.companyName, this.currentUser._id).subscribe(
      (response:HttpResponse<any>) => {
        this.totalUserLeads = response.body.count;
      }
    )
  }

  ngAfterViewInit() {
    this.getTotalCompanyBrokerInstructions();
    this.getTotalUserInstructions();

    this.getTotalCompanyClaims();
    this.getTotalUserClaims();

    this.getTotalCompanyPolicies();
    this.getTotalUserPolicies();

    this.getTotalCompanyQuotes();
    this.getTotalUserQuotes();

    this.getTotalCompanyLeads();
    this.getTotalUserLeads();
  }

  ngOnInit() {
  }

  hasUserPermission(permission:string){
    let company = this.currentUser.company.find(company => company.companyName === this.brokerComponent.brokerCompany.companyName);
    return company.permissions.includes(permission);
  }

  isCompanyAdmin(){
    return this.hasUserPermission('brokerDirector') || this.hasUserPermission('brokerAdmin') || this.hasUserPermission('systemAdmin');
  }

  getAllEvents(startDate, endDate){

    this.calendarService.getAllCalendarEvents(this.authService.currentCompany.companyName, startDate, endDate).subscribe(
      (response:HttpResponse<CalendarEventResponse>) => {
      this.calendarDataEvents = response.body.events;

      this.calendarComponent.getApi().removeAllEventSources();
      this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);
    })
  }

  getAllEventsPerUser(startDate, endDate){

    this.calendarService.getAllEventsPerUser(this.currentUser._id, this.authService.currentCompany.companyName, startDate, endDate).subscribe(
      (response:HttpResponse<CalendarEventResponse>) => {
      this.calendarDataEvents = response.body.events;

      this.calendarComponent.getApi().removeAllEventSources();
      this.calendarComponent.getApi().addEventSource(this.calendarDataEvents);
    })
  }

}
