<div class="row">

    <div class="col-md-3 col-sm-4 col-6" *ngFor="let policy of policies">
        <div class="card h-100">
            <div class="card-body">

                <div class="text-right">
                    <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="menu">
            <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
            </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['/b/clients/policies/edit/', policy._id]">
                <span>Edit policy</span>
            </button>
                        <button mat-menu-item (click)="deactivatePolicy(policy._id)">
                <span>Remove policy</span>
            </button>
                    </mat-menu>
                </div>

                <!-- <div class="text-center mb-3">
            <span class="userpic userpic-lg userpic-circle userpic-dark">
            <span>{{ policy?.name.substring(0, 1) }}</span>
            </span>
        </div> -->

                <div class="text-center mb-0">
                    <span class="color-dark-grey">Policy number</span>
                </div>

                <h5 class="card-title text-center color-label-4 mb-4">{{ policy?.policyNumber }}</h5>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Administrator:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ policy?.administrator?.name }}</small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Underwriter:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ policy?.underwriter?.name }}</small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Policy date:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ policy?.createdAt | date: 'dd MMM yyyy' }}<span *ngIf="policy?.policyTerminationDate"> - {{ policy?.policyTerminationDate | date: 'dd MMM yyyy' }}</span></small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Total items:</strong></small>
                    <small class="text-right float-right color-dark-grey">{{ policy?.policyItemLength }}</small>
                </p>
                <p class="card-text mb-0">
                    <small class="text-left"><strong>Status:</strong></small>
                    <small class="float-right label label-inline font-weight-bold" [ngClass]="policy?.active?.isActive? 'label-light-primary' : 'label-light-danger'">
                        {{ policy?.active?.isActive ? 'Active' : 'Cancelled' }}
                    </small>
                </p>
            </div>
        </div>
        <!-- Close div for .card -->
    </div>

</div>
<!-- Close div for .row -->