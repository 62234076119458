<div class="row" [formGroup]="instructionFormGroup">

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Client"
                  placeholder="Client"
                  formControlName='client'
                  [matAutocomplete]="user"
                  [readonly]="inEditMode"
                />
                <mat-hint [hidden]="instructionFormGroup.get('client')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding clients...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="clients?.length === 0">
                            <span>No client found</span>
                        </mat-option>
                        <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="instructionFormGroup.get('productType').enable()">
                            <span>{{ client?.firstName }} {{ client?.lastName }}{{ client?.companyName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>client</strong> for this instruction.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- PRODUCT TYPE -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <mat-label>Please select a product type</mat-label>
                <mat-select
                    name="productType"
                    formControlName="productType"
                    [ngClass]="{ 'is-invalid': instructionFormGroup.get('productType').errors }"
                    (selectionChange)="selectedProductType($event)"
                >
                    <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                    <ng-container *ngIf="!instructionFormGroup.get('client')?.value?.companyName">
                       <mat-option value="vehicle">Vehicle</mat-option>
                        <mat-option value="building">Building</mat-option>
                        <mat-option value="homeContent">Home contents</mat-option>
                        <mat-option value="trailerAndCaravan">Trailers and Caravans</mat-option>
                        <mat-option value="allRisk">All risk item</mat-option>
                        <mat-option value="vap">Value added products</mat-option>
                        <mat-option value="general">General</mat-option> 
                    </ng-container>
                    <mat-option *ngIf="instructionFormGroup.get('client')?.value?.companyName" value="commercialProduct">Commercial product</mat-option>
                    
                </mat-select>

                <mat-error>
                    Please select a <strong>product type</strong>.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- PRODUCT -->
    <div class="col-sm-6 col-12 mb-3" *ngIf="instructionFormGroup.get('productType').value !== 'general'">
        <div class="input-group">
            <mat-form-field>
                <input
                    matInput
                    type="text"
                    aria-label="Product"
                    placeholder="Product"
                    formControlName='productDisplay'
                    [matAutocomplete]="product"
                />
                <mat-hint [hidden]="instructionFormGroup?.get('product')?.dirty">Type a {{ instructionFormGroup.get('productType').value | sentenceCase }} to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #product="matAutocomplete" [displayWith]="displayProduct.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding {{ instructionFormGroup.get('productType').value | sentenceCase }}s...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="products?.length === 0">
                            <span>No {{ instructionFormGroup.get('productType').value | sentenceCase }}s found</span>
                        </mat-option>
                        <mat-option *ngFor="let product of products" [value]="product" (onSelectionChange)="mapProduct($event)">
                            <span *ngIf="instructionFormGroup.get('productType').value === 'vehicle'">{{ product.make }} {{ product.model }} - {{ product.registrationNumber }}</span>
                            <span *ngIf="instructionFormGroup.get('productType').value === 'trailerAndCaravan'">{{ product.type }} - {{ product.make }} {{ product.model }}</span>
                            <span *ngIf="instructionFormGroup.get('productType').value === 'building' || instructionFormGroup.get('productType').value === 'homeContent'">{{ product?.address?.formattedAddress }}</span>
                            <span *ngIf="instructionFormGroup.get('productType').value === 'allRisk'">{{ product?.itemName }}</span>
                            <span *ngIf="instructionFormGroup.get('productType').value === 'vaps'">{{ product?.description }}</span>
                            <span *ngIf="instructionFormGroup.get('productType').value === 'commercialProduct'">{{ product?.productType | sentenceCase }} - {{ product?.description }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select a <strong>{{ instructionFormGroup.get('productType').value | sentenceCase }}</strong> for this instruction.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field>
                <input
                  matInput 
                  type="text"
                  aria-label="Employee"
                  placeholder="Employee"
                  formControlName='employee'
                  [matAutocomplete]="employeeAuto"
                />
                <mat-hint [hidden]="instructionFormGroup.get('employee')?.dirty">Type a name to start the search</mat-hint>

                <mat-autocomplete autoActiveFirstOption #employeeAuto="matAutocomplete" [displayWith]="displayClient.bind(this)">
                    <mat-option disabled *ngIf="loadingResults" class="is-loading">Finding employees...</mat-option>

                    <ng-container *ngIf="!loadingResults">
                        <mat-option disabled *ngIf="employees?.length === 0">
                            <span>No employee found</span>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="instructionFormGroup.get('productType').enable()">
                            <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <mat-error>
                    Please select an <strong>employee</strong> for this instruction.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- INSTRUCTION TYPE -->
    <div class="col-sm-6 col-12 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <mat-label>Instruction Type</mat-label>
                <mat-select
                    name="instructionType"
                    formControlName="instructionType"
                    [ngClass]="{ 'is-invalid': instructionFormGroup.get('instructionType').errors }"
                >
                    <mat-option value="" disabled>Please select an instruction type</mat-option>
                    <mat-option value="new">Add new product</mat-option>
                    <mat-option *ngIf="instructionFormGroup.get('productType').value === 'vehicle'" value="existing">Existing product</mat-option>
                    <mat-option value="delete">Remove product</mat-option>
                    <mat-option value="general">General</mat-option>
                    <mat-option value="renewal">Renewal</mat-option>
                    <mat-option value="cancellation">Cancellation</mat-option>
                </mat-select>

                <mat-error>
                    Please select the <strong>instruction type</strong>.
                </mat-error>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

    <!-- INSTRUCTION DESCRIPTION -->
    <div class="col-12 col-sm-6 mb-3">
        <div class="input-group">
            <mat-form-field class="mb-0">
                <textarea 
                    matInput
                    type="text"
                    placeholder="Instruction description"
                    name="instructionDescription"
                    formControlName="instructionDescription"
                ></textarea>

            </mat-form-field>
        </div> <!-- Close div for .input-group -->
    </div>

</div>

<div class="row instructionSteps" [formGroup]="instructionFormGroup">

    <div class="col-12" formGroupName="instructionSteps">

        <div class="row">

            <div class="col-12 col-sm-6" formGroupName="instructionReceivedVia">
                <div class="input-group">
                    <mat-form-field class="mb-0">
                        <mat-label>How was the instruction received from the client?</mat-label>
                        <mat-select
                            name="instructionReceivedVia"
                            formControlName="value"
                            [disabled]="displayCompletedBy('instructionReceivedVia')"
                            [ngClass]="{ 'is-invalid': instructionFormGroup.get('productType').errors }"
                        >
                            <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                            <mat-option value="email">Email</mat-option>
                            <mat-option value="telephone">Phonecall</mat-option>
                            <mat-option value="whatsapp">Whatsapp</mat-option>
                            <mat-option value="faceToFace">Face to face</mat-option>
                            <mat-option value="fax">Fax</mat-option>
                            <!-- <mat-option value="surespace">SureSpace</mat-option> -->
                        </mat-select>
        
                        <mat-error>
                            Please select a instruction <strong>receive method</strong>.
                        </mat-error>
        
                    </mat-form-field>

                    <small *ngIf="displayCompletedBy('instructionReceivedVia')" class="text-muted">Completed by: {{ getStepCompletedBy('instructionReceivedVia') }} </small>
                </div> <!-- Close div for .input-group -->
            </div>

            <div class="col-12" formGroupName="instructionSavedToSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('instructionSavedToSureSpace')" class="step" disableRipple formControlName="value">The instruction has been saved to SureSpace.</mat-checkbox>
                <small *ngIf="displayCompletedBy('instructionSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('instructionSavedToSureSpace') }} </small>
            </div>

            <div class="col-12" formGroupName="instructionSentToInsuranceCompany">
                <mat-checkbox [disabled]="displayCompletedBy('instructionSentToInsuranceCompany')" class="step" disableRipple formControlName="value">The instruction has been sent to the insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('instructionSentToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('instructionSentToInsuranceCompany') }} </small>
            </div>

            <div class="col-12" formGroupName="insuranceCompanyReceivedInstruction">
                <mat-checkbox [disabled]="displayCompletedBy('insuranceCompanyReceivedInstruction')" class="step" disableRipple formControlName="value">The insurance company has received the instruction.</mat-checkbox>
                <small *ngIf="displayCompletedBy('insuranceCompanyReceivedInstruction')" class="text-muted">Completed by: {{ getStepCompletedBy('insuranceCompanyReceivedInstruction') }} </small>
            </div>

            <div class="col-12" formGroupName="receiveNewScheduleFromInsuranceCompany">
                <mat-checkbox [disabled]="displayCompletedBy('receiveNewScheduleFromInsuranceCompany')" class="step" disableRipple formControlName="value">The insurance company has sent through a new schedule.</mat-checkbox>
                <small *ngIf="displayCompletedBy('receiveNewScheduleFromInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('receiveNewScheduleFromInsuranceCompany') }} </small>
            </div>

            <div class="col-12" formGroupName="scheduleSavedToSureSpace">
                <mat-checkbox [disabled]="displayCompletedBy('scheduleSavedToSureSpace')" class="step" disableRipple formControlName="value">The new schedule has been uploaded and saved to SureSpace.</mat-checkbox>
                <small *ngIf="displayCompletedBy('scheduleSavedToSureSpace')" class="text-muted">Completed by: {{ getStepCompletedBy('scheduleSavedToSureSpace') }} </small>
            </div>

            <div class="col-12" formGroupName="requestProRataPremiums">
                <mat-checkbox [disabled]="displayCompletedBy('requestProRataPremiums')" class="step" disableRipple formControlName="value">New pro rate premiums have been requested from the insurance company.</mat-checkbox>
                <small *ngIf="displayCompletedBy('requestProRataPremiums')" class="text-muted">Completed by: {{ getStepCompletedBy('requestProRataPremiums') }} </small>
            </div>

            <div class="col-12" formGroupName="sentScheduleAndProRataToClient">
                <mat-checkbox [disabled]="displayCompletedBy('sentScheduleAndProRataToClient')" class="step" disableRipple formControlName="value">The new schedule and pro rata agreement have been sent to the client for approval.</mat-checkbox>
                <small *ngIf="displayCompletedBy('sentScheduleAndProRataToClient')" class="text-muted">Completed by: {{ getStepCompletedBy('sentScheduleAndProRataToClient') }} </small>
            </div>

            <div class="col-12" formGroupName="confirmClientReceivedLastestSchedule">
                <mat-checkbox [disabled]="displayCompletedBy('confirmClientReceivedLastestSchedule')" class="step" disableRipple formControlName="value">The client has received the most up to date schedule.</mat-checkbox>
                <small *ngIf="displayCompletedBy('confirmClientReceivedLastestSchedule')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmClientReceivedLastestSchedule') }} </small>
            </div>

            <ng-container *ngIf="instructionFormGroup.get('instructionType').value === 'delete'">

                <div class="col-12" formGroupName="clientRequestedRefund">
                    <mat-checkbox [disabled]="displayCompletedBy('clientRequestedRefund')" class="step" disableRipple formControlName="value">The client has requested a refund.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('clientRequestedRefund')" class="text-muted">Completed by: {{ getStepCompletedBy('clientRequestedRefund') }} </small>
                </div>

            </ng-container>
            
            <ng-container *ngIf="!instructionFormGroup.get('instructionSteps').get('clientRequestedRefund').get('value').value">

                <div class="col-12" formGroupName="confirmProRataCredit">
                    <mat-checkbox [disabled]="displayCompletedBy('confirmProRataCredit')" class="step" disableRipple formControlName="value">Confirm with client if pro rata has been debited or credited from their account.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('confirmProRataCredit')" class="text-muted">Completed by: {{ getStepCompletedBy('confirmProRataCredit') }} </small>
                </div>

            </ng-container>

            <ng-container *ngIf="instructionFormGroup.get('instructionSteps').get('clientRequestedRefund').get('value').value">

                <div class="col-12" formGroupName="sentRefundRequestToInsuranceCompany">
                    <mat-checkbox [disabled]="displayCompletedBy('sentRefundRequestToInsuranceCompany')" class="step" disableRipple formControlName="value">The client refund request has been sent to the insurance company for approval.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('sentRefundRequestToInsuranceCompany')" class="text-muted">Completed by: {{ getStepCompletedBy('sentRefundRequestToInsuranceCompany') }} </small>
                </div>

                <div class="col-12" formGroupName="insuranceConfirmReceiptOfRefund">
                    <mat-checkbox [disabled]="displayCompletedBy('insuranceConfirmReceiptOfRefund')" class="step" disableRipple formControlName="value">The insurance company has received the request for refund</mat-checkbox>
                    <small *ngIf="displayCompletedBy('insuranceConfirmReceiptOfRefund')" class="text-muted">Completed by: {{ getStepCompletedBy('insuranceConfirmReceiptOfRefund') }} </small>
                </div>

                <div class="col-12" formGroupName="refundPaymentDate">
                    <div class="input-group">
                        <mat-form-field class="mb-0">
                            <input [disabled]="displayCompletedBy('refundPaymentDate')" placeholder="What is the refund date for this instruction" matInput [matDatepicker]="carHireDate" name="carHireDate"
                                formControlName="value" [min]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="carHireDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #carHireDate></mat-datepicker>        
                        </mat-form-field>
                        <small *ngIf="displayCompletedBy('refundPaymentDate')" class="text-muted">Completed by: {{ getStepCompletedBy('refundPaymentDate') }} </small>
                    </div> <!-- Close div for .input-group -->
                </div>

                <div class="col-12" formGroupName="receivedProofOfPayment">
                    <mat-checkbox [disabled]="displayCompletedBy('receivedProofOfPayment')" class="step" disableRipple formControlName="value">The payment has been made and the proof of payment has been received.</mat-checkbox>
                    <small *ngIf="displayCompletedBy('receivedProofOfPayment')" class="text-muted">Completed by: {{ getStepCompletedBy('receivedProofOfPayment') }} </small>
                </div>

            </ng-container>

            <div class="col-12" formGroupName="instructionFinalised">
                <mat-checkbox [disabled]="displayCompletedBy('instructionFinalised')" class="step" disableRipple formControlName="value">The instruction needs to be signed off.</mat-checkbox>
                <small *ngIf="displayCompletedBy('instructionFinalised')" class="text-muted">Completed by: {{ getStepCompletedBy('instructionFinalised') }} </small>
            </div>

            <div *ngIf="canCompleteInstruction()" class="col-12" formGroupName="instructionSignedOff">
                <mat-checkbox [disabled]="displayCompletedBy('instructionSignedOff')" class="step" disableRipple formControlName="value">The instruction is correct and complete.  This instruction is signed off.</mat-checkbox>
                <small *ngIf="displayCompletedBy('instructionSignedOff')" class="text-muted">Completed by: {{ getStepCompletedBy('instructionSignedOff') }} </small>
            </div>

        </div>

    </div>

</div>