<div class="modal-header">
    <h6 class="mb-0"><strong>Reject Quote?</strong></h6>
</div>
    
<div class="modal-body">
    <div mat-dialog-content>
        <p class="mb-4 mt-4">
            You are about to reject quote <strong>{{ data?.quoteNumber }}</strong>.
            <br />
            Please provide a reason why this quote is being rejected.
        </p>

        <textarea cdkFocusInitial class="form-control" [(ngModel)]="rejectedReason" rows="5"></textarea>

    </div>
    </div>
    
    <div class="modal-footer">
    <div class="inline-buttons">
        <button class="btn btn-secondary mr-2" (click)="closeDialog('cancel')"><i class="fi flaticon-cancel"></i>Cancel</button>
        <button class="btn btn-danger" (click)="rejectQuote()"><i class="fi flaticon-trash"></i>Reject quote</button>
    </div>
</div>
      