<h1>Buttons</h1>

<div class="row">

  <div id="buttons" class="col-12">

    <div class="row">

      <div class="col-6">
        <button class="btn btn-primary mr-3">Primary</button>
        <button class="btn btn-primary hover">Hover state</button>
      </div> <!-- Close div for col-6 -->

      <div class="col-6">
        <button class="btn btn-secondary mr-3">Secondary</button>
        <button class="btn btn-secondary hover">Hover state</button>
      </div> <!-- Close div for col-6 -->

      <div class="col-12">
         <hr />
      </div>
     
      <div class="col-6">
        <button class="btn btn-add mr-3"><i class="fi flaticon-add"></i> Add</button>
        <button class="btn btn-add hover"><i class="fi flaticon-add"></i> Hover state</button>
      </div> <!-- Close div for col-6 -->
      
      <div class="col-6">
        <button class="btn btn-remove mr-3"><i class="fi flaticon-trash"></i> Remove</button>
        <button class="btn btn-remove hover"><i class="fi flaticon-trash"></i> Hover state</button>
      </div> <!-- Close div for col-6 -->

      <div class="col-6">
        <button class="btn btn-save mr-3"><i class="fi flaticon-save"></i> Save</button>
        <button class="btn btn-save hover"><i class="fi flaticon-save"></i> Hover state</button>
      </div> <!-- Close div for col-6 -->
      
      <div class="col-6">
        <button class="btn btn-back mr-3"><i class="fi flaticon-remove"></i> Back</button>
        <button class="btn btn-back hover"><i class="fi flaticon-remove"></i> Hover state</button>
      </div> <!-- Close div for col-6 -->

      <div class="col-6">
        <button class="btn btn-edit mr-3"><i class="fi flaticon-edit"></i> Edit</button>
        <button class="btn btn-edit hover"><i class="fi flaticon-edit"></i> Hover state</button>
      </div> <!-- Close div for col-6 -->

      <div class="col-6">
        <button class="btn btn-primary btn-rounded mr-3">Rounded</button>
        <button class="btn btn-primary btn-rounded hover">Hover state</button>
      </div> <!-- Close div for col-6 -->

    </div> <!-- Close div for .row -->

  </div> <!-- Close div for .buttons -->

</div> <!-- Close div for .row -->
