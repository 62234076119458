<div id="content" class="row">

  <div class="col-12">
    <h2 class="page-header">
      Policies
    </h2>
  </div>

  <div class="col-12">
  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for #content -->