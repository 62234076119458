import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { HttpResponse } from '@angular/common/http';
import { HomeResponse, Home } from 'src/app/_shared/models/homeInsurance.model';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { BrokerService } from 'src/app/services/broker.service';
import { BrokerComponent } from '../../broker.component';
import { PolicyResponse, Policy } from 'src/app/_shared/models/policy.model';
import { EmployeeResponse, Employee } from 'src/app/_shared/models/employeeModel';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-new-edit-client-homeowner-insurance',
  templateUrl: './broker-new-edit-client-homeowner-insurance.component.html',
  styleUrls: ['./broker-new-edit-client-homeowner-insurance.component.scss'],
  providers: [BrokerComponent]
})

export class BrokerNewEditClientHomeownerInsuranceComponent implements OnInit, AfterViewInit {

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @Input() view:boolean

  routerPath: string = this.activeRoute.routeConfig.path;
  editHome:boolean = this.routerPath.includes('edit');

  clientId = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId') ;
  structureId = this.activeRoute.snapshot.paramMap.get('structureId');

  home;

  formSubmitted = false;
  homeFormGroup: FormGroup;

  isLoading: boolean = false;

  policies: Policy[];
  brokers: Employee[];

  wallMaterial: string[] = ['brick', 'cement', 'corrugatedIron', 'glass', 'prefab', 'asbestos', 'wood', 'other'];
  roofMaterial: string[] = ['concrete', 'corrugatedIron', 'glass', 'thatch', 'tiles', 'wood', 'asbestos'];
  floorMaterial: string[] = ['carpet', 'sealed', 'tiles', 'woodenFloors'];

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }

  isQuoteProduct: boolean = this.activeRoute.snapshot.queryParams['quote'] === 'true';

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private clientHomeService: BrokerClientHomeService,
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent,
    private router: Router,
    public location: Location,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (this && (!control.value && this.isQuoteProduct)) {
      return { 'required': true };
    }
    return null;
  }


  ngOnInit(): void {
    this.homeFormGroup = this.formBuilder.group({
      address: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        unit: [''],
        city: [''],
        suburb: [''],
        province: [''],
        country: ['South Africa'],
        postalCode: [''],
        municipalAccNr: [''],
        stand: [''],
        formattedAddress: ['']
      }),
      insurance: this.formBuilder.group({
        companyName: ['', this.insuranceRequired],
        insuredValue: ['', this.insuranceRequired],
        premium: ['', this.insuranceRequired],
        policyNumber: ['', this.insuranceRequired],
        inceptionDate: ['', this.insuranceRequired],
        coverType: ['', this.insuranceRequired],
        excess: [''],
        accidentalDamage: [''],
        powerSurge: ['']
      }),
      residenceType: ['', Validators.required],
      isPrimaryResidence: [true, Validators.required],
      isHolidayHome: [false, Validators.required],
      buildingAge: ['', Validators.required],
      environment: this.formBuilder.group({
        builtOnSteepSlope: false,
        excessiveRetainingWalls: false,
        closeToWater: false,
        wallDamp: false,
        crackedWalls: false,
        subsidence: false
      }),
      areaType: ['', Validators.required],
      externalWalls: ['', Validators.required],
      roof: ['', Validators.required],
      floor: ['', Validators.required],
      underConstruction: false,
      thatch: this.formBuilder.group({
        hasThatch: false,
        lightningConductorInstalled: false,
        thatchWithin5Meters: false,
        treated: false
      }),
      swimmingPool: false,
      gateMotor: false,
      isCommune: false,
      occupiedDuringDay: false,
      geyser: this.formBuilder.group({
        totalGeysers: [],
        geysers: this.formBuilder.array([])
      }),
      isQuoteProduct: false,
    })

    this.homeFormGroup.get('insurance').get('companyName').setValue(this.authenticationService.currentCompany.companyName);
    if(this.isQuoteProduct){
      this.homeFormGroup.get('isQuoteProduct').setValue(true);
    }

  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authenticationService.currentCompany.companyName, this.clientId, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  ngAfterViewInit(): void {

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicyNumber();
    }

    this.getLocalStorageItem();

    if(this.editHome){
      this.getCurrentStructure(this.structureId);
    }
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authenticationService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  addNewPolicy(){
    localStorage.setItem('SureSpace-Home', JSON.stringify(this.homeFormGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-Home')){
      this.homeFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-Home')));
      localStorage.removeItem('SureSpace-Home');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  createGeyser(): FormGroup {
    return this.formBuilder.group({
      solarGeyser: [],
      installationDate: [],
      installedOutsideHouse: []
    })
  }

  addGeyserToHome(): void {
    let geyserInFormGroup = this.homeFormGroup.get('geyser').get('geysers') as FormArray;
    geyserInFormGroup.push(this.createGeyser());
  }

  addNewStructure(saveType?:string){

    if(this.homeFormGroup.invalid){
      console.warn(this.homeFormGroup)
      this.toastr.error('There are some errors on your form.', 'Failed saving home.')
      return
    }

    if(!this.homeFormGroup.get('address').get('formattedAddress').value){
      let homeFormGroupAddress = this.homeFormGroup.get('address');
      let addressLine1 = homeFormGroupAddress.get('addressLine1').value;
      let suburb = homeFormGroupAddress.get('suburb').value;
      let city = homeFormGroupAddress.get('city').value;
      let postalCode = homeFormGroupAddress.get('postalCode').value;
      homeFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
      return
    }

    this.clientHomeService.addNewClientHomeInsurance(this.clientId, this.homeFormGroup.value).subscribe(
      (response: HttpResponse<HomeResponse>) => {
        this.location.back()
      }
    )
  }

  addNewQuotedProduct(){
    this.clientHomeService.addNewClientHomeQuote(this.clientId, this.authService.currentCompany.companyName, this.homeFormGroup.value).subscribe(
      (response: HttpResponse<HomeResponse>) => {
        let newHome = response.body.home;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newHome))
        this.location.back()
      }
    )
  }

  editQuotedProduct(){
    this.clientHomeService.editClientHomeQuote(this.clientId, this.structureId, this.authService.currentCompany.companyName, this.homeFormGroup.value).subscribe(
      (response: HttpResponse<HomeResponse>) => {
        let newHome = response.body.home;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newHome))
        this.location.back()
      }
    )
  }

  getCurrentStructure(structureId:string){
    this.clientHomeService.getSingleHomeInsuranceForClient(this.clientId, structureId).subscribe(
      (response:HttpResponse<HomeResponse>) => {
        this.home = response.body.home;

        this.homeFormGroup.patchValue(this.home);

        this.homeFormGroup.controls.insurance.patchValue(this.home['insurance']);
      }
    )
  };

  updateStructure(saveType?:string){

    if(this.homeFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Failed saving home.')
      return
    }

    if(!this.homeFormGroup.get('address').get('formattedAddress').value){
      let homeFormGroupAddress = this.homeFormGroup.get('address');
      let addressLine1 = homeFormGroupAddress.get('addressLine1').value;
      let suburb = homeFormGroupAddress.get('suburb').value;
      let city = homeFormGroupAddress.get('city').value;
      let postalCode = homeFormGroupAddress.get('postalCode').value;
      homeFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    if(this.isQuoteProduct){
      this.editQuotedProduct();
      return
    }

    this.clientHomeService.updateClientHomeInsurance(this.clientId, this.structureId, this.homeFormGroup.value).subscribe(
      (response: HttpResponse<HomeResponse>) => {
        this.location.back()
      }
    )
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let homeFormGroupAddress = this.homeFormGroup.get('address');

    homeFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    homeFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    homeFormGroupAddress.get('city').setValue(findInAddress('locality'));
    homeFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    homeFormGroupAddress.get('country').setValue(findInAddress('country'));
    homeFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    homeFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);

  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}
