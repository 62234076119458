import { Employee } from 'src/app/_shared/models/employeeModel';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from './../../../services/broker.service';
import { User, UserResponse } from './../../../_shared/models/User.model';
import { debounceTime, tap, switchMap, finalize, mergeMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarService } from 'src/app/services/calendar.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-create-new-calendar-event',
  templateUrl: './broker-create-new-calendar-event.component.html',
  styleUrls: ['./broker-create-new-calendar-event.component.scss']
})
export class BrokerCreateNewCalendarEventComponent implements OnInit {

  currentCompany = this.authService.currentCompany.companyName;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  calendarEventFormGroup: FormGroup;
  formSubmitted: boolean = false;

  isAllDayEvent: boolean = false;

  isLoading: boolean = false;
  clients: any[];
  employees: any[];

  constructor(
    private dialogRef:MatDialogRef<BrokerCreateNewCalendarEventComponent>,
    private formBuilder: FormBuilder,
    private calendarService: CalendarService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private brokerService: BrokerService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit(): void {

    this.getAllClients();

    if(this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerDirector') ){
      this.getAllEmployees();
    }
    

    this.calendarEventFormGroup = this.formBuilder.group({
      clientType: 'User',
      client: this.formBuilder.group({
        _id: [''],
        firstName: [''],
        lastName: [''],
        // refNr: [''],
        clientNr: [''],
        username: [''],
        companyName: ['']
      }),
      clientAutoCompleteDisplay: [''],
      employee: this.formBuilder.group({
        _id: [''],
        firstName: [''],
        lastName: [''],
      }),
      employeeAutoCompleteDisplay: [''],
      title: ['', Validators.required],
      description: [''],
      start: ['', Validators.required],
      end: ['', Validators.required],
      allDay: [false],
      type: ['', Validators.required],
      companyName: ['', Validators.required]
    })

    this.calendarEventFormGroup.get('companyName').setValue(this.authService.currentCompany.companyName);

    // GET Employees
    this.calendarEventFormGroup
    .get('employeeAutoCompleteDisplay')
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName, {search: value}, true)
        .pipe(finalize(() => this.isLoading = false))
      ),
    )
    .subscribe(
      (response) => {
        this.employees = response.body.user
      }
    )

    // GET CLIENTS
    this.calendarEventFormGroup
    .get('clientAutoCompleteDisplay')
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, {search: value}, true)
      // switchMap(value => this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName, {search: value})
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response) => {
        this.clients = response.body.user;
      }
    )

  }

  addNewCalendarEvent(){
    this.formSubmitted = true;
    this.calendarEventFormGroup.markAllAsTouched();

    if(this.isAllDayEvent){
      this.calendarEventFormGroup.get('end').setValue(this.calendarEventFormGroup.get('start').value)
    }

    if(this.calendarEventFormGroup.invalid){
      this.toastr.error('There were some errors while creating a calendar event.  Please make sure that all the required fields are filled.', 'Error creating calendar event.')
      return;
    }

    this.calendarService.createNewCalendarEvent(this.calendarEventFormGroup.value, this.currentCompany).subscribe(response => {
      this.closeDialog('success', response.body.events);
    })
  }

  closeDialog(status: string, data?:any){
    this.dialogRef.close({
      status, data
    })
  }

  markEnd(event){
    this.isAllDayEvent = event.checked;
  }

  displayFn(user?: any) {
    if(user === 0) return 'No client';

    return user._id ? user.companyName ? user.companyName : `${user.firstName} ${user.lastName}` : typeof user === 'string' ? user : undefined;
  }

  displayEmployee(user?: Employee) {
    if(user === 0) return 'No employee';

    return user._id ? `${user.firstName} ${user.lastName}` : typeof user === 'string' ? user : undefined;
  }

  mapClient(client){

    if(!client) return

    this.calendarEventFormGroup.get('client').get('_id').setValue(client._id);
    this.calendarEventFormGroup.get('client').get('firstName').setValue(client.firstName);
    this.calendarEventFormGroup.get('client').get('lastName').setValue(client.lastName);
    this.calendarEventFormGroup.get('client').get('clientNr').setValue(client.broker.find(company => company.companyName === this.currentCompany).clientNumber);
    this.calendarEventFormGroup.get('client').get('username').setValue(client.username);
    this.calendarEventFormGroup.get('client').get('companyName').setValue(client.companyName);
    
    if(client.companyName){
      this.calendarEventFormGroup.get('clientType').setValue('CommercialClient');
    };
  }


  mapEmployee(employee){
    this.calendarEventFormGroup.get('employee').get('_id').setValue(employee._id);
    this.calendarEventFormGroup.get('employee').get('firstName').setValue(employee.firstName);
    this.calendarEventFormGroup.get('employee').get('lastName').setValue(employee.lastName);
  }

  getAllClients(){

    let req = {
      limit: 10
    }

    this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, req, true).subscribe(response => {
      this.clients = response.body.user;
    })
  }

  getAllEmployees(){

    let req = {
      limit: 10
    }

    this.brokerService.getAllCompanyEmployees(this.currentCompany, req, true).subscribe(response => {
      this.employees = response.body.user;
    })
  }


}
