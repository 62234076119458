<div id="content" class="row">

  <!-- <div class="col-12">
    <h2 class="page-header">
      Add a new insured vehicle
    </h2>
  </div> Close div for .row -->

  <div class="col-12 mt-3">

    <div class="panel panel-primary">
      <form id="newVehicleForm" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">

        <div class="panel-heading">
          <h5>Change my password</h5>
          <hr />
        </div>

        <div class="panel-body">

          <div class="row">
            <div class="col-12 col-sm-8 col-md-6">
              <!-- Current Password -->
              <div class="form-group">

                <label for="currentPassword">Current password:</label>
                <input
                  type="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && form['password'].errors }"
                  name="password"
                  formControlName="password"
                />
                <div *ngIf="submitted && form['password'].errors" class="invalid-feedback">
                  <div *ngIf="form['password'].errors.required">We need your current password to be able to change your password.</div>
                </div>

              </div> <!-- Close div for .form-group -->

              <!-- Password -->
              <div class="form-group"
                ng-class="{'has-error': (formResetPassword.$submitted && formResetPassword.password.$error.required) || (formResetPassword.password.$error.minlength && formResetPassword.password.$touched)}">

                <label for="password">New password:</label>
                <input
                  #newPassword
                  type="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && form['newPassword'].errors }"
                  name="newPassword"
                  formControlName="newPassword"
                />

                <mat-password-strength 
                  #passwordStengthMeter
                  (onStrengthChanged)="onStrengthChanged($event)"
                  [password]="newPassword.value">
                </mat-password-strength>

                <div *ngIf="submitted && form['newPassword'].errors" class="invalid-feedback">
                  <div *ngIf="form['newPassword'].errors.required">Please provide a new password.</div>
                  <div *ngIf="form['newPassword'].errors.minlength">Password must be at least 8 characters.</div>
                </div>
                
                <p class="color-dark-grey smallest mt-1">
                  <small>To conform with our Strong Password policy, you are required to use a
                  sufficiently strong password. Password must be more than 8 characters.</small>
                </p>

                <mat-password-strength-info
                  *ngIf="form['newPassword'].dirty"
                  [passwordComponent]="passwordStengthMeter">
                </mat-password-strength-info>

              </div>
              <!-- Close div for .form-group -->

              <!-- Confirm Password -->
              <div class="form-group">

                <label for="confirmNewPassword">Confirm new password:</label>

                <input
                  type="password"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && form['confirmNewPassword'].errors }"
                  name="confirmNewPassword"
                  formControlName="confirmNewPassword"
                />
                <div *ngIf="submitted && form['confirmNewPassword'].errors" class="invalid-feedback">
                  <div *ngIf="form['confirmNewPassword'].errors.required">Please confirm your new password.</div>
                  <div *ngIf="form['newPassword'].errors.minlength">Password must be at least 8 characters.</div>
                </div>

              </div> <!-- Close div for .form-group -->

              <div class="col-12 inline-buttons">
                <div class="row">

                  <app-save-button type="submit"></app-save-button>
                  <app-cancel-button type="button" routerLink="/myAccount"></app-cancel-button>

                </div> <!-- Close div for .row -->
              </div> <!-- Close div for .inline-buttons -->

            </div> <!-- Close div for .col-12 col-sm-8 col-md-6 -->
          </div> <!-- Close div for .row -->

        </div> <!-- Close div for .panel-body -->

      </form>
    </div> <!-- Close div for .panel -->

  </div>

</div> <!-- Close div for #content -->
