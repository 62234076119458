import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Employee, EmployeeResponse } from './../../../_shared/models/employeeModel';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from './../../../services/broker.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-broker-reporting-returned-debit',
  templateUrl: './broker-reporting-returned-debit.component.html',
  styleUrls: ['./broker-reporting-returned-debit.component.scss']
})
export class BrokerReportingReturnedDebitComponent implements OnInit {

  returnedDebitFilters: FormGroup;

  returnedDebitTotals: any;
  employeesReturnedDebits: any;

  employees: Employee[];
  filteredEmployees: Observable<Employee[]>;

  displayedColumns: string[] = ['employee', 'totalReturnedDebits', 'completedReturnedDebits', 'avgResolutionTime'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService
  ) {

    this.returnedDebitFilters = this.formBuilder.group({
      timePeriod: [""],
      dateFrom: [""],
      dateTo: [""],
      productType: [""],
      returnedDebitType: [""],
      status: [""],
      employee: [""],
      employeeId: [""]
    })

  }

  ngOnInit(): void {
    this.getEmployees();
    this.getReturnedDebitReport();
  }

  getReturnedDebitReport(){
    this.reportingService.getReturnedDebitReports(this.authService.currentCompany.companyName, this.returnedDebitFilters.value).subscribe(response => {
      this.returnedDebitTotals = response.body.totals;
      this.employeesReturnedDebits = response.body.employees.employeeHandledReturnedDebits;
    })
  }

  getHoursFromMs(ms:number){
    if(!ms) return 'No completes'
    return `${moment.utc(ms).format('HH:mm')} hrs`;
  }

  findBiggestValue(array){
    return array.reduce((a, b) => a.count > b.count ? a : b);
  }

  createDate(selection){

    if(selection.value === 'custom') return;

    if(!selection.value || selection.value == ""){
      this.returnedDebitFilters.get('dateTo').setValue("");
      this.returnedDebitFilters.get('dateFrom').setValue("");
    } else {
      let today = moment();

      this.returnedDebitFilters.get('dateTo').setValue(today.toDate());
      this.returnedDebitFilters.get('dateFrom').setValue(today.subtract(Number(selection.value), "days").toDate());
    }

  }

  getEmployees() {
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;

        this.filteredEmployees = this.returnedDebitFilters.get('employee').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    )
  }

  private _filter(value: string): Employee[] {
    if(typeof value !== 'string') return;

    const filterValue = value.toLowerCase();
    return this.employees.filter(employee => employee.firstName.toLowerCase().includes(filterValue) || employee.lastName.toLowerCase().includes(filterValue));
  }

  displayEmployeeName(employee: Employee) {
    return employee.lastName ? `${employee?.firstName} ${employee?.lastName}` : 'All employees'
  }

  mapEmployee(event:MatAutocompleteSelectedEvent):void {
    if(event.option.value._id === 0){ return this.returnedDebitFilters.get('employeeId').setValue("")}
    this.returnedDebitFilters.get('employeeId').setValue(event.option.value._id)
  }

}
