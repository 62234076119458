import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { Observable } from 'rxjs';
import { HomeContentResponse, HomeContent } from '../_shared/models/homeContentInsurance.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerClientHomeContentService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientHomeContentContentInsurance(clientId:string, homeContentFormData:HomeContent, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.post<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}`, homeContentFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientHomeContentQuote(clientId:string, companyName: string, homeContentFormData:HomeContent, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.post<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/company/${companyName}/quote`, homeContentFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientHomeContentQuote(clientId:string, contentId:string, companyName: string, homeContentFormData:HomeContent, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.put<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/company/${companyName}/quote/${contentId}`, homeContentFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllHomeContentInsuranceForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.get<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousHomeContentInsuranceForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.get<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/previouslyInsured/client/${clientId}/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleHomeContentInsuranceForClient(clientId:string, homeContentId:string, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.get<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/homeContent/${homeContentId}`, {
      observe: 'response',
      params: req
    })
  }

  updateClientHomeContentInsurance(clientId:string, homeContentId:string, homeContentFormData:HomeContent, req?:any): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.put<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/${homeContentId}`, homeContentFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteInsuranceFromHomeContent(clientId:string, homeContentId:string): Observable<HttpResponse<HomeContentResponse>>{
    return this.http.delete<HomeContentResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/homeContent/client/${clientId}/${homeContentId}`, {
      observe: 'response'
    })
  }

}