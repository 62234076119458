<div id="register" class="container">

  <div class="row">

    <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 text-center mb-5">
      <h1 class="color-dark-grey">Forgot your password</h1>
    </div>

    <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
      <p>
        <strong>Please enter the email address registered on your account.</strong>
      </p>
    </div>

    <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
      <p>We'll email you a link to change your password.</p>
    </div>

    <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mt-4">
      <form [formGroup]="registerFormGroup">
        <div class="form-group">
  
          <label for="email">Email address:</label>
          <input formControlName="email" type="email" class="form-control"
            [ngClass]="{ 'is-invalid': formSubmitted && form.email.errors }" placeholder="Email address" required>
  
          <div *ngIf="formSubmitted && form['email'].errors" class="invalid-feedback">
            <div *ngIf="form['email'].errors.required">Please enter your email address.</div>
            <div *ngIf="form['email'].errors.pattern || form['email'].errors.email">Please enter a valid email address.</div>
          </div>
  
        </div> <!-- Close div for .form-group -->
  
        <div class="text-center">
          <button type="button" class="btn btn-brand btn-widest mt-4 mr-0" (click)="forgotPassword()">Send</button>
          <br />
          <button type="button" class="btn btn-secondary mt-3 mr-0" [routerLink]="['/login']">Back to login</button>
        </div>
      </form>
    </div>

  </div> <!-- Close div for .row -->

</div> <!-- Close div for .container -->
