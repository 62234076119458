
<form id="newVehicleForm" [formGroup]="cancelAccountForm" (ngSubmit)="cancelAccount()">
  
  <div class="modal-header">
    <h4>Are you sure</h4>
  </div>

  <div class="modal-body">
    <div mat-dialog-content>

      <h5>You are about to cancel your SureSpace account</h5>

      <p>
        If you cancel your account, it will be blocked and you will no longer be able to log in.
        All of your content will remain attributed to your user name.<br />
        This action cannot be undone.
      </p>

      <p>Please enter your password in the field below to confirm cancelling your account.</p>

      <input
        type="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && form['security'].errors }"
        name="security"
        formControlName="security"
      />
      <div *ngIf="submitted && form['security'].errors" class="invalid-feedback">
        <div *ngIf="form['security'].errors.required">Please enter your SureSpace password.</div>
      </div>
      
    </div>
  </div> <!-- Close div for .modal-body -->

  <div class="modal-footer">
    <div mat-dialog-actions class="inline-buttons">
      <app-save-button type="submit" buttonText="Cancel account" cdkFocusInitial></app-save-button>
      <app-cancel-button type="button" (click)="close()" buttonText="Go back to using SureSpace"></app-cancel-button>
    </div>
  </div> <!-- Close div for .modal-footer -->

</form>
