import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { SurespaceService } from 'src/app/services/surespace.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BugResponse } from 'src/app/_shared/models/bug.model';
import { SuggestionResponse } from '../_shared/models/suggestion.model';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit, AfterViewInit {

  authenticated = this.globals.AUTENTICATED;

  public Editor = ClassicEditor;

  submitted = false;
  suggestionForm: FormGroup;
  get form() { return this.suggestionForm.controls; }

  currentUser: any = JSON.parse(localStorage.getItem('SureSpace-currentUser'));

  constructor(
    public globals: Globals,
    private surespaceService: SurespaceService,
    private formBuilder: FormBuilder
  ) { }

  reportIssue() {

    this.submitted = true;
    if (this.suggestionForm.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    this.surespaceService.addSuggestion(this.suggestionForm.value).subscribe(
      (response:HttpResponse<SuggestionResponse>) => {
        this.suggestionForm.reset();
        this.setFormValue();
      }
    )
  }

  ngOnInit() {
    this.suggestionForm = this.formBuilder.group({
      submitterName: ['', Validators.required],
      submitterEmail: ['', [Validators.required, Validators.email]],
      subject: [''],
      suggestion: ['', Validators.required]
    });
  }

  setFormValue() {
    this.suggestionForm.setValue({
      submitterName: this.currentUser.firstName,
      submitterEmail: this.currentUser.email,
      subject: 'Suggestion',
      suggestion: ''
    });  
  }

  ngAfterViewInit() {
      this.setFormValue();
  }

}
