<h5 mat-dialog-title>
    Reject claim?
  </h5>
  
  <div mat-dialog-content class="pb-3">
    <p *ngIf="data?.request?.whatNeedsToHappen === 'handover'">What is the reason that you are rejecting this handover?</p>
    <p *ngIf="data?.request?.whatNeedsToHappen === 'signOff'">What is the reason that the claim can't be signed off?</p>
    <input class="form-control" [(ngModel)]="rejectReason" />
  </div>
  
  <div mat-dialog-actions align="end">
    <button class="btn btn-secondary" (click)="closeDialog('cancel')">Cancel</button>
    <button class="btn btn-success" cdkFocusInitial (click)="rejectRequest(data?._id)">Submit</button>
  </div>