import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class RemoveService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  removeSelectedFamilyMember(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/myAccount/familyMember/${id}`, {
      observe: 'response'
    });
  };

  removeSelectedInsuranceCompany(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/insurance/insuranceCompany/${id}`, {
      observe: 'response'
    });
  };

  removeHouseholdInsurance(){
    return this.http.delete(`${this.globals.SERVER_URL}/api/householdInsurance`, {
      observe: 'response'
    });
  };

  removeSelectedVehicle(registrationNumber){
    return this.http.delete(`${this.globals.SERVER_URL}/api/vehicle/${registrationNumber}`, {
      observe: 'response'
    });
  }

  removeSelectedHouseholdItem(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/householdItem/${id}`, {
      observe: 'response'
    });
  }

  removePersonalItem(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/personalItem/${id}`, {
      observe: 'response'
    });
  }

  removeSelectedStructure(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/structure/structure/${id}`, {
      observe: 'response'
    });
  }

}
