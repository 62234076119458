<div class="container">

    <div class="row">

      <div class="col-12">

        <h2 class="text-white page-header">Terms and Conditions</h2>

        <section>
          <p class="text-white">
            <strong>Statement of Rights and Responsibilities</strong>
            <br /> This Statement of Rights and Responsibilities ("Statement," "Terms," or "SRR") derives from the SureSpace Principles,
            and is our terms of service that governs our relationship with our users and others who interact with SureSpace,
            as well as SureSpace brands, products and services, which we call the “SureSpace Services” or “Services”. By using
            or accessing the SureSpace Services, you agree to this Statement, as updated from time to time in accordance with
            Section 11 below. Additionally, you will find resources at the end of this document that help you understand how
            SureSpace works.
          </p>
        </section>

        <section>
          <p class="text-white">
            <strong>1. Privacy</strong>
            <br /> Your privacy is very important to us. We designed our Data Policy to make important disclosures about how you
            can use SureSpace to share information. We encourage you to read the Data Policy, and to use it to help you make
            informed decisions.
          </p>
        </section>

        <section>
          <p class="text-white">
            <strong>2. Sharing Your Content and Information</strong>
            <br /> You own all of the content and information you post on SureSpace, and you can control how it is shared through
            your privacy and application settings. In addition:
          </p>

          <ol class="text-white">

            <li>For content that is covered by intellectual property rights, like photos and documents (IP content), you specifically
              give us the following permission, subject to your privacy and application settings: to share implicated information
              with parties you have nominated (IP Licence). This IP License ends when you delete your IP content or your
              account, unless your content has been shared with others, and they have not deleted it.
            </li>

            <li>When you delete IP content, it is deleted in a manner similar to emptying the recycle bin on a computer. However,
              you understand that removed content may persist in backup copies for a reasonable period of time (but will
              not be available to others).
            </li>

            <li>When you use an application, the application may ask for your permission to access your content and information
              as well as content and information that others have shared with you. We require these applications to respect
              your privacy, and your agreement with that application will control how the application can use, store, and
              transfer that content and information. (To learn more about Platform, including how you can control what information
              other people may share with applications, read our Data Policy and Platform Page.)
            </li>

            <li>When you publish content or information using the Public setting, it means that you are allowing certain parties,
              to access and use that information, and to associate it with you (i.e., your name and profile picture).
            </li>

            <li>We always appreciate your feedback or other suggestions about SureSpace, but you understand that we may use your
              feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to
              offer them).
            </li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>3. Safety</strong>
            <br /> We do our best to keep SureSpace safe, but we cannot guarantee it. We need your help to keep SureSpace safe, which
            includes the following commitments by you:
          </p>

          <ol class="text-white">

            <li>You will not post unauthorized commercial communications (such as spam) on SureSpace.</li>

            <li>You will not collect users' content or information, or otherwise access SureSpace, using automated means (such
              as harvesting bots, robots, spiders, or scrapers).</li>

            <li>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on SureSpace.</li>

            <li>You will not upload viruses or other malicious code.</li>

            <li>You will not solicit login information or access an account belonging to someone else.</li>

            <li>You will not bully, intimidate, or harass any user.</li>

            <li>You will not post content that: is hate speech, threatening, or pornographic; incites violence; or contains nudity
              or graphic or gratuitous violence.</li>

            <li>You will not use SureSpace to do anything unlawful, misleading, malicious, or discriminatory.</li>

            <li>You will not do anything that could disable, overburden, or impair the proper working or appearance of SureSpace,
              such as a denial of service attack or interference with page rendering or other SureSpace functionality.</li>

            <li>You will not facilitate or encourage any violations of this Statement or our policies.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>4. Registration and Account Security</strong>
            <br /> SureSpace users provide their real names and information, and we need your help to keep it that way. Here are
            some commitments you make to us relating to registering and maintaining the security of your account:
          </p>

          <ol class="text-white">

            <li>You will not provide any false personal information on SureSpace, or create an account for anyone other than
              yourself without permission.</li>

            <li>You will not create more than one personal account (this doesn’t include a business account belonging to yourself).</li>

            <li>If we disable your account, you will not create another one without our permission.</li>

            <li>You will keep your contact information accurate and up-to-date.</li>

            <li>You will not share your password (or in the case of developers, your secret key), let anyone else access your
              account, or do anything else that might jeopardize the security of your account.</li>

            <li>You will not transfer your account (including any Page or application you administer) to anyone without first
              getting our written permission.</li>

            <li>If you select a username or similar identifier for your account or Page or alias, we reserve the right to remove
              or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a username that
              does not closely relate to a user's actual name).</li>

          </ol>

        </section>

        <section>

          <p class="text-white">
            <strong>5. Protecting Other People's Rights</strong>
            <br /> We respect other people's rights, and expect you to do the same.
          </p>

          <ol class="text-white">

            <li>You will not post content or take any action on SureSpace that infringes or violates someone else's rights or
              otherwise violates the law.</li>

            <li>We can remove any content or information you post on SureSpace if we believe that it violates this Statement
              or our policies.</li>

            <li>If we remove your content for infringing someone else's copyright, and you believe we removed it by mistake,
              we will provide you with an opportunity to appeal.</li>

            <li>If you repeatedly infringe other people's intellectual property rights, we will disable your account when appropriate.</li>

            <li>You will not use our copyrights or Trademarks or any confusingly similar marks, except as expressly permitted
              by our Brand Usage Guidelines or with our prior written permission.</li>

            <li>If you collect information from users, you will: obtain their consent, make it clear you (and not SureSpace)
              are the one collecting their information, and post a privacy policy explaining what information you collect
              and how you will use it.</li>

          </ol>

        </section>

        <section>

          <p class="text-white">
            <strong>6. Mobile and Other Devices</strong>
          </p>

          <ol class="text-white">

            <li>We currently provide our mobile services for free, but please be aware that your carrier's normal rates and fees,
              such as text messaging and data charges, will still apply.</li>

            <li>In the event you change or deactivate your mobile telephone number, you will need to update your account information
              on SureSpace within 48 hours to ensure that your messages are not sent to the person who acquires your old
              number.</li>

            <li>You provide consent and all rights necessary to enable users to sync (including through an application) their
              devices with any information that is visible to them on SureSpace.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>7. Payments</strong><br />
            If you make a payment on SureSpace, you agree to our Payments Terms unless it is stated that other terms apply.
          </p>

        </section>

        <section>

          <p class="text-white">
            <strong>8. Special Provisions Applicable to Developers/Operators of Applications and Websites</strong>
            <br /> If you are a developer or operator of a Platform application or website or if you use Social Plugins, you must
            comply with the SureSpace Platform Policy.
          </p>

        </section>

        <section>
          <p class="text-white">
            <strong>9. About Advertisements and Other Commercial Content Served or Enhanced by SureSpace</strong>
            <br /> Our goal is to deliver advertising and other commercial or sponsored content that is valuable to our users and
            advertisers. In order to help us do that, you agree to the following:
          </p>

          <ol class="text-white">

            <li>You give us permission to use your name, profile picture, content, and information in connection with commercial,
              sponsored, or related content (such as a brand you like) served or enhanced by us. This means, for example,
              that you permit a business or other entity to pay us to display your name and/or profile picture with your
              content or information, without any compensation to you. If you have selected a specific audience for your
              content or information, we will respect your choice when we use it.</li>

            <li>We do not give your content or information to advertisers without your consent.</li>

            <li>You understand that we may not always identify paid services and communications as such.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>10. Special Provisions Applicable to Software</strong>
          </p>

          <ol class="text-white">

            <li>If you download or use our software, such as a stand-alone software product, an app, or a browser plugin, you
              agree that from time to time, the software may download and install upgrades, updates and additional features
              from us in order to improve, enhance, and further develop the software.</li>

            <li>You will not modify, create derivative works of, decompile, or otherwise attempt to extract source code from
              us, unless you are expressly permitted to do so under an open source license, or we give you express written
              permission.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>11. Amendments</strong>
          </p>

          <ol class="text-white">

            <li>We’ll notify you before we make changes to these terms and give you the opportunity to review and comment on
              the revised terms before continuing to use our Services.</li>

            <li>If we make changes to policies, guidelines or other terms referenced in or incorporated by this Statement, we
              may provide notice on the Site Governance Page.</li>

            <li>Your continued use of the SureSpace Services, following notice of the changes to our terms, policies or guidelines,
              constitutes your acceptance of our amended terms, policies or guidelines.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">

            <strong>12. Termination</strong>
            <br /> If you violate the letter or spirit of this Statement, or otherwise create risk or possible legal exposure for
            us, we can stop providing all or part of SureSpace to you. We will notify you by email or at the next time you
            attempt to access your account. You may also delete your account or disable your application at any time. In all
            such cases, this Statement shall terminate, but the following provisions will still apply: 2.2, 2.4, 3-5, and 12-16.

          </p>
        </section>

        <section>
          <p class="text-white">
            <strong>13. Disputes</strong>
          </p>

          <ol class="text-white">

            <li>You will resolve any claim, cause of action or dispute (claim) you have with us arising out of or relating to
              this Statement or SureSpace exclusively in the Pretoria Magistrate Court, and you agree to submit to the personal
              jurisdiction of this courts for the purpose of litigating all such claims. The laws of the Republic of South
              Africa will govern this Statement, as well as any claim that might arise between you and us, without regard
              to conflict of law provisions.</li>

            <li>If anyone brings a claim against us related to your actions, content or information on SureSpace, you will indemnify
              and hold us harmless from and against all damages, losses, and expenses of any kind (including reasonable legal
              fees and costs) related to such claim. Although we provide rules for user conduct, we do not control or direct
              users' actions on SureSpace and are not responsible for the content or information users transmit or share
              on SureSpace. We are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable
              content or information you may encounter on SureSpace. We are not responsible for the conduct, whether online
              or offline, of any user of SureSpace.</li>

            <li>WE TRY TO KEEP SureSpace UP, BUG-FREE, AND SAFE, BUT YOU USE IT AT YOUR OWN RISK. WE ARE PROVIDING SureSpace
              AS IS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT SureSpace WILL ALWAYS BE SAFE,
              SECURE OR ERROR-FREE OR THAT SureSpace WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. SureSpace
              IS NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR
              DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR
              IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.</li>

            <li>WE WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR INCIDENTAL DAMAGES.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>14. Special Provisions Applicable to Users Outside the South Africa</strong>
            <br /> We strive to create a global community with consistent standards for everyone, but we also strive to respect local
            laws. The following provisions apply to users and non-users who interact with SureSpace outside South Africa:
          </p>

          <ol class="text-white">

            <li>You consent to having your personal data transferred to and processed in South Africa.</li>

            <li>If you are located in a country other than South Africa, you will not engage in commercial activities on SureSpace
              (such as advertising or payments) or operate a Platform application or website.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>15. Definitions</strong>
          </p>

          <ol class="text-white">

            <li>By "SureSpace" or” SureSpace Services” we mean the features and services we make available, including through
              (a) our website at www.SureSpace.co.za and any other SureSpace branded or co-branded websites (including sub-domains,
              widgets, and mobile versions); (b) other media, brands, products, services, software devices, or networks now
              existing or later developed. SureSpace reserves the right to designate, in its sole discretion, that certain
              of our brands, products, or services are governed by separate terms and not this SRR.</li>

            <li>By "Platform" we mean a set of APIs and services (such as content) that enable others, including application
              developers and website operators, to retrieve data from SureSpace or provide data to us.</li>

            <li>By "information" we mean facts and other information about you, including actions taken by users and non-users
              who interact with SureSpace.</li>

            <li>By "content" we mean anything you or other users post, provide or share using SureSpace Services.</li>

            <li>By "data" or "user data" or "user's data" we mean any data, including a user's content or information that you
              or third parties can retrieve from SureSpace or provide to SureSpace through Platform.</li>

            <li>By "post" we mean post on SureSpace or otherwise make available by using SureSpace.</li>

            <li>By "use" we mean use, run, copy, publicly perform or display, distribute, modify, translate, and create derivative
              works of.</li>

            <li>By "application" we mean any application or website that uses or accesses Platform, as well as anything else
              that receives or has received data from us. If you no longer access Platform but have not deleted all data
              from us, the term application will apply until you delete the data.</li>

            <li>By “Trademarks” we mean the list of trademarks provided here.</li>

          </ol>

        </section>

        <section>
          <p class="text-white">
            <strong>16. Other</strong>
          </p>

          <ol class="text-white">

            <li>This Statement makes up the entire agreement between the parties regarding SureSpace, and supersedes any prior
              agreements.</li>

            <li>If any portion of this Statement is found to be unenforceable, the remaining portion will remain in full force
              and effect.</li>

            <li>If we fail to enforce any of this Statement, it will not be considered a waiver.</li>

            <li>Any amendment to or waiver of this Statement must be made in writing and signed by us.</li>

            <li>You will not transfer any of your rights or obligations under this Statement to anyone else without our consent.</li>

            <li>All of our rights and obligations under this Statement are freely assignable by us in connection with a merger,
              acquisition, or sale of assets, or by operation of law or otherwise.</li>

            <li>Nothing in this Statement shall prevent us from complying with the law.</li>

            <li>This Statement does not confer any third party beneficiary rights.</li>

            <li>We reserve all rights not expressly granted to you.</li>

            <li>You will comply with all applicable laws when using or accessing SureSpace.</li>

          </ol>
        </section>

        <section class="text-center">
          <p class="text-white">
            <small>
              SureSpace Terms and Conditions is currently only available in the English version.
            </small>
          </p>
        </section>

      </div>

    </div>
    <!-- Close div for .row -->

  </div>
  <!-- Close div for .container -->
