import { AuthenticationService } from './../../../services/authentication.service';
import { CommercialClientsService } from './../../../services/commercial-clients.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-commercial-clients-new',
  templateUrl: './broker-commercial-clients-new.component.html',
  styleUrls: ['./broker-commercial-clients-new.component.scss']
})
export class BrokerCommercialClientsNewComponent implements OnInit {

  commercialClientFormGroup: FormGroup;
  currentCompany: string;

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private commercialClientService: CommercialClientsService,
    private authenticationService: AuthenticationService
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;
  }

  ngOnInit(): void {
  
    this.commercialClientFormGroup = this.formBuilder.group({
      client: []
    })
  
  }

  addClient(saveType:string = 'back'){

    if(this.commercialClientFormGroup.get('client').invalid){
      this.toastr.error('There were some errors on your form.', 'Could not add commercial client.')
      return
    };

    this.commercialClientService.addNewClient(this.commercialClientFormGroup.get('client').value, this.currentCompany).subscribe(response => {
      this.afterSave(saveType)
    })

  }

  goBack(){
    this.location.back();
  }

  afterSave(saveType){
    switch(saveType){
      case 'back':
        this.location.back();
        break;

      case 'new':
        this.commercialClientFormGroup.get('client').reset();
        break;

      case 'continue':
        break;

      default:
        this.location.back();
        break;
    }
  }

}
