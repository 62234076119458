<div id="content" class="row">

  <!-- <div class="col-12">
    <h2 class="page-header">
      Add a new insured vehicle
    </h2>
  </div> Close div for .row -->

  <div class="col-12 mt-3">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h5>Cancel my account</h5>
        <hr />
      </div>

      <div class="panel-body">

        <div class="row">

          <div class="col-12">

            <p>
              If you cancel your account, it will be blocked and you will no longer be able to log in. All of your
              content will remain
              attributed to your user name.
              <br /> This action cannot be undone.
            </p>

            <p>
              Are you sure?
            </p>

            <div class="row">

              <div class="edit col-12 inline-buttons">

                <app-cancel-button buttonText="Cancel account" type="button" (click)="openModal()"></app-cancel-button>
                <app-save-button buttonText="Go back" routerLink="/myAccount" type="button"></app-save-button>

              </div>

            </div>

          </div> <!-- Close div for .col-12 col-sm-8 col-md-6 -->

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div>

</div> <!-- Close div for #content -->
