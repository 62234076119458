import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-cancel-account-modal',
  templateUrl: './cancel-account-modal.component.html',
  styleUrls: ['./cancel-account-modal.component.scss']
})
export class CancelAccountModalComponent implements OnInit {

  submitted = false;
  cancelAccountForm: FormGroup;
  get form() { return this.cancelAccountForm.controls; }

  constructor(
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<CancelAccountModalComponent>,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  cancelAccount(){

    this.submitted = true;
    // stop here if form is invalid
    if (this.cancelAccountForm.invalid) {
      return;
    }

    this.authService.cancelAccount(this.cancelAccountForm.value).subscribe(
      (response:HttpResponse<any>) => {
        this.router.navigate(['/logout']);
        this.close();
      }
    )
  };

  close(){
    this.dialogRef.close();
  };

  ngOnInit() {
    this.cancelAccountForm = this.formBuilder.group({
      security: ['', Validators.required]
    })
  };
  
}
