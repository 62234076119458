import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { InsuranceService } from 'src/app/services/insurance.service'
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service'
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Router } from '@angular/router';
import { HouseholdItem, HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { Broker, BrokerResponse } from 'src/app/_shared/models/broker.model';
import { InsuranceCompany, InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-new-household-insurance-item',
  templateUrl: './client-new-household-insurance-item.component.html',
  styleUrls: ['./client-new-household-insurance-item.component.scss']
})
export class ClientNewHouseholdInsuranceItemComponent implements OnInit, AfterViewInit {

  maxDate = new Date();

  brokers: Broker[];
  insuranceCompanies: InsuranceCompany[];

  // FILE UPLOADER SETTINGS
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  public Editor = ClassicEditor;

  submitted = false;
  newHouseholdItemFormGroup: FormGroup;
  get form() { return this.newHouseholdItemFormGroup.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private insuranceService: InsuranceService,
    private personalInsuranceService: PersonalInsuranceService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.options = { concurrency: 2, maxUploads: 6, allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  };

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
    
            this.files.push(file);
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  };

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
 
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }
 
  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  getAllBrokers(){
    this.insuranceService.getAllAssignedBrokers().subscribe(
      (response: HttpResponse<BrokerResponse>) => {
        this.brokers = response.body.broker;
      }
    )
  };

  getAllInsuranceCompanies(){
    this.insuranceService.getAllInsuranceCompanies().subscribe(
      (response: HttpResponse<InsuranceCompanyResponse>) => {
        this.insuranceCompanies = response.body.insuranceCompany;
      }
    )
  };

  addNewHouseholdItem(saveType?:string){

    this.submitted = true;

    if (this.newHouseholdItemFormGroup.invalid) {
      // window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add new household item.')
      return;
    }

    // Set photos === files
    this.files.map(file => {
      this.newHouseholdItemFormGroup.value.photos.push(file.nativeFile)
    });

   this.personalInsuranceService.addNewHouseholdItem(this.newHouseholdItemFormGroup.value).subscribe(
      (response:HttpResponse<HouseholdItemResponse>) => {

        switch(saveType){
          case 'back':
            this.goBack();
            break;

          case 'continue':
            break;

          case 'new':
            this.newHouseholdItemFormGroup.reset();
            break;

          default:
            this.goBack();
            break;
        }
        
      }
   )
  };

  goBack(){
    this.router.navigate(['/u/short-term-insurance/household']);
  }

  ngOnInit() {
    this.newHouseholdItemFormGroup = this.formBuilder.group({
      item: ['', Validators.required],
      photos: [[]],
      itemDescription: ['', Validators.required],
      itemCategory : ['', Validators.required],
      itemSerial : [''],
      itemQuantity : [''],
      itemRoom : [''],
      purchaseDate : [''],
      purchasePrice : [''],
      warranty : [''],
      specified : ['No', Validators.required],
      insuredValue : ['', Validators.required]
    });
  };

  ngAfterViewInit(){
    this.getAllBrokers();
    this.getAllInsuranceCompanies();
  }

}
