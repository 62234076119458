import { BrokerUnderwriterService } from 'src/app/services/broker-underwriter.service';
import { BrokerUnderwriterResponse } from './../../_shared/models/brokerUnderwriter.model';
import { BrokerUnderwriter } from 'src/app/_shared/models/brokerUnderwriter.model';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Globals } from 'src/app/app.globals';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
  selector: 'app-broker-underwriters',
  templateUrl: './broker-underwriters.component.html',
  styleUrls: ['./broker-underwriters.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerUnderwritersComponent implements OnInit {

  underwriters: BrokerUnderwriter[];
  searchUnderwriter: string;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public globals: Globals,
    private brokerUnderwriterService: BrokerUnderwriterService,
    private router: Router,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  getUnderwriters() {
    this.brokerUnderwriterService.getAllUnderwriters(this.brokerComponent.brokerCompany.companyName).subscribe(
      (response: HttpResponse<BrokerUnderwriterResponse>) => {
        this.underwriters = response.body.underwriter;
      }
    )
  }

  deleteUnderwriter(underwriterId:string) {
    this.brokerUnderwriterService.deleteUnderwriter(underwriterId).subscribe(
      (response: HttpResponse<BrokerUnderwriterResponse>) => {
        this.underwriters = this.underwriters.filter(underwriter => underwriter._id != underwriterId)
      }
    )
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUnderwriters();
  }

}

