import { ActivatedRoute } from '@angular/router';
import { BrokerClaim } from 'src/app/_shared/models/brokerClaims.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { BrokerComponent } from 'src/app/broker/broker.component';
import { BrokerClaimsService } from 'src/app/services/broker-claims.service';

@Component({
  selector: 'broker-view-client-claims',
  templateUrl: './broker-view-user-claims.component.html',
  styleUrls: ['./broker-view-user-claims.component.scss']
})
export class BrokerViewUserClaimsComponent implements OnInit {

  claims: BrokerClaim[];

  clientId:string = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  
  displayedColumns: string[] = ['clientName', 'clientNumber', 'referenceNumber', 'claimType', 'status', 'dateApproved', 'view'];
  resultsLength: number = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  claimSearch:string = '';
  claimSearchStatus: 'all'|'completed'|'incomplete' = 'all';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private brokerComponent: BrokerComponent,
    private brokerClaimsService: BrokerClaimsService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.findAllClaims();
  }

  findAllClaims(searchParam:string = '', noLoad:boolean = false, completed = this.claimSearchStatus){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam || '',
      completed: completed
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.brokerClaimsService.getAllClaimsPerUser(this.brokerComponent.brokerCompany.companyName, this.clientId, req)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.claim;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {this.claims = data;console.log(data)});
  }

}
