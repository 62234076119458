<div id="content" class="row">

    <sub-navigation heading="{{ editContent ? 'Edit' : 'Insure new' }} home content"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="homeContentFormGroup" [formGroup]="homeContentFormGroup">

              <mat-horizontal-stepper linear #stepper>

                <mat-step>
                  <ng-template matStepLabel>Address</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="mat-stepper-body row" formGroupName="address">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Add home address</strong></h5>
                        </div>

                        <div class="col-12" *ngIf="!editContent && !isQuoteProduct">
                          <mat-checkbox (change)="selectedPreviousBuilding($event, selectBuildingModal)" [(ngModel)]="insuredPreviousBuildingContent" [ngModelOptions]="{ standalone: true }">Add content from previously insured building?</mat-checkbox>
                        </div>

                        <ng-container *ngIf="!insuredPreviousBuildingContent">

                          <div class="col-12 clearfix mb-3"></div>

                          <div class="col-12">
                            <label for="addressLine1">Address</label>
                          </div>

                          <div class="col-12">
                            <input
                              ngx-google-places-autocomplete
                              #placesRef="ngx-places"
                              (onAddressChange)="handleAddressChange($event)"
                              formControlName="addressLine1"
                              [options]="addressOptions"
                              class="form-control"
                            />
                          </div>

                          <div class="col-12 clearfix mb-3"></div>

                          <div class="col-12 col-md-6">

                            <div class="row">

                              <div class="col-12">
                                <label for="suburb">Suburb</label>
                              </div>
          
                              <div class="col-12">
                                <input 
                                  class="form-control"
                                  name="suburb"
                                  formControlName="suburb"
                                  placeholder="Suburb"
                                >
                              </div>

                            </div>

                          </div>

                          <div class="col-12 clearfix mb-3 d-block d-md-none"></div>

                          <div class="col-12 col-md-6">

                            <div class="row">

                              <div class="col-12">
                                <label for="city">City</label>
                              </div>
          
                              <div class="col-12">
                                <input 
                                  class="form-control"
                                  name="city"
                                  formControlName="city"
                                  placeholder="City"
                                >
                              </div>

                            </div>

                          </div>

                          <div class="col-12 clearfix mb-3"></div>

                          <div class="col-12 col-md-6">

                            <div class="row">

                              <div class="col-12">
                                <label for="postalCode">Postal Code</label>
                              </div>
          
                              <div class="col-12">
                                <input 
                                  class="form-control"
                                  name="postalCode"
                                  formControlName="postalCode"
                                  placeholder="Postal Code"
                                >
                              </div>

                            </div>

                          </div>

                        </ng-container>

                      </div> <!-- Close div for .row-->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-bg btn-outline-secondary" (click)="location.back()">Cancel</button>
                        <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                      </div>

                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>

                  <ng-template matStepLabel>Details</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Describe the property </strong></h5>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="yearsLivingAtProperty">How many years have you lived on the property</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <input 
                              class="form-control"
                              name="yearsLivingAtProperty"
                              formControlName="yearsLivingAtProperty"
                              placeholder="Total years"
                            >
                          </div>
                        </div>

                        <ng-container *ngIf="!insuredPreviousBuildingContent">

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Type of building</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="residenceType">
                                  <mat-option value="house">House</mat-option>
                                  <mat-option value="townhouse">Townhouse</mat-option>
                                  <mat-option value="cottageAttached">Cottage-attached</mat-option>
                                  <mat-option value="cottageNotAttached">Cottage-not attached</mat-option>
                                  <mat-option value="cluster">Cluster</mat-option>
                                  <mat-option value="flatAboveGround">Flat above ground</mat-option>
                                  <mat-option value="flatOnGround">Flat on ground</mat-option>
                                  <mat-option value="outbuilding">Outbuilding</mat-option>
                                  <mat-option value="storage">Storage facility</mat-option>
                                  <mat-option value="garage">Park home / Caravan</mat-option>
                                  <mat-option value="other">Other</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is this a primary home?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="isPrimaryResidence">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is this a holiday home?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="isHolidayHome">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is there a thatched lapa on the property?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group" formGroupName="thatch">
                              <mat-form-field>
                                <mat-select formControlName="hasThatch">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <ng-container formGroupName="thatch" *ngIf="homeContentFormGroup.get('thatch').get('hasThatch').value">

                            <div class="col-12 clearfix"></div>

                            <div class="col-12 col-sm-7 col-md-8 my-auto">
                              <label for="insuredValue">Is the thatch closer than 5 meters to the main house?</label>
                            </div>
        
                            <div class="col-12 col-sm-5 col-md-4" >
                              <div class="form-group">
                                <mat-form-field>
                                  <mat-select formControlName="thatchWithin5Meters">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="col-12 clearfix"></div>

                            <div class="col-12 col-sm-7 col-md-8 my-auto">
                              <label for="insuredValue">Is the thatch treated?</label>
                            </div>
        
                            <div class="col-12 col-sm-5 col-md-4" >
                              <div class="form-group">
                                <mat-form-field>
                                  <mat-select formControlName="treated">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div> <div class="col-12 clearfix"></div>

                            <div class="col-12 col-sm-7 col-md-8 my-auto">
                              <label for="insuredValue">Is there a SABS approved lightning conductor installed?</label>
                            </div>
        
                            <div class="col-12 col-sm-5 col-md-4" >
                              <div class="form-group">
                                <mat-form-field>
                                  <mat-select formControlName="lightningConductorInstalled">
                                    <mat-option [value]="true">Yes</mat-option>
                                    <mat-option [value]="false">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>

                          </ng-container>
                          
                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Does the property have a swimming pool?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="swimmingPool">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the premises ever unoccupied for more than 60 consecutive days?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="unoccupiedConsecutively">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the property a commune?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="isCommune">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                        </ng-container>

                      </div> <!-- Close div for row -->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-outline-secondary btn-bg" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                      </div>
                    
                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step *ngIf="!insuredPreviousBuildingContent">
                  <ng-template matStepLabel>Building</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body">

                        <div class="col-12">
                          <h5 class="mb-4"><strong>Describe the home construction</strong></h5>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Is the building currently under construction?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select formControlName="underConstruction">
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What are the walls of the building made of?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="externalWalls">
                                <mat-option *ngFor="let wall of wallMaterial" [value]="wall">
                                  {{ wall | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What type of roof is on the building?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="roof">
                                <mat-option *ngFor="let roof of roofMaterial" [value]="roof">
                                  {{ roof | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">What type of flooring is in the building?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="floor">
                                <mat-option *ngFor="let floor of floorMaterial" [value]="floor">
                                  {{ floor | sentenceCase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-7 col-md-8 my-auto">
                          <label for="insuredValue">Surrounding area type?</label>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4" >
                          <div class="form-group">
                            <mat-form-field>
                              <mat-select multiple formControlName="areaType">
                                <mat-option value="farm">Farm</mat-option>
                                <mat-option value="plot">Plot</mat-option>
                                <mat-option value="golfEstate">Golf estate</mat-option>
                                <mat-option value="countryEstate">Country estate / Residential estate</mat-option>
                                <mat-option value="securityComplex">Security complex</mat-option>
                                <mat-option value="complex">Complex with no access control</mat-option>
                                <mat-option value="boomedOffSuburb">Boomed-off suburb</mat-option>
                                <mat-option value="suburb">Suburb</mat-option>
                                <mat-option value="industrialArea">Industrial area</mat-option>
                                <mat-option value="commercialArea">Commercial area</mat-option>
                                <mat-option value="cbd">Inner city / CBD</mat-option>
                                <mat-option value="informalSettlement">Informal settlement</mat-option>
                                <mat-option value="retirementVillage">Retirement village</mat-option>
                                <mat-option value="natureReserve">Nature reserve</mat-option>
                                <mat-option value="holidayResort">Holiday resort</mat-option>
                                <mat-option value="school">School / University</mat-option>
                                <mat-option value="other">Other</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                      </div> <!-- Close div for .row -->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                        <button class="btn btn-primary btn-bg mr-0 float-right" matStepperNext>Next Step</button>
                      </div>

                    </div>

                  </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Security</ng-template>

                    <div class="row">

                      <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
  
                        <div class="row mat-stepper-body">
  
                          <div class="col-12">
                            <h5 class="mb-4"><strong>Describe the home security</strong></h5>
                          </div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Do you conduct business or keep any stock on the premises?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="isBusinessProperty">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
  
                          <div class="col-12 clearfix"></div>

                          <ng-container formGroupName="security">
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">What type of alarm system does the property have?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select multiple formControlName="alarmType">
                                  <mat-option *ngFor="let alarm of securityAlarmType" [value]="alarm">
                                    {{ alarm | sentenceCase }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
  
                          <div class="col-12 clearfix"></div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Are there burglar bars installed on the windows?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="burglarBars">
                                  <mat-option *ngFor="let burglarBars of securityBurglarBars" [value]="burglarBars">
                                    {{ burglarBars | sentenceCase }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
  
                          <div class="col-12 clearfix"></div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Does the property have security or surveilance cameras?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="securityCameras">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
  
                          <div class="col-12 clearfix"></div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Does the property have any external leading sliding or stacking doors?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="externalSlidingDoor">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">What type of perimeter does the property have?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select multiple formControlName="outsidePermimeter">
                                  <mat-option  *ngFor="let perimeter of securityOutsidePerimeter" [value]="perimeter">
                                    {{ perimeter | sentenceCase }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>
  
                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Are there security gates on all external doors, including interlinking doors between the main house and garage?</label>
                          </div>
  
                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="securityGates">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="insuredValue">Is the premises in a security complex?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select formControlName="securityComplex">
                                  <mat-option [value]="true">Yes</mat-option>
                                  <mat-option [value]="false">No</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                        </ng-container>

                        <div class="col-12 clearfix"></div>

                          <div class="col-12 col-sm-7 col-md-8 my-auto">
                            <label for="occupantsDuringDay">Who occupies the property during the day?</label>
                          </div>

                          <div class="col-12 col-sm-5 col-md-4" >
                            <div class="form-group">
                              <mat-form-field>
                                <mat-select multiple formControlName="occupantsDuringDay">
                                  <mat-option *ngFor="let occupants of dailyOccupants" [value]="occupants">{{ occupants | sentenceCase }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                        </div> <!-- Close div for .row -->

                        <div class="mat-stepper-footer">
                          <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                          <button class="btn btn-primary btn-bg mr-0 float-right" matStepperNext>Next Step</button>
                          <!-- <button *ngIf="isQuoteProduct" class="btn btn-success btn-bg float-right mr-0" (click)="editContent ? updateContent() : addNewContent()">Finish</button> -->
                        </div>
  
                      </div>
  
                    </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>Insurance</ng-template>

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                      <div class="row mat-stepper-body" formGroupName="insurance">

                        <div class="col-12">
                          <h5 class="mb-5"><strong>Add insurance to home</strong></h5>
                        </div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="contentValue">Home content value</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="contentValue"
                                formControlName="contentValue"
                                placeholder="Home content value"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>home content value</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="jeweleryValue">Home jewelery value</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="jeweleryValue"
                                formControlName="jeweleryValue"
                                placeholder="Home jewelery value"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>jewelery value</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <ng-container *ngIf="!isQuoteProduct">

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="premium">Premium</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="premium"
                                formControlName="premium"
                                placeholder="Premium"
                            >
                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>premium</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="policy">Policy number</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <div class="input-group">
                            <mat-form-field>
                              <mat-select formControlName="policyNumber">
                                <mat-option disabled *ngIf="policies?.length === 0 && homeContentFormGroup.get('insurance').get('policyNumber').value">
                                  <span>No policy found</span>
                              </mat-option>
                              <mat-option class="px-0 py-0" style="height: unset" *ngIf="policies?.length === 0 && homeContentFormGroup.get('insurance').get('policyNumber').value">
                                  <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                              </mat-option>
                                <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                  <span>{{ policy?.policyNumber }}</span>
                              </mat-option>
                              </mat-select>

                              <mat-error>
                                Please select a <strong>policy number</strong> for the home content.
                              </mat-error>

                            </mat-form-field>
                          </div> <!-- Close div for .input-group -->
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="inceptionDate">Home content inception date</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="inceptionDate"
                                [matDatepicker]="inceptionDate"
                                name="inceptionDate"
                                formControlName="inceptionDate"
                            >
                            <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #inceptionDate></mat-datepicker>
      
                            <mat-error>
                                Please provide the <strong>home content inception date</strong>.
                            </mat-error>
      
                          </mat-form-field>

                        </div>

                        </ng-container>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="excess">Excess</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="excess"
                                formControlName="excess"
                                placeholder="Excess"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>excess amount</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="accidentalDamage">Accidental damage</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="accidentalDamage"
                                formControlName="accidentalDamage"
                                placeholder="Accidental damage"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>accidental damage</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                        <div class="col-12 clearfix"></div>

                        <div class="col-12 col-sm-5 col-md-4 my-auto">
                          <label for="powerSurge">Power surge</label>
                        </div>

                        <div class="col-12 col-sm-7 col-md-8" >
                          <mat-form-field>
                            <input 
                                matInput
                                name="powerSurge"
                                formControlName="powerSurge"
                                placeholder="Power Surge"
                            >

                            <span matPrefix>R</span>

                            <mat-error>
                                Please provide the <strong>power surge</strong>.
                            </mat-error>

                          </mat-form-field>
                        </div>

                      </div> <!-- Close div for .row-->

                      <div class="mat-stepper-footer">
                        <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                        <button *ngIf="!view && canViewItem" class="btn btn-success btn-bg float-right mr-0" (click)="editContent ? updateContent() : addNewContent()">Finish</button>
                      </div>

                    </div>
                  
                  </div> <!-- Close div for .row -->

                </mat-step>
                
              </mat-horizontal-stepper>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->

<ng-template #selectBuildingModal>
  <h2 mat-dialog-title>Select insured building</h2>
<mat-dialog-content class="mat-typography">

  <div class="row" *ngIf="insuredBuildings?.length === 0">
    <div class="col-12">
  <p>Client has no insured buildings.</p>
  </div>
  </div>

<div class="row" *ngIf="insuredBuildings?.length > 0">
  <div class="col-12">
    <div class="form-group">
      <mat-form-field>
        <mat-select [(ngModel)]="insuredBuildingSelect">
          <mat-option *ngFor="let building of insuredBuildings" [value]="building">{{ building?.residenceType | sentenceCase }} - {{ building?.address?.formattedAddress}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-secondary" (click)="dialogRef.close('cancel')">Cancel</button>
  <button class="btn btn-primary" (click)="selectedInsuredBuildingForContent(insuredBuildingSelect)" cdkFocusInitial>Select</button>
</mat-dialog-actions>
</ng-template>
