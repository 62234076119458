import { FileUploader } from 'ng2-file-upload';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentModel } from 'src/app/_shared/models/document.model';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-company-documents',
  templateUrl: './upload-company-documents.component.html',
  styleUrls: ['./upload-company-documents.component.scss']
})
export class UploadCompanyDocumentsComponent implements OnInit {

  public uploadDocumentFormGroup: FormGroup;
  private formSubmitted: boolean = false;

  brokerCompany = this.authenticationService.currentCompany.companyName;

  uploader: FileUploader;

  constructor(
    public dialogRef: MatDialogRef<UploadCompanyDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this.uploader = new FileUploader({})
  }

  ngOnInit(): void {
    this.uploadDocumentFormGroup = this.formBuilder.group({
      file: [null, Validators.required],
      documentName: [''],
      // documentType: [null, Validators.required],
      documentDescription: [null]
    })
  }

  closeDialog(reason){
    this.dialogRef.close(reason)
  }

  handleUploadedFile(file:any){
    const documentFile = file[0];

    this.uploadDocumentFormGroup.get('file').setValue(documentFile)
    this.uploadDocumentFormGroup.get('documentName').setValue(documentFile.name)
  }

  uploadFile(){

    this.formSubmitted = true;
    this.uploadDocumentFormGroup.markAllAsTouched();

    if(this.uploadDocumentFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Could not save file.')
      return;
    }

    let formData = new FormData();
    let controls = this.uploadDocumentFormGroup.controls;

    // MAKE FORMDATA
    for (let key in controls){
      formData.append(key, controls[key].value)
    };
    
    this.documentService.uploadCompanyDocument(formData, this.brokerCompany).subscribe(
      (response: HttpResponse<DocumentModel>) => {
        this.closeDialog('success');
      }
    )

  }

}
