import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private http: HttpClient,
    public globals: Globals,
  ) { }

  getVehicleYears(){

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get(`${this.globals.SERVER_URL}/api/vehicle/carQuery/years`, {
      observe: 'response',
      headers: headers
    });
  };

  getVehicleMakes(year:string){
    
    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get(`${this.globals.SERVER_URL}/api/vehicle/carQuery/makes/${year}`, {
      observe: 'response',
      headers: headers
    });
  };

  getVehicleModels(req: { year: string, make:string }){
    
    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get(`${this.globals.SERVER_URL}/api/vehicle/carQuery/models/${req.year}/${req.make}`, {
      observe: 'response',
      headers: headers
    });
  };

  getVehicleModelDetails(id:string){
    
    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get(`${this.globals.SERVER_URL}/api/vehicle/carQuery/modelDetails/${id}`, {
      observe: 'response',
      headers: headers
    });
  };

  getVehicleTrims(req: { year: string, make:string, model:string }){
    
    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get(`${this.globals.SERVER_URL}/api/vehicle/carQuery/trims/${req.year}/${req.make}/${req.model}`, {
      observe: 'response',
      headers: headers
    });
  };
}
