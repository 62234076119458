import { CommercialClientResponse } from './../../../../_shared/models/commercialClient.model';
import { CommercialProductResponse } from './../../../../_shared/models/commercialProduct.model';
import { CommercialProductsService } from './../../../../services/commercialProduct.service';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BrokerService } from 'src/app/services/broker.service';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { Policy, PolicyResponse } from 'src/app/_shared/models/policy.model';
import { UserResponse } from 'src/app/_shared/models/User.model';
import { CommercialProduct } from 'src/app/_shared/models/commercialProduct.model';
import { CommercialClientsService } from 'src/app/services/commercial-clients.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';


@Component({
  selector: 'app-broker-commercial-clients-products-new',
  templateUrl: './broker-commercial-clients-products-new.component.html',
  styleUrls: ['./broker-commercial-clients-products-new.component.scss']
})
export class BrokerCommercialClientsProductsNewComponent implements OnInit, AfterViewInit {

  @Input() view: boolean = false;

  redirectTo = this.activeRoute.snapshot.paramMap.get('r');

  routerPath: string = this.activeRoute.routeConfig.path;
  editProduct = this.routerPath.includes('edit');

  maxDate = new Date();

  clientId = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  clientInformation: any;

  productIdNumber = this.activeRoute.snapshot.paramMap.get('productId');
  product: CommercialProduct[];

  brokers: Employee[];
  // insuranceCompanies: InsuranceCompany[];
  policies: Policy[] = [];

  formSubmitted = false;
  productFromGroup: FormGroup;
  get productForm() { return this.productFromGroup.controls; }

  formData: FormData;
  isLoading: boolean = false;

  isQuoteProduct: boolean;

  addressOptions = {
    componentRestrictions: { country: 'ZA' }
  }


  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (!control.value && this.isQuoteProduct) {
      return { 'required': true };
    }
    return null;
  }

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private brokerService: BrokerService,
    private commercialClientService: CommercialClientsService,
    private commercialProductService: CommercialProductsService,
    private authService: AuthenticationService,
    private location: Location
  ) {
    this.isQuoteProduct = this.activeRoute.snapshot.queryParams['quote'] === 'true';
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getCurrentProduct(productId:string){

    this.commercialProductService.getSingleCommercialProductForClient(this.clientId, productId).subscribe(
      (response:HttpResponse<CommercialProductResponse>) => {
        this.product = response.body.products;
        this.productFromGroup.patchValue(this.product);
        this.productFromGroup.controls.insurance.patchValue(this.product['insurance']);
      }
    )
  };

  getClientInformation(client_id:string){
    this.commercialClientService.getSingleClient(client_id).subscribe(
      (response: HttpResponse<CommercialClientResponse>) => {
        this.clientInformation = response.body.user;
      }
    )
  }

  updateProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save product.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(!this.productFromGroup.get('riskAddress').get('formattedAddress').value){
      let productFormGroupAddress = this.productFromGroup.get('riskAddress');
      let addressLine1 = productFormGroupAddress.get('addressLine1').value;
      let suburb = productFormGroupAddress.get('suburb').value;
      let city = productFormGroupAddress.get('city').value;
      let postalCode = productFormGroupAddress.get('postalCode').value;
      productFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    if(this.isQuoteProduct){
      this.editQuoteProduct();
      return
    }

    this.commercialProductService.updateCommercialProduct(this.clientId, this.productIdNumber, this.productFromGroup.value).subscribe(
      (response:HttpResponse<CommercialProductResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewProduct(saveType?:string){

    // activate mat-error
    this.productFromGroup.markAllAsTouched();

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.productFromGroup.invalid) {
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', `Could not save ${this.productFromGroup.get('type').value}.`)
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }

    if(!this.productFromGroup.get('riskAddress').get('formattedAddress').value){
      let productFormGroupAddress = this.productFromGroup.get('riskAddress');
      let addressLine1 = productFormGroupAddress.get('addressLine1').value;
      let suburb = productFormGroupAddress.get('suburb').value;
      let city = productFormGroupAddress.get('city').value;
      let postalCode = productFormGroupAddress.get('postalCode').value;
      productFormGroupAddress.get('formattedAddress').setValue(`${addressLine1}, ${suburb}, ${city}, ${postalCode}, South Africa`);
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
      return;
    }

    this.commercialProductService.addNewClientCommercialProduct(this.clientId, this.productFromGroup.value).subscribe(
      (response:HttpResponse<CommercialProductResponse>) => {
        this.saveType(saveType)
      }
    )
  };

  addNewQuotedProduct(){
    this.commercialProductService.addNewClientCommercialProductQuote(this.clientId, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<CommercialProductResponse>) => {
        let newProduct = response.body.products;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newProduct))
        this.location.back()
      }
    )
  }

  editQuoteProduct(){
    this.commercialProductService.editClientCommercialProductQuote(this.clientId, this.productIdNumber, this.authService.currentCompany.companyName, this.productFromGroup.value).subscribe(
      (response: HttpResponse<CommercialProductResponse>) => {
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(response.body.products))
        this.location.back()
      }
    )
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
      this.goBack();
        break;

      case 'continue':
        break;

      default:
        this.goBack();
        break;
    }
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  goBack(){
    if(this.redirectTo){
      this.router.navigate([this.redirectTo])
    } else {
      // this.router.navigate(['/b/clients/personal/edit', this.clientId]);
      this.location.back();
    };
  }

  addNewPolicy(){
    localStorage.setItem('SureSpace-CommercialProducts', JSON.stringify(this.productFromGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-CommercialProducts')){
      this.productFromGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-CommercialProducts')));
      localStorage.removeItem('SureSpace-CommercialProducts');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  ngOnInit() {
    this.productFromGroup = this.formBuilder.group({
      _id: [],
      productType: ['', Validators.required],
      productValue: ['', Validators.required],
      description: [''],
      insurance: this.formBuilder.group({
        companyName: [''],
        premium: [''],
        policyNumber: [''],
        inceptionDate: [''],
      }),
      riskAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        unit: [''],
        city: [''],
        suburb: [''],
        province: [''],
        country: ['South Africa'],
        postalCode: [''],
        municipalAccNr: [''],
        stand: [''],
        formattedAddress: ['']
      }),
      isQuoteProduct: false,
      commercialProductType: "commercialProduct"
    });

    this.productFromGroup.get('insurance').get('companyName').setValue(this.authService.currentCompany.companyName);

    if(this.isQuoteProduct){
      this.productFromGroup.get('isQuoteProduct').setValue(true);
    }

  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, this.clientId, { active: true }).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  ngAfterViewInit(){
    this.getClientInformation(this.clientId);

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicyNumber();
    }

    if(this.editProduct){
      this.getCurrentProduct(this.productIdNumber);
    }

    this.getLocalStorageItem();
  }

  handleAddressChange(address: Address){

    function findInAddress (property:string) {
      return address.address_components.filter(component => component.types.includes(property))[0].long_name
    }

    let productFormGroupAddress = this.productFromGroup.get('riskAddress');

    productFormGroupAddress.get('addressLine1').setValue(`${findInAddress('street_number')} ${findInAddress('route')}`);
    productFormGroupAddress.get('suburb').setValue(findInAddress('sublocality_level_1'));
    productFormGroupAddress.get('city').setValue(findInAddress('locality'));
    productFormGroupAddress.get('province').setValue(findInAddress('administrative_area_level_1'));
    productFormGroupAddress.get('country').setValue(findInAddress('country'));
    productFormGroupAddress.get('postalCode').setValue(findInAddress('postal_code'));
    productFormGroupAddress.get('formattedAddress').setValue(address.formatted_address);
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}
