import { BrokerComponent } from './../../broker.component';
import { RejectInstructionModalComponent } from 'src/app/components/modal/reject-instruction-modal/reject-instruction-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserRequest } from 'src/app/_shared/models/userRequest.interface';
import { RequestService } from 'src/app/services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerAdminInstructionResponse } from './../../../_shared/models/brokerAdminInstruction.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from './../../../services/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BrokerAdminInstructionService } from './../../../services/broker-admin-instruction.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AdminInstructionHandoverModalComponent } from './handover-instruction/admin-instruction-handover-modal.component';
@Component({
  selector: 'app-broker-admin-instruction-edit',
  templateUrl: './broker-admin-instruction-edit.component.html',
  styleUrls: ['./broker-admin-instruction-edit.component.scss'],
  providers: [BrokerComponent]
})
export class BrokerAdminInstructionEditComponent implements OnInit {

  hasRequestAttached: boolean = false;
  request: any; //<!-- SCREW YOU TOO (cannot set as UserRequest[])

  currentCompany: string;
  instructionFormGroup: FormGroup;

  selectedInstruction = this.activeRoute.snapshot.paramMap.get('instructionId');

  dialogRef = null;

  currentInstructionStep: string;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  get instructionRejectedReason(){
    return this.instructionFormGroup.get('instruction').value.instructionRejected
  }

  constructor(
    private brokerAdminInstructionService: BrokerAdminInstructionService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private requestService: RequestService,
    private dialog: MatDialog,
    private rejectInstructionModal: RejectInstructionModalComponent,
    private brokerComponent: BrokerComponent,
    private router: Router
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions

    this.instructionFormGroup = this.formBuilder.group({
      instruction: []
    })

    this.activeRoute.queryParamMap.subscribe(param => {
      Object.keys(param['params']).forEach((key, index) => {

        if(key === 'request'){
          this.hasRequestAttached = true;

          this.requestService.getSingleRequest(param['params']['request']).subscribe(response => {
            this.request = response.body.requests;

            if(this.request?.whatNeedsToHappen === 'assign'){
              this.openHandoverModal(this.request._id);
            }
            
          })
        }
      })
    })

  }

  setCurrentStep(event){
    this.currentInstructionStep = event;
  }

  openHandoverModal(requestId:string){

   this.dialogRef = this.dialog.open(AdminInstructionHandoverModalComponent, { 
     width: '800px',
     data: {
       requestId,
       employee: `${this.request.createdBy.firstName} ${this.request.createdBy.lastName}`
     }
    })
  }

  ngOnInit(): void {
    this.getCurrentInstruction(this.selectedInstruction);
  }

  saveInstruction(saveType?:string){

    // stop here if form is invalid
    if (this.instructionFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not save instruction.')
      return;
    }

    this.brokerAdminInstructionService.editInstruction(this.currentCompany, this.selectedInstruction, this.instructionFormGroup.get('instruction').value).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.saveType(saveType)
      }
    )

  }

  getCurrentInstruction(instructionId:string){
    this.brokerAdminInstructionService.getSelectedInstruction(this.currentCompany, instructionId).subscribe(
      (response:HttpResponse<BrokerAdminInstructionResponse>) => {
        this.instructionFormGroup.get('instruction').patchValue(response.body.instruction)      
        console.log('instruction => ', response.body)  
      }
    )
  }

  goBack(){
    this.location.back()
  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.instructionFormGroup.get('instruction').reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  acceptRequest(requestId:string){
    this.requestService.handleRequest(requestId, 'accept').subscribe(
      (response: HttpResponse<any>) => {
        this.hasRequestAttached = false;
        this.brokerComponent.getUserRequests();
        this.location.back();
      }
    )
  }

  rejectRequest(request){
    this.rejectInstructionModal.openDialog({request}, 'instruction');
    this.rejectInstructionModal.dialogRef.afterClosed().subscribe(response => {
      if(response == 'success'){
        this.brokerComponent.getUserRequests();
        this.hasRequestAttached = false;
      }
    })
  }

  canSignoff(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  goToClientFile(){
    let client = this.instructionFormGroup.get('instruction').value.client;

    if(client.companyName){
      this.router.navigate(['/b/clients/commercial/edit', client._id], { queryParams: { file: '7' } })
    } else {
      this.router.navigate(['/b/clients/personal/edit', client._id], { queryParams: { file: '7' } })
    }
  }

}
