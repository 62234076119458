import { CommercialProductsService } from './../../../services/commercialProduct.service';
import { AllRiskItem, AllRiskItemResponse } from 'src/app/_shared/models/allRiskItem.model';
import { ValueAddedProduct, ValueAddedProductResponse } from 'src/app/_shared/models/vaps.model';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { BrokerClientHomeContentService } from 'src/app/services/broker-client-home-content.service';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { HttpResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BrokerService } from 'src/app/services/broker.service';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, pairwise } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators, FormControl, NG_VALIDATORS } from '@angular/forms';
import { Component, forwardRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Claim } from 'src/app/_shared/models/brokerClaims.model';
import { Vehicle } from 'src/app/_shared/models/vehicle.model';
import { BrokerClientHomeService } from 'src/app/services/broker-client-home.service';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';
import { HomeContent, HomeContentResponse } from 'src/app/_shared/models/homeContentInsurance.model';
import { HomeResponse, Home } from 'src/app/_shared/models/homeInsurance.model';
import { TrailersAndCaravans, TrailersAndCaravansResponse } from 'src/app/_shared/models/trailersAndCaravans.model';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { formatDate } from '@angular/common';

@Component({
  selector: 'broker-claim-form-group',
  templateUrl: './broker-claim-form-group.component.html',
  styleUrls: ['./broker-claim-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrokerClaimFormGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BrokerClaimFormGroupComponent),
      multi: true
    }
  ]
})
export class BrokerClaimFormGroupComponent implements ControlValueAccessor, OnDestroy {

  @Output() currentClaimStep = new EventEmitter()

  currentCompany: string = this.authenticationService.currentCompany.companyName;
  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  inEditMode: boolean = false;

  claimsFormGroup: FormGroup;
  subscriptions: Subscription[] = [];

  loadingResults:boolean = true;
  formSubmitted: boolean = false;

  clients: User[];
  employees: Employee[];
  products: Vehicle[] | ValueAddedProduct[] | AllRiskItem[] | HomeContent[] | Home[] | TrailersAndCaravans[];

  maxDate: any;

  get claimFormValue(): Claim {
    return this.claimsFormGroup.value;
  }

  set claimFormValue(value: Claim) {
    this.claimsFormGroup.patchValue(value);

    if(value['client']._id){
      this.inEditMode = true;
      this.claimsFormGroup.get('productType').enable();
      this.claimsFormGroup.get('productDisplay').enable();
    }

    this.currentClaimStep.emit(value['currentStep']);

    this.onChange(value);
    this.onTouched();
  }

  get claimControl() {
    return this.claimsFormGroup.controls.client;
  }

  constructor(
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private homeService: BrokerClientHomeService,
    private homeContentService: BrokerClientHomeContentService,
    private allRiskService: BrokerClientAllRiskServiceService,
    private vapsService: ValueAddedProductsService,
    private trailersAndCaravansService: TrailersAndCaravansService,
    private authenticationService: AuthenticationService,
    private commercialProductService: CommercialProductsService
  ) {

    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.currentCompany).permissions

    this.claimsFormGroup = this.formBuilder.group({
      _id: '',
      client: [null, Validators.required],
      productType: [{
        value: '',
        disabled: true
      }, Validators.required],
      affectedArea: [],
      claimCompleted: this.formBuilder.group({
        value: false,
        dateCompleted: null,
        completedBy: this.formBuilder.group({
          brokerId: null,
          firstName: null,
          lastName: null
        })
      }),
      productTypeDescription: [''],
      productDisplay: [{
        value: null,
        disabled: true
      }, Validators.required],
      product: [null, Validators.required],
      employee: [null, Validators.required],
      claimType: [null, Validators.required],
      dateOfLoss: [null, Validators.required],
      dateReported: [new Date(), Validators.required],
      claimNumber: [''],
      underwritersClaimNumber: [''],
      excess: [''],
      totalCost: [''],
      claimDescription: [''],
      claimRejected: [],
      claimSteps: this.formBuilder.group({
        claimStatus: this.formBuilder.group({ value: 'pending', completedBy: {}, dateCompleted: null }),
        claimReceivedVia: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
        claimFormsSentToClient: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
        clientReceivedClaimForms: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        advisedInsurersOfPossibleClaim: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        claimFormsReceivedFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        claimFormsCompleted: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        additionalDocumentationReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        documentsUploadedOnSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        recovery: this.formBuilder.group({
          recoveryRequired: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          insuranceRequiresSalvage: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          salvageRecoveryDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null }),
          salvageRecovered: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          settlementMethod: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null })
        }),
        roadsideAssist: this.formBuilder.group({
          roadsideAssistRequired: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          roadsideAssistCompany: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          roadsideAssistTowingRefNo: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          vehicleMovedTo: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          roadsideAssistDeclineReason: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          vehicleCurrentLocation: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          clientUnderstandsStorageCost: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        carHire: this.formBuilder.group({
          requireCarHire: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          carHireDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null }),
          carDeliveryLocation: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          deliveryArrangementsMadeWithInsurance: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          deliveryArrangementsMadeWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          clientConfirmDelivery: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          deliveryDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null }),
          // vehicleReturned: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          carHireReturnDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null })
        }),
        documentsSentToInsurer: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        insurerReceivedDocuments: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        claimNoReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        claimNoSentToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        assessorAppointed: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        confirmAssesorWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        assesorMadeContactWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        assesorBusyWithReport: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        assesorReportReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        assesorReportSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        repairAuthReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        authAndExcessInOrder: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        repairAuthSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        confirmRepairsWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        vehicleRepairDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null }),
        clientReceivedVehicle: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
        repairs: this.formBuilder.group({
          clientHappyWithRepairs: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmRepairWithInsurer: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          repairNotes: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          requestProofOfPayment: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          savedProofOfPayment: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        investigator: this.formBuilder.group({
          investigatorAppointed: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmInvestigatorToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          investigatorMadeContactWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          investigatorBusyWithReport: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          investigatorReportReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        finance: this.formBuilder.group({
          vehicleFinanced: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          requestSettlementDocumentsFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          settlementDocumentsReceivedFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          settlementDocumentsSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          settlementDocumentsSentToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          insuranceCompanyReceivedSettlementDocuments: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          requestChangeOfOwnershipFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          changeOfOwnershipReceivedFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          changeOfOwnershipSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          changeOfOwnershipSentToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          insuranceCompanyReceivedChangeOfOwnership: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        agreementOfLoss: this.formBuilder.group({
          agreementOfLossReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          agreementOfLossChecked: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          agreementOfLossSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          agreementOfLossSendToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          clientAcceptedAgreement: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          clientSignedAgreement: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          signedAgreementSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          signedAgreementSentToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmReceiptOfSignedAgreementFromInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          requestPaymentDateFromClient: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          requestPoPFromClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          savedPoPToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmWithClientReceiptPoP: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          agreementChangesToBeMade: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          sentChangesToInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmReceiptOfChangesByInsuranceCompany: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          receivedNewAgreement: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          agreementInOrder: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          newAgreementSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          sentAgreementToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        replacement: this.formBuilder.group({
          requestReplacementList: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmAllItemsOnList: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          sendListToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          saveListToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          clientAcceptsList: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          acceptListSaved: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          insuranceToProceedWithReplacement: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          replacementDate: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          confirmReplacementDateWithClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          itemsReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          requestItemSerials: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          rejectListReason: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
          rejectionSentToInsurance: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          receivedAmendedList: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          amendedListSentToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          savedAmendedListToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
        }),
        repudiation: this.formBuilder.group({
          clientAcceptsRepudiation: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          confirmationSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          rejectionSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          clientRequestNegotiation: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          sendNegotiationToInsurance: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          receiveNegotiationOutcome: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          sentOutcomeToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          outcome: this.formBuilder.group({
            clientAcceptsOutcome: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
            outcomeSavedToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
            adviseToSubmitToOmbud: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
            provideOmbudDetails: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
          }),
          ombud: this.formBuilder.group({
            clientSubmitToOmbud: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
            sumbittedDate: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
            ombudAcceptedClaim: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
            ombudOutcome: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null }),
            ombudRefNr: this.formBuilder.group({ value: '', completedBy: {}, dateCompleted: null })
          })
        }),
        claimFinalised: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        claimSignedOff: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
      })
    })

    this.claimsFormGroup.get('dateReported').patchValue(new Date());
    if(this.claimsFormGroup.get('claimSteps').get('claimStatus').get('value').value || !this.claimsFormGroup.get('claimSteps').get('value').get('claimStatus').value){
      this.claimsFormGroup.get('claimSteps').get('claimStatus').get('value').setValue('pending');
    }

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.claimsFormGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // GET CLIENTS
    this.claimsFormGroup.get('client').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

    // GET EMPLOYEES
    this.claimsFormGroup.get('employee').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

    // GET PRODUCTS
    this.claimsFormGroup.get('productDisplay').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.getProducts(this.claimsFormGroup.get('productType')?.value, this.claimsFormGroup.get('client')?.value?._id, value))
    )
    .subscribe(
      (response: HttpResponse<any>) => {
        this.products = response.body.vehicle || response.body.home || response.body.homeContent || response.body.trailerAndCaravan || response.body.item || response.body.products;
      }
    )

  }

  getMaxDate(step?:string, stepGroup?){

    if(!step && !stepGroup){
      if(!this.inEditMode){
        return new Date()
      }
    }

    if(!this.displayCompletedBy(step, stepGroup)){
      return new Date()
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.claimFormValue = value;
    }

    if (value === null) {
      this.claimsFormGroup.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.claimsFormGroup.valid ? null : { claim: { valid: false } };
  }

  displayClient(client?: User): string | undefined {
    return client ? client.firstName ? `${client.firstName} ${client.lastName}` : `${client.companyName}` : undefined;
  }

  displayProductDescription(product?: any): string | undefined {
    return product?.description;
  }

  displayProduct(product?: any): string | undefined {

    if(product && this.claimsFormGroup.get('productType').value === 'commercialProduct'){
      if(product.productType === 'vehicle'){
        this.claimsFormGroup.get('productTypeDescription').setValue('motor');
      }

      return product.description;
    }

    if(product && product.hasOwnProperty('path')){
      return product.description;
    }

    if(product && this.claimsFormGroup.get('productType').value === 'vehicle') {
      return `${product.make} ${product.model} - ${product.registrationNumber}`
    }

    if(product && this.claimsFormGroup.get('productType').value === 'trailerAndCaravan') {
      return `${product.type} - ${product.make} ${product.model}`
    }

    if(product && (this.claimsFormGroup.get('productType').value === 'building' || this.claimsFormGroup.get('productType').value === 'homeContent')) {
      return `${product?.address?.formattedAddress}`
    }

    if(product && this.claimsFormGroup.get('productType').value === 'allRiskItem') {
      return `${product?.itemName}`
    }

    if(product && this.claimsFormGroup.get('productType').value === 'vap') {
      return `${product?.description}`
    }

    return undefined;

  }

  mapProduct(event){
    let product = {
      _id: event.source.value._id,
      description: this.displayProduct(event.source.value),
      path: event.source.value._id
    }
    this.claimsFormGroup.get('product').patchValue(product);
  }

  selectedProductType(product){

    let productValue = this.claimsFormGroup.get('productDisplay');

    productValue.enable();
    if(productValue && productValue.value) productValue.setValue(null);

    if(product === 'vehicle'){
      this.claimsFormGroup.get('productTypeDescription').setValue('motor');
    } else {
      this.claimsFormGroup.get('productTypeDescription').setValue('nonMotor');
    }
  }

  getProducts(product:string, clientId:string, searchValue:string = '') {
    if(product === 'vehicle') return this.brokerService.getUserVehicle(clientId, { search: searchValue, isQuoteProduct: false }, true).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'building') return this.homeService.getAllHomeInsuranceForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'homeContent') return this.homeContentService.getAllHomeContentInsuranceForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'trailerAndCaravan') return this.trailersAndCaravansService.getAllTrailersAndCaravansInsuranceForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'allRiskItem') return this.allRiskService.getAllAllRiskInsuranceForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'vap') return this.vapsService.getAllVapsForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
    if(product === 'commercialProduct') return this.commercialProductService.getAllCommercialProductForClient(clientId, this.currentCompany, { search: searchValue, isQuoteProduct: false }).pipe(finalize(() => { this.loadingResults = false }))
  }

  claimNumberReceived(event){
    if(event.checked){
      // TODO ADD MODAL TO PROVIDE CLAIM NUMBER
    }
  }

  canCompleteClaim(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  displayCompletedBy(step, groupStep?){
    let claimStep = this.claimsFormGroup.get('claimSteps');
    if(groupStep){
      return claimStep?.get(groupStep).get(step)?.get('completedBy').value?.brokerId;
    } else {
      return claimStep?.get(step)?.get('completedBy').value?.brokerId;
    }
    
  }

  getStepCompletedBy(step: string, groupStep?){
    let stepFormControl;

    if(groupStep){
      stepFormControl = this.claimsFormGroup.get('claimSteps').get(groupStep).get(step);
    } else {
      stepFormControl = this.claimsFormGroup.get('claimSteps').get(step);
    }

    return `${stepFormControl?.get('completedBy')?.value?.firstName} ${stepFormControl?.get('completedBy')?.value?.lastName} on ${formatDate(stepFormControl.get('dateCompleted').value, "dd MMM yyyy 'at' HH:mm", 'en')}`;
  }

}
