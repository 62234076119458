import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-users',
  templateUrl: './management-users.component.html',
  styleUrls: ['./management-users.component.scss']
})
export class ManagementUsersComponent implements OnInit, AfterViewInit {

  users: User[];

  displayedColumns: string[] = ['firstName', 'lastName', 'idNumber', 'email', 'userType', 'active', 'cancelled', 'permissions', 'circa', 'action'];
  resultsLength = 0;
  pageSize = 25;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private managementService: ManagementService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.getAllUsers()
  }

  getAllUsers(noLoad?:boolean, eventData?:number){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: eventData ? (eventData * this.pageSize) - 1: this.paginator.pageIndex,
      limit: this.pageSize,
      firstName: '',
      lastName: '',
      idNumber: ''
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.managementService.getAllUsers(req)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;
          return data.body.user;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.users = data);
  }

  editUser(user:string){}

  disableUser(user:string){
    this.managementService.disableUser(user).subscribe(
      (response:HttpResponse<UserResponse>) => {
        user['disabled'] = true;
      }
    )
  }

  enableUser(user:string){
    this.managementService.enableUser(user).subscribe(
      (response:HttpResponse<UserResponse>) => {
        user['disabled'] = false;
      }
    )
  }

  activateUser(user:string){
    this.managementService.activateUser(user).subscribe(
      (response:HttpResponse<UserResponse>) => {
        user['active'] = true;
      }
    )
  }

}
