import { MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from 'src/app/services/broker.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { BrokerQuote } from 'src/app/_shared/models/brokerQuotes.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { Location } from '@angular/common'
import { Policy, PolicyResponse } from 'src/app/_shared/models/policy.model';

@Component({
  selector: 'app-approve-quote-modal',
  templateUrl: './approve-quote-modal.component.html',
  styleUrls: ['./approve-quote-modal.component.scss']
})
export class ApproveQuoteModalComponent implements OnInit, AfterViewInit {

  @ViewChild("quoteStepper", { static: false }) quoteStepper: MatStepper;

  quoteRoute = this.activeRoute.snapshot.paramMap.get('id');
  brokerQuote: BrokerQuote[]; 
  policies: Policy[];

  quoteFormGroup: FormGroup;

  showNextStep: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private quoteService: BrokerQuoteService,
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private brokerService: BrokerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.quoteFormGroup = this.formBuilder.group({
      _id: [''],
      client: [''],
      quoteNumber: [''],
      quoteStatus: [''],
      products: this.formBuilder.array([]),
      policyNumber: ['', Validators.required],
      inceptionDate: ['', Validators.required],
      vehicles: this.formBuilder.array([]),
      homeContent: this.formBuilder.array([]),
      home: this.formBuilder.array([]),
      allRisk: this.formBuilder.array([]),
      valueAddedProducts: this.formBuilder.array([]),
      trailersAndCaravans: this.formBuilder.array([])
    })

    this.getCurrentQuote()
  }

  ngAfterViewInit(){
    this.getLocalStorageItem();
  }

  getCurrentQuote(){
    this.quoteService.getSelectedQuote(this.authService.currentCompany.companyName, this.quoteRoute).subscribe(response => {
      this.brokerQuote = response.body.quotes;
      console.log('quote => ', this.brokerQuote)

      if(this.brokerQuote['policyNumber'] == '0') this.brokerQuote['policyNumber'] = null

      this.quoteFormGroup.patchValue(this.brokerQuote);

      this.getClientPolicies(this.brokerQuote['client']._id)
    })
  }

  goBack(){
    this.location.back();
  }

  addNewPolicy(){
    localStorage.setItem('SureSpace-Quote', JSON.stringify(this.quoteFormGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-Quote')){
      this.quoteFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-Quote')));
      localStorage.removeItem('SureSpace-Quote');
    }
  };

  getClientPolicies(clientId: string){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, clientId, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  approveQuote(){

    this.quoteFormGroup.markAllAsTouched();

    if(this.quoteFormGroup.invalid){
      console.warn(this.quoteFormGroup)
      this.toastr.error('There were some errors found.  Please double check everything and try again.', 'Could not approve quote.')
      return;
    }

    this.quoteService.approveQuote(this.quoteFormGroup.value).subscribe(response => {
      this.router.navigate([`/b/quotes`])
    })
  }

  insureProducts(){

    this.quoteFormGroup.get('policyNumber').markAsTouched();

    if(!this.quoteFormGroup.get('policyNumber').value || !this.quoteFormGroup.get('inceptionDate').value){
      this.toastr.error('Please provide a policy number and inception date for quote.', 'Please provide all details.');
      return;
    }

    this.brokerQuote['products'].forEach(product => {
      
      if(product && product.productType == 'AllRisk'){
        let formGroup = new FormGroup({
          _id: new FormControl(''),
          itemName: new FormControl(''),
          itemSerial: new FormControl(''),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl('', Validators.required),
            premium: new FormControl('', Validators.required),
            insuredValue: new FormControl('')
          })
        })

        let allRiskArray = this.quoteFormGroup.get('allRisk') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        allRiskArray.push(formGroup);
      }

      if(product && product.productType == 'Vehicle'){

        let formGroup = new FormGroup({
          _id: new FormControl(''),
          year: new FormControl(''),
          make: new FormControl(''),
          model: new FormControl(''),
          registrationNumber: new FormControl(''),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl(''),
            premium: new FormControl('', Validators.required),
            insuredValue: new FormControl(''),
            insuredValueRetail: new FormControl(false),
            terminationDate: new FormControl(''),
            currentlyInsured: new FormControl(true),
            regularDriver: new FormControl(''),
            additionalDriver: new FormControl('')
          })
        })

        let vehicleArray = this.quoteFormGroup.get('vehicles') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        vehicleArray.push(formGroup);
      }

      if(product && product.productType == 'HomeContent'){

        let formGroup = new FormGroup({
          _id: new FormControl(''),
          address: new FormGroup({
            formattedAddress: new FormControl(''),
          }),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl(''),
            premium: new FormControl('', Validators.required),
            contentValue: new FormControl(''),
            jeweleryValue: new FormControl(''),
            excess: new FormControl(''),
            accidentalDamage: new FormControl(''),
            powerSurge: new FormControl(''),
          })
        })

        let homeContentArray = this.quoteFormGroup.get('homeContent') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        homeContentArray.push(formGroup);
      }

      if(product && product.productType == 'Home'){

        let formGroup = new FormGroup({
          _id: new FormControl(''),
          address: new FormGroup({
            formattedAddress: new FormControl(''),
          }),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl(''),
            premium: new FormControl('', Validators.required),
            insuredValue: new FormControl(''),
            coverType: new FormControl(''),
            excess: new FormControl(''),
            accidentalDamage: new FormControl(''),
            powerSurge: new FormControl(''),
          })
        });

        let homeArray = this.quoteFormGroup.get('home') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        homeArray.push(formGroup);
      }

      if(product && product.productType == 'VAPS'){

        let formGroup = new FormGroup({
          _id: new FormControl(''),
          vapName: new FormControl(''),
          vapType: new FormControl(''),
          productType: new FormControl(''),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl(''),
            premium: new FormControl('', Validators.required),
          })
        });

        let vapsArray = this.quoteFormGroup.get('valueAddedProducts') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        vapsArray.push(formGroup);
      }

      if(product && product.productType == 'TrailersAndCaravans'){

        let formGroup = new FormGroup({
          _id: new FormControl(''),
          type: new FormControl(''),
          year: new FormControl(''),
          make: new FormControl(''),
          model: new FormControl(''),
          insurance: new FormGroup({
            companyName: new FormControl(''),
            inceptionDate: new FormControl(''),
            premium: new FormControl('', Validators.required),
            insuredValue: new FormControl(''),
            insuredValueRetail: new FormControl(''),
            policyNumber: new FormControl(''),
            cfg: new FormControl(''),
          })
        });

        let trailersAndCaravansArray = this.quoteFormGroup.get('trailersAndCaravans') as FormArray;
        formGroup.patchValue(product);
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        formGroup.get('insurance').get('inceptionDate').setValue(this.quoteFormGroup.get('inceptionDate').value)
        trailersAndCaravansArray.push(formGroup);
      }

    });

    // NEXT STEP
    this.quoteStepper.next();

    this.showNextStep = true;

  }

  returnVehicleDescription(vehicle){
    return `${vehicle.get('year').value} ${vehicle.get('make').value} ${vehicle.get('model').value} - ${vehicle.get('registrationNumber').value}`;
  }

  returnTrailersAndCaravansDescription(vehicle){
    return `${vehicle.get('type').value} - ${vehicle.get('year').value} ${vehicle.get('make').value} ${vehicle.get('model').value}`;
  }

  returnVapsDescription(vap){
    return `${vap.get('vapName').value} for ${vap.get('productType').value}`;
  }

}
