import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { Policy, PolicyResponse } from 'src/app/_shared/models/policy.model';
import { BrokerService } from 'src/app/services/broker.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BrokerClientAllRiskServiceService } from 'src/app/services/broker-client-all-risk-service.service';
import { AllRiskItemResponse, AllRiskItem } from 'src/app/_shared/models/allRiskItem.model';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-new-edit-client-all-risk-insurance',
  templateUrl: './broker-new-edit-client-all-risk-insurance.component.html',
  styleUrls: ['./broker-new-edit-client-all-risk-insurance.component.scss'],
})
export class BrokerNewEditClientAllRiskInsuranceComponent implements OnInit {

  @Input() view:boolean

  routerPath: string = this.activeRoute.routeConfig.path;
  editItem:boolean = this.routerPath.includes('edit');

  clientId = this.activeRoute.snapshot.paramMap.get('id') || this.activeRoute.snapshot.paramMap.get('clientId');
  itemId = this.activeRoute.snapshot.paramMap.get('itemId');

  formSubmitted = false;
  allRiskItemFormGroup: FormGroup;
  
  policies: Policy[];
  brokers: Employee[];

  allRiskItem: AllRiskItem[];

  redirectTo: string = this.activeRoute.snapshot.queryParams['r'];
  isQuoteProduct: boolean;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private clientAllRiskService: BrokerClientAllRiskServiceService,
    public location: Location,
    public authenticationService: AuthenticationService
  ) {
    this.isQuoteProduct = this.activeRoute.snapshot.queryParams['quote'] === 'true';
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  insuranceRequired(control: AbstractControl): {[key: string]: any} | null  {
    if (!control.value && (this && this.isQuoteProduct)) {
      return { 'required': true };
    }
    return null;
  }

  ngOnInit(): void {

    this.allRiskItemFormGroup = this.formBuilder.group({
      itemName: ['', Validators.required],
      itemDescription: '',
      itemCategory: '',
      itemSerial: [''],
      // specified: ['', Validators.required],
      insurance: this.formBuilder.group({
        companyName: ['', this.insuranceRequired],
        insuredValue: ['', this.insuranceRequired],
        premium: ['', this.insuranceRequired],
        policyNumber: ['', this.insuranceRequired],
        inceptionDate: ['', this.insuranceRequired]
      }),
      isInsured: [true],
      isQuoteProduct: false,
    })

    this.allRiskItemFormGroup.get('insurance').get('companyName').setValue(this.authService.currentCompany.companyName);
    if(this.isQuoteProduct){
      this.allRiskItemFormGroup.get('isQuoteProduct').setValue(true);
    }

  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authService.currentCompany.companyName, this.clientId, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  ngAfterViewInit(): void {

    if(!this.isQuoteProduct){
      this.getAllBrokers();
      this.getClientPolicyNumber();
    }

    this.getLocalStorageItem();

    if(this.editItem){
      this.getCurrentAllRiskItem(this.itemId);
    }
  }

  getAllBrokers(){
    this.brokerService.getAllCompanyEmployees(this.authService.currentCompany.companyName).subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.brokers = response.body.user;
      }
    )
  };

  addNewPolicy(){
    localStorage.setItem('SureSpace-Home', JSON.stringify(this.allRiskItemFormGroup.value))
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    if(localStorage.getItem('SureSpace-AllRisk')){
      this.allRiskItemFormGroup.patchValue(JSON.parse(localStorage.getItem('SureSpace-AllRisk')));
      localStorage.removeItem('SureSpace-AllRisk');
    }
  };

  displayFn(policy?: Policy): string | undefined {
    if(policy && typeof policy == 'string'){
      return policy
    } else return undefined
  }

  addNewAllRiskItem(saveType?:string){

    if(this.allRiskItemFormGroup.invalid){
      console.warn(this.allRiskItemFormGroup)
      this.toastr.error('There are some errors on your form.', 'Failed saving item.')
      return
    }

    if(this.isQuoteProduct){
      this.addNewQuotedProduct();
      return;
    }

    this.allRiskItemFormGroup.get('isInsured').setValue(true);

    this.clientAllRiskService.addNewClientAllRiskInsurance(this.clientId, this.allRiskItemFormGroup.value).subscribe(
      (response: HttpResponse<AllRiskItemResponse>) => {
        this.location.back()
      }
    )
  }

  addNewQuotedProduct(){

    this.allRiskItemFormGroup.get('isInsured').setValue(false);

    this.clientAllRiskService.addNewClientAllRiskItemQuote(this.clientId, this.authService.currentCompany.companyName, this.allRiskItemFormGroup.value).subscribe(
      (response: HttpResponse<AllRiskItemResponse>) => {
        let newAllriskItem = response.body.item;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newAllriskItem))
        this.location.back()
      }
    )
  }

  editQuotedProduct(){
    this.clientAllRiskService.editClientAllRiskItemQuote(this.clientId, this.itemId, this.authService.currentCompany.companyName, this.allRiskItemFormGroup.value).subscribe(
      (response: HttpResponse<AllRiskItemResponse>) => {
        let newAllriskItem = response.body.item;
        localStorage.setItem('SureSpace-QuoteItem', JSON.stringify(newAllriskItem))
        this.location.back()
      }
    )
  }

  getCurrentAllRiskItem(itemId:string){
    this.clientAllRiskService.getSingleAllRiskInsuranceForClient(this.clientId, itemId).subscribe(
      (response:HttpResponse<AllRiskItemResponse>) => {

        this.allRiskItem = response.body.item;

        this.allRiskItemFormGroup.patchValue(this.allRiskItem);

        this.allRiskItemFormGroup.controls.insurance.patchValue(this.allRiskItem['insurance']);
      }
    )
  };

  updateAllRiskItem(saveType?:string){

    if(this.allRiskItemFormGroup.invalid){
      this.toastr.error('There are some errors on your form.', 'Failed saving home.')
      return
    }

    this.clientAllRiskService.updateClientAllRiskInsurance(this.clientId, this.itemId, this.allRiskItemFormGroup.value).subscribe(
      (response: HttpResponse<AllRiskItemResponse>) => {
        this.location.back()
      }
    )
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}
