import { BrokerCompanyResponse } from 'src/app/_shared/models/brokerCompany.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { BrokerResponse } from '../_shared/models/broker.model';
import { Observable } from 'rxjs';
import { InsuranceCompanyResponse } from 'src/app/_shared/models/insuranceCompany.model';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) {}

  saveNewBroker(data): Observable<HttpResponse<BrokerResponse>>{
    return this.http.post<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/broker`, data, {
      observe: 'response'
    });
  };

  addNewBroker(data): Observable<HttpResponse<BrokerResponse>>{
    return this.http.post<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/addBroker`, data, {
      observe: 'response'
    });
  };

  /*getAllBrokerCompanies(broker, limit?): Observable<HttpResponse<BrokerResponse>>{
    let params = {
      broker: broker,
      limit: limit
    }

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/registeredBrokers`, {
      headers: headers,
      observe: 'response',
      params: params
    });
  };*/

  getBrokerCompanies(company, limit?): Observable<HttpResponse<BrokerCompanyResponse>>{
    let params = {
      company: company,
      limit: limit
    }

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get<BrokerCompanyResponse>(`${this.globals.SERVER_URL}/api/insurance/brokerCompanies`, {
      headers: headers,
      observe: 'response',
      params: params
    });
  }

  getBrokersWorkingForCompany(company, limit?): Observable<HttpResponse<BrokerResponse>>{
    let params = {
      limit: limit
    }

    let headers = new HttpHeaders();
    headers = headers.set("noload", "true");

    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/brokersFromCompany/${company}`, {
      headers: headers,
      observe: 'response',
      params: params
    });
  }

  getAllLinkedBrokers(): Observable<HttpResponse<BrokerResponse>>{
    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/broker`, {
      observe: 'response'
    });
  }

  getAllAssignedBrokers(req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/client/insurance/brokers/assignedBrokers`, {
      params: req,
      headers: headers,
      observe: 'response'
    });
  };

  getCustomBrokers(req?:any, noLoad?:boolean): Observable<HttpResponse<BrokerResponse>>{

    let headers = new HttpHeaders();
    if(noLoad){
      headers = headers.set("noload", "true");
    }

    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/customBroker`, {
      params: req,
      headers: headers,
      observe: 'response'
    });
  }

  getSelectedBroker(company): Observable<HttpResponse<BrokerResponse>>{
    return this.http.get<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/broker/${company}`, {
      observe: 'response'
    });
  };

  updateSelectedBroker(id, data): Observable<HttpResponse<BrokerResponse>>{
    return this.http.put<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/broker/${id}`, data, {
      observe: 'response'
    });
  };

  selectDifferentBroker(newBroker, currentBroker): Observable<HttpResponse<BrokerResponse>>{

    let params = {
      newBroker: newBroker,
      currentBroker: currentBroker
    }

    return this.http.put<BrokerResponse>(`${this.globals.SERVER_URL}/api/insurance/selectNewBroker`, params, {
      observe: 'response'
    });
  }

  removeBroker(id){
    return this.http.delete(`${this.globals.SERVER_URL}/api/insurance/broker/${id}`, {
      observe: 'response'
    });
  };

  saveNewInsuranceCompany(data): Observable<HttpResponse<InsuranceCompanyResponse>>{
    return this.http.post<InsuranceCompanyResponse>(`${this.globals.SERVER_URL}/api/createNew/insuranceCompany`, data, {
      observe: 'response'
    });
  };

  getAllInsuranceCompanies(): Observable<HttpResponse<InsuranceCompanyResponse>>{
    return this.http.get<InsuranceCompanyResponse>(`${this.globals.SERVER_URL}/api/insurance/insuranceCompany`, {
      observe: 'response'
    });
  };

  getSelectedInsuranceCompany(id): Observable<HttpResponse<InsuranceCompanyResponse>>{
    return this.http.get<InsuranceCompanyResponse>(`${this.globals.SERVER_URL}/api/insurance/insuranceCompany/${id}`, {
      observe: 'response'
    });
  };

  updateSelectedInsuranceCompany(id, data): Observable<HttpResponse<InsuranceCompanyResponse>>{
    return this.http.put<InsuranceCompanyResponse>(`${this.globals.SERVER_URL}/api/insurance/insuranceCompany/${id}`, data, {
      observe: 'response'
    });
  };

}
