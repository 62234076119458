<div id="content" class="row">

    <div class="secondary-nav">
        <div class="subnav-background"></div>
        <div class="row h-100">
            <div class="col-12 v-align">
                <h5 class="d-inline-block">Edit returned debit order</h5>
                <div class="separator"></div>
                <!-- <h6 class="d-inline-block">{{ returnedDebitFormGroup.get('returnedDebitOrder').value?.client?.firstName }} {{ returnedDebitFormGroup.get('returnedDebitOrder').value?.client?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" [routerLink]="['/b/clients/personal/edit', returnedDebitFormGroup.get('returnedDebitOrder').value?.client?._id]" [queryParams]="{file: '9'}"><img src="assets/icons/goto.svg" /></i></h6> -->
                <h6 class="d-inline-block">{{ returnedDebitFormGroup.get('returnedDebitOrder').value?.client?.firstName }} {{ returnedDebitFormGroup.get('returnedDebitOrder').value?.client?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" (click)="goToClientFile()"><img src="assets/icons/goto.svg" /></i></h6>  
            </div>
        </div>  
      </div>

    <div class="col-12">

        <form [formGroup]="returnedDebitFormGroup">
  
            <div class="panel panel-primary">

                <div class="panel-heading">
                    <h5>Returned debit order details</h5>
        
                    <div class="my-auto">
        
                    <button type="button" matTooltip="Go back to view all returned debit orders" class="btn btn-secondary my-auto" (click)="goBack()">
                        <i class="fi flaticon-back"></i>Back
                    </button>
        
                    <div class="btn-group" ngbDropdown>

                        <button type="submit" class="btn btn-primary" mat-tooltip="Save returned debit order and go back." (click)="saveReturnedDebit('back')">
                            Save returned debit
                        </button>

                        <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                        </button>
                        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" (click)="saveReturnedDebit('continue')">Save &amp; continue</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="saveReturnedDebit('new')">Save &amp; add new</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="saveReturnedDebit('back')">Save &amp; go back</a></li>
                        </ul>

                    </div> <!-- Close div for .btn-group -->
        
                    </div> <!-- Close div for .my-auto -->
        
                </div>
        
                <div class="panel-body">
                    
                    <div class="row">
                        <returned-debit-order-form-group class="col-12" formControlName="returnedDebitOrder"></returned-debit-order-form-group>
                    </div>

                </div>

            </div>

        </form>

    </div>

</div>