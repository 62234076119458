<div id="content" class="row">

    <sub-navigation heading="{{ editQuote ? 'Edit' : 'Add new' }} quote"></sub-navigation>

    <div class="secondary-nav">
        <div class="subnav-background"></div>
        <div class="row h-100">
          <div class="col-12 v-align">
            <h5 class="d-inline-block">{{ editQuote ? 'Edit' : 'Add new' }} quote</h5>
            <ng-container *ngIf="editQuote">
                <div class="separator"></div>
                <!-- <h6 class="d-inline-block">{{ quoteFormGroup.get('client')?.value?.firstName }} {{ quoteFormGroup.get('client')?.value?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" [routerLink]="['/b/clients/personal/edit', quoteFormGroup.get('client')?.value?._id]"><img src="assets/icons/goto.svg" /></i></h6> -->
                <h6 class="d-inline-block">{{ quoteFormGroup.get('client')?.value?.firstName }} {{ quoteFormGroup.get('client')?.value?.lastName }} <i class="goto my-auto" mat-tooltip="Go to client's file" (click)="goToClientFile()"><img src="assets/icons/goto.svg" /></i></h6>
            </ng-container>  
        </div>
        </div>  
      </div>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
        <form id="quoteFormGroup" [formGroup]="quoteFormGroup">

            <mat-horizontal-stepper linear #quoteStepper [selectedIndex]="selectedStep">

                <mat-step label="Details">

                  <div class="row">

                    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                        <div class="row mat-stepper-body">

                            <div class="col-12">
                                <h5 class="mb-4"><strong>Add quote details</strong></h5>
                            </div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Client:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8" >
                                <div class="input-group">
                                    <mat-form-field>
                                        <input
                                          matInput 
                                          type="text"
                                          aria-label="Client"
                                          formControlName='clientAutoCompleteDisplay'
                                          [matAutocomplete]="user"
                                        />
                                        <mat-hint [hidden]="quoteFormGroup.get('clientAutoCompleteDisplay').dirty">Type a name to start the search</mat-hint>
        
                                        <mat-autocomplete autoActiveFirstOption #user="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="getClientPolicyNumber(); quoteFormGroup.get('policyNumber').enable()">
                                            <mat-option *ngIf="isLoading" class="is-loading">Finding clients...</mat-option>
        
                                            <div class="autocomplete-panel" *ngIf="!isLoading">
                                                <mat-option disabled *ngIf="clients?.length === 0 && quoteFormGroup.get('client').value">
                                                    <span>No client found</span>
                                                </mat-option>
                                                <mat-option *ngFor="let client of clients" [value]="client" (onSelectionChange)="mapClient(client)">
                                                    <span>{{ client?.firstName || client?.companyName }} {{ client?.lastName }}</span>
                                                </mat-option>

                                                <!-- <div class="mt-2">
                                                    <button (click)="addNewClient()" class="btn btn-square btn-label-primary btn-block">
                                                        Add new client
                                                    </button>
                                                </div> -->

                                            </div>

                                            <div class="autocomplete-footer">
                                                <button (click)="addNewClient()" class="btn btn-square btn-label-primary btn-block">
                                                    Add new client
                                                </button>
                                            </div>
                                        </mat-autocomplete>
                                        
        
                                        <mat-error>
                                            Please select a <strong>client</strong> for this quote.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Employee:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">

                                    <mat-form-field>
                                        <input
                                            matInput 
                                            type="text"
                                            aria-label="Employee"
                                            formControlName='brokerAutoCompleteDisplay'
                                            [matAutocomplete]="employee"
                                        />
                                        <mat-hint [hidden]="quoteFormGroup.get('brokerAutoCompleteDisplay').value">Type an employee name to start the search</mat-hint>
        
                                        <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayFn.bind(this)">
                                            <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>
        
                                            <ng-container *ngIf="!isLoading">
                                                <mat-option disabled *ngIf="employees?.length === 0 && quoteFormGroup.get('brokerAutoCompleteDisplay').value">
                                                    <span>No employees found</span>
                                                </mat-option>
                                                <mat-option *ngFor="let broker of employees" [value]="broker" (onSelectionChange)="mapBroker(broker)">
                                                    <span>{{ broker?.firstName }} {{ broker?.lastName }}</span>
                                                </mat-option>

                                            </ng-container>
                                        </mat-autocomplete>
        
                                        <mat-error>
                                            Please select an employee for this quote.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="underwriter">Underwriter:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">
                                    <mat-form-field class="mb-0">
                                        <mat-select
                                            name="underwriter"
                                            formControlName="selectedUnderwriter"
                                            (selectionChange)="mapUnderwriter($event)"
                                            [ngClass]="{ 'is-invalid': formSubmitted && quoteFormGroup.get('underwriter').errors }"
                                            multiple
                                            [compareWith]="compareSelectedUnderwriters"
                                        >
                                            <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                                            <mat-option *ngFor="let underwriter of underwriters" [value]="underwriter">{{ underwriter?.name }}</mat-option>
                                        </mat-select>
        
                                        <mat-error>
                                            Please select an <strong>underwriter</strong>.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="administrator">Administrator:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">
                                    <mat-form-field class="mb-0">
                                        <mat-select
                                            name="administrator"
                                            formControlName="selectedAdministrator"
                                            (selectionChange)="mapAdministrator($event)"
                                            [ngClass]="{ 'is-invalid': formSubmitted && quoteFormGroup.get('administrator').errors }"
                                            multiple
                                            [compareWith]="compareSelectedUnderwriters"
                                        >
                                            <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                                            <mat-option *ngFor="let administrator of administrators" [value]="administrator">{{ administrator?.name }}</mat-option>
                                        </mat-select>
        
                                        <mat-error>
                                            Please select an <strong>administrator</strong>.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto" [hidden]="true">
                                <label for="itemName">Quote status:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8" [hidden]="true">
                                <div class="input-group">
                                    <mat-form-field class="mb-0">
                                        <mat-select
                                            name="quoteStatus"
                                            formControlName="quoteStatus"
                                            [ngClass]="{ 'is-invalid': formSubmitted && quoteFormGroup.get('quoteStatus').errors }"
                                        >
                                            <!-- <mat-option value="" disabled>Please select a product</mat-option> -->
                                            <mat-option value="inProgress">In progress</mat-option>
                                            <mat-option value="sent">Sent</mat-option>
                                            <mat-option value="accepted">Accepted</mat-option>
                                            <mat-option value="rejected">Rejected</mat-option>
                                        </mat-select>
        
                                        <mat-error>
                                            Please select a <strong>product type</strong>.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <div class="col-12 clearfix mb-3"></div>

                            <div class="col-12 col-sm-5 col-md-4 my-auto">
                                <label for="itemName">Policy number:</label>
                            </div>

                            <div class="col-12 col-sm-7 col-md-8">
                                <div class="input-group">

                                    <mat-form-field>
                                        <input
                                            matInput 
                                            type="text"
                                            aria-label="Policy number"
                                            [matAutocomplete]="policyNumber"

                                            [placeholder]="!quoteFormGroup.get('client').get('_id').value ? 'Please select a client' : 'Policy number'"
                                            formControlName="policyNumber"
                                            [readOnly]="!quoteFormGroup.get('client').get('_id').value"
                                        />
        
                                        <mat-autocomplete autoActiveFirstOption #policyNumber="matAutocomplete">
                                            <mat-option *ngIf="isLoading" class="is-loading">Finding policy numbers...</mat-option>
        
                                            <ng-container *ngIf="!isLoading">
                                                <mat-option [value]="0" matTooltipPosition="left" matTooltip="No policy needs to be attached to the quote">
                                                    <span>No Policy</span>
                                                </mat-option>
                                                <mat-option disabled *ngIf="policies?.length === 0 && quoteFormGroup.get('policyNumber').value">
                                                    <span>No policy numbers found</span>
                                                </mat-option>
                                                <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                                    <span>{{ policy?.policyNumber }}</span>
                                                </mat-option>

                                            </ng-container>
                                        </mat-autocomplete>
        
                                        <mat-error>
                                            Please select a <strong>policy number</strong> for this quote.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div> <!-- Close div for .input-group -->
                            </div>

                            <ng-container *ngIf="editQuote">

                                <div class="col-12 clearfix mb-3"></div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemName">Quote number:</label>
                                </div>
    
                                <div class="col-12 col-sm-7 col-md-8">
                                    <div class="input-group">
                                        <input class="form-control" readonly [value]="quoteFormGroup.get('quoteNumber').value">
                                    </div> <!-- Close div for .input-group -->
                                </div>

                            </ng-container>

                        </div> <!-- Close div for .mat-stepper-body -->

                        <div class="mat-stepper-footer">
                            <button class="btn btn-secondary btn-bg" (click)="goBack()">Cancel</button>
                            <button class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                        </div>

                    </div>

                </div> <!-- Close div for .row -->

                </mat-step>

                <mat-step label="Products">

                    <div class="row" *ngIf="isCurrentStep('Products') && quoteFormGroup.get('client').get('_id').value; else noClient">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">
    
                            <div class="row mat-stepper-body">

                                <div class="col-12 col-sm-6">
                                    <h5 class="mb-4"><strong>Products</strong></h5>
                                </div>
            
                                <div class="col-12 col-sm-6 text-left text-sm-right">

                                    <button *ngIf="getClientType(quoteFormGroup.get('client').value) === 'commercial'" type="button" type="button" class="btn btn-primary" aria-controls="dropdown-basic" (click)="addCommercialProduct()">
                                        Add product
                                    </button>
        
                                    <div class="btn-group" ngbDropdown *ngIf="getClientType(quoteFormGroup.get('client').value) === 'personal'">
                                        <!-- <button type="submit" class="btn btn-primary" mat-tooltip="Add a new product." (click)="addNewProduct()">
                                            Add product
                                        </button> -->
                                        <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                                            Add product
                                        </button>
                                        <!-- <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-basic"> -->
                                        <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic" x-placement="bottom-left">
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('vehicle')">Add vehicle</a></li>
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('building')">Add building</a></li>
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('homeContent')">Add home content</a></li>
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('allRiskItem')">Add all risk item</a></li>
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('trailersAndCaravans')">Add trailer or caravan</a></li>
                                            <li role="menuitem"><a class="dropdown-item" (click)="addNewProduct('valueAddedProduct')">Add value added product</a></li>
                                        </ul>
                                    </div> <!-- Close div for .btn-group -->
        
                                </div>
        
                                <div class="col-12 clearfix mb-3"></div>

                                <ng-container *ngFor="let product of quoteProductsControls.controls;">

                                    <div class="col-10">
                                        <p>{{ product?.value?.productType | sentenceCase }} - {{ createProductDescription(product?.value) }}</p>
                                    </div>
    
                                    <div class="col-2 text-right">
                                        <button 
                                            class="btn btn-light btn-pill color-brand mr-0"
                                            matTooltip="Edit product"
                                            (click)="editExistingProduct(product)"
                                        >
                                            <i class="fi flaticon-edit mr-0"></i>
                                        </button>
    
                                        <button 
                                            class="btn btn-light btn-pill color-danger mr-0"
                                            matTooltip="Remove product from quote"
                                            (click)="removeProductFromQuote(product)"
                                        >
                                            <i class="fi flaticon-trash mr-0"></i>
                                        </button>
                                    </div>

                                </ng-container>


                            </div> <!-- Close div for .mat-stepper-body --> 

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button *ngIf="isCurrentStep('Products') && quoteFormGroup.get('client').get('_id').value" class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                    <ng-template #noClient>
                        <p class="text-center">Please select a client in the previous step</p>
                    </ng-template>

                </mat-step>

                <mat-step label="Notes">
                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Add comments:</strong></h5>
                                </div>

                                <div class="col-12 col-sm-5 col-md-4 my-auto">
                                    <label for="itemName">Additional comment</label>
                                </div>

                                <div class="col-12 col-sm-7 col-md-8" >
                                    <div class="form-group">
                                        <textarea [(ngModel)]="additionalComment" [ngModelOptions]="{standalone: true}" class="form-control" row="3" (blur)="addAdditionalComment({ comment: additionalComment })" ></textarea>
                                    </div>
                                </div>
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="addAdditionalComment({ comment: additionalComment })">Add comment</button>
                                </div>

                            </div> <!-- Close div for .mat-stepper-body -->

                            <div class="row mat-stepper-body">
                                <div class="col-12 mt-3" *ngFor="let comment of quoteFormGroup.get('additionalComments').value; let i = index; let last = last">
                                    <div class="row">
                                        <div class="col-11">
                                            <span>{{ comment?.comment }}</span><br />
                                            <small>{{ comment?.createdBy?.firstName }} {{ comment?.createdBy?.lastName }} - {{ comment?.createdAt | date: 'dd MMM yyyy HH:mm' }}</small>
                                        </div>

                                        <div class="col-1 text-right my-auto">
                                            <button class="btn btn-icon btn-danger" (click)="removeAdditionalComment(i)"><i class="fi flaticon-trash"></i></button>
                                        </div>
                                    </div>

                                    <hr class="my-0 mt-4" *ngIf="!last" />

                                </div>
                            </div> <!-- Close div for .row -->

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <button *ngIf="isCurrentStep('Notes') && quoteFormGroup.get('client').get('_id').value" class="btn btn-primary btn-bg float-right mr-0" matStepperNext>Next Step</button>
                            </div>

                        </div>

                    </div> <!-- Close div for .row-->

                </mat-step>

                <mat-step label="Submit">

                    <div class="row">

                        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-12">

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <h5 class="mb-4"><strong>Submit changes:</strong></h5>
                                </div>

                            </div>

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <p><strong>Quote details:</strong></p>
                                    <p>
                                        <span class="text-muted">Client:</span> {{ quoteFormGroup.get('client')?.value?.companyName }}{{ quoteFormGroup.get('client')?.value?.firstName }} {{ quoteFormGroup.get('client')?.value?.lastName }}<br />
                                        <span class="text-muted">Employee:</span> {{ quoteFormGroup.get('broker')?.value?.firstName }} {{ quoteFormGroup.get('broker')?.value?.lastName }}<br />
                                        
                                        <span class="text-muted">Underwriter:</span>
                                        <span *ngFor="let underwriter of this.quoteFormGroup.get('selectedUnderwriter').value">
                                            {{ underwriter?.name }} <br />
                                        </span>
                                        <span *ngIf="!this.quoteFormGroup.get('selectedUnderwriter').value || this.quoteFormGroup.get('selectedUnderwriter').value == ''"> N/A<br /></span>
                                        
                                        <span class="text-muted">Administrator:</span>
                                        <span *ngFor="let administrator of this.quoteFormGroup.get('selectedAdministrator').value">
                                            {{ administrator?.name }} <br />
                                        </span>
                                        <span *ngIf="!this.quoteFormGroup.get('selectedAdministrator').value || this.quoteFormGroup.get('selectedAdministrator').value == ''"> N/A<br /></span>

                                        <span class="text-muted">Policy number:</span> {{ quoteFormGroup.get('policyNumber').value == 0 ? 'No policy' : quoteFormGroup.get('policyNumber').value }}<br />

                                        <span class="text-muted">Quote status:</span> {{ quoteFormGroup.get('quoteStatus').value | sentenceCase }}
                                    </p>
                                </div>

                            </div>

                            <hr />

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <p><strong>Products:</strong></p>
                                </div>

                                <div class="col-6" *ngFor="let product of quoteProductsControls.controls">
                                    {{ returnProduct(product) }} - {{ createProductDescription(product?.value) }}
                                </div>

                            </div>

                            <hr />

                            <div class="row mat-stepper-body">

                                <div class="col-12">
                                    <p><strong>Additional notes:</strong></p>
                                </div>

                                <div class="col-12">
                                    <ul *ngFor="let comment of quoteFormGroup.get('additionalComments').value;">
                                        <li>{{ comment?.comment }}</li>
                                    </ul>
                                </div> <!-- Close div for .row -->

                            </div>

                            <div class="mat-stepper-footer">
                                <button class="btn btn-secondary btn-bg" matStepperPrevious>Back</button>
                                <div class="btn-group float-right" ngbDropdown>
                                    <button *ngIf="quoteFormGroup.get('client').get('_id').value && !viewQuote && canViewItem" class="btn btn-success btn-bg float-right mr-0" (click)="editQuote ? updateQuote('back') : addNewQuote('back')">Finish</button>
                                    <button ngbDropdownToggle type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                                    </button>
                                    <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                        <li role="menuitem"><a class="dropdown-item" (click)="saveAsDraft()">Save as draft</a></li>
                                    </ul>
                                </div>
                                <!-- Close div for .btn-group -->
                            </div>

                        </div>

                    </div> <!-- Close div for .row -->

                </mat-step>

            </mat-horizontal-stepper>
  
          <div class="panel-heading" *ngIf="false">
            <h5>Quote details</h5>
  
            <div class="my-auto">
  
              <button type="button" matTooltip="Go back to view all quotes" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>
  
              <div class="btn-group" ngbDropdown>
                <button type="submit" class="btn btn-primary" mat-tooltip="Save quote and go back." (click)="editQuote ? updateQuote('back') : addNewQuote('back')">
                  Save quote
                </button>
                <button type="button" ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  aria-controls="dropdown-basic">
                </button>
                <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" (click)="editQuote ? updateQuote('continue') : addNewQuote('continue')">Save &amp; continue</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="editQuote ? updateQuote('new') : addNewQuote('new')">Save &amp; add new</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="editQuote ? updateQuote('back') : addNewQuote('back')">Save &amp; go back</a></li>
                </ul>
              </div> <!-- Close div for .btn-group -->
  
            </div> <!-- Close div for .my-auto -->
  
          </div>

        </form>
      </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->

  </div> <!-- Close div for #content -->

  <ng-template #chooseProductToAdd>
    <h2 mat-dialog-title>Add item to quote</h2>
    <mat-dialog-content class="mat-typography">

        <button class="btn btn-block btn-primary" (click)="addNewProduct('vehicle')">Add vehicle</button>
        <button class="btn btn-block btn-primary" (click)="addNewProduct('building')">Add building</button>
        <button class="btn btn-block btn-primary" (click)="addNewProduct('homeContent')">Add home content</button>
        <button class="btn btn-block btn-primary" (click)="addNewProduct('allRiskItem')">Add all risk item</button>
        <button class="btn btn-block btn-primary" (click)="addNewProduct('trailersAndCaravans')">Add trailer or caravan</button>
        <button class="btn btn-block btn-primary" (click)="addNewProduct('valueAddedProduct')">Add value added product</button>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="btn btn-outline-secondary" mat-dialog-close>Close</button>
    </mat-dialog-actions>
  </ng-template>