import { CommercialProduct, CommercialProductResponse } from './../_shared/models/commercialProduct.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class CommercialProductsService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  addNewClientCommercialProduct(clientId:string, productFormData:CommercialProduct, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.post<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  addNewClientCommercialProductQuote(clientId:string, companyName: string, productFormData:CommercialProduct, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.post<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/company/${companyName}/quote`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  editClientCommercialProductQuote(clientId:string, productId:string, companyName: string, productFormData:CommercialProduct, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.put<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/company/${companyName}/quote/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  getAllCommercialProductForClient(clientId:string, companyName: string, req:any = {}): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.get<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getPreviousCommercialProductForClient(clientId:string, companyName: string, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.get<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/previouslyInsured/client/${clientId}/company/${companyName}`, {
      observe: 'response',
      params: req
    })
  }

  getSingleCommercialProductForClient(clientId:string, productId:string, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.get<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/product/${productId}`, {
      observe: 'response',
      params: req
    })
  }

  updateCommercialProduct(clientId:string, productId:string, productFormData:CommercialProduct, req?:any): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.put<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/product/${productId}`, productFormData, {
      observe: 'response',
      params: req
    })
  }

  deleteCommercialProduct(clientId:string, productId:string): Observable<HttpResponse<CommercialProductResponse>>{
    return this.http.delete<CommercialProductResponse>(`${this.globals.SERVER_URL}/api/broker/insurance/commercialProduct/client/${clientId}/product/${productId}`, {
      observe: 'response'
    })
  }

}
