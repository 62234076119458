import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteValueAddedProductComponent } from 'src/app/components/modal/delete-value-added-product/delete-value-added-product.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ValueAddedProductsService } from 'src/app/services/value-added-products.service';
import { ValueAddedProduct, ValueAddedProductResponse } from 'src/app/_shared/models/vaps.model';

@Component({
  selector: 'broker-view-user-value-added-products',
  templateUrl: './broker-view-user-value-added-products.component.html',
  styleUrls: ['./broker-view-user-value-added-products.component.scss'],
  providers: [DeleteValueAddedProductComponent]
})
export class BrokerViewUserValueAddedProductsComponent implements OnInit {

  view:string = 'current';

  selectedUser = this.activeRoute.snapshot.paramMap.get('id');

  userProducts: ValueAddedProduct[]
  previouslyInsuredProducts: ValueAddedProduct[]

  displayedColumns: string[] = ['product', 'productType', 'productValue', 'premium', 'inceptionDate', 'menu'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100]
  isLoadingResults = true;
  isProductRateLimitReached = false;
  isPreviousProductRateLimitReached = false;

  resultsLengthPreviousInsurance = 0;
  pageSizePreviousInsurance = 25;

  currentUser = this.authService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public router: Router, // used in view
    private activeRoute: ActivatedRoute,
    private vapsService: ValueAddedProductsService,
    private authService: AuthenticationService,
    private deleteProductModal: DeleteValueAddedProductComponent,
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authService.currentCompany.companyName).permissions
  }

  getUserVaps(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.isProductRateLimitReached){

      this.isLoadingResults = true;

      this.vapsService.getAllVapsForClient(this.selectedUser, this.authService.currentCompany.companyName, req).subscribe(
        (response:HttpResponse<ValueAddedProductResponse>) => {
          this.isLoadingResults = false;
          this.userProducts = response.body.products;

          console.log('vap => ', response)

          this.resultsLength = response.body.count;
          if(this.userProducts.length < this.pageSize){
            this.isProductRateLimitReached = true;
          }
        }
      )
    }
  };

  getPreviousInsuredProducts(event?:any){

    const req = {
      offset: event ? event.pageIndex : 0,
      limit: this.pageSize
    }

    if(!this.previouslyInsuredProducts){

      this.isLoadingResults = true;

      this.vapsService.getPreviousVapsForClient(this.selectedUser, this.authService.currentCompany.companyName, req).subscribe(
        (response:HttpResponse<ValueAddedProductResponse>) => {
          this.isLoadingResults = false;
          this.previouslyInsuredProducts = response.body.products;

          this.resultsLengthPreviousInsurance = response.body.count;
          if(this.previouslyInsuredProducts.length < this.pageSize){
            this.isPreviousProductRateLimitReached = true;
          }
        }
      )
    }

  }

  deleteProduct(product){
    product['clientId'] = this.selectedUser;
    this.deleteProductModal.openDialog(product);
    this.deleteProductModal.dialogRef.afterClosed().subscribe(response => {
      if (response === 'success'){
        this.userProducts = this.userProducts.filter(item => item != product);
      }
    })
  };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getUserVaps();
    this.getPreviousInsuredProducts();
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') ||  this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
