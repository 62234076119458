<div id="content" class="row">

    <sub-navigation heading="Reports"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Policy report</h5>
        </div>
  
        <div class="panel-body">

            <div class="row form-group" [formGroup]="policyFilters">

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="">Opened</label>
                    <mat-select id="timePeriod" class="form-control" formControlName="timePeriod" (selectionChange)="createDate($event)">
                        <mat-option value="0">Today</mat-option>
                        <mat-option value="1">Yesterday</mat-option>
                        <mat-option value="7">Last 7 days</mat-option>
                        <mat-option value="30">Last 30 days</mat-option>
                        <mat-option value="365">Last year</mat-option>
                        <mat-option value="">All time</mat-option>
                        <mat-option class="custom" value="custom" disabled>{{ policyFilters?.get('dateFrom').value | date: 'dd/MM/yyyy' }} - {{ policyFilters?.get('dateTo').value | date: 'dd/MM/yyyy' }}</mat-option>
                    </mat-select>

                    <mat-form-field id="datepicker" appearance="fill">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" formControlName="dateFrom">
                          <input matEndDate placeholder="End date" formControlName="dateTo">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <small class="link py-2" (click)="picker.open(); policyFilters.get('timePeriod').setValue('custom')">Select custom date range</small>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="clientType">Client type</label>
                    <mat-select class="form-control" formControlName="clientType">
                        <mat-option value="">All clients</mat-option>
                        <mat-option value="User">Private clients</mat-option>
                        <mat-option value="CommercialClient">Commercial clients</mat-option>
                    </mat-select>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="status">Status</label>
                    <mat-select class="form-control" formControlName="status">
                        <mat-option value="">All policies</mat-option>
                        <mat-option [value]="true">Active policies</mat-option>
                        <mat-option [value]="false">Cancelled policies</mat-option>
                        <!-- <mat-option value="abandoned">
                            Abandoned claims <i class="fi fi-md flaticon-question ml-3" matTooltip="Abandoned claims are claims that have not been touched in the last 30 days."></i>
                        </mat-option> -->
                    </mat-select>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="employee">Employee</label>
                    <input type="text"
                        class="form-control"
                        placeholder="Employee"
                        matInput
                        formControlName="employee"
                        [matAutocomplete]="employee">
                    <mat-autocomplete #employee="matAutocomplete" [displayWith]="displayEmployeeName" (optionSelected)="mapEmployee($event)">
                    <mat-option [value]="{_id: 0}">
                        All employees
                    </mat-option>
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{ employee?.firstName }} {{ employee?.lastName }}
                    </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-auto">
                    <button (click)="getPolicyReport()" class="btn btn-primary">Apply filters</button>
                </div>

            </div>

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <div class="row">

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ policyTotals?.totalPolicies || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Total Policies</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ policyTotals?.totalActivePolicies}}</h4>
                                    <small class="my-0" style="opacity: .8">Active policies</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ policyTotals?.totalPrivatePolicies || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Private client policies</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ policyTotals?.totalCommercialPolicies || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Commercial client policies</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div> <!-- Close div for .row -->

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <table mat-table [dataSource]="employeesPolicies" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

                <!-- Client Column -->
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>Employee</th>
                    <td mat-cell *matCellDef="let row" data-label="Employee">{{ row?.firstName }} {{ row?.lastName }}</td>
                </ng-container>

                <!-- Client Number Column -->
                <ng-container matColumnDef="totalPolicies">
                    <th mat-header-cell *matHeaderCellDef>Total policies</th>
                    <td mat-cell *matCellDef="let row" data-label="Total policies">{{ row?.totalPolicies || "0"}}</td>
                </ng-container>

                 <!-- Reference Number Column -->
                 <ng-container matColumnDef="activePolicies">
                    <th mat-header-cell *matHeaderCellDef>Active policies</th>
                    <td mat-cell *matCellDef="let row" data-label="Active policies">{{ row?.totalActivePolicies || "0" }}</td>
                </ng-container>

                <ng-container matColumnDef="personalClients">
                    <th mat-header-cell *matHeaderCellDef>Total personal clients</th>
                    <td mat-cell *matCellDef="let row" data-label="Total personal clients">{{ row?.totalPrivatePolicies }}</td>
                </ng-container>

                <!-- Admin Status Column -->
                <ng-container matColumnDef="commercialClients">
                    <th mat-header-cell *matHeaderCellDef>Total commercial clients</th>
                    <td mat-cell *matCellDef="let row" data-label="Total commercial clients">{{ row?.totalCommercialPolicies }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- <mat-paginator [hidden]="policies?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator> -->

        </div> <!-- Close div for panel-body -->

    </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for content-->