import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'style-guide-background',
  templateUrl: './style-guide-background.component.html',
  styleUrls: ['./style-guide-background.component.scss']
})
export class StyleGuideBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
