import { Vehicle, VehicleResponse } from 'src/app/_shared/models/vehicle.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { BrokerClientService } from 'src/app/services/broker-client.service';

@Component({
  template: ''
})
export class DeleteVehicleComponent {

  dialogRef: any;

  constructor(
    public dialog: MatDialog
  ) {}

  openDialog(vehicleData): void {
    this.dialogRef = this.dialog.open(DeleteVehicleComponentPreview, {
      // width: '600px',
      data: vehicleData
    });
  }

}

@Component({
  templateUrl: './delete-vehicle.component.html',
  styleUrls: ['./delete-vehicle.component.scss']
})
export class DeleteVehicleComponentPreview {

  constructor(
    public dialogRef: MatDialogRef<DeleteVehicleComponentPreview>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    public personalInsuranceService: PersonalInsuranceService,
    private brokerClientService: BrokerClientService
  ) { }

  closeDialog(type:string): void {
    this.dialogRef.close(type);
  }

  removeVehicle(data: any){
    console.log('data => ', data)
    this.brokerClientService.deleteInsuranceFromVehicle(data.clientId, data._id).subscribe(
      (response:HttpResponse<VehicleResponse>) => {
        this.closeDialog('success');
      }
    )
  }

}

