<div class="panel-body pb-0">

    <div class="col-12">

        <div class="row">

          <div class="mb-5 col-md-4 col-sm-6 col-6">
          
            <div class="col-12 my-auto">
              <label for="employee">Employee:</label>
            </div>
            <div class="col-12 my-auto">
                <div class="input-group">
                    <mat-form-field>
                        <input
                          matInput 
                          type="text"
                          aria-label="Employee"
                          [formControl]='brokerSearch'
                          [matAutocomplete]="employee"
                          placeholder="Enter employee name"
                        />
                        <mat-hint>Choose an employee to find stuff they worked on</mat-hint>
  
                        <mat-autocomplete autoActiveFirstOption #employee="matAutocomplete" [displayWith]="displayFn.bind(this)">
                            <mat-option *ngIf="isLoading" class="is-loading">Finding employees...</mat-option>
  
                            <ng-container *ngIf="!isLoading">
                                <mat-option disabled *ngIf="employees?.length === 0">
                                    <span>No employee found</span>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees" [value]="employee" (onSelectionChange)="runBrokerSearch(employee._id)">
                                    <span>{{ employee?.firstName }} {{ employee?.lastName }}</span>
                                </mat-option>
  
                            </ng-container>
                        </mat-autocomplete>
  
                    </mat-form-field>
                </div> <!-- Close div for .input-group -->
            </div>
  
          </div> <!-- Close div for .mb-5-->
  
          <div class="mb-5 col-md-4 col-sm-6 col-6">

            <div class="col-12 my-auto">
              <label for="employee">Note reference:</label>
            </div>
            <div class="col-12 my-auto">
              <mat-form-field>
                <input matInput placeholder="Search" name="noteSearch" type="text" [(ngModel)]="noteSearch" (ngModelChange)="runSearch($event)"/>
                <mat-hint>
                  Find a note reference
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="mb-5 col-md-4 col-sm-6 col-6">

            <div class="col-12 my-auto">
              <label for="employee">Date range:</label>
            </div>
            <div class="col-12 my-auto">

              <div class="row">

                <div class="col-12 col-sm-6">
                  <mat-form-field>
                    <input placeholder="Start date" matInput [matDatepicker]="searchDateStartPicker" name="searchDateStartPicker"
                    [(ngModel)]="searchDateStart" (ngModelChange)="runSearch($event)">
                    <mat-datepicker-toggle matSuffix [for]="searchDateStartPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #searchDateStartPicker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-12 col-sm-6">
                  <mat-form-field>
                    <input placeholder="End date" matInput [matDatepicker]="searchDateEndPicker" name="searchDateEndPicker"
                    [(ngModel)]="searchDateEnd" (ngModelChange)="runSearch($event)">
                    <mat-datepicker-toggle matSuffix [for]="searchDateEndPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #searchDateEndPicker></mat-datepicker>
                  </mat-form-field>
                </div>

              </div> <!-- Close div for .row -->

            </div>
          </div>

        </div>

      </div>

      <div class="col-12 mt-2"></div>

    <div class="timeline-v4" *ngIf="!loadingResults; else findingResults">

        <div class="timeline-items">

            <div *ngFor="let note of activityLogs; let i = index; let first = first" class="timeline-item">

                <div class="mb-4" [ngClass]="first ? 'mt-0' : 'mt-4'">
                    <h5 *ngIf="compareDates(i)" class="mb-3 color-dark">{{ note?.createdAt | date: 'dd MMMM yyyy'}}</h5>
                </div>

                <div class="position-relative">

                    <span class="timeline-time">{{ note?.createdAt | date: 'HH:mm'}}</span>

                    <div class="timeline-item-circle">
                        <div class="circle circle-primary"></div>
                    </div>

                    <div class="timeline-item-content">

                        <p class="text-muted"><small>{{ note?.category | sentenceCase }}</small></p>
                        <p class="mb-1">{{ note?.message }}</p>
                        <p *ngIf="note?.information?.referenceNumber" class="mb-1"><strong>Ref no: </strong> {{ note?.information?.referenceNumber }}</p>
                        <p *ngIf="note?.information?.claim" class="mb-1"><strong>Claim no: </strong> {{ note?.information?.claim }}</p>
                        <p *ngIf="note?.information?.quoteNumber" class="mb-1"><strong>Quote no: </strong> {{ note?.information?.quoteNumber }}</p>
                        <p *ngIf="note?.information?.policyNumber" class="mb-1"><strong>Policy no: </strong> {{ note?.information?.policyNumber }}</p>
                        <p class="mb-1"><strong>By: </strong> {{ note?.createdBy?.firstName }} {{ note?.createdBy?.lastName }}</p>

                        <ng-container *ngIf="note?.action !== 'POST'">
                        <div class="row mb-2" *ngFor="let item of note?.diff; let index = index">
                            <div class="col-12" *ngIf="index === 0">
                                <p><b>Changes:</b></p>
                            </div>
                            <div *ngIf="item?.changeFrom?.key" class="col-12 col-sm-6">
                                <p><u>{{ item?.changeFrom?.key | sentenceCase }}</u></p>
                                <p>{{ item?.changeFrom?.prop | sentenceCase }}</p>
                                <p><b>was: </b>{{ item?.changeFrom?.value }}</p>
                            </div>
                            <div class="col-12 col-sm-6">
                                <p class="d-none d-sm-block" *ngIf="item?.changeFrom?.key"><br /></p>
                                <p *ngIf="item?.changeTo?.prop"><br /></p>
                                <p><b *ngIf="item?.changeFrom?.key">is now: </b>{{ item?.changeTo?.value }}</p>
                            </div>
                        </div>
                        </ng-container>

                        <ng-container *ngIf="note?.action === 'POST'">
                            <div class="row mb-2" *ngFor="let item of note?.diff | keyvalue">
                                <div class="col-12">
                                    <p><u>{{ item?.key | sentenceCase }} details</u></p>
                                    <div *ngFor="let prop of item?.value?.changeTo | keyvalue">
                                        <span *ngIf="prop?.key !== '_id'"><strong>{{ prop?.key | sentenceCase }}: </strong></span>
                                        <span *ngIf="prop?.key !== '_id' && prop?.key !== 'emailBody'">{{ prop?.value }}</span>
                                        <span *ngIf="prop?.key == 'emailBody'" [innerHtml]="prop?.value"></span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                    <!-- Close div for .timeline-content -->

                </div>

            </div>
            <!-- Close div for .timeline-item -->

        </div>
        <!-- Close div for timeline-items -->

        <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="getActivityLogs()"></div>

        <div class="text-center mt-5">
            <p class="mb-0"><b>--- END OF NOTES ---</b></p>
        </div>

    </div>
    <!-- Close div for .timeline-v4 -->

    <ng-template #findingResults>
        <div id="loading">
        </div>
    </ng-template>
    

</div>
<!-- Close div for .panel-body -->