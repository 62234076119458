<h2 mat-dialog-title>Upload Document</h2>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="uploadDocumentFormGroup">

        <input class="mb-3" type="file" ng2FileSelect [uploader]="uploader" (change)="handleUploadedFile($event.target.files)" />

        <!-- <mat-form-field>
            <mat-label>Document type</mat-label>
            <mat-select formControlName="documentType" (selectionChange)="clearDocumentItem()">
              <mat-option value="general" (click)="uploadEmailDocument = false">General</mat-option>
              <mat-option value="instructions" (click)="uploadEmailDocument = false">Admin Instruction</mat-option>
              <mat-option value="claims" (click)="uploadEmailDocument = false">Claims</mat-option>
              <mat-option value="quotations" (click)="uploadEmailDocument = false">Quotation</mat-option>
              <mat-option value="policies" (click)="uploadEmailDocument = false">Policy schedule</mat-option>
              <mat-option value="email" (click)="uploadEmailDocument = true">Email</mat-option>
              <mat-option value="returnedDebit" (click)="uploadEmailDocument = false">Returned debit order</mat-option>
            </mat-select>
          </mat-form-field> -->

        <div class="row mt-3">
          <div class="col-12">
            <label for="accessDate">Document description:</label>
          </div>
          <div class="col-12">
            <textarea type="text" class="form-control" placeholder="Document description" formControlName="documentDescription"></textarea>
          </div>
        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-4 mb-1">
  <button class="btn btn-outline-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" (click)="uploadFile()" cdkFocusInitial>Upload</button>
</mat-dialog-actions>