import { BrokerCommercialClientDeleteComponent } from './delete-client/broker-commercial-client-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { CommercialClientsService } from './../../services/commercial-clients.service';
import { AuthenticationService } from './../../services/authentication.service';

import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_shared/models/User.model';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Globals } from 'src/app/app.globals';
import { BrokerComponent } from 'src/app/broker/broker.component';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DeleteClientComponent } from 'src/app/components/modal/delete-client/delete-client.component';
import { CommercialClient } from 'src/app/_shared/models/commercialClient.model';

@Component({
  selector: 'app-broker-commercial-clients',
  templateUrl: './broker-commercial-clients.component.html',
  styleUrls: ['./broker-commercial-clients.component.scss'],
  providers: [BrokerComponent, DeleteClientComponent]
})
export class BrokerCommercialClientsComponent implements OnInit {

  allUsers: any[];

  displayedColumns: string[] = ['companyName', 'clientNumber', 'regNumber',  'contact', 'email', 'language', 'status', 'view'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  userSearch:string = '';
  filterByStatus: string;

  clientStatus:string = this.activeRoute.snapshot.queryParams.status;

  writeEmail: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];
  currentCompany: string;

  constructor(
    public globals: Globals,
    private brokerService: BrokerService,
    private insuranceService: InsuranceService,
    private deleteClient: DeleteClientComponent,
    public brokerComponent: BrokerComponent,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private commercialClientService: CommercialClientsService,
    private dialog: MatDialog
  ) {
    this.currentCompany = this.authenticationService.currentCompany.companyName;
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.currentCompany).permissions
  }

  removeClient(client:CommercialClient){    
    const dialogRef = this.dialog.open(BrokerCommercialClientDeleteComponent, {width: '800px', data: client})
    dialogRef.afterClosed().subscribe(response => {
      if(response === 'success'){
        this.findAllClients(null, this.clientStatus);
      }
    })

  }

  findAllClients(searchParam?:string, status?:string, noLoad?:boolean){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam,
      status: status
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.commercialClientService.getAllClients(this.currentCompany, req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;
          this.filterByStatus = status;

          return data.body.user;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        data.forEach(user => {
          user.clientNumber = user.broker.find(broker => broker.companyName === this.brokerComponent.brokerCompany.companyName).clientNumber;
          return user
        })
        this.allUsers = data
      });
  }

  ngAfterViewInit() {
    this.findAllClients(null, this.clientStatus);
  }

  ngOnInit() {
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  getClientStatus(clientBroker){
    return clientBroker.find(broker => broker.companyName === this.currentCompany).clientStatus;
  }

}
