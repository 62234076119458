import { HttpResponse } from '@angular/common/http';
import { User } from 'src/app/_shared/models/User.model';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BrokerService } from 'src/app/services/broker.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { Globals } from 'src/app/app.globals';
import { BrokerComponent } from 'src/app/broker/broker.component';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DeleteClientComponent } from 'src/app/components/modal/delete-client/delete-client.component';
import { PolicyResponse } from 'src/app/_shared/models/policy.model';

import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-broker-client-policies',
  templateUrl: './broker-client-policies.component.html',
  styleUrls: ['./broker-client-policies.component.scss'],
  providers: [BrokerComponent, DeleteClientComponent]
})
export class BrokerClientPoliciesComponent implements OnInit {

  policies: any[];

  displayedColumns: string[] = ['policyNumber', 'policyHolder', 'status', 'inceptionDate', 'view'];
  resultsLength = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  userSearch:string = '';
  policySearchStatus: 'all'|'active'|'cancelled' = 'active';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public globals: Globals,
    private brokerService: BrokerService,
    private brokerComponent: BrokerComponent,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  findAllPolicies(status, searchParam?:string, noLoad?:boolean){
    this.isLoadingResults = true;
    this.policySearchStatus = status;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: this.paginator.pageIndex,
      limit: this.pageSize,
      search: searchParam || '',
      status
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          req['offset'] = this.paginator.pageIndex * this.paginator.pageSize; 
          req['limit'] = this.paginator.pageSize;
          return this.brokerService.getPolicies(this.brokerComponent.brokerCompany.companyName, req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          return data.body.policies;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        return this.policies = data;
      });
  }

  deactivatePolicy(policyId){
    this.brokerService.deactivatePolicy(policyId).subscribe(
      (response:HttpResponse<PolicyResponse>) => {
        this.policies.filter(policy => policy._id !== policyId)
      }
    )
  }

  ngAfterViewInit() {
    this.findAllPolicies('active');
  }

  ngOnInit() {
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

}

