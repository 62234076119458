<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">My investments</h5>
      <h6 class="d-inline-block mb-0">{{ investments?.length }} Total</h6>

      <div class="search d-inline-block ml-4" *ngIf='false'>
        <div class="input-group">
          <input placeholder="Search..." type="text" class="form-control" name="investment-search" />
          <div class="input-group-append">
            <div class="input-group-text"><i class="color-primary fi flaticon-zoom"></i></div>
          </div>
        </div> <!-- Close div for .input-group-->
      </div> <!-- Close div for .search -->

    </div>

    <!-- <div class="col-6 text-right align-self-center">
      <button mat-flat-button class="btn btn-outline" color="outline" (click)="location.back()">Back</button>

      <div class="toggle-buttons">
          <button mat-flat-button class="btn" (click)="updateUserInformation()">Save changes</button>
          <button mat-flat-button class="btn" [matMenuTriggerFor]="menu"><i class="fi fi-small flaticon-chevron-right fi-rotate-90"></i></button>
            <mat-menu xPosition="before" #menu="matMenu">
              <button mat-menu-item class="text-left">Save &amp; continue</button>
              <button mat-menu-item class="text-left">Save &amp; go back</button>
            </mat-menu>
      </div> <!-- Close div for .toggle-buttons ->  

    </div> -->
  </div>  
</div>

<div class="row" *ngIf="false">
  <div class="col-12 card border-none" *ngFor="let investment of investments">

    <div class="row">

      <div class="col-4">

          <h5>{{ investment?.type }}</h5>
          <h6>{{ investment?.investmentCompany }}</h6>

          <!-- <p>{{ investment | json }}</p> -->

      </div> <!-- Close div for .col-6 -->

      <div class="col-8">

        <div class="widget">
    
          <div class="widget-item">  
            <div class="widget-details">
              <p class="color-black font-weight-500 font-14 mb-2">Start date</p>
              <span class="btn btn-label-primary btn-sm btn-bold btn-upper">{{ investment?.investmentDate | date: 'dd MMM, yy' }}</span>
            </div> <!-- Close div for .widget-details -->
          </div> <!-- Close div for .widget-item -->

          <div class="widget-item" *ngIf="investment?.payoutDate">  
            <div class="widget-details">
              <p class="color-black font-weight-500 font-14 mb-2">Payout Date</p>
              <span class="btn btn-label-danger btn-sm btn-bold btn-upper">{{ investment?.payoutDate | date: 'dd MMM, yy' }}</span>
            </div> <!-- Close div for .widget-details -->
          </div> <!-- Close div for .widget-item -->
  
          <div class="widget-item" *ngIf="investment?.payoutDate">  
            <div class="widget-details">
              <p class="color-black font-weight-500 font-14 mb-2">Payout Date</p>
              <span class="btn btn-label-danger btn-sm btn-bold btn-upper">{{ investment?.payoutDate | date: 'dd MMM, yy' }}</span>
            </div> <!-- Close div for .widget-details -->
          </div> <!-- Close div for .widget-item -->
  
          <div class="widget-item flex-fill">
  
            <div class="widget-details">
  
              <p class="color-black font-weight-500 font-14 mb-2">Current value</p>
  
            </div> <!-- Close div for .widget-details -->
          </div> <!-- Close div for .widget-item -->
    
          </div> <!-- Close div for .widget -->
  
      </div> <!-- Close div for .col-6 -->
      <div class="widget">

          <div class="widget-bottom">

              <div class="widget-item">
                <div class="widget-icon">
      
                </div> <!-- Close div for .widget-icon -->
      
                <div class="widget-details">
      
                  <p><small class="color-dark-grey">Amount invested</small><br />
                    {{ investment?.investedAmount | currency: 'R' }}
                  </p>
      
                </div> <!-- Close div for .widget-details -->
              </div> <!-- Close div for .widget-item -->
      
              <div class="widget-item">
                <div class="widget-icon">
      
                </div> <!-- Close div for .widget-icon -->
      
                <div class="widget-details">
      
                  <p><small class="color-dark-grey">Growth</small><br />
                    {{ '??' }}
                  </p>
      
                </div> <!-- Close div for .widget-details -->
              </div> <!-- Close div for .widget-item -->
      
              <div class="widget-item">
                <div class="widget-icon">
      
                </div> <!-- Close div for .widget-icon -->
      
                <div class="widget-details">
      
                  <p><small class="color-dark-grey">Current value</small><br />
                    {{ investment.currentValue || investment.investedAmount | currency: 'R' }}
                  </p>
      
                </div> <!-- Close div for .widget-details -->
              </div> <!-- Close div for .widget-item -->
      
            </div> <!-- Close div for .widget-bottom -->

      </div> <!-- Close div for .widget -->

    </div> <!-- Close div for .row -->

  </div> <!-- Close div for .card -->
</div> <!-- Close div for .row -->

<div class="row">

  <div *ngFor="let investment of investments" class="col-12 mb-3">

    <div class="panel panel-primary">
      <div class="panel-heading heading-lg d-block">
        <small class="color-dark-grey">Investment type</small>
        <h5>{{ investment?.type }}</h5>
      </div>
      <div class="panel-body">

        <div class="row">

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Invested with</small><br />
              {{ investment?.investmentCompany }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Invesment date</small><br />
              {{ investment?.investmentDate | date: 'dd MMMM yyyy' }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Calulation period</small><br />
              {{ calculateCalculationDate(investment?.investmentDate, investment?.calculationPeriod) | date: 'dd MMMM yyyy' }}
            </p>
          </div>

          <div class="col6 col-sm-4 col-md-3">
            <p><small class="color-dark-grey">Amount invested</small><br />
              {{ investment?.investedAmount | currency: 'R' }}
            </p>
          </div>

        </div> <!-- Close div for .row -->

      </div>
    </div> <!-- Close div for .panel-->

  </div> <!-- Close div for .col-12 -->

</div>

