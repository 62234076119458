<div id="content" class="row">

  <div class="col-12 text-center">
      <img width="150" src="https://image.flaticon.com/icons/svg/1833/1833069.svg" />
      <p class="big">404</p>
      <p>Too bad.  We searched high and low, but could not find what you were looking for.</p>

      <button mat-flat-button color="white" class="btn mt-3 btn-white" [routerLink]="['/myAccount']" >Go back to the beginning</button>
  </div> <!-- Close div for .row -->

</div> <!-- Close div for .row -->