import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';

@Injectable({
  providedIn: 'root'
})
export class IdNumberVerifyService {

  constructor() {
  }

  // test 7206200189088

  // 1 - 6 = Date of birth
  // 7 = Gender identification (0 - 4 is female, 5 - 9 is male)
  // 8 - 10 = Sequential number (not used)
  // 11 = Citizensip (0 = SA, 1 = non SA, 2 = refugee)
  // 12 = race
  // 13 = verification number

  validateIdNumber = (id_number:string) => {

    let result = {
      dob: null,
      age: null,
      gender: null,
      citizenship: null,
      race: null,
      error: null,
      isValid: true
    };

    if(!id_number){
      result.error = "Please provide an ID number."
      result.isValid = false;
      return false;
    }

    /* DO ID LENGTH TEST */
    if(id_number && id_number.length < 13){
      result.error = "ID number too short."
      result.isValid = false;
      return false;
    }

    if(id_number && id_number.length > 13){
      result.error = "ID number too long."
      result.isValid = false;
      return false;
    }

    /* SPLIT ID INTO SECTIONS */
    // 8912045010087
    let year      	= id_number.substring(0, 2);
    let month     	= parseInt(id_number.substring(2, 4));
    let day       	= parseInt(id_number.substring(4, 6));
    let gender    	= parseInt(id_number.substring(6, 7));
    let citizen   	= parseInt(id_number.substring(10, 11));
    let race        = parseInt(id_number.substring(11, 12));
    let check_sum 	= parseInt(id_number.substring(12, 13))

    // DO YEAR TEST
    let nowYearNotCentury = JSON.stringify(new Date().getFullYear());
    nowYearNotCentury = nowYearNotCentury.substring(2, 4);
   
    if (year <= nowYearNotCentury){
      year = '20' + year;
    } else {
      year = '19' + year;
    }

    if (!(parseInt(year) > 1900) && !(parseInt(year) < new Date().getFullYear())){
      result.error = "Year could not be calculated."
      result.isValid = false;
      return result;
    }
    // END DO YEAR TEST

    // DO MONTH TEST
    if (month < 0 && month > 13){
      result.error = "Month could not be calculated."
      result.isValid = false;
      return result;
    }
    // END DO MONTH TEST

    // DO DAY TEST
      if (day < 0 && day > 32){
        result.error = "Day could not be calculated."
        result.isValid = false;
        return result;
      }
    // END DO DAY TEST

    result.dob = new Date(parseInt(year), month - 1, day);
    result.age = moment().diff(result.dob, 'years');

    // DO DATE TEST
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day == 31) {
      result.error = "Invalid date."
      result.isValid = false;
      return result;
    }
    if (month == 2) { // check for february 29th
      var isleapyear = (parseInt(year)% 4 == 0 && (parseInt(year) % 100 != 0 || parseInt(year) % 400 == 0));
      if (day > 29 || (day === 29 && !isleapyear)) {
        result.error = "Invalid date."
        result.isValid = false;
        return result;
      }
    }
    // END DATE TEST

    /* DO GENDER TEST */
    if(gender >= 0 && gender <= 4) result.gender = 'Female';
    else if (gender >= 5 && gender <= 9) result.gender = 'Male';
    else {
      result.isValid = false;
      result.error = 'Gender could not be determined.';
      return result;
    }
    // END GENDER TEST

    // DO CITIZEN TEST
    //08 or 09 SA citizen
    //18 or 19 Not SA citizen but with residence permit
    switch (citizen) {
      case 0:
        result.citizenship = 'SA Citizen';
        break;
      case 1:
        result.citizenship = 'Non-SA Citizen';
        break;
      case 2:
        result.citizenship = 'Refugee';
        break;
      default:
        result.isValid = false;
        result.error = 'Citizenship could not be determined';
        return result;
    }
    // END CITIZEN TEST

    // DO RACE TEST
    result.race = race === 8 ? "White" : (race === 9 ? "Black" : null)
    // END RACE TEST

    // GET CHECKSUM VALUE => http://geekswithblogs.net/willemf/archive/2005/10/30/58561.aspx
    const digits = id_number.replace(/\D/g, '').split('').map(d => Number(d));

    let odd = 0;
    let evenString = '';
    let even = 0;
    let total = 0;

    digits.map((digit, index) => {
      // Add all the digits in the odd positions (excluding last digit).
      if(index % 2 === 0 && index !== digits.length - 1){
        odd += digit;
      }
      // Move the even positions into a field and multiply the number by 2.
      if(index % 2 != 0){
        evenString += digit.toString()
      }

      if(index !== digits.length - 1){
        even = parseInt(evenString) * 2;

        // Add the digits of the result in b).
        even = even.toString().replace(/\D/g, '').split('').map(d => Number(d)).reduce((memo, d) => memo += d, 0);

        // Add the answer in [2] to the answer in [1].
        total = odd + even;

        // Subtract the second digit (of total) from 10.
        let totalLastDigit = total.toString().replace(/\D/g, '').split('').map(d => Number(d)).slice(-1)[0]
        if(totalLastDigit === 0){
          totalLastDigit = 10;
        }

        total = 10 - totalLastDigit;
      }
    })

    console.log(total)
    if(total != check_sum){
      result.isValid = false;
      result.error = 'Checksum failed.';
      return result;
    }

    return result

  }

}
