import { BrokerClaimResponse } from './../_shared/models/brokerClaims.model';
import { BrokerAdminInstructionResponse } from './../_shared/models/brokerAdminInstruction.model';
import { InvestmentResponse } from './../_shared/models/investment.model';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrokerDashboardService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getTotalInvestmentsCompany(company:string): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.get<InvestmentResponse>(`${this.globals.SERVER_URL}/api/reporting/totalCompanyInvestments/${company}`, {
      observe: 'response'
    })
  }

  getTotalInvestmentsBroker(brokerId:string): Observable<HttpResponse<InvestmentResponse>>{
    return this.http.get<InvestmentResponse>(`${this.globals.SERVER_URL}/api/reporting/totalInvestments/${brokerId}`, {
      observe: 'response'
    })
  }

  getTotalCompanyBrokerInstructions(company:string): Observable<HttpResponse<BrokerAdminInstructionResponse>>{
    return this.http.get<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/dashboard/totalInstructions/company/${company}`, {
      observe: 'response'
    })
  }

  getTotalUserInstructions(company:string, userId:string): Observable<HttpResponse<BrokerAdminInstructionResponse>>{
    return this.http.get<BrokerAdminInstructionResponse>(`${this.globals.SERVER_URL}/api/dashboard/totalInstructions/company/${company}/user/${userId}`, {
      observe: 'response'
    })
  }

  getTotalCompanyClaims(company:string): Observable<HttpResponse<BrokerClaimResponse>>{
    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/dashboard/totalClaims/company/${company}`, {
      observe: 'response'
    })
  }

  getTotalUserClaims(company:string, userId:string): Observable<HttpResponse<BrokerClaimResponse>>{
    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/dashboard/totalClaims/company/${company}/user/${userId}`, {
      observe: 'response'
    })
  }

  getTotalCompanyPolicies(company:string): Observable<HttpResponse<BrokerClaimResponse>>{
    return this.http.get<BrokerClaimResponse>(`${this.globals.SERVER_URL}/api/dashboard/totalPolicies/company/${company}`, {
      observe: 'response'
    })
  }

  getTotalUserPolicies(company:string, userId:string): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/dashboard/totalPolicies/company/${company}/user/${userId}`, {
      observe: 'response'
    })
  }

  getTotalCompanyQuotes(company:string): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/dashboard/totalQuotes/company/${company}`, {
      observe: 'response'
    })
  }

  getTotalUserQuotes(company:string, userId:string): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/dashboard/totalQuotes/company/${company}/user/${userId}`, {
      observe: 'response'
    })
  }

  getTotalCompanyLeads(company:string): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/dashboard/totalLeads/company/${company}`, {
      observe: 'response'
    })
  }

  getTotalUserLeads(company:string, userId: string): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.globals.SERVER_URL}/api/dashboard/totalLeads/company/${company}/user/${userId}`, {
      observe: 'response'
    })
  }

}
