import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/app/app.globals';
import { Observable } from 'rxjs';
import { NotificationResponse } from '../_shared/models/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    public globals: Globals
  ) { }

  getAllNotifications(): Observable<HttpResponse<NotificationResponse>> {
    return this.http.get<NotificationResponse>(`${this.globals.SERVER_URL}/api/notifications/all`, {
      observe: 'response'
    });
  }

  markNotifcationAsRead(notificationId:string): Observable<HttpResponse<NotificationResponse>> {
    return this.http.put<NotificationResponse>(`${this.globals.SERVER_URL}/api/notifications/notification/markAsRead/${notificationId}`, null, {
      observe: 'response'
    });
  }

}
