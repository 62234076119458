import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { Globals } from '../app.globals';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit {

  @Output() navToggle = new EventEmitter<boolean>();
  toggleNav() {
    this.navToggle.emit(true);
  }

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];

  constructor(
    public globals: Globals,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit() {
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

  canViewReports(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

}
