import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit {

  @Input() buttonText:string = "Edit"
  @Input() buttonColor:string = "primary"

  constructor() { }

  ngOnInit() {
  }

}
