<div id="navigation">

    <div class="navigation-surespace">

        <div class="text-center" id="logo">
            <!-- <h2 class="color-brand">SureSpace</h2> -->
            <img src="assets/images/full_logo_dark.png" class="img-fluid" />
        </div>

        <div id="main">

            <p>
                <span class="heading">Main</span>
            </p>

            <ul>

                <li [routerLink]="['/b/dashboard']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-dashboard"></i> Dashboard
                </li>

                <li [routerLink]="['/b/calendar']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-dashboard"></i> Calendar
                </li>

                <mat-expansion-panel *ngIf="currentUser.userType === 'admin'" (click)="toggleNav()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fi flaticon-management"></i> Management
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <li routerLink="management/console">
                        Console
                    </li>

                    <li routerLink="management/registered-users">
                        Registered users
                    </li>

                </mat-expansion-panel>

                <li routerLink="myAccount" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser?.userType !== 'broker'">
                    <i class="fi flaticon-account"></i> My account
                </li>
            </ul>

        </div> <!-- Close div for #main -->

        <div id="insurance" *ngIf="currentUser?.userType !== 'broker'">

            <p>
                <span class="heading">Insurance</span>
            </p>

            <ul>
                <li routerLink="shortTermInsurance" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-umbrella"></i> Short-term insurance
                </li>
                <li routerLink="investmentss" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-investments"></i> Investments
                </li>
                <!--<li routerLink="policies" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-briefcase"></i> Policies
                </li>
                <!-- <li routerLink="medicalAid" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-medical-aid"></i> Medical aid
                </li>
                <li routerLink="funeral" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-funeral"></i> Funeral
                </li> -->
            </ul>

        </div> <!-- Close div for #insurance -->

        <div *ngIf="currentUser?.userType === 'broker'" id="broker">
            
            <p><span class="heading">Clients</span></p>
            <ul>
                <li [routerLink]="['/b/clients/personal']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-account"></i> Personal
                </li>
                <!-- <li [routerLink]="['/b/clients/personal']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-account"></i> Personal lines
                </li> -->
                <li [routerLink]="['/b/clients/commercial']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-account"></i> Commercial
                </li>
            </ul>

            <ng-container *ngIf="canViewItem()">
              <p><span class="heading">Employees</span></p>

                <ul>
                    <li [routerLink]="['/b/employees']" routerLinkActive="active" (click)="toggleNav()">
                        <i class="fi flaticon-users"></i> Employees
                    </li>
                </ul>  
            </ng-container>
            

        </div>

        <div id="admin" *ngIf="currentUser?.userType === 'broker'">
            <p>
                <span class="heading">Admin</span>
            </p>

            <ul>
                <li *ngIf="canViewItem" [routerLink]="['/b/instructions']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-folder"></i>  Instructions
                </li>
                <li [routerLink]="['/b/clients/policies']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-file"></i> Policies
                </li>
                <li [routerLink]="['/b/claims']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-umbrella"></i> Claims
                </li>   
                <li [routerLink]="['/b/quotes']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-file"></i> Quotes
                </li>   
                <li [routerLink]="['/b/returned-debits']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-file"></i> Returned debits
                </li>   
            </ul>

        </div>

        <div id="insurance" *ngIf="currentUser?.userType === 'broker' && canViewItem">
                <p>
                    <span class="heading">Insurance</span>
                </p>
    
                <ul>
                    <li [routerLink]="['/b/administrators']" routerLinkActive="active" (click)="toggleNav()">
                        <i class="fi flaticon-briefcase"></i> Administrators
                    </li>
    
                    <li [routerLink]="['/b/underwriters']" routerLinkActive="active" (click)="toggleNav()">
                        <i class="fi flaticon-ribbon"></i> Underwriters
                    </li> 
                </ul>
    
            </div>

        <div id="reporting" *ngIf="canViewReports()">
            <p>
                <span class="heading">Reporting</span>
            </p>

            <ul>
                <!-- <li *ngIf="canViewItem" [routerLink]="['/b/reporting/activity-log']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-list"></i> Company notes
                </li> -->
                <li *ngIf="canViewItem" [routerLink]="['/b/reporting']" routerLinkActive="active" (click)="toggleNav()">
                    <i class="fi flaticon-list"></i> Reporting
                </li>
            </ul>

        </div>

        <div id="settings" *ngIf='false'>

            <p>
                <span class="heading">Settings</span>
            </p>

            <ul>

                <mat-accordion>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="fi flaticon-settings"></i> Settings
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <li routerLink="settings/reset-password">
                            Change my password
                        </li>
                        <li routerLink="settings/cancel-account">
                            Cancel my account
                        </li>

                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel *ngIf="currentUser?.userType !== 'broker'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="fi flaticon-helmet"></i> SureSpace
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <li routerLink="about">
                            About SureSpace
                        </li>
                        <li routerLink="suggestions">
                            Suggestions
                        </li>
                        <li routerLink="reportIssue">
                            Report an issue
                        </li>

                    </mat-expansion-panel>

                    <mat-expansion-panel *ngIf="currentUser?.userType !== 'broker'">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="fi flaticon-shield"></i> Insurance
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <li routerLink="insuredItemsOverview">
                            Insured items overview
                        </li>

                    </mat-expansion-panel> -->

                </mat-accordion>

            </ul>

        </div> <!-- Close div for #settings -->

    </div> <!-- close div for .navigation-surespace -->

</div> <!-- Close div for #navigation -->
