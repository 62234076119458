<div id="content" class="row">

    <sub-navigation heading="Reports"></sub-navigation>
  
    <div class="col-12">
  
      <div class="panel panel-primary">
  
        <div class="panel-heading">
          <h5>Quote report</h5>
        </div>
  
        <div class="panel-body">

            <div class="row form-group" [formGroup]="quoteFilters">

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="">Created</label>
                    <mat-select id="timePeriod" class="form-control" formControlName="timePeriod" (selectionChange)="createDate($event)">
                        <mat-option value="0">Today</mat-option>
                        <mat-option value="1">Yesterday</mat-option>
                        <mat-option value="7">Last 7 days</mat-option>
                        <mat-option value="30">Last 30 days</mat-option>
                        <mat-option value="365">Last year</mat-option>
                        <mat-option value="">All time</mat-option>
                        <mat-option class="custom" value="custom" disabled>{{ quoteFilters.get('dateFrom').value | date: 'dd/MM/yyyy' }} - {{ quoteFilters.get('dateTo').value | date: 'dd/MM/yyyy' }}</mat-option>
                    </mat-select>

                    <mat-form-field id="datepicker" appearance="fill">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" formControlName="dateFrom">
                          <input matEndDate placeholder="End date" formControlName="dateTo">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <small class="link py-2" (click)="picker.open(); quoteFilters.get('timePeriod').setValue('custom')">Select custom date range</small>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="status">Status</label>
                    <mat-select class="form-control" formControlName="status">
                        <mat-option value="">All quotes</mat-option>
                        <mat-option value="accepted">Accepted quotes</mat-option>
                        <mat-option value="rejected">Rejected quotes</mat-option>
                        <mat-option value="sent">Sent quotes</mat-option>
                    </mat-select>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2 form-group">
                    <label for="employee">Employee</label>
                    <input type="text"
                        class="form-control"
                        placeholder="Employee"
                        formControlName="employee"
                        [matAutocomplete]="employee">
                    <mat-autocomplete #employee="matAutocomplete" [displayWith]="displayEmployeeName" (optionSelected)="mapEmployee($event)">
                    <mat-option [value]="{_id: 0}">
                        All employees
                    </mat-option>
                    <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                        {{ employee?.firstName }} {{ employee?.lastName }}
                    </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-auto">
                    <button (click)="getQuoteReport()" class="btn btn-primary">Apply filters</button>
                </div>

            </div>

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <div class="row">

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ quoteTotals?.totalQuotes || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Total Quotes</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ quoteTotals?.totalAcceptedQuotes || "0"}}</h4>
                                    <small class="my-0" style="opacity: .8">Accepted quotes</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ quoteTotals?.totalRejectedQuotes || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Rejected quotes</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-3 col-sm-3 col-6">
                    <div class="panel panel-secondary pt-4">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-6 col-sm-12 col-md-12 col-lg-6">
                                    <h4 class="color-darker-grey font-weight-500">{{ quoteTotals?.totalCommercialQuotes || "0" }}</h4>
                                    <small class="my-0" style="opacity: .8">Commercial client quotes</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            
            </div> <!-- Close div for .row -->

        </div> <!-- Close div for panel-body -->

        <div class="panel-body">

            <table mat-table [dataSource]="employeesQuotes" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

                <!-- Client Column -->
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>Employee</th>
                    <td mat-cell *matCellDef="let row" data-label="Employee">{{ row?.firstName }} {{ row?.lastName }}</td>
                </ng-container>

                <!-- Client Number Column -->
                <ng-container matColumnDef="totalQuotes">
                    <th mat-header-cell *matHeaderCellDef>Total quotes</th>
                    <td mat-cell *matCellDef="let row" data-label="Total quotes">{{ row?.totalQuotes || "0"}}</td>
                </ng-container>

                 <!-- Reference Number Column -->
                 <ng-container matColumnDef="acceptedQuotes">
                    <th mat-header-cell *matHeaderCellDef>Accepted quotes</th>
                    <td mat-cell *matCellDef="let row" data-label="Active quotes">{{ row?.totalAcceptedQuotes || "0" }}</td>
                </ng-container>

                <ng-container matColumnDef="rejectedQuotes">
                    <th mat-header-cell *matHeaderCellDef>Rejected quotes</th>
                    <td mat-cell *matCellDef="let row" data-label="Total personal clients">{{ row?.totalRejectedQuotes || '0' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <!-- <mat-paginator [hidden]="quotes?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator> -->

        </div> <!-- Close div for panel-body -->

    </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for content-->