<div id="content" class="row">

    <sub-navigation heading="User management"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">

            <div class="panel-heading">

              <div class="row w-100">

                <div class="col-12 col-sm-6">
                  <h5>Clients</h5>
                </div>

                <div class="col-12 col-sm-6 text-left text-sm-right">
                  <!-- <app-add-button (click)="brokerComponent.toggleSendingEmail()" class="my-auto" buttonText="New email" matTooltip="Send an email to client"></app-add-button> -->
                  <app-add-button
                    *ngIf="canViewItem"
                    class="my-auto"
                    [routerLink]="['new']"
                    buttonText="Add client"
                    matTooltip="Add a new client">
                  </app-add-button>
                </div>

              </div>

            </div>

            <div class="panel-body">

              <div class="mb-5 col-md-4 col-sm-6 col-6">
                <mat-form-field>
                  <input matInput placeholder="Search" name="policyNumber" type="text" [(ngModel)]="userSearch" (ngModelChange)="findAllClients($event, null, true)"/>
                  <mat-hint>
                    Search in all fields.
                  </mat-hint>
                </mat-form-field>
              </div>

              <div class="col-md-8 col-sm-6 col-6 d-inline">
                <p class="d-inline-block mr-3"><strong>Filter by role</strong></p>
                <button class="btn btn-sm btn-pill" (click)="findAllClients()" [ngClass]="!filterByStatus ? 'btn-info' : 'btn-label-info'">All</button>
                <button class="btn btn-sm btn-pill" (click)="findAllClients(null, 'lead')" [ngClass]="filterByStatus == 'Lead' ? 'btn-info' : 'btn-label-info'">Lead</button>
                <button class="btn btn-sm btn-pill" (click)="findAllClients(null, 'quote')" [ngClass]="filterByStatus == 'Quote' ? 'btn-info' : 'btn-label-info'">Quote</button>
                <button class="btn btn-sm btn-pill" (click)="findAllClients(null, 'active')" [ngClass]="filterByStatus == 'Active' ? 'btn-info' : 'btn-label-info'">Active</button>
                <button class="btn btn-sm btn-pill" (click)="findAllClients(null, 'quote_cancelled')" [ngClass]="filterByStatus == 'Active' ? 'btn-info' : 'btn-label-info'">Quote cancelled</button>
                <button class="btn btn-sm btn-pill" (click)="findAllClients(null, 'cancelled')" [ngClass]="filterByStatus == 'Concelled' ? 'btn-info' : 'btn-label-info'">Cancelled</button>
              </div>

              <table mat-table [dataSource]="allUsers" matSort matSortActive="created" matSortDisableClear
                matSortDirection="desc">

                <!-- ID Number Column -->
                <ng-container matColumnDef="companyName">
                  <th mat-header-cell *matHeaderCellDef>Company name</th>
                  <td mat-cell *matCellDef="let row" data-label="Company name">{{row?.companyName}}</td>
                </ng-container>

                <!-- Client Column -->
                <ng-container matColumnDef="clientNumber">
                  <th mat-header-cell *matHeaderCellDef>Client number</th>
                  <td mat-cell *matCellDef="let row" data-label="Client number">{{ row?.clientNumber}}</td>
                </ng-container>

                <ng-container matColumnDef="regNumber">
                  <th mat-header-cell *matHeaderCellDef>Registration number</th>
                  <td mat-cell *matCellDef="let row" data-label="Registration number">{{row?.companyRegistrationNumber}}</td>
                </ng-container>

                <!-- Surname Column -->
                <ng-container matColumnDef="contact">
                  <th mat-header-cell *matHeaderCellDef>Contact</th>
                  <td mat-cell *matCellDef="let row" data-label="Contact">{{row?.contactPersonName}} {{row?.contactPersonSurname}}</td>
                </ng-container>

                <!-- Surname Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell *matCellDef="let row" data-label="Email">
                    <a (click)="brokerComponent.toggleSendingEmail(row?.email)">{{ row?.email }}</a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="language">
                  <th mat-header-cell *matHeaderCellDef>Language</th>
                  <td mat-cell *matCellDef="let row" data-label="Language">{{row?.language}}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let row" data-label="Status">
                    {{ getClientStatus(row?.broker) | sentenceCase }}
                  </td>
                </ng-container>

                <!-- View Column -->
                <ng-container matColumnDef="view">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row" data-label="Actions">
                      <!-- <button class="btn btn-light btn-pill color-brand" matTooltip="View client" [routerLink]="['view', row?._id]"><i class="fi flaticon-edit mr-0"></i></button> -->
                      <button *ngIf="canViewItem" class="btn btn-light btn-pill color-brand" matTooltip="Edit client" [routerLink]="['edit', row?._id]"><i class="fi flaticon-edit mr-0"></i></button>
                      <button *ngIf="currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')" class="btn btn-light btn-pill color-danger" matTooltip="Remove client" (click)="removeClient(row)"><i class="fi flaticon-trash mr-0"></i></button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <p class="mt-3 text-center" *ngIf="allUsers?.length === 0">No clients found</p>

              <mat-paginator [hidden]="allUsers?.length === 0" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

            </div> <!-- Close div for .panel-body -->

          </div> <!-- Close div for .panel -->

    </div> <!-- Close div for .col-12 -->

  </div> <!-- Close div for #content -->