import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TrailersAndCaravans, TrailersAndCaravansResponse } from 'src/app/_shared/models/trailersAndCaravans.model';
import { HttpResponse } from '@angular/common/http';
import { TrailersAndCaravansService } from 'src/app/services/trailers-and-caravans.service';

@Component({
  selector: 'select-new-policy-items-trailers-and-caravans',
  templateUrl: './select-new-policy-items-trailers-and-caravans.component.html',
  styleUrls: ['./select-new-policy-items-trailers-and-caravans.component.scss']
})
export class SelectNewPolicyItemsTrailersAndCaravansComponent implements OnInit, OnChanges {

  @Input() clientId:string;
  @Input() companyName:string;
  @Input() selection;

  displayedColumns: string[] = ['select', 'type', 'make', 'model', 'registrationNumber'];
  policyItems = new MatTableDataSource<TrailersAndCaravans>();

  constructor(
    private trailersAndCaravanService: TrailersAndCaravansService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientTrailersAndCaravans()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.policyItems.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.policyItems.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getClientTrailersAndCaravans(){
    this.trailersAndCaravanService.getAllTrailersAndCaravansInsuranceForClient(this.clientId, this.companyName).subscribe(
      (response:HttpResponse<TrailersAndCaravansResponse>) => {
        this.policyItems = new MatTableDataSource<TrailersAndCaravans>(response.body.trailerAndCaravan);
      }
    )
  }

}
