<div class="page-description">
    <div class="row h-100">
        <div class="col-6 align-self-center">
            <h5 class="d-inline-block mb-0 font-weight-400">User notifications</h5>
            <h6 class="d-inline-block mb-0">{{ newNotifications }} New</h6>
        </div>

        <div class="col-6 text-right align-self-center">
            <button class="btn btn-link">Notification settings</button>
        </div>
    </div>
</div>

<div class="container mt-3">

    <div class="row">

        <div class="col-12" *ngIf="notifications?.length === 0">
        <p class="text-center">The notifications you are trying to reach is not available at present, please try again later.</p>
        </div>

        <div class="col-12 notification" *ngFor="let notification of notifications" [ngClass]="{'unread': !notification.isSeen}" (click)="markNotificationAsRead(notification)">

            <span class="d-flex my-auto">
                <div class="d-inline-block h-100 my-auto mr-3">
                    <span class='icon'><i class="fi mr-0 flaticon-bell fi-bold"></i></span>
                </div>
                <div class="d-inline-block">
                    <span class='heading'>{{ notification?.notification }}</span>
                    <span class='description'>{{ notification?.dateAdded | date: 'dd MMM yyyy - HH:mm' }}</span>
                </div>
            </span>

        </div> <!-- Close div for .col-12 -->

    </div> <!-- Close div for .row -->

</div> <!-- Close div for .container -->
