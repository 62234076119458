import { Policy, PolicyResponse } from './../../../_shared/models/policy.model';
import { HttpResponse } from '@angular/common/http';
import { BrokerService } from 'src/app/services/broker.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { ReturnedDebitOrders } from 'src/app/_shared/models/returnedDebitOrders.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'returned-debit-order-form-group',
  templateUrl: './returned-debit-order-form-group.component.html',
  styleUrls: ['./returned-debit-order-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReturnedDebitOrderFormGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReturnedDebitOrderFormGroupComponent),
      multi: true
    }
  ]
})
export class ReturnedDebitOrderFormGroupComponent implements OnInit, OnDestroy {

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];
  currentCompany: string = this.authenticationService.currentCompany.companyName;
  inEditMode: boolean = false;

  returnedDebitFormGroup: FormGroup;
  subscriptions: Subscription[] = [];

  loadingResults:boolean = true;

  clients: User[];
  employees: Employee[];
  policies: Policy[];

  maxDate: Date;

  get returnedDebitValue(): ReturnedDebitOrders {
    return this.returnedDebitFormGroup.value;
  }

  set returnedDebitValue(value: ReturnedDebitOrders) {
    this.returnedDebitFormGroup.patchValue(value);
    this.clientSelected(value.client);
    this.inEditMode = true;

    this.onChange(value);
    this.onTouched();
  }

  get clientControl() {
    return this.returnedDebitFormGroup.controls.client;
  }

  get policyControl() {
    return this.returnedDebitFormGroup.controls.policy;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.currentCompany).permissions
  }

  ngOnInit(): void {

    this.returnedDebitFormGroup = this.formBuilder.group({
      _id: '',
      client: [null, Validators.required],
      returnedDebitDate: [null, Validators.required],
      returnedDebitSteps: this.formBuilder.group({
        receivedReturnedDebitFromInsuranceCompany: this.formBuilder.group({ value: true, completedBy: {}, dateCompleted: null }),
        communicateReturnedDebitToClient: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        clientRequestForcedDebit: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        clientRequestDoublePayment: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        savedClientRequestToSureSpace: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        forcedDebitDate: this.formBuilder.group({ value: null, completedBy: {}, dateCompleted: null }),
        confirmedPaymentReceived: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        returnedDebitFinalised: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null }),
        returnedDebitSignedOff: this.formBuilder.group({ value: false, completedBy: {}, dateCompleted: null })
      }),
      employee: [null, Validators.required],
      policyNumber: [{ value: null, disabled: true }, Validators.required],
      currentStep: ['']
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.returnedDebitFormGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // GET CLIENTS
    this.returnedDebitFormGroup.get('client').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<UserResponse>) => {
        this.clients = response.body.user;
      }
    )

    // GET EMPLOYEES
    this.returnedDebitFormGroup.get('employee').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.currentCompany, { search: value }, true)
      .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

    this.maxDate = new Date();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.returnedDebitValue = value;
    }

    if (value === null) {
      this.returnedDebitFormGroup.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    // THIS KEEPS RETURNING INVALID
    // return this.returnedDebitFormGroup.valid ? null : { returnedDebitOrder: { valid: false } };
  }

  displayClient(client?: User): string | undefined {
    if(client?.lastName){
      return `${client.firstName} ${client.lastName}`
    } else if(client?.companyName){
      return `${client?.companyName}`
    }
    return undefined;
  }

  displayPolicy(policy?: Policy): string | undefined {
    console.log(policy)
    return policy ? `${policy.policyNumber}` : undefined;
  }

  canCompleteReturnedDebit(){
    return this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerDirector')
  }

  displayCompletedBy(step){
    return this.returnedDebitFormGroup?.get('returnedDebitSteps')?.get(step)?.get('completedBy').value?.brokerId;
  }

  getStepCompletedBy(step: string){
    let stepFormControl = this.returnedDebitFormGroup.get('returnedDebitSteps').get(step);
    return `${stepFormControl?.get('completedBy')?.value?.firstName} ${stepFormControl?.get('completedBy')?.value?.lastName} on ${formatDate(stepFormControl.get('dateCompleted').value, "dd MMM yyyy 'at' HH:mm", 'en')}`;
  }

  clientSelected(client){
    this.returnedDebitFormGroup.get('policyNumber').enable();
    this.getClientPolicies(client._id);

    // GET POLICY
    this.returnedDebitFormGroup.get('policyNumber').valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loadingResults = true),
      switchMap(value => this.brokerService.getPoliciesPerUser(this.currentCompany, client._id, { search: value, active: true }, true)
        .pipe(finalize(() => this.loadingResults = false))
      )
    )
    .subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;
      }
    )
  }

  getClientPolicies(clientId: string){
    this.brokerService.getPoliciesPerUser(this.currentCompany, clientId, { active: true }).subscribe(response => {
      this.policies = response.body.policies
    })
  }

}
