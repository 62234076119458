<div id="content" class="row">

    <sub-navigation heading="{{ editProduct ? 'Edit' : 'Add new' }} commercial product"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="productFromGroup" [formGroup]="productFromGroup">

                <div class="panel-heading">
                    <h5>Commercial product information</h5>

                    <div class="my-auto">

                        <button matTooltip="Go back to previous page" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>

                        <div class="btn-group" ngbDropdown *ngIf="!view && canViewItem">
                            <button type="button" class="btn btn-primary" mat-tooltip="Save product and go back." (click)="editProduct ? updateProduct('back') : addNewProduct('back')">
                  Submit
                </button>
                            <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                </button>
                            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><a class="dropdown-item" (click)="editProduct ? updateProduct('continue') : addNewProduct('continue')">Save &amp; continue</a>
                                </li>
                                <li role="menuitem"><a class="dropdown-item" (click)="editProduct ? updateProduct('back') : addNewProduct('back')">Save &amp; go back</a></li>
                            </ul>
                        </div>
                        <!-- Close div for .btn-group -->

                    </div>
                    <!-- Close div for .my-auto -->

                </div>

                <div class="panel-body">

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <div class="input-group">
                                <mat-form-field [ngClass]="{ 'is-invalid': formSubmitted && productForm['productType'].errors }">
                                    <mat-select placeholder="Product type" name="productType" formControlName="productType">
                                        <mat-option value="accidentalDamage">Accidental damage</mat-option>
                                        <mat-option value="accountsReceivable">Accounts receivable</mat-option>
                                        <mat-option value="buildingCombined">Building combined</mat-option>
                                        <mat-option value="businessAllRisk">Business all risk</mat-option>
                                        <mat-option value="businessInterruption">Business interruption</mat-option>
                                        <mat-option value="deteriorationOfStock">Deterioration of stock</mat-option>
                                        <mat-option value="electronicEquipment">Electronic equipment</mat-option>
                                        <mat-option value="employersLiability">Employers liability</mat-option>
                                        <mat-option value="extendedPersonalLegalLiability">Extended personal legal liability</mat-option>
                                        <mat-option value="externalRisk">External Risk</mat-option>
                                        <mat-option value="fidelityGuarantee">Fidelity guarantee</mat-option>
                                        <mat-option value="fireCol1">Fire Col 1</mat-option>
                                        <mat-option value="fireCol2">Fire Col 2</mat-option>
                                        <mat-option value="fireCol3">Fire Col 3</mat-option>
                                        <mat-option value="fireCol4">Fire Col 4</mat-option>
                                        <mat-option value="fireCol5">Fire Col 5</mat-option>
                                        <mat-option value="glass">Glass</mat-option>
                                        <mat-option value="goodsInTransit">Goods in Transit</mat-option>
                                        <mat-option value="greensAndIrrigationSystems">Greens and Irrigation Systems</mat-option>
                                        <mat-option value="groupPersonalAccident">Group Personal Accident</mat-option>
                                        <mat-option value="HCV">HCV</mat-option>
                                        <mat-option value="houseHolders">House holders</mat-option>
                                        <mat-option value="houseOwner">House owner</mat-option>
                                        <mat-option value="internalRisk">Internal risk</mat-option>
                                        <mat-option value="machineryBreakdown">Machinery breakdown</mat-option>
                                        <mat-option value="machineryBreakdownBusinessInterrupt">Machinery breakdown – business interruption</mat-option>
                                        <mat-option value="money">Money</mat-option>
                                        <mat-option value="motor">Motor</mat-option>
                                        <mat-option value="motorTradersExternalRisk">Motor traders external risk</mat-option>
                                        <mat-option value="motorTradersInternalRisk">Motor traders internal risk</mat-option>
                                        <mat-option value="officeContent">Office contents</mat-option>
                                        <mat-option value="personalAllRisk">Personal all risk</mat-option>
                                        <mat-option value="personalLegalLiability">Personal legal liability</mat-option>
                                        <mat-option value="publicLiability">Public liability</mat-option>
                                        <mat-option value="riskAddress">Risk Address</mat-option>
                                        <mat-option value="statedBenefits">Stated benefits</mat-option>
                                        <mat-option value="theft">Theft</mat-option>
                                        <mat-option value="trailer">Trailer</mat-option>
                                        <mat-option value="vapExcessProtect">VAP – excess protect</mat-option> 
                                        <mat-option value="vapPotholeProtect">VAP – pothole protect</mat-option>
                                        <mat-option value="vapRoadsideAssist">VAP – roadside assist</mat-option>
                                        <mat-option value="vapTopUpProtect">VAP - top up protect</mat-option>
                                        <mat-option value="vapTrackingDevice">VAP - tracking device</mat-option>
                                        <mat-option value="vapeTyreProtect">VAP – tyre protect</mat-option>
                                        <mat-option value="waterCraft">Water craft</mat-option>
                                    </mat-select>

                                    <mat-error>
                                        Please select a <strong>product type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <span matPrefix>R</span>
                                <input matInput type="text" placeholder="Product value" name="productValue" formControlName="productValue" />
                                <mat-error>
                                    Please enter a <strong>value</strong> for this product.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <textarea matInput class="form-control w-100" placeholder="Description" name="description" formControlName="description"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->
                </div>
                <!-- Close div for .panel-body -->

                <div class="panel-heading mt-4">
                    <h5>Risk Address</h5>
                </div>

                <div class="panel-body" formGroupName="riskAddress">

                    <div class="row">

                        <div class="col-12">
                            <mat-form-field>
                                <input
                                    ngx-google-places-autocomplete
                                    matInput
                                    #placesRef="ngx-places"
                                    (onAddressChange)="handleAddressChange($event)"
                                    formControlName="addressLine1"
                                    [options]="addressOptions"
                                    placeholder="Address"
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="suburb"
                                    formControlName="suburb"
                                    placeholder="Suburb"
                                >
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="city"
                                    formControlName="city"
                                    placeholder="City"
                                > 
                            </mat-form-field>
                            
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="postalCode"
                                    formControlName="postalCode"
                                    placeholder="Postal Code"
                                >
                            </mat-form-field>
                        </div>

                    </div>

                </div>

                <ng-container *ngIf="!isQuoteProduct">

                    <div class="panel-heading mt-4">
                        <h5>Insurance</h5>
                    </div>
    
                    <div class="panel-body" formGroupName="insurance">
    
                        <div class="row">
    
                            <div class="col-sm-6 col-12">
                                <mat-form-field>
                                    <input matInput type="number" placeholder="Premium" name="premium" formControlName="premium" />
                                    <span matPrefix>R</span>
                                    <mat-error>
                                        Please provide the <strong>monthly premium</strong> of the product.
                                    </mat-error>
                                </mat-form-field>
                            </div>
    
                            <div class="col-12 clearfix mt-3 d-sm-none d-block">
                            </div>
    
                            <div class="col-sm-6 col-12">
    
                                <div class="input-group">
                                    <mat-form-field>
                                        <mat-select placeholder="Policy number" formControlName="policyNumber">
                                          <mat-option disabled *ngIf="policies?.length === 0">
                                            <span>No policy numbers found</span>
                                        </mat-option>
                                        <mat-option class="px-0 py-0" style="height: unset" *ngIf="policies?.length === 0">
                                            <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                                        </mat-option>
                                          <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                            <span>{{ policy?.policyNumber }}</span>
                                        </mat-option>
                                        </mat-select>
          
                                        <mat-error>
                                          Please select a <strong>policy number</strong> for this product.
                                        </mat-error>
          
                                      </mat-form-field>
                                </div>
                                <!-- Close div for .input-group -->
                            </div>
    
                        </div>
                        <!-- Close div for .row -->
    
                        <div class="col-12 clearfix mt-3">
                        </div>
    
                        <div class="row">
    
                            <div class="col-sm-6 col-12">
    
                                <mat-form-field>
                                    <input 
                                        matInput
                                        name="inceptionDate"
                                        [matDatepicker]="inceptionDate"
                                        name="inceptionDate"
                                        formControlName="inceptionDate"
                                        placeholder="Inception date"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #inceptionDate></mat-datepicker>
            
                                    <mat-error>
                                        Please provide the <strong>inception date</strong> for this product.
                                    </mat-error>
            
                                </mat-form-field>
    
                            </div>
    
                        </div>
                        <!-- Close div for .row -->
    
                    </div>
                    <!-- Close div for .panel-body-->

                </ng-container>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->