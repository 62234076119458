<h2 mat-dialog-title>Create manual note</h2>
<mat-dialog-content class="mat-typography">

    <form [formGroup]="clientNoteFormGroup">

        <div class="row mt-3">
          <div class="col-12">
            <label for="message">Note message:</label>
          </div>
          <div class="col-12">
            <textarea type="text" class="form-control" placeholder="Please provide a note message" formControlName="message"></textarea>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <label for="message">What is this note in reference to:</label>
          </div>
          <div class="col-12">          
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [(ngModel)]="noteReference"
              [ngModelOptions]="{ standalone: true }"
              (change)="selectNoteReference($event)"
            >
              <mat-radio-button class="mr-3" value="policy">Policy</mat-radio-button>
              <mat-radio-button class="mr-3" value="instruction">Instruction</mat-radio-button>
              <mat-radio-button class="mr-3" value="claim">Claim</mat-radio-button>
              <mat-radio-button class="mr-3" value="quote">Quote</mat-radio-button>
              <mat-radio-button value="returnedDebit">Returned debit</mat-radio-button>
            </mat-radio-group>
          
          </div>
        </div>

        <div class="row mt-3" *ngIf="noteReference !== ''">

          <div class="col-12">
            <label for="message">Please select a {{ noteReference | sentenceCase }}</label>
          </div>

          <div class="col-12 col-sm-6" *ngIf="noteReference === 'policy'">
            <mat-form-field>
              <mat-label>Policy</mat-label>
              <mat-select formControlName="policy" (selectionChange)="setPolicyDetails($event)">
                <mat-option disabled *ngIf="policies?.length === 0">No policies found</mat-option>
                <mat-option [value]="null">No policy selected</mat-option>
                <mat-option *ngFor="let policy of policies" [value]="policy?.policyNumber">
                  Policy {{ policy.policyNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" *ngIf="noteReference === 'instruction'">
            <mat-form-field>
              <mat-label>Instruction</mat-label>
              <mat-select formControlName="instruction" (selectionChange)="setInstructionDetails($event)">
                <mat-option disabled *ngIf="instructions?.length === 0">No instructions found</mat-option>
                <mat-option [value]="null">No Instruction selected</mat-option>
                <mat-option *ngFor="let instruction of instructions" [value]="instruction?.referenceNumber">
                  {{ instruction?.referenceNumber }} - {{ instruction?.product?.description || instruction?.instructionDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" *ngIf="noteReference === 'claim'">
            <mat-form-field>
              <mat-label>Claim</mat-label>
              <mat-select formControlName="claim" (selectionChange)="setClaimDetails($event)">
                <mat-option disabled *ngIf="claims?.length === 0">No claims found</mat-option>
                <mat-option [value]="null">No claim selected</mat-option>
                <mat-option *ngFor="let claim of claims" [value]="claim?.referenceNumber">
                  {{ claim?.referenceNumber }} - {{ claim?.product?.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" *ngIf="noteReference === 'quote'">
            <mat-form-field>
              <mat-label>Quote</mat-label>
              <mat-select formControlName="quote" (selectionChange)="setQuoteDetails($event)">
                <mat-option disabled *ngIf="quotes?.length === 0">No quotes found</mat-option>
                <mat-option [value]="null">No quote selected</mat-option>
                <mat-option *ngFor="let quote of quotes" [value]="quote?.quoteNumber">
                  {{ quote?.quoteNumber }} - {{ quote?.client?.lastName || quote?.client?.companyName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-6" *ngIf="noteReference === 'returnedDebit'">
            <mat-form-field>
              <mat-label>Returned debit</mat-label>
              <mat-select formControlName="returnedDebit" (selectionChange)="setReturnedDebitDetails($event)">
                <mat-option disabled *ngIf="returnedDebits?.length === 0">No returned debits found</mat-option>
                <mat-option [value]="null">No returned debit selected</mat-option>
                <mat-option *ngFor="let returnedDebit of returnedDebits" [value]="returnedDebit?.referenceNumber">
                  {{ returnedDebit?.referenceNumber }} - {{ returnedDebit?.policyNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-4 mb-1">
  <button class="btn btn-outline-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" (click)="createManualNote()" cdkFocusInitial>Add note</button>
</mat-dialog-actions>