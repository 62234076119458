import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestmentService } from 'src/app/services/investment.service';
import { Investment, InvestmentResponse } from 'src/app/_shared/models/investment.model';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';

@Component({
  selector: 'app-edit-client-investment',
  templateUrl: './edit-client-investment.component.html',
  styleUrls: ['./edit-client-investment.component.scss']
})
export class EditClientInvestmentComponent implements OnInit {

    // FILE UPLOADER SETTINGS
    options: UploaderOptions;
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
  

  user = this.activeRoute.snapshot.paramMap.get('id');
  currentInvestment:string = this.activeRoute.snapshot.paramMap.get('i');

  investment:Investment[]
  investmentType:string[] = [
    "Retirement Annuity"
  ];

  today:Date = new Date();

  investmentForm: FormGroup;
  submittedForm = false;
  get form(){ return this.investmentForm.controls; }

  constructor(
    private investmentService: InvestmentService,
    private formBuilder: FormBuilder,
    public location: Location,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.options = { concurrency: 1, allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

  }

  previewImage(file: any) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function(e: any) {
        resolve(e.target.result);
      };
    });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':      
        if (typeof output.file !== 'undefined') {
          this.previewImage(output.file).then(response => {
            let file: any = Object.assign(output.file, { imagePreview: response });
            this.files.push(file);
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        if(output.file.responseStatus === 200){
          //The process was successful
          this.router.navigate(['/personalInsurance']);
        } else {
          //error here
        }
        break;
    }
  }

  getInvestmentTypes() {
    // TODO: Get all the types of investments
  }

  resetForm(){
    this.investmentForm.reset();
  }

  getCurrentInvestment(){
      this.investmentService.getCurrentInvestment(this.currentInvestment).subscribe(
        (response:HttpResponse<InvestmentResponse>) => {
          this.investment = response.body.investment;
          this.investmentForm.patchValue(this.investment)
        }
      )
  }

  saveInvestment(saveType?:string){
    this.investmentService.saveInvestment(this.user, this.investmentForm.value).subscribe(
      (response:HttpResponse<InvestmentResponse>) => {

        switch(saveType){
          case 'back':
            this.location.back();
            break;

          case 'continue':
            this.resetForm()
            break;

          default:
            this.location.back();
            break;
        }

      }
    )
  }

  ngOnInit() {
    this.investmentForm = this.formBuilder.group({
      _id: [''],
      type: ['', Validators.required],
      policyNumber: ['', Validators.required],
      investmentCompany: ['', Validators.required],
      investedAmount: ['', Validators.required],
      investmentDate: ['', Validators.required],
      calculationPeriod: ['', Validators.required],
      payoutDate: [''],
      note: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.getCurrentInvestment()
  }

}

