<div id="login-page" class="container">
  <div class="row h-100">

    <div id="login-body" class="align-self-center col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-sm-12 col-12">
      
      <div class="row">

          <div class="col-12 text-center mb-4">
            <img src="assets/images/logoicon.png" height="100" />
          </div>

          <div class="col-12 text-center">
            <h1 class="welcome-text font-weight-100">Welcome to SureSpace</h1>
          </div>

          <div class="col-12">
              <form [formGroup]="loginForm" (ngSubmit)="loginUser()">

                <!-- USERNAME -->
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="IdNumber-icon"><i class="fi flaticon-user"></i></span>
                  </div>
                  <input
                    #username
                    name="username"
                    type="text"
                    class="form-control mt-2"
                    [ngClass]="{ 'is-invalid': formSubmitted && form.username.errors }"
                    placeholder="ID number or email address"
                    aria-label="IdNumber"
                    aria-describedby="IdNumber-icon"
                    formControlName="username"
                  />
    
                  <div *ngIf="formSubmitted && form['username'].errors" class="invalid-feedback">
                    <div *ngIf="form['username'].errors.required">Please enter your username or email address.</div>
                  </div>
                </div> <!-- Close div for .input-group -->
    
                <!-- PASSWORD -->
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="password-icon"><i class="fi flaticon-lock"></i></span>
                  </div>
                  <input
                    #password
                    name="password"
                    type="password"
                    class="form-control mt-2"
                    [ngClass]="{ 'is-invalid': formSubmitted && form.password.errors }"
                    placeholder="Password"
                    aria-label="password"
                    aria-describedby="password-icon"
                    formControlName="password"
                    maxlength="50"
                    showHidePassword
                  />
    
                  <div *ngIf="formSubmitted && form['password'].errors" class="invalid-feedback">
                    <div *ngIf="form['password'].errors.required">Please enter your password.</div>
                  </div>
                </div> <!-- Close div for .input-group -->
    
                <!-- REMEMBER ME -->
                <!-- <div class="checkbox mt-3 color-dark-grey font-weight-300">
                  <mat-checkbox color="brand">Remember me for 30 days</mat-checkbox>
                </div> -->
    
                <p><br /></p>

                <div class="row">

                  <div class="col-12">
                    <button type="submit" class="btn btn-brand btn-block">
                      Login to my account <i class="ml-2 mr-0 fi flaticon-chevron-right fi-sm"></i>
                    </button>
                  </div>

                  <div class="col-12 mt-5 mb-5">
                    <a [routerLink]="['/forgot-password']" class="btn color-label-3 btn-block">
                      I forgot my password?
                    </a>
                  </div>

                  <div class="col-6">
                    <span class="btn color-label-3 btn-block">
                      Not a member yet
                    </span>
                  </div>

                  <div class="col-6">
                    <!-- <button [routerLink]="['/register', 'user']"  type="button" class="btn btn-brand btn-block"> -->
                      <!-- Create an account <i class="ml-2 mr-0 fi fi-small flaticon-chevron-right"></i> -->
                    <!-- </button> -->
                    <span class="btn color-label-3 btn-block">
                      Contact us on <a href="mailto:accounts@surespace.co.za">accounts@surespace.co.za</a>
                    </span>
                  </div>

                </div> <!-- Close div for .row -->               
    
              </form>

          </div>

      </div> <!-- Close div for .row -->

    </div> <!-- Close div for #login-body -->

    <div class="col-lg-2 offset-1 d-none">
      <div id="login-logo" class="text-right">
        <img src="assets/images/logo_large.png" class="img-fluid" />
      </div> <!-- Close div for #login-logo -->
    </div>

  </div>
</div>