import { LoadingScreenService } from './../../../components/loading-screen/loading-screen.service';
import { BrokerAdministrator, BrokerAdministratorResponse } from 'src/app/_shared/models/brokerAdministrator.model';
import { BrokerAdministratorService } from 'src/app/services/broker-administrator.service';
import { Policy, PolicyResponse } from 'src/app/_shared/models/policy.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { User, UserResponse } from 'src/app/_shared/models/User.model';
import { Employee, EmployeeResponse } from 'src/app/_shared/models/employeeModel';
import { BrokerService } from 'src/app/services/broker.service';
import { HttpResponse } from '@angular/common/http';
import { BrokerQuoteService } from 'src/app/services/broker-quote.service';
import { BrokerQuoteResponse } from 'src/app/_shared/models/brokerQuotes.model';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { BrokerUnderwriterService } from 'src/app/services/broker-underwriter.service';
import { BrokerUnderwriter, BrokerUnderwriterResponse } from 'src/app/_shared/models/brokerUnderwriter.model';
import { MatSelectChange } from '@angular/material/select';
import { SentenceCasePipe } from 'src/app/pipe/sentence-case.pipe'

@Component({
  selector: 'app-broker-new-edit-quotes',
  templateUrl: './broker-new-edit-quotes.component.html',
  styleUrls: ['./broker-new-edit-quotes.component.scss'],
  providers: [ SentenceCasePipe ]
})
export class BrokerNewEditQuotesComponent implements OnInit, AfterViewInit {

  @ViewChild('quoteStepper', {static:false}) quoteStepper: MatStepper;
  @ViewChild('chooseProductToAdd', { static: false}) chooseProductToAdd;

  selectedQuote = this.activeRoute.snapshot.paramMap.get('id');
  routerPath: string = this.activeRoute.routeConfig.path;
  editQuote = this.routerPath.includes('edit');
  viewQuote = this.routerPath.includes('view');

  currentUser = this.authenticationService.currentUserValue;
  currentUserPermissions: any[];
  currentCompany = this.authenticationService.currentCompany.companyName;

  underwriters: BrokerUnderwriter[];
  administrators: BrokerAdministrator[];

  isLoading: boolean = false;
  clients: User[];
  employees: Employee[];
  products: any[];
  productType: string;

  quoteFormGroup: FormGroup;
  formSubmitted = false;

  dialogRef = null;

  quoteProductsControls: FormArray;

  policies: Policy[] = [];

  selectedStep: number = 0;

  additionalComment: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private brokerService: BrokerService,
    private brokerQuoteService: BrokerQuoteService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private underwriterService: BrokerUnderwriterService,
    private administratorService: BrokerAdministratorService,
    private loadingService: LoadingScreenService,
    private sentenceCase: SentenceCasePipe
  ) {
    this.currentUserPermissions = this.currentUser.company.find(company => company.companyName === this.authenticationService.currentCompany.companyName).permissions
  }

  ngOnInit(): void {

    this.quoteFormGroup = this.formBuilder.group({
      client: this.formBuilder.group({
        _id: ['', Validators.required],
        firstName: [''],
        lastName: [''],
        // refNr: [''],
        clientNr: [''],
        username: [''],
        companyName: [''],
        maritalStatus: ['']
      }),
      clientAutoCompleteDisplay: [''],
      broker: this.formBuilder.group({
        brokerId: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
      }),
      brokerAutoCompleteDisplay: [''],
      products: this.formBuilder.array([]),
      quoteStatus: ['inProgress', Validators.required],
      quoteNumber: [''],
      underwriter: [null],
      administrator: [null],
      selectedUnderwriter: [],
      selectedAdministrator: [],
      additionalComments: this.formBuilder.array([]),
      policyNumber: [0, Validators.required]
    });

    this.quoteProductsControls = this.quoteFormGroup.get('products') as FormArray;

    // this.quoteFormGroup.get('companyRef').setValue(this.currentCompany);

    this.mapBroker(this.currentUser);

    this.getAllClients();

    // GET EMPLOYEES
    this.quoteFormGroup
    .get('brokerAutoCompleteDisplay')
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.brokerService.getAllCompanyEmployees(this.currentCompany, {search: value}, true)
      .pipe(finalize(() => this.isLoading = false))
      )
    )
    .subscribe(
      (response: HttpResponse<EmployeeResponse>) => {
        this.employees = response.body.user;
      }
    )

    this.getLocalStorageItem();

  }

  ngAfterViewInit(){

    this.getCompanyUnderwriters();
    this.getCompanyAdministrators();

    let quoteItem = localStorage.getItem('SureSpace-QuoteItem');

    if(quoteItem){
      let formGroupProducts = this.quoteFormGroup.get('products') as FormArray;
      let itemToAdd = JSON.parse(quoteItem);

      setTimeout(() => {
  
        let quoteItemIndex = formGroupProducts.value.findIndex(item => item.createdAt == itemToAdd.createdAt);
        if(quoteItemIndex > -1){
          formGroupProducts.removeAt(quoteItemIndex);
          formGroupProducts.insert(quoteItemIndex, this.createFormGroup(itemToAdd));
        } else {
          // formGroupProducts.push(this.formBuilder.group(itemToAdd));
          formGroupProducts.push(this.createFormGroup(itemToAdd));
        }

        localStorage.removeItem('SureSpace-QuoteItem');
      }, 300)



      this.quoteStepper.next();
    }
  }

  getCompanyUnderwriters(){
    this.underwriterService.getAllUnderwriters(this.authenticationService.currentCompany.companyName).subscribe(
      (response:HttpResponse<BrokerUnderwriterResponse>) => {
        this.underwriters = response.body.underwriter;

        if(this.editQuote){
          let selectedUnderwriters = []

          this.underwriters.forEach(underwriter => {
            this.quoteFormGroup.get('underwriter').value?.forEach(quoteUnderwriter => {
              if(underwriter._id === quoteUnderwriter){
                selectedUnderwriters.push(underwriter)
              }
            })
          })

          this.quoteFormGroup.get('selectedUnderwriter').setValue(selectedUnderwriters);
        }
      }
    )
  }

  getCompanyAdministrators(){
    this.administratorService.getAllAdministrators(this.authenticationService.currentCompany.companyName).subscribe(
      (response:HttpResponse<BrokerAdministratorResponse>) => {
      this.administrators = response.body.administrator;

      if(this.editQuote && this.administrators?.length > 0){
        let selectedAdministrators = []

        this.administrators.forEach(admin => {
          this.quoteFormGroup.get('administrator').value?.forEach(quoteAdmin => {
            if(admin._id === quoteAdmin){
              selectedAdministrators.push(admin)
            }
          })
        })

        this.quoteFormGroup.get('selectedAdministrator').setValue(selectedAdministrators);
      }

    })
  }

  createFormGroup(jsonObject:any){

    let product = this.formBuilder.group({});

    for (const [key, value] of Object.entries(jsonObject)){
      // if(!Array.isArray(value)){
      //   product[key] = value;
      // }
      product.addControl(key, new FormControl(value));
    }

    return product
  }

  createProductDescription(product){

    switch(product.productType){

      case 'Vehicle':
        return `${product.year} ${product.make} ${product.model} - ${product.registrationNumber}`
        break;

      case 'Home':
      case 'HomeContent':
        return `${product.address.formattedAddress}`
        break;

      case 'AllRisk':
        return `${product.itemName} - ${product.itemDescription}`
        break;

      case 'TrailersAndCaravans':
        return `${product.type} - ${product.year} ${product.make} ${product.model}`
        break;

      case 'VAPS':
        return `${product.vapName} for ${product.vapType}`
        break;

      default:
        return `${product.description}`

    }
  }

  isCurrentStep(step:string){
    if(this.quoteStepper){
      return step === this.quoteStepper.selected.label
    }
    return false;
  }

  getCurrentQuote(quoteId:string){
    this.brokerQuoteService.getSelectedQuote(this.currentCompany, quoteId).subscribe(
      (response:HttpResponse<BrokerQuoteResponse>) => {
        
        this.quoteFormGroup.patchValue(response.body.quotes);
        this.quoteFormGroup.get('clientAutoCompleteDisplay').setValue(response.body.quotes['client']);
        this.quoteFormGroup.get('brokerAutoCompleteDisplay').setValue(response.body.quotes['broker']);

        for(let i = 0; i < response.body.quotes['products'].length; i++){
          this.quoteProductsControls.push(this.createFormGroup(response.body.quotes['products'][i]))
        }

        response.body.quotes['additionalComments'].forEach(comment => this.addAdditionalComment(comment))

      }
    )
  }

  saveAsDraft(){
    this.brokerQuoteService.saveQuoteAsDraft(this.currentCompany, this.quoteFormGroup.value).subscribe(
      (response:HttpResponse<BrokerQuoteResponse>) => {
        this.saveType('back')
      }
    )
  }

  addNewQuote(saveType?:string){

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.quoteFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add the new quote.')
      return;
    }

    this.brokerQuoteService.saveNewQuote(this.currentCompany, this.quoteFormGroup.value).subscribe(
      (response:HttpResponse<BrokerQuoteResponse>) => {
        this.saveType(saveType)
      }
    )
  }

  updateQuote(saveType?:string){

    this.formSubmitted = true;
    // stop here if form is invalid
    if (this.quoteFormGroup.invalid) {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.toastr.error('There are some errors on your form.  Please confirm everything is correct and try again.', 'Could not add the new quote.')
      return;
    }

    this.brokerQuoteService.updateQuote(this.currentCompany, this.selectedQuote, this.quoteFormGroup.value).subscribe(
      (response:HttpResponse<BrokerQuoteResponse>) => {
        this.saveType(saveType)
      }
    )

  }

  saveType(saveType?: string) {
    switch(saveType){
      case 'back':
        this.goBack();
        break;

      case 'continue':
        break;

      case 'new':
        this.quoteFormGroup.reset();
        window.scroll({ top: 0, behavior: 'smooth' });
        break;

      default:
        this.goBack();
        break;
    }
  }

  goBack(){
    this.router.navigate(['b/quotes'])
  }

  displayFn(user?: User) {
    return user._id || user['brokerId'] ? user.firstName ? `${user.firstName} ${user.lastName}` : `${user['companyName']}` : typeof user === 'string' ? user : undefined;
  }

  mapClient(client){
    this.quoteFormGroup.get('client').get('_id').setValue(client._id);
    this.quoteFormGroup.get('client').get('companyName').setValue(client.companyName);
    this.quoteFormGroup.get('client').get('firstName').setValue(client.firstName);
    this.quoteFormGroup.get('client').get('lastName').setValue(client.lastName);
    this.quoteFormGroup.get('client').get('clientNr').setValue(client.broker.find(company => company.companyName === this.currentCompany).clientNumber);
    this.quoteFormGroup.get('client').get('username').setValue(client.username);
  }

  mapBroker(broker){
    this.quoteFormGroup.get('broker').patchValue(broker);
    this.quoteFormGroup.get('broker').get('brokerId').setValue(broker._id)
    this.quoteFormGroup.get('brokerAutoCompleteDisplay').setValue(`${broker.firstName} ${broker.lastName}`)
  }

  addAdditionalComment(comment){

    if(comment.comment == '' || !comment.comment) return

    this.additionalComment = '';

    let createdBy = {
      _id: this.currentUser._id,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName
    };

    if(comment.createdBy){
      createdBy = comment.createdBy
    }

    let additionalCommentFormGroup = new FormGroup({
      comment: new FormControl(comment.comment),
      createdBy: new FormControl(createdBy),
      createdAt: new FormControl(comment.createdAt || new Date())
    })

    let quoteFormGroupAddtionalComments = this.quoteFormGroup.get('additionalComments') as FormArray;
    quoteFormGroupAddtionalComments.push(additionalCommentFormGroup);

  }

  removeAdditionalComment(index: number){

    let quoteFormGroupAddtionalComments = this.quoteFormGroup.get('additionalComments') as FormArray;
    quoteFormGroupAddtionalComments.removeAt(index)
  }

  addNewClient(){
    this.storeLocalStorageItem()
    this.router.navigate([`/b/clients/personal/new`, { r: this.router.url }])
  }

  getLocalStorageItem(){
    let localstorageItem = localStorage.getItem('SureSpace-Quote')
    if(localstorageItem){

      let localStorageItem = JSON.parse(localstorageItem);

      this.quoteFormGroup.patchValue(localStorageItem);

      localStorageItem.products.forEach(product => {
        if(this.quoteProductsControls.value.map(p => p._id).indexOf(product._id) < 0){
          // this.quoteProductsControls.push(this.formBuilder.group(product))
          this.quoteProductsControls.push(this.createFormGroup(product));
        }
      });

      localStorageItem.additionalComments.forEach(comment => this.addAdditionalComment(comment))


      localStorage.removeItem('SureSpace-Quote');
    } else {
      if(this.editQuote){
        this.getCurrentQuote(this.selectedQuote);
        this.quoteFormGroup.get('policyNumber').disable();
        this.quoteFormGroup.get('clientAutoCompleteDisplay').disable();
      }
    }
  };

  storeLocalStorageItem(){
    localStorage.setItem('SureSpace-Quote', JSON.stringify(this.quoteFormGroup.value))
  }

  askProductType(){
    this.dialogRef = this.dialog.open(this.chooseProductToAdd, { width: '800px' });
  }

  addNewProduct(productType?:string){

    const clientId = this.quoteFormGroup.get('client').get('_id').value;

    switch(productType){

      case 'vehicle':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/vehicle`, clientId, 'new'], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'building':
      case 'home':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/structure`, clientId, 'new'], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'homeContent':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/homeContent`, clientId, `new`], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'allRiskItem':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/allRisk`, clientId, `new`], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'trailersAndCaravans':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/trailersAndCaravans`, clientId, `new`], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'valueAddedProduct':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/valueAddedProducts`, clientId, `new`], { queryParams: { quote: true, r: this.router.url }});
        return;

      default:
         this.askProductType();
         return;

    }

  }

  addCommercialProduct(){
    const clientId = this.quoteFormGroup.get('client').get('_id').value;

    this.storeLocalStorageItem();
    this.router.navigate([`/b/clients/commercial/edit/${clientId}/short-term-insurance/commercialProduct/new`], { queryParams: { quote: true, r: this.router.url }});

  }

  editCommercialProduct(productId: string){
    const clientId = this.quoteFormGroup.get('client').get('_id').value;

    this.storeLocalStorageItem();
    this.router.navigate([`/b/clients/commercial/edit/`, clientId, `/short-term-insurance/commercialProduct/edit/`, productId], { queryParams: { quote: true, r: this.router.url }});

  }

  mapUnderwriter(event){
    let underwriters = [];

    event.value.forEach(uw => {
      underwriters.push(uw._id)
    })

    this.quoteFormGroup.get('underwriter').setValue(underwriters);
  }

  mapAdministrator(event){
    let administrators = [];

    event.value.forEach(a => {
      administrators.push(a._id)
    })

    this.quoteFormGroup.get('administrator').setValue(administrators);
  }

  getClientPolicyNumber(){
    this.brokerService.getPoliciesPerUser(this.authenticationService.currentCompany.companyName, this.quoteFormGroup.get('client').get('_id').value, {active: true}).subscribe(
      (response: HttpResponse<PolicyResponse>) => {
        this.policies = response.body.policies;

        this.quoteFormGroup
        .get('policyNumber')
        .valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this.brokerService.getPoliciesPerUser(this.authenticationService.currentCompany.companyName, this.quoteFormGroup.get('client').get('_id').value, {active: true, search: value}, true)
          .pipe(finalize(() => this.isLoading = false))
          )
        )
        .subscribe(
          (response: HttpResponse<PolicyResponse>) => {
            this.policies = response.body.policies;
          }
        )

      }
    )
  }

  addNewPolicy(){
    this.storeLocalStorageItem();
    this.router.navigate([`/b/clients/policies/new`, { r: this.router.url }])
  }

  compareSelectedUnderwriters(u1, u2){
    if(!u1 || !u2) return;

    return u1?._id === u2?._id
  }

  canViewItem(){
    return this.currentUserPermissions.includes('brokerLeadProvider') || this.currentUserPermissions.includes('brokerClerk') || this.currentUserPermissions.includes('brokerAdmin') || this.currentUserPermissions.includes('brokerTeamLead') || this.currentUserPermissions.includes('brokerManager') || this.currentUserPermissions.includes('brokerHR') || this.currentUserPermissions.includes('brokerDirector')
  }

  getAllClients(){

    this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, { limit: 25 }).subscribe(response => {

      this.clients = response.body.user;

      this.quoteFormGroup
      .get('clientAutoCompleteDisplay')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.brokerService.getAllCompanyClientsAndCommercial(this.currentCompany, { search: value }, true)
        .pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(
        (response: HttpResponse<UserResponse>) => {
          this.clients = response.body.user;
        }
      )

    })
  }

  removeProductFromQuote(product){
    const products = this.quoteFormGroup.get('products') as FormArray;
    products.removeAt(products.value.findIndex(p => p._id === product.value._id))
  }

  editExistingProduct(product){

    const clientId = this.quoteFormGroup.get('client').get('_id').value;

    let productType = product.value.productType.toLowerCase();

    switch(productType){

      case 'vehicle':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/vehicle`, clientId, 'edit', product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'building':
      case 'home':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/structure`, clientId, 'edit', product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'homecontent':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/homeContent`, clientId, `edit`, product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'allRiskItem':
      case 'allrisk':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/allRisk`, clientId, `edit`, product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'trailersandcaravans':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/trailersAndCaravans`, clientId, `edit`, product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      case 'vaps':
        this.storeLocalStorageItem();
        this.router.navigate([`/b/products/short-term-insurance/valueAddedProducts`, clientId, `edit`, product.value._id], { queryParams: { quote: true, r: this.router.url }});
        return;

      default:
         this.askProductType();
         return;

    }
  }

  getClientType(client){
    if(client.companyName && !client.firstName){
      return 'commercial';
    }

    return 'personal';
  }

  returnProduct(product){
    if(product.value){
      return this.sentenceCase.transform(product.value.productType);
    }
    return this.sentenceCase.transform(product);
  }

  goToClientFile(){
    let client = this.quoteFormGroup.value.client;

    if(client.companyName){
      this.router.navigate(['/b/clients/commercial/edit', client._id], { queryParams: { file: '9' } })
    } else {
      this.router.navigate(['/b/clients/personal/edit', client._id], { queryParams: { file: '9' } })
    }
  }

}
