<div id="content" class="row">

    <sub-navigation heading="Manage administrators" subheading="Showing {{ administrators?.length || '0' }} administrator{{ administrators?.length != 1 ? 's' : '' }}"></sub-navigation>
    
    <div class="col-12">
        
        <div class="panel panel-primary">
    
        <div class="panel-heading">
            <h5>Administrators</h5>
    
            <button
                *ngIf="currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')"
                type="button"
                class="btn btn-brand my-auto"
                [routerLink]="['new']"
                matTooltip="Add a new administrator"
            >
                <i class="fi flaticon-add"></i>Add administrator
            </button>
    
        </div> <!-- Close div for .panel-heading -->
    
        <div class="panel-body">
    
            <div id="filter" class="col-12 mb-3">
    
                <div class="row">

                    <div class="col-12 px-0 d-flex mb-3">
                        <!-- <p class="mr-3 my-auto"><strong>Search administrator by name or email</strong></p>
                        <input type="text" placeholder="Search administrator by name, email" class="form-control w-50" name="searchAdministrator" [(ngModel)]="searchAdministrator" (ngModelChange)="getAdministrators($event, null, true)"/> -->
                    </div>
                    
                </div> <!-- Close div for .row -->
            
            </div> <!-- Close div for #filter -->
    
            <div id="administrators" class="col-12">
    
                <div class="row">

                    <div class="col-12 text-center" *ngIf="!administrators || administrators?.length === 0">
                        <p class="mt-3">No administrators found</p>
                    </div>
        
                    <div class="col-md-4 col-sm-6 col-6 mb-3" *ngFor="let administrator of administrators">
                        <div class="card h-100">
                            <div class="card-body">   
                            
                            <div class="text-right">
                                <button class="btn btn-pill btn-icon" style="margin-right: 0px !important;" [matMenuTriggerFor]="menu">
                                <i class="color-dark-grey fi fi-rotate-90 flaticon-menu"></i>
                                </button>
                        
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item [routerLink]="['view/', administrator._id]">
                                        <span>View administrator</span>
                                    </button>
                                    <button *ngIf="currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')" mat-menu-item [routerLink]="['edit/', administrator._id]">
                                        <span>Edit administrator</span>
                                    </button>
                                    <button *ngIf="currentUserPermissions.includes('brokerManager') || currentUserPermissions.includes('brokerDirector')" mat-menu-item (click)="deleteAdministrator(administrator._id)">
                                        <span>Remove administrator</span>
                                    </button>
                                </mat-menu>
                            </div>

                            <div class="text-center mb-3">
                                <span class="userpic userpic-lg userpic-circle userpic-dark">
                                <span>{{ administrator?.name.substring(0, 1) }}</span>
                                </span>
                            </div>

                            <h5 class="card-title text-center color-label-4 mb-3">{{ administrator?.name }}</h5>
                            <p class="card-text mb-0">
                                <small class="text-left"><strong>Ref number:</strong></small>
                                <small class="float-right color-dark-grey">{{ administrator?.referenceNumber }}</small>
                            </p>
                            <p class="card-text mb-0">
                                <small class="text-left"><strong>Consultant:</strong></small>
                                <small class="float-right color-dark-grey"><span>{{ administrator?.brokerConsultant?.consultantName }}</span></small>
                            </p>
                            <p class="card-text mb-0">
                                <small class="text-left"><strong>Email:</strong></small>
                                <small class="float-right color-dark-grey">{{ administrator?.brokerConsultant?.consultantEmail }}</small>
                            </p>
                            <p class="card-text mb-0">
                                <small class="text-left"><strong>Telephone:</strong></small>
                                <small class="float-right color-dark-grey" *ngIf="administrator.telephone.length > 0"><span>{{ administrator.telephone[0].number }}</span></small>
                            </p>
                            </div>
                        </div> <!-- Close div for .card -->
                    </div>
        
                </div> <!-- Close div for .row -->
    
            </div> <!-- Close div for #employees -->
    
        </div> <!-- Close div for .panel-body -->
    
        </div> <!-- Close div for .panel -->
    </div> <!-- Close div for .col-12 -->
    
</div> <!-- Close div for #content -->