<div class="page-description">
  <div class="row h-100">
    <div class="col-6 my-auto">
      <h5 class="d-inline-block mb-0 font-weight-400">Vehicle insurance</h5>
      <h6 class="d-inline-block mb-0">Add a new vehicle and submit</h6>
    </div>

    <div class="col-6 text-right my-auto">

      <button type="button" class="btn btn-secondary" (click)="goBack()">
        Back
      </button>

      <div class="btn-group" ngbDropdown>
        <button type="button" class="btn btn-primary" mat-tooltip="Add new vehicle and go back." (click)="addNewVehicle('back')">
          Submit
        </button>
        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          aria-controls="dropdown-basic">
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item" (click)="addNewVehicle('continue')">Save &amp; continue</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addNewVehicle('new')">Save &amp; add new</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addNewVehicle('back')">Save &amp; go back</a></li>
        </ul>
      </div> <!-- Close div for .btn-group -->

    </div>
  </div> <!-- Close div for .row -->
</div> <!-- Close div for .page-description -->


<div class="row">

  <div class="col-12">

    <div class="panel panel-primary">
      <form id="newVehicleFormGroup" [formGroup]="newVehicleFormGroup">

        <div class="panel-heading">
          <h5>Information</h5>
        </div>

        <div class="panel-body">

          <div class="row">

            <div class="col-sm-5 col-12">
              <label for="registrationNumber"><strong>Registration number:</strong></label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['registrationNumber'].errors }" name="registrationNumber"
                formControlName="registrationNumber" />
              <div *ngIf="submitted && form['registrationNumber'].errors" class="invalid-feedback">
                <div *ngIf="form['registrationNumber'].errors.required">Please provide a registration number for this
                  vehicle.</div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-5 col-12">
              <label for="make"><strong>Vehicle make</strong></label>
              <div class="input-group">
                  <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['make'].errors }">
                    <mat-select name="make" formControlName="make">
                      <!-- <mat-option *ngFor="let vehicle of vehicleMake" [value]="vehicle">{{ vehicle }}</mat-option> -->
                    </mat-select>
                  </mat-form-field>
                </div>
              <div *ngIf="submitted && form['make'].errors" class="invalid-feedback">
                <div *ngIf="form['make'].errors.required">Please provide the make of your vehicle.</div>
              </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-4 col-12">
              <label for="model"><strong>Vehicle model</strong></label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['model'].errors }"
                name="model" formControlName="model" />
              <div *ngIf="submitted && form['model'].errors" class="invalid-feedback">
                <div *ngIf="form['model'].errors.required">Please provide the model of your vehicle.</div>
              </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-3 col-12">
              <label for="year"><strong>Vehicle year</strong></label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['year'].errors }"
                name="year" formControlName="year" />
              <div *ngIf="submitted && form['year'].errors" class="invalid-feedback">
                <div *ngIf="form['year'].errors.required">Please provide the year of your vehicle.</div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-12">

              <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">

                <i class="fi flaticon-upload"></i>
                <strong>Drop photos here<br />or <span class="color-primary">click to select</span></strong>

                <label class="upload-button">
                  <input #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                    (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                </label> <!-- Close .upload-button -->

              </div><!-- close .drop-container -->

              <div>
                <div id="uploads" class="row" *ngIf="files?.length">
                  <div id="upload-item" class="col-sm-2 col-4" *ngFor="let f of files; let i = index;">

                    <div class="thumbnail">
                      <img src="{{f['imagePreview']}}" class="img-fluid" />
                      <!-- <button (click)="removeFile(f.id)"></button> -->
                      <div class="removeImage">
                        <div class="remove" (click)="removeFile(f.id)">
                          x
                        </div> <!-- Close div for .remove -->
                      </div> <!-- Close div for .removeImage -->
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

              <div class="col-sm-6 col-12">
                <label for="color"><strong>Vehicle color</strong></label>
                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['color'].errors }"
                  name="color" formControlName="color" />
                <div *ngIf="submitted && form['color'].errors" class="invalid-feedback">
                  <div *ngIf="form['color'].errors.required">Please provide the color of your vehicle.</div>
                </div>
              </div>

              <div class="col-12 clearfix mt-3 d-sm-none d-block">
              </div>

              <div class="col-sm-6 col-12">
                <label for="variant"><strong>Vehicle variant</strong></label>
                <div class="input-group">
                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['variant'].errors }"
                    name="variant" formControlName="variant" />
    
                    <div *ngIf="submitted && form['variant'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['variant'].errors.required">Please select your vehicle variant.</div>
                    </div>
                </div>

              </div>

              <!-- <div class="col-sm-6 col-12">
                <label for="status"><strong>Vehicle status</strong></label>
                <div class="input-group">
                    <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['status'].errors }">
                        <mat-select name="status" formControlName="status">
                            <mat-option value="" disabled>None</mat-option>
                            <mat-option value="Pre-owned">Pre-owned</mat-option>
                            <mat-option value="Demo">Demo</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="submitted && form['status'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['status'].errors.required">Please select your vehicle status.</div>
                    </div>
                </div>

              </div> -->
          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12">
              <label for="use"><strong>Vehicle use</strong></label>
                <div class="input-group">

                    <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['use'].errors }">
                        <mat-select name="use" formControlName="use">
                        <mat-option value="" disabled>None</mat-option>
                        <mat-option value="Pre-owned">Work</mat-option>
                        <mat-option value="Demo">Private</mat-option>
                        <mat-option value="Other">Combined</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="submitted && form['use'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['use'].errors.required">Please select your vehicle daily use.</div>
                    </div>

                </div> <!-- Close div for .input-group -->

            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12">
              <label for="milage"><strong>Vehicle milage</strong></label>

              <div class="input-group">
                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['milage'].errors }"
                  name="milage" formControlName="milage" />
                <div class="input-group-append">
                  <div class="input-group-text">km</div>
                </div>

                <div *ngIf="submitted && form['milage'].errors" class="invalid-feedback">
                  <div *ngIf="form['milage'].errors.required">Please provide the milage of your vehicle.</div>
                </div>
              </div>
            </div> <!-- Close div for .col-12 -->

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12">
              <label for="engineSize"><strong>Vehicle engine size</strong></label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['engineSize'].errors }" name="engineSize"
                formControlName="engineSize" />
              <div *ngIf="submitted && form['engineSize'].errors" class="invalid-feedback">
                <div *ngIf="form['engineSize'].errors.required">Please provide the engine size your vehicle.</div>
              </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12">
              <label for="engineNumber"><strong>Vehicle engine number</strong></label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form['engineNumber'].errors }" name="engineNumber"
                formControlName="engineNumber" />
              <div *ngIf="submitted && form['engineNumber'].errors" class="invalid-feedback">
                <div *ngIf="form['engineNumber'].errors.required">Please provide the engine number your vehicle.</div>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <!-- <div class="col-12 clearfix mt-3">
          </div> -->

          <div class="row">

            <div class="col-sm-6 col-12">
              <label for="status"><strong>Vehicle status</strong></label>
              <div class="input-group">
                  <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['status'].errors }">
                      <mat-select name="status" formControlName="status">
                          <mat-option value="" disabled>None</mat-option>
                          <mat-option value="Pre-owned">Pre-owned</mat-option>
                          <mat-option value="Demo">Demo</mat-option>
                          <mat-option value="Other">Other</mat-option>
                      </mat-select>
                  </mat-form-field>
  
                  <div *ngIf="submitted && form['status'].errors" class="invalid-feedback d-block">
                      <div *ngIf="form['status'].errors.required">Please select your vehicle status.</div>
                  </div>
              </div>

            </div>

            <div class="col-sm-6 col-12">
              <label for="vin"><strong>Vehicle VIN</strong></label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['vin'].errors }"
                name="vin" formControlName="vin" />
              <div *ngIf="submitted && form['vin'].errors" class="invalid-feedback">
                <div *ngIf="form['vin'].errors.required">Please provide the VIN number of your vehicle.</div>
              </div>
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12">
              <label for="licenceExpiryDate"><strong>Licence expiry date:</strong></label>
                <div class="input-group">
                    <mat-form-field class="form-control">
                        <input matInput [matDatepicker]="licenceExpiryDate" name="licenceExpiryDate"
                        formControlName="licenceExpiryDate" [min]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="licenceExpiryDate"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #licenceExpiryDate></mat-datepicker>
                    </mat-form-field>
                </div> <!-- Close div for .input-group -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12" formGroupName="homeEnvironment">
              <label for="homeParking"><strong>Home parking environment:</strong></label>
              <div class="checkbox" *ngFor="let parking of newVehicleFormGroup.get('homeEnvironment')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}</mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12" formGroupName="officeEnvironment">
              <label for="parkingEnvironment"><strong>Office parking environment:</strong></label>
              <div class="checkbox" *ngFor="let parking of newVehicleFormGroup.get('officeEnvironment')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}</mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div class="row">

            <div class="col-sm-6 col-12" formGroupName="optionalSafetyExtras">
              <label for="optionalSafetyExtras"><strong>Optional safety extras:</strong></label>
              <div class="checkbox" *ngFor="let extra of newVehicleFormGroup.get('optionalSafetyExtras')['controls'] | keyvalue">
                <mat-checkbox disableRipple [formControlName]="extra.key">{{ extra.key | sentenceCase }}</mat-checkbox>
              </div> <!-- Close div for .checkbox -->
            </div>

            <div class="col-12 clearfix mt-3 d-sm-none d-block">
            </div>

            <div class="col-sm-6 col-12" formArrayName="optionalExtras">
              <label for="optionalExtras"><strong>Optional added extras:</strong></label>
              <div id="optionalExtras" class="row mt-2" [formGroupName]="i" *ngFor="let addition of newVehicleFormGroup.get('optionalExtras')['controls']; let i = index">
                <div class="form-group col-10 col-sm-6 col-md-9 my-auto">
                  <input type="text" class="form-control" name="optionalExtras" formControlName="extra" />
                </div>
                <div class="form-group col-2 col-sm-6 col-md-3 my-auto">
                  <button class="btn btn-icon btn-danger btn-sm float-right float-sm-left"
                    (click)="removeOptionalExtra(i)">
                    <i class="fi flaticon-trash mr-0"></i>
                  </button>
                </div>
              </div> <!-- Close div for .row -->

              <div class="row mt-2">
                <div class="col-12">
                  <button class="btn btn-label-success btn-sm" (click)="addNewOptionalExtra()">Add extra</button>
                </div>
              </div>
            </div>

          </div> <!-- Close div for .row -->
        </div> <!-- Close div for .panel-body -->

        <div class="panel-heading mt-4">
          <h5>Insurance</h5>
        </div>

        <div class="panel-body">
          <div class="row">

            <div class="col-sm-6 col-12">
              <label for="insured"><strong>Is the vehicle insured?</strong></label>

              <div class="input-group">
                <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['insured'].errors }">
                  <mat-select name="insured" formControlName="insured">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div> <!-- Close div for .row -->

          <div class="col-12 clearfix mt-3">
          </div>

          <div *ngIf="form['insured'].value === 'Yes'">

            <div class="row">

              <div class="col-sm-6 col-12">
                <label for="insuredWith"><strong>Insured with?</strong></label>
                <div class="input-group">
                    <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['insuredWith'].errors }">
                        <mat-select name="insuredWith" formControlName="insuredWith">

                        <mat-optgroup label="Brokers" *ngIf="brokers && brokers.length > 0">
                            <mat-option *ngFor="let broker of brokers" [value]="broker._id">
                                {{ broker?.company }}
                            </mat-option>
                        </mat-optgroup>

                        <!-- <mat-optgroup label="Insurance companies" *ngIf="insuranceCompanies.length > 0">
                            <mat-option *ngFor="let company of insuranceCompanies" [value]="company.companyName">{{
                                    company?.companyName }}</mat-option>
                        </mat-optgroup> -->

                        <mat-option *ngIf="brokers && brokers.length === 0" disabled>
                            No insurers added
                        </mat-option>

                        <div class="row mt-2">
                            <button (click)="addNewBroker()" class="btn btn-square btn-label-primary btn-block">
                                New broker
                            </button>
                            <!-- <div class="dropdown-buttons col-12 col-md-6">
                            <button class="btn btn-secondary btn-block h-100 btn-square">New insurance company</button>
                            </div> -->
                        </div>

                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="submitted && form['insuredWith'].errors" class="invalid-feedback d-block">
                        <div *ngIf="form['insuredWith'].errors.required">Please select the insurer for your item.</div>
                    </div>

                </div> <!-- Close div for .input-group -->

              </div>

              <div class="col-12 clearfix mt-3 d-sm-none d-block">
              </div>

              <div class="col-sm-6 col-12">
                <label for="insuredValue"><strong>Insured for:</strong></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">R</div>
                  </div>
                  <input type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['insuredValue'].errors }" name="insuredValue"
                    formControlName="insuredValue" />
                  <div *ngIf="submitted && form['insuredValue'].errors" class="invalid-feedback d-block">
                    <div *ngIf="form['insuredValue'].errors.required">Please provide the insured value of your vehicle.
                    </div>
                  </div>
                </div>
              </div>

            </div> <!-- Close div for .row -->

            <div class="col-12 clearfix mt-3">
            </div>

            <div class="row">

              <div class="col-sm-6 col-12">
                <label for="dateInsured"><strong>Date insured:</strong></label>
                <div class="input-group">
                  <mat-form-field class="form-control">
                    <input [max]="maxDate" matInput [matDatepicker]="dateInsured" name="dateInsured" formControlName="dateInsured">
                    <mat-datepicker-toggle matSuffix [for]="dateInsured"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #dateInsured></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-12 clearfix mt-3 d-sm-none d-block">
              </div>

              <div class="col-sm-6 col-12">
                <label for="premium"><strong>Premium:</strong></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">R</div>
                  </div>
                  <input type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form['premium'].errors }" name="premium"
                    formControlName="premium" />
                  <div *ngIf="submitted && form['premium'].errors" class="invalid-feedback">
                    <div *ngIf="form['premium'].errors.required">Please provide the monthly premium of your vehicle.
                    </div>
                  </div>
                </div>
              </div>

            </div> <!-- Close div for .row -->

            <div class="col-12 clearfix mt-3">
            </div>

            <div class="row">

                <div class="col-sm-6 col-12">
                    <label for="policyNumber"><strong>Policy number:</strong></label>
                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['policyNumber'].errors }"
                name="policyNumber" formControlName="policyNumber" />
                </div>

            </div>

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .panel-body-->

      <div class="panel-heading mt-4">
        <h5>Quote</h5>
      </div>

      <div class="panel-body">      
        
        <div class="row">

          <div class="col-sm-6 col-12">
            <label for="color"><strong>Who is the regular driver of the vehicle?</strong></label>
            <div class="input-group">
              <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && form['regularDriver'].errors }">
                <mat-select name="regularDriver" formControlName="regularDriver">
                  <mat-option value="" disabled>Please select the regular driver</mat-option>
                  <mat-option [value]="currentUser?._id">{{ currentUser?.firstName }} {{ currentUser?.lastName }} (Me)</mat-option>
                  <mat-option *ngFor="let familyMember of familyMembers" [value]="familyMember.idNumber">{{ familyMember?.name }} {{ familyMember?.surname }}</mat-option>
                
                  <div class="row mt-2">
                    <button (click)="addNewRegularDriver()" class="btn btn-square btn-label-primary btn-block">
                      New driver
                    </button>
                  </div>
                
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="submitted && form['color'].errors" class="invalid-feedback">
              <div *ngIf="form['color'].errors.required">Please provide the regular driver of this vehicle.</div>
            </div>
          </div>

          <div class="col-12 clearfix mt-3 d-sm-none d-block">
          </div>

          <div class="col-sm-6 col-12">
            <label for="claimFreeYears"><strong>How many years of uninterupted claim free years do you have?</strong></label>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form['claimFreeYears'].errors }"
              name="claimFreeYears" formControlName="claimFreeYears" />
            <div *ngIf="submitted && form['claimFreeYears'].errors" class="invalid-feedback">
              <div *ngIf="form['claimFreeYears'].errors.required">Please provide the number of claim free years you have had.</div>
            </div>
          </div>

        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .panel-body-->

    </form>
  </div> <!-- Close div for .panel -->

</div> <!-- Close div for .col-12 -->

</div> <!-- Close div for #content -->
