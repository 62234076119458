import { SharedModule } from './../_shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client.component';
import { RouterModule } from '@angular/router';
import { ClientInformationComponent } from './about/information/client-information.component';
import { ClientFamilyComponent } from './about/information/client-family.component';
import { PageDescriptionComponent } from 'src/app/components/page-description/page-description.component';
import { ClientDashboardComponent } from './dashboard/client-dashboard.component';
import { ViewClientInvestmentsComponent } from './investments/view-client-investments/view-client-investments.component';
import { ClientViewBrokerComponent } from './brokers/view-brokers/client-view-broker.component';
import { ClientAddNewBrokerComponent } from './brokers/add-broker/client-add-new-broker.component';
import { ClientNewBrokerComponent } from './brokers/new-broker/client-new-broker.component';
import { ClientHouseholdInsuranceComponent } from './insurance/householdInsurance/client-household-insurance.component';

import { DeleteFamilyMemberComponent, DeleteFamilyMemberComponentPreview } from 'src/app/components/modal/delete-family-member/delete-family-member.component';
import { DeleteHouseholdItemComponent, DeleteHouseholdItemComponentPreview } from 'src/app/components/modal/delete-household-item/delete-household-item.component';
import { DeleteVehicleComponent, DeleteVehicleComponentPreview } from 'src/app/components/modal/delete-vehicle/delete-vehicle.component';
import { DeletePersonalItemComponent, DeletePersonalItemComponentPreview } from 'src/app/components/modal/delete-personal-item/delete-personal-item.component';
import { DeleteStructureComponent, DeleteStructureComponentPreview } from 'src/app/components/modal/delete-structure/delete-structure.component';
import { VehicleInsuranceQuoteComponent, VehicleInsuranceQuoteComponentPreview } from './insurance/vehicleInsurance/quote/vehicle-insurance-quote.component';

import { AppRoutes } from 'src/app/app.routing';
import { ClientNewFamilyMemberComponent } from './about/information/family/new-member/client-new-family-member.component';
import { ClientEditFamilyMemberComponent } from './about/information/family/edit-member/client-edit-family-member.component';
import { ClientEditHouseholdInsuranceComponent } from './insurance/householdInsurance/edit/client-edit-household-insurance.component';
import { ClientNewHouseholdInsuranceItemComponent } from './insurance/householdInsurance/household-items/new/client-new-household-insurance-item.component';
import { ClientEditHouseholdInsuranceItemComponent } from './insurance/householdInsurance/household-items/edit/client-edit-household-insurance-item.component';
import { ClientNewHouseholdInsuranceComponent } from './insurance/householdInsurance/new/client-new-household-insurance.component';
import { ClientPersonalInsuranceComponent } from './insurance/personalInsurance/client-personal-insurance.component';
import { ClientVehicleInsuranceComponent } from './insurance/vehicleInsurance/client-vehicle-insurance.component';
import { ClientStructureInsuranceComponent } from './insurance/structureInsurance/client-structure-insurance.component';
import { ClientNewVehicleComponent } from './insurance/vehicleInsurance/new/client-new-vehicle.component';
import { ClientEditVehicleComponent } from './insurance/vehicleInsurance/edit/client-edit-vehicle.component';
import { ClientNewPersonalItemComponent } from './insurance/personalInsurance/new/client-new-personal-item.component';
import { ClientEditPersonalItemComponent } from './insurance/personalInsurance/edit/client-edit-personal-item.component';
import { ClientNewStructureComponent } from './insurance/structureInsurance/new/client-new-structure.component';
import { ClientEditStructureComponent } from './insurance/structureInsurance/edit/client-edit-structure.component';
import { ClientSettingsComponent } from './settings/client-settings.component';

@NgModule({
  declarations: [
    ClientComponent,
    PageDescriptionComponent,
    ClientInformationComponent,
    ClientFamilyComponent,
    ClientDashboardComponent,
    ViewClientInvestmentsComponent,
    ClientViewBrokerComponent,
    ClientAddNewBrokerComponent,
    ClientNewBrokerComponent,
    ClientHouseholdInsuranceComponent,
    DeleteFamilyMemberComponent,
    DeleteFamilyMemberComponentPreview,
    DeleteHouseholdItemComponent,
    DeleteHouseholdItemComponentPreview,
    ClientNewFamilyMemberComponent,
    ClientEditFamilyMemberComponent,
    ClientEditHouseholdInsuranceComponent,
    ClientNewHouseholdInsuranceItemComponent,
    ClientEditHouseholdInsuranceItemComponent,
    ClientNewHouseholdInsuranceComponent,
    ClientPersonalInsuranceComponent,
    ClientVehicleInsuranceComponent,
    ClientStructureInsuranceComponent,
    ClientNewVehicleComponent,
    ClientEditVehicleComponent,
    DeleteVehicleComponent,
    DeleteVehicleComponentPreview,
    ClientNewPersonalItemComponent,
    ClientEditPersonalItemComponent,
    DeletePersonalItemComponent,
    DeletePersonalItemComponentPreview,
    ClientNewStructureComponent,
    ClientEditStructureComponent, 
    DeleteStructureComponent,
    DeleteStructureComponentPreview,
    ClientSettingsComponent,
    VehicleInsuranceQuoteComponent,
    VehicleInsuranceQuoteComponentPreview
  ],
  entryComponents:[
    DeleteFamilyMemberComponent,
    DeleteFamilyMemberComponentPreview,
    DeleteHouseholdItemComponent,
    DeleteHouseholdItemComponentPreview,
    DeleteVehicleComponent,
    DeleteVehicleComponentPreview,
    DeletePersonalItemComponent,
    DeletePersonalItemComponentPreview,
    DeleteStructureComponent,
    DeleteStructureComponentPreview,
    VehicleInsuranceQuoteComponent,
    VehicleInsuranceQuoteComponentPreview
  ],
  imports: [
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    }),
    CommonModule,
    SharedModule
  ],
  exports:[ClientComponent]
})
export class ClientModule { }
