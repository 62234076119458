<div id="content" class="row">

  <sub-navigation heading="New client"></sub-navigation>

  <div class="col-12">

    <div class="panel panel-primary">

      <div class="panel-heading">

        <h5>Add a new client</h5>

        <div class="my-auto">

          <button matTooltip="Go back to view clients" class="btn btn-secondary my-auto" (click)="location.back()"><i class="fi flaticon-back"></i>Back</button>
          <button
            matTooltip="Add new client and go back"
            class="btn btn-brand my-auto"
            (click)="addClient('back')"
            >
              Add client
          </button>
        
          <button class="btn btn-light btn-pill my-auto color-brand" [matMenuTriggerFor]="menuEdit">
            <i class="fi flaticon-menu mr-0"></i>
          </button>

          <mat-menu xPosition="before" yPosition="below" #menuEdit="matMenu">
            <button (click)="addClient('back')" mat-menu-item>
              <span>Save &amp; go back</span>
            </button>
            <button (click)="addClient('new')" mat-menu-item>
              <span>Save &amp; add new</span>
            </button>
          </mat-menu>

        </div> <!-- Close div for .my-auto -->

      </div> <!-- Close div for .panel-heading -->

      <div class="panel-body pb-5">

        <mat-tab-group>

            <mat-tab label="Basic information">
              <div class="row">
    
                <div id="basicInformation" class="col-12">

                  <p><strong>Basic information</strong></p>
      
                  <form [formGroup]="basicInformationForm">
    
                    <div class="row">
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput name="idNumber" placeholder="ID number" type="text" [value]="basicForm['username'].value" formControlName="username" (blur)="determineGender()" />
                          <mat-error *ngIf="basicForm['username'].errors && basicForm['username'].errors.required">
                            Please enter client <strong>ID number</strong>.
                          </mat-error>
                          <mat-error *ngIf="basicForm['username'].errors && basicForm['username'].errors.minLength">
                              ID number must be <strong>13 characters</strong> long.
                          </mat-error>
                          <mat-error *ngIf="basicForm['username'].errors && basicForm['username'].errors.invalidIdNumber">
                            Please enter a valid <strong>SA ID number</strong>.
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput name="passportNumber" placeholder="Passport number" type="text" [value]="basicForm['passportNumber'].value" formControlName="passportNumber" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-sm-4 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Tax number" name="taxNumber" type="text" formControlName="taxNumber" />
                          
                          <mat-error *ngIf="basicForm['taxNumber'].errors && basicForm['taxNumber'].errors.required">
                            Please enter client <strong>tax number</strong>.
                          </mat-error>
                        
                        </mat-form-field>  
                      </div>

                      <!-- <div class="col-sm-4 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Client number" name="clientNumber" type="text" formControlName="clientNumber" />
                          
                          <mat-error *ngIf="basicForm['clientNumber'].errors && basicForm['clientNumber'].errors.required">
                            Please enter <strong>client number</strong>.
                          </mat-error>
                        
                        </mat-form-field>  
                      </div> -->

                      <div class="col-12">
                        <hr />
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Gender" name="gender" formControlName="gender">
                            <mat-option value="" disabled read-only>
                              Please select a gender
                            </mat-option>
                            <mat-option value="Male">
                              Male
                            </mat-option>
                            <mat-option value="Female">
                              Female
                            </mat-option>
                            <mat-option value="Other">
                              Other
                            </mat-option>
                          </mat-select>

                          <mat-error *ngIf="basicForm['gender'].errors && basicForm['gender'].errors.required">
                            Please enter user <strong>gender</strong>.
                          </mat-error>

                        </mat-form-field>
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Title" name="title" formControlName="title" (blur)="checkTitle()">
                            <mat-option value="" disabled read-only>
                              Please select a title
                            </mat-option>
                            <mat-option value="Mr">
                              Mr
                            </mat-option>
                            <mat-option value="Mrs">
                              Mrs
                            </mat-option>
                            <mat-option value="Miss">
                              Miss
                            </mat-option>
                            <mat-option value="Dr">
                              Dr
                            </mat-option>
                            <mat-option value="Prof">
                              Prof
                            </mat-option>
                            <mat-option value="Rev">
                              Rev
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Initials" name="initials" type="text" formControlName="initials" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="First name" name="firstName" type="text" formControlName="firstName" />
    
                          <mat-error *ngIf="basicForm['firstName'].errors && basicForm['firstName'].errors.required">
                            Please enter user <strong>first name</strong>.
                          </mat-error>
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Last name" name="lastName" type="text" formControlName="lastName" />
    
                          <mat-error *ngIf="basicForm['lastName'].errors && basicForm['lastName'].errors.required">
                            Please enter user <strong>last name</strong>.
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Nickname" name="nickName" type="text" formControlName="nickName" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Marital status" name="maritalStatus" formControlName="maritalStatus">
                            <mat-option value="" disabled read-only>
                              Please select marital status
                            </mat-option>
                            <mat-option value="single">
                              Single
                            </mat-option>
                            <mat-option value="married">
                              Married
                            </mat-option>
                            <mat-option value="separated">
                              Separated
                            </mat-option>
                            <mat-option value="divorced">
                              Divorced
                            </mat-option>
                            <mat-option value="widow/widower">
                              Widow/Widower
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-12">
                        <hr />
                      </div>

                      <ng-container class="row" formGroupName="physicalAddress">

                        <div class="col-12">
                            <mat-form-field>
                                <input
                                    ngx-google-places-autocomplete
                                    matInput
                                    #placesRef="ngx-places"
                                    (onAddressChange)="handleAddressChange($event)"
                                    formControlName="addressLine1"
                                    [options]="addressOptions"
                                    placeholder="Address"
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mb-3"></div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="suburb"
                                    formControlName="suburb"
                                    placeholder="Suburb"
                                >
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="city"
                                    formControlName="city"
                                    placeholder="City"
                                > 
                            </mat-form-field>
                            
                        </div>

                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field>
                                <input 
                                    matInput
                                    name="postalCode"
                                    formControlName="postalCode"
                                    placeholder="Postal Code"
                                >
                            </mat-form-field>
                        </div>

                    </ng-container>

                    <div class="col-12">
                        <hr />
                    </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <mat-select placeholder="Language" name="language" formControlName="language">
                            <mat-option value="" disabled read-only>
                              Please select a language
                            </mat-option>
                            <mat-option value="Afrikaans">Afrikaans</mat-option>
                            <mat-option value="English">English</mat-option>
                            <mat-option value="Zulu">Zulu</mat-option>
                            <mat-option value="Xhosa">Xhosa</mat-option>
                            <mat-option value="Venda">Venda</mat-option>
                            <mat-option value="Southern Sotho">Southern Sotho</mat-option>
                            <mat-option value="Tswana">Tswana</mat-option>
                            <mat-option value="Northern Sotho">Northern Sotho</mat-option>
                            <mat-option value="Tsongo">Tsongo</mat-option>
                            <mat-option value="Swati">Swati</mat-option>
                            <mat-option value="Ndebele">Ndebele</mat-option>
                          </mat-select>

                          <mat-error *ngIf="basicForm['language'].errors && basicForm['language'].errors.required">
                            Please select the user <strong>language</strong>.
                          </mat-error>

                        </mat-form-field>
                      </div>

                    </div> <!-- Close div for .row -->
    
                  </form>
      
                </div> <!-- Close div for .col-12 -->
      
              </div> <!-- Close div for .row-->
            </mat-tab>

            <mat-tab label="Additional information">
              <div class="row">           
      
                <div id="additionalInformation" class="col-12">

                  <p><strong>Additional information</strong></p>
      
                  <form [formGroup]="additionalInformationForm">
    
                    <div class="row">
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Middle name(s)" name="middlename" type="text" formControlName="middleName" />
                        </mat-form-field>  
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence">
                        <mat-form-field>
                          <mat-select placeholder="Drivers license" name="licenceCode" formControlName="licenceCode">
                              <mat-option value="None">
                                None
                              </mat-option>

                              <mat-optgroup label="Motorcycles">
                                <mat-option value="Code A1">Code A1</mat-option>
                                <mat-option value="Code A">Code A</mat-option>
                              </mat-optgroup>

                              <mat-optgroup label="Light motor vehicles">
                                <mat-option value="Code B">Code B</mat-option>
                                <mat-option value="Code EB">Code EB</mat-option>
                              </mat-optgroup>

                              <mat-optgroup label="Heavy motor vehicles">
                                <mat-option value="Code C1">Code C1</mat-option>
                                <mat-option value="Code C">Code C</mat-option>
                                <mat-option value="Code EC1">Code EC1</mat-option>
                                <mat-option value="Code EC">Code EC</mat-option>
                              </mat-optgroup>
                          </mat-select>
                        </mat-form-field>      
                      </div>

                      <div class="col-md-4 col-sm-6 col-6" formGroupName="driversLicence"
                        *ngIf="additionalForm['driversLicence'].value.licenceCode && additionalForm['driversLicence'].value.licenceCode != 'None'">

                        <mat-form-field class="example-full-width">
                          <input placeholder="First issue" matInput [matDatepicker]="driversLicenseFirstIssue" name="driversLicenceFirstIssue"
                            formControlName="licenceFirstIssue">
                          <mat-datepicker-toggle matSuffix [for]="driversLicenseFirstIssue"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #driversLicenseFirstIssue></mat-datepicker>
                        </mat-form-field>

                        <!-- <mat-form-field class="example-full-width">
                          <input placeholder="Valid until" matInput [matDatepicker]="driversLicenseValidUntil" name="driversLicenceExpiryDate"
                            formControlName="licenceExpiryDate">
                          <mat-datepicker-toggle matSuffix [for]="driversLicenseValidUntil"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #driversLicenseValidUntil></mat-datepicker>
                        </mat-form-field> -->

                      </div>
  
                      <div class="col-12">
                        <hr />
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Employer" name="employer" type="text" formControlName="employer" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Job description" name="jobDescription" type="text" formControlName="jobDescription" />
                        </mat-form-field>
                      </div>
  
                    </div> <!-- Close div for .row -->
    
                  </form>
      
                </div> <!-- Close div for .col-12 -->
      
              </div> <!-- Close div for .row-->
            </mat-tab>

            <mat-tab label="Contact information">
              <div class="row"> 
      
                <div id="contactInformation" class="col-12">

                  <p><strong>Contact information</strong></p>
    
                  <form [formGroup]="contactInformationForm">
    
                    <div class="row">
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Email" name="firstName" type="email" formControlName="email" />
    
                          <mat-error *ngIf="contactForm['email'].errors && contactForm['email'].errors.required">
                            Please enter a <strong>email address</strong> for this user.
                          </mat-error>
                          <mat-error *ngIf="contactForm['email'].errors && contactForm['email'].errors.pattern">
                            Please enter a <strong>valid</strong> email address.
                          </mat-error>
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Cellphone" name="cellphone" type="text" formControlName="cellphone" />
    
                          <mat-error *ngIf="contactForm['cellphone'].errors && contactForm['cellphone'].errors.required">
                            Please enter a <strong>cellphone number</strong> for the user.
                          </mat-error>
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Home phone" name="homePhone" type="text" formControlName="homePhone" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-12">
                        <hr />
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Work phone" name="workPhone" type="text" formControlName="workPhone" />
                        </mat-form-field>
                      </div>
  
                      <div class="col-md-4 col-sm-6 col-6">
                        <mat-form-field>
                          <input matInput placeholder="Alternative number" name="alternateNumber" type="text" formControlName="alternateNumber" />
                        </mat-form-field>
                      </div>
  
                    </div> <!-- Close div for .row -->
    
                  </form>
      
                </div> <!-- Close div for .col-12 -->
      
              </div> <!-- Close div for .row-->
            </mat-tab>

            <mat-tab label="Insurance information">

              <p><strong>Consultant information</strong></p>

              <form [formGroup]="basicInformationForm">

                <div class="row" formGroupName="broker">

                  <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                      <mat-select placeholder="Consultant" name="consultant" formControlName="_id">
                        <mat-option value="" disabled read-only>
                          Please select a consultant
                        </mat-option>
                        <mat-option *ngFor="let employee of employees" [value]="employee._id">
                          {{ employee.firstName }} {{ employee.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-md-4 col-sm-6 col-6">
                    <mat-form-field>
                      <mat-select placeholder="Client status" name="clientStatus" formControlName="clientStatus">
                        <mat-option value="" disabled read-only>
                          Please select client status
                        </mat-option>
                        <mat-option [value]="'lead'">
                          Lead
                        </mat-option>
                        <mat-option [value]="'quote'">
                          Quote
                        </mat-option>
                        <mat-option [value]="'active'">
                          Active
                        </mat-option>
                        <mat-option [value]="'cancelled'">
                          Cancelled
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div> <!-- Close div for .row -->

              </form>

              <br />

              <p>
                <strong>Product information</strong><br />
                <small>
                  Please select which types of products you would like for this client.
                </small>
              </p>

              <form [formGroup]="basicInformationForm">
                
                <!-- INSURANCE TYPES -->
                <div class="form-group" formGroupName="insuranceType">

                  <div class="row">

                    <div class="checkbox col-12">
                      <mat-checkbox color="brand" class="mr-4" formControlName="householdInsurance">Household insurance</mat-checkbox>

                      <mat-checkbox color="brand" class="mr-4" formControlName="vehicleInsurance">Vehicle insurance</mat-checkbox>

                      <mat-checkbox color="brand" class="mr-4" formControlName="buildingInsurance">Building insurance</mat-checkbox>

                      <mat-checkbox color="brand" class="mr-4" formControlName="personalItemInsurance">Personal item insurance</mat-checkbox>

                      <mat-checkbox color="brand" formControlName="trailersAndCaravansInsurance">Trailers and Caravans insurance</mat-checkbox>

                      <mat-checkbox color="brand" formControlName="vapsInsurance">Value added product insurance</mat-checkbox>

                    </div>

                  </div> <!-- Close div for .row -->
  
                </div> <!-- Close div for .form-group -->

              </form>

            </mat-tab>

        </mat-tab-group>

      </div> <!-- Close div for .panel-body -->

    </div> <!-- Close div for .panel -->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for #content -->