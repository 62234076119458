<div class="page-description">
  <div class="row h-100">
    <div class="col-6 align-self-center">
      <h5 class="d-inline-block mb-0 font-weight-400">Design components</h5>
      <h6 class="d-inline-block mb-0">Modal</h6>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-12">

    <div class="panel panel-primary">

      <div class="panel-heading">
        <h3>Modal demos</h3>
      </div> <!-- Close div for .panel-heading -->

      <div class="modal-body">

        <div class="table-responsive">

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td width="500px">Basic demo</td>
                <td><button class="btn btn-bold btn-label-primary" (click)="open(content)">Launch modal</button></td>
              </tr>

              <tr>
                <td>Scrollable fixed content</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Scrolling long content</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Tooltips and popovers</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Large modal</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Small modal</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Vertically centered</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

              <tr>
                <td>Stick to the bottom right</td>
                <td><button class="btn btn-bold btn-label-primary">Launch modal</button></td>
              </tr>

            </tbody>
          </table>

        </div> <!-- Close div for .table-responsive -->

      </div> <!-- Close div for .modal-body -->

    </div> <!-- Close div for .panlel-->

  </div> <!-- Close div for .col-12 -->

</div> <!-- Close div for .row -->


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        body
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>