import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PersonalInsuranceService } from 'src/app/services/personal-insurance.service';
import { HouseholdInsurance, HouseholdInsuranceResponse } from 'src/app/_shared/models/householdInsurane.model';
import { HouseholdItem, HouseholdItemResponse } from 'src/app/_shared/models/householdItem.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DeleteHouseholdItemComponent } from 'src/app/components/modal/delete-household-item/delete-household-item.component';
import { HomeContent } from 'src/app/_shared/models/homeContentInsurance.model';

@Component({
  selector: 'app-client-household-insurance',
  templateUrl: './client-household-insurance.component.html',
  styleUrls: ['./client-household-insurance.component.scss'],
  providers: [DeleteHouseholdItemComponent]
})
export class ClientHouseholdInsuranceComponent implements OnInit, AfterViewInit {

  householdInsurance: HouseholdInsurance;
  hasHouseholdInsurance: Boolean = false;
  householdItems: HouseholdItem[] = [];
  totalInsuredItemValue: number = 0;

  householdInsuranceForm: FormGroup;
  get hf_form(){ return this.householdInsuranceForm.controls; }

  displayedColumns: string[] = ['image', 'item', 'category', 'insuredValue', 'specified', 'quantity', 'action'];
  resultsLength = 0;
  pageSize = 25;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private personalInsuranceService: PersonalInsuranceService,
    private formBuilder: FormBuilder,
    private deleteHouseholdItemModal: DeleteHouseholdItemComponent,
  ) { }

  getHouseholdInsurance(){
    this.personalInsuranceService.getHouseholdInsurance().subscribe(
      (response: HttpResponse<HouseholdInsuranceResponse>) => {
        this.householdInsurance = response.body.householdInsurance;
        this.householdInsuranceForm.patchValue(this.householdInsurance);

        if(Object.keys(this.householdInsurance).length > 0){
          this.hasHouseholdInsurance = true;
          this.getAllHouseholdItems();
        }
        

        // TODO: IF this.householdInsurance.insuredWith.company === 'None'

      }
    )
  };

  getAllHouseholdItems(noLoad?:boolean, eventData?:number){
    this.isLoadingResults = true;

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    const req = {
      offset: eventData ? (eventData * this.pageSize) - 1: this.paginator.pageIndex,
      limit: this.pageSize,
      itemName: '',
      itemCategory: '',
      specified: ''
    }

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.personalInsuranceService.getAllHouseholdItems(req, noLoad)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.body.count;

          this.totalInsuredItemValue = data.body.totalInsuredValue;

          // TODO:  IF A CLIENT IS UNDER INSURED - SEND MAIL TO INSURER
          // if(this.totalInsuredItemValue > this.householdInsurance.insuredHouseholdValue){
          //   this.sendMailUnderInsured(this.totalInsuredItemValue, this.householdInsurance.insuredHouseholdValue);
          // };

          return data.body.householdItem;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.householdItems = data);
  }

  deleteHouseholdItem(householdItem: HouseholdItem){
    this.deleteHouseholdItemModal.openDialog(householdItem as HomeContent);
    this.deleteHouseholdItemModal.dialogRef.afterClosed().subscribe(response => {
      this.getAllHouseholdItems();
    })
  }

  ngOnInit() {
    this.householdInsuranceForm = this.formBuilder.group({
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      city: '',
      postalCode: '',
      insuredWith: '',
      insuredHouseholdValue: ''
    });
  }

  ngAfterViewInit() {
    this.getHouseholdInsurance();
  }

}
