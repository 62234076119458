<div id="content" class="row">

    <sub-navigation heading="{{ editVehicle ? 'Edit' : 'Add new' }} vehicle"></sub-navigation>

    <div class="col-12">

        <div class="panel panel-primary">
            <form id="vehicleFormGroup" [formGroup]="vehicleFormGroup">

                <div class="panel-heading">
                    <h5>Vehicle information</h5>

                    <div class="my-auto">

                        <button matTooltip="Go back to view client vehicles" class="btn btn-secondary my-auto" (click)="goBack()">
                <i class="fi flaticon-back"></i>Back
              </button>

                        <div class="btn-group" ngbDropdown *ngIf="!view && canViewItem">
                            <button type="button" class="btn btn-primary" mat-tooltip="Save vehicle and go back." (click)="editVehicle ? updateVehicle('back') : addNewVehicle('back')">
                  Submit
                </button>
                            <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" aria-controls="dropdown-basic">
                </button>
                            <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><a class="dropdown-item" (click)="editVehicle ? updateVehicle('continue') : addNewVehicle('continue')">Save &amp; continue</a>
                                </li>
                                <li role="menuitem"><a class="dropdown-item" (click)="editVehicle ? updateVehicle('back') : addNewVehicle('back')">Save &amp; go back</a></li>
                            </ul>
                        </div>
                        <!-- Close div for .btn-group -->

                    </div>
                    <!-- Close div for .my-auto -->

                </div>

                <div class="panel-body">

                    <div class="row">

                        <div class="col-sm-5 col-12">
                            <div class="input-group">
                                <mat-form-field [ngClass]="{ 'is-invalid': formSubmitted && vehicleForm['type'].errors }">
                                    <mat-select placeholder="Vehicle type" name="use" formControlName="type">
                                        <!-- <mat-option value="" disabled>Please select vehicle type</mat-option> -->
                                        <mat-option value="Car">Car</mat-option>
                                        <mat-option value="Motorcycle">Motorcycle</mat-option>
                                        <mat-option value="LDV<1400">LDV < 1400</mat-option>
                                        <mat-option value="LDV>1400">LDV > 1400</mat-option>
                                        <mat-option value="4X2DCab">4X2 Double cab</mat-option>
                                        <mat-option value="4X2SCab">4X2 Single cab</mat-option>
                                        <mat-option value="4X4Luxury">4X4 Luxury</mat-option>
                                        <mat-option value="4X4DCab">4X4 Double cab</mat-option>
                                        <mat-option value="4X4SCab">4X4 Single cab</mat-option>
                                        <mat-option value="Kombi">Kombi</mat-option>
                                        <mat-option value="Venture">Venture</mat-option>
                                        <mat-option value="SUV">SUV</mat-option>
                                    </mat-select>

                                    <mat-error>
                                        Please select a <strong>vehicle type</strong>.
                                    </mat-error>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-5 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Registration number" name="registrationNumber" formControlName="registrationNumber" />
                                <mat-error>
                                    Please enter a <strong>registration number</strong> for this vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-3 col-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Vehicle year" name="year" formControlName="year" (keyup)="getVehicleMakes(vehicleForm['year'].value)" />
                                <mat-error>
                                    Please enter the <strong>production year</strong> of this vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-5 col-12">
                            <div class="input-group">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Vehicle make" name="make" formControlName="make"  />
                                    <!-- <mat-select placeholder="Vehicle make" name="make" formControlName="make" [disabled]="vehicleMakes?.length < 1" (selectionChange)="getVehicleModels(vehicleForm['year'].value, vehicleForm['make'].value)">
                                        <mat-option value="" disabled>None</mat-option>
                                        <mat-option *ngFor="let make of vehicleMakes" [value]="make.display">{{ make.display }}</mat-option>
                                    </mat-select>
                                    <div *ngIf="loadingVehicleMakes" class="loader">Loading...</div> -->

                                    <mat-error>
                                        Please select the <strong>make</strong> of this vehicle.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-4 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle model" name="model" formControlName="model"  />
                                <!-- <mat-select placeholder="Vehicle model" name="model" formControlName="model" [disabled]="vehicleMakes?.length < 1 && vehicleModels?.length < 1" (selectionChange)="getVehicleTrims(vehicleForm['year'].value, vehicleForm['make'].value, vehicleForm['model'].value)">
                                    <mat-option value="" disabled>None</mat-option>
                                    <mat-option *ngFor="let model of vehicleModels" [value]="model.name">{{ model.name }}</mat-option>
                                </mat-select>
                                <div *ngIf="loadingVehicleModels" class="loader">Loading...</div> -->

                                <mat-error>
                                    Please select the <strong>model</strong> of this vehicle.
                                </mat-error>

                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row d-none">

                        <div class="col-12">

                            <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">

                                <i class="fi flaticon-upload"></i>
                                <strong>Drop photos here<br />or <span class="color-primary">click to select</span></strong>

                                <label class="upload-button">
                    <input #fileInput type="file" (click)="fileInput.value = ''" ngFileSelect [options]="options"
                      (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                  </label>
                                <!-- Close .upload-button -->

                            </div>
                            <!-- close .drop-container -->

                            <div>
                                <div id="uploads" class="row" *ngIf="files?.length">
                                    <div id="upload-item" class="col-sm-2 col-4" *ngFor="let f of files; let i = index;">

                                        <div class="thumbnail">
                                            <img src="{{f['imagePreview']}}" class="img-fluid" />
                                            <!-- <button (click)="removeFile(f.id)"></button> -->
                                            <div class="removeImage">
                                                <div class="remove" (click)="removeFile(f.id)">
                                                    x
                                                </div>
                                                <!-- Close div for .remove -->
                                            </div>
                                            <!-- Close div for .removeImage -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle MM code" name="MMCode" formControlName="MMCode" />
                                <mat-error>
                                    Please provide the <strong>vehicle MM code</strong> of the vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12">

                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle variant" name="variant" formControlName="variant"  />
                                <!-- <mat-select placeholder="Vehicle variant" name="variant" formControlName="variant" [disabled]="vehicleTrims?.length < 1">
                                    <mat-option value="" disabled>None</mat-option>
                                    <mat-option *ngFor="let variant of vehicleTrims" [value]="variant.trim">{{ variant.trim }}</mat-option>
                                </mat-select>
                                <div *ngIf="loadingVehicleTrims" class="loader">Loading...</div> -->

                                <mat-error>
                                    Please select the vehicle <strong>variant.</strong>
                                </mat-error>

                            </mat-form-field>
                        </div>


                        <!-- <div class="col-sm-6 col-12">
                        <label for="status"><strong>Vehicle status</strong></label>
                        <div class="input-group">
                            <mat-form-field class="form-control" [ngClass]="{ 'is-invalid': submitted && vehicleForm['status'].errors }">
                                <mat-select name="status" formControlName="status">
                                    <mat-option value="" disabled>None</mat-option>
                                    <mat-option value="Pre-owned">Pre-owned</mat-option>
                                    <mat-option value="Demo">Demo</mat-option>
                                    <mat-option value="Other">Other</mat-option>
                                </mat-select>
                            </mat-form-field>
            
                            <div *ngIf="submitted && vehicleForm['status'].errors" class="invalid-feedback d-block">
                                <div *ngIf="vehicleForm['status'].errors.required">Please select the vehicle status.</div>
                            </div>
                        </div>
        
                      </div> -->
                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Vehicle engine size" name="engineSize" formControlName="engineSize" />
                                <span matSuffix>cc</span>
                                <mat-error>
                                    Please provide the <strong>engine size</strong> for the vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle color" name="color" formControlName="color" />
                                <mat-error>
                                    Please provide the <strong>color</strong> of the vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <mat-select placeholder="Vehicle use" name="use" formControlName="use">
                                    <mat-option value="" disabled>None</mat-option>
                                    <mat-option value="Business">Business</mat-option>
                                    <mat-option value="Private">Private</mat-option>
                                    <mat-option value="Combined">Combined</mat-option>
                                </mat-select>

                                <mat-error>
                                    Please select the vehicle daily <strong>use</strong>.
                                </mat-error>

                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Vehicle milage" name="milage" formControlName="milage" />
                                <span matSuffix>km</span>
                            </mat-form-field>
                        </div> <!-- Close div for .col-12 -->

                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle engine number" name="engineNumber" formControlName="engineNumber" />
                                <mat-error>
                                    Please provide the <strong>Engine number</strong> of the vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Vehicle VIN" name="vin" formControlName="vin" />
                                <mat-error>
                                    Please provide the <strong>VIN number</strong> of the vehicle.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12" formGroupName="homeEnvironment">
                            <label for="homeParking"><strong>Home parking environment:</strong></label>
                            <div class="checkbox" *ngFor="let parking of vehicleFormGroup.get('homeEnvironment')['controls'] | keyvalue">
                                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}
                                </mat-checkbox>
                            </div>
                            <!-- Close div for .checkbox -->
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12" formGroupName="officeEnvironment">
                            <label for="parkingEnvironment"><strong>Office parking environment:</strong></label>
                            <div class="checkbox" *ngFor="let parking of vehicleFormGroup.get('officeEnvironment')['controls'] | keyvalue">
                                <mat-checkbox disableRipple [formControlName]="parking.key">{{ parking.key | sentenceCase }}
                                </mat-checkbox>
                            </div>
                            <!-- Close div for .checkbox -->
                        </div>

                    </div>
                    <!-- Close div for .row -->

                    <div class="col-12 clearfix mt-3">
                    </div>

                    <div class="row">

                        <div class="col-sm-6 col-12" formGroupName="optionalSafetyExtras">
                            <label for="optionalSafetyExtras"><strong>Optional safety extras:</strong></label>
                            <div class="checkbox" *ngFor="let extra of vehicleFormGroup.get('optionalSafetyExtras')['controls'] | keyvalue">
                                <mat-checkbox disableRipple [formControlName]="extra.key">{{ extra.key | sentenceCase }}</mat-checkbox>
                            </div>
                            <!-- Close div for .checkbox -->
                        </div>

                        <div class="col-12 clearfix mt-3 d-sm-none d-block">
                        </div>

                        <div class="col-sm-6 col-12" formArrayName="optionalExtras">
                            <label for="optionalExtras"><strong>Optional added extras:</strong></label>
                            <div id="optionalExtras" class="row" [formGroupName]="i" *ngFor="let addition of vehicleFormGroup.get('optionalExtras')['controls']; let i = index">
                                <div class="form-group col-10 col-sm-6 col-md-9 my-auto">
                                    <!-- <input type="text" class="form-control" name="optionalExtras" formControlName="extra" /> -->
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Description" name="optionalExtraDescription" formControlName="optionalExtraDescription" />
                                        <mat-error>
                                            Please provide the <strong>description</strong> of the optional extra.
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Value" name="optionalExtraValue" formControlName="optionalExtraValue" />
                                        <span matPrefix>R</span>
                                        <mat-error>
                                            Please provide the <strong>value</strong> of the optional extra.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-2 col-sm-6 col-md-3 my-auto">
                                    <button class="btn btn-icon btn-danger btn-sm float-right float-sm-left" (click)="removeOptionalExtra(i)">
                      <i class="fi flaticon-trash mr-0"></i>
                    </button>
                                </div>
                            </div>
                            <!-- Close div for .row -->

                            <div class="row mt-2">
                                <div class="col-12">
                                    <button type="button" class="btn btn-label-success btn-sm" (click)="addNewOptionalExtra()">Add extra</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Close div for .row -->
                </div>
                <!-- Close div for .panel-body -->

                <ng-container>
                
                    <div class="panel-heading mt-4">
                        <h5>Insurance</h5>
                    </div>

                    <div class="panel-body" formGroupName="insurance">

                        <div class="row">                            

                            <div class="col-sm-6 col-12">

                                <div class="row">

                                    <div class="col-12 col-md-8 col-lg-9">
                                        <mat-form-field>
                                            <input [disabled]="useRetailValue.checked" matInput type="number" placeholder="Insured value" name="insuredValue" formControlName="insuredValue" />
                                            <span matPrefix>R</span>
                                            <mat-error>
                                                Please provide the <strong>insured value</strong> of the vehicle.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-12 col-md-4 col-lg-3 my-auto">
                                        <mat-checkbox #useRetailValue disableRipple formControlName="insuredValueRetail" (click)="vehicleFormGroup.get('insurance').get('insuredValue').setValue(0)">Use retail?</mat-checkbox>

                                    </div>

                                </div>

                            </div>

                            <ng-container *ngIf="!isQuoteProduct">

                                <div class="col-12 clearfix mt-3 d-sm-none d-block">
                                </div>

                                <div class="col-sm-6 col-12">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Premium" name="premium" formControlName="premium" />
                                        <span matPrefix>R</span>
                                        <mat-error>
                                            Please provide the <strong>monthly premium</strong> of the vehicle.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </ng-container>

                        </div>
                        <!-- Close div for .row -->

                        <div class="col-12 clearfix mt-3">
                        </div>

                        <div class="row" *ngIf="!isQuoteProduct">

                            <div class="col-12 clearfix mt-3 d-sm-none d-block">
                            </div>

                            <div class="col-sm-6 col-12">
                                <!-- <mat-form-field>
                            <input 
                                matInput
                                type="text"
                                placeholder="Policy number"
                                name="policyNumber"
                                formControlName="policyNumber"
                            />
                            <mat-error>
                                Please provide the <strong>policy number</strong> of the vehicle.
                            </mat-error>
                        </mat-form-field> -->

                                <div class="input-group">
                                    <mat-form-field>
                                        <mat-select placeholder="Policy number" formControlName="policyNumber">
                                            <mat-option disabled *ngIf="policies?.length === 0">
                                                <span>No policies found</span>
                                            </mat-option>
                                            <mat-option *ngFor="let policy of policies" [value]="policy.policyNumber">
                                                <span>{{ policy?.policyNumber }}</span>
                                            </mat-option>

                                            <mat-option class="px-0 py-0" style="height: unset">
                                                <button (click)="addNewPolicy()" class="btn btn-block btn-square btn-info">Add new policy</button>
                                            </mat-option>
                                        </mat-select>
        
                                        <mat-error>
                                        Please select a <strong>policy number</strong> for this vehicle.
                                        </mat-error>
        
                                    </mat-form-field>
                                </div>
                                <!-- Close div for .input-group -->
                            </div>

                        </div>
                        <!-- Close div for .row -->

                        <div class="col-12 clearfix mt-3">
                        </div>

                        <!--<div class="row">
    
                </div> <!-- Close div for .row -->

                        <div class="col-12 clearfix mt-3">
                        </div>

                        <div class="row">

                            <div class="col-sm-6 col-12">
                                <mat-form-field>
                                    <mat-select name="regularDriver" formControlName="regularDriver" placeholder="Who is the regular driver of the vehicle?" [compareWith]="regularDriverCompare" (selectionChange)="mapRegularDriverDisplay($event)">
                                        <mat-option value="" disabled>Please select the regular driver</mat-option>
                                        <mat-option [value]="clientInformation?._id">{{ clientInformation?.firstName }} {{ clientInformation?.lastName }}</mat-option>
                                        <mat-option *ngFor="let familyMember of familyMembers" [value]="familyMember?._id">
                                            {{ familyMember?.name }} {{ familyMember?.surname }}</mat-option>

                                        <div class="mt-2">
                                            <button (click)="addNewRegularDriver()" class="btn btn-square btn-label-primary btn-block">
                                    New driver
                                    </button>
                                        </div>

                                    </mat-select>

                                    <mat-error>
                                        Please provide the <strong>regular driver</strong> of this vehicle.
                                    </mat-error>

                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-12">
                                <mat-form-field>
                                    <mat-select name="additionalDriver" formControlName="additionalDriver" multiple placeholder="Are there any other drivers of the vehicle?" (selectionChange)="mapAdditionalDriverDisplay($event)">
                                        <mat-option disabled readonly>Please select additional driver(s)</mat-option>
                                        <mat-option [value]="clientInformation?._id">{{ clientInformation?.firstName }} {{ clientInformation?.lastName }}</mat-option>
                                        <mat-option *ngFor="let familyMember of familyMembers" [value]="familyMember?._id">
                                            {{ familyMember?.name }} {{ familyMember?.surname }}
                                        </mat-option>

                                        <div class="mt-2">
                                            <button (click)="addNewRegularDriver()" class="btn btn-square btn-label-primary btn-block">
                                    New driver
                                    </button>
                                        </div>

                                    </mat-select>

                                </mat-form-field>
                            </div>

                            <div class="col-12 clearfix mt-3">
                            </div>

                            <div class="col-sm-6 col-12" *ngIf="!isQuoteProduct">
                                <mat-form-field>
                                    <input 
                                        matInput
                                        name="inceptionDate"
                                        [matDatepicker]="inceptionDate"
                                        name="inceptionDate"
                                        formControlName="inceptionDate"
                                        placeholder="Vehicle inception date"
                                    >
                                    <mat-datepicker-toggle matSuffix [for]="inceptionDate"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #inceptionDate></mat-datepicker>
            
                                    <mat-error>
                                        Please provide the <strong>vehicle inception date</strong>.
                                    </mat-error>
            
                                </mat-form-field>
                            </div>

                        </div>
                        <!-- Close div for .row -->

                    </div>
                    <!-- Close div for .panel-body-->

                </ng-container>

            </form>
        </div>
        <!-- Close div for .panel -->

    </div>
    <!-- Close div for .col-12 -->

</div>
<!-- Close div for #content -->