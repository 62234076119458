<div class="page-description">
    <div class="row h-100">
      <div class="col-6 my-auto">
        <h5 class="d-inline-block mb-0 font-weight-400">Household insurance</h5>
        <h6 class="d-inline-block mb-0"></h6>
      </div>

      <div class="col-6 text-right my-auto" *ngIf="!hasHouseholdInsurance">

        <button [routerLink]="['add']" type="button" class="btn btn-primary" matTooltip="Add household insurance.">
          Add insurance
        </button>

      </div> <!-- Close div for .col-6 -->

      <div class="col-6 text-right my-auto" *ngIf="hasHouseholdInsurance">

        <!-- <button (click)="getHouseholdQuote()" type="button" class="btn btn-label-info" matTooltip="Get a quote on a vehicle.">
          Request a quote
        </button>

        <button *ngIf="hasHouseholdInsurance" type="button" class="btn btn-label-success" matTooltip="Submit a claim on an insured vehicle.">
          Submit a claim
        </button>

        <button class="btn btn-link btn-separator">
          <span class="line"></span>
        </button> -->

        <button [routerLink]="['edit']" type="button" class="btn btn-primary" matTooltip="Edit household insurance information.">
          Edit home info
        </button>

        <button class="btn btn-link btn-separator">
          <span class="line"></span>
        </button>

        <button [routerLink]="['add-item']" type="button" class="btn btn-label-info" matTooltip="Allocate a new item to your household insurance.">
          Add new household item
        </button>

      </div> <!-- Close div for .col-6 -->

    </div>  <!-- Close div for .row -->

  </div> <!-- Close div for .page-description -->

  <div class="row mt-5" *ngIf="!hasHouseholdInsurance"> 
    <div class="col-12 text-center">
      <p>No household insurance.</p>
    </div>
  </div>

  <div class="row" [hidden]="!hasHouseholdInsurance">

    <div class="col-md-4 col-sm-6 col-12">

      <div class="panel panel-primary h-100">

        <div class="panel-heading"><h5>Insured address</h5></div>

        <div class="panel-body">
          <span>{{ hf_form['addressLine1'].value }}</span><br />
          <span *ngIf="hf_form['addressLine2'].value">{{ hf_form['addressLine2'].value }}<br /></span>
          <span>{{ hf_form['suburb'].value  }}</span><br />
          <span>{{ hf_form['city'].value }}</span><br />
          <span>{{ hf_form['postalCode'].value }}</span>
        </div>
      </div> <!-- Close div for .panel -->

    </div> <!-- col-md-4 col-sm-6 col-12 -->

    <div class="col-md-4 col-sm-6 col-12">

      <div class="panel panel-primary h-100"
        [ngClass]="hf_form['insuredHouseholdValue'].value < totalInsuredItemValue ? 'panel-error' : ''"
      >
        <div class="panel-heading"><h5>Total insured value</h5></div>
        <div class="panel-body">
          <div class="row">
            <div class="col-6">
              <small class="color-light-grey mb-0">Current</small>
              <p><strong>{{ totalInsuredItemValue | currency: 'R ' }}</strong></p>
            </div>
            <div class="col-6">
              <small class="color-light-grey mb-0">Total</small>
              <p>{{ hf_form['insuredHouseholdValue'].value | currency: 'R ' }}</p>
            </div>
          </div> <!-- Close div for .row -->
  
          <p *ngIf="hf_form['insuredHouseholdValue'].value >= totalInsuredItemValue" class="color-dark-grey font-weight-100"><i class="fi flaticon-success color-success mr-3"></i>You are over insured.</p>
          <p *ngIf="hf_form['insuredHouseholdValue'].value < totalInsuredItemValue" class="color-dark-grey font-weight-100"><i class="fi flaticon-error color-danger mr-3"></i><strong>You are under insured.</strong></p>      
        </div>
      </div> <!-- Close div for .panel -->

    </div> <!-- col-md-4 col-sm-6 col-12 -->

    <div class="col-md-4 col-sm-6 col-12">

      <div class="panel panel-primary h-100">
        <div class="panel-heading"><h5>Insured with</h5></div>
        <div class="panel-body">
          <p>
            <strong>{{ hf_form['insuredWith'].value.company }}</strong><br />
            {{ hf_form['insuredWith'].value.firstName }} {{ hf_form['insuredWith'].value.lastName }}<br />
            {{ hf_form['insuredWith'].value.email }}<br />
            {{ hf_form['insuredWith'].value.cellphone }}
          </p>
        </div>
      </div> <!-- Close div for .panel -->

    </div> <!-- col-md-4 col-sm-6 col-12 -->

    <div class="col-12 mt-4">

      <p class="mt-5" *ngIf="householdItems?.length === 0">No Insured household items added.</p>

      <div class="data-table" [ngClass]="{'d-none': householdItems?.length === 0}">

        <table mat-table [dataSource]="householdItems" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
      
          <!-- Number Column -->
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="table-image table-image-rounded">
                <img [src]="row?.selectedPhoto" class="img-fluid" />
              </div>
            </td>
          </ng-container>

          <!-- Item Column -->
          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>Item</th>
            <td mat-cell *matCellDef="let row" data-label="Item">{{ row?.item }}</td>
          </ng-container>

          <!-- Category Column -->
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let row" data-label="Category">{{ row?.itemCategory }}</td>
          </ng-container>

          <!-- Insured Value Column -->
          <ng-container matColumnDef="insuredValue">
            <th mat-header-cell *matHeaderCellDef>Insured for</th>
            <td mat-cell *matCellDef="let row" data-label="Insured for">{{ row?.insuredValue | currency: 'R ' }}</td>
          </ng-container>

          <!-- Specified Value Column -->
          <ng-container matColumnDef="specified">
            <th mat-header-cell *matHeaderCellDef>Specified</th>
            <td mat-cell *matCellDef="let row" data-label="Specified">{{ row?.specified }}</td>
          </ng-container>

          <!-- Specified Value Column -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let row" data-label="Quantity">{{ row?.itemQuantity }}</td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row" data-label="Action">
                <button class="color-info btn btn-icon btn-label-hover-info mr-0" [matMenuTriggerFor]="menu">
                  <i class="fi flaticon-menu fi-rotate-90"></i>
                </button>
        
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item [routerLink]="['view-item/', ({id: row._id})]">
                      <span>View item</span>
                    </button>
      
                    <button mat-menu-item [routerLink]="['edit-item/', ({id: row._id})]">
                      <span>Edit item</span>
                    </button>
      
                    <button mat-menu-item (click)="deleteHouseholdItem(row)">
                      <span>Delete item</span>
                    </button>
                  </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="row table-footer">
          <div class="col-12 col-sm-6 my-auto">
            <ngb-pagination [collectionSize]="resultsLength" [(page)]="page" [boundaryLinks]="true" [pageSize]=pageSize (pageChange)="getAllHouseholdItems(false, $event)">
              <ng-template ngbPaginationFirst><i class="fi fi-sm flaticon-caret fi-rotate-90"></i><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
              <ng-template ngbPaginationPrevious><i class="fi fi-sm flaticon-caret fi-rotate-90"></i></ng-template>
              <ng-template ngbPaginationNext><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
              <ng-template ngbPaginationLast><i class="fi fi-sm flaticon-caret fi-rotate-270"></i><i class="fi fi-sm flaticon-caret fi-rotate-270"></i></ng-template>
            </ngb-pagination>
          </div>

          <div class="col-12 col-sm-6 my-auto">
            <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
          </div>
        </div> <!-- Close div for .row -->

      </div> <!-- Close div for .data-table -->

    </div> <!-- Close div for .col-12 -->

  </div> <!-- Close div for .row -->