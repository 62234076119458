import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BulletinService } from '../services/bulletin.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Globals } from '../app.globals';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(
    private bulletinService: BulletinService,
    public globals: Globals,
    private alert: ToastrService
  ) { }

  bulletins = [];

  getAllBulletins(){
    const page = 1;

    this.bulletinService.getAllBulletins(page).subscribe(
      (response:HttpResponse<any>) => {
        this.bulletins = response.body.bulletin;
      },
      (error:HttpErrorResponse) => {
        this.onError(error.error)
      }
    )
  }

  onSuccess(message) {
    this.alert.success(`${message.message} ${message.sub_message ? '<br />' + message.sub_message : '' }`, message.heading);
  }

  onError(error) {
    this.alert.error(`${error.errors.message} ${error.errors.sub_message ? '<br />' + error.errors.sub_message : '' }`, error.errors.heading)
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllBulletins();
  }

}
